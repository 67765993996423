import {
  STAGE_UNKNOWN,
  STAGE_IN_BUILD,
  STAGE_QA,
  STAGE_LAUNCH_PREP,
  STAGE_LAUNCHED,
  STAGE_DELETED,
  STAGE_REPLICATED,
  ACADEMY_GO,
  LP_GSR,
  LP_SKILLS,
} from '../../constants';

const stageTextLookup = {
  [STAGE_UNKNOWN]: 'Unknown',
  [STAGE_IN_BUILD]: 'In Build',
  [STAGE_QA]: 'QA',
  [STAGE_LAUNCH_PREP]: 'Launch Prep',
  [STAGE_LAUNCHED]: 'Launched',
  [STAGE_DELETED]: 'Deleted',
  [STAGE_REPLICATED]: 'Replicated',
  [ACADEMY_GO]: 'GO',
  [LP_SKILLS]: 'SKILLS',
  [LP_GSR]: 'GSR',
};

export default stageTextLookup;
