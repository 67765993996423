import React, {
  useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import TransferStatusModal from '../../WaveManagement/TransferStatusModal';
import { internalServerErrorModalLogic } from '../../../containers/common/utils';
import { getProgress } from '../../../containers/common/apis';
import ErrorModal from '../../ErrorModal/ErrorModal';

const TransferCohortUsers = ({
  transactionId, onClose, programId, type,
}) => {
  const [progress, setProgress] = useState({ done: null, percentage: 0 });
  const [openStatusModal, setOpenStatusModal] = useState(true);
  const [pollProgress, setPollProgress] = useState(true);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const history = useHistory();
  useEffect(() => {
    let timer = null;
    const pollProgressApi = async () => {
      if (!pollProgress) return;
      try {
        if (!transactionId) return;
        const res = await getProgress(transactionId);
        if (res.data.done) {
          batchUpdates(() => {
            setProgress(res.data);
            setPollProgress(false);
          });
        } else {
          setProgress(res.data);
          timer = setTimeout(pollProgressApi, 1000);
        }
      } catch (err) {
        timer = internalServerErrorModalLogic(history, err, setIsErrorModalOpen, pollProgressApi);
      }
    };
    pollProgressApi();
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId, history]);

  const preClose = () => {
    setOpenStatusModal(false);
    onClose();
  };
  return (
    <>
      {openStatusModal && progress?.done !== null ? (
        <TransferStatusModal
          open={openStatusModal}
          onClose={preClose}
          progress={progress}
          programId={programId}
          action={type}
        />
      ) : null}
      <ErrorModal open={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)} />
    </>
  );
};

TransferCohortUsers.propTypes = {
  transactionId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default TransferCohortUsers;
