import {
  SKILL_DATA_FETCH,
  SKILL_DATA_FETCH_SUCCESS,
  SKILL_DATA_FETCH_FAILED,
  UPDATE_SKILL_INFO,
  UPDATE_SKILL_INFO_SUCCESS,
  UPDATE_SKILL_INFO_FAILURE,
  ADD_NEW_SKILL,
  ADD_NEW_SKILL_SUCCESS,
  ADD_NEW_SKILL_FAILURE,
  DELETE_SKILL,
  DELETE_SKILL_SUCCESS,
  DELETE_SKILL_FAILED,
  RESET_MAPPED_SKILL_LP,
} from '../constants';

const initialState = {
  isLoading: true,
  isError: false,
  result: {},
  message: '',
  statusText: '',
  id: '',
  searchText: '',
  page: 0,
  statusMsg: '',
  mappedSkillLp: [],
};

function updateScreenState(state, { data }) {
  const { searchValue, pageNum } = data;
  if (typeof searchValue !== 'undefined' && searchValue !== state.searchText) {
    return {
      ...state, result: {}, searchText: searchValue, isLoading: true, isError: false, page: pageNum,
    };
  }
  return {
    ...state, isLoading: true, isError: false, page: pageNum || state.page,
  };
}

function onFetchSuccess(state, { results }) {
  if (state.page > 1) {
    const data = state.result.data ? state.result.data.concat(results.data) : results.data;
    const result = results;
    result.data = data;
    return {
      ...state, result, message: '', statusMsg: '', id: '', isLoading: false,
    };
  }

  const result = results;
  return {
    ...state, result, message: '', id: '', statusMsg: '', isLoading: false,
  };
}

function onUpdateSuccess(state, { response, msg }) {
  if (response) {
    const { message, data: { id } = { id: '' } } = response;
    return {
      ...state, message, id, statusMsg: msg, isLoading: false,
    };
  }
  return {
    ...state, isLoading: false,
  };
}
function onDeleteSuccess(state, { response, msg }) {
  if (response) {
    const {
      message, success, results = [], data: { id } = { id: '' },
    } = response;
    if (success) {
      return {
        ...state, message, id, statusMsg: msg, isLoading: false,
      };
    }
    return {
      ...state, id, isLoading: false, mappedSkillLp: results,
    };
  }
  return {
    ...state, isLoading: false,
  };
}

function onFetchFailure(state, { response }) {
  const { message } = response;
  return {
    ...state, message, isLoading: false, isError: true,
  };
}

function onResetMappedSkill(state) {
  return {
    ...state, mappedSkillLp: [],
  };
}

export default function skillMasterData(state = initialState, action = {}) {
  switch (action.type) {
    case SKILL_DATA_FETCH:
    case UPDATE_SKILL_INFO:
    case ADD_NEW_SKILL:
    case DELETE_SKILL:
      return updateScreenState(state, action);
    case SKILL_DATA_FETCH_SUCCESS:
      return onFetchSuccess(state, action);
    case UPDATE_SKILL_INFO_SUCCESS:
    case ADD_NEW_SKILL_SUCCESS:
      return onUpdateSuccess(state, action);
    case DELETE_SKILL_SUCCESS:
      return onDeleteSuccess(state, action);
    case SKILL_DATA_FETCH_FAILED:
    case UPDATE_SKILL_INFO_FAILURE:
    case ADD_NEW_SKILL_FAILURE:
    case DELETE_SKILL_FAILED:
      return onFetchFailure(state, action);
    case RESET_MAPPED_SKILL_LP:
      return onResetMappedSkill(state);
    default:
      return state;
  }
}
