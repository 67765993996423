import * as React from 'react';
import PropTypes from 'prop-types';

import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import FormattedTypography from '../../common/FormattedTypography';
import CustomUploadModal from '../../common/CustomUploadModal';
import ErrorRoundIcon from '../../../assets/icons/alert-red-warning.svg';

const FileUploadCancelConfirmation = ({
  open, onConfirm, onResumeUploading,
}) => (
  <CustomUploadModal
    open={open}
    heading={(
      <DialogTitle>
        <FormattedTypography
          body="Cancel Upload?"
          subHeading="Are you sure, you want to cancel, if you cancel the upload, the progress will discontinue and the training material will not be added."
        />
      </DialogTitle>
    )}
    logo={(
      <Box>
        <img src={ErrorRoundIcon} alt="progress-failed" width={40} height={40} />
      </Box>
    )}
    primaryBtnText="Resume Uploading"
    primaryBtnProps={{
      onClick: () => onResumeUploading(),
    }}
    secondaryBtnText="Confirm"
    secondaryBtnProps={{
      onClick: () => onConfirm(),
    }}
  >
    <></>
  </CustomUploadModal>
);

FileUploadCancelConfirmation.propTypes = {
  open: PropTypes.bool.isRequired,
  onResumeUploading: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default FileUploadCancelConfirmation;
