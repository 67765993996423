import React from 'react';
import PropTypes from 'prop-types';
import {
  Stepper, Step, StepLabel,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { USER_CIRCLE_DARK_BG, SWITCH_GREY } from '../../stylesheets/colors';
import CompleteTask from '../../assets/icons/stepper-complete.svg';

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-circle': {
    width: 10,
    height: 10,
    borderRadius: '50%',
  },
  '& .QontoStepIcon-active': {
    backgroundColor: USER_CIRCLE_DARK_BG,
  },
  '& .QontoStepIcon-inactive': {
    backgroundColor: SWITCH_GREY,
  },
}));

const QontoStepIcon = (props) => {
  const { completed, active, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {(() => {
        if (completed) {
          return (
            <img src={CompleteTask} alt="complete-task" />
          );
        } if (active) {
          return (<div className="QontoStepIcon-circle QontoStepIcon-active" />
          );
        }
        return (<div className="QontoStepIcon-circle QontoStepIcon-inactive" />
        );
      })()}
    </QontoStepIconRoot>
  );
};

QontoStepIcon.defaultProps = {
  active: false,
  className: '',
  completed: false,
};

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
};

const WizardStepper = ({ steps, activeStep }) => (
  <Box sx={{ width: '40%', margin: 'auto' }}>
    <Stepper activeStep={activeStep} alternativeLabel sx={{ width: '50%' }}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  </Box>
);
WizardStepper.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
};

export default WizardStepper;
