import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FileInput from '../../components/FileInput';
import MainButton from '../../components/MainButton';
import {
  ACCORDION_GREY, MCKINSEY_BLUE, MODERATE_DARK_GREY, MODERATE_LIGHT_GREY,
} from '../../stylesheets/colors';
import {
  ROUTE_REPORT,
  ASSIGNMENT_DOWNLOAD,
  UPLOAD_GRADE_TABS_EVALUATE,
  UPLOAD_GRADE_TABS_MODIFY,
  MODIFY_GRADE,
  UPLOAD_GRADE,
} from '../../constants';

const useStyles = makeStyles({
  bodyWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 2rem 0 4rem',
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  tabs: {
    borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
    borderColor: 'divider',
  },
  tab: {
    '&.MuiTab-root': {
      textTransform: 'none',
      minWidth: 0,
      fontSize: '1.125rem',
    },
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '2rem',
    paddingRight: '2rem',
    '& > div': {
      flex: 0.485,
    },
    '& > div:not(:last-of-type)': {
      borderRight: `1px solid ${MODERATE_LIGHT_GREY}`,
      paddingRight: '2rem',
    },
  },
  infoText: {
    color: MODERATE_DARK_GREY,
  },
  infoHeading: {
    fontWeight: '800',
  },
  infoHelperText: {
    color: ACCORDION_GREY,
    padding: '0.25rem 0',
  },
  divider: {
    margin: '1.125rem 0',
  },
  link: {
    margin: '0 0.5rem',
  },
  border: {
    border: `0.5px dashed ${MCKINSEY_BLUE}`,
    width: '40%',
  },
});

const UploadGradesBody = ({
  programSubType, programId, showAlertBar, onUpload,
}) => {
  const classes = useStyles();

  const history = useHistory();

  const tabChange = (ev, newValue) => {
    history.push(`/${ROUTE_REPORT}/${programId}/${newValue}`);
  };

  const a11yProps = (index) => ({
    id: index,
    'aria-controls': `tabpanel-${index}`,
    value: index,
    disabled: showAlertBar,
  });

  return (
    <Container className={classes.bodyWrapper} disableGutters maxWidth={false}>
      <Box className={classes.innerWrapper}>
        <Tabs
          className={classes.tabs}
          value={programSubType}
          sx={{
            '& .MuiTab-root.Mui-selected': {
              color: MCKINSEY_BLUE,
            },
            '& .MuiTabs-indicator': {
              backgroundColor: MCKINSEY_BLUE,
            },
          }}
          onChange={tabChange}
        >
          <Tab
            label={UPLOAD_GRADE_TABS_EVALUATE}
            className={classes.tab}
            {...a11yProps(UPLOAD_GRADE)}
          />
          <Tab
            label={UPLOAD_GRADE_TABS_MODIFY}
            className={classes.tab}
            {...a11yProps(MODIFY_GRADE)}
          />
        </Tabs>

        <div className={classes.innerContainer}>
          {programSubType === MODIFY_GRADE ? (
            <div>
              <Typography variant="subtitle2" className={classes.infoHeading}>
                Ensure that you have the latest Metadata file from
                <Link
                  className={classes.link}
                  component={RouterLink}
                  to={{
                    pathname: `/${ROUTE_REPORT}/${programId}/${ASSIGNMENT_DOWNLOAD}`,
                  }}
                >
                  Assignment Download
                </Link>
                page.
              </Typography>
              <br />
              <Typography variant="subtitle2" component="p" className={classes.infoText}>
                You can use this space to modify grades of an evaluated assignment.
              </Typography>
              <Divider className={classes.divider} />
              <Typography variant="subtitle2" component="p" className={classes.infoHelperText}>
                If you are trying to evaluate a re-submitted assignment, use the
                <Link
                  style={showAlertBar ? { pointerEvents: 'none' } : {}}
                  className={classes.link}
                  component={RouterLink}
                  to={{
                    pathname: `/${ROUTE_REPORT}/${programId}/${UPLOAD_GRADE}`,
                  }}
                >
                  {UPLOAD_GRADE_TABS_EVALUATE}
                </Link>
                section.
              </Typography>
            </div>
          ) : (
            <div>
              <Typography variant="subtitle2" className={classes.infoHeading}>
                Ensure that you have the latest Metadata file from
                <Link
                  role="link"
                  className={classes.link}
                  component={RouterLink}
                  to={{
                    pathname: `/${ROUTE_REPORT}/${programId}/${ASSIGNMENT_DOWNLOAD}`,
                  }}
                >
                  Assignment Download
                </Link>
                page.
              </Typography>
              <Typography variant="subtitle2" className={classes.infoHeading}>
                Ensure you only have one assignment per group with the upload file.
              </Typography>
              <br />
              <Typography variant="subtitle2" component="p" className={classes.infoText}>
                You can use this space to:
              </Typography>
              <Typography variant="subtitle2" component="p" className={classes.infoText}>
                - Evaluate the learner’s assignment for the first time
              </Typography>
              <Typography variant="subtitle2" component="p" className={classes.infoText}>
                - Evaluate any re-submitted assignments for the first time
              </Typography>
              <Divider className={classes.divider} />
              <Typography variant="subtitle2" component="p" className={classes.infoHelperText}>
                Grades once uploaded can only be updated from the
                <Link
                  style={showAlertBar ? { pointerEvents: 'none' } : {}}
                  className={classes.link}
                  component={RouterLink}
                  to={{
                    pathname: `/${ROUTE_REPORT}/${programId}/${MODIFY_GRADE}`,
                  }}
                >
                  {UPLOAD_GRADE_TABS_MODIFY}
                </Link>
                section.
              </Typography>
            </div>
          )}

          <Box className={classes.border}>
            <FileInput
              idx={0}
              onFileInput={(e) => onUpload(e, programSubType === MODIFY_GRADE)}
              isDisabled={showAlertBar}
            >
              <MainButton title="Upload XLS file" subtitle="Browse your files and upload now!" isDisabled={showAlertBar} />
            </FileInput>
          </Box>
        </div>
      </Box>
    </Container>
  );
};

UploadGradesBody.propTypes = {
  programId: PropTypes.string.isRequired,
  programSubType: PropTypes.string.isRequired,
  showAlertBar: PropTypes.bool.isRequired,
  onUpload: PropTypes.func.isRequired,
};

export default UploadGradesBody;
