import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  LIGHT_MEDIUM_GREY, REPORT_ERROR_RED, MCKINSEY_BLUE, MODERATE_DARK_GREY, WHITE,
} from '../../stylesheets/colors';
import theme from '../../stylesheets/theme';
import MCKINSEY_SANS from '../../stylesheets/fonts';
import { getOptionsBgColor } from '../../helpers/utils';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  labelWrapper: ({ required }) => ({
    display: 'flex',
    flex: 0.25,
    fontWeight: 'bold',
    alignItems: 'flex-end',
    '&::after': {
      content: required ? "'*'" : "''",
      color: REPORT_ERROR_RED,
    },
  }),
  selectWrapper: {
    flex: 0.75,
    width: '100%',
    paddingTop: '8px',
    minHeight: '40px',
    maxHeight: '2.5rem',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  CustomOption: {
    backgroundColor: 'transparent',
    color: MCKINSEY_BLUE,
    cursor: 'pointer',
    padding: '8px 12px',
    width: '100%',
    textDecoration: 'underline',
  },
  loaderWrapper: {
    width: '1em',
    height: '1em',
    display: 'flex',
    alignItems: 'center',
  },
  loader: {
    width: '5px',
    height: '5px',
    aspectRatio: 1,
    borderRadius: '50%',
    animation: '$l5 1s infinite linear alternate',
  },
  '@keyframes l5': {
    '0%': {
      boxShadow: '8px 0 #0001, -8px 0 #0002',
      background: '#0001',
    },
    '33%': {
      boxShadow: '8px 0 #0001, -8px 0 #0002',
      background: '#0002',
    },
    '66%': {
      boxShadow: '8px 0 #0002,-8px 0 #0001',
      background: '#0002',
    },
    '100%': {
      boxShadow: '8px 0 #0002,-8px 0 #0001',
      background: '#0001',
    },
  },
}));

// min-height: unset;
// height: 31px;
const selectStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0px',
    borderColor: LIGHT_MEDIUM_GREY,
    minHeight: 'unset',
    height: 'auto',
    paddingTop: '0px',
    paddingBottom: '0px',
    maxHeight: '120px',
    overflow: 'auto',
    '&:hover': {
      borderColor: LIGHT_MEDIUM_GREY,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: 'auto',
    minHeight: 'unset',
    fontFamily: MCKINSEY_SANS,
    fontSize: '1.125rem',

    'p, span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: 'auto',
    minHeight: 'unset',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '0px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: LIGHT_MEDIUM_GREY,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamily,
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamily,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  option: (provided, { isDisabled, isFocused, isSelected }) => (
    {
      ...provided,
      backgroundColor: getOptionsBgColor({ isDisabled, isFocused, isSelected }),
      cursor: isDisabled ? 'not-allowed' : 'default',
    }
  ),
  multiValue: (provided, { isDisabled }) => ({
    ...provided,
    backgroundColor: isDisabled ? '#ccc' : MODERATE_DARK_GREY,
    padding: '0.25rem 0.5rem',
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamily,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: WHITE,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: WHITE,
  }),
};

const Menu = (props) => {
  const classes = useStyles({});
  const { children, selectProps } = props;
  return (
    <>
      <components.Menu {...props}>
        <div>
          <div>{children}</div>
          {
            selectProps.customOption && (
              <Typography variant="body1" onClick={selectProps.customOptionHandler} className={`${classes.CustomOption}`}>
                {selectProps.customOption}
              </Typography>
            )
          }

        </div>
      </components.Menu>
    </>
  );
};
Menu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  selectProps: PropTypes.object.isRequired,
};

const DropdownIndicator = (props) => {
  const classes = useStyles({});
  const { selectProps } = props;
  return (
    <components.DropdownIndicator {...props}>
      {selectProps.selectIconLoading ? <div className={classes.loaderWrapper}><div className={classes.loader} /></div> : <KeyboardArrowDownIcon fontSize="large" />}
    </components.DropdownIndicator>
  );
};

DropdownIndicator.propTypes = {
  selectProps: PropTypes.object.isRequired,
};

const CustomSelect = ({
  label, placeholder, options, onChange, defaultValue, maxMenuHeight,
  menuPlacement, name, className, selectRef, disabled, required, labelClass,
  customOption, customOptionHandler, isSearchable, isOptionsNotLoading, isMulti,
  noOptionsMessage, isClearable, selectIconLoading,
}) => {
  const classes = useStyles({ required, isMulti });
  const isLoading = isOptionsNotLoading ? false : !options.length;

  return (
    <div className={`${classes.wrapper} ${className}`}>
      {typeof label === 'string' || typeof label === 'number' ? (
        <Typography variant="body1" className={`${classes.labelWrapper} ${labelClass}`}>
          {label}
        </Typography>
      ) : label}
      <Select
        ref={selectRef}
        styles={selectStyles}
        placeholder={placeholder}
        isSearchable={isSearchable}
        isLoading={isLoading}
        name={name.length > 0 ? name : label}
        options={options}
        customOption={customOption}
        components={{ Menu, DropdownIndicator }}
        customOptionHandler={customOptionHandler}
        classNamePrefix="react-select"
        onChange={onChange}
        value={defaultValue}
        maxMenuHeight={maxMenuHeight}
        menuPlacement={menuPlacement}
        isDisabled={disabled}
        menuPortalTarget={document.body}
        isOptionDisabled={(option) => option.isdisabled}
        noOptionsMessage={() => noOptionsMessage}
        isMulti={isMulti}
        isClearable={isClearable}
        selectIconLoading={selectIconLoading}
      />
    </div>
  );
};

CustomSelect.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  maxMenuHeight: PropTypes.number,
  menuPlacement: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  selectRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    PropTypes.object,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  required: PropTypes.bool,
  labelClass: PropTypes.string,
  customOption: PropTypes.string,
  customOptionHandler: PropTypes.func,
  isSearchable: PropTypes.bool,
  isOptionsNotLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  noOptionsMessage: PropTypes.string,
  isClearable: PropTypes.bool,
  selectIconLoading: PropTypes.bool,
};

CustomSelect.defaultProps = {
  label: null,
  defaultValue: undefined,
  maxMenuHeight: 300,
  menuPlacement: 'bottom',
  name: '',
  className: '',
  disabled: false,
  required: false,
  selectRef: null,
  labelClass: '',
  customOption: '',
  customOptionHandler: () => { },
  isSearchable: true,
  isOptionsNotLoading: false,
  isMulti: false,
  noOptionsMessage: 'No Options Found',
  isClearable: false,
  selectIconLoading: false,
};

export default CustomSelect;
