import { put, call, takeLatest } from 'redux-saga/effects';

import { validateXLSXApiCall } from '../async/common';

import { uploadUsersDeletion, activateDeactivateUsersAPI } from '../async/platFormusersApi';

import {
  fileValidateSuccess,
  fileValidateFailure,
  fileUploadSuccess,
  fileUploadFailure,
} from '../fileValidate';

import { FILE_VALIDATE, FILE_UPLOAD } from '../../constants';

export function* uploadFile({ automationType, programSubType, data }) {
  try {
    let response;
    if (data === 'Activate_deactivate') {
      response = (yield call(activateDeactivateUsersAPI, programSubType)).data;
    } else {
      response = yield call(uploadUsersDeletion, automationType, programSubType, data);
    }
    yield put(fileUploadSuccess(response));
  } catch (err) {
    yield put(fileUploadFailure(err.response.data));
  }
}

export function* validateFile({ automationType, programSubType, data }) {
  try {
    const response = yield call(validateXLSXApiCall, automationType, programSubType, data);
    yield put(fileValidateSuccess(response));
  } catch (err) {
    yield put(fileValidateFailure(err.response.data));
  }
}

export function* fileValidateSaga() {
  yield takeLatest(FILE_VALIDATE, validateFile);
  yield takeLatest(FILE_UPLOAD, uploadFile);
}
