import {
  FILE_VALIDATE,
  FILE_VALIDATE_SUCCESS,
  FILE_VALIDATE_FAILURE,
  FILE_UPLOAD,
  FILE_UPLOAD_FAILURE,
  FILE_UPLOAD_SUCCESS,
  CLEAR_MESSAGE,
  CLEAR_TRANSACTION_ID,
  SELECTED_BULK_ACTION,
} from '../constants';

export function fileValidate(automationType, programSubType, data) {
  return {
    type: FILE_VALIDATE,
    automationType,
    programSubType,
    data,
  };
}

export function fileValidateSuccess(results) {
  return {
    type: FILE_VALIDATE_SUCCESS,
    results,
  };
}
export function fileValidateFailure(results) {
  return {
    type: FILE_VALIDATE_FAILURE,
    results,
  };
}

export function fileUpload(automationType, programSubType, data) {
  return {
    type: FILE_UPLOAD,
    automationType,
    programSubType,
    data,
  };
}

export function fileUploadSuccess(results) {
  return {
    type: FILE_UPLOAD_SUCCESS,
    results,
  };
}

export function fileUploadFailure(results) {
  return {
    type: FILE_UPLOAD_FAILURE,
    results,
  };
}

export function clearMessage() {
  return {
    type: CLEAR_MESSAGE,
  };
}

export function clearTransactionId() {
  return {
    type: CLEAR_TRANSACTION_ID,
  };
}

export function setSelectedBulkAction(action) {
  return {
    type: SELECTED_BULK_ACTION,
    action,
  };
}
