import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import GlobalActivityNav from './components/common/GlobalActivityNav';
import ActivityLogs from './containers/ActivityLogs';
import {
  ACTIVE_LOGS_MENU,
} from './constants';

const useStyles = makeStyles({
  drawerWrapper: {
    '& .MuiDrawer-paper': {
      display: 'flex',
      flexDirection: 'row',
    },
  },
});

const ActivityLogsContainer = ({ allowedActivityLog, role }) => {
  const classes = useStyles();
  const [allowedLogs, setAllowLogs] = useState([]);
  const location = useLocation();
  useEffect(() => {
    const getAllowedRoutes = (pathname) => allowedActivityLog.filter(
      ({ activityLog, path, permission }) => (activityLog
        && pathname.includes(path)
        && permission.includes(role)),
    );

    const logs = getAllowedRoutes(location.pathname);
    setAllowLogs(logs);
  }, [allowedActivityLog, location, role]);

  const [open, setOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(ACTIVE_LOGS_MENU[0]);

  const toggleDrawer = (value) => {
    setOpen(value);
  };

  const onMenuItemClick = (item) => {
    setSelectedMenu(item);
  };

  return (
    <>
      {
      allowedLogs.length ? (
        <>
          <Paper className={classes.logWrapper} component="div" elevation={3}>
            <GlobalActivityNav
              expanded={open}
              toggleDrawer={toggleDrawer}
              selectedMenu={selectedMenu}
              onMenuItemClick={onMenuItemClick}
              disabled
            />
          </Paper>
          <Drawer
            anchor="right"
            open={open}
            onClose={() => toggleDrawer(false)}
            className={classes.drawerWrapper}
          >
            <Paper className={classes.drawerIcons} elevation={3}>
              <GlobalActivityNav
                expanded={open}
                toggleDrawer={toggleDrawer}
                selectedMenu={selectedMenu}
                onMenuItemClick={onMenuItemClick}
                disabled={false}
                platformType={allowedLogs[0].value}
              />
            </Paper>
            <Box className={classes.drawerContainer}>
              <ActivityLogs
                selectedMenu={selectedMenu}
                programSubType={allowedLogs[0].value}
              />
            </Box>
          </Drawer>
        </>
      ) : <></>
    }
    </>
  );
};

ActivityLogsContainer.propTypes = {
  allowedActivityLog: PropTypes.array.isRequired,
  role: PropTypes.string.isRequired,
};

export default memo(ActivityLogsContainer, (prevProps, nextProps) => (
  prevProps.role === nextProps.role
));
