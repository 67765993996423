import * as React from 'react';
import PropTypes from 'prop-types';
import {
  List, ListItem, ListItemIcon, ListItemText,
} from '@mui/material';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Radio from '@material-ui/core/Radio';
import Box from '@mui/material/Box';
import { Typography } from '@material-ui/core';
import Chip from '@mui/material/Chip';
import { DESKTOP_VIEW_MODE_OPTIONS } from '../../../constants';
import FormattedTypography from '../../common/FormattedTypography';
import {
  ACCORDION_GREY, MODERATE_DARK_GREY, MODERATE_LIGHT_GREY,
} from '../../../stylesheets/colors';

const useStyles = makeStyles({
  subHeadingColor: {
    color: MODERATE_DARK_GREY,
  },
  noPaading: {
    '& .MuiButtonBase-root': {
      paddingLeft: '0',
    },
  },
  primaryWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
});

const DesktopViewMode = ({
  values, setFieldValue, formHeadingClass, titleFontSize,
}) => {
  const classes = useStyles({});

  return (
    <Box style={{ width: '60%', margin: 'auto' }}>
      <FormattedTypography
        body="Set Desktop view mode"
        variant="h1"
        className={`${formHeadingClass} ${titleFontSize}`}
        justifyContent="flex-start"
        subHeading="Set the view mode when viewed on a desktop screen. (For tablet and mobile devices, the default view will be set to full screen)"
        subHeadingClass={classes.subHeadingColor}
        subheadingVariant="subtitle2"
        subHeadingAlignment="left"
      />
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {DESKTOP_VIEW_MODE_OPTIONS.map((mode) => (
          <ListItem
            alignItems="flex-start"
            key={mode.primaryText}
            disableGutters
          >
            <ListItemIcon className={classes.noPaading} sx={{ marginTop: '0rem', minWidth: '5px' }}>
              <Radio
                checked={values.launch_mode === mode.value}
                onChange={(e) => {
                  setFieldValue('launch_mode', e.target.value);
                }}
                value={mode.value}
                name="launch_mode"
                inputProps={{ 'aria-label': 'A' }}
                color="Primary"
              />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Box className={classes.primaryWrapper}>
                  <Typography variant="subtitle2" className={formHeadingClass}>{mode.primaryText}</Typography>
                  {mode.primaryChipText && (
                    <Chip
                      style={{
                        backgroundColor: MODERATE_LIGHT_GREY,
                        color: MODERATE_DARK_GREY,
                        fontSize: '1rem',
                        fontWeight: 500,
                        padding: '0 0.5rem',
                        borderRadius: '5px',
                        marginLeft: '1rem',
                      }}
                      label={mode.primaryChipText}
                      size="small"
                    />
                  )}

                </Box>
              )}
              primaryTypographyProps={{ fontSize: '1.25rem' }}
              secondary={mode.secondaryText}
              secondaryTypographyProps={{ fontSize: '1.25rem', color: ACCORDION_GREY }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

DesktopViewMode.propTypes = {
  values: PropTypes.shape({
    launch_mode: PropTypes.string.isRequired,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  formHeadingClass: PropTypes.string.isRequired,
  titleFontSize: PropTypes.string.isRequired,
};

export default DesktopViewMode;
