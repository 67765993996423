/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import {
  FieldArray,
  useField,
} from 'formik';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import ElucidatProjectInput from './ElucidatProjectInput';
import { LIGHT_MEDIUM_GREY } from '../../stylesheets/colors';
import { getMaterialType, isScormUnassigned } from '../../helpers/utils';
import WarningInfoAlert from '../common/WarningInfoAlert';
import {
  ROUTE_REPORT, CONTENT_MANAGEMENT, REPLICATION_TMS_IN_COMPONENT_LIMIT, ELUCIDAT_SCORM_TYPE,
} from '../../constants';
import LinkButton from '../common/LinkButton';
import ScormTypeChip from '../common/ScormTypeChip';

const useStyles = makeStyles({
  noDisplay: {
    display: 'none',
  },
  warningBox: {
    padding: '0.5rem 0',
  },
  textWrapper: {
    minWidth: '20rem',
    padding: '0.5rem',
    border: `1px solid ${LIGHT_MEDIUM_GREY}`,
    color: LIGHT_MEDIUM_GREY,
  },
});

const LoRow = ({
  courseId,
  loIndex,
  lo,
  coureseRow,
  isDisabled,
  courseIndex,
  checkElucidatValidity,
  updateCourseErrorStatus,
  formik,
  courseChecked,
  isExpanded,
  courseLimitExceeded,
  destinationConfigId,
  programId,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const redirectToCourse = () => {
    history.push(`/${ROUTE_REPORT}/${programId}/${CONTENT_MANAGEMENT}?feature=view-course&id=${courseId}`);
  };

  // eslint-disable-next-line no-unused-vars
  const [selectedField, selectedMeta, selectedHelpers] = useField({
    name: `${coureseRow}.lo[${loIndex}].selected`,
    type: 'checkbox',
  });

  const { values, setFieldValue } = formik;

  // To enable LO's checkbox when course checked
  useEffect(() => {
    if (!values?.courses[courseIndex].selected) {
      selectedHelpers.setValue(false);
    }
  }, [values]);

  // To make scorm inavlid on load
  useEffect(() => {
    if (lo?.type?.includes('scorm')) {
      setFieldValue('isScormValidated', false);
    }
  }, []);

  useEffect(() => {
    if (lo?.type?.includes('scorm')) {
      if (isScormUnassigned(lo)) {
        selectedHelpers.setValue(false);
      } else {
        selectedHelpers.setValue(courseChecked);
      }
    } else {
      selectedHelpers.setValue(courseChecked);
    }
  }, [courseChecked]);

  useEffect(() => {
    if (_filter(_get(values, `courses[${courseIndex}].lo`, []), { selected: true }).length > REPLICATION_TMS_IN_COMPONENT_LIMIT) {
      courseLimitExceeded(true);
    } else {
      courseLimitExceeded(false);
    }
  }, [selectedField.value, courseIndex]);

  const hideClass = isExpanded ? '' : classes.noDisplay;
  const { label, icon } = getMaterialType(lo);

  return (
    <TableRow key={lo.name} className={hideClass}>
      <TableCell />
      <TableCell />
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          id={selectedField.name}
          name={selectedField.name}
          checked={selectedField.checked}
          onClick={(event) => {
            selectedHelpers.setValue(event.target.checked);
          }}
          disabled={isScormUnassigned(lo) || isDisabled}
        />
      </TableCell>
      <TableCell>
        {lo.name}
        {isScormUnassigned(lo) && (
          <WarningInfoAlert severity="warning" customStyles={classes.warningBox}>
            SCORM type cannot be blank. Kindly
            <LinkButton text="edit the training material" onClick={() => redirectToCourse()} color="primary" variant="btnGeneral2" />
            to assign SCORM type.
          </WarningInfoAlert>
        )}
        {lo?.type?.includes('scorm') && lo?.elucidatSelect === 'duplicate' && (
          <WarningInfoAlert severity="warning" customStyles={classes.warningBox}>
            Duplicated projects are added to the elucidat folder of the parent project
            and to any additional folders that were selected
          </WarningInfoAlert>
        )}
      </TableCell>
      <TableCell>
        <ScormTypeChip label={label} icon={icon} />
      </TableCell>
      <TableCell>
        {
          lo?.type?.includes('scorm')
          && (
            <>
              {lo?.scorm_type === ELUCIDAT_SCORM_TYPE ? (
                <ElucidatProjectInput
                  key={lo.id}
                  scormInputPath={`courses[${courseIndex}].lo[${loIndex}]`}
                  lo={{ ...lo, lo_id: lo?.id?.split(':')[0] }}
                  formik={formik}
                  updateCourseErrorStatus={updateCourseErrorStatus}
                  checkElucidatValidity={checkElucidatValidity}
                  selected={selectedField.checked}
                  disable={isDisabled}
                  destinationConfigId={destinationConfigId}
                  feature="Replication"
                />
              ) : (
                <Typography className={classes.textWrapper}>Not Applicable</Typography>
              )}
            </>
          )
        }
      </TableCell>
    </TableRow>
  );
};

const ExpandedComponent = ({
  courseId,
  isDisabled,
  courseIndex,
  coureseRow,
  checkElucidatValidity,
  updateCourseErrorStatus,
  formik,
  courseChecked,
  isExpanded,
  courseLimitExceeded,
  destinationConfigId,
  programId,
}) => (
  <FieldArray name={`${coureseRow}.lo`}>
    {() => (
      <>
        {formik?.values?.courses[courseIndex].lo.map((loObj, index) => (
          <LoRow
            key={loObj.name}
            loIndex={index}
            lo={loObj}
            formik={formik}
            courseChecked={courseChecked}
            coureseRow={coureseRow}
            isExpanded={isExpanded}
            isDisabled={isDisabled}
            updateCourseErrorStatus={updateCourseErrorStatus}
            courseIndex={courseIndex}
            checkElucidatValidity={checkElucidatValidity}
            courseLimitExceeded={courseLimitExceeded}
            destinationConfigId={destinationConfigId}
            courseId={courseId}
            programId={programId}
          />
        ))}
      </>

    )}
  </FieldArray>

);

export default ExpandedComponent;

ExpandedComponent.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  checkElucidatValidity: PropTypes.func.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({
      courses: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
  courseIndex: PropTypes.string.isRequired,
  coureseRow: PropTypes.string.isRequired,
  updateCourseErrorStatus: PropTypes.func.isRequired,
  courseChecked: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  courseLimitExceeded: PropTypes.func.isRequired,
  destinationConfigId: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired,
};

LoRow.propTypes = {
  courseId: PropTypes.string.isRequired,
  lo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    original_project_id: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]),
    ]),
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    scorm_type: PropTypes.string.isRequired,
    project_id_status: PropTypes.string.isRequired,
    project_name: PropTypes.string.isRequired,
    elucidatSelect: PropTypes.string.isRequired,
  }).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  checkElucidatValidity: PropTypes.func.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({
      courses: PropTypes.array.isRequired,
    }).isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  loIndex: PropTypes.string.isRequired,
  courseIndex: PropTypes.string.isRequired,
  coureseRow: PropTypes.string.isRequired,
  updateCourseErrorStatus: PropTypes.func.isRequired,
  courseChecked: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  courseLimitExceeded: PropTypes.func.isRequired,
  destinationConfigId: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired,
};
