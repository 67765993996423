/* eslint-disable import/prefer-default-export */
import config from '../../../config';
import request from '../../../helpers/apiHelper';

export const uploadUsersDeletion = async (programSubType, data) => {
  let url = '';
  if (programSubType === 'bulk_user_creation') {
    url = `${config.ROUTES.USER_CREATION}`;
  } else {
    url = `${config.ROUTES.USER_MANAGEMENT_USER_DETAILS}/v2/bulk_deletion_user_flag`;
  }
  const method = data.get('flag_type') === 'Unmark' ? 'PUT' : 'POST';
  const options = {
    url,
    method,
    data,
  };
  const resp = await request(options);
  return resp?.data;
};

export const markForDeleteAPI = async (data) => {
  const url = `${config.ROUTES.USER_MANAGEMENT_USER_DETAILS}/v2/deletion_user_flag`;
  const method = data.flag_type === 'Mark' ? 'POST' : 'PUT';
  const options = {
    url,
    method,
    data,
  };
  const resp = await request(options);
  return resp?.data;
};

export const deleteUserAPI = async (data) => {
  const url = `${config.ROUTES.USER_MANAGEMENT_USER_DATA}/delete`;
  const method = 'POST';
  const options = {
    url,
    method,
    data,
  };
  const resp = await request(options);
  return resp?.data;
};

export const activateDeactivateUsersAPI = async (data) => {
  const url = `${config.ROUTES.AUTOMATION}/user_statuses`;
  const options = { url, method: 'POST', data };
  const result = await request(options);
  return result;
};
