import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@mui/material/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {
  BLACK,
  MEDIUM_GREY,
} from '../../stylesheets/colors';
import ConfirmationModal from '../common/ConfirmationModal';
import JournalingDeleteIcon from '../../assets/img/deleteIconJournaling.svg';

const useStyles = makeStyles(() => ({
  wrapper: {
    '&.MuiContainer-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    '& > img': {
      marginBottom: '2.5rem',
    },

    '& > p': {
      textAlign: 'center',
    },

  },
  subHeading: {
    color: BLACK,
    fontWeight: 600,
    marginBottom: '0.75rem',
  },
  infoContentText: {
    color: MEDIUM_GREY,
  },
  modalStyle: {
    minWidth: '70rem',
  },
  listContainer: {
    maxHeight: '20rem',
    overflow: 'auto',
    width: '100%',
  },
}));

const MappedCohortsModal = ({
  onClose,
  data,
  heading,
  subHeading,
  tableColumn1,
  tableColumn2,
}) => {
  const classes = useStyles();

  return (
    <ConfirmationModal
      open
      onClose={onClose}
      modalStyle={classes.modalStyle}
      content={(
        <Container className={classes.wrapper}>
          <img width={100} height={100} src={JournalingDeleteIcon} alt="delete-icon" />
          <Typography variant="body2" component="p" className={classes.subHeading}>{heading}</Typography>
          <Typography variant="body1" component="p" className={`${classes.infoContentText}`}>
            {subHeading}
          </Typography>

          <Box className={classes.listContainer}>
            <List>
              <ListItem
                disablePadding
                secondaryAction={(
                  <ListItemText primary={tableColumn1.label} />
                )}
              >
                <ListItemText primary={tableColumn2.label} />
              </ListItem>
              {data.map((item) => (
                <ListItem
                  disablePadding
                  key={item[tableColumn1.id]}
                  secondaryAction={(
                    <ListItemText secondary={item[tableColumn1.id]} />
                  )}
                >
                  <ListItemText secondary={item[tableColumn2.id]} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Container>
      )}
      actions={(
        <>
          <Button color="primary" variant="contained" onClick={onClose}>
            Close
          </Button>
        </>
      )}
    />
  );
};

MappedCohortsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  tableColumn1: PropTypes.object.isRequired,
  tableColumn2: PropTypes.object.isRequired,
};

export default MappedCohortsModal;
