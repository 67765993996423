import {
  FILE_UPLOAD, FILE_UPLOAD_FAILURE, FILE_UPLOAD_SUCCESS, CLEAR_TRANSACTION_ID,
} from '../constants';

const initialState = {
  isLoading: false,
  errors: [],
  message: '',
  transaction_id: null,
};

function updateScreenState(state) {
  return {
    ...state, isLoading: true, errors: [], message: '',
  };
}

function onFileUploadSuccess(state, { results }) {
  return {
    ...state, isLoading: false, message: results.message, transaction_id: results.transaction_id,
  };
}

function onFileUploadFailure(state, { results }) {
  return { ...state, isLoading: false, results };
}

export default function FileUploadReducer(state = initialState, action = {}) {
  switch (action.type) {
    case FILE_UPLOAD:
      return updateScreenState(state, action);
    case FILE_UPLOAD_SUCCESS:
      return onFileUploadSuccess(state, action);
    case FILE_UPLOAD_FAILURE:
      return onFileUploadFailure(state, action);
    case CLEAR_TRANSACTION_ID:
      return { ...state, transaction_id: null };
    default:
      return state;
  }
}
