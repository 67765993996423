import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {
  MEDIUM_GREY, REPORT_ERROR_RED, USER_CIRCLE_DARK_BG,
} from '../../../stylesheets/colors';
import LabledTextField from '../../common/LabledTextField';
import CustomSelect from '../../common/CustomSelect';
import { SCORM_TYPE_SELECT_OPTIONS } from '../../../constants';
import ElucidatFields from './ElucidatFields';
import ErrorText from '../../common/ErrorText';
import OutlineReplaceIcon from '../../../assets/icons/outline-replace-icon.svg';
import ScormLink from './ScormLink';

const useStyles = makeStyles({
  subHeading: {
    color: MEDIUM_GREY,
  },
  labelText: {
    marginTop: '1rem',
  },
  fileWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& label': {
      width: 'auto',
    },
    alignItems: 'center',
  },
  labelClass: {
    margin: '0.8rem 0rem',
    fontSize: '1.125rem',
    fontWeight: 'normal',
  },
  required: {
    '&.MuiTypography-root::after': {
      content: "'*'",
      color: REPORT_ERROR_RED,
    },
  },
  formTitle: {
    marginTop: '1rem',
  },
  dropdown: {
    paddingBottom: '0.5rem',
  },
  overlaySection: {
    opacity: 0.4,
  },
  UploadBtn: {
    padding: 0,
    '&.MuiIconButton-root:hover': {
      backgroundColor: 'white',
    },
    '& span': {
      color: USER_CIRCLE_DARK_BG,
    },
  },
  iconButton: {
    display: 'flex',
    flexDirection: 'row',
    color: USER_CIRCLE_DARK_BG,
  },
  uploadLabel: {
    fontSize: '1.25rem',
    textDecoration: 'underline',
    marginLeft: '0.5rem',
  },
  rightText: {
    textAlign: 'end',
  },
});

const ScormDetailsForm = ({
  values, handleChange, setFieldValue, onBlur, validateProjectId, touched, errors, setFieldTouched,
  isValidating, clearElucidatDetails, formHeadingClass, onReplace,
}) => {
  const classes = useStyles();
  const {
    title, scorm_link, box_link, scorm_type,
  } = values;

  const clearScormDetails = useCallback(() => {
    setFieldValue('scorm_link', '');
    setFieldValue('box_link', '');
    setFieldValue('project_id', '');
  }, [setFieldValue]);

  return (
    <Box sx={{ width: '70%', margin: 'auto' }}>
      <Box>
        <Box className={classes.fileWrapper}>
          <Typography variant="body1" className={classes.subHeading}>
            {values?.file?.name}
          </Typography>
          <IconButton
            component="label"
            variant="outlined"
            classes={{ label: classes.iconButton }}
            className={classes.UploadBtn}
            tabIndex={-1}
            onClick={onReplace}
          >
            {values?.file?.name ? (
              <>
                <img src={OutlineReplaceIcon} alt="replace-icon" />
                <span className={classes.uploadLabel}>Replace File</span>
              </>
            ) : null}
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.formTitle}>
        <Typography variant="h1" className={`${classes.required} ${formHeadingClass}`}>
          Enter SCORM details
        </Typography>
        <Typography variant="body1" className={classes.subHeading}>
          Values entered will reflect in the production checklist.
        </Typography>
      </Box>
      <Box>
        <LabledTextField
          className={classes.labelText}
          name="title"
          required
          label="Title"
          value={title}
          onBlur={onBlur}
          onChange={(e) => {
            if (e.target.value.length <= 255) {
              handleChange(e);
            }
          }}
          placeholder="Enter training material title"
        />
        <ErrorText
          touchedField={touched.title}
          errorField={errors.title}
        />
        <Typography className={classes.rightText}>
          {(title || '').length}
          /255
        </Typography>
        <CustomSelect
          label="SCORM Type"
          placeholder="Select type"
          options={SCORM_TYPE_SELECT_OPTIONS}
          className={classes.dropdown}
          labelClass={classes.labelClass}
          defaultValue={SCORM_TYPE_SELECT_OPTIONS.find((el) => el.value === scorm_type)}
          required
          onChange={(event) => {
            setFieldValue('scorm_type', event.value, false);
            setTimeout(() => setFieldTouched('scorm_type', true));
            clearScormDetails();
            clearElucidatDetails();
          }}
        />
        <ErrorText
          touchedField={touched.scorm_type}
          errorField={errors.scorm_type}
        />
        {values?.scorm_type && (
          <>
            {
              values?.scorm_type === 'Elucidat' ? (
                <ElucidatFields
                  labelTextClass={classes.labelText}
                  values={values}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  validateProjectId={validateProjectId}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  labelClass={`${classes.labelClass}`}
                  selectBoxClass={classes.dropdown}
                  isValidating={isValidating}
                  overlaySection={classes.overlaySection}
                />
              ) : (
                <ScormLink
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  box_link={box_link}
                  scorm_link={scorm_link}
                />
              )
            }
          </>
        )}
      </Box>
    </Box>
  );
};

ScormDetailsForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    title: PropTypes.string.isRequired,
    file: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    scorm_type: PropTypes.string.isRequired,
    scorm_link: PropTypes.string,
    box_link: PropTypes.string,
  }).isRequired,
  onBlur: PropTypes.func.isRequired,
  touched: PropTypes.shape({
    file: PropTypes.string,
    title: PropTypes.string.isRequired,
    scorm_type: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    file: PropTypes.string,
    title: PropTypes.string.isRequired,
    scorm_type: PropTypes.string,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  validateProjectId: PropTypes.func.isRequired,
  isValidating: PropTypes.bool.isRequired,
  clearElucidatDetails: PropTypes.func.isRequired,
  formHeadingClass: PropTypes.string.isRequired,
  onReplace: PropTypes.func.isRequired,
};

export default ScormDetailsForm;
