import React, {
  useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  FieldArray,
  FormikProvider,
  useField,
} from 'formik';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _every from 'lodash/every';

import ExpandableTableTmsRow from './ExpandableTableTmsRow';
import { MODERATE_LIGHT_GREY } from '../../../stylesheets/colors';
import AlertWarning from '../../../assets/icons/alert-red-warning.svg';
import BulkScormReleaseTableHead from './BulkScormReleaseTableHead';
import { anyScormValidInComponent, isAllScormValidInComponent, checkReleaseData } from '../../../helpers/utils';

const useStyles = makeStyles({
  tableHeadRow: {
    alignItems: 'center',
    padding: '0.5 0',
    marginBottom: '1rem',
    backgroundColor: MODERATE_LIGHT_GREY,
  },
  tableBodyRow: {
    alignItems: 'center',
    padding: '0.5 0',
    marginBottom: '1rem',
    backgroundColor: MODERATE_LIGHT_GREY,
  },
  checkboxFlexBasis: {
    flexBasis: '4%',
  },
  alertMsgWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  alertImage: {
    paddingRight: '0.5rem',
  },
});

const CourseRow = ({
  index, course, expandAll, formik, programId, updateScorm, progress,
  progressReport, isDDAdmin, disabledActions,
}) => {
  const classes = useStyles();

  const { values, setValues } = formik;

  // eslint-disable-next-line no-unused-vars
  const [selectedField, selectedMeta, selectedHelpers] = useField({
    name: `courses[${index}.selected]`,
    type: 'checkbox',
  });

  const handleCourseCheckbox = (event) => {
    selectedHelpers.setValue(event.target.checked);
  };

  useEffect(() => {
    const updateCourses = _map(values?.courses,
      (a) => (a.id === course.id ? {
        ...a,
        selected: a.courseValidRelease ? selectedField.checked : false,
        eachChildScormValidRelease: isAllScormValidInComponent(course?.training_materials),
        training_materials: _map(a.training_materials,
          (scormItem) => ({
            ...scormItem,
            selected: scormItem?.tmValidRelease ? selectedField.checked : false,
          })),
      } : a));
    setValues({
      ...values,
      isAllCourseSelected: _every(updateCourses, ['selected', true]),
      isNoneCourseSelected: _every(updateCourses, ['selected', false]),
      courses: updateCourses,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedField.checked]);

  const updateTableReport = (checked) => {
    const updatedReport = values?.courses?.map((component) => ({
      ...component,
      selected: anyScormValidInComponent(component?.training_materials) ? checked : false,
      courseValidRelease: anyScormValidInComponent(component?.training_materials),
      eachChildScormValidRelease: isAllScormValidInComponent(component?.training_materials),
      training_materials: component.training_materials.map((so) => ({
        ...so,
        courseId: component?.id,
        tmValidRelease: checkReleaseData(so),
        selected: checkReleaseData(so) ? checked : false,
      })),
    }));
    setValues({
      selectAll: checked,
      isAllCourseSelected: _every(updatedReport, ['selected', true]),
      isNoneCourseSelected: _every(updatedReport, ['selected', false]),
      courses: updatedReport,
    });
  };

  useEffect(() => {
    updateTableReport(values.selectAll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.selectAll]);

  return (
    <ExpandableTableTmsRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={course.id}
      course={course}
      courseIndex={index}
      expandAll={expandAll}
      coureseRow={`courses.${index}`}
      formik={formik}
      tableBodyRow={classes.tableHeadRow}
      programId={programId}
      updateScorm={updateScorm}
      progress={progress}
      progressReport={progressReport}
      isDDAdmin={isDDAdmin}
      disabledActions={disabledActions}
    >
      <Grid item xs={1} className={classes.checkboxFlexBasis}>
        <Checkbox
          color="primary"
          id={selectedField.name}
          name={selectedField.name}
          disabled={!course?.courseValidRelease}
          checked={course?.courseValidRelease && selectedField.checked}
          indeterminate={course?.courseValidRelease
            && !course?.eachChildScormValidRelease && selectedField.checked}
          onClick={(event) => handleCourseCheckbox(event)}
        />
      </Grid>
      <Grid item xs={2}>
        <Typography>{course?.name}</Typography>
      </Grid>
      <Grid item xs={1} className={classes.checkboxFlexBasis} />
      <Grid item xs={3}>
        <Box className={classes.alertMsgWrapper}>
          {!course?.eachChildScormValidRelease && <img src={AlertWarning} alt="alert" className={classes.alertImage} />}
          <Typography>{`${_filter(course?.training_materials, { selected: true, tmValidRelease: true }).length}/${course?.training_materials?.length} included for full re-release`}</Typography>
        </Box>
      </Grid>
      <Grid item xs />
      <Grid item xs />
    </ExpandableTableTmsRow>
  );
};

const BulkScormReleaseTable = ({
  formik, programId, updateScorm, progress, progressReport, isDDAdmin, disabledActions,
}) => {
  const [expandAll, setExpandAll] = useState(false);

  const { values } = formik;

  return (
    <Box style={{ width: '100%' }}>
      <FormikProvider value={formik}>
        <BulkScormReleaseTableHead
          values={values}
          expandAll={expandAll}
          setExpandAll={setExpandAll}
        />
        <FieldArray name="courses">
          {() => (
            <>
              {formik?.values?.courses?.length > 0 && formik?.values?.courses?.map((row, idx) => {
                const labelId = `enhanced-table-checkbox-${idx}`;
                return (
                  <CourseRow
                    index={idx}
                    formik={formik}
                    key={row.course_id}
                    course={row}
                    expandAll={expandAll}
                    labelId={labelId}
                    programId={programId}
                    updateScorm={updateScorm}
                    progress={progress}
                    progressReport={progressReport}
                    disabledActions={disabledActions}
                    isDDAdmin={isDDAdmin}
                  />
                );
              })}
            </>
          )}
        </FieldArray>
      </FormikProvider>
    </Box>
  );
};

BulkScormReleaseTable.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.array.isRequired,
  }).isRequired,
  programId: PropTypes.string.isRequired,
  updateScorm: PropTypes.func.isRequired,
  progress: PropTypes.shape({
    done: PropTypes.bool.isRequired,
    percentage: PropTypes.string.isRequired,
  }).isRequired,
  progressReport: PropTypes.array.isRequired,
  isDDAdmin: PropTypes.bool.isRequired,
  disabledActions: PropTypes.bool.isRequired,
};

CourseRow.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.array.isRequired,
    errors: PropTypes.object.isRequired,
    validateForm: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
  }).isRequired,
  course: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    courseValidRelease: PropTypes.bool.isRequired,
    eachChildScormValidRelease: PropTypes.bool.isRequired,
    training_materials: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        original_project_id: PropTypes.oneOfType([
          PropTypes.string.isRequired,
          PropTypes.oneOf([null]),
        ]),
        project_id_status: PropTypes.string.isRequired,
        project_name: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  index: PropTypes.string.isRequired,
  expandAll: PropTypes.bool.isRequired,
  programId: PropTypes.string.isRequired,
  updateScorm: PropTypes.func.isRequired,
  progress: PropTypes.shape({
    done: PropTypes.bool.isRequired,
    percentage: PropTypes.string.isRequired,
  }).isRequired,
  progressReport: PropTypes.array.isRequired,
  isDDAdmin: PropTypes.bool.isRequired,
  disabledActions: PropTypes.bool.isRequired,
};

export default BulkScormReleaseTable;
