import * as Yup from 'yup';
import moment from 'moment';
import { validateEmailSeperatedBySemiColon, isDateValid } from './utils';
import { COMPONENT_CODE_MAX_LIMIT, COMPONENT_TITLE_MAX_LIMIT } from '../constants';

export const ComponentDetailsValidationSchema = (is_skill_lp) => Yup.object({
  name: Yup.string().trim().required('This is a required field').max(255, 'Title should not exceed 255 characters'),
  code: Yup.string().trim().required('This is a required field').max(50, 'Code should not exceed 50 characters'),
  description: Yup.string().trim().required('This is a required field'),
  duration: Yup.string().matches(
    /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
    'Insert time in hh:mm format.',
  ),
  credits: Yup.number().max(1000000000, 'value can not exceed 1000000000 characters'),
  date_begin: Yup.string(),
  date_end: Yup.string().test({
    test(value, ctx) {
      if (ctx.parent.date_begin && value && moment(value).diff(moment(ctx.parent.date_begin), 'days') <= 0) {
        return ctx.createError({ message: 'Start date cannot be same or after End date' });
      }
      return true;
    },
  }),
  additional_fields: Yup.object().shape({
    Offering: Yup.object().shape({
      value: Yup.string().trim().required('This is a required field').nullable('This is a required field'),
    }),
    Portfolio: Yup.object().shape({
      value: Yup.string().trim().required('This is a required field').nullable('This is a required field'),
    }),
    'Course runner': Yup.object().shape({
      value: Yup.string().test({
        test(Obj, ctx) {
          if (Obj && !validateEmailSeperatedBySemiColon(Obj)) {
            return ctx.createError({ message: 'Component Runner should have valid Email ID/s, separated with a semi colon' });
          }
          return true;
        },
      }).nullable(),
    }),
  }),
  ...(is_skill_lp && { skill_id: Yup.number().required('This is a required field') }),
  ...(is_skill_lp && { skill_alias: Yup.string().required('This is a required field') }),
});

export const CopyComponentValidationSchema = Yup.object({
  newComponentTitle: Yup.string().required('This is a required field').max(COMPONENT_TITLE_MAX_LIMIT, `Code should not exceed ${COMPONENT_TITLE_MAX_LIMIT} characters`),
  newComponentCode: Yup.string().required('This is a required field').max(COMPONENT_CODE_MAX_LIMIT, `Code should not exceed ${COMPONENT_CODE_MAX_LIMIT} characters`),
});

export const ImportComponentValidationSchema = [
  Yup.object({
    configId: Yup.string().required('Config Id Is Required'),
    componentId: Yup.string().required('Select one component'),
    isHavingEOP: Yup.boolean(),
    eopSetting: Yup.string().when('isHavingEOP', {
      is: true,
      then: Yup.string().required('Choose one option'),
    }),
  }),
  Yup.object({
    lo: Yup.array(
      Yup.object({
        isScorm: Yup.boolean(),
        elucidatSelect: Yup.string().when(['selected', 'isScorm'], {
          is: (selected, isScorm) => selected && isScorm,
          then: Yup.string().required('Select option from list'),
        }),
        elucidatInput: Yup.string().when(['selected', 'isScorm'], {
          is: (selected, isScorm) => selected && isScorm,
          then: Yup.string().required('Required'),
        }),
      }),
    ),
  }),
  Yup.object({
    newComponentTitle: Yup.string().required('This is a required field').max(COMPONENT_TITLE_MAX_LIMIT, `Title should not exceed ${COMPONENT_TITLE_MAX_LIMIT} characters`),
    newComponentCode: Yup.string().required('This is a required field').max(COMPONENT_CODE_MAX_LIMIT, `Code should not exceed ${COMPONENT_CODE_MAX_LIMIT} characters`),
    userAccess: Yup.string().required('This is a required field'),
  }),
];

export const LearningPlansFiltersValidationSchema = Yup.object({
  enableCreate: Yup.boolean(),
  filter_name: Yup.string().notRequired()
    .when('enableCreate', {
      is: (val) => val === true,
      then: Yup.string().required('This is a required field').max(30, 'Name should not exceed 30 characters'),
      otherwise: Yup.string().notRequired(),
    })
    .when('enableEdit', {
      is: (val) => val === true,
      then: Yup.string().required('This is a required field').max(30, 'Name should not exceed 30 characters'),
      otherwise: Yup.string().notRequired(),
    }),
  elucidat_std_date_from: Yup.date().nullable(true),
  elucidat_std_date_to: Yup.date().nullable(true).when('elucidat_std_date_from', (elucidat_std_date_from, schema) => (elucidat_std_date_from !== null && isDateValid(elucidat_std_date_from) ? schema.min(elucidat_std_date_from, 'End date has to be after than start date') : schema)),
  docebo_std_date_from: Yup.date().nullable(true),
  docebo_std_date_to: Yup.date().nullable(true).when('docebo_std_date_from', (docebo_std_date_from, schema) => (docebo_std_date_from !== null && isDateValid(docebo_std_date_from) ? schema.min(docebo_std_date_from, 'End date has to be after than start date') : schema)),
  closeout_date_from: Yup.date().nullable(true),
  closeout_date_to: Yup.date().nullable(true).when('closeout_date_from', (closeout_date_from, schema) => (closeout_date_from !== null && isDateValid(closeout_date_from) ? schema.min(closeout_date_from, 'End date has to be after than start date') : schema)),
});
