import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';

import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@material-ui/core/Typography';
import { SWITCH_GREY } from '../../stylesheets/colors';
import RedirectArrowLink from '../common/RedirectArrowLink';

const ContentDetailsBlock = ({
  headerText, children, noPaper, fieldRedirectOnLabel, blockHeaderRef,
}) => (
  <Grid item xs={12} md={12}>
    <Card sx={{ overflow: 'visible' }} elevation={noPaper ? 0 : 1}>
      <CardHeader
        ref={blockHeaderRef}
        sx={{ padding: '1rem 3rem', borderBottom: `1px solid ${SWITCH_GREY}` }}
        title={(
          <Box style={{
            display: 'flex',
            alignItems: 'center',
          }}
          >
            <Typography
              variant="subtitle1"
              style={{
                fontSize: '2rem',
              }}
            >
              {headerText}
            </Typography>
            {fieldRedirectOnLabel?.length > 0 && (
              <RedirectArrowLink pathname={fieldRedirectOnLabel} />
            )}
          </Box>
        )}
      />
      <CardContent sx={{ padding: '3rem' }}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          direction="row"
        >
          {children}
        </Grid>
      </CardContent>
    </Card>
  </Grid>
);

ContentDetailsBlock.defaultProps = {
  noPaper: false,
  blockHeaderRef: null,
};

ContentDetailsBlock.propTypes = {
  headerText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  noPaper: PropTypes.bool,
  fieldRedirectOnLabel: PropTypes.string.isRequired,
  blockHeaderRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default ContentDetailsBlock;
