import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { GROUP_ENROLLMENT, snackBarInitialState, UPLOAD_GROUPWORK_ROLES } from '../../constants';
import GroupWorksUploadPage from '../../components/GroupWorksPages/GroupWorksUploadPage';
import CustomSnackbar from '../../components/common/CustomSnackbar';
import AssignmentDetails from '../../components/GroupWorksPages/GroupWorksLandingScreen/AssignmentDetails';
import UserEnrollmentUpload from '../../components/GroupWorksPages/UserEnrollmentUpload';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
  },
});

const GroupWorkPage = ({ match, programMetadata }) => {
  const configId = programMetadata.config_id;
  const programId = Number.parseInt(get(match, 'params.programId'), 10);
  const programSubType = get(match, 'params.programSubType');
  const classes = useStyles();

  const [snackbarObj, setSnackbarObj] = useState(snackBarInitialState);

  let BodyComponent;
  if (programSubType === UPLOAD_GROUPWORK_ROLES) {
    BodyComponent = GroupWorksUploadPage;
  } else if (programSubType === GROUP_ENROLLMENT) {
    BodyComponent = UserEnrollmentUpload;
  } else {
    BodyComponent = AssignmentDetails;
  }

  const bodyDom = (
    <BodyComponent
      programId={programId}
      programSubType={programSubType}
      configId={configId}
      setSnackbarObj={setSnackbarObj}
      match={match}
      programMetadata={programMetadata}
    />
  );

  return (
    <div className={classes.wrapper}>
      {bodyDom}
      <CustomSnackbar snackbarObj={snackbarObj} setSnackbarObj={setSnackbarObj} />
    </div>
  );
};

GroupWorkPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string,
    }),
  }).isRequired,
  programMetadata: PropTypes.shape({
    config_id: PropTypes.string.isRequired,
  }).isRequired,
};

export default GroupWorkPage;
