import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@mui/material/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  BLACK,
  MEDIUM_GREY,
} from '../../stylesheets/colors';
import ConfirmationModal from '../common/ConfirmationModal';
import JournalingDeleteIcon from '../../assets/img/deleteIconJournaling.svg';

const useStyles = makeStyles(() => ({
  wrapper: {
    '&.MuiContainer-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0 6.5rem',
    },

    '& > img': {
      marginBottom: '2.5rem',
    },

    '& > p': {
      textAlign: 'center',
    },

  },
  subHeading: {
    color: BLACK,
    fontWeight: 600,
    marginBottom: '0.75rem',
  },
  infoContentText: {
    color: MEDIUM_GREY,
  },
  modalStyle: {
    minWidth: '70rem',
  },
}));

const DeleteModal = ({
  onClose,
  onConfirmDelete,
  data,
  heading,
  subHeading,
  feature,
  helperText,
}) => {
  const classes = useStyles();
  return (
    <ConfirmationModal
      open
      onClose={onClose}
      modalStyle={classes.modalStyle}
      content={(
        <Container className={classes.wrapper}>
          <img width={100} height={100} src={JournalingDeleteIcon} alt="delete-icon" />
          <Typography variant="body2" component="p" className={classes.subHeading}>{heading}</Typography>
          <Typography variant="body1" component="p" className={`${classes.infoContentText}`}>
            {subHeading}
            {' '}
            <span style={{ fontWeight: 'bold' }}>{feature}</span>
          </Typography>
          <Typography variant="body1" component="p" className={`${classes.infoContentText}`}>
            {helperText}
          </Typography>
        </Container>
      )}
      actions={(
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={() => { onConfirmDelete(data); }}>
            Delete
          </Button>
        </>
      )}
    />
  );
};

DeleteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  feature: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
};

export default DeleteModal;
