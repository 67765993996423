import React, { memo } from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { ERROR_LABEL_RED, ERROR_RED, WHITE } from '../../stylesheets/colors';

const useStyles = makeStyles({
  iconWrapper: {
    height: '2rem',
    width: '6rem',
    backgroundColor: ERROR_LABEL_RED,
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorIcon: {
    color: ERROR_RED,
  },
  indicator: {
    color: WHITE,
  },
  listPadding: {
    paddingLeft: '14rem',
  },
  multiLine: {
    marginTop: '1.5rem',
    marginBottom: '0.75rem',
    paddingLeft: '2rem',
  },
  singleLine: {
    marginBottom: '0.75rem',
    paddingLeft: '2rem',
  },
});

const XlsScanError = ({ errors }) => {
  const classes = useStyles();

  return (
    <List component="nav" aria-label="main" dense classes={{ padding: classes.listPadding }}>
      {errors.map((err, i) => {
        const errRow = err.row || '';
        const errTitle = err.msg_head || '';
        return (
          <ListItem key={err.message}>
            <ListItemIcon className={classes.iconWrapper}>
              <Typography component="span" className={classes.indicator}>
                {errRow ? `Row #${errRow}` : `E${i + 1}`}
              </Typography>
            </ListItemIcon>
            <ListItemText
              classes={{ multiline: errRow ? classes.multiLine : classes.singleLine }}
              primaryTypographyProps={{
                fontSize: '1.5rem',
                fontWeight: '900',
              }}
              primary={errTitle || err.message}
              secondary={errTitle ? err.message : ''}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

XlsScanError.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default memo(XlsScanError, () => true);
