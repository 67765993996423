import React, {
  useState, useEffect, useCallback,
} from 'react';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@material-ui/core/Button';
import Divider from '@mui/material/Divider';
import { internalServerErrorModalLogic } from '../../../containers/common/utils';
import Loader from '../../Loader';
import FileInput from '../../FileInput';
import MainButton from '../../MainButton';
import CustomUploadModal from '../../common/CustomUploadModal';
import InfoOutline from '../../../assets/icons/info-outline.svg';
import ErrorModal from '../../ErrorModal/ErrorModal';
import XlsScanError from '../../common/XlsScanError';
import MetadataUsersOverview from './MetadataUsersOverview';
import ValidationError from '../../../assets/img/validation-error.webp';
import AlertIcon from '../../../assets/icons/alert-icon.svg';
import CheckRoundIcon from '../../../assets/icons/green-round-check.svg';
import ErrorRoundIcon from '../../../assets/icons/error-round.svg';

import {
  ACCORDION_GREY, MCKINSEY_BLUE,
} from '../../../stylesheets/colors';
import {
  validateXLSXApiCall,
} from '../../../containers/common/apis';
import FormattedTypography from '../../common/FormattedTypography';
import { parseValidationResult } from '../../../helpers/xlsxValidation';
import { zeroAppender } from '../../../helpers/formattingHelpers';
import { FEATURE_CLICKED } from '../../../constants';

const useStyles = makeStyles({
  innerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '1rem',
  },
  uploadSection: {
    display: 'flex',
    flexDirection: 'column',
    width: '70%',
  },
  border: {
    marginTop: '2rem',
    border: `0.5px dashed ${MCKINSEY_BLUE}`,
    width: '50%',
  },
  textColor: {
    color: ACCORDION_GREY,
    wordWrap: 'break-word',
  },
  infoText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonIcon: {
    width: '1rem',
    height: '1rem',
    marginRight: '0.5rem',
  },
  leftSpacing: {
    marginLeft: '0.5rem',
  },
});

const MetaDataUsers = ({
  downloadFile, match, uploadXlsx, fetchProgramMetadata, transactionIds,
  getProgress, getReport, onDownloadLog, settingAlertText, downloadXlsTransId,
  getUserMetadata,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const programSubType = get(match, 'params.programSubType');
  const programId = get(match, 'params.programId');
  const initialValidationResult = { status: 'none', errors: [] };
  const [modalState, setModalState] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [progress, setProgress] = useState({ done: true, percentage: 0 });
  const [transactionId, setTransactionId] = useState(null);
  const [pollProgress, setPollProgress] = useState(true);
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState({ done: true, percentage: 0 });
  const [downloadTransactionId, setDownloadTransactionId] = useState(null);
  const [downloadPollProgress, setDownloadPollProgress] = useState(true);
  const [disableDownloadButton, setDisableDownloadButton] = useState(false);
  const [modalSchema, setModalScheme] = useState({
    VALIDATION: {
      component: XlsScanError,
      wrapperProps: {
        logo: <img src={ValidationError} alt="validation error" width={120} height={120} />,
        heading: null,
        primaryBtnText: 'Re-upload File',
        primaryBtnProps: {
          onClick: setModalState.bind(null, null),
        },
        contentDivider: true,
      },
      props: initialValidationResult,
    },
    UPLOAD: {
      component: MetadataUsersOverview,
      wrapperProps: {
        logo: null,
        heading: null,
        primaryBtnText: undefined,
        primaryBtnProps: {
          disabled: true,
          onClick: () => { },
        },
      },
      props: {
        totalGrades: 0,
      },
    },
  });

  const onErrorModalClose = () => {
    setIsErrorModalOpen(false);
  };
  const resetFileUpload = useCallback(
    (e) => {
      setModalScheme((schema) => ({
        ...schema,
        VALIDATION: {
          ...schema.VALIDATION,
          props: initialValidationResult,
        },
      }));
      e.target.value = '';
    },
    [initialValidationResult],
  );

  const generateFormData = useCallback(
    (file) => {
      const formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('program_id', programId);
      return formData;
    },
    [programId],
  );

  const getSrc = (result) => {
    if (result?.fail_count === 0) {
      return CheckRoundIcon;
    } if ((result?.success_count === 0)) {
      return ErrorRoundIcon;
    }
    return AlertIcon;
  };

  const getHeading = (result) => {
    if (result?.fail_count === 0) {
      return (
        <FormattedTypography
          variant="subtitle1"
          body={result?.filename || 'file'}
          suffix="uploaded successfully"
          suffixClassName={classes.leftSpacing}
        />
      );
    } if ((result?.success_count === 0)) {
      return (
        <FormattedTypography
          variant="subtitle1"
          body={result?.filename || 'file'}
          suffix="could not be uploaded"
          suffixClassName={classes.leftSpacing}
        />
      );
    }
    return (
      <FormattedTypography
        variant="subtitle1"
        className={classes.leftSpacing}
        prefix="Found discrepancies in"
        body={result?.filename || 'file'}
      />
    );
  };

  useEffect(() => {
    if (transactionIds.length) {
      setTransactionId(transactionIds[0]);
      setPollProgress(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionIds.length]);

  useEffect(() => {
    // TODO: Support multiple transactions
    let timer = null;
    const pollProgressApi = async () => {
      if (!transactionIds.length || !transactionId) return;
      try {
        const res = await getProgress(transactionId);
        if (res.data.done) {
          const result = await getReport(transactionId);
          if (isFileLoaded) {
            setModalState('UPLOAD');
            setModalScheme((schema) => ({
              ...schema,
              UPLOAD: {
                ...schema.UPLOAD,
                wrapperProps: {
                  ...schema.UPLOAD.wrapperProps,
                  logo: <img src={getSrc(result)} alt="Spreadsheet" width={50} height={50} />,
                  heading: getHeading(result),
                  primaryBtnText: result.fail_count > 0 ? 'Download Log' : undefined,
                  primaryBtnProps: {
                    ...schema.UPLOAD.wrapperProps.primaryBtnProps,
                    disabled: !(result.fail_count > 0),
                    onClick: () => onDownloadLog({ transaction_id: transactionId }),
                  },
                },
                props: {
                  successCount: result.success_count,
                  errorCount: result.fail_count,
                },
              },
            }));
            settingAlertText(`File ${result?.filename} uploaded successfully. Check 'Activity Log' for more details.`);
          }
          setProgress(res.data);
          setPollProgress(false);
          setIsFileLoaded(false);
        } else {
          setProgress(res.data);
          timer = setTimeout(pollProgressApi, 1000);
        }
      } catch (err) {
        timer = internalServerErrorModalLogic(history, err, setIsErrorModalOpen, pollProgressApi);
      }
    };
    pollProgressApi();
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollProgress, transactionId, programSubType, history]);

  useEffect(() => {
    if (downloadXlsTransId.length) {
      setDownloadTransactionId(downloadXlsTransId[0]);
      setDownloadPollProgress(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadXlsTransId]);

  useEffect(() => {
    // TODO: Support multiple transactions
    let timer = null;
    const pollProgressApi = async () => {
      if (!downloadXlsTransId.length || !downloadTransactionId) return;
      try {
        const res = await getProgress(downloadTransactionId);
        if (res.data.done) {
          await downloadFile();
          setDownloadTransactionId(null);
          setDownloadProgress(res.data);
          setDownloadPollProgress(false);
          setDisableDownloadButton(false);
        } else {
          setDownloadProgress(res.data);
          setDisableDownloadButton(true);
          timer = setTimeout(pollProgressApi, 1000);
        }
      } catch (err) {
        setDownloadTransactionId(null);
        timer = internalServerErrorModalLogic(history, err, setIsErrorModalOpen, pollProgressApi);
      }
    };
    pollProgressApi();
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadPollProgress, downloadTransactionId, programSubType, history]);

  const uploadFile = useCallback(
    async (formData) => {
      try {
        // adding heap tracking script to track actual feature click
        window.heap.track(FEATURE_CLICKED, programSubType);

        await uploadXlsx(formData);
        setIsFileLoaded(true);
        fetchProgramMetadata();
      } catch (e) {
        console.error(e);
        setProgress({ done: true, percentage: 100 });
      }
    },
    [programSubType, uploadXlsx, fetchProgramMetadata],
  );

  const onUpload = useCallback(
    async (event) => {
      const file = event.target.files[0];
      const formData = generateFormData(file);
      resetFileUpload(event);
      let result;
      try {
        result = await validateXLSXApiCall(
          'bulk_edit_metadata',
          formData,
        );
      } catch (e) {
        console.error(e);
        // TODO: Show a toast, unable to upload
        return; // Early exit
      }
      const errorMessages = parseValidationResult(result);
      const errorMessageCount = errorMessages.errors?.length || 0;
      const [extension, ...nameParts] = file.name.split('.').reverse();

      batchUpdates(() => {
        setModalScheme((schema) => ({
          ...schema,
          VALIDATION: {
            ...schema.VALIDATION,
            wrapperProps: {
              ...schema.VALIDATION.wrapperProps,
              heading: (
                <FormattedTypography
                  prefix="Scanning file&nbsp;"
                  body={nameParts.join('.')}
                  suffix={`.${extension}`}
                  subHeading={`${zeroAppender(
                    errorMessageCount,
                  )} errors found. Please make the required changes and re-upload your file.`}
                />
              ),
            },
            props: errorMessages,
          },
        }));
      });

      if (errorMessages.errors.length) {
        setModalState('VALIDATION');
        return;
      }

      await uploadFile(formData);
    },
    [generateFormData,
      uploadFile,
      resetFileUpload],
  );

  const shouldShowLoader = ((downloadTransactionId && !downloadProgress.done) || !progress.done);
  const progressValue = downloadTransactionId ? downloadProgress.percentage : progress.percentage;

  const loader = (
    <Loader progress={progressValue} hideCancel />
  );

  const onClickDownloadHandler = () => {
    setDisableDownloadButton(true);
    getUserMetadata();
  };

  const body = (
    <Container className={classes.innerWrapper} disableGutters maxWidth={false}>
      <Box className={classes.uploadSection}>
        <Typography variant="subtitle1">Download the Existing Metadata file and upload it after updating</Typography>
        <Button
          variant="contained"
          style={{ marginTop: '1rem', marginBottom: '0.5rem', width: '20%' }}
          color="primary"
          onClick={onClickDownloadHandler}
          disabled={disableDownloadButton}
        >
          Download XLS file
        </Button>
        <Box className={classes.infoText}>
          <img src={InfoOutline} alt="infoIcon" className={classes.buttonIcon} />
          <Typography variant="body1" className={classes.textColor}>
            Download the Existing Metadata of all the enrolled users in the Program. The same can be
            updated and reloaded to update the Metadata
          </Typography>
        </Box>
        <Divider light flexItem sx={{ mt: '2rem', width: '50%' }} />
        <Box className={classes.border}>
          <FileInput idx={0} onFileInput={onUpload}>
            <MainButton
              title="Upload XLS file"
              subtitle="We will update metadata for uploaded users"
            />
          </FileInput>
        </Box>
      </Box>
    </Container>
  );

  const ModalComponent = modalSchema[modalState] ? modalSchema[modalState].component : null;
  return (
    <Box sx={{ padding: '2rem 0' }}>
      {shouldShowLoader ? loader : body}
      {ModalComponent && (
        <CustomUploadModal
          open={modalSchema[modalState] !== undefined}
          onClose={() => setModalState(null)}
          className={classes.modalHeight}
          breakpoint="sm"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...modalSchema[modalState].wrapperProps}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <ModalComponent {...modalSchema[modalState].props} />
        </CustomUploadModal>
      )}
      <ErrorModal
        open={isErrorModalOpen}
        onClose={onErrorModalClose}
        message="Could not updte users due to some technical error."
      />
    </Box>
  );
};

MetaDataUsers.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string,
    }),
  }).isRequired,
  downloadFile: PropTypes.func.isRequired,
  uploadXlsx: PropTypes.func.isRequired,
  fetchProgramMetadata: PropTypes.func.isRequired,
  getProgress: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
  onDownloadLog: PropTypes.func.isRequired,
  settingAlertText: PropTypes.func.isRequired,
  transactionIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  getUserMetadata: PropTypes.func.isRequired,
  downloadXlsTransId: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default MetaDataUsers;
