import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';

import makeStyles from '@material-ui/core/styles/makeStyles';

import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _every from 'lodash/every';

import _flatten from 'lodash/flatten';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Divider from '@mui/material/Divider';

import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import FormattedTypography from '../common/FormattedTypography';

import ConfirmModal from '../common/ConfirmModal';
import CustomPrompt from '../common/CustomPrompt';
import ReplicationCoursesTable from './ReplicationCoursesTable';
import ReplicationConfirmModal from './ReplicationConfirmModal';
import { MODERATE_DARK_GREY } from '../../stylesheets/colors';
import CountCard from '../common/CountCard';
import RestrictReplicationModal from './RestrictReplicationModal';
import { REPLICATION_COMPONENT_LIMIT, REPLICATION_TOTAL_LO_LIMIT, ELUCIDAT_SCORM_TYPE } from '../../constants';
import LimitExceedValidationMsg from './LimitExceedValidationMsg';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  sidebarWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.27,
  },
  bodyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    // flex: 0.5,
    // alignItems: 'center',

    // fontSize: '1rem',
    // color: DARK_MEDIUM_GREY,
  },
  headerButtonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.2,
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '1rem 2rem',
  },
  coursesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.83,
  },
  button: {
    marginLeft: '1rem',
    maxHeight: '3rem',
  },
  headingContainer: {
    display: 'inline',
    padding: '3rem',
    paddingLeft: '2rem',
    flex: '0.8',
  },
  headingText: {
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: '2rem 0',
  },
  boldText: {
    fontWeight: 800,
  },
  lightText: {
    color: MODERATE_DARK_GREY,
  },
});

const ReplicationConfigureCourses = (props) => {
  const {
    className,
    report,
    checkElucidatValidity,
    onProceed,
    destinationConfigId,
    shouldProceed,
    channelConfig,
    programId,
  } = props;

  const classes = useStyles();
  // const [proceedDisabled, setProceedDisabled] = useState(false);
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [tableData, setTableData] = useState({ selectAll: true, courses: [] });
  const [component, setComponent] = useState(0);
  const [trainingMaterials, setTrainingMaterials] = useState(0);
  const [exceedLimitComponents, setExceedLimitComponents] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [showRestrictReplicationModal, setShowRestrictReplicationModal] = useState(false);

  const history = useHistory();

  const replicateValidationSchema = Yup.object({
    courses: Yup.array(
      Yup.object({
        lo: Yup.array(
          Yup.object({
            // selected: Yup.boolean().required('it reuire lo'),
            isScorm: Yup.boolean(),
            elucidatSelect: Yup.string().when(['selected', 'isScorm'], {
              is: (selected, isScorm) => selected && isScorm,
              then: Yup.string().required('Select option from list'),
            }),
            elucidatInput: Yup.string().when(['selected', 'isScorm'], {
              is: (selected, isScorm) => selected && isScorm,
              then: Yup.string().required('Required'),
            }),
          }),
        ),
      }),
    ),
  });

  const formik = useFormik({
    initialValues: tableData,
    enableReinitialize: true,
    validationSchema: replicateValidationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      setModalOpen(false);
      onProceed({
        ...values,
        courses: values?.courses.map((course) => ({
          ...course,
          lo: course.lo.map((lo) => ({
            ...lo,
            new_project_id: lo?.elucidatInput,
            ...(lo?.type?.includes('scorm') && lo?.additionalFolders) && {
              selected_folders: lo?.additionalFolders && _map(lo?.additionalFolders, 'value'),
            },
          })),
        })),
      });
    },
  });

  const {
    handleSubmit,
    setStatus,
    setErrors,
    values,
  } = formik;

  const checkScorm = (loType, scormType) => (!!((loType?.includes('scorm') && scormType === ELUCIDAT_SCORM_TYPE)));

  const componentCountExceed = component > REPLICATION_COMPONENT_LIMIT;
  const eachComponentTmCountExceed = exceedLimitComponents > 0;
  const totalLoCountExceed = component + trainingMaterials > REPLICATION_TOTAL_LO_LIMIT;

  const updateTableData = useCallback(
    (data, checked) => {
      let standardPagesCount = 0;
      let reviewPagesCount = 0;
      const updatedReport = data?.courses.map((course) => {
        let courseObj = {
          pageType: '',
          pageTypeLoIndex: null,
        };
        return ({
          ...course,
          selected: checked,
          isCourseValidated: false,
          lo: course.lo.map((lo, index) => {
            const { type, page_type = '', sub_type = '' } = lo;
            if (type === 'lti' && sub_type === 'journal') {
              courseObj = {
                pageType: page_type,
                pageTypeLoIndex: index,
              };
              // Calculating standard and review pages length
              if (page_type === 'standard') standardPagesCount += 1;
              else if (page_type === 'review') reviewPagesCount += 1;
            }
            return ({
              ...lo,
              selected: checked,
              isValidating: false,
              isScorm: !!checkScorm(lo?.type, lo?.scorm_type),
              ...(!!checkScorm(lo?.type, lo?.scorm_type) && { elucidatSelect: '' }),
              ...(!!checkScorm(lo?.type, lo?.scorm_type) && { elucidatInput: '' }),
              ...(!!checkScorm(lo?.type, lo?.scorm_type) && { isValidating: false }),
            });
          }),
          ...courseObj,
        });
      });
      setTableData({
        ...data, selectAll: checked, courses: updatedReport, standardPagesCount, reviewPagesCount,
      });
    },
    [],
  );

  useEffect(() => {
    updateTableData(report, true);
  }, [report, updateTableData]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    setStatus(_map(tableData?.courses,
      (course) => ({
        isValidate: false,
        lo: _map(course.lo,
          () => ({ isValidate: false })),
      })));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  useEffect(() => {
    const selectedComponents = _filter(values?.courses, { selected: true });
    const trainingMaterialList = _filter(_flatten(_map(values?.courses, 'lo')), { selected: true });
    const LimitExceededComponents = _filter(values?.courses, { isTmLimitExceed: true });
    batchUpdates(() => {
      setExceedLimitComponents(LimitExceededComponents.length);
      setTrainingMaterials(trainingMaterialList.length);
      setComponent(selectedComponents.length);
    });
  }, [values]);

  const closeModal = useCallback(() => {
    setAlertModalOpen(false);
  }, [setAlertModalOpen]);

  const onConfirm = useCallback(() => {
    closeModal();
    window.location.reload();
  }, [closeModal]);

  const onConfirmProceed = () => {
    setErrors({});
    handleSubmit();
  };

  const limitExceeded = componentCountExceed || eachComponentTmCountExceed || totalLoCountExceed;

  const hanldeProceed = () => {
    const { standardPagesCount, reviewPagesCount, courses } = values;
    let hasSelectedStandardLo = false;
    let hasSelectedReviewLo = false;
    if (standardPagesCount && reviewPagesCount) {
      const standardPageCourses = _filter(courses, { pageType: 'standard' });
      for (let i = 0; i < standardPagesCount; i += 1) {
        const courseObj = standardPageCourses[i];
        if (courseObj?.lo[courseObj?.pageTypeLoIndex]?.selected) {
          hasSelectedStandardLo = true;
          break;
        }
      }
      if (!hasSelectedStandardLo) {
        const reviewPageCourses = _filter(courses, { pageType: 'review' });
        for (let i = 0; i < reviewPagesCount; i += 1) {
          const courseObj = reviewPageCourses[i];
          if (courseObj?.lo[courseObj?.pageTypeLoIndex]?.selected) {
            hasSelectedReviewLo = true;
            break;
          }
        }
      }
    }

    if (hasSelectedReviewLo && !hasSelectedStandardLo) setShowRestrictReplicationModal(true);
    else setModalOpen(true);
  };

  const getCard = (item, count, showAlert = false) => (
    <CountCard text={item} value={count} isError={showAlert} />
  );

  return (
    <Paper className={`${classes.wrapper} ${className}`}>
      {showRestrictReplicationModal && (
        <RestrictReplicationModal
          onClose={() => { setShowRestrictReplicationModal(false); }}
        />
      )}
      <Container disableGutters className={classes.headerWrapper}>
        <FormattedTypography
          containerClassName={classes.headingContainer}
          className={classes.headingText}
          prefixClassName={classes.lightText}
          suffixClassName={classes.lightText}
          variant="subtitle2"
          prefix="All selected learning objects will be included for replication. Please uncheck any learning object to exclude it."
          body="Maximum of 180"
          suffix="learning objects can be replicated at a time."
        />
        <Box className={classes.headerButtonsWrapper}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => setAlertModalOpen(true)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={hanldeProceed}
            className={classes.button}
            disabled={limitExceeded
              || !_every(values?.courses, { isCourseValidated: true })}
          >
            Proceed
          </Button>
        </Box>
      </Container>
      <Divider light flexItem sx={{ mb: '2rem', textAlign: 'center' }} variant="middle" />
      <Container>
        <Typography variant="subtitle1" className={classes.boldText}>Learning Objects to include for Replication</Typography>
        <Box className={classes.cardsContainer}>
          {getCard('Components', component, componentCountExceed)}
          {getCard('Training Materials', trainingMaterials, eachComponentTmCountExceed)}
          {getCard('Total Learning Objects', component + trainingMaterials, totalLoCountExceed)}
        </Box>
        <Box>
          <LimitExceedValidationMsg
            componentCountExceed={componentCountExceed}
            eachComponentTmCountExceed={eachComponentTmCountExceed}
            totalLoCountExceed={totalLoCountExceed}
            exceedLimitComponents={exceedLimitComponents}
          />
        </Box>
      </Container>
      <Container>
        <ReplicationCoursesTable
          report={tableData}
          formik={formik}
          checkElucidatValidity={checkElucidatValidity}
          destinationConfigId={destinationConfigId}
          programId={programId}
        />
      </Container>
      <ReplicationConfirmModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        report={values}
        destinationConfigId={destinationConfigId}
        onConfirm={onConfirmProceed}
        channelConfig={channelConfig}
      />
      <ConfirmModal
        open={alertModalOpen}
        message="All your changes until now will be lost. Are you sure you want to revert the program to its original state and start from the beginning?"
        onClose={closeModal}
        onConfirm={onConfirm}
      />
      <CustomPrompt
        message="All your changes until now will be lost. Are you sure to leave this page?"
        navigate={(path) => history.replace(path)}
        shouldBlockNavigation={() => !shouldProceed}
      />
    </Paper>
  );
};

ReplicationConfigureCourses.defaultProps = {
  className: '',
};

ReplicationConfigureCourses.propTypes = {
  className: PropTypes.string,
  report: PropTypes.shape({
    learning_plan: PropTypes.shape({}).isRequired, // Unused for now
    courses: PropTypes.arrayOf(
      PropTypes.shape({
        course_id: PropTypes.number.isRequired,
        course_name: PropTypes.string.isRequired,
        lo: PropTypes.arrayOf(
          PropTypes.shape({
            lo_id: PropTypes.number.isRequired,
            original_project_id: PropTypes.oneOfType([
              PropTypes.string.isRequired,
              PropTypes.oneOf([null]),
            ]),
            project_id_status: PropTypes.string.isRequired,
            project_name: PropTypes.string.isRequired,
          }),
        ),
      }),
    ).isRequired,
  }).isRequired,
  checkElucidatValidity: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  shouldProceed: PropTypes.bool.isRequired,
  destinationConfigId: PropTypes.string.isRequired,
  channelConfig: PropTypes.array.isRequired,
  programId: PropTypes.string.isRequired,
};

export default ReplicationConfigureCourses;
