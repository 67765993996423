import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CancelToken, isCancel } from 'axios';
import _debounce from 'lodash/debounce';
import _map from 'lodash/map';
import { useFormik } from 'formik';
import Container from '@mui/material/Container';
import { useHistory } from 'react-router-dom';
import { getCoursesAndValidateLp, getTrainingMaterials, validateCourseCode } from '../../../containers/ContentManagement/apis';
import { checkValidityOfElucidatPid } from '../../../containers/common/apis';
import CustomUploadModal from '../../common/CustomUploadModal';
import FormattedTypography from '../../common/FormattedTypography';
import SelectLearningPlan from './SelectLearningPlan';
import ConfigureComponent from './ConfigureComponent';
import ReviewComponent from './ReviewComponent';
import Loading from '../../common/Loading';
import {
  THREE_NUMBER_STEPS, ROUTE_REPORT,
  CONTENT_MANAGEMENT, ELUCIDAT_SCORM_TYPE,
} from '../../../constants';
import { getErrorMessage } from '../../../helpers/apiHelper';
import WizardStepper from '../../common/WizardStepper';
import { ImportComponentValidationSchema } from '../../../helpers/validationHelper';

const checkScorm = (loType, scormType) => (!!((loType?.includes('scorm') && scormType === ELUCIDAT_SCORM_TYPE)));

const ImportComponentCopy = ({
  open, onClose, programId, targetLpCode, triggerImport, eopComponent, importLoading,
  fetchContentApi,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isConfigLoading, setIsConfigLoading] = useState(false);
  const [lpDetails, setLpDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tmList, setTmList] = useState([]);
  const [validateCourseError, setValidateCourseError] = useState('');
  const history = useHistory();

  useEffect(() => {
    setLoading(importLoading);
  }, [importLoading]);

  const fetchTrainingMaterilas = useCallback(
    async (configId, newCourseId, sourceProgramId) => {
      try {
        setLoading(true);
        setValidateCourseError('');
        const response = await getTrainingMaterials(newCourseId, programId, sourceProgramId);
        if (response?.data?.success) {
          setLoading(false);
          setTmList(response?.data?.data);
          setActiveStep(1);
        } else {
          setValidateCourseError(response?.data?.message);
          setActiveStep(0);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const next = (values) => {
    // const data = validateConfigId(values?.configId)
    if (activeStep === 0) {
      fetchTrainingMaterilas(
        lpDetails.docebo_lp_id, values?.componentId, Number(lpDetails?.program_id),
      );
    } else if (activeStep === 1) {
      setActiveStep(2);
    }
  };

  const onImport = (values) => {
    triggerImport({
      new_course_code: values?.newComponentCode,
      new_course_title: values?.newComponentTitle,
      new_course_status: values?.userAccess,
      target_lp_id: Number(programId),
      target_lp_code: targetLpCode,
      source_course_id: values?.componentId,
      course_tms: values?.lo?.map((lo) => ({
        ...lo,
        new_project_id: lo?.elucidatInput,
        ...(lo?.type?.includes('scorm') && lo?.additionalFolders) && {
          selected_folders: lo?.additionalFolders && _map(lo?.additionalFolders, 'value'),
        },
      })),
      program_id: Number(programId),
      source_program_id: Number(lpDetails?.program_id),
      eop_transfer: values?.dropdownDefaultValue?.isEop && values?.eopSetting ? values?.eopSetting === 'true'
        : (!!values?.dropdownDefaultValue?.isEop),
    });
  };

  const cancelTokenSource = {};

  const formik = useFormik({
    initialValues: {
      configId: '',
      componentId: '',
      isHavingEOP: false,
      lo: [],
      newComponentTitle: '',
      newComponentCode: '',
      userAccess: '',
      eopSetting: '',
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: ImportComponentValidationSchema[activeStep],
    onSubmit: (values, bag) => {
      if (activeStep === 2) {
        // eslint-disable-next-line consistent-return
        const validateAndSubmit = async () => {
          try {
            const resp = await validateCourseCode({
              new_course_code: values?.newComponentCode,
              target_lp_code: targetLpCode,
            });
            if (resp?.data?.success === true) {
              return onImport(values);
            }
            bag.setErrors({ newComponentCode: resp?.data?.message });
          } catch (err) {
            bag.setErrors({ newComponentCode: getErrorMessage(err) });
          }
        };
        validateAndSubmit();
      }
      next(values);
      bag.setSubmitting(false);
    },
  });

  const {
    handleSubmit, handleChange, values, errors, touched,
    setErrors, setFieldValue, setValues, resetForm,
  } = formik;

  useEffect(() => {
    if (tmList?.length > 0) {
      setValues({
        ...values,
        lo: tmList.map((tm) => ({
          ...tm,
          selected: !((tm?.type?.includes('scorm') && tm?.scorm_type === null)),
          isValidating: false,
          isAllTmsValidated: false,
          isScorm: !!checkScorm(tm?.type, tm?.scorm_type),
          ...(!!checkScorm(tm?.type, tm?.scorm_type) && { elucidatSelect: '' }),
          ...(!!checkScorm(tm?.type, tm?.scorm_type) && { elucidatInput: '' }),
          ...(!!checkScorm(tm?.type, tm?.scorm_type) && { isValidating: false }),
        })),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmList]);

  const beforeClose = () => {
    resetForm();
    onClose();
  };

  const onCheckInner = useCallback(
    async (configText) => {
      // Cancel old request
      if (cancelTokenSource[programId]) {
        cancelTokenSource[programId].cancel();
      }
      try {
        setIsConfigLoading(true);
        setLpDetails({});
        cancelTokenSource[programId] = CancelToken.source();
        if (configText === targetLpCode) {
          setErrors({ ...errors, configId: 'Cannot select current Learning plan for importing a component.' });
        } else {
          const response = await getCoursesAndValidateLp(
            configText, cancelTokenSource[programId].token,
          );

          if (response.data.success) {
            setErrors({});
            setLpDetails(response.data.data);
          } else {
            setLpDetails({});
            setErrors({ ...errors, configId: 'No Learning Plan found with this ID. Please try another one.' });
          }
        }
      } catch (e) {
        if (!isCancel(e)) {
          setErrors({ ...errors, configId: 'Something went wrong' });
        }
      } finally {
        setIsConfigLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const debouncedChange = useCallback(_debounce(onCheckInner, 500), [onCheckInner]);

  const handleBlur = useCallback((textFieldValue) => {
    if (textFieldValue) {
      onCheckInner(textFieldValue);
    }
  }, [onCheckInner]);

  const onRefresh = async (lpProgramId) => {
    try {
      setLoading(true);
      const resp = await fetchContentApi({ isRefresh: true, programId: lpProgramId });
      if (resp?.data?.success === true) {
        onCheckInner(lpDetails?.config_id);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (validateCourseError.length) {
      setErrors({ ...errors, componentId: validateCourseError });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateCourseError]);

  const redirectToCourse = () => {
    beforeClose();
    history.push(`/${ROUTE_REPORT}/${lpDetails?.program_id}/${CONTENT_MANAGEMENT}?feature=view-course&id=${values?.doceboCourseId}`);
  };

  return (
    <>
      <CustomUploadModal
        open={open}
        onClose={beforeClose}
        heading={(
          <DialogTitle>
            <FormattedTypography
              variant="subtitle1"
              body="Import a Component Copy"
              subHeading="Choose a component from any learning plan on the platform.
             A copy of the component and it's training materials will be created into this learning plan."
            />
          </DialogTitle>
        )}
        headingDivider
        disableGutters
        contentDivider
        primaryBtnText={activeStep < 2 ? 'Next' : 'Import'}
        primaryBtnProps={
          {
            onClick: () => handleSubmit(),
            disabled: !!((activeStep === 0 && lpDetails?.is_locked)
              || (activeStep === 1 && !values?.isAllTmsValidated)),
          }
        }
        secondaryBtnText={activeStep !== 0 && 'Back'}
        secondaryBtnProps={
          { onClick: () => setActiveStep(activeStep - 1) }
        }
      >
        <Container sx={{ width: '100%' }}>
          <WizardStepper steps={THREE_NUMBER_STEPS} activeStep={activeStep} />
          {(() => {
            if (loading) {
              return (<Loading />);
            }
            if (activeStep === 1) {
              return (
                <ConfigureComponent
                  formik={formik}
                  checkElucidatValidity={checkValidityOfElucidatPid}
                  targetLpCode={targetLpCode}
                  redirectToCourse={redirectToCourse}
                />
              );
            } if (activeStep === 2) {
              return (
                <ReviewComponent
                  formik={formik}
                  targetLpCode={targetLpCode}
                  handleChange={handleChange}
                />
              );
            }
            return (
              <SelectLearningPlan
                values={values}
                errors={errors}
                touched={touched}
                lpDetails={lpDetails}
                setValues={setValues}
                handleChange={handleChange}
                onBlur={handleBlur}
                setFieldValue={setFieldValue}
                isConfigLoading={isConfigLoading}
                debouncedChange={debouncedChange}
                eopComponent={eopComponent}
                targetLpCode={targetLpCode}
                onRefresh={onRefresh}
              />
            );
          })()}
        </Container>
      </CustomUploadModal>
    </>
  );
};

ImportComponentCopy.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
  targetLpCode: PropTypes.string.isRequired,
  triggerImport: PropTypes.func.isRequired,
  eopComponent: PropTypes.object.isRequired,
  importLoading: PropTypes.bool.isRequired,
  fetchContentApi: PropTypes.func.isRequired,
};

export default ImportComponentCopy;
