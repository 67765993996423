import FileDownload from 'js-file-download';
import config from '../../config';
import { GROUPWORK_GROUPS } from '../../constants';
import request from '../../helpers/apiHelper';

export const validateForm = async (payload) => {
  const url = `${config.ROUTES.VALIDATE_JSON}/${GROUPWORK_GROUPS}`;
  const method = 'POST';

  const data = {
    program_id: payload.programId,
    // config_id: payload.configId,
    assignment_name: payload.assignmentName,
    starting_group_number: payload.startingAt,
    num_of_groups: payload.count,
  };

  const options = { url, method, data };
  const result = await request(options);
  return result;
};

export const createGroupworkGroup = async (payload) => {
  const url = `${config.ROUTES.GW_GROUPS}/bulk`;
  const method = 'POST';

  const data = {
    program_id: payload.programId,
    // config_id: payload.configId,
    // assignment_name: payload.assignmentName,
    starting_group_number: payload.startingAt,
    num_of_groups: payload.count,
  };

  const options = { url, method, data };
  const result = await request(options);
  return result;
};

export const createRemoveGroupWorkTM = async (data, method) => {
  const url = `${config.ROUTES.GW}/lti/paxview`;
  const options = { url, method, data };
  const result = await request(options);
  return result;
};

export const removeGroupWorkTM = async ({ docebo_course_id }) => {
  const url = `${config.ROUTES.GW}/lti/delete_paxview/${docebo_course_id}`;
  const options = { url, method: 'DELETE' };
  const result = await request(options);
  return result;
};

export const updateAssignmentName = async (payload) => {
  const url = `${config.ROUTES.GW_GROUPS}`;
  const method = 'PUT';

  const data = {
    program_id: payload.programId,
    transaction_id: payload.transactionId,
    assignment_name: payload.assignmentName,
    new_assignment_name: payload.newAssignmentName,
  };

  const options = { url, method, data };
  const result = await request(options);
  return result;
};

export const updateGroupworkGroup = async (payload) => {
  const url = `${config.ROUTES.GW_GROUPS}`;
  const method = 'PUT';

  const data = {
    program_id: payload.programId,
    transaction_id: payload.transactionId,
    assignment_name: payload.assignmentName,
    starting_group_number: payload.startingAt,
    num_of_groups: payload.count,
  };

  const options = { url, method, data };
  const result = await request(options);
  return result;
};

export const downloadGwFile = async (programId) => {
  const url = new URL(`${config.ROUTES.GROUPWORK_MANAGEMENT}/roles_download/${programId}`);
  const options = { url, method: 'GET', responseType: 'blob' };
  const response = await request(options);
  const { data } = response;
  FileDownload(data, `groupwork${programId}.xlsx`);
};

export const downloadGroupEnrollmentFile = async (programId) => {
  const url = new URL(`${config.ROUTES.GROUP_ENROLLMENT}/download/${programId}`);
  const options = { url, method: 'GET', responseType: 'blob' };
  const response = await request(options);
  const { data } = response;
  FileDownload(data, `group_enrollment_${programId}.xlsx`);
};

export const uploadXlsx = async (programSubType, data) => {
  const url = `${config.ROUTES.AUTOMATION}/${programSubType}`;
  const options = { url, method: 'POST', data };
  const result = await request(options);
  return result;
};

export const downloadLogForGW = async ({
  transactionId, configId, programSubType, createdAt,
}) => {
  const url = `${config.ROUTES.GROUPWORK_MANAGEMENT}/download_error_log_for_upload/${transactionId}`;
  const options = { url, method: 'GET', responseType: 'blob' };
  const result = await request(options);
  const { data } = result;
  const createdAtFormatted = new Date(createdAt).toDateString();
  // https://stackoverflow.com/a/41940307/1217998
  FileDownload(data, `${configId}: ${programSubType} - ${createdAtFormatted}.xlsx`);
  return result;
};

export const getAssignments = async (programId) => {
  const url = `${config.ROUTES.GW}/${programId}`;
  const options = { url, method: 'GET' };
  const result = await request(options);
  return result.data;
};

export const getGroupsForAssignment = async ({
  searchText, searchType, currentPage, cancelToken, data, all_groups_info,
}) => {
  const url = `${config.ROUTES.GW}/groups?page=${currentPage}&search=${encodeURIComponent(
    searchText,
  )}&search_type=${searchType}&all_groups_info=${all_groups_info}`;
  const options = {
    url, method: 'POST', cancelToken, data,
  };
  const result = await request(options);
  return result?.data;
};

export const editGroupDetails = async ({ data }) => {
  const url = `${config.ROUTES.GROUPWORK_MANAGEMENT}/update_ta`;
  const options = { url, method: 'PUT', data };
  const result = await request(options);
  return result?.data;
};

export const getGroupsMetadataForAssignment = async ({ data, all_groups_info }) => {
  const url = `${config.ROUTES.GW}/groups?&all_groups_info=${all_groups_info}`;
  const options = {
    url, method: 'POST', data,
  };
  const result = await request(options);
  return result?.data;
};

export const moveMemberAcrossGroup = async ({ data }) => {
  const url = `${config.ROUTES.GROUPWORK_MANAGEMENT}/move_user`;
  const options = { url, method: 'PUT', data };
  const result = await request(options);
  return result?.data;
};

// This API is used to update GC i.ie to both assign and un-assign
export const makeMemberGc = async ({ data }) => {
  const url = `${config.ROUTES.GROUPWORK_MANAGEMENT}/update_gc`;
  const options = { url, method: 'PUT', data };
  const result = await request(options);
  return result?.data;
};

export const removeMemberFromGroup = async ({ data }) => {
  const url = `${config.ROUTES.GROUPWORK_MANAGEMENT}/remove_member`;
  const options = { url, method: 'POST', data };
  const result = await request(options);
  return result?.data;
};
