import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@material-ui/core/Typography';
import { MODERATE_DARK_GREY, MCKINSEY_BLUE } from '../../stylesheets/colors';
import InfoTooltip from '../common/InfoTooltip';
import LinkButton from '../common/LinkButton';
import Select from '../common/SelectMui';
import CustomSwitch from '../common/CustomSwitch';
import { USER_ACCESS_OPTIONS as options } from '../../constants';

const useStyles = makeStyles({
  sideWrapper: {
    height: '100%',
    padding: '1rem 0',
  },
  formLable: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formMandatory: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '1rem',
  },
  switchBtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '7px',
  },
  userAccessWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  editButtonBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  rightBorderBtn: {
    borderRight: '0.15rem solid',
  },
  accessInfoWrapper: {
    padding: '0.5rem 0',
  },
  noTextTransform: {
    textTransform: 'none',
  },
  disabledText: {
    opacity: 0.3,
  },
});

const ContentDetailsSidebar = ({
  label, icon, editUserAccess, isUserAccessEdit, userAccessvalue, updateUserAccess, isMandatory,
}) => {
  const classes = useStyles();

  const [userAccess, setUserAccess] = useState(options.find((el) => el.value === userAccessvalue));
  const [isRequired, setIsRequired] = useState(!!isMandatory);

  useEffect(() => {
    setUserAccess(options.find((el) => el.value === userAccessvalue));
  }, [isUserAccessEdit, userAccessvalue]);

  useEffect(() => {
    if (!isUserAccessEdit) {
      setIsRequired(!!isMandatory);
    }
  }, [isMandatory, isUserAccessEdit]);

  const handleChange = (value) => {
    setUserAccess(value);
  };

  const userAccessInfo = (type, info) => (
    <Box className={classes.accessInfoWrapper}>
      <Typography
        variant="h3"
        className={classes.noTextTransform}
        color="inherit"
      >
        {type}
        :
      </Typography>
      <Typography
        className={classes.noTextTransform}
        style={{ fontSize: '0.88rem', lineHeight: '1.25rem' }}
        variant="body1"
        color="inherit"
      >
        {info}
      </Typography>
    </Box>
  );

  return (
    <Container>
      <Box className={classes.formLable}>
        <Box className={classes.formLable}>
          <Typography
            component="p"
            style={{
              fontSize: '1.25rem', color: MODERATE_DARK_GREY, textTransform: 'unset', padding: '0.25rem 0', marginRight: '0.5rem',
            }}
          >
            User Access
          </Typography>
          <InfoTooltip
            title={(
              <>
                <Typography variant="body1" color="inherit">
                  Types of access (Docebo)
                </Typography>
                {userAccessInfo('Published', 'Component is available and searchable for enrolled users')}
                {userAccessInfo('Locked', 'Component is unvailable for anyone, including assigned users but is always available in Admin areas.')}
              </>
            )}
          />
        </Box>
        {
          isUserAccessEdit ? (
            <Box className={classes.editButtonBox}>
              <LinkButton text="Cancel" onClick={editUserAccess} />
              <Divider sx={{ height: 14, backgroundColor: MCKINSEY_BLUE }} orientation="vertical" flexItem light />
              <LinkButton text="Save" onClick={() => updateUserAccess(userAccess.value, isRequired)} />
            </Box>
          ) : (
            <Box className={classes.editButtonBox}>
              <LinkButton text="Edit" onClick={editUserAccess} />
            </Box>
          )
        }
      </Box>
      {
        isUserAccessEdit ? (
          <Box className={classes.userAccessWrapper} style={{ justifyContent: 'space-between' }}>
            <Select
              name="user-access"
              defaultValue={options.find((el) => el.value === userAccess.value)}
              options={options}
              onChange={(v) => handleChange(v)}
              isMulti={false}
            />
          </Box>
        ) : (
          <Box className={classes.userAccessWrapper}>
            <img src={icon} alt="img" height={15} width={15} style={{ marginRight: '0.5rem' }} />
            <Typography>
              {label}
            </Typography>
          </Box>
        )
      }
      <Box className={classes.horizontalAlignment}>
        <Box className={classes.formMandatory}>
          <Typography
            component="p"
            style={{
              fontSize: '1.25rem', color: MODERATE_DARK_GREY, textTransform: 'unset', padding: '0.25rem 0', marginRight: '0.5rem',
            }}
          >
            Mandatory
          </Typography>
          <InfoTooltip
            title={(
              <>
                {userAccessInfo('Mandatory (Yes)', 'To indicate whether the course is required to mark the learning plan as completed on Docebo.')}
                {userAccessInfo('Mandatory (No) ', 'To indicate whether the course is not required to mark the learning plan as completed on Docebo.')}
              </>
            )}
          />
        </Box>
        <Box className={classes.switchBtn}>
          <CustomSwitch
            checked={isRequired}
            onChange={(event) => setIsRequired(event.target.checked)}
            name="markedDeletion"
            disabled={!isUserAccessEdit}
          />
          <Typography className={`${!isUserAccessEdit ? classes.disabledText : ''}`} component={'span'} variant="body1" color="inherit" disabled={!isUserAccessEdit}>
            {isRequired ? 'Yes' : 'No' }
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

ContentDetailsSidebar.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isUserAccessEdit: PropTypes.bool.isRequired,
  editUserAccess: PropTypes.func.isRequired,
  updateUserAccess: PropTypes.func.isRequired,
  userAccessvalue: PropTypes.bool.isRequired,
  isMandatory: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default ContentDetailsSidebar;
