import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider } from '@mui/material';
import _map from 'lodash/map';
import moment from 'moment';
import { ContentDetailsSection, ContentDetailsLabel } from '../ContentManagement/ContentDetailsSection';
import ContentDetailsBlock from '../ContentManagement/ContentDetailsBlock';
import TableRC from '../common/TableRC';
import LoadingCircle from '../common/LoadingCircle/LoadingCircle';
import {
  PRODUCTION_CHECKLIST, QA_SCOPE_SELECT_OPTIONS, ROUTE_REPORT, WAVE_MANAGEMENT,
} from '../../constants';

const ProgramDetails = ({
  values, isEdit, handleChange, dividerStyle, customLabel, isLoading,
  setFieldValue, programId, isCPAdmin,
}) => {
  const data = _map(values?.enrollment_cohorts, (obj) => ({
    ...obj,
    period: `${obj?.start_date ? moment(obj?.start_date).format('ll') : 'N/A'} - ${obj?.end_date ? moment(obj?.end_date).format('ll') : 'N/A'}`,
    expiration_date: obj?.expiration_date ? moment(obj?.expiration_date).format('ll') : 'N/A',
  }));
  const parentLpProductionChecklistLink = values?.parent_prod_checklist ? `${window.location.origin}/${ROUTE_REPORT}/${values.parent_prod_checklist}/${PRODUCTION_CHECKLIST}` : '';

  return (
    <>
      <ContentDetailsBlock headerText="Program Details" noPaper>
        {isLoading ? <LoadingCircle labelText="Loading Program Details" /> : (
          <>
            <Grid item xs={12} md={12} sx={{ padding: '1rem 0' }}>
              <ContentDetailsLabel
                label="Program Links"
                labelCustomCss={customLabel}
              />
            </Grid>
            <ContentDetailsSection
              label="Docebo Script"
              name="program_links_docebo_script"
              type="text"
              infoText="Box link to the Docebo script for LP"
              fieldType="text"
              havingLinkText
              isEdit={isEdit}
              value={values.program_links_docebo_script}
              handleChange={handleChange}
            />
            <ContentDetailsSection
              label="Elucidat Scripts"
              name="program_links_elucidat_script"
              type="text"
              infoText="Box link to the Elucidat scripts for LP"
              fieldType="text"
              havingLinkText
              isEdit={isEdit}
              value={values.program_links_elucidat_script}
              handleChange={handleChange}
            />
            <ContentDetailsSection
              label="Change Log"
              name="program_links_change_log"
              type="text"
              infoText="Box link to the Change Log for client deployment LP"
              fieldType="text"
              havingLinkText
              isEdit={isEdit}
              value={values.program_links_change_log}
              handleChange={handleChange}
            />
            <ContentDetailsSection
              label="Wrike Project"
              name="program_links_wrike_project"
              type="text"
              infoText="Wrike project link for LP"
              fieldType="text"
              havingLinkText
              isEdit={isEdit}
              value={values.program_links_wrike_project}
              handleChange={handleChange}
            />
            <Divider className={dividerStyle} variant="middle" light />
            <Grid item xs={12} md={12} sx={{ padding: '1rem 0' }}>
              <ContentDetailsLabel
                label="Parent Program Links"
                labelCustomCss={customLabel}
              />
            </Grid>
            <ContentDetailsSection
              label="Parent Docebo Script"
              name="parent_docebo_script"
              type="text"
              fieldType="text"
              havingLinkText
              value={values.parent_docebo_script}
            />
            <ContentDetailsSection
              label="Parent Elucidat Scripts"
              name="parent_elucidat_script"
              type="text"
              fieldType="text"
              havingLinkText
              value={values.parent_elucidat_script}
            />
            <ContentDetailsSection
              label="Parent Change Log"
              name="parent_change_log"
              type="text"
              fieldType="text"
              havingLinkText
              value={values.parent_change_log}
            />
            <ContentDetailsSection
              label="Parent Production Checklist"
              name="parent_prod_checklist"
              type="text"
              fieldType="text"
              havingLinkText
              value={parentLpProductionChecklistLink}
            />
            <ContentDetailsSection
              label="Translation Master PC Link"
              name="parent_translation_master_pc_link"
              type="text"
              infoText="Link to the Translation Master Production Checklist"
              fieldType="text"
              havingLinkText
              isEdit={isEdit}
              value={values.parent_translation_master_pc_link}
              handleChange={handleChange}
            />
            <Divider className={dividerStyle} variant="middle" light />
            <Grid item xs={12} md={12} sx={{ padding: '1rem 0' }}>
              <ContentDetailsLabel
                label="QA Test Cases"
                labelCustomCss={customLabel}
              />
            </Grid>
            <ContentDetailsSection
              label="Box Link"
              name="qa_test_cases_box_link"
              type="text"
              infoText="Box link where test cases from QA team are maintained for LP"
              fieldType="text"
              havingLinkText
              isEdit={isEdit}
              value={values.qa_test_cases_box_link}
              handleChange={handleChange}
            />
            <ContentDetailsSection
              label="QA Scope"
              name="qa_test_cases_qa_scope"
              type="text"
              infoText="Scope of QA testing for LP"
              fieldType="select"
              isEdit={isEdit}
              options={QA_SCOPE_SELECT_OPTIONS}
              value={values.qa_test_cases_qa_scope}
              handleChange={(n, v, b) => {
                setFieldValue(n, v, b);
              }}
            />
            <Divider className={dividerStyle} variant="middle" light />
          </>
        )}
        <Grid item xs={12} md={12} sx={{ padding: '1rem 0' }}>
          <ContentDetailsLabel
            label="Enrollment Cohort"
            labelCustomCss={customLabel}
            fieldRedirectOnLabel
            fieldRedirectLink={!isCPAdmin && `/${ROUTE_REPORT}/${programId}/${WAVE_MANAGEMENT}`}
          />
        </Grid>
        {isLoading ? <LoadingCircle labelText="Loading Enrollment Cohort" /> : (
          <Grid item xs={12} md={12} style={{ paddingTop: 0, paddingBottom: '1rem' }}>
            <TableRC
              columns={[
                { title: 'EC Code', property: 'ec_code' },
                { title: 'EC Type', property: 'experience_type' },
                { title: 'Cohort Title', property: 'wave_name' },
                { title: 'Period', property: 'period' },
                { title: 'Expiration Date', property: 'expiration_date' },
                { title: 'No. of Users', property: 'user_count' },
                { title: 'CST Member', property: 'cst_username' },
                { title: 'Order ID', property: 'order_id' },
                { title: 'PDT ID', property: 'pdt_id' },
              ]}
              data={data}
              noDataMessage="Enrollment cohort details unavailable for this learning plan."
            />
          </Grid>
        )}
        <Divider className={dividerStyle} variant="middle" light />
        <Grid item xs={12} md={12} sx={{ padding: '1rem 0' }}>
          <ContentDetailsLabel
            label="Additional"
            labelCustomCss={customLabel}
          />
        </Grid>
        <ContentDetailsSection
          label="Notes from CP"
          name="addtional_cp_notes"
          type="text"
          fieldType="textarea"
          isEdit={isEdit}
          value={values.addtional_cp_notes}
          handleChange={handleChange}
        />
        <ContentDetailsSection
          label="Offering Configuration Standards"
          name="addtional_offering_configuration_std"
          type="text"
          fieldType="text"
          isEdit={isEdit}
          value={values.addtional_offering_configuration_std}
          handleChange={handleChange}
        />
      </ContentDetailsBlock>
    </>
  );
};

ProgramDetails.propTypes = {
  values: PropTypes.object.isRequired,
  isEdit: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  dividerStyle: PropTypes.string.isRequired,
  customLabel: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
  isCPAdmin: PropTypes.bool.isRequired,
};

export default ProgramDetails;
