import React, { useEffect, useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@mui/material/Box';
import * as Yup from 'yup';
import FormattedTypography from '../common/FormattedTypography';
import ConfirmationModal from '../common/ConfirmationModal';
import LabledTextField from '../common/LabledTextField';
import Loading from '../common/Loading';
import CustomSelect from '../common/CustomSelect';
import {
  ERROR_RED,
  SWITCH_GREY,
  ACCORDION_GREY,
} from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  modalWrapper: {
    padding: '0 4rem 2rem',
    width: '87%',
    margin: 'auto',
  },
  width100: {
    width: '85%',
    margin: '0 auto',
  },
  modalHeight: {
    minHeight: '40rem',
  },
  fieldPadding: {
    paddingTop: '1rem',
  },
  red: {
    color: ERROR_RED,
  },
  rootLabelText: {
    '& > div': {
      borderRadius: 'unset',
    },
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  save: {
    '&:disabled': {
      color: ACCORDION_GREY,
      boxShadow: 'none',
      backgroundColor: SWITCH_GREY,
    },
  },
  rightText: {
    marginLeft: 'auto',
    float: 'right',
  },
}));

const AddEditSkillModal = ({
  open,
  onClose,
  isLoading,
  onUpdateSkillData,
  onAddSkill,
  skillData,
  isNewSkill,
}) => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState({});
  const skildOptionList = [{ value: 1, label: '1' }, { value: 2, label: '2' }, { value: 3, label: '3' },
    { value: 4, label: '4' }, { value: 5, label: '5' },
  ];
  const skillForm = {
    heading: isNewSkill ? 'Add Skill Information' : 'Edit Skill Information',
    subHeading: isNewSkill
      ? 'Add Skill information for Skill-Based Programs'
      : 'Edit Skill information for Skill-Based Programs',
  };
  const prepareFormValues = useCallback(
    () => ({
      skill_code: skillData?.code,
      skill_name: skillData?.name,
      skill_description: skillData?.description ?? '',
      version: skildOptionList.filter((item) => item.value === skillData?.version)[0] ?? null,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [skillData],
  );

  useEffect(() => {
    if (isNewSkill) {
      setFormValues({
        skill_code: '',
        skill_name: '',
        skill_description: '',
        version: null,
      });
    } else {
      setFormValues(prepareFormValues());
    }
  }, [isNewSkill, prepareFormValues]);

  const skillSchema = Yup.object({
    skill_code: Yup.string(),
    skill_name: Yup.string().required('This field is Required').max(128, 'Skill Name should not exceed 128 characters'),
    skill_description: Yup.string().required('This field is Required').max(500, 'Skill Description should not exceed 128 characters'),
    version: Yup.object().required('This field is Required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formValues,
    validationSchema: skillSchema,
    onSubmit: (values) => {
      const finalValues = {
        name: values.skill_name,
        description: values.skill_description,
        version: values.version.value,
      };
      if (isNewSkill) {
        onAddSkill(finalValues);
      } else {
        onUpdateSkillData({ ...finalValues, id: skillData.id });
      }
    },
  });

  const {
    values, handleSubmit, handleChange, setValues, setFieldValue, resetForm, errors,
  } = formik;
  useEffect(() => {
    setValues(formValues);
  }, [formValues, setValues]);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open, resetForm]);

  const checkCharacterLimit = (e, charLen) => {
    if (e.target.value.length <= charLen) {
      handleChange(e);
    }
  };

  const requiredFieldLabel = (label, className) => (
    <Box className={`${classes.label} ${className || ''}`}>
      <Typography>{label}</Typography>
      <span className={classes.red}>*</span>
    </Box>
  );

  const onSkillVersionChange = (e) => {
    setFieldValue('version', e || []);
  };

  return (
    <ConfirmationModal
      open={open}
      isContainForm
      onClose={onClose}
      onSubmit={handleSubmit}
      dialogContentClass={classes.width100}
      minHeightClass={classes.modalHeight}
      modalWidth={80}
      title={(
        <FormattedTypography
          variant="subtitle1"
          subheadingVariant="body1"
          body={skillForm.heading}
          subHeading={skillForm.subHeading}
          suffixClassName={classes.bold}
        />
      )}
      content={
        isLoading ? (
          <Loading />
        ) : (
          <Grid container justifyContent="space-between" className={classes.modalWrapper}>
            { !isNewSkill && (
            <Grid item xs={12}>
              <LabledTextField
                label={(
                  <Box className={classes.label}>
                    <Typography>Skill Code</Typography>
                    <span className={classes.red}>*</span>
                  </Box>
                )}
                placeholder="Add a Skill"
                className={classes.fieldPadding}
                inputProps={{
                  name: 'skill_code',
                  classes: {
                    root: classes.rootLabelText,
                  },
                }}
                disabled
                value={values.skill_code || ''}
                onChange={handleChange}
              />
              <Box className={classes.errorBlock}>
                {errors.skill_code && (
                <Typography className={classes.red}>{errors.skill_code}</Typography>
                )}
              </Box>
            </Grid>
            )}
            <Grid item xs={12}>
              <LabledTextField
                label={(
                  <Box className={classes.label}>
                    <Typography>Skill Name</Typography>
                    <span className={classes.red}>*</span>
                  </Box>
                )}
                placeholder="Add a Skill Name"
                className={classes.fieldPadding}
                inputProps={{
                  name: 'skill_name',
                  classes: {
                    root: classes.rootLabelText,
                  },
                }}
                value={values.skill_name || ''}
                onChange={(e) => checkCharacterLimit(e, 128)}
              />
              <Box className={classes.errorBlock}>
                {errors.skill_name && (
                <Typography className={classes.red}>{errors.skill_name}</Typography>
                )}
                <Typography className={classes.rightText}>
                  {(values.skill_name || '').length}
                  /128
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <LabledTextField
                label={(
                  <Box className={classes.label}>
                    <Typography>Skill Description</Typography>
                    <span className={classes.red}>*</span>
                  </Box>
                )}
                className={classes.fieldPadding}
                placeholder="Add a Skill Description"
                inputProps={{
                  name: 'skill_description',
                  classes: { root: classes.rootLabelText },
                }}
                value={values.skill_description || ''}
                onChange={(e) => checkCharacterLimit(e, 500)}
              />
              <Box className={classes.errorBlock}>
                {errors.skill_description && (
                <Typography className={classes.red}>{errors.skill_description}</Typography>
                )}
                <Typography className={classes.rightText}>
                  {(values.skill_description || '').length}
                  /500
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <CustomSelect
                name="version"
                options={skildOptionList}
                className={`${classes.fieldPadding} ${classes.experience_type_label}`}
                defaultValue={values.version}
                placeholder="Select a Skill Version"
                type="select"
                label={requiredFieldLabel('Skill Version', classes.experience_type_label)}
                labelClass={classes.experience_type_label}
                onChange={(e) => onSkillVersionChange(e)}
              />
              <Box className={classes.errorBlock}>
                {errors.skill_name && (
                <Typography className={classes.red}>{errors.skill_name}</Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        )
      }
      actions={(
        <>
          <Button
            className={classes.cancelModal}
            color="primary"
            variant="outlined"
            type="button"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            className={classes.save}
            color="primary"
            disabled={
              !(values.skill_name?.trim() && values.skill_description?.trim() && values.version)
            }
            variant="contained"
            type="submit"
          >
            Save
          </Button>
        </>
      )}
    />
  );
};

export default AddEditSkillModal;

AddEditSkillModal.defaultProps = {
  skillData: {},
};

AddEditSkillModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdateSkillData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  skillData: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
    version: PropTypes.number,
  }),
  isNewSkill: PropTypes.bool.isRequired,
  onAddSkill: PropTypes.func.isRequired,
};
