import React, {
  useState, useCallback, useEffect, useRef, useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Box from '@mui/material/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@mui/material/Divider';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import FileInput from '../../components/FileInput';
import MainButton from '../../components/MainButton';

import CustomUploadModal from '../../components/common/CustomUploadModal';
import InfoOutline from '../../assets/icons/info-outline.svg';
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import AlertBarWithAction from '../../components/common/AlertBarWithAction';
import MetadataUsersValidation from '../../components/MetaDataManagement/MetaDataUsers/MetadataUsersValidation';
import MetadataComponentsOverview from '../../components/MetaDataManagement/MetaDataUsers/MetadataComponentsOverview';
import ActivityInitiatedInstructions from '../../components/MetaDataManagement/MetaDataUsers/ActivityInitiatedInstructions';
import useUploadExcel from '../../hooks/useUploadExcel';
import PlatformHeader from '../../components/PlatformHeader';
import FormattedTypography from '../../components/common/FormattedTypography';
import CheckRoundIcon from '../../assets/icons/green-round-check.svg';
import ErrorRoundIcon from '../../assets/icons/error-round.svg';
import BlueAlertIcon from '../../assets/icons/icon-blue-alert.svg';
import { internalServerErrorModalLogic } from '../common/utils';
import {
  validateXLSXApiCall, uploadComponentXlsx, getProgress, getReport, downloadFile, getMetaDataForLP,
} from '../common/apis';
import {
  BULK_EDIT_COMPONENT_METADATA_TEMPLATE,
  STAGE_IN_BUILD, STAGE_QA, STAGE_LAUNCH_PREP, STAGE_LAUNCHED, BULK_EDIT_METADATA,
  STAGE_UNKNOWN, STAGE_REPLICATED, COMPLETED_WITH_ERRORS, FAILED, PENDING, PROCESSING,
} from '../../constants';
import {
  MCKINSEY_BLUE, ACCORDION_GREY, LIGHT_MEDIUM_GREY, WHITE,
  FILL_ERROR_RED, PALE_GREY, MODERATE_DARK_GREY, RED_DARK,
} from '../../stylesheets/colors';
import NoData from '../../assets/img/no-data.svg';
import NoDataComponent from '../../components/common/NoDataComponent';
import { getAllPrograms } from '../BasePage/apis';
import { getPlatformTransactions } from '../ActivityLogs/api';
import MyContext from '../../context';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 2rem',
    height: '70vh',
  },
  uploadSection: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    margin: '2rem 0 1rem 0',
    padding: '0 2.5rem',
  },
  searchSection: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    margin: '2rem 0 1rem 0',
    padding: '0 2.5rem 0 0',
    borderRight: 'thin solid rgba(0,0,0,0.12)',
    position: 'relative',
  },
  border: {
    border: `0.5px dashed ${MCKINSEY_BLUE}`,
    width: '50%',
  },
  disabledborder: {
    border: `0.5px dashed ${ACCORDION_GREY}`,
    width: '50%',
  },
  textColor: {
    color: ACCORDION_GREY,
    wordWrap: 'break-word',
    width: '50%',
  },
  infoText: {
    display: 'flex',
    flexDirection: 'row',
  },
  downloadText: {
    width: '50%',
  },
  buttonIcon: {
    width: '1rem',
    height: '1rem',
    marginRight: '0.5rem',
    marginTop: '0.5rem',
  },
  leftSpacing: {
    marginLeft: '0.5rem',
  },
  loading: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  helperTextFirst: {
    flexBasis: '100%',
    '&::after': {
      content: '"*"',
      color: FILL_ERROR_RED,
    },
  },
  searchWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '2rem 0',
    position: 'relative',
  },
  inputWrapper: {
    flex: 0.9,
    margin: '0.5rem 1.25rem 0 0',
    position: 'relative',
  },
  searchInput: {
    width: '100%',
    height: '2.5rem',
    fontSize: '1rem',
    border: `.1rem solid ${LIGHT_MEDIUM_GREY}`,
    outline: 'none !important',
    outlineOffset: 'none !important',
    padding: '0.5rem 3rem 0.5rem 1rem',
  },
  searchButton: {
    flex: 0.1,
    color: WHITE,
    backgroundColor: MCKINSEY_BLUE,
    width: '5rem',
    height: '2.5rem',
    fontFamily: 'McKinseySans',
    margin: '0.5rem 0rem 0 0',
    padding: '0.5rem 2.688rem',
  },
  metaDataFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1.2rem',
  },
  learningPlanList: {
    display: 'flex',
    marginTop: '1.125rem',
  },
  noResultsText: {
    fontSize: '1.5rem',
  },
  selectedLPList: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectedLPListItem: {
    backgroundColor: MODERATE_DARK_GREY,
    borderRadius: '0.5rem',
    display: 'flex',
    margin: '.2rem .5rem .2rem .2rem',
    padding: '0.25rem 0.5rem',
    fontSize: '1.125rem',
  },
  selectedLPLabel: {
    color: WHITE,
    fontSize: '75%',
    overflow: 'hidden',
    padding: '.1rem',
    paddingLeft: '.6rem',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginTop: '.2rem',
    fontWeight: '500',
  },
  removeLPListItem: {
    alignItems: 'center',
    borderRadius: '.2rem',
    display: 'flex',
    padding: '0rem .4rem',
    color: WHITE,
    cursor: 'pointer',
  },
  lpLabel: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
    color: MODERATE_DARK_GREY,
  },
  metadataLable: {
    fontSize: '1rem',
    fontWeight: 500,
    color: ACCORDION_GREY,
    textDecoration: 'underline',
  },
  searchItemWrapper: {
    position: 'absolute',
    background: WHITE,
    width: '100%',
    top: '100%',
    marginTop: '.5rem',
    overflow: 'auto',
    boxShadow: '0 0 .1rem 0 rgb(5 28 44 / 20%), 0 .2rem .4rem -.1rem rgb(5 28 44 / 20%)',
  },
  searchItemList: {
    padding: '0.563rem 1.25rem 0.563rem 1rem',
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  addItem: {
    margin: '0 0 0 25.438rem',
    padding: '0.5rem',
    border: `.1rem solid ${MCKINSEY_BLUE}`,
    color: MCKINSEY_BLUE,
    cursor: 'pointer',
    display: 'flex',
    borderRadius: '1%',
    width: '3rem',
    height: '2rem',
  },
  disabledItemList: {
    background: PALE_GREY,
    cursor: 'none',
    color: LIGHT_MEDIUM_GREY,
  },
  errorText: {
    color: FILL_ERROR_RED,
    position: 'absolute',
    top: '100%',
    display: 'flex',
    marginTop: '0.5rem',
  },
  errorInput: {
    border: `.1rem solid ${FILL_ERROR_RED}`,
  },
  maxLenText: {
    position: 'absolute',
    display: 'flex',
  },
  disabled: {
    '&$disabled': {
      background: PALE_GREY,
      color: LIGHT_MEDIUM_GREY,
    },
  },
  searchSectionLoader: {
    position: 'absolute',
    right: '0.5rem',
    top: '0.5rem',
  },
  enabledownload: {
    color: MCKINSEY_BLUE,
    cursor: 'pointer',
  },
  headingText: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
    marginBottom: '0.5rem',
  },
  secondaryText: {
    fontSize: '1.125rem',
  },
});

// eslint-disable-next-line react/prop-types
const Loading = ({ additionalClass }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.loading} ${additionalClass ? classes[additionalClass] : ''}`}>
      <CircularProgress size={additionalClass ? '1.5rem' : '4rem'} />
    </div>
  );
};

const ComponentMetadata = () => {
  const history = useHistory();

  const { programType } = useContext(MyContext);

  const classes = useStyles();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoadingOnSearch, setIsLoadingOnSearch] = useState(false);
  const [alertBarOnValidation, setalertBarOnValidation] = useState(false);
  const [filename, setFileName] = useState('');
  const [progress, setProgress] = useState({ done: null, percentage: 0 });
  const [transactionId, setTransactionId] = useState(null);
  const [pollProgress, setPollProgress] = useState(true);
  const [isFileLoaded, setIsFileLoaded] = useState(false);
  const [listLp, setListLp] = useState([]);
  const [showList, setShowList] = useState(false);
  const [selectedLP, setSelectedLP] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [errortext, setErrorText] = useState(null);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [showElement, setShowElement] = useState(false);
  const defaultStageSelection = [
    STAGE_IN_BUILD,
    STAGE_QA,
    STAGE_LAUNCH_PREP,
    STAGE_LAUNCHED,
    STAGE_UNKNOWN,
    STAGE_REPLICATED,
  ];
  const dropDownListRef = useRef(null);

  useEffect(() => {
    const fetchProgramMetadata = async () => {
      try {
        const resp = await getPlatformTransactions(['bulk_edit_component_metadata'], '', true, 1, 10);
        if (resp.data.data && resp.data.data.length) {
          const filterData = resp.data.data.filter(
            (item) => item.status === PROCESSING || item.status === PENDING,
          );
          if (filterData.length) {
            setTransactionId(filterData[0].transaction_id);
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchProgramMetadata();
  }, []);

  const onFileValidation = useCallback(() => {
    setOpenStatusModal(true);
    setalertBarOnValidation(false);
  }, []);

  const onFileUpload = useCallback(
    async (formData) => {
      try {
        setShowElement(true);
        setalertBarOnValidation(false);
        const res = await uploadComponentXlsx(formData);
        if (res.data.success) {
          batchUpdates(() => {
            setTransactionId(res.data.transaction_id);
            setIsFileLoaded(true);
            setPollProgress(true);
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    [],
  );

  const onDownloadSample = () => {
    window.open(BULK_EDIT_COMPONENT_METADATA_TEMPLATE, '_blank');
  };

  const onDownloadLog = async (data) => {
    try {
      const tid = data.transaction_id;
      const createdAt = data.created_at ? data.created_at : new Date();
      await downloadFile(tid, undefined, BULK_EDIT_METADATA, createdAt);
    } catch (e) {
      internalServerErrorModalLogic(history, e, setIsErrorModalOpen, () => { });
      console.error(e);
    }
  };

  const {
    modalSchema, modalState, closeModal, showReport, showProgress, onFileValidate,
  } = useUploadExcel(MetadataUsersValidation,
    MetadataComponentsOverview, ActivityInitiatedInstructions, onFileUpload);

  useEffect(() => {
    if (!modalState) {
      setOpenStatusModal(false);
    }
  }, [modalState]);

  const onErrorModalClose = () => {
    setIsErrorModalOpen(false);
  };

  const generateFormData = useCallback(
    (file) => {
      const formData = new FormData();
      formData.append('file', file, file.name);
      setFileName(file.name);
      return formData;
    },
    [],
  );

  const preUploadFile = (event) => {
    const file = event.target.files[0];
    setProgress({ done: null, percentage: 0 });
    setalertBarOnValidation(true);
    onFileValidate(generateFormData(file), file, validateXLSXApiCall, onFileValidation, ['bulk_edit_component_metadata']);
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
  };

  const getSrc = (result) => {
    if (result?.fail_count === 0) {
      return CheckRoundIcon;
    } if ((result?.success_count === 0)) {
      return ErrorRoundIcon;
    }
    return BlueAlertIcon;
  };

  const getHeading = (result) => {
    if (result?.fail_count === 0) {
      return (
        <FormattedTypography
          variant="h1"
          suffix="Component metadata bulk edit file upload complete!"
          suffixClassName={classes.leftSpacing}
        />
      );
    } if ((result?.success_count === 0)) {
      return (
        <FormattedTypography
          variant="h1"
          suffix="Component metadata bulk edit file upload failed!"
          suffixClassName={classes.leftSpacing}
        />
      );
    }
    return (
      <FormattedTypography
        variant="h1"
        suffix="Component metadata bulk edit file upload was completed with errors!"
        suffixClassName={classes.leftSpacing}
      />
    );
  };

  useEffect(() => {
    let timer = null;
    const pollProgressApi = async () => {
      if (!pollProgress) return;
      try {
        if (!transactionId) return;
        const res = await getProgress(transactionId);
        if (res.data.done) {
          const result = await getReport(transactionId);
          if (isFileLoaded) {
            showReport({
              logo: <img src={getSrc(result)} alt="Spreadsheet" width={30} height={30} />,
              heading: getHeading(result),
              primaryBtnText: 'Download Log',
              primaryBtnProps: {
                onClick: () => onDownloadLog({ transaction_id: transactionId }),
              },
              successCount: result.success_count,
              failCount: result.fail_count,
              totalCount: result.total_rows,
              fileName: result.filename,
            });
          }
          batchUpdates(() => {
            setIsFileLoaded(false);
            setProgress(res.data);
            setPollProgress(false);
          });
        } else {
          batchUpdates(() => {
            setShowElement(true);
            setIsFileLoaded(true);
            showProgress({
              percentage: res.data.percentage,
              fileName: res.data.file_name,
              created_by: res.data.created_by,
              created_time: res.data.created_at,
            });
            setProgress(res.data);
          });
          timer = setTimeout(pollProgressApi, 1000);
        }
      } catch (err) {
        timer = internalServerErrorModalLogic(history, err, setIsErrorModalOpen, pollProgressApi);
      }
    };
    pollProgressApi();
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pollProgress, transactionId, history]);

  const handleClickOutside = (event) => {
    if (dropDownListRef.current && !dropDownListRef.current.contains(event.target)) {
      setShowList(false);
    }
  };

  useEffect(() => {
    if (progress?.done) {
      setTimeout(() => setShowElement(false), 15000);
    }
  }, [progress]);

  const AlertComp = () => {
    if (!progress?.done && progress?.done !== null) {
      return (
        <AlertBarWithAction
          variant="with-percentage"
          percentage={!progress?.done ? progress?.percentage : null}
          labelText={`Uploading component metadata file "${progress.file_name}" is in process`}
          actionButtonText="View Status"
          onActionClick={() => setOpenStatusModal(true)}
        />
      );
    }
    if (progress?.done) {
      if ([COMPLETED_WITH_ERRORS, FAILED].includes(progress?.status)) {
        return (
          <>
            {showElement ? (
              <AlertBarWithAction
                variant="error"
                percentage={null}
                labelText={progress?.status === FAILED
                  ? `Failed to upload Component Metadata file “${progress.file_name}". Please try again.`
                  : `Component Metadata file "${progress.file_name}" upload was completed with errors.`}
                actionButtonText="View Details"
                onActionClick={() => setOpenStatusModal(true)}
              />
            ) : null}
          </>
        );
      }
      return (
        <>
          {showElement ? (
            <AlertBarWithAction
              variant="success"
              percentage={null}
              labelText={`Component metadata file “${progress.file_name}" was successfully uploaded`}
            />
          ) : null}
        </>
      );
    }
    if (progress?.done == null && alertBarOnValidation) {
      return (
        <AlertBarWithAction
          variant="with-percentage"
          percentage={null}
          labelText={`Validating component metadata file "${filename}" is in process`}
        />
      );
    }
    return <></>;
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  const onSearch = useCallback(
    async () => {
      if (!searchText) return;
      setErrorText(null);
      try {
        setIsLoadingOnSearch(true);
        const result = await getAllPrograms(
          programType,
          searchText,
          0,
          defaultStageSelection,
        );
        if (!result.data.docebo_success) throw new Error();
        const newPrograms = result.data.items;
        // eslint-disable-next-line max-len
        const matchList = newPrograms.filter((item) => (item.config_id.toLowerCase() === searchText.toLowerCase()) && (item.replicated || !item.docebo));
        setIsLoadingOnSearch(false);
        if (matchList.length) {
          setListLp(matchList);
          setShowList(true);
          setErrorText(null);
        } else {
          setListLp([]);
          setErrorText('No Learning Plan found with this ID. Please try another one.');
          setShowList(false);
        }
      } catch (e) {
        console.error(e);
      }
    }, [searchText, defaultStageSelection, programType],
  );

  const onSearchInner = useCallback(
    (event) => {
      const newText = event.target.value;
      setSearchText(newText);
    },
    [setSearchText],
  );

  const addLpItem = (addeditem) => {
    const selectedLPList = [...selectedLP];
    selectedLPList.push(addeditem);
    setSelectedLP(selectedLPList);
  };
  const removeFromLP = (removeItem) => {
    const lp = selectedLP.filter((item) => item.config_id !== removeItem.config_id);
    setSelectedLP(lp);
  };
  const checkItemOnSelectedList = (item) => {
    const matchItem = selectedLP.filter((sl) => sl.config_id === item.config_id);
    return matchItem.length;
  };

  const getLPList = () => listLp.map((item) => (
    <div key={item.config_id} ref={dropDownListRef} className={`${classes.searchItemList} ${checkItemOnSelectedList(item) ? classes.disabledItemList : ''}`}>
      {item.config_id}
      {checkItemOnSelectedList(item)
        ? (
          <IconButton onClick={() => removeFromLP(item)} className={classes.addItem}>
            <RemoveIcon sx={{ fontSize: '1rem' }} />
          </IconButton>
        )
        : (
          <IconButton onClick={() => addLpItem(item)} className={classes.addItem}>
            <AddIcon sx={{ fontSize: '1rem' }} />
          </IconButton>
        )}
    </div>
  ));
  const downLoadMetaData = async () => {
    if (!selectedLP.length) return;
    const lp_codes = selectedLP.map((item) => item.config_id);
    try {
      await getMetaDataForLP({ lp_codes });
    } catch (e) {
      internalServerErrorModalLogic(history, e, setIsErrorModalOpen, () => { });
      console.error(e);
    }
  };

  const disabledInput = () => alertBarOnValidation || (isFileLoaded && !(progress?.done));
  const body = (
    <Container className={classes.innerWrapper} maxWidth={false}>
      <Box className={classes.searchSection}>
        <Typography variant="h6" className={classes.headingText} component="p" mb={2}>Select Learning Plans to edit metadata </Typography>
        <Typography variant="body1" component="span">
          Select upto 10 learning plans and download the excel file
          containing all the components and  their latest metadata. Edit into that file and
          update the metadata through a re-upload.
        </Typography>
        <div className={classes.searchWrapper}>
          <FormHelperText
            id="standard-weight-helper-text"
            className={classes.helperTextFirst}
          >
            Enter the Learning plan Configuration ID
          </FormHelperText>
          <div className={classes.inputWrapper}>
            <input
              type="text"
              className={`${classes.searchInput} ${selectedLP.length === 10 ? classes.disabled : ''} ${errortext ? classes.errorInput : ''}`}
              onChange={onSearchInner}
              value={searchText}
              disabled={selectedLP.length === 10}
              placeholder="Type the Learning Plan Configuration ID  here"
            />
            {isLoadingOnSearch && <Loading additionalClass="searchSectionLoader" />}
            {
              selectedLP.length === 10 ? (
                <ReportProblemIcon sx={{
                  color: RED_DARK, position: 'absolute', right: '1rem', top: '.5rem',
                }}
                />
              ) : <></>
            }
          </div>
          {
            errortext ? (
              <p className={classes.errorText}>
                <CancelOutlinedIcon sx={{ fontSize: '1rem', marginRight: '.3rem', marginTop: '.3rem' }} />
                {errortext}
              </p>
            ) : <></>
          }
          {
            selectedLP.length === 10 ? (
              <p className={`${classes.maxLenText} ${classes.errorText}`}>
                You have reached the 10 Learning Plan limit,
                delete a selected Learning Plan to add another.
              </p>
            ) : <></>
          }
          {
            listLp.length && showList ? (
              <div className={classes.searchItemWrapper}>
                {getLPList()}
              </div>
            ) : <></>
          }

          <Button
            color="primary"
            variant="contained"
            className={classes.searchButton}
            onClick={onSearch}
          >
            Search
          </Button>
        </div>
        <Divider light flexItem sx={{ mt: '2.5rem', width: '100%' }} />
        <div className={classes.metaDataFooter}>
          <Typography className={classes.lpLabel} variant="body1" component="p">Selected Learning Plans </Typography>
          <Typography className={`${classes.metadataLable} ${selectedLP.length && classes.enabledownload}`} variant="body1" onClick={downLoadMetaData} component="p">Download Metadata for Selected LPs </Typography>
        </div>
        <div className={classes.learningPlanList}>
          {selectedLP.length
            ? (
              <div className={classes.selectedLPList}>
                {selectedLP.map((item) => (
                  <div key={item.config_id} className={classes.selectedLPListItem}>
                    <div className={classes.selectedLPLabel}>{item.config_id}</div>
                    {' '}
                    <IconButton onClick={() => removeFromLP(item)} className={classes.removeLPListItem}><ClearIcon sx={{ fontSize: '1rem' }} /></IconButton>
                  </div>
                ))}
              </div>
            )
            : <NoDataComponent imgSrc={NoData} primaryText="" secondaryText="No learning plans selected yet!" secondaryTextCls={classes.secondaryText} />}
        </div>
      </Box>
      <Box className={classes.uploadSection}>
        <Box className={disabledInput() ? classes.disabledborder : classes.border}>
          <FileInput idx={0} onFileInput={preUploadFile} isDisabled={disabledInput()}>
            <MainButton
              title="Upload XLS file"
              subtitle="To update or add metadata for courses"
              isDisabled={disabledInput()}
            />
          </FileInput>
        </Box>
        <Divider light flexItem sx={{ m: '1.2rem 0rem', width: '50%' }} />
        <Typography className={classes.downloadText} variant="body1" component="span">Directly edit into the template file if you do not want to download the latest metadata. </Typography>
        <Button
          variant="outlined"
          style={{ marginTop: '1rem', marginBottom: '0.5rem', width: '25%' }}
          color="primary"
          onClick={onDownloadSample}
          data-testid="downloadtemplate"
        >
          Download XLS Template
        </Button>
        <Box className={classes.infoText}>
          <img src={InfoOutline} alt="infoIcon" className={classes.buttonIcon} />
          <Typography variant="body1" className={classes.textColor}>
            Make sure to enter the correct: Learning Plan Configuration ID,
            Component Code and Elucidat Project ID (where applicable)
          </Typography>
        </Box>
      </Box>
    </Container>
  );

  const ModalComponent = modalSchema[modalState] ? modalSchema[modalState].component : null;
  return (
    <Paper className={classes.wrapper}>
      {progress || alertBarOnValidation ? <AlertComp /> : null}

      <PlatformHeader shouldShow={false} />
      <Container className={classes.innerWrapper} disableGutters maxWidth={false}>
        {body}
        {ModalComponent && openStatusModal && (
          <CustomUploadModal
            open={modalSchema[modalState] !== undefined}
            onClose={closeModal}
            className={classes.modalHeight}
            breakpoint="md"
            {...modalSchema[modalState].wrapperProps}
          >
            <ModalComponent {...modalSchema[modalState].props} />
          </CustomUploadModal>
        )}
        <ErrorModal
          open={isErrorModalOpen}
          onClose={onErrorModalClose}
          message="Could not update users due to some technical error."
        />
      </Container>
    </Paper>
  );
};

ComponentMetadata.propTypes = {

};
export default ComponentMetadata;
