import {
  SAVE_FORM_VALUES, REMOVE_FORM_VALUES,
} from '../constants';

const initialState = {
  data: {},
  selectedFilterValue: {},
};

export default function NotificationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SAVE_FORM_VALUES:
      return {
        ...state,
        data: action.payload.filterParams,
        selectedFilterValue: action.payload.selectedFilterValue,
      };
    case REMOVE_FORM_VALUES:
      return {
        ...state,
        data: {},
        selectedFilterValue: {},
      };
    default:
      return state;
  }
}
