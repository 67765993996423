import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';

import Grid from '@material-ui/core/Grid';
import Divider from '@mui/material/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import FormattedTypography from '../common/FormattedTypography';
import ConfirmationModal from '../common/ConfirmationModal';
import MetadataFormTemplate from './MetadataFormTemplate';
import FormProgress from './FormProgress';
import FieldSelectionForm from './FieldSelectionForm';
import SelectedUserTable from '../CreateTestUsers/SelectedUserTable';
import { validateEmail } from '../../helpers/utils';
import { DARK_MEDIUM_GREY } from '../../stylesheets/colors';
import filedsToUpdate from './constant';

const useStyles = makeStyles(() => ({
  bulkModalWrapper: {
    padding: '0 48px 20px',
  },
  width100: {
    width: '100%',
  },
  modalHeight: {
    minHeight: '400px',
  },
  textLight: {
    color: DARK_MEDIUM_GREY,
    fontWeight: 'bold',
  },
  bold: {
    fontWeight: 'bold',
  },
}));

const validateEmailSeperatedBySemiColon = (email) => {
  const emailAdress = email.split(';');
  const emailArray = emailAdress.filter((element) => validateEmail(element.trim()));
  return emailAdress.length === emailArray.length;
};

const validate = (values) => {
  const errors = {};
  if (values['course-runner'] && !validateEmailSeperatedBySemiColon(values['course-runner'])) {
    errors['course-runner'] = 'Component Runner should have valid Email ID/s and separated with a semi colon';
  }
  return errors;
};

const BulkEditMetadata = ({
  open, onClose, onBulkUpdate, selectedData, isLoading, options,
  status, setStatus, isValidating, setIsValidating,
}) => {
  const [selectedFields, setSelectedFields] = useState([]);
  const [isNext, setNext] = useState(false);
  const classes = useStyles();

  const resetFieldSelection = () => {
    setNext(false);
    setSelectedFields([]);
  };

  const formik = useFormik({
    initialValues: {
      portfolio: '',
      offering: '',
      program: '',
      'elucidat-project-id': '',
      'course-runner': '',
      'additional-metadata': '',
      'keep-in-mind': '',
      'after-you-complete-this-course': '',
      'progress-logic': 'Include in reporting',
      'proficiency-logic': 'Include in reporting',
    },
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    validate,
    onSubmit: (values, { resetForm }) => {
      const newValues = Object.keys(values).reduce((acc, curKey) => {
        if (selectedFields.includes(curKey)) {
          acc[curKey] = values[curKey];
        }
        return acc;
      }, {});
      resetForm();
      resetFieldSelection();
      onBulkUpdate(newValues);
    },
  });

  const {
    handleSubmit, handleChange, values, setFieldValue, resetForm, errors, setErrors,
  } = formik;

  const preClose = () => {
    resetForm();
    onClose();
    resetFieldSelection();
  };

  const onCheck = ({ target: { checked, value } }) => {
    let selectedFieldsValues = [...selectedFields];
    if (value === 'portfolio') {
      value = ['portfolio', 'offering', 'program'];
    } else {
      value = [value];
    }
    if (checked) {
      selectedFieldsValues = selectedFieldsValues.concat(value);
    } else {
      selectedFieldsValues = selectedFieldsValues.filter((item) => !value.includes(item));
    }
    setSelectedFields(selectedFieldsValues);
  };

  return (
    <ConfirmationModal
      open={open}
      onClose={preClose}
      isContainForm
      onSubmit={handleSubmit}
      dialogContentClass={classes.width100}
      minHeightClass={classes.modalHeight}
      breakpoint="md"
      isLoading={isLoading}
      modalWidth={90}
      title={(
        <FormattedTypography
          variant="subtitle2"
          body="Edit metadata for selected"
          suffix={'\u00A0\'Courses / ILTs\''}
          subHeading="Any changes made to the metadata here will reflect in Docebo setting too."
          dense
          className={classes.textLight}
          suffixClassName={classes.bold}
          justifyContent="flex-start"
          subHeadingAlignment="left"
        />
      )}
      contentHeader={<FormProgress completedSteps={!isNext ? 0 : 1} />}
      content={(
        <>
          <Grid container justifyContent="space-between" className={classes.bulkModalWrapper}>
            <Grid item xs={4}>
              <SelectedUserTable
                title="Selected courses"
                list={selectedData}
              />
            </Grid>
            <Divider light orientation="vertical" flexItem sx={{ ml: '3rem' }} />

            {!isNext && (
            <Grid item xs>
              <FieldSelectionForm
                heading="Select metadata fields you wish to edit"
                fields={filedsToUpdate}
                selectedFields={selectedFields}
                onCheck={onCheck}
              />
            </Grid>
            )}

            {isNext && selectedFields.length > 0 && (
            <Grid item xs>
              <MetadataFormTemplate
                values={values}
                single={false}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                options={options}
                selectedFields={selectedFields}
                errors={errors}
                setErrors={setErrors}
                isValidating={isValidating}
                setIsValidating={setIsValidating}
                status={status}
                setStatus={setStatus}
              />
            </Grid>
            )}

          </Grid>
        </>
      )}
      actions={(
        isNext ? (
          <>
            <Button variant="outlined" onClick={() => setNext(false)}>
              Back
            </Button>
            <Button color="primary" variant="contained" type="submit" disabled={errors['elucidat-project-id'] || isValidating || (selectedFields.includes('portfolio') && !values.portfolio) || (selectedFields.includes('offering') && !values.offering)}>
              Save Changes
            </Button>
          </>
        ) : (
          <Button variant="outlined" onClick={() => setNext(true)} disabled={!selectedFields.length}>
            Next
          </Button>
        )
      )}
    />
  );
};

export default BulkEditMetadata;

BulkEditMetadata.defaultProps = {
};

BulkEditMetadata.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedData: PropTypes.arrayOf(PropTypes.string).isRequired,
  onBulkUpdate: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  status: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired,
  isValidating: PropTypes.bool.isRequired,
  setIsValidating: PropTypes.func.isRequired,

};
