import * as React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import DialogTitle from '@material-ui/core/DialogTitle';
import FormattedTypography from '../../common/FormattedTypography';
import {
  MEDIUM_GREY,
} from '../../../stylesheets/colors';
import CustomUploadModal from '../../common/CustomUploadModal';
import GenericProgressModal from '../../common/GenericProgressModal';
import { getUserFromLocalStorage } from '../../../helpers/userHelper';
import {
  USER_INFO_KEY,
} from '../../../constants';
import { LoaderInner } from '../../Loader';

const useStyles = makeStyles({
  subHeadingSection: {
    '& p': {
      color: MEDIUM_GREY,
    },
  },
  progressRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.17,
    justifyContent: 'center',
    maxHeight: '10rem',
    transform: 'scale(0.4) translate(0, -100%)',
    padding: '2rem 0',
  },
});

const ScormFileUpload = ({
  open, progress, fileName, cancelFileUpload,
}) => {
  const classes = useStyles({});
  const userInfo = getUserFromLocalStorage(USER_INFO_KEY);

  return (
    <CustomUploadModal
      open={open}
      onClose={cancelFileUpload}
      heading={(
        <DialogTitle>
          <FormattedTypography
            body="Upload SCORM package"
            subHeading={`Uploading SCORM zip file ${fileName}.`}
            subHeadingSection={classes.subHeadingSection}
          />
        </DialogTitle>
      )}
      logo={(
        <div className={classes.progressRow}>
          <LoaderInner
            progress={progress || 0}
            scaleFactor={1.5}
          />
        </div>
      )}
      primaryBtnText="Cancel"
      primaryBtnProps={{
        onClick: cancelFileUpload,
      }}
    >
      <GenericProgressModal
        initiatedBy={userInfo?.email}
        initiatedAt={new Date()}
        width="80%"
      />
    </CustomUploadModal>
  );
};

ScormFileUpload.propTypes = {
  open: PropTypes.bool.isRequired,
  progress: PropTypes.number.isRequired,
  fileName: PropTypes.string.isRequired,
  cancelFileUpload: PropTypes.func.isRequired,
};

export default ScormFileUpload;
