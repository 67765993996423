import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Loader from '../../components/Loader';
import AssignContentBody from '../../components/CourseProdReportBody/AssignContentBody';

import { getProgress, getReport } from '../common/apis';

import {
  REPORT_LOADING_PAYLOAD,
  ROUTE_ERROR,
  ROUTE_REPORT,
  ROUTE_CONTENT_ASSIGNMENT,
  PROGRAM_CREATION,
} from '../../constants';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

const FindContentReportPage = (props) => {
  const { match, location, transactionIds } = props;

  // Just one txn id for this operation
  const transactionId = get(transactionIds, 0, null);

  // const programSubType = FIND_CONTENT;
  const programId = get(match, 'params.programId');

  const history = useHistory();
  const classes = useStyles();

  const [progress, setProgress] = useState({ done: false, percentage: 0 });
  const [report, setReport] = useState(REPORT_LOADING_PAYLOAD);

  useEffect(() => {
    let timer = null;
    const pollProgressApi = async () => {
      try {
        const res = await getProgress(transactionId);
        if (res.data.done) {
          const reportResult = await getReport(transactionId);
          setProgress({ done: res.data.done, percentage: res.data.percentage });
          setReport(reportResult);
        } else {
          setProgress({ done: res.data.done, percentage: res.data.percentage });
          timer = setTimeout(pollProgressApi, 1000);
        }
      } catch (err) {
        const status = get(err, 'response.status');
        if (status >= 500) {
          console.error(err);
        } else {
          history.replace(`/${ROUTE_ERROR}`);
        }
      }
    };
    pollProgressApi();

    return () => {
      clearTimeout(timer);
    };
  }, [transactionId, history]);

  const onAssignClick = (courseId) => {
    history.push(`/${ROUTE_REPORT}/${programId}/${PROGRAM_CREATION}/${transactionId}/${ROUTE_CONTENT_ASSIGNMENT}/${courseId}`);
  };

  const loaderDom = progress.done ? null : <Loader progress={progress.percentage} />;

  const bodyDom = !progress.done ? null : (
    <div className={classes.bodyContainer}>
      <AssignContentBody
        data={report}
        onAssignClick={onAssignClick}
        match={match}
        location={location}
        transactionId={transactionId}
      />
    </div>
  );

  return (
    <div className={classes.wrapper}>
      {loaderDom}
      {bodyDom}
    </div>
  );
};

FindContentReportPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.any,
    search: PropTypes.string,
  }).isRequired,
  transactionIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FindContentReportPage;
