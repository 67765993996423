import React, { useState, useCallback } from 'react';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';

import ValidationError from '../assets/img/validation-error.webp';
import FormattedTypography from '../components/common/FormattedTypography';
import { parseValidationResult } from '../helpers/xlsxValidation';
import { zeroAppender } from '../helpers/formattingHelpers';
import { LoaderInner } from '../components/Loader';

// eslint-disable-next-line max-len
const useUploadExcel = (ValidationComponent, ReportComponent, ProgressInstruction, onFileUpload) => {
  const initialValidationResult = { status: 'none', errors: [] };
  const [modalState, setModalState] = useState(null);
  const [modalSchema, setModalScheme] = useState({
    VALIDATION: {
      component: ValidationComponent,
      wrapperProps: {
        logo: <img src={ValidationError} alt="validation error" width={80} height={80} />,
        heading: null,
        primaryBtnText: 'Re-upload File',
        primaryBtnProps: {
          onClick: setModalState.bind(null, null),
        },
        contentDivider: true,
      },
      props: initialValidationResult,
    },
    UPLOAD: {
      component: ReportComponent,
      wrapperProps: {
        logo: null,
        heading: null,
        primaryBtnText: undefined,
        primaryBtnProps: {
          onClick: () => { },
        },
        contentDivider: true,
        disableGutters: true,
      },
      props: {
        successCount: 0,
        failCount: 0,
        totalCount: 0,
        fileName: '',
      },
    },
    PROGRESS: {
      component: ProgressInstruction,
      wrapperProps: {
        logo: (
          <div>
            <LoaderInner progress={0} scaleFactor={0.75} />
          </div>
        ),
        heading: null,
        breakpoint: 'md',
        secondaryBtnText: 'Close',
        secondaryBtnProps: {
          onClick: setModalState.bind(null, null),
        },
      },
      props: {
        created_by: '',
        created_time: '',
      },
    },
  });

  const closeModal = () => {
    setModalState(null);
  };

  const resetFileUpload = useCallback(
    () => {
      setModalScheme((schema) => ({
        ...schema,
        VALIDATION: {
          ...schema.VALIDATION,
          props: initialValidationResult,
        },
      }));
    },
    [initialValidationResult],
  );

  const showProgress = useCallback(({
    percentage, fileName, created_by, created_time,
  }) => {
    setModalState('PROGRESS');
    setModalScheme((schema) => ({
      ...schema,
      PROGRESS: {
        ...schema.PROGRESS,
        wrapperProps: {
          ...schema.PROGRESS.wrapperProps,
          heading: (
            <FormattedTypography
              body="Component Meta"
              subHeading={`Uploading component metadata file ‘${fileName || 'temp.xls'}`}
            />
          ),
          logo: (
            <div>
              <LoaderInner
                progress={percentage || 0}
                scaleFactor={0.75}
              />
            </div>
          ),
        },
        props: {
          ...schema.PROGRESS.props,
          created_by,
          created_time,
        },
      },
    }));
  }, []);

  const showReport = useCallback(({
    logo, heading, primaryBtnText, primaryBtnProps, successCount, failCount, totalCount, fileName,
  }) => {
    setModalState('UPLOAD');
    setModalScheme((schema) => ({
      ...schema,
      UPLOAD: {
        ...schema.UPLOAD,
        wrapperProps: {
          ...schema.UPLOAD.wrapperProps,
          logo,
          heading,
          primaryBtnText,
          primaryBtnProps: {
            ...schema.UPLOAD.wrapperProps.primaryBtnProps,
            ...primaryBtnProps,
          },
        },
        props: {
          successCount,
          failCount,
          totalCount,
          fileName,
        },
      },
    }));
  }, []);

  const handleUpload = useCallback(
    async (formData, file, validateCall, onValidation, args) => {
      resetFileUpload();
      let result;
      try {
        result = await validateCall(
          ...args,
          formData,
        );
      } catch (e) {
        console.error(e);
        return; // Early exit
      }
      const errorMessages = parseValidationResult(result);
      const errorMessageCount = errorMessages.errors?.length || 0;
      const [extension, ...nameParts] = file.name.split('.').reverse();
      batchUpdates(() => {
        setModalScheme((schema) => ({
          ...schema,
          VALIDATION: {
            ...schema.VALIDATION,
            wrapperProps: {
              ...schema.VALIDATION.wrapperProps,
              heading: (
                <FormattedTypography
                  prefix="Scanning file&nbsp;"
                  body={nameParts.join('.')}
                  suffix={`.${extension}`}
                  subHeading={`${zeroAppender(
                    errorMessageCount,
                  )} errors found. Please make the required changes and re-upload your file.`}
                />
              ),
            },
            props: errorMessages,
          },
        }));
      });
      if (errorMessages.errors.length) {
        setModalState('VALIDATION');
        onValidation();
        return;
      }

      await onFileUpload(formData);
    },
    [onFileUpload, resetFileUpload],
  );

  return {
    modalSchema,
    modalState,
    closeModal,
    showReport,
    showProgress,
    onFileValidate: handleUpload,
  };
};

export default useUploadExcel;
