import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';

const FilterSection = ({
  summary, onChange, children, ...props
}) => (
  <Accordion
    disableGutters
    elevation={0}
    sx={{
      '& .MuiAccordionSummary-content h6': {
        fontWeight: '900',
      },
      '&.MuiAccordion-root:before': {
        opacity: 1,
      },
    }}
    onChange={onChange}
    {...props}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon fontSize="large" />}
      aria-controls="panel1-content"
      id="panel1-header"
      sx={{ padding: 0, fontWeight: '500' }}
    >
      {summary}
    </AccordionSummary>
    <AccordionDetails>
      {children}
    </AccordionDetails>
  </Accordion>
);

FilterSection.defaultProps = {
  onChange: () => { },
};

FilterSection.propTypes = {
  summary: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
};

export default FilterSection;
