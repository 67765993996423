import React from 'react';
import { Link } from 'react-router-dom';

import GlobalHeader from '../ds-react-components/GlobalHeader';

import mckAcademyLogo from '../../assets/img/mck-academy-logo.svg';
import { ROUTE_HOME } from '../../constants';
import Config from '../../config';

const SimpleHeader = () => (
  <GlobalHeader>
    <GlobalHeader.Left>
      <Link color="textPrimary" to={`${Config.BASE_PATH}${ROUTE_HOME}`}>
        <img src={mckAcademyLogo} title="mckAcademyLogo" alt="mckAcademyLogo" />
      </Link>
    </GlobalHeader.Left>
  </GlobalHeader>
);

SimpleHeader.propTypes = {
};

export default SimpleHeader;
