import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { Draggable } from 'react-beautiful-dnd';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import CheckIcon from '@mui/icons-material/Check';
import { MCKINSEY_BLUE, LIGHT_MEDIUM_GREY, WHITE } from '../../stylesheets/colors';
import EmptyComponentTitle from '../../assets/img/empty-component-title.svg';
import CustomPopover from '../common/CustomPopover';
import ThreeDotButton from '../common/ThreeDotButton';
import InfoTooltip from '../common/InfoTooltip';
import EOPIcon from '../../assets/icons/eop-icon.svg';
import MoveIcon from '../../assets/icons/move-icon.svg';

const useStyles = makeStyles({
  componentTitle: {
    display: 'flex',
    alignItems: 'center',

    '& :hover': {
      cursor: 'pointer',
    },

    '& img': {
      height: '1.75rem',
      width: '3.125rem',
    },

    '& span': {
      marginLeft: '2.25rem',
    },
  },
  contentType: {
    display: 'flex',
    alignItems: 'center',

    '& img': {
      height: '1.25rem',
      width: '1.25rem',
    },
    '& span': {
      marginLeft: '4px',
    },
  },
  userAccess: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '1.25rem',
      height: '1.25rem',
    },
  },
  trainingMatCount: {
    cursor: 'pointer',
    color: MCKINSEY_BLUE,
    textDecoration: 'underline',
  },
  orderIndicator: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  indicator: {
    height: '1.25rem',
    width: ' 1.25rem',
    borderRadius: ' 50%',
  },
  orderText: {
    paddingLeft: '0.5rem',
  },
  move: {
    width: '20px',
    height: '20px',
  },
  draggingRow: {
    '& td': {
      borderBottom: 0,
    },
  },
});

const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? LIGHT_MEDIUM_GREY : WHITE,
  ...draggableStyle,
});

const EnhancedTableRow = ({
  row, index, enableReordering, selectedComponents, handleCheckboxChange, updatedComponentsId,
  copiedComponentData, goToView, myRef, openEOPComponentModal, onTmSelect, openCopyComponentModal,
  isDDAdmin,
}) => {
  const classes = useStyles();

  const highlightBlue = updatedComponentsId?.indexOf(row?.docebo_course_id) > -1
    || (copiedComponentData?.new_component_code
      && row?.course_code === copiedComponentData?.new_component_code);

  const showEopIcon = row.eop && row?.eop === true;

  const eopTitle = showEopIcon ? 'Unassign EOP Feedback' : 'Assign EOP Feedback';

  return (
    <Draggable
      key={row.id}
      index={index}
      draggableId={`q-${row.docebo_id}`}
      isDragDisabled={!enableReordering}
    >
      {(providedDrag, snapshot) => (
        <TableRow
          ref={providedDrag.innerRef}
          {...providedDrag.draggableProps}
          {...providedDrag.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            providedDrag.draggableProps.style,
          )}
          className={snapshot.isDragging ? classes.draggingRow : ''}
        >
          <TableCell>
            {enableReordering ? (
              <Box className={classes.dragger} {...providedDrag.dragHandleProps}>
                <img className={classes.move} src={MoveIcon} alt="drag" />
              </Box>
            ) : (
              <Checkbox
                className={classes.checkbox}
                checked={
                  selectedComponents.map((item) => item.id).includes(row.id)
                }
                onChange={(e) => handleCheckboxChange(e, row)}
                name="checkComponent"
                color="primary"
                data-testid="selectrow"
                classes={{
                  checked: classes.checkboxChecked,
                }}
              />
            )}
          </TableCell>
          <TableCell align="left">
            <Box className={classes.orderIndicator}>
              {highlightBlue ? (
                <Box
                  ref={myRef}
                  className={classes.indicator}
                  style={{ backgroundColor: MCKINSEY_BLUE }}
                />
              ) : (
                <Box
                  className={classes.indicator}
                />
              )}
              <Typography className={classes.orderText}>{row.order}</Typography>
            </Box>
          </TableCell>
          <TableCell>
            <Box
              className={classes.componentTitle}
              onClick={() => goToView(row)}
            >
              {row?.course_graphic ? <img src={row?.course_graphic} alt="img" /> : <img src={EmptyComponentTitle} alt="img" />}
              <span>{row?.component_title || '-'}</span>
            </Box>
          </TableCell>
          <TableCell align="left">{row.code}</TableCell>
          <TableCell align="left">
            <div className={classes.contentType}>
              <img src={row.type.icon} alt="img" />
              <span>{row?.type?.label.includes('classroom') ? '-' : row?.type?.label}</span>
            </div>
          </TableCell>
          <TableCell align="left">
            <Typography
              className={row?.no_of_tm ? classes.trainingMatCount : {}}
              onClick={row?.no_of_tm ? () => onTmSelect(row) : () => { }}
            >
              {row?.no_of_tm}
            </Typography>
            <span />
          </TableCell>
          <TableCell align="left">{row.estimated_time}</TableCell>
          <TableCell align="left">{row.docebo_id}</TableCell>
          <TableCell align="center">
            {row?.is_required ? <CheckIcon /> : ''}
          </TableCell>
          <TableCell align="left">
            <div className={classes.userAccess}>
              <img src={row.user_access.icon} alt="img" />
              <span style={{ marginLeft: '4px' }}>{row.user_access.label}</span>
            </div>

          </TableCell>
          <TableCell align="left">
            {showEopIcon && (
              <InfoTooltip title="Feedback required at the end of this course">
                <img src={EOPIcon} alt="eop" />
              </InfoTooltip>
            )}
          </TableCell>
          <TableCell align="left">
            <CustomPopover
              list={[
                {
                  title: 'View Component',
                  onClick: () => goToView(row),
                },
                (!isDDAdmin ? {
                  title: 'Copy component shell',
                  onClick: () => openCopyComponentModal(row),
                } : null),
                (!isDDAdmin
                  ? {
                    title: eopTitle,
                    onClick: () => openEOPComponentModal(row),
                  } : null),
              ]}
              button={ThreeDotButton}
            />
          </TableCell>
        </TableRow>
      )}
    </Draggable>
  );
};

EnhancedTableRow.defaultProps = {
  copiedComponentData: {
    new_component_title: '',
    new_component_code: '',
  },
};

EnhancedTableRow.propTypes = {
  index: PropTypes.number.isRequired,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    docebo_id: PropTypes.number.isRequired,
    docebo_course_id: PropTypes.number.isRequired,
    course_code: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    course_graphic: PropTypes.string.isRequired,
    component_title: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    type: PropTypes.shape({
      icon: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
    no_of_tm: PropTypes.number.isRequired,
    estimated_time: PropTypes.string.isRequired,
    user_access: PropTypes.shape({
      icon: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
    is_required: PropTypes.bool.isRequired,
    eop: PropTypes.bool.isRequired,
  }).isRequired,
  copiedComponentData: PropTypes.shape({
    new_component_title: PropTypes.string,
    new_component_code: PropTypes.string,
  }),
  updatedComponentsId: PropTypes.array.isRequired,
  onTmSelect: PropTypes.func.isRequired,
  goToView: PropTypes.func.isRequired,
  openCopyComponentModal: PropTypes.func.isRequired,
  openEOPComponentModal: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  myRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  isDDAdmin: PropTypes.bool.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  selectedComponents: PropTypes.array.isRequired,
  enableReordering: PropTypes.bool.isRequired,
  onDragEnd: PropTypes.func.isRequired,
};

export default EnhancedTableRow;
