import React, {
  useState, useEffect, useCallback,
} from 'react';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import differenceInSeconds from 'date-fns/differenceInSeconds';

import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Loader from '../../components/Loader';
import IgnoreErrorsBanner from '../../components/IgnoreErrorsBanner';
import XlsxValidationModal from '../../components/XlsxValidationModal';
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import ConflictErrorModal from '../../components/ConflictErrorModal/ConflictErrorModal';
import ProgramCreationReportBody from '../../components/CourseProdReportBody/ProgramCreationReportBody';
import LinkModal from '../../components/CourseProdReportBody/FlowCommonComponents/LinkModal';
import LinkModalCreated from '../../components/CourseProdReportBody/FlowCommonComponents/LinkModalCreated';
import CancelSuggestionModal from '../../components/common/CancelSuggestionModal';

import { getEnvironmentFromLocalStorage } from '../../helpers/userHelper';
import { parseValidationResult } from '../../helpers/xlsxValidation';
import {
  cancelTransaction,
  createProgramLinks,
  getProgress,
  getReport,
  validateXLSXApiCall,
  uploadXlsx,
  downloadFile,
  ignoreErrorsForTransaction,
} from '../common/apis';

import {
  REPORT_LOADING_PAYLOAD,
  PROGRAM_CREATION,
  ROUTE_REPORT,
  ROUTE_CONTENT_ASSIGNMENT,
  ROUTE_HOME,
  INVALID_URL,
  BOX_URL,
  DOCEBO_URL,
  DOCEBO_URL_2,
  WRIKE_URL,
  THRESHOLD_TIME,
} from '../../constants';
import { internalServerErrorModalLogic } from '../common/utils';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

const ProgramCreationReportPage = (props) => {
  const {
    match, location, transactionIds, programMetadata, onProgramMetadataRefresh,
  } = props;

  // Just one txn id for this operation
  const transactionId = get(transactionIds, 0, null);

  const programId = get(match, 'params.programId');
  const programSubType = get(match, 'params.programSubType');
  // const routeTransactionId = get(match, 'params.transactionId');

  const history = useHistory();
  const classes = useStyles();

  const [progress, setProgress] = useState({ done: false, percentage: 0 });
  const [report, setReport] = useState(REPORT_LOADING_PAYLOAD);
  const initialValidationResult = { status: 'none', errors: [] };
  const [validationResult, setValidationResult] = useState(initialValidationResult);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLinkModalOpen, setLinkModalOpen] = useState(true);
  const [openCancelSuggestionModal, setOpenCancelSuggestionModal] = useState(false);
  const [userClosedCancelSuggestionModal, setUserClosedCancelSuggestionModal] = useState(false);
  const [isLinkCreatedModalOpen, setLinkCreatedModalOpen] = useState(false);
  const [pollProgress, setPollProgress] = useState(true);
  const [skipped, setSkipped] = useState(false);
  const [cancellationRequested, setCancellationRequested] = useState(false);
  const [linkCreationError, setLinkCreationError] = useState('');
  const [isConflictErrorModalOpen, setIsConflictErrorModalOpen] = useState(false);

  useEffect(() => {
    let timer = null;
    const pollProgressApi = async () => {
      if (!transactionId) return;
      try {
        const res = await getProgress(transactionId);
        if (res.data.done) {
          const reportResult = await getReport(transactionId);
          batchUpdates(() => {
            setProgress(res.data);
            setReport(reportResult);
            setPollProgress(false);
          });
        } else {
          if (!userClosedCancelSuggestionModal && res.data.percentage > 0
            && differenceInSeconds(new Date(), new Date(`${res.data.updated_at}Z`)) > THRESHOLD_TIME.CREATE_PROGRAM) {
            setOpenCancelSuggestionModal(true);
          }
          setProgress(res.data);
          timer = setTimeout(pollProgressApi, 1000);
        }
      } catch (err) {
        timer = internalServerErrorModalLogic(history, err, setIsErrorModalOpen, pollProgressApi);
      }
    };
    pollProgressApi();

    return () => {
      clearTimeout(timer);
    };
  }, [pollProgress, transactionId, programSubType, history, userClosedCancelSuggestionModal]);

  // Check if the XLSX has errors and the user wishes to rectify them
  const isRectifyable = () => {
    const { overall_success: isSuccessful, marked_by: markedBy } = report.meta;
    // Explicit equation to false to not allow nulls or undefineds
    return isSuccessful === false && !markedBy;
  };

  const onDownload = async () => {
    await downloadFile(transactionId, report.meta.title, PROGRAM_CREATION, report.meta.created_at);
  };

  const resetFileUpload = (e) => {
    setValidationResult({ status: 'none', errors: [] });
    e.target.value = '';
    // document.getElementById('fileInput').value = '';
  };

  const resetCancellationStatus = () => {
    setCancellationRequested(false);
  };

  const onReUpload = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('transaction_id', transactionId);
    resetFileUpload(event);
    resetCancellationStatus();
    let result;
    try {
      result = await validateXLSXApiCall(programSubType, formData);
    } catch (e) {
      console.error(e);
      // TODO: Show a toast, unable to upload
      return; // Early exit
    }
    const errorMessages = parseValidationResult(result);
    setValidationResult(errorMessages);
    // Early exit
    if (errorMessages.errors.length !== 0) return;

    setProgress({ done: false, percentage: 0 });
    try {
      await uploadXlsx(programSubType, formData);
    } catch (e) {
      console.error(e);
      if (e.response.status === 409) {
        setIsConflictErrorModalOpen(true);
      } else {
        setProgress({ done: true, percentage: 100 });
      }
      // TODO: Show a toast, unable to upload
      return;
    }
    // Restart the progress API and clear background
    setPollProgress(true);
    onProgramMetadataRefresh();
  };

  const onIgnoreErrorsClick = async () => {
    await ignoreErrorsForTransaction(transactionId);

    // Reload the report data
    const reportResult = await getReport(transactionId);
    setReport(reportResult);
  };

  // Show the LinkModal or not
  useEffect(() => {
    const isAnyUrlPresent = programMetadata.links[BOX_URL] !== undefined
      || programMetadata.links[DOCEBO_URL] !== undefined
      || programMetadata.links[WRIKE_URL] !== undefined;

    const shouldOpenLinkModal = !isAnyUrlPresent;
    setLinkModalOpen(shouldOpenLinkModal);
  }, [setLinkModalOpen, programMetadata]);

  // Autoclose the LinkCreated modal when done
  useEffect(() => {
    if (progress.done) {
      setLinkCreatedModalOpen(false);
    }
  }, [setLinkCreatedModalOpen, progress.done]);

  const onLinkSave = async (linkObj) => {
    try {
      await createProgramLinks(programId, linkObj);
      await onProgramMetadataRefresh();
      batchUpdates(() => {
        setLinkCreationError('');
        setLinkModalOpen(false);
        // Should show when not done
        if (!progress.done) {
          setLinkCreatedModalOpen(true);
        }
      });
    } catch (e) {
      // TODO: Show error toast
      const status = get(e, 'response.status', '');
      if (status === 400) {
        setLinkCreationError(INVALID_URL);
      } else {
        setLinkCreationError('');
      }
      console.error(e);
    }
  };

  const onLinkModalSkip = async (payload) => {
    setSkipped(true);
    await onLinkSave(payload);
  };

  const environment = getEnvironmentFromLocalStorage();

  const onAssignClick = (courseId) => {
    history.push(
      `/${ROUTE_REPORT}/${programId}/${PROGRAM_CREATION}/${transactionId}/${ROUTE_CONTENT_ASSIGNMENT}/${courseId}`,
    );
  };

  const onErrorModalClose = () => {
    setIsErrorModalOpen(false);
    history.replace(`/${ROUTE_HOME}`);
  };

  const onConflictModalClose = () => {
    setIsConflictErrorModalOpen(false);
  };

  const onCancelTransaction = useCallback(() => cancelTransaction(transactionId), [transactionId]);

  const onCancelSuggestionClose = useCallback(async () => {
    await onCancelTransaction();
    setUserClosedCancelSuggestionModal(true);
    setOpenCancelSuggestionModal(false);
  }, [onCancelTransaction]);

  const shouldShowIgnoreBanner = isRectifyable();

  const loaderDom = progress.done ? null : (
    <Loader
      progress={progress.percentage}
      estimatedTimeSeconds={progress.estimated_time_left}
      onCancelTransaction={onCancelTransaction}
      cancellationRequested={cancellationRequested}
      setCancellationRequested={setCancellationRequested}
    />
  );

  const bodyDom = !progress.done ? null : (
    <div className={classes.bodyContainer}>
      {shouldShowIgnoreBanner && (
        <IgnoreErrorsBanner onSaveChanges={onIgnoreErrorsClick} programSubType={programSubType} />
      )}
      <ProgramCreationReportBody
        programMetadata={programMetadata}
        data={report}
        onReUpload={onReUpload}
        onDownload={onDownload}
        environmentUrl={environment.url}
        onAssignClick={onAssignClick}
        match={match}
        location={location}
        transactionId={transactionId}
      />
    </div>
  );

  return (
    <div className={classes.wrapper}>
      {loaderDom}
      {bodyDom}
      <XlsxValidationModal
        open={validationResult.status === 'error'}
        onClose={() => setValidationResult(initialValidationResult)}
        onOk={() => setValidationResult(initialValidationResult)}
        errors={validationResult.errors}
      />
      <ErrorModal open={isErrorModalOpen} onClose={onErrorModalClose} />
      <ConflictErrorModal open={isConflictErrorModalOpen} onClose={onConflictModalClose} />
      <LinkModal
        open={isLinkModalOpen}
        progress={progress.percentage}
        onClose={onLinkSave} // Same effect
        onSave={onLinkSave}
        onSkip={onLinkModalSkip}
        programMetadata={programMetadata}
        errorText={linkCreationError}
      />
      <LinkModalCreated
        open={isLinkCreatedModalOpen}
        progress={progress.percentage}
        onClose={() => setLinkCreatedModalOpen(false)}
        programMetadata={programMetadata}
        skipped={skipped}
        cancellationRequested={cancellationRequested}
        setCancellationRequested={setCancellationRequested}
        onCancelTransaction={onCancelTransaction}
      />
      <CancelSuggestionModal
        open={openCancelSuggestionModal}
        onCancel={() => setCancellationRequested(true)}
        onClose={onCancelSuggestionClose}
      />
    </div>
  );
};

ProgramCreationReportPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.any,
    search: PropTypes.string,
  }).isRequired,
  transactionIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onProgramMetadataRefresh: PropTypes.func.isRequired,
  programMetadata: PropTypes.shape({
    links: PropTypes.shape({
      [BOX_URL]: PropTypes.string,
      [DOCEBO_URL]: PropTypes.string,
      [DOCEBO_URL_2]: PropTypes.string,
      [WRIKE_URL]: PropTypes.string,
    }),
  }).isRequired,
};

export default ProgramCreationReportPage;
