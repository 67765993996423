import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { ACCORDION_GREY, MCKINSEY_BLUE } from '../../../../../stylesheets/colors';
import PageIcon from '../../../../../assets/img/NoPages.png';

const useStyles = makeStyles(() => ({
  pageDataWrapper: {
    marginBottom: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > div': {
      display: 'flex',
      maxWidth: '80%',
      '& > img': {
        height: '3rem',
        width: '3rem',
      },
    },
  },
  textInfo: {
    marginLeft: '1rem',

    '& > p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      boxOrient: 'vertical',
    },
    '& > p:nth-child(1)': {
      lineClamp: 1,
    },
    '& > p:nth-child(2)': {
      lineClamp: 2,
    },
  },
  pageTitle: {
    fontWeight: 600,
    fontSize: '1.25rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 1,
    boxOrient: 'vertical',
  },
  clickablePageTitle: {
    color: MCKINSEY_BLUE,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  title: {
    fontSize: '0.875rem',
    color: ACCORDION_GREY,
    whiteSpace: 'nowrap',
  },
  value: {
    fontSize: '0.875rem',
    fontWeight: 600,
    marginLeft: '2px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 1,
    boxOrient: 'vertical',
  },
  pageIdWrapper: {
    display: 'flex',
    marginTop: '0.375rem',
  },
  pageActionItems: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > div:nth-child(1)': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      textAlign: 'center',

      '&  > button': {
        fontSize: '1rem',
        fontWeight: 600,
        padding: 0,
      },

      '& > p': {
        color: MCKINSEY_BLUE,
      },
    },
    '& > p': {
      color: ACCORDION_GREY,
      marginTop: '0.375rem',
    },
  },
}));

const PageInfo = ({
  page, handlePageDelete, handlePageEdit, allowRedirection, is_locked,
}) => {
  const classes = useStyles({ });
  const {
    journal_title, page_id, id, journal_prompts,
  } = page;
  const history = useHistory();
  const journalLength = journal_prompts.length;

  const getPromptMetadataJsx = () => {
    let promptMetadataJsx;
    if (!allowRedirection) {
      promptMetadataJsx = 'No prompts allowed';
    } else if (journal_prompts.length > 0) {
      promptMetadataJsx = `Showing ${journalLength > 4 ? 4 : journalLength} of ${journalLength} ${journalLength > 1 ? 'prompts' : 'prompt'}`;
    }

    return (
      <Typography variant="h2" component="p">
        {promptMetadataJsx}
      </Typography>
    );
  };
  return (
    <Box>
      <Box className={classes.pageDataWrapper}>
        <Box>
          <img src={PageIcon} alt="dots" />
          <Box className={classes.textInfo}>
            <Typography
              className={`${classes.pageTitle} ${allowRedirection ? classes.clickablePageTitle : ''}`}
              onClick={allowRedirection ? () => {
                history.push(`/contentLibrary/lti/pages/${id}`);
              } : () => {}}
            >
              {journal_title}
            </Typography>
            <Box className={classes.pageIdWrapper}>
              <Typography className={classes.title}>Page ID:</Typography>
              <Typography className={classes.value}>{page_id}</Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.pageActionItems}>
          <Box>
            <Button variant="textSecondary" disabled={is_locked} onClick={() => { handlePageEdit(page); }}>Edit</Button>
            <Typography> | </Typography>
            <Button variant="textSecondary" disabled={is_locked} onClick={() => { handlePageDelete(page); }}>Delete</Button>
          </Box>
          {getPromptMetadataJsx()}
        </Box>
      </Box>
    </Box>
  );
};

PageInfo.propTypes = {
  page: PropTypes.object.isRequired,
  handlePageDelete: PropTypes.func.isRequired,
  handlePageEdit: PropTypes.func.isRequired,
  allowRedirection: PropTypes.bool.isRequired,
  is_locked: PropTypes.bool.isRequired,
};

export default PageInfo;
