import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import _find from 'lodash/find';
import _includes from 'lodash/includes';
import _pickBy from 'lodash/pickBy';
import _isEmpty from 'lodash/isEmpty';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@mui/icons-material/Close';
import ConflictErrorModal from '../../components/ConflictErrorModal/ConflictErrorModal';
import { getReport, getExperienceTypeOptions, fetchActiveLanguages } from '../common/apis';
import {
  addTestUsers,
  updateTestUsers,
  deleteTestUsers,
  activateTestUsers,
  deactivateTestUsers,
  updateTestUsersProperty,
  updateTestUsersByLPs,
} from './apis';

import CreateTestUsersReportPage from '../../components/CreateTestUsers/CreateTestUsersReportPage';
import CreateTestUsersOperationsPage from '../../components/CreateTestUsers/CreateTestUsersOperationsPage';
import TestUserHeader from '../../components/CreateTestUsers/TestUserHeader';
import { DEFAULT, FEATURE_CLICKED } from '../../constants';
import useAlertBar from '../../hooks/useAlertBar';
import AlertBarWithAction from '../../components/common/AlertBarWithAction';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  alertSnackBar: {
    display: 'flex',
    alignItems: 'center',
  },
});

const TestUserCreationContainer = (props) => {
  const {
    match,
    transactionIds,
    onProgramMetadataRefresh,
    programMetadata,
    extendedEnterpriseTypeOptions,
  } = props;

  // Just one txn id for this operation
  const transactionId = get(transactionIds, 0, null);

  const classes = useStyles();
  const history = useHistory();

  const programId = Number.parseInt(get(match, 'params.programId'), 10);
  const programSubType = get(match, 'params.programSubType');
  const configId = get(programMetadata, 'config_id', '');
  const extendedEnterprise = get(programMetadata, 'extended_enterprise', '');
  const [report, setReport] = useState({});
  const [experienceTypeOptions, setExperienceTypeOptions] = useState([]);
  const [tid, setTid] = useState(transactionId);
  const [showReportPage, setShowReportPage] = useState(false);
  const [isSnackBarOpen, setSnackBarOpen] = useState(false);
  const [activeLanguages, setActiveLanguages] = useState([]);
  const [isConflictErrorModalOpen, setIsConflictErrorModalOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('Unable to connect to Docebo. Please try later.');
  const [isSuccess, setSuccess] = useState(false);

  const {
    labelText, variant, open, setAlertBarConfig, initialAlertBarConfig,
  } = useAlertBar();

  useEffect(() => {
    setTid(transactionId);
    if (transactionId) setShowReportPage(true);
    else setShowReportPage(false);
  }, [transactionId, setShowReportPage]);

  useEffect(() => {
    let timer = null;
    const pollProgressApi = async () => {
      if (!tid) return; // Not created yet
      try {
        const reportResult = await getReport(tid);
        if (!reportResult.in_progress) {
          setReport(reportResult.data);
        } else {
          setReport({}); // Show loading screen
          timer = setTimeout(pollProgressApi, 1000);
        }
      } catch (err) {
        // TODO: Show error modal
      }
    };
    pollProgressApi();

    return () => {
      clearTimeout(timer);
    };
  }, [tid]);

  useEffect(() => {
    const populateExpTypes = async () => {
      try {
        const expTypeResult = await getExperienceTypeOptions();
        const options = expTypeResult.data.data.options
          .sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }))
          .map((op) => ({ value: op, label: op }));
        setExperienceTypeOptions(options);
      } catch (e) {
        setSnackBarOpen(true);
        setExperienceTypeOptions([]);
        console.error(e);
      }
    };
    const populateActiveLanguages = async () => {
      try {
        const response = await fetchActiveLanguages();
        const activeLanguagesResult = get(response, 'data.languages', []);
        setActiveLanguages(activeLanguagesResult);
      } catch (e) {
        setSnackBarOpen(true);
        console.error(e);
      }
    };
    populateActiveLanguages();
    populateExpTypes();
  }, [setExperienceTypeOptions]);

  const refreshPage = async () => {
    onProgramMetadataRefresh();
    if (tid) {
      const reportBackup = report;
      setReport({});
      try {
        const reportResult = await getReport(tid);
        setReport(reportResult?.data);
      } catch (e) {
        if (e.response.status === 409) {
          setIsConflictErrorModalOpen(true);
        } else {
          const errors = get(e.response, 'data.errors', []);
          if (errors.length) {
            setErrorMsg(errors.join(', '));
          }
          setSnackBarOpen(true);
        }
        setReport(reportBackup);
        console.error(e);
      }
    }
  };

  const onUpdateTransaction = async (primaryExp, start, count, selectedPrograms) => {
    // Trigger loading screen
    const reportBackup = report;
    setReport({});
    try {
      await updateTestUsers(
        tid,
        primaryExp,
        start,
        count,
        get(
          extendedEnterpriseTypeOptions.find((eeOption) => eeOption.code === extendedEnterprise),
          'id',
          DEFAULT,
        ),
        selectedPrograms,
      );
      const reportResult = await getReport(tid);
      history.push({
        hash: primaryExp,
      });
      setReport(reportResult.data);
      onProgramMetadataRefresh();
    } catch (e) {
      if (e.response.status === 409) {
        setIsConflictErrorModalOpen(true);
      } else {
        const errors = get(e.response, 'data.errors', []);
        if (errors.length) {
          setErrorMsg(errors.join(', '));
        }
        setSnackBarOpen(true);
      }
      setReport(reportBackup);
      console.error(e);
    }
  };

  const onCreateTransaction = async (
    primaryExp,
    start,
    count,
    isDefaultExperience,
    selectedPrograms,
  ) => {
    // Trigger loading screen
    setShowReportPage(true);
    setReport({});
    try {
      // adding heap tracking script to track actual feature click
      window.heap.track(FEATURE_CLICKED, programSubType);

      const createResponse = await addTestUsers(
        programId,
        primaryExp,
        start,
        count,
        isDefaultExperience,
        get(
          extendedEnterpriseTypeOptions.find((eeOption) => eeOption.code === extendedEnterprise),
          'id',
          DEFAULT,
        ),
        selectedPrograms,
      );
      const newTid = createResponse.data.transaction_id;
      const reportResult = await getReport(newTid);
      setTid(newTid);
      setReport(reportResult.data);
      onProgramMetadataRefresh();
    } catch (e) {
      if (e.response.status === 409) {
        setIsConflictErrorModalOpen(true);
      } else {
        setSnackBarOpen(true);
      }
      setShowReportPage(false);
      console.error(e);
    }
  };

  const updateReport = (users, data) => {
    const updatedObj = {};
    Object.entries(data).forEach(([key, value]) => {
      updatedObj[key] = value.map((v) => {
        const temp = _find(users, { user: v.user });
        return temp || v;
      });
    });
    setReport(updatedObj);
  };

  const onStatusChange = async (users, action) => {
    const data = _cloneDeep(report);

    // Trigger loading screen
    setReport({});
    const func = action ? activateTestUsers : deactivateTestUsers;
    try {
      const response = await func(tid, users);
      if (response && response.data && response.data.success) {
        const updatedObj = {};
        Object.entries(data).forEach(([key, value]) => {
          updatedObj[key] = value.map((v) => {
            if (users.includes(v.user)) {
              return { ...v, status: action };
            }
            return v;
          });
        });
        setReport(updatedObj);
        setAlertBarConfig({
          labelText: 'Selected users updated successfully',
          variant: 'success',
          open: true,
        });
      } else {
        setReport(data);
        setAlertBarConfig({
          labelText: `${get(response, 'data.message', 'unable to update')}`,
          variant: 'error',
          open: true,
        });
        refreshPage();
      }
    } catch (e) {
      if (e.response.status === 409) {
        setIsConflictErrorModalOpen(true);
      } else {
        setSnackBarOpen(true);
        setReport(data);
      }
      console.error(e);
    }
  };

  const onTestUserUpdate = async (users, reset, language) => {
    const data = _cloneDeep(report);

    setReport({});
    try {
      const response = await updateTestUsersProperty(tid, users, reset, language);
      if (response && response.data && response.data.success) {
        updateReport(response.data.users, data);
        setAlertBarConfig({
          labelText: 'Selected users updated successfully',
          variant: 'success',
          open: true,
        });
      } else {
        setReport(data);
        setAlertBarConfig({
          labelText: `${get(response, 'data.message', 'unable to update')}`,
          variant: 'error',
          open: true,
        });
        refreshPage();
      }
    } catch (e) {
      if (e.response.status === 409) {
        setIsConflictErrorModalOpen(true);
      } else {
        setSnackBarOpen(true);
        setReport(data);
      }
      console.error(e);
    }
  };

  const onTestUserDelete = async (users) => {
    const data = _cloneDeep(report);

    try {
      setReport({});
      const response = await deleteTestUsers(tid, users);
      if (response && response.data && response.data.success) {
        const updatedObj = {};
        Object.entries(data).forEach(([key, value]) => {
          updatedObj[key] = value.filter((v) => !_includes(users, v.user));
        });
        const pickedObject = _pickBy(updatedObj, (o) => (o.length > 0));
        if (_isEmpty(pickedObject)) {
          setShowReportPage(false);
        } else {
          setReport(pickedObject);
        }
        setAlertBarConfig({
          labelText: `${response?.data?.message}`,
          variant: 'success',
          open: true,
        });
      } else {
        setReport(data);
        setAlertBarConfig({
          labelText: `${get(response, 'data.message', 'unable to update')}`,
          variant: 'error',
          open: true,
        });
        refreshPage();
      }
    } catch (e) {
      if (e.response.status === 409) {
        setIsConflictErrorModalOpen(true);
      } else {
        setSnackBarOpen(true);
        setReport(data);
      }
      console.error(e);
    }
  };

  const enrollAdditionalProgram = async (users, primaryExperience, learningPlans) => {
    const data = _cloneDeep(report);

    setReport({});
    try {
      const response = await updateTestUsersByLPs(tid, users, primaryExperience, learningPlans);
      if (response && response.data && response.data.success) {
        updateReport(response.data.users, data);
        setAlertBarConfig({
          labelText: 'Selected users updated successfully',
          variant: 'success',
          open: true,
        });
      } else {
        setReport(data);
        setAlertBarConfig({
          labelText: `${get(response, 'data.message', 'unable to update')}`,
          variant: 'error',
          open: true,
        });
        refreshPage();
      }
    } catch (e) {
      if (e.response.status === 409) {
        setIsConflictErrorModalOpen(true);
      } else {
        setSnackBarOpen(true);
        setReport(data);
      }
      console.error(e);
    }
  };

  const onConflictModalClose = () => {
    setIsConflictErrorModalOpen(false);
  };

  const onAlertClose = () => {
    setSnackBarOpen(false);
    setTimeout(() => {
      setSuccess(false);
      setErrorMsg('Unable to connect to Docebo. Please try later.');
    }, 0);
  };

  const Page = showReportPage ? CreateTestUsersReportPage : CreateTestUsersOperationsPage;

  return (
    <>
      {open && (
        <AlertBarWithAction
          variant={variant}
          labelText={labelText}
          actionButtonIcon={<CloseIcon onClick={() => setAlertBarConfig(initialAlertBarConfig)} />}
        />
      )}
      <Paper className={classes.wrapper}>
        <TestUserHeader shouldShow />
        <Page
          data={report}
          activeLanguages={activeLanguages}
          onStatusChange={onStatusChange}
          onTestUserUpdate={onTestUserUpdate}
          onTestUserDelete={onTestUserDelete}
          enrollAdditionalProgram={enrollAdditionalProgram}
          onAddPrimaryExperience={onUpdateTransaction}
          onAddMoreUsers={onUpdateTransaction}
          experienceTypeOptions={experienceTypeOptions}
          onCreate={onCreateTransaction}
          configId={configId}
          programSubType={programSubType}
        />
        <ConflictErrorModal open={isConflictErrorModalOpen} onClose={onConflictModalClose} />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={isSnackBarOpen}
          autoHideDuration={6000}
          onClose={onAlertClose}
        >
          <Alert
            className={classes.alertSnackBar}
            onClose={onAlertClose}
            severity={isSuccess ? 'success' : 'error'}
          >
            {errorMsg}
          </Alert>
        </Snackbar>
      </Paper>
    </>
  );
};

TestUserCreationContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.any,
    search: PropTypes.string,
  }).isRequired,
  transactionIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
    .isRequired,
  onProgramMetadataRefresh: PropTypes.func.isRequired,
  programMetadata: PropTypes.shape({
    config_id: PropTypes.string,
    extendedEnterprise: PropTypes.string,
  }).isRequired,
  extendedEnterpriseTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default TestUserCreationContainer;
