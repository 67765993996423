import React, {
  useRef, useState, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import {
  Box, Typography,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  MODERATE_DARK_GREY, ERROR_RED,
} from '../../stylesheets/colors';
import { fetchClientId } from '../../containers/WaveManagementPage/api';

import CustomSelect from './CustomSelect';

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '1.125rem',
    color: MODERATE_DARK_GREY,
    textTransform: 'unset',
    padding: '0.25rem 0',
    display: 'flex',
    alignItems: 'center',
  },
  red: {
    color: ERROR_RED,
  },
  select_label: {
    marginBottom: '0.8rem',
    fontSize: '1.125rem',
    fontWeight: 'normal',
  },
  fieldPadding: {
    paddingTop: '1rem',
  },
}));

const SelectClient = ({ setSelectedClient, selectedClient }) => {
  const classes = useStyles();
  const clientIdRef = useRef();

  const [clientIdList, setClientIdList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const toArrayOfObject = (arr) => arr.map((a) => ({ value: a.id, label: `${a.client_id} - ${a.client_name}` }));

  const getClientId = useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await fetchClientId();
      if (!resp.data.success) throw new Error();
      batchUpdates(() => {
        setClientIdList(toArrayOfObject(resp?.data?.data));
        setIsLoading(false);
      });
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getClientId();
  }, [getClientId]);

  const requiredFieldLabel = (label, className) => (
    <Box className={`${classes.label} ${className || ''}`}>
      <Typography>{label}</Typography>
      <span className={classes.red}>*</span>
    </Box>
  );

  const onClientIdChange = (e) => {
    setSelectedClient(e);
  };

  return (
    <CustomSelect
      selectRef={clientIdRef}
      name="client_id"
      options={clientIdList}
      className={`${classes.fieldPadding} ${classes.select_label}`}
      defaultValue={Object.keys(selectedClient).length ? selectedClient : null}
      placeholder="Select Client ID and name"
      type="select"
      label={requiredFieldLabel('Client ID and name', classes.select_label)}
      labelClass={classes.select_label}
      onChange={(e) => onClientIdChange(e)}
      isOptionsNotLoading
      selectIconLoading={isLoading}
      noOptionsMessage="Client does not exist. Please contact local admin."
    />
  );
};

SelectClient.propTypes = {
  selectedClient: PropTypes.object.isRequired,
  setSelectedClient: PropTypes.func.isRequired,
};

export default SelectClient;
