import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { useStyles, useDialogStyles } from '../../stylesheets/commonModal.styles';

const modalStyles = makeStyles({
  imageWrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    margin: 0,
    '& > :nth-child(1)': {
      margin: '3rem 0 2rem 0',
    },
  },
  closeButton: {
    alignSelf: 'unset',
    height: '46px',
    position: 'absolute',
    right: 0,
  },
  dialogTitle: {
    paddingTop: '2px',
    paddingBottom: '2px',
  },
  dialogContent: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  buttonActions: {
    marginTop: '2rem',
    marginBottom: '2rem',
  },
});

const CustomUploadModal = ({
  open, logo, heading, contentDivider, children, primaryBtnText, primaryBtnProps, secondaryBtnText,
  secondaryBtnProps, onClose, breakpoint, logoWrapper, headingDivider,
  disableGutters, contentWrapper, showActionButtons,
}) => {
  const classes = useStyles();
  const dialogClasses = useDialogStyles();
  const modalClasses = modalStyles();

  return (
    <Dialog
      maxWidth={breakpoint}
      fullWidth
      open={open}
      classes={dialogClasses}
    >
      <Container className={classes.headingContainer} data-testid="file-upload-modal" disableGutters={disableGutters}>
        {onClose && (
          <IconButton aria-label="close" className={`${classes.closeButton} ${modalClasses.closeButton}`} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
        <div className={`${modalClasses.imageWrapper} ${logoWrapper}`}>
          {logo}
        </div>
        {heading}
        {headingDivider && <Divider variant="fullWidth" className={classes.divider} light />}
        <DialogContent className={`${classes.dialogContent} ${modalClasses.dialogContent} ${contentWrapper}`}>
          {children}
        </DialogContent>
        {contentDivider && <Divider variant="fullWidth" className={classes.divider} light />}
        {showActionButtons ? (
          <DialogActions className={`${classes.buttonActions} ${modalClasses.buttonActions}`} style={{ justifyContent: 'center' }}>
            {secondaryBtnText && (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Button color="primary" variant="outlined" {...secondaryBtnProps}>
                {secondaryBtnText}
              </Button>
            )}
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {primaryBtnText && (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Button color="primary" variant="contained" {...primaryBtnProps}>
                {primaryBtnText}
              </Button>
            )}
          </DialogActions>
        ) : null}

      </Container>
    </Dialog>
  );
};

CustomUploadModal.defaultProps = {
  primaryBtnText: '',
  secondaryBtnText: '',
  primaryBtnProps: {},
  secondaryBtnProps: {},
  onClose: null,
  headingDivider: false,
  contentDivider: false,
  logo: null,
  heading: null,
  breakpoint: 'md',
  logoWrapper: '',
  disableGutters: false,
  contentWrapper: '',
  showActionButtons: true,
};

CustomUploadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  logo: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  heading: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  primaryBtnText: PropTypes.string,
  onClose: PropTypes.func,
  secondaryBtnText: PropTypes.string,
  primaryBtnProps: PropTypes.shape({
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  }),
  secondaryBtnProps: PropTypes.shape({
    onClick: PropTypes.func,
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  headingDivider: PropTypes.bool,
  contentDivider: PropTypes.bool,
  breakpoint: PropTypes.string,
  logoWrapper: PropTypes.string,
  disableGutters: PropTypes.bool,
  contentWrapper: PropTypes.string,
  showActionButtons: PropTypes.bool,
};

export default memo(CustomUploadModal, ((prevProps, nextProps) => (
  prevProps.open === nextProps.open && prevProps.logo === nextProps.logo
  && prevProps.primaryBtnProps === nextProps.primaryBtnProps
)));
