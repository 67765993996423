import React, {
  useCallback, useEffect, useRef, useState, useContext,
} from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@mui/material/Box';
import { isCancel } from 'axios';
import Paper from '@mui/material/Paper';
import { Table, TableContainer } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddIcon from '@mui/icons-material/Add';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import _debounce from 'lodash/debounce';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import _map from 'lodash/map';
import _differenceWith from 'lodash/differenceWith';
import {
  MEDIUM_GREY,
  SWITCH_GREY,
  ACCORDION_GREY,
  MCKINSEY_BLUE,
  WHITE,
} from '../../stylesheets/colors';
import Header from '../../components/common/Header';
import LabledTextField from '../../components/common/LabledTextField';
import EnhancedTableHeader from '../../components/Table/EnhancedTableHeader';
import {
  PLATFORM_LEVEL_USERS_TABLE_HEAD_ATTRIBUTES,
  QUEUED,
  ROLE_SUPERADMIN,
} from '../../constants';
import { getComparator, tableSort } from '../../helpers/utils';
import NoDataComponent from '../../components/common/NoDataComponent';
import NoSearchedUserData from '../../assets/img/noChannel.svg';
import CustomSnackbar from '../../components/common/CustomSnackbar';
import LoadingCircle from '../../components/common/LoadingCircle/LoadingCircle';
import UsersTableBody from '../../components/PlatformLevelUsers/UsersDataTableBody';
import PaginationComponent from '../../components/PlatformLevelUsers/PaginationComponent';
import UsersFilterModal from '../../components/PlatformLevelUsers/UsersFilterModal';
import HyperlinkButton from '../../components/common/HyperlinkButton';
import CustomPopover from '../../components/common/CustomPopover';
import ProgressStatusModal from '../../components/PlatformLevelUsers/ProgressStatusModal';
import { getProgress } from '../../store/actions/async/common';
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import { internalServerErrorModalLogic } from '../common/utils';
import AlertReleaseInfo from '../../components/ContentManagement/AlertReleaseInfo';
import MyContext from '../../context';
import useGetRunningTransaction from '../../hooks/useGetRunningTransaction';
import WarningInfoAlert from '../../components/common/WarningInfoAlert';
import DeleteUserConfirmationModal from '../../components/PlatformLevelUsers/DeleteUserConfirmationModal';
import AlertBarWithAction from '../../components/common/AlertBarWithAction';
import { downloadMarkedUsers } from '../common/apis';

const useStyles = makeStyles({
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
  },
  containerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  pageHeader: {
    padding: '2rem',
  },
  usersSearchSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '1rem 0',
    width: '33rem',
  },
  table: {
    '& .MuiTableCell-root': {
      padding: '0.7rem',
    },
    '& th.MuiTableCell-root:first-child > div': {
      marginLeft: '1.5rem',
    },
    '& td.MuiTableCell-root:first-child p': {
      marginLeft: '1.5rem',
    },
    '& th.MuiTableCell-root': {
      lineHeight: '1.92rem',
    },
  },
  secondaryTextCls: {
    fontSize: '2.25rem',
  },
  noDataContent: {
    boxShadow: `0px 1px 4px -2px ${MEDIUM_GREY}`,
    flex: 1,
  },
  filtersButton: {
    marginLeft: '1.25rem',
    marginBottom: '1rem',
    alignSelf: 'end',
  },
  clearFilterBtn: {
    marginLeft: '1.25rem',
    alignSelf: 'end',
    marginBottom: '2rem',
  },
  userTopbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  userBtnSection: {
    display: 'flex',
  },
  bulkActionBtn: {
    marginRight: '1rem',
  },
  actionBtn: {
    background: MCKINSEY_BLUE,
    border: `1px solid ${MCKINSEY_BLUE}`,
    color: WHITE,
    '&:hover': {
      background: MCKINSEY_BLUE,
    },
  },
  disabledActionBtn: {
    '&:disabled': {
      backgroundColor: SWITCH_GREY,
      color: ACCORDION_GREY,
      border: `1px solid ${SWITCH_GREY}`,
    },
  },
  selecteduserTopbar: {
    backgroundColor: SWITCH_GREY,
    padding: '0.5rem',
    marginTop: '0.5rem',
  },
  progressBarStyle: {
    marginBottom: '2px',
  },
  refreshButton: {
    color: MCKINSEY_BLUE,
    fontSize: '1.35rem',
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    fontWeight: 400,
    boxShadow: 'none',
    '&:disabled': {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      textDecoration: 'underline',
    },
  },
  usersTopbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const filtersObj = {
  selectedPrograms: [],
  selectedClients: [],
};

const CommonButton = ({ onClick, disabled, text }) => {
  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      className={`${classes.actionBtn} ${disabled ? classes.disabledActionBtn : ''}`}
      variant="contained"
      color="primary"
      disabled={disabled}
    >
      <Typography>{text}</Typography>
      <KeyboardArrowDownIcon />
    </Button>
  );
};

CommonButton.defaultProps = {
  anchorEl: null,
  disabled: false,
};

CommonButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  anchorEl: PropTypes.shape({}),
  disabled: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

const PlatformUsers = (props) => {
  const {
    usersData,
    getUsers,
    clearAlertBar,
    markForDelete,
    transaction_id,
    clearTransactionId,
    isCpRole,
    deleteUser,
    setTransactionId,
  } = props;

  const { data: transactionData } = useGetRunningTransaction(
    'user_delete, user_mark_deletion, user_unmark_deletion, user_statuses',
  );

  const action = {
    Mark: 'Mark for Deletion',
    Unmark: 'Unmark for Deletion',
  };
  const PROGRESSBARMESSAGE = {
    Mark: {
      progressMessage: 'Mark for deletion is in progress.',
      withErrorsMessage: 'Mark for Deletion is completed with errors.',
      failedMessage: 'Mark for Deletion failed. Please try again.',
      successMessage: 'Mark for Deletion completed successfully.',
    },
    Unmark: {
      progressMessage: 'Unmark for deletion is in progress.',
      withErrorsMessage: 'Unmark for Deletion is completed with errors.',
      failedMessage: 'Unmark for Deletion failed. Please try again.',
      successMessage: 'Unmark for Deletion completed successfully.',
    },
    Delete: {
      progressMessage: 'Deleting selected users.',
      withErrorsMessage: 'User deletion has been completed with errors.',
      failedMessage: 'Failed to delete users. Please try again.',
      successMessage: 'Users deleted successfully.',
    },
  };
  const classes = useStyles();
  const { role } = useContext(MyContext);
  const isSuperAdmin = role === ROLE_SUPERADMIN;
  const [searchValue, setSearchValue] = useState('');
  const [orderSort, setOrderSort] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [userFilters, setUserFilters] = useState(filtersObj);
  const [headerCheckBox, setHeaderCheckBox] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [progress, setProgress] = useState({ done: null, percentage: 0 });
  const [showProgressBar, setProgressBar] = useState(false);
  const [actionText, setActionText] = useState('');
  const [actionName, setActionName] = useState('');
  const [showWarningBar, setShowWarningBar] = useState(false);
  const [isActionDisabled, setIsActionDisabled] = useState(false);
  const [pollProgress, setPollProgress] = useStateIfMounted(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [deleteuserList, setDeleteUserList] = useState([]);
  const [userNotFoundError, setUserNotFoundError] = useState('');
  const [pageSize, setPageSize] = useState(50);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllMarkedUsers, setSelectAllMarkedUsers] = useState(0);

  const { selectedPrograms, selectedClients, flaggedUser } = userFilters;

  const searchRef = useRef();
  const pageRef = useRef();
  const userFiltersRef = useRef();

  searchRef.current = searchValue;
  pageRef.current = currentPage;
  userFiltersRef.current = userFilters;

  const { isLoading, result, snackbarObj } = usersData;
  const { data: usersList, total_rows: totalUsers } = result;

  const history = useHistory();
  const location = useLocation();
  const { state } = location;

  useEffect(() => {
    if (state?.errorMessage) {
      setUserNotFoundError(state?.errorMessage);
      history.replace();
    }
  }, [state, history]);

  useEffect(() => {
    if (transactionData?.length) {
      const deleteTransactionIDs = transactionData.filter(
        (item) => item.type === 'user_delete' && item.status === 'PROCESSING',
      )?.[0];
      const markUserTransactionIDs = transactionData.filter(
        (item) => item.type === 'user_mark_deletion' && item.status === 'PROCESSING',
      )?.[0];
      const unmarkUserTransactionIDs = transactionData.filter(
        (item) => item.type === 'user_unmark_deletion' && item.status === 'PROCESSING',
      )?.[0];
      if (deleteTransactionIDs) {
        setTransactionId({ transaction_id: deleteTransactionIDs?.transaction_id });
        setActionName('Delete');
        setPollProgress(true);
      } else if (markUserTransactionIDs) {
        setTransactionId({ transaction_id: markUserTransactionIDs?.transaction_id });
        setActionName('Mark');
        setPollProgress(true);
      } else if (unmarkUserTransactionIDs) {
        setTransactionId({ transaction_id: unmarkUserTransactionIDs?.transaction_id });
        setActionName('Unmark');
        setPollProgress(true);
      }
    }
  }, [setPollProgress, setTransactionId, transactionData]);

  useEffect(() => {
    if (selectAll) {
      setHeaderCheckBox(true);
    }
    if (selectedUsers.length) {
      const diff_user = _differenceWith(
        usersList,
        selectedUsers,
        (x, y) => x.user_id === y.user_id,
      );
      if (diff_user.length === 0) {
        setHeaderCheckBox(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersList]);

  const getUsersFunc = useCallback(
    ({
      searchVal, pageNum, programs, clients, markForDeletion,
    }) => {
      const selectedProgramsValues = _map(
        programs || userFiltersRef.current.selectedPrograms,
        'program_id',
      );
      const selectedClientsValues = _map(
        clients || userFiltersRef.current.selectedClients,
        'value',
      );
      // eslint-disable-next-line max-len
      const selectedFlaggedUser = markForDeletion === undefined ? !!userFiltersRef.current.flaggedUser : markForDeletion;
      const data = {
        params: {
          search_param: searchVal || searchRef.current,
          page: pageNum || pageRef.current,
          page_size: pageSize,
        },
        body: {
          programs: selectedProgramsValues || [],
          clients: selectedClientsValues || [],
          ...(selectedFlaggedUser && { markForDeletion: selectedFlaggedUser }),
        },
      };
      getUsers(data);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [getUsers, pageSize],
  );

  useEffect(() => {
    if (selectedUsers.length) {
      const markedUser = selectedUsers.filter((item) => item.deleted_flag);
      if (!markedUser.length) {
        setActionText('Mark');
      } else if (markedUser.length && markedUser.length === selectedUsers.length) {
        setActionText('Unmark');
      } else {
        setActionText('');
      }
    } else {
      setActionText('');
    }
  }, [action, selectedUsers]);

  const handleDeletion = (flag_type) => {
    const userList = selectedUsers.map((user) => user.user_name);
    const paylod = {
      username: [...userList],
      flag_type,
    };
    markForDelete(paylod);
    setActionName(flag_type);
    setPollProgress(true);
  };

  const deleteMarkedUser = useCallback(
    (singleUser) => {
      let userList = [];
      if (singleUser) {
        userList = [singleUser.user_id];
      } else {
        userList = selectedUsers.map((user) => user.user_id);
      }
      setDeleteUserList(userList);
      setDeleteUserModal(true);
    },
    [selectedUsers],
  );

  const onDeleteUser = () => {
    const data = {
      delete_all_marked_users: selectAll,
      ops_user_ids: selectAll ? [] : deleteuserList,
    };
    deleteUser(data);
    setActionName('Delete');
    setPollProgress(true);
    setDeleteUserModal(false);
    setDeleteUserList([]);
  };

  const markUsersActionMenu = [
    {
      title: actionText ? action[actionText] : 'Mark/UnMark For Deletion',
      onClick: () => {
        handleDeletion(actionText);
      },
      disabled: !actionText || isActionDisabled,
    },
    ...(isSuperAdmin
      ? [
        {
          title: 'Delete Users',
          onClick: () => deleteMarkedUser(),
          disabled: actionText === 'Mark' || isActionDisabled,
        },
      ]
      : []),
  ];

  const markUnmarkForDeletion = useCallback(
    (deletion_flag, userData) => {
      const paylod = {
        username: [userData.user_name],
        flag_type: !deletion_flag ? 'Mark' : 'Unmark',
      };
      markForDelete(paylod);
      setActionName(!deletion_flag ? 'Mark' : 'Unmark');
      setPollProgress(true);
    },
    [markForDelete, setPollProgress],
  );

  useEffect(() => {
    getUsersFunc({});
  }, [getUsersFunc]);

  useEffect(() => {
    let timer = null;
    const pollProgressApi = async () => {
      try {
        if (!transaction_id || !pollProgress) {
          return;
        }
        const res = await getProgress(transaction_id);
        setIsActionDisabled(true);
        if (actionName === 'Delete' && !isSuperAdmin) {
          setShowWarningBar(true);
        } else {
          setProgressBar(true);
          setShowWarningBar(false);
        }
        const { done } = res.data;
        if (done) {
          batchUpdates(() => {
            setProgress(res.data);
            clearTransactionId();
            setPollProgress(false);
          });
        } else {
          batchUpdates(() => {
            setProgress(res.data);
            if (res.data.status === QUEUED) {
              setOpenStatusModal(true);
            }
          });
          timer = setTimeout(pollProgressApi, 1000);
        }
      } catch (err) {
        if (isCancel(err)) {
          return;
        }
        timer = internalServerErrorModalLogic(history, err, setIsErrorModalOpen, pollProgressApi);
      }
    };
    if (transaction_id) {
      pollProgressApi();
    }
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, transaction_id, isSuperAdmin, actionName, pollProgress]);

  const debouncedSearchHandler = useCallback(
    _debounce((searchVal) => {
      getUsersFunc({ searchVal, pageNum: 1 });
    }, 500),
    [],
  );

  const handleSearch = (searchVal, clear = '') => {
    batchUpdates(() => {
      setSearchValue(searchVal);
      setCurrentPage(1);
    });
    if (searchVal.trim() || clear) {
      debouncedSearchHandler(searchVal.trim());
    }
  };

  const selectAllRow = useCallback(
    (checked) => {
      if (checked) {
        const diff_list = _differenceWith(
          usersList,
          selectedUsers,
          (x, y) => x.user_id === y.user_id,
        );
        const newuserList = selectedUsers.concat(diff_list);
        setSelectedUsers(newuserList);
        setHeaderCheckBox(true);
      } else {
        const newuserList = _differenceWith(
          selectedUsers,
          usersList,
          (x, y) => x.user_id === y.user_id,
        );
        setSelectedUsers(newuserList);
        setHeaderCheckBox(false);
      }
    },
    [selectedUsers, usersList],
  );

  const handleClearAll = useCallback(async () => {
    await batchUpdates(() => {
      setUserFilters(filtersObj);
      setCurrentPage(1);
      setSelectAll(false);
      setSelectAllMarkedUsers();
      setSelectedUsers([]);
      selectAllRow(false);
    });
    getUsersFunc({
      pageNum: 1,
      programs: [],
      clients: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUsersFunc]);

  useEffect(() => {
    let timer = null;
    if (progress?.done) {
      setSelectedUsers([]);
      setHeaderCheckBox(false);
      setShowWarningBar(false);
      setIsActionDisabled(false);
      if (selectAll && actionName === 'Delete' && progress?.status === 'COMPLETED') {
        handleClearAll();
      }
      timer = setTimeout(() => {
        getUsersFunc({});
      }, 1000);
      if (actionName !== 'Delete' || progress?.status === 'COMPLETED') {
        timer = setTimeout(() => {
          setProgressBar(false);
        }, 6000);
      }
    }
    return () => {
      clearTimeout(timer);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUsersFunc, actionName, progress, selectAll]);

  useEffect(() => {
    let timer;
    if (userNotFoundError) {
      setTimeout(() => {
        setUserNotFoundError('');
      }, 6000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [userNotFoundError]);

  const handlePageClick = useCallback(
    (pageNum) => {
      setCurrentPage(pageNum);
      setHeaderCheckBox(false);
      getUsersFunc({ pageNum });
    },
    [getUsersFunc],
  );

  const getSearchIcon = () => {
    if (!searchValue) {
      return <SearchIcon />;
    }
    return (
      <CloseIcon
        data-testid="clearSearch"
        onClick={() => handleSearch('', 'clear')}
        style={{ cursor: 'pointer' }}
      />
    );
  };

  const handleRequestSort = useCallback(() => {
    const isAsc = orderSort && orderSort === 'asc';
    setOrderSort(isAsc ? 'desc' : 'asc');
  }, [orderSort]);

  const onUserClick = useCallback(
    (userId) => {
      if (!isActionDisabled) {
        history.push(`/users/${userId}`);
      }
    },
    [history, isActionDisabled],
  );

  useEffect(() => {
    setHeaderCheckBox(false);
  }, [pageSize]);

  const handleCheckUser = useCallback(
    (ev, userData) => {
      if (ev.target.checked) {
        const updatedArray = [...selectedUsers, userData];
        setSelectedUsers(updatedArray);
        if (updatedArray.length === usersList.length) {
          setHeaderCheckBox(true);
        } else {
          setHeaderCheckBox(false);
        }
      } else {
        const filteredArray = selectedUsers.filter((item) => item.user_id !== userData.user_id);
        if (selectAll) {
          setSelectAllMarkedUsers(selectAllMarkedUsers - 1);
        }
        batchUpdates(() => {
          setHeaderCheckBox(false);
          setSelectedUsers(filteredArray);
        });
      }
    },
    [selectedUsers, usersList.length, selectAll, selectAllMarkedUsers],
  );

  const tableJsx = useCallback(
    () => (totalUsers ? (
      <>
        <TableContainer style={{ maxHeight: '40vh', marginBottom: '3rem', flex: 1 }}>
          <Table aria-label="Users table" className={classes.table} padding="normal" stickyHeader>
            <EnhancedTableHeader
              headCells={PLATFORM_LEVEL_USERS_TABLE_HEAD_ATTRIBUTES}
              order={orderSort}
              orderBy={orderSort ? 'user_name' : ''}
              onRequestSort={handleRequestSort}
              checkboxEnable
              checkboxHandler={selectAllRow}
              defaultCheckBoxValue={headerCheckBox}
              isDisabled={selectAll}
            />
            <UsersTableBody
              tableData={
                  orderSort
                    ? tableSort(usersList, getComparator(orderSort, 'user_name'))
                    : usersList
                }
              onUserClick={onUserClick}
              selectedUsers={selectedUsers}
              handleCheckUser={handleCheckUser}
              markUnmarkForDeletion={markUnmarkForDeletion}
              isCpRole={isCpRole}
              deleteUser={deleteMarkedUser}
              isActionDisabled={isActionDisabled}
              isDisabled={selectAll}
            />
          </Table>
        </TableContainer>
        <PaginationComponent
          totalUsers={totalUsers}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          handlePageClick={handlePageClick}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </>
    ) : (
      <Box className={classes.noDataContent}>
        <NoDataComponent
          imgSrc={NoSearchedUserData}
          primaryText="Oops!"
          secondaryText="Could not find user you were looking for."
          secondaryTextCls={classes.secondaryTextCls}
        />
      </Box>
    )),
    [
      totalUsers,
      classes.table,
      classes.noDataContent,
      classes.secondaryTextCls,
      orderSort,
      handleRequestSort,
      selectAllRow,
      headerCheckBox,
      usersList,
      onUserClick,
      selectedUsers,
      handleCheckUser,
      markUnmarkForDeletion,
      isCpRole,
      deleteMarkedUser,
      isActionDisabled,
      currentPage,
      handlePageClick,
      pageSize,
      selectAll,
    ],
  );

  const handleApplyFilters = useCallback(
    async (updatedFiltersObj) => {
      const { selectedProgramsClone, selectedClientsClone, markedDeletion } = updatedFiltersObj;
      batchUpdates(() => {
        setUserFilters({
          selectedPrograms: selectedProgramsClone,
          selectedClients: selectedClientsClone,
          ...(markedDeletion && { flaggedUser: markedDeletion }),
        });
        setCurrentPage(1);
        setShowFiltersModal(false);
      });
      getUsersFunc({
        programs: selectedProgramsClone,
        clients: selectedClientsClone,
        markForDeletion: markedDeletion,
        pageNum: 1,
      });
    },
    [getUsersFunc],
  );

  const xlsUploadHandler = () => {
    history.push('/users/bulkUpload');
  };

  const selectAllHandler = () => {
    setHeaderCheckBox(true);
    setSelectAll(true);
    setSelectAllMarkedUsers(totalUsers);
  };

  const onViewStatus = useCallback(() => {
    batchUpdates(() => {
      setOpenStatusModal(true);
    });
  }, []);

  const createSingleUser = useCallback(() => {
    history.push('/users/createUser');
  }, [history]);

  const handleDownloadMarkedUsers = async () => {
    const userList = selectedUsers.map((user) => user.user_id);
    const payload = {
      user_ids: selectAll ? [] : userList,
      select_all: selectAll ? 1 : 0,
    };
    try {
      await downloadMarkedUsers(new Date(), payload);
    } catch (e) {
      console.error(e);
    }
  };

  const filtersLength = selectedClients.length + selectedPrograms.length + (flaggedUser ? 1 : 0);

  return (
    <Box className={classes.wrapper}>
      {showProgressBar && (
        <AlertReleaseInfo
          progress={progress}
          showElement={showProgressBar}
          progressMessage={PROGRESSBARMESSAGE[actionName].progressMessage}
          withErrorsMessage={PROGRESSBARMESSAGE[actionName].withErrorsMessage}
          failedMessage={PROGRESSBARMESSAGE[actionName].failedMessage}
          successMessage={PROGRESSBARMESSAGE[actionName].successMessage}
          onViewStatus={onViewStatus}
          setShowElement={setProgressBar}
          customStyle={classes.progressBarStyle}
        />
      )}
      {showWarningBar && (
        <WarningInfoAlert severity="info" customStyles={classes.warningBox}>
          {'Deletion of users is in process. Please retry in sometime.'}
        </WarningInfoAlert>
      )}
      {userNotFoundError && (
        <AlertBarWithAction
          variant={'error'}
          labelText={userNotFoundError}
          actionButtonIcon={<CloseIcon onClick={() => setUserNotFoundError('')} />}
        />
      )}
      <Paper className={classes.containerWrapper}>
        <Header
          heading="Users"
          subHeading="Details of all the users on the platform"
          className={classes.pageHeader}
        >
          <Button
            className={classes.bulkActionBtn}
            startIcon={<AddIcon />}
            color="primary"
            variant="contained"
            onClick={createSingleUser}
          >
            Create User
          </Button>
        </Header>
        <Box className={classes.wrapper}>
          <Box className={classes.usersTopbar}>
            <Typography variant="h1" component={'span'}>
              {`${totalUsers} Users`}
            </Typography>
            <Button
              className={classes.bulkActionBtn}
              color="primary"
              variant="outlined"
              onClick={handleDownloadMarkedUsers}
              disabled={!(selectAll || selectedUsers.length)}
            >
              Download XLS
            </Button>
          </Box>
          {selectedUsers.length ? (
            <Box className={classes.selecteduserTopbar}>
              <Typography variant="body1" component={'span'}>
                {`${selectAll ? selectAllMarkedUsers : selectedUsers.length} User(s) selected`}
              </Typography>
            </Box>
          ) : (
            <></>
          )}
          <Box className={classes.userTopbar}>
            <Box className={`${classes.usersSearchSection}`}>
              <LabledTextField
                label="Search"
                placeholder="Search Name or Email"
                inputProps={{
                  name: 'search_client',
                  'data-testid': 'search_user',
                  InputProps: {
                    endAdornment: getSearchIcon(),
                  },
                }}
                value={searchValue}
                onChange={(e) => handleSearch(e.target.value)}
              />
              <Button
                className={classes.filtersButton}
                color="primary"
                variant="contained"
                type="button"
                onClick={() => {
                  setShowFiltersModal(true);
                }}
              >
                {`Filters ${filtersLength ? `(${filtersLength})` : ''}`}
              </Button>
              {filtersLength > 0 ? (
                <HyperlinkButton className={classes.clearFilterBtn} onClick={handleClearAll}>
                  Clear Filters
                </HyperlinkButton>
              ) : null}
            </Box>

            <Box className={`${classes.userBtnSection}`}>
              {isSuperAdmin && (
                <Button
                  className={classes.refreshButton}
                  disabled={!(flaggedUser && usersList.length)}
                  color="primary"
                  variant="contained"
                  onClick={selectAllHandler}
                >
                  Select All
                </Button>
              )}
              {!isCpRole && (
                <>
                  {/* <div className={classes.refreshButton}>Select All</div> */}
                  <Button
                    className={classes.bulkActionBtn}
                    disabled={isActionDisabled}
                    startIcon={<CloudUploadIcon />}
                    color="primary"
                    variant="contained"
                    onClick={xlsUploadHandler}
                  >
                    Bulk Action XLS Upload
                  </Button>
                  <div className={classes.markUserWrapper}>
                    <CustomPopover
                      additionalClass={classes.markUserButton}
                      list={markUsersActionMenu}
                      button={(e) => CommonButton({
                        ...e,
                        text: 'Actions',
                        disabled: !actionText || isActionDisabled,
                      })}
                    />
                  </div>
                </>
              )}
            </Box>
          </Box>

          {isLoading ? <LoadingCircle /> : tableJsx()}
        </Box>
      </Paper>
      <CustomSnackbar snackbarObj={snackbarObj} setSnackbarObj={clearAlertBar} />
      {showFiltersModal && (
        <UsersFilterModal
          open={showFiltersModal}
          onClose={() => setShowFiltersModal(false)}
          handleApplyFilters={handleApplyFilters}
          selectedPrograms={selectedPrograms}
          selectedClients={selectedClients}
          flaggedUser={!!flaggedUser}
          isLoading={false}
          loadingText={''}
        />
      )}
      {openStatusModal ? (
        <ProgressStatusModal
          open={openStatusModal}
          onClose={() => {
            setOpenStatusModal(false);
          }}
          progress={progress}
          action={actionName}
        />
      ) : null}
      <DeleteUserConfirmationModal
        open={deleteUserModal}
        isAllMarkedSelected={selectAll}
        userCount={totalUsers}
        onDelete={onDeleteUser}
        onClose={() => {
          setDeleteUserModal(false);
          setDeleteUserList([]);
        }}
      />
      <ErrorModal open={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)} />
    </Box>
  );
};
PlatformUsers.defaultProps = {
  transaction_id: null,
};
PlatformUsers.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string,
      programSubType: PropTypes.string,
    }),
  }).isRequired,
  getUsers: PropTypes.func.isRequired,
  markForDelete: PropTypes.func.isRequired,
  clearAlertBar: PropTypes.func.isRequired,
  usersData: PropTypes.object.isRequired,
  transaction_id: PropTypes.number,
  clearTransactionId: PropTypes.func.isRequired,
  isCpRole: PropTypes.bool.isRequired,
  deleteUser: PropTypes.func.isRequired,
  setTransactionId: PropTypes.func.isRequired,
};

export default PlatformUsers;
