import React from 'react';
import PropTypes from 'prop-types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditors from 'ck5-mck-editor/build/ckeditor';
import { getDefaultHeaders } from '../../helpers/userHelper';
import config from '../../config';

class UploadAdapter {
  constructor(loader, programId) {
    this.loader = loader;
    this.programId = programId;
  }

  async upload() {
    const headers = await getDefaultHeaders();
    return this.loader.file.then(
      (file) => new Promise((resolve, reject) => {
        const body = new FormData();
        body.append('file', file);
        fetch(`${config.ROUTES.UPLOAD_IMAGE}/${this.programId}`, {
          method: 'post',
          headers,
          body,
        })
          .then((res) => res.json())
          .then((res) => {
            console.log(res);
            resolve({
              default: `${res.url}`,
            });
          })
          .catch((err) => {
            reject(err);
          });
      }),
    );
  }
}

const Editor = ({
  defaultContent, name, onChange, programId, setEditorInstance,
}) => (
  <CKEditor
    id={name}
    editor={ClassicEditors.ClassicEditorDefault}
    data={defaultContent}
    config={{
      allowedContent: true,
      autoParagraph: false,
      enterMode: 'div',
      defaultUploader(loader) {
        return new UploadAdapter(loader);
      },
    }}
    div
    onReady={(editor) => {
      // eslint-disable-next-line func-names
      setEditorInstance(editor);
      editor.plugins.get('FileRepository').createUploadAdapter = function (loader) {
        return new UploadAdapter(loader, programId);
      };
      editor.model.schema.register('div', { inheritAllFrom: '$block' });
      editor.conversion.elementToElement({ model: 'div', view: 'div' });
    }}
    onChange={(_, editor) => {
      const data = editor.getData();
      onChange(name, data);
    }}
  />
);

Editor.propTypes = {
  name: PropTypes.string.isRequired,
  defaultContent: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  programId: PropTypes.number.isRequired,
  setEditorInstance: PropTypes.func.isRequired,
};

export default Editor;
