import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowBack from '@material-ui/icons/ArrowBackIos';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { Box } from '@mui/material';
import Edit from '../../../assets/icons/newedit.svg';
import top_nav_dots from '../../../assets/img/top-nav-dot.svg';

// import EditIcon from '@mui/icons-material/Edit';

import {
  WHITE,
  ERROR_RED,
  KEY_BLACK,
  BLACK,
  ERROR_DEEP_BLUE,
} from '../../../stylesheets/colors';

import StageBadge from '../../StageBadge/StageBadge';
import WrappedIconRow from './WrappedIconRow';
import { reportPageDateTimeFormatter } from '../../../helpers/formattingHelpers';

import {
  BOX_URL,
  DOCEBO_URL,
  DOCEBO_URL_2,
  NA,
  PLATFORMS_URL,
  ADMIN_GROUP,
  WRIKE_URL,
  ROUTE_REPORT,
  SETTINGS,
  TEXT_NOT_ASSIGNED,
  ACADEMY_GO,
  LP_SKILLS,
  LP_GSR,
} from '../../../constants';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    background: `url(${top_nav_dots}) right 7px no-repeat, linear-gradient(90deg, #AADEF4 0.1%, #E3F7FF 30.57%, #87DBFF 100%)`,
    color: BLACK,
    padding: '16px',
    fontFamily: theme.typography.fontFamily,
    height: '10.5rem',
  },
  backButtonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.04,
  },
  backButton: {
    flex: 0.5,
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent',
    color: ERROR_DEEP_BLUE,
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.94,
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.5,
    justifyContent: 'space-between',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  configIdWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
  },
  stageWrapper: {
    display: 'flex',
    marginLeft: '2rem',
  },
  createdAtByWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  createdAtBy: {
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    color: BLACK,
    justifyContent: 'flex-end',
  },
  subTitleRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.5,
    fontSize: '1.5rem',
    justifyContent: 'space-between',
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.87,
    alignItems: 'center',
  },
  linksWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.13,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  subtitleKey: {
    padding: '2px',
    color: ERROR_DEEP_BLUE,
  },
  subtitleValue: {
    padding: '2px',
    fontWeight: 'bold',
  },
  subtitleSeparator: {
    color: ERROR_DEEP_BLUE,
    margin: '8px',
  },
  experienceDropdown: {
    color: WHITE,
    backgroundColor: 'transparent',
    border: `1px solid ${WHITE}`,
    padding: '0.25rem',
    minWidth: '12rem',
    borderRadius: '2px',
    '&:before': {
      content: 'unset',
    },
    '&:after': {
      content: 'unset',
    },
  },
  experienceIcon: {
    color: WHITE,
  },
  warningText: {
    marginTop: '0.5rem',
    marginLeft: '6.2rem',
    color: ERROR_RED,
    fontWeight: 'bold',
    marginBottom: '-0.5rem',
  },
  buttonDisabled: {
    '&:hover': {
      cursor: 'default',
    },
  },
  buttonWrapper: {
    position: 'relative',
  },
  copyTextWrapper: {
    color: KEY_BLACK,
    backgroundColor: WHITE,
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '-70%',
    left: '-100%',
    minWidth: '13rem',
  },
  copyText: {
    fontSize: '1rem',
    marginRight: '0.5rem',
  },
  checkIcon: {
    width: '2rem',
    height: '2rem',
    marginRight: '0.5rem',
  },
  titleTextWrapper: {
    display: 'flex',
    fontSize: '12px',
    wordBreak: 'break-all',
    color: ERROR_DEEP_BLUE,
    fontWeight: 'bold',
  },
  editIcon: {
    width: '1.2rem',
    height: '1.2rem',
    marginLeft: '0.5rem',
    display: 'inline-block',
    verticalAlign: 'baseline',
  },
}));

const ExperienceDropdownComponent = ({
  defaultValue, onChange, options, locked, withNA,
}) => {
  const classes = useStyles();

  // If value is not set, then allow it to change
  if (locked) {
    return <Typography className={classes.experienceDropdown}>{defaultValue}</Typography>;
  }

  // Loading
  if (!options.length) {
    return <Typography className={classes.experienceDropdown}>Loading...</Typography>;
  }

  let optionsWithNa = [...options];
  if (withNA) {
    optionsWithNa = [{ label: NA, value: NA }, ...options];
  }

  const optionsDom = optionsWithNa.map((option) => (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>
  ));

  return (
    <Select
      className={classes.experienceDropdown}
      classes={{ icon: classes.experienceIcon }}
      value={defaultValue}
      autoWidth
      onChange={(e) => onChange(e.target.value)}
      placeholder={defaultValue}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }}
    >
      {optionsDom}
    </Select>
  );
};

ExperienceDropdownComponent.defaultProps = {
  locked: false,
  withNA: false,
};

ExperienceDropdownComponent.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  locked: PropTypes.bool,
  withNA: PropTypes.bool,
};

const ReportBanner = ({
  onBackClick,
  programMetadata,
  onStageChange,
  onLinkSave,
  match,
  userRole,
}) => {
  const classes = useStyles();
  const {
    config_id: configId,
    stage,
    updated_at: updatedAt,
    updated_by: updatedBy,
    experience,
    extended_enterprise: extendedEnterprise,
    updatedExp,
    updatedPlatformUrl,
    updatedStatus,
    test_users: testUsers,
    is_academy_go,
    is_skill,
    is_gsr,
    groups,
  } = programMetadata;

  const programId = get(match, 'params.programId');
  const isAdmin = ADMIN_GROUP.includes(userRole);
  const separator = <Typography className={classes.subtitleSeparator}>|</Typography>;

  const subtitle = (
    <>
      <Typography className={classes.subtitleKey}>Experience:</Typography>
      <Typography className={classes.titleTextWrapper}>
        {`${updatedExp || experience || TEXT_NOT_ASSIGNED}`}
      </Typography>
      <Link
        className={classes.link}
        component={RouterLink}
        to={{ pathname: `/${ROUTE_REPORT}/${programId}/${SETTINGS}?tab=2` }}
      >
        <img src={Edit} className={classes.editIcon} alt="Edit" />
      </Link>

      {separator}
      <Typography className={classes.subtitleKey}>Test Users:</Typography>
      <Typography className={classes.subtitleValue}>{`(${testUsers})`}</Typography>
      {separator}
      <Typography className={classes.subtitleKey}>Groups:</Typography>
      <Typography className={classes.subtitleValue}>{`(${groups})`}</Typography>
      {separator}
      <Typography className={classes.subtitleKey}>Platform URL:</Typography>
      <Typography className={classes.titleTextWrapper}>{`${updatedPlatformUrl || extendedEnterprise}`}</Typography>
      <Link
        className={classes.link}
        component={RouterLink}
        to={{ pathname: `/${ROUTE_REPORT}/${programId}/${SETTINGS}?tab=2` }}
      >
        <img src={Edit} className={classes.editIcon} alt="Edit" />
      </Link>
    </>
  );

  const formattedUpdatedAt = reportPageDateTimeFormatter(new Date(updatedAt));
  const updatedAtUpdatedByBlock = updatedBy && updatedAt ? `Updated by ${updatedBy} at ${formattedUpdatedAt}` : '';

  return (
    <div className={classes.wrapper}>
      <div className={classes.backButtonWrapper}>
        <button type="button" className={classes.backButton} onClick={onBackClick}>
          <ArrowBack />
        </button>
        <div />
      </div>

      <div className={classes.innerWrapper}>
        <div className={classes.titleRow}>
          <div className={classes.titleWrapper}>
            <Typography variant="h4" className={classes.configIdWrapper}>
              {configId}
            </Typography>
            <div className={classes.stageWrapper}>
              <StageBadge stage={updatedStatus || stage} editable onChange={onStageChange} />
            </div>
            {is_academy_go ? (
              <Box sx={{ pl: '1rem' }}>
                <StageBadge stage={ACADEMY_GO} />
              </Box>
            ) : null}
            {is_skill ? (
              <Box sx={{ pl: '1rem' }}>
                <StageBadge stage={LP_SKILLS} />
              </Box>
            ) : null}
            {is_gsr ? (
              <Box sx={{ pl: '1rem' }}>
                <StageBadge stage={LP_GSR} />
              </Box>
            ) : null}
          </div>

          <div className={classes.createdAtByWrapper}>
            <Typography variant="body1" className={classes.createdAtBy}>
              {updatedAtUpdatedByBlock}
            </Typography>
          </div>
        </div>

        <div className={classes.subTitleRow}>
          <Typography variant="subtitle1" className={classes.infoWrapper}>
            {subtitle}
          </Typography>

          <div className={classes.linksWrapper}>
            <WrappedIconRow programMetadata={programMetadata} onLinkSave={onLinkSave} editable />
          </div>
        </div>
        {!isAdmin && !experience && (
          <Typography variant="subtitle2" className={classes.warningText}>
            *Once set, the value of experience cannot be changed
          </Typography>
        )}
      </div>
    </div>
  );
};

ReportBanner.defaultProps = {
  programMetadata: PropTypes.shape({
    updated_at: null,
  }),
};

ReportBanner.propTypes = {
  onBackClick: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  programMetadata: PropTypes.shape({
    config_id: PropTypes.string.isRequired,
    stage: PropTypes.string.isRequired,
    updated_at: PropTypes.string,
    updated_by: PropTypes.string.isRequired,
    experience: PropTypes.string.isRequired,
    extended_enterprise: PropTypes.string.isRequired,
    test_users: PropTypes.number.isRequired,
    groups: PropTypes.number.isRequired,
    updatedExp: PropTypes.string.isRequired,
    updatedPlatformUrl: PropTypes.string.isRequired,
    updatedStatus: PropTypes.string.isRequired,
    docebo: PropTypes.bool.isRequired,
    is_academy_go: PropTypes.bool.isRequired,
    is_skill: PropTypes.bool.isRequired,
    is_gsr: PropTypes.bool.isRequired,
    links: PropTypes.shape({
      [PLATFORMS_URL]: PropTypes.string.isRequired,
      [BOX_URL]: PropTypes.string,
      [DOCEBO_URL]: PropTypes.string,
      [DOCEBO_URL_2]: PropTypes.string,
      [WRIKE_URL]: PropTypes.string,
    }).isRequired,
  }),
  onStageChange: PropTypes.func.isRequired,
  onLinkSave: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
};

export default ReportBanner;
