import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DialogTitle } from '@mui/material';
import Loading from '../common/Loading';
import ErrorModal from './ErrorModal';
import ValidationError from '../../assets/img/validation-error.webp';
import EnrollmentStuckQueue from '../common/EnrollmentStuckQueue';
import { ordinalSuffix } from '../../helpers/formattingHelpers';
import {
  CANCELLING, PENDING,
  PROCESSING, PROGRESS, QUEUED, REPORT,
} from '../../constants';

import CustomUploadModal from '../common/CustomUploadModal';
import FormattedTypography from '../common/FormattedTypography';
import LoadingCircle from '../common/LoadingCircle/LoadingCircle';

const StatusModalWrapper = ({
  open, onClose, modalState, progressData, progressErrorMsg,
  progressDataModal, reportModal, applyQueuedModal, feature, applyActionModal,
}) => {
  const [schema, setSchema] = useState({
    component: null,
    wrapperProps: {},
    props: {},
  });

  const errorModal = useCallback((message) => ({
    component: ErrorModal,
    wrapperProps: {
      logo: <img src={ValidationError} alt="validation error" width={120} height={120} />,
      breakpoint: 'md',
    },
    props: {
      message,
    },
  }), []);

  const loadingModal = useCallback(() => ({
    component: Loading,
    wrapperProps: {
      logo: null,
      breakpoint: 'md',
      heading: null,
      primaryBtnText: '',
      primaryBtnProps: null,
    },
    props: {
      minHeight: '20rem',
    },
  }), []);

  const progressModal = useCallback(() => {
    if (progressData) {
      return progressDataModal();
    }
    return loadingModal();
  }, [progressData, loadingModal, progressDataModal]);

  const queuedDataModal = useCallback(() => ({
    component: EnrollmentStuckQueue,
    wrapperProps: {
      primaryBtnText: 'Close',
      primaryBtnProps: {
        onClick: () => onClose(),
      },
    },
    props: {
      queue_position: ordinalSuffix(progressData?.queue_position),
    },
  }), [onClose, progressData]);

  const queuedModal = useCallback(() => {
    if (progressData) {
      return queuedDataModal();
    }
    return loadingModal();
  }, [progressData, loadingModal, queuedDataModal]);

  const cancellingDataModal = useCallback(() => ({
    component: () => (<></>),
    wrapperProps: {
      heading: (
        <DialogTitle>
          <FormattedTypography body={`Cancelling pending ${feature} tasks...`} />
        </DialogTitle>
      ),
      logo: (<LoadingCircle />),
    },
    props: {
    },
  }), [feature]);

  const cancellingModal = useCallback(() => {
    if (progressData) {
      return cancellingDataModal();
    }
    return loadingModal();
  }, [progressData, loadingModal, cancellingDataModal]);

  const getComponentDetails = useCallback(async (activityLogState) => {
    if (activityLogState === PROGRESS || activityLogState === PROCESSING) {
      return progressModal(progressData);
    }
    if (activityLogState === CANCELLING) {
      return cancellingModal();
    }
    if (activityLogState === QUEUED) {
      if (progressData) {
        /* this modal generally has cancel operation
        or is different from the queuedModal below */
        if (applyQueuedModal) {
          return applyQueuedModal();
        }
        return queuedModal(progressData);// this modal doesn;t have cancel operartion
      }
      return loadingModal();
    }
    if (activityLogState === PENDING) {
      return applyActionModal();
    }
    if (activityLogState === REPORT) {
      const reportComponent = await reportModal();
      return reportComponent;
    }
    return errorModal(progressErrorMsg);
  }, [errorModal, progressErrorMsg, progressModal, progressData,
    cancellingModal, applyQueuedModal, queuedModal, reportModal, loadingModal, applyActionModal]);

  useEffect(() => {
    async function fetchComponent() {
      const schemaObject = await getComponentDetails(modalState);
      if (schemaObject) {
        setSchema(schemaObject);
      }
    }
    if (modalState) {
      fetchComponent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalState, progressData, progressErrorMsg]);

  const ModalComponent = schema?.component ? schema.component : null;
  return (
    <>
      {Object.keys(schema?.wrapperProps).length > 0
        && (
          <CustomUploadModal
            open={open}
            onClose={onClose}
            {...schema.wrapperProps}
          >
            <ModalComponent {...schema.props} />
          </CustomUploadModal>
        )}
    </>
  );
};

StatusModalWrapper.defaultProps = {
  applyQueuedModal: null,
  applyActionModal: null,
  feature: '',
};

StatusModalWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  modalState: PropTypes.string.isRequired,
  progressData: PropTypes.object.isRequired,
  progressErrorMsg: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  progressDataModal: PropTypes.func.isRequired,
  reportModal: PropTypes.func.isRequired,
  applyQueuedModal: PropTypes.func,
  applyActionModal: PropTypes.func,
  feature: PropTypes.string,
};

export default StatusModalWrapper;
