import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { getErrorMessage } from '../helpers/apiHelper';
import useNotifications from './useNotifications';
import { getTransaction } from '../store/actions/async/common';

const useGetRunningTransaction = (feature) => {
  const { notifyError } = useNotifications();

  const {
    data, isFetching, isError, error, refetch,
  } = useQuery(['transaction', feature], async () => {
    const response = await getTransaction(feature);
    if (response.data.success) {
      return response.data.data;
    }
    throw new Error(response?.data?.message);
  });

  useEffect(() => {
    if (isError) {
      notifyError(getErrorMessage(error));
    }
  }, [error, isError, notifyError]);

  return {
    data,
    isFetching,
    isError,
    error,
    refetch,
  };
};

export default useGetRunningTransaction;
