import React, {
  useState, useEffect, useRef, useContext,
} from 'react';

import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core/styles';
import { Draggable } from 'react-beautiful-dnd';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { getErrorMessage } from '../../helpers/apiHelper';

import { getMaterialType, getScorm } from '../../helpers/utils';
import CopyUrl from '../../assets/icons/icon-copy-url.svg';
import {
  BLACK, SWITCH_GREY, MODERATE_DARK_GREY, MCKINSEY_BLUE, LIGHT_MEDIUM_GREY, WHITE,
} from '../../stylesheets/colors';
import InfoTooltip from '../common/InfoTooltip';

import ExpandedRowComponent from './ExpandedRowComponent';
import AddProjectAndReleaseDetails from './AddProjectAndReleaseDetails';
import DisabledCopyUrl from '../../assets/icons/disabled-copy-url-icon.svg';
import NewFolderIcon from '../../assets/icons/tm_folder_icon.svg';
import ReleaseConfirmationModal from './ReleaseConfirmationModal';
import MoveIcon from '../../assets/icons/move-icon.svg';
import CustomPopover from '../common/CustomPopover';
import ThreeDotButton from '../common/ThreeDotButton';
import MyContext from '../../context';
import {
  ROLE_PROD, ROLE_OPS, ELUCIDAT_SCORM_TYPE,
  ADMIN_GROUP,
} from '../../constants';
import ScormTypeChip from '../common/ScormTypeChip';

const useStyles = makeStyles({
  order: {
    color: BLACK,
    fontWeight: 800,
    paddingLeft: '1rem',
  },
  scromIcons: {
    display: 'flex',
    flexDirection: 'column',
  },
  orderIndicator: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  indicator: {
    height: '1.25rem',
    width: ' 1.25rem',
    borderRadius: ' 50%',
    margin: '0 1rem',
  },
  orderText: {
    paddingLeft: '0.5rem',
  },
  labelWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
  },
  dragger: {
    width: '1.85rem',
    height: '1.85rem',
    '&:focus': {
      outline: 'unset',
    },
  },
  move: {
    width: '20px',
    height: '20px',
  },
});

const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? LIGHT_MEDIUM_GREY : WHITE,
  ...draggableStyle,
});

const ContentTabRow = ({
  courseId, doceboUrl, row, index, getScormReleaseDetails, updatedscormRow,
  configId, programId, disabledActions, refreshScorm, newScorm, totalRows, openEditScormModal,
  updateReleaseTransaction, releaseTransaction, progress, updateScorm, enableReordering,
  openDeleteScormModal,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [elucidatRelease, setElucidatRelease] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [releaseData, setReleaseData] = useState({});
  const [loading, setLoading] = useState(false);
  const [fetchingRelease, setFetchingRelease] = useState(false);
  const [releasedScormId, setReleasedScormId] = useState('');

  const classes = useStyles();

  const myRef = useRef(null);
  const { role } = useContext(MyContext);

  const isDDAdmin = role === ROLE_OPS;
  const isCPAdmin = role === ROLE_PROD;
  const isAdmin = ADMIN_GROUP.includes(role);

  const releaseCompleted = row?.id === releasedScormId
    && releaseTransaction && progress.done === true;

  const isNotAllowed = isDDAdmin
    || Object.keys(elucidatRelease).length === 0
    || elucidatRelease?.elucidat_project_id?.length === undefined || 0
    || elucidatRelease?.elucidat_release_id?.length === undefined || 0;

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      // eslint-disable-next-line no-return-await
      return await navigator.clipboard.writeText(text);
    }
    return document.execCommand('copy', true, text);
  }
  const getActionIcon = (isDisabled) => (
    <>
      <InfoTooltip title={isCopied ? 'URL Copied' : 'Copy Playable URL'} placement="top">
        <img src={isDisabled ? DisabledCopyUrl : CopyUrl} alt="copy-url" />
      </InfoTooltip>

    </>
  );

  const handleCopyClick = (copyText) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOnClick = (tm_id, slug_name) => {
    handleCopyClick(getScorm(doceboUrl, courseId, tm_id, slug_name));
  };

  const expandRow = async (tm_id, scorm_type) => {
    try {
      setIsExpanded(true);
      if (scorm_type === 'Elucidat') {
        setFetchingRelease(true);
        const resp = await getScormReleaseDetails(courseId, tm_id?.split(':')[0]);
        if (resp?.data?.success === true) {
          setElucidatRelease(resp.data.data);
        } else {
          setElucidatRelease({});
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setFetchingRelease(false);
    }
  };

  const updateScormDetials = async (data) => {
    try {
      setFetchingRelease(true);
      setIsExpanded(true);
      const resp = await updateScorm(courseId, row?.id?.split(':')[0], data);
      if (resp?.data?.success === true) {
        const response = await getScormReleaseDetails(courseId, row?.id?.split(':')[0]);
        if (response?.data?.success === true) {
          setElucidatRelease(response.data.data);
        } else {
          setElucidatRelease({});
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setFetchingRelease(false);
    }
  };

  useEffect(() => {
    if (releaseCompleted) {
      expandRow(row?.id?.split(':')[0], row?.scorm_type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseTransaction, progress]);

  const noBorder = {
    borderBottom: isExpanded && row?.type?.includes('scorm') ? '0px' : `1px solid ${SWITCH_GREY}`,
    paddingBottom: isExpanded && row?.type?.includes('scorm') && '0px',
  };

  const initiateRelease = async () => {
    try {
      setLoading(true);
      const resp = await refreshScorm(courseId, row?.id?.split(':')[0], releaseData);
      if (resp.data.success === true) {
        updateReleaseTransaction(true, resp.data.transaction_id, row?.name);
        setReleasedScormId(row?.id);
      } else {
        updateReleaseTransaction(false, undefined, undefined, resp?.data?.message);
        expandRow(row?.id?.split(':')[0], row?.scorm_type);
      }
    } catch (e) {
      updateReleaseTransaction(false, undefined, undefined, getErrorMessage(e));
      expandRow(row?.id?.split(':')[0], row?.scorm_type);
    } finally {
      setLoading(false);
      setConfirmationModal(false);
    }
  };

  const handleReRelease = (releaseObject) => {
    setConfirmationModal(true);
    setReleaseData(releaseObject);
  };

  const onRelease = () => {
    handleReRelease(
      {
        project_id: elucidatRelease.elucidat_project_id,
        release_id: elucidatRelease.elucidat_release_id,
        program_id: programId,
        target_lp_code: configId,
        lo_title: row.name,
      },
    );
  };

  const onManualRelease = (projectDetails, releaseDetials) => {
    setOpenModal(false);
    updateScormDetials({
      project_id: projectDetails?.projectId,
      project_name: projectDetails?.projectName,
      release_id: releaseDetials?.releaseId,
      release_description: releaseDetials?.releaseDescription,
      release_version: releaseDetials?.releaseVersion,
      last_released: releaseDetials?.lastReleased,
    });
  };

  const addManually = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    if (enableReordering) {
      setIsExpanded(false);
    }
  }, [enableReordering]);

  const { label, icon } = getMaterialType(row);

  const getAccessByScormType = (scormType) => {
    if (isAdmin) {
      return false;
    }
    if (isCPAdmin) {
      return scormType === ELUCIDAT_SCORM_TYPE;
    }
    return true;
  };

  const isEditDisabled = disabledActions || getAccessByScormType(row?.scorm_type);

  return (
    <>
      <Draggable
        key={row.id}
        draggableId={`q-${row.id}`}
        index={index}
        isDragDisabled={!enableReordering}
      >
        {(provided, snapshot) => (
          <TableRow
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style,
            )}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell align="left" style={noBorder}>
              <Box className={classes.orderIndicator}>
                {enableReordering && (
                  <Box className={classes.dragger} {...provided.dragHandleProps}>
                    <img className={classes.move} src={MoveIcon} alt="drag" />
                  </Box>
                )}
                {(releaseCompleted || (newScorm && index === totalRows - 1)
                  || (updatedscormRow.status === 'success' && row.id === updatedscormRow.id))
                  ? (
                    <Box
                      ref={myRef}
                      className={classes.indicator}
                      style={{ backgroundColor: MCKINSEY_BLUE }}
                    />
                  ) : (
                    <Box
                      className={classes.indicator}
                    />
                  )}
                <Typography variant="subtitle2" className={classes.order}>{index + 1 > 9 ? index + 1 : `0${index + 1}`}</Typography>
              </Box>
            </TableCell>
            <TableCell align="left" style={noBorder}>
              <Box className={classes.labelWrapper}>
                {!row?.type && (
                  <img src={NewFolderIcon} alt="lo-type-icon" style={{ paddingRight: '1rem' }} />
                )}
                <Typography variant="body1" style={{ color: MODERATE_DARK_GREY }}>
                  {row.name}
                </Typography>
              </Box>
            </TableCell>
            <TableCell align="left" style={noBorder}>
              <ScormTypeChip label={label} icon={icon} />
            </TableCell>
            <TableCell align="left" style={noBorder}>
              {((row?.type?.includes('scorm') && !row?.scorm_type?.includes('Elucidat')) || (row?.scorm_type?.includes('Elucidat') && isAdmin)) ? (
                <CustomPopover
                  list={[
                    {
                      title: 'Edit',
                      disabled: isEditDisabled,
                      listItemTextStyle: { padding: '0 1.5rem' },
                      listItemStyle: isEditDisabled ? { borderBottom: '1px solid white' } : {},
                      onClick: () => openEditScormModal({ ...row, course_id: courseId }),
                    },
                    {
                      title: 'Delete',
                      disabled: disabledActions || !isAdmin,
                      listItemTextStyle: { padding: '0 1.5rem' },
                      onClick: () => openDeleteScormModal({ ...row, course_id: courseId }),
                    },
                  ]}
                  button={ThreeDotButton}
                />
              ) : null}
            </TableCell>
            <TableCell align="right" style={noBorder}>
              {row?.type?.includes('scorm') ? (
                <IconButton
                  disabled={disabledActions}
                  onClick={() => { handleOnClick(row.id, row.slug_name); }}
                >
                  {(row?.type?.includes('scorm') ? getActionIcon(disabledActions) : '')}
                </IconButton>
              ) : null}
            </TableCell>
            <TableCell align="right" style={noBorder}>
              <Box
                className={classes.scromIcons}
              >
                {row?.type?.includes('scorm') && !enableReordering ? (
                  <Box>
                    {isExpanded ? (
                      <IconButton disabled={disabledActions}>
                        <KeyboardArrowUpIcon onClick={() => setIsExpanded(false)} />
                      </IconButton>
                    ) : (
                      <IconButton disabled={disabledActions}>
                        <KeyboardArrowDownIcon onClick={() => expandRow(row.id, row?.scorm_type)} />
                      </IconButton>
                    )}
                  </Box>
                ) : null}
              </Box>
            </TableCell>
          </TableRow>
        )}
      </Draggable>
      {!enableReordering && (
        <ExpandedRowComponent
          isExpanded={isExpanded}
          elucidatRelease={elucidatRelease}
          onRelease={onRelease}
          disabledActions={disabledActions}
          addManually={addManually}
          isNotAllowed={isNotAllowed}
          forDDAdmin={isDDAdmin}
          loading={fetchingRelease}
          scormType={row?.scorm_type}
        />
      )}
      {
        confirmationModal && (
          <ReleaseConfirmationModal
            open={confirmationModal}
            onClose={() => setConfirmationModal(false)}
            subTitle="This action will create a full re-release for the project"
            mainContent="The course will update automatically with the new full re-release.
            All actions for the training materials will be disabled until
            the re-release is updated."
            loading={loading}
            initiateRelease={initiateRelease}
          />
        )
      }
      {
        openModal && (
          <AddProjectAndReleaseDetails
            open={openModal}
            onClose={() => setOpenModal(false)}
            elucidatRelease={elucidatRelease}
            onConfirm={onManualRelease}
            programId={programId}
            courseId={courseId}
          />
        )
      }

    </>
  );
};

ContentTabRow.propTypes = {
  courseId: PropTypes.number.isRequired,
  doceboUrl: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  row: PropTypes.object.isRequired,
  getScormReleaseDetails: PropTypes.func.isRequired,
  updateScorm: PropTypes.func.isRequired,
  configId: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired,
  disabledActions: PropTypes.bool.isRequired,
  refreshScorm: PropTypes.func.isRequired,
  updateReleaseTransaction: PropTypes.func.isRequired,
  releaseTransaction: PropTypes.string.isRequired,
  progress: PropTypes.shape({
    done: PropTypes.bool.isRequired,
  }).isRequired,
  isDDAdmin: PropTypes.bool.isRequired,
  enableReordering: PropTypes.bool.isRequired,
  totalRows: PropTypes.number.isRequired,
  newScorm: PropTypes.bool.isRequired,
  openEditScormModal: PropTypes.func.isRequired,
  updatedscormRow: PropTypes.object.isRequired,
  openDeleteScormModal: PropTypes.func.isRequired,
};

export default ContentTabRow;
