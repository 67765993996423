import React, {
  useState, useEffect, useMemo, useRef, memo, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import _debounce from 'lodash.debounce';
import {
  Box, Paper, CircularProgress, Container,
} from '@mui/material';
import Button from '@material-ui/core/Button';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import LabledTextField from '../common/LabledTextField';
import MasterDataTable from './MasterDataTable';
import Header from '../common/Header';
import EditClientModal from './EditClientDataModal';
import DeleteModal from './DeleteModal';
import MappedCohortsModal from './MappedCohortsModal';
import LoadingCircle from '../common/LoadingCircle/LoadingCircle';
import NoDataComponent from '../common/NoDataComponent';
import NoSearchedUserData from '../../assets/img/noChannel.svg';
import {
  CLIENT_MASTERDATA_TABLE_HEAD_ATTRIBUTES, ROLE_PROD,
} from '../../constants';
import { downloadClientMaster } from '../../containers/common/apis';
import MyContext from '../../context';

const ClientPage = ({
  clientMasterData, updateClient, alertMessage, deleteClient, deleteClientResetRequest,
  loadMoreData, isAdmin, setAlertMessage, addNewClient, deleteClientData, classes,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [editModal, setEditmodal] = useState(false);
  const [selectedPortfolioRow, setSelectedPortfolioRow] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isSnackBarOpen, setSnackBarOpen] = useState(false);
  const [updatedClientId, setUpdatedCLientId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableScrollTop, setTableScrollTop] = useState(0);
  const [isNewClient, setNewClient] = useState(false);
  const clientTableData = clientMasterData?.result?.data ?? [];
  const totalRows = clientMasterData?.result?.total_rows ?? 0;
  const hasMore = clientMasterData?.result?.has_more ?? false;
  const tableRef = useRef(null);
  const { role } = useContext(MyContext);
  const isCPAdmin = role === ROLE_PROD;
  const [pickedRow, setPickedRow] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [mappedCohortsModal, setMappedCohortsModal] = useState(false);
  const [mappedCohortsData, setMappedCohortsData] = useState([]);

  useEffect(() => {
    setIsLoading(clientMasterData?.isLoading);
    if (!clientMasterData?.isLoading) {
      setSearchLoading(false);
    }
    setTimeout(() => {
      if (!clientMasterData?.isLoading && currentPage > 1 && tableRef.current && tableScrollTop) {
        tableRef.current.scrollTo({ top: tableScrollTop });
      }
    }, 200);
  }, [clientMasterData.isLoading, currentPage, tableScrollTop]);

  const hideDots = () => {
    setTimeout(() => {
      setUpdatedCLientId(0);
    }, 6000);
  };

  const showMappedCohortsModal = () => {
    setMappedCohortsModal(true);
  };

  useEffect(() => {
    if (clientMasterData.isError) {
      setSnackBarOpen(true);
    } else if (clientMasterData.message === 'Client updated') {
      batchUpdates(() => {
        setEditmodal(false);
        setSnackBarOpen(true);
        setUpdatedCLientId(clientMasterData.id);
        setCurrentPage(1);
        setTableScrollTop(0);
      });
      hideDots();
    } else if (clientMasterData.message === 'Client record created') {
      batchUpdates(() => {
        setNewClient(false);
        setEditmodal(false);
        setSnackBarOpen(true);
        setUpdatedCLientId(clientMasterData.id);
        setCurrentPage(1);
        setTableScrollTop(0);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientMasterData]);

  useEffect(() => {
    if (deleteClientData.isError) {
      setSnackBarOpen(true);
    } else if (deleteClientData.isMappedCohorts === true) {
      showMappedCohortsModal();
      setMappedCohortsData(deleteClientData.mapped_cohorts);
    } else if (deleteClientData.status === 'success') {
      setSnackBarOpen(true);
    }

    const timeoutId = setTimeout(() => {
      deleteClientResetRequest();
    }, 2000);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line max-len
  }, [deleteClientData.isError, deleteClientData.isMappedCohorts, deleteClientData.status, deleteClientResetRequest, deleteClientData.mapped_cohorts]);

  const debouncedSearchHandler = useMemo(
    () => _debounce((value) => loadMoreData(1, value), 1000),
    [loadMoreData],
  );

  const handleSearch = (searchVal) => {
    debouncedSearchHandler(searchVal);
    batchUpdates(() => {
      setSearchValue(searchVal);
      setCurrentPage(1);
      setTableScrollTop(0);
      setSearchLoading(true);
    });
  };

  const editClientData = (row) => {
    batchUpdates(() => {
      setNewClient(false);
      setEditmodal(true);
      setSelectedPortfolioRow(row);
    });
  };

  const deleteClientById = (row) => {
    setPickedRow(row);
    setDeleteModal(true);
  };

  const onUpdateClientData = (data) => {
    updateClient(data);
  };

  const onAddNewClient = (data) => {
    addNewClient(data);
  };

  const onLoadMore = () => {
    loadMoreData(currentPage + 1, searchValue);
    batchUpdates(() => {
      setCurrentPage(currentPage + 1);
      setTableScrollTop(tableRef.current.scrollTop);
    });
  };
  const preCloseModal = () => {
    batchUpdates(() => {
      setEditmodal(false);
      setNewClient(false);
    });
  };
  const preCloseDeleteModal = () => {
    setDeleteModal(false);
  };
  const preCloseMappedCohortModal = () => {
    setMappedCohortsModal(false);
    setMappedCohortsData([]);
  };
  const onAlertClose = () => {
    setSnackBarOpen(false);
  };

  const onDeleteClient = (data) => {
    deleteClient(data);
    setDeleteModal(false);
  };

  const showDomainValidationError = () => {
    batchUpdates(() => {
      setSnackBarOpen(true);
      setAlertMessage({ type: 'error', message: 'Not a valid Domain' });
    });
  };

  const onDownloadLog = async () => {
    try {
      await downloadClientMaster(new Date());
    } catch (e) {
      // TODO: Show error toast
      console.error(e);
    }
  };

  const getSearchIcon = () => {
    if (searchLoading) {
      return <CircularProgress size="1.5rem" />;
    }
    if (!searchValue) {
      return <SearchIcon />;
    }
    return <CloseIcon data-testid="clearSearch" onClick={() => handleSearch('')} style={{ cursor: 'pointer' }} />;
  };

  const transformClientData = (data) => {
    const transformData = data.map((item) => {
      if (item.domains) {
        item.domains = item.domains.split(',').join(', ');
      }
      return item;
    });
    return transformData;
  };

  const addNewClientHandler = () => {
    batchUpdates(() => {
      setEditmodal(true);
      setNewClient(true);
    });
  };
  if (isLoading && !searchLoading) return <LoadingCircle />;
  return (
    <Paper elevation={0} className={classes.mainWrapper}>
      <Header
        heading="Client Master Data"
        subHeading="Details of all the clients on the platform"
        className={classes.pageHeader}
      >
        {!isCPAdmin && (
          <Button
            color="primary"
            variant="outlined"
            style={{ maxHeight: '3.25rem', marginLeft: '1rem' }}
            fontSize="medium"
            onClick={() => onDownloadLog()}
          >
            Download Client Master Data
          </Button>
        )}
      </Header>
      <Box className={classes.wrapper}>
        <Container maxWidth={false} className={classes.middleWrapper}>
          <Box className={classes.portfolioHeading}>
            <Typography variant="h1">{`${totalRows} clients`}</Typography>
          </Box>
          <Box className={classes.portfolioTopSection}>
            <Box className={classes.inputSearchField}>
              <LabledTextField
                label="Search"
                placeholder="Search by Client ID / Name"
                className={classes.inputBox}
                inputProps={{
                  name: 'search_client',
                  className: classes.input,
                  'data-testid': 'search_client',
                  InputProps: {
                    endAdornment: getSearchIcon(),
                  },
                }}
                value={searchValue}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Box>
            {
              !isCPAdmin && (
                <Button disabled={false} color="primary" variant="contained" onClick={addNewClientHandler}>
                  Add  Client
                </Button>
              )
            }
          </Box>
          {
            totalRows ? (
              <MasterDataTable
                tabledata={transformClientData(clientTableData)}
                tableColumn={CLIENT_MASTERDATA_TABLE_HEAD_ATTRIBUTES}
                isAdmin={isAdmin}
                adminRestriction={false}
                updatedId={updatedClientId}
                ref={tableRef}
                actionList={isAdmin ? [
                  {
                    title: 'Edit Client',
                    handler: editClientData,
                  },
                  {
                    title: 'Delete Client',
                    handler: deleteClientById,
                  },
                ] : [{
                  title: 'Edit Client',
                  handler: editClientData,
                }]}
                loadMoreButton
                totalItems={totalRows}
                loadMoreText="Client"
                hasMore={hasMore}
                isLoading={!!isLoading}
                onLoadMore={onLoadMore}
              />
            )
              : (
                !isLoading
                && (
                  <Box className={classes.noDataContent}>
                    <NoDataComponent
                      imgSrc={NoSearchedUserData}
                      primaryText="Oops!"
                      secondaryText="Could not find Client you were looking for."
                      secondaryTextCls={classes.secondaryTextCls}
                    />
                  </Box>
                )
              )
          }

        </Container>
      </Box>
      {
        editModal
        && (
          <EditClientModal
            open={editModal}
            onClose={preCloseModal}
            clientData={selectedPortfolioRow}
            onUpdateClientData={onUpdateClientData}
            onAddClient={onAddNewClient}
            isLoading={isLoading}
            showDomainValidationError={showDomainValidationError}
            isNewClient={isNewClient}
          />
        )
      }
      {
        deleteModal
        && (
          <DeleteModal
            open={deleteModal}
            onClose={preCloseDeleteModal}
            data={pickedRow}
            onConfirmDelete={onDeleteClient}
            heading="Delete Client"
            subHeading="You are about to delete client"
            feature={pickedRow?.client_name}
            helperText="Please note, while this client will be deleted, the details of this client may
            not be present on the platform anymore."
          />
        )
      }
      {
        mappedCohortsModal
        && (
          <MappedCohortsModal
            open={mappedCohortsModal}
            onClose={preCloseMappedCohortModal}
            data={mappedCohortsData}
            heading="This client cannot be deleted as it is mapped to multiple cohorts"
            subHeading="Client is mapped to enrollment cohort(s). Please try again"
            tableColumn1={{ id: 'ec_code', label: 'Cohort Code' }}
            tableColumn2={{ id: 'config_id', label: 'Program Name' }}
          />
        )
      }
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isSnackBarOpen}
        autoHideDuration={6000}
        onClose={onAlertClose}
      >
        <Alert
          className={classes.alertSnackBar}
          onClose={onAlertClose}
          severity={alertMessage?.type}
        >
          {alertMessage?.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

ClientPage.propTypes = {
  clientMasterData: PropTypes.object.isRequired,
  updateClient: PropTypes.func.isRequired,
  addNewClient: PropTypes.func.isRequired,
  alertMessage: PropTypes.object.isRequired,
  loadMoreData: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  setAlertMessage: PropTypes.func.isRequired,
  deleteClient: PropTypes.func.isRequired,
  deleteClientData: PropTypes.shape({
    isError: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    mapped_cohorts: PropTypes.array.isRequired,
    isMappedCohorts: PropTypes.bool.isRequired,
  }).isRequired,
  deleteClientResetRequest: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default memo(ClientPage, (prevProps, nextProps) => (
  prevProps.clientMasterData.isLoading === nextProps.clientMasterData.isLoading
  && prevProps.clientMasterData.isError === nextProps.clientMasterData.isError
  && prevProps.clientMasterData.id === nextProps.clientMasterData.id
  && prevProps.clientMasterData.message === nextProps.clientMasterData.message
  && prevProps.clientMasterData.page === nextProps.clientMasterData.page
  && prevProps.clientMasterData.searchText === nextProps.clientMasterData.searchText
  && prevProps.clientMasterData.result === nextProps.clientMasterData.result
  && prevProps.clientMasterData.statusText === nextProps.clientMasterData.statusText
  && prevProps.clientMasterData.message === nextProps.clientMasterData.message
  && prevProps.alertMessage === nextProps.alertMessage
  && prevProps.deleteClientData.isError === nextProps.deleteClientData.isError
  && prevProps.deleteClientData.status === nextProps.deleteClientData.status
  && prevProps.clientMasterData.mapped_cohorts === nextProps.clientMasterData.mapped_cohorts
  && prevProps.clientMasterData.isMappedCohorts === nextProps.clientMasterData.isMappedCohorts
));
