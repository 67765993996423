import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
  actionWrapper: {
    minWidth: '18rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const CtaActions = ({
  ctaText, isReorderingEnabled, onEnableReordering, onSave, onCancel,
}) => {
  const classes = useStyles();

  return (
    <>
      {
        isReorderingEnabled ? (
          <Box className={classes.actionWrapper}>
            <Button
              variant="outlined"
              color="primary"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={onSave}>
              Save Changes
            </Button>
          </Box>
        ) : (
          <Button
            variant="outlined"
            color="primary"
            onClick={onEnableReordering}
          >
            {ctaText}
          </Button>
        )
      }
    </>
  );
};
CtaActions.propTypes = {
  ctaText: PropTypes.string.isRequired,
  isReorderingEnabled: PropTypes.bool.isRequired,
  onEnableReordering: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CtaActions;
