import React from 'react';
import PropTypes from 'prop-types';

import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash/get';

import makeStyles from '@material-ui/core/styles/makeStyles';

import UploadPage from '../UploadPage';
import {
  ROUTE_HOME, ROUTE_REPORT,
} from '../../constants';
import { VERY_LIGHT_GREY } from '../../stylesheets/colors';
import BreadCrumbHOC from '../../components/BreadCrumbHOC';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: VERY_LIGHT_GREY,
  },
  breadCrumbWrapper: {
    display: 'flex',
    margin: '2rem',
  },
  uploadButtonWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  uploadButtonWrapperInner: {
    minHeight: '708px',
    flexBasis: 'auto',
    flexGrow: 1,
  },
});

const CreateProgramPage = (props) => {
  const { match } = props;
  const programSubType = get(match, 'params.programSubType');

  const classes = useStyles();
  const history = useHistory();

  const goToReport = (programId) => history
    .push(`/${ROUTE_REPORT}/${programId}/${programSubType}`);

  const breadCrumbList = [
    { label: 'Home', redirectionUrl: `/${ROUTE_HOME}`, isActive: false },
    { label: 'Create new Program', redirectionUrl: '', isActive: true },
  ];

  return (
    <div className={classes.wrapper}>
      <div className={classes.breadCrumbWrapper}>
        <BreadCrumbHOC list={breadCrumbList} />
      </div>
      <div className={classes.uploadButtonWrapper}>
        <div className={classes.uploadButtonWrapperInner}>
          <UploadPage match={match} goToReport={goToReport} />
        </div>
      </div>
    </div>
  );
};

CreateProgramPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programSubType: PropTypes.string,
    }),
  }).isRequired,
  mid: PropTypes.shape({
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  mid: state.mid,
});

export default withRouter(connect(mapStateToProps)(CreateProgramPage));
