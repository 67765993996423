/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { DialogTitle } from '@mui/material';
import { format } from 'date-fns';
import CustomUploadModal from '../../components/common/CustomUploadModal';
import { LoaderInner } from '../../components/Loader';
import EnrollmentStuckQueue from '../../components/common/EnrollmentStuckQueue';
import UploadReportModal from './UploadReportModal';
import ActivityInitiatedInstruction from '../../components/WaveManagement/ActivityInitiatedInstruction';
import FormattedTypography from '../../components/common/FormattedTypography';

import CheckRoundIcon from '../../assets/icons/green-round-check.svg';
import BlueAlertIcon from '../../assets/icons/icon-blue-alert.svg';
import ErrorRoundIcon from '../../assets/icons/error-round.svg';

import MCKINSEY_SANS from '../../stylesheets/fonts';
import {
  COMPLETED, COMPLETED_WITH_ERRORS, FAILED, QUEUED,
} from '../../constants';
import { MEDIUM_GREY } from '../../stylesheets/colors';
import { downloadFile } from '../common/apis';
import { ordinalSuffix } from '../../helpers/formattingHelpers';
import LoadingCircle from '../../components/common/LoadingCircle/LoadingCircle';

const useStyles = makeStyles(() => ({
  logoWrapper: {
    margin: '0 auto',
  },
  avtarSection: {
    padding: '1rem 1rem 0 1rem',
    marginBottom: '0 !important',
    marginTop: '2rem !important',
  },
  bodyTextClass: {
    fontSize: '2rem',
    fontWeight: 'bold',
    fontFamily: MCKINSEY_SANS,
    textAlign: 'center',
  },
  headingWrapper: {
    padding: '0rem 15rem 1rem !important',
  },
  headingWrapperForProgress: {
    flexDirection: 'column',
    textAlign: 'center',
  },
  contentBox: {
    marginBottom: '2rem',
  },
  suffixForProgress: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: MEDIUM_GREY,
    marginTop: '0.5rem',
  },
  dialogContainer: {
    marginTop: '50px',
  },
}));

const UploadStatusModal = ({
  open, onClose, progress, action,
}) => {
  const classes = useStyles();
  const [modalState, setModalState] = useState(null);
  const onDownloadLog = async () => {
    try {
      // eslint-disable-next-line max-len
      await downloadFile(progress?.transaction_id, '', 'lms_mck_id_upload', progress?.created_at, '', false);
    } catch (e) {
      console.error(e);
    }
  };

  const reportModalButtonProps = () => {
    if ([COMPLETED_WITH_ERRORS, FAILED].includes(progress?.status)) {
      return {
        primaryBtnText: 'Download Log',
        primaryBtnProps: { onClick: () => onDownloadLog() },
      };
    }
    return {
      primaryBtnText: 'Close',
      primaryBtnProps: { onClick: () => onClose() },
    };
  };

  const [modalSchema, setModalScheme] = useState({
    PROGRESS: {
      component: ActivityInitiatedInstruction,
      wrapperProps: {
        logo: (
          <div className={classes.progressRow}>
            <LoaderInner progress={0} scaleFactor={1.5} />
          </div>
        ),
        heading: null,
        breakpoint: 'md',
        primaryBtnText: 'Close',
        primaryBtnProps: {
          onClick: () => onClose(),
        },
      },
      props: {},
    },
    QUEUED: {
      component: EnrollmentStuckQueue,
      wrapperProps: {
        logo: <></>,
        heading: null,
        breakpoint: 'md',
      },
      props: {
        queue_position: '',
      },
    },
    REPORT: {
      component: UploadReportModal,
      wrapperProps: {
        logo: null,
        heading: null,
        breakpoint: 'md',
      },
      props: {},
    },
  });

  const modalText = (name) => ({
    PROCESSING: {
      lms_mck_id_upload: `Uploading LMS users file '${name}'`,
    },
    COMPLETED: {
      lms_mck_id_upload: 'LMS users file has been uploaded successfully!',
    },
    FAILED: {
      lms_mck_id_upload: 'File upload failed!',
    },
    COMPLETED_WITH_ERRORS: {
      lms_mck_id_upload: 'LMS users file upload was completed with error',
    },
  });
  const modalSubHeadingText = (name) => ({
    COMPLETED: {
      lms_mck_id_upload: '',
    },
    FAILED: {
      lms_mck_id_upload: `LMS users file '${name}' could not be uploaded`,
    },
    COMPLETED_WITH_ERRORS: {
      lms_mck_id_upload: `'${name}' was completed with errors`,
    },
  });

  const applyReportModal = useCallback(() => {
    batchUpdates(() => {
      setModalState('REPORT');
      setModalScheme((schema) => ({
        ...schema,
        REPORT: {
          ...schema.REPORT,
          wrapperProps: {
            ...schema.REPORT.wrapperProps,
            logoWrapper: classes.logoIcon,
            contentWrapper: classes.contentBox,
            headingDivider: !(progress?.status === COMPLETED || progress?.status === FAILED),
            contentDivider: true,
            disableGutters: true,
            logo: (
              <img
                src={
                  progress?.status === COMPLETED
                    ? CheckRoundIcon
                    : progress?.status === FAILED
                      ? ErrorRoundIcon
                      : BlueAlertIcon
                }
                alt="status-icons"
                width={60}
                height={60}
                className={classes.avtarSection}
              />
            ),
            heading: (
              <DialogTitle className={classes.headingWrapper}>
                <FormattedTypography
                  className={classes.bodyTextClass}
                  body={modalText(progress?.file_name)[progress?.status][action]}
                  subHeading={modalSubHeadingText(progress?.file_name)[progress?.status][action]}
                  helperNode={progress?.status === FAILED ? 'Please try again later.' : ''}
                />
              </DialogTitle>
            ),
            ...reportModalButtonProps(),
          },
          props: {
            ...schema.REPORT.props,
            progressData: { ...progress },
            action,
          },
        },
      }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const applyProgressModal = useCallback(() => {
    if (progress) {
      batchUpdates(() => {
        setModalState('PROGRESS');
        setModalScheme((schema) => ({
          ...schema,
          PROGRESS: {
            ...schema.PROGRESS,
            wrapperProps: {
              ...schema.PROGRESS.wrapperProps,
              contentDivider: false,
              headingDivider: false,
              heading: (
                <>
                  <FormattedTypography
                    containerClassName={classes.headingWrapperForProgress}
                    className={classes.headingBody}
                    body="LMS Users"
                    suffix={
                      modalText(progress?.file_name).PROCESSING[action]
                    }
                    suffixClassName={classes.suffixForProgress}
                  />
                </>
              ),
              logo: (
                <div className={classes.progressRow}>
                  <LoaderInner
                    progress={progress?.percentage ? progress?.percentage : 0}
                    scaleFactor={0.75}
                  />
                </div>
              ),
              primaryBtnProps: {
                ...schema.PROGRESS.wrapperProps.primaryBtnProps,
              },
            },
            props: {
              ...schema.PROGRESS.props,
              progressData: { ...progress },
              username: progress?.created_by,
              createDateTime: progress?.created_at ? format(new Date(`${progress?.created_at}+00:00`), "do MMM 'at' hh:mm a") : '',
            },
          },
        }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const applyQueuedModal = useCallback(() => {
    batchUpdates(() => {
      setModalState('QUEUED');
      setModalScheme((schema) => ({
        ...schema,
        QUEUED: {
          ...schema.QUEUED,
          props: {
            ...schema.QUEUED.props,
            queue_position: progress?.queue_position || progress?.queue_position === 0 ? ordinalSuffix(progress?.queue_position) : '',
          },
        },
      }));
    });
  }, [progress]);

  useEffect(() => {
    if (progress && Object.keys(progress).length && open) {
      if (progress?.done === true) {
        applyReportModal();
      } else if (progress?.status === QUEUED) {
        applyQueuedModal();
      } else {
        applyProgressModal();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  const ModalComponent = modalSchema[modalState] ? modalSchema[modalState].component : null;

  return (
    <>
      {ModalComponent ? (
        <CustomUploadModal open={open} onClose={onClose} {...modalSchema[modalState].wrapperProps}>
          <ModalComponent {...modalSchema[modalState].props} />
        </CustomUploadModal>
      ) : (
        <CustomUploadModal open={open} onClose={onClose} contentWrapper={classes.dialogContainer}>
          <LoadingCircle />
        </CustomUploadModal>
      )}
    </>
  );
};

UploadStatusModal.defaultProps = {

};

UploadStatusModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  progress: PropTypes.shape({
    done: PropTypes.bool.isRequired,
    percentage: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    transaction_id: PropTypes.number,
    created_at: PropTypes.string,
    created_by: PropTypes.string,
    file_name: PropTypes.string,
    config_id: PropTypes.string,
    queue_position: PropTypes.string,
    progesskey: PropTypes.string,
  }).isRequired,
  onSyncAgainClick: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
};

export default UploadStatusModal;
