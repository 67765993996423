import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import {
  COMPLETED,
} from '../../constants';
import CheckRoundIcon from '../../assets/icons/green-round-check.svg';
import ErrorRoundIcon from '../../assets/icons/error-round.svg';
import { MEDIUM_GREY } from '../../stylesheets/colors';
import StatusModalWrapper from '../ActivityLogs/StatusModalWrapper';
import GenericReportOverview from '../common/GenericReportOverview';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  subtitle: {
    color: MEDIUM_GREY,
    textAlign: 'center',
  },
  subtitleStyles: {
    marginTop: '1.25rem',
  },
  headingWrapper: {
    padding: '0 1.5rem 1.5rem 1.5rem',
    display: 'flex',
    flexDirection: 'column',
  },
  completedSubheading: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentWrapper: {
    marginBottom: '0',
  },
}));

const ReorderComponentsStatuses = ({
  open, onClose, modalState, transactionId, getReport, progressData,
  progressErrorMsg, onDownloadLog,
}) => {
  const classes = useStyles();

  const getStatus = useCallback(({ reportStatus }) => {
    if (reportStatus === COMPLETED) {
      return {
        src: CheckRoundIcon,
        heading: 'Components re-ordered successfully',
        primaryBtnText: 'Close',
        primaryBtnClick: () => onClose(),
      };
    }

    return {
      src: ErrorRoundIcon,
      heading: 'Failed to re-order components',
      primaryBtnText: 'Download Log',
      primaryBtnClick: (created_at) => onDownloadLog({
        transaction_id: transactionId,
        created_at,
      }),
    };
  }, [onDownloadLog, onClose, transactionId]);

  const reportModal = useCallback(async () => {
    const result = await getReport(transactionId);
    const reportStatus = result.status;
    const statusObj = getStatus({ reportStatus });

    return ({
      component: GenericReportOverview,
      wrapperProps: {
        disableGutters: true,
        logo: (
          <Box>
            <img src={statusObj?.src} alt="copied-status" width={40} height={40} />
          </Box>
        ),
        heading: (
          <Box className={classes.headingWrapper}>
            <Typography variant="subtitle1" component="span" className={classes.title}>{statusObj?.heading}</Typography>
            <Typography variant="subtitle2" component="span" className={`${classes.subtitle} ${classes.subtitleStyles}`}>{statusObj?.subHeading}</Typography>
          </Box>
        ),
        contentDivider: true,
        primaryBtnText: statusObj?.primaryBtnText,
        primaryBtnProps: {
          onClick: () => statusObj?.primaryBtnClick(result?.created_at),
        },
        breakpoint: 'md',
        contentWrapper: classes.contentWrapper,
      },
      props: {
        status: reportStatus,
      },
    });
  }, [getReport, transactionId, getStatus, classes.headingWrapper,
    classes.title, classes.subtitle, classes.subtitleStyles,
    classes.contentWrapper]);

  return (
    <StatusModalWrapper
      open={open}
      onClose={onClose}
      modalState={modalState}
      progressData={progressData}
      progressErrorMsg={progressErrorMsg}
      progressDataModal={() => { }}
      reportModal={reportModal}
    />
  );
};

ReorderComponentsStatuses.defaultProps = {
  progressErrorMsg: 'Unable to get Progress',
};

ReorderComponentsStatuses.propTypes = {
  transactionId: PropTypes.number.isRequired,
  modalState: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDownloadLog: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
  progressData: PropTypes.object.isRequired,
  progressErrorMsg: PropTypes.string,
};

export default ReorderComponentsStatuses;
