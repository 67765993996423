import React, {
  useEffect, useState, memo, useMemo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import _debounce from 'lodash.debounce';
import {
  Box, Paper, CircularProgress, Container,
} from '@mui/material';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Header from '../common/Header';
import LabledTextField from '../common/LabledTextField';
import AddEditPortfolioModal from './AddEditPortfolioModal';
import DeletePortfolioModal from './DeletePortfolioModal';
import LoadingCircle from '../common/LoadingCircle/LoadingCircle';
import MasterDataTable from './MasterDataTable';
import NoDataComponent from '../common/NoDataComponent';
import NoSearchedUserData from '../../assets/img/noChannel.svg';
import {
  PORTFOLIO_MASTERDATA_TABLE_HEAD_ATTRIBUTES,
} from '../../constants';
import { downloadProgramData } from '../../containers/common/apis';
import { getErrorMessage } from '../../helpers/apiHelper';
import useNotifications from '../../hooks/useNotifications';

const PortfolioPage = ({
  portfolioOfferingData, addNewOffering, deleteOffering,
  alertMessage, updateOffering, loadMoreData,
  getPortfolioOfferingsList, isAdmin, classes,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [addEditModal, setAddEditmodal] = useState(false);
  const [isPortfolioEdit, setIsPortfolioEdit] = useState(false);
  const [selectedPortfolioRow, setSelectedPortfolioRow] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSnackBarOpen, setSnackBarOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [portfolioOptions, setPortfolioOptions] = useState([]);
  const [offeringOptions, setOfferingsOptions] = useState([]);
  const [tableScrollTop, setTableScrollTop] = useState(0);
  const [updatedClientId, setUpdatedClientId] = useState(0);
  const [searchLoading, setSearchLoading] = useState(false);
  const portfolioTableData = portfolioOfferingData?.result?.data ?? [];
  const totalRows = portfolioOfferingData?.result?.total_rows ?? 0;
  const hasMore = portfolioOfferingData?.result?.has_more ?? false;
  const tableRef = useRef(null);
  const { notifyError } = useNotifications();

  useEffect(() => {
    setIsLoading(portfolioOfferingData?.isLoading);
    if (!portfolioOfferingData?.isLoading) {
      setSearchLoading(false);
    }
    setTimeout(() => {
      if (!portfolioOfferingData?.isLoading
        && currentPage > 1 && tableRef.current && tableScrollTop) {
        tableRef.current.scrollTo({ top: tableScrollTop });
      }
    }, 200);
  }, [currentPage, portfolioOfferingData.isLoading, tableScrollTop]);

  useEffect(() => {
    setPortfolioOptions(portfolioOfferingData.portfolioList);
  }, [portfolioOfferingData.portfolioList]);

  useEffect(() => {
    setOfferingsOptions(portfolioOfferingData.offeringList);
  }, [portfolioOfferingData.offeringList]);

  const hideDots = () => {
    setTimeout(() => {
      setSelectedPortfolioRow({});
      setUpdatedClientId(0);
    }, 6000);
  };

  const onDownloadprogram = async () => {
    try {
      await downloadProgramData(new Date());
    } catch (e) {
      notifyError(getErrorMessage(e));
    }
  };

  useEffect(() => {
    if (portfolioOfferingData.isError) {
      setSnackBarOpen(true);
    } else if (portfolioOfferingData.message === 'Portfolio is created' || portfolioOfferingData.message === 'Portfolio updated') {
      batchUpdates(() => {
        setAddEditmodal(false);
        setSnackBarOpen(true);
        setIsPortfolioEdit(false);
        setUpdatedClientId(portfolioOfferingData.id);
        setCurrentPage(1);
        setTableScrollTop(0);
      });
      hideDots();
    } else if (portfolioOfferingData.message === 'Portfolio deleted successfully') {
      batchUpdates(() => {
        setSnackBarOpen(true);
        setSelectedPortfolioRow({});
        setCurrentPage(1);
        setTableScrollTop(0);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioOfferingData]);

  const debouncedSearchHandler = useMemo(
    () => _debounce((value) => loadMoreData(1, value), 1000),
    [loadMoreData],
  );

  const handleSearch = (searchVal) => {
    debouncedSearchHandler(searchVal);
    batchUpdates(() => {
      setSearchValue(searchVal);
      setCurrentPage(1);
      setTableScrollTop(0);
      setSearchLoading(true);
    });
  };

  const editOffering = (row) => {
    setAddEditmodal(true);
    setIsPortfolioEdit(true);
    setSelectedPortfolioRow(row);
    getPortfolioOfferingsList({ portfolio: row.portfolio });
  };

  const addNewPortfolio = () => {
    getPortfolioOfferingsList();
    setAddEditmodal(true);
    setSelectedPortfolioRow({});
  };

  const onUpdatePortfolio = (data) => {
    updateOffering(data);
  };

  const onCreatePortfolio = (data) => {
    addNewOffering(data);
    setSelectedPortfolioRow(data);
  };

  const openDeleteModal = (row) => {
    setDeleteModal(true);
    setSelectedPortfolioRow(row);
  };

  const onConfirmDelete = ({ id }) => {
    deleteOffering(id);
    setDeleteModal(false);
  };

  const preCloseModal = () => {
    batchUpdates(() => {
      setAddEditmodal(false);
      setIsPortfolioEdit(false);
      setDeleteModal(false);
      setSelectedPortfolioRow({});
    });
  };

  const onLoadMore = () => {
    loadMoreData(currentPage + 1, searchValue);
    batchUpdates(() => {
      setCurrentPage(currentPage + 1);
      setTableScrollTop(tableRef.current.scrollTop);
    });
  };

  const onAlertClose = () => {
    setSnackBarOpen(false);
  };

  const getSearchIcon = () => {
    if (searchLoading) {
      return <CircularProgress size="1.5rem" />;
    }
    if (!searchValue) {
      return <SearchIcon />;
    }
    return <CloseIcon data-testid="clearSearch" onClick={() => handleSearch('')} style={{ cursor: 'pointer' }} />;
  };

  if (isLoading && !addEditModal && !searchLoading) return <LoadingCircle />;
  return (
    <Paper elevation={0} className={classes.mainWrapper}>
      <Header
        heading="Portfolio, Offerings and Program Master Data"
        subHeading="Details of all the clients on the platform"
        className={classes.pageHeader}
        childrenWrapper={classes.exportBtnWrapper}
      >
        <Button
          color="primary"
          variant="outlined"
          style={{ maxHeight: '3.25rem', marginLeft: '1rem' }}
          fontSize="medium"
          onClick={() => onDownloadprogram()}
        >
          Export LP level data
        </Button>
      </Header>
      <Box className={classes.wrapper}>
        <Container maxWidth={false} className={classes.middleWrapper}>
          <Box className={classes.portfolioHeading}>
            <Typography variant="h1">{`${totalRows} Portfolio, Offerings and Program`}</Typography>
          </Box>
          <Box className={classes.portfolioTopSection}>
            <Box className={classes.inputSearchField}>
              <LabledTextField
                label="Search"
                placeholder="Search by Portfolio / Offering"
                className={classes.inputBox}
                inputProps={{
                  name: 'search_offerings',
                  className: classes.input,
                  'data-testid': 'search_offerings',
                  InputProps: {
                    endAdornment: getSearchIcon(),
                  },
                }}
                value={searchValue}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Box>
            {
              isAdmin && (
                <Button disabled={false} color="primary" variant="contained" onClick={addNewPortfolio}>
                  Add  Portfolio/Offerings
                </Button>
              )
            }

          </Box>
          {
            totalRows ? (
              <MasterDataTable
                tabledata={portfolioTableData}
                tableColumn={PORTFOLIO_MASTERDATA_TABLE_HEAD_ATTRIBUTES}
                isAdmin={isAdmin}
                adminRestriction
                updatedId={updatedClientId}
                ref={tableRef}
                actionList={[
                  {
                    title: 'Edit Offering',
                    handler: editOffering,
                  },
                  {
                    title: 'Delete Offering',
                    handler: openDeleteModal,
                  },
                ]}
                loadMoreButton
                totalItems={totalRows}
                loadMoreText="Portfolio"
                hasMore={hasMore}
                isLoading={!!isLoading}
                onLoadMore={onLoadMore}
              />
            )
              : (
                !isLoading && (
                  <Box className={classes.noDataContent}>
                    <NoDataComponent
                      imgSrc={NoSearchedUserData}
                      primaryText="Oops!"
                      secondaryText="Could not find Portfolio/ Offering/ Program you were looking for."
                      secondaryTextCls={classes.secondaryTextCls}
                    />
                  </Box>
                )
              )
          }
        </Container>
      </Box>
      {
        addEditModal
        && (
          <AddEditPortfolioModal
            open={addEditModal}
            onClose={preCloseModal}
            isPortfolioEdit={isPortfolioEdit}
            portFolioData={selectedPortfolioRow}
            onCreatePortfolio={onCreatePortfolio}
            onUpdatePortfolio={onUpdatePortfolio}
            alertMessage={alertMessage}
            isLoading={isLoading}
            portfolioOptions={portfolioOptions}
            offeringOptions={offeringOptions}
            getPortfolioOfferingsList={getPortfolioOfferingsList}
          />
        )
      }
      {
        deleteModal && (
          <DeletePortfolioModal
            open={deleteModal}
            onClose={preCloseModal}
            portfolioData={selectedPortfolioRow}
            onConfirmDelete={onConfirmDelete}
          />
        )
      }
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isSnackBarOpen}
        autoHideDuration={6000}
        onClose={onAlertClose}
      >
        <Alert
          className={classes.alertSnackBar}
          onClose={onAlertClose}
          severity={alertMessage?.type}
        >
          {alertMessage?.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};
PortfolioPage.propTypes = {
  portfolioOfferingData: PropTypes.object.isRequired,
  addNewOffering: PropTypes.func.isRequired,
  deleteOffering: PropTypes.func.isRequired,
  updateOffering: PropTypes.func.isRequired,
  alertMessage: PropTypes.object.isRequired,
  loadMoreData: PropTypes.func.isRequired,
  getPortfolioOfferingsList: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

export default memo(PortfolioPage, (prevProps, nextProps) => (
  prevProps.portfolioOfferingData.isLoading === nextProps.portfolioOfferingData.isLoading
  && prevProps.portfolioOfferingData.isError === nextProps.portfolioOfferingData.isError
  && prevProps.portfolioOfferingData.id === nextProps.portfolioOfferingData.id
  && prevProps.portfolioOfferingData.isPortfolioListCall
  === nextProps.portfolioOfferingData.isPortfolioListCall
  && prevProps.portfolioOfferingData.message === nextProps.portfolioOfferingData.message
  && prevProps.portfolioOfferingData.page === nextProps.portfolioOfferingData.page
  && prevProps.portfolioOfferingData.searchText === nextProps.portfolioOfferingData.searchText
  && prevProps.portfolioOfferingData.offeringList === nextProps.portfolioOfferingData.offeringList
  && prevProps.portfolioOfferingData.portfolioList === nextProps.portfolioOfferingData.portfolioList
  && prevProps.portfolioOfferingData.result === nextProps.portfolioOfferingData.result
  && prevProps.portfolioOfferingData.statusText === nextProps.portfolioOfferingData.statusText
));
