import React from 'react';
import { format } from 'date-fns';

import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { MEDIUM_GREY } from '../../stylesheets/colors';
import LoaderCancelSection from './LoaderCancelSection';
import { CANCEL_TRANSACTION_CONFIRMATION } from '../../constants';

const GenericProgressModal = ({
  initiatedBy, initiatedAt, onCancelTransaction, cancellationRequested, setCancellationRequested,
  width,
}) => (
  <Box style={{ width, margin: '0 auto' }}>
    <Typography variant="body1" style={{ textAlign: 'center', color: MEDIUM_GREY }}>
      Activity initiated by
      {' '}
      {initiatedBy}
      {' '}
      on
      {' '}
      {format(new Date(`${initiatedAt}+00:00`), 'do MMM yy hh:mm a')}
      {' '}
      is in process.
    </Typography>
    <Typography variant="body1" style={{ textAlign: 'center', color: MEDIUM_GREY }}>
      Please hold on a bit, we are working on it…
    </Typography>
    {onCancelTransaction ? (
      <LoaderCancelSection
        cancelText={CANCEL_TRANSACTION_CONFIRMATION}
        onCancelTransaction={onCancelTransaction}
        cancellationRequested={cancellationRequested}
        setCancellationRequested={setCancellationRequested}
      />
    ) : null}
  </Box>

);

GenericProgressModal.defaultProps = {
  onCancelTransaction: null,
  cancellationRequested: true,
  setCancellationRequested: () => { },
  width: '60%',
};

GenericProgressModal.propTypes = {
  initiatedBy: PropTypes.string.isRequired,
  initiatedAt: PropTypes.string.isRequired,
  onCancelTransaction: PropTypes.func,
  cancellationRequested: PropTypes.bool,
  setCancellationRequested: PropTypes.func,
  width: PropTypes.string,
};
export default GenericProgressModal;
