import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { CancelToken } from 'axios';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@mui/material/Box';
import _get from 'lodash/get';
import { getPortfolioOfferingProgram } from '../../containers/common/apis';
import { selectComponentStyles, selectStyles } from './AsyncSelectStyles';

let cancelTokenSource = null;

const useStyles = makeStyles(
  { ...selectComponentStyles },
);

const SelectPortfolio = ({
  label, placeholder, onChange, maxMenuHeight, selectedOptions, data,
}) => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const loadOptions = async () => {
      if (cancelTokenSource) {
        cancelTokenSource.cancel();
      }
      cancelTokenSource = CancelToken.source();
      const result = await getPortfolioOfferingProgram(data);
      const optionArray = _get(result, 'data.data', []);
      const newPrograms = optionArray.map((program) => ({
        value: program,
        label: program,
      }));
      setOptions(newPrograms);
    };
    loadOptions();
  }, [data]);

  return (
    <Box className={classes.wrapper}>
      <Typography variant="body1" className={classes.labelWrapper}>
        {label}
      </Typography>
      <Box className={classes.selectWrapper}>
        <Select
          defaultOptions
          styles={selectStyles}
          placeholder={placeholder}
          options={options}
          name={label}
          onChange={onChange}
          maxMenuHeight={maxMenuHeight}
          menuPosition="fixed"
          value={selectedOptions}
        />
      </Box>
    </Box>
  );
};

SelectPortfolio.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  maxMenuHeight: PropTypes.number,
  selectedOptions: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
};

SelectPortfolio.defaultProps = {
  maxMenuHeight: 300,
};

export default SelectPortfolio;
