import React, {
  memo, useState, useEffect, useCallback, useMemo,
} from 'react';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import { useHistory, useLocation } from 'react-router-dom';
import { CancelToken, isCancel } from 'axios';
import PropTypes from 'prop-types';

import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _findLastIndex from 'lodash/findLastIndex';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { trimValue } from '../../helpers/formattingHelpers';

import AlertBar from '../../components/common/AlertBar';
import EditProgramHeader from '../../components/EditProgram/EditProgramHeader';
import EditProgramSidebar from '../../components/EditProgram/EditProgramSidebar';
import EditProgramFormPage from '../../components/EditProgram/EditProgramFormPage';
import EditConfirmationPage from '../../components/EditProgram/EditConfirmationPage';
import EditProgramSummary from '../../components/EditProgram/EditProgramSummary';
import ErrorModal from '../../components/ErrorModal/ErrorModal';

import config from '../../config';
import { ROUTE_HOME, ROUTE_REPORT } from '../../constants';
import { internalServerErrorModalLogic } from '../common/utils';
import {
  createTransaction,
  getProgress,
  getReportData,
  proceedWithRename,
  checkNewConfigIdExists,
  editProgramTimeline,
  markComplete,
  deleteTransaction,
} from './api';
import { getUsersEnrolled } from '../OverviewUserPage/apis';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  spinnerWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  },
  timeline: {
    flex: 0.25,
  },
  body: {
    flex: 0.75,
  },
  instructionWrapper: {
    display: 'flex',
    flex: 0.34,
    flexDirection: 'column',
  },
});

const EditProgram = ({
  programMetadata, match, onProgramMetadataRefresh, onDownload,
}) => {
  const { config_id: configId, docebo: isDoceboProgram } = programMetadata;
  const programId = _get(match, 'params.programId');
  const programSubType = _get(match, 'params.programSubType');
  const tId = _get(match, 'params.transactionId');

  const [progress, setProgress] = useState({ done: false, percentage: 0 });
  const [showSpinner, setShowSpinner] = useState(false);
  const [transactionId, setTransactionId] = useState(tId);
  const [report, setReport] = useState([]);
  const [enrolledUsers, setEnrolledUsers] = useState();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [openProgressModal, setOpenProgressModal] = useState(false);
  const [timelineData, setTimelineData] = useState([]);
  const [shouldProceed, setShouldProceed] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [processSteps, setProcessSteps] = useState([
    {
      label: 'Modify Config ID',
      completed: true,
    },
    {
      label: 'Review and Proceed',
      completed: false,
    },
    {
      label: 'Summary',
      completed: false,
    },
  ]);
  const { pathname, hash } = useLocation();
  const history = useHistory();
  const classes = useStyles();

  const getData = useCallback(async (tranId) => {
    const [reportResponse, enrolledUserResponse] = await Promise.all([
      getReportData(tranId),
      getUsersEnrolled(programId),
    ]);
    setReport(reportResponse.data);
    setEnrolledUsers(enrolledUserResponse);
  }, [programId]);

  const proceed = useCallback((retry) => proceedWithRename({
    program_id: programId,
    transaction_id: transactionId,
    retry,
  }), [programId, transactionId]);

  useEffect(() => {
    let timer = null;
    let cancelTokenSource = null;

    const getTimelineData = async () => {
      try {
        const resp = await editProgramTimeline(programId);
        const data = _get(resp, 'data.data', []);
        setTimelineData(data);
      } catch (e) {
        console.error(e);
      }
    };

    const pollProgressApi = async () => {
      try {
        if (cancelTokenSource) {
          cancelTokenSource.cancel();
        }

        cancelTokenSource = CancelToken.source();
        const res = await getProgress(tId, cancelTokenSource.token);
        const { done } = res.data;
        if (done) {
          if (hash.substr(1) === 'retry') {
            history.push(pathname);
          } else {
            await Promise.all([
              getData(tId),
              onProgramMetadataRefresh(),
            ]);
            batchUpdates(() => {
              setOpenProgressModal(false);
              setShowProgress(false);
              setShowSpinner(false);
              setProcessSteps((steps) => steps.map(
                (v, idx) => (idx <= 2 ? { ...v, completed: true } : v),
              ));
            });
          }
        } else {
          timer = setTimeout(pollProgressApi, 1000);
        }

        // set data
        setProgress(res.data);
      } catch (err) {
        if (isCancel(err)) {
          return;
        }
        timer = internalServerErrorModalLogic(history, err, setIsErrorModalOpen, pollProgressApi);
      }
    };

    if (tId) {
      switch (hash.substr(1)) {
        case 'retry':
          batchUpdates(() => {
            setShouldProceed(true);
            setOpenProgressModal(true);
            setShowProgress(true);
            setProgress({ done: false, percentage: 0 });
          });
          break;
        case 'view':
          setShowSpinner(true);
          break;
        default:
          batchUpdates(() => {
            setProcessSteps((steps) => steps.map(
              (v, idx) => (idx <= 1 ? { ...v, completed: true } : v),
            ));
            setShouldProceed(true);
            setOpenProgressModal(true);
            setShowProgress(true);
            setProgress({ done: false, percentage: 0 });
          });
      }
      pollProgressApi();
    } else {
      getTimelineData();
      batchUpdates(() => {
        setProcessSteps((steps) => steps.map(
          (v, idx) => (idx !== 0 ? { ...v, completed: false } : v),
        ));
        setShowSpinner(false);
      });
    }

    return () => {
      clearTimeout(timer);
    };
  }, [tId, history, hash, pathname, programId, getData, onProgramMetadataRefresh]);

  const onNext = useCallback(async (values) => {
    try {
      setShowSpinner(true);

      const transaction = await createTransaction({
        program_id: programId,
        new_config_id: trimValue(values.configId),
      });

      const tranId = _get(transaction.data, 'transaction_id');
      if (!tranId) {
        throw new Error('Transaction not created');
      }

      await getData(tranId);
      batchUpdates(() => {
        setTransactionId(tranId);
        setProcessSteps((steps) => steps.map(
          (v, idx) => (idx === 1 ? { ...v, completed: true } : v),
        ));
        setShowSpinner(false);
      });
    } catch (e) {
      console.error(e);
      setShowSpinner(false);
    }
  }, [programId, getData]);

  const onProceed = useCallback(async (retry = false) => {
    try {
      setShouldProceed(true);

      await proceed(retry);

      const path = {};
      if (retry) {
        path.pathname = pathname;
        path.hash = 'retry';
      } else {
        path.pathname = `${pathname}${transactionId}`;
      }
      history.push(path);
    } catch (e) {
      console.error(e);
    }
  }, [history, proceed, transactionId, pathname]);

  const downloadLog = (transId, fileName) => (
    onDownload(`${config.ROUTES.DOWNLOAD_XLSX}/${transId}`, fileName)
  );

  const onErrorModalClose = useCallback(() => {
    setIsErrorModalOpen(false);
    history.replace(`/${ROUTE_HOME}`);
  }, [history, setIsErrorModalOpen]);

  const onProceedWithErrors = useCallback(async () => {
    try {
      await markComplete(transactionId);
      history.push({ pathname: `/${ROUTE_REPORT}/${programId}/${programSubType}` });
    } catch (e) {
      console.log(e);
    }
  }, [history, programId, programSubType, transactionId]);

  const onBack = useCallback(() => {
    deleteTransaction(transactionId);
  }, [transactionId]);

  const processStepIdx = useMemo(
    () => _findLastIndex(processSteps, { completed: true }), [processSteps],
  );

  const Body = showSpinner
    ? () => null
    : {
      step1: EditProgramFormPage,
      step2: EditConfirmationPage,
      step3: EditProgramSummary,
    }[`step${processStepIdx + 1}`];

  // Loading
  if (showSpinner) {
    return (
      <Paper className={classes.wrapper}>
        <div className={classes.spinnerWrapper}>
          <CircularProgress />
        </div>
      </Paper>
    );
  }

  return (
    <Paper className={classes.wrapper}>
      <AlertBar text="Please refrain from renaming a live program as many critical components such as enrollments will be affected." icon="error" />
      <EditProgramHeader
        shouldShow={!timelineData.length && processStepIdx === 0}
        isDoceboProgram={isDoceboProgram}
      />
      <Box className={classes.innerWrapper}>
        <EditProgramSidebar className={classes.timeline} items={processSteps} />
        <Body
          className={classes.body}
          sourceConfigId={configId}
          onNext={onNext}
          report={report}
          onProceed={onProceed}
          onBack={onBack}
          progress={progress}
          openProgressModal={openProgressModal}
          setOpenProgressModal={setOpenProgressModal}
          checkNewConfigIdExists={checkNewConfigIdExists}
          timelineData={timelineData}
          shouldProceed={shouldProceed}
          showProgress={showProgress}
          isDoceboProgram={isDoceboProgram}
          enrolledUser={enrolledUsers}
          onProceedWithErrors={onProceedWithErrors}
          downloadLog={downloadLog}
        />
      </Box>
      <ErrorModal open={isErrorModalOpen} onClose={onErrorModalClose} />
    </Paper>
  );
};

EditProgram.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string,
    }),
  }).isRequired,
  programMetadata: PropTypes.shape({
    config_id: PropTypes.string.isRequired,
    program_id: PropTypes.number.isRequired,
    docebo: PropTypes.bool.isRequired,
  }).isRequired,
  onProgramMetadataRefresh: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
};

export default memo(EditProgram,
  (prevProps, nextProps) => _isEqual(prevProps.match, nextProps.match)
    && _isEqual(prevProps.programMetadata, nextProps.programMetadata)
    && prevProps.onProgramMetadataRefresh === nextProps.onProgramMetadataRefresh
    && prevProps.onDownload === nextProps.onDownload);
