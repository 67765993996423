const filedsToUpdate = [
  {
    type: 'switch',
    label: 'Progress Logic',
    key: 'progress-logic',
  },
  {
    type: 'switch',
    label: 'Proficiency Logic',
    key: 'proficiency-logic',
  },
  {
    type: 'dropdown',
    label: 'Source Portfolio',
    key: 'portfolio',
    required: true,
  },
  {
    type: 'dropdown',
    label: 'Source Offering',
    key: 'offering',
    required: true,
  },
  {
    type: 'dropdown',
    label: 'Source Program',
    key: 'program',
    required: false,
  },
  {
    type: 'textbox',
    label: 'Elucidat Project ID',
    key: 'elucidat-project-id',
    required: false,
  },
  {
    type: 'textarea',
    label: 'Component runner',
    key: 'course-runner',
    required: false,
  },
  {
    type: 'textarea',
    label: 'Additional Metadata',
    key: 'additional-metadata',
    required: false,
  },
  {
    type: 'textarea',
    label: 'Keep in mind...',
    key: 'keep-in-mind',
    required: false,
  },
  {
    type: 'textarea',
    label: 'After you complete the course',
    key: 'after-you-complete-this-course',
    required: false,
  },
];

export default filedsToUpdate;
