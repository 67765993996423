import {
  CLIENT_DATA_FETCH,
  CLIENT_DATA_FETCH_SUCCESS,
  CLIENT_DATA_FETCH_FAILED,
  UPDATE_CLIENT_INFO,
  UPDATE_CLIENT_INFO_SUCCESS,
  UPDATE_CLIENT_INFO_FAILURE,
  ADD_NEW_CLIENT,
  ADD_NEW_CLIENT_SUCCESS,
  ADD_NEW_CLIENT_FAILURE,
} from '../constants';

import {
  DELETE_CLIENT,
  DELETE_CLIENT_STARTED,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILED,
  DELETE_CLIENT_RESET,
} from './actionTypes';

export function getClientData(data) {
  return {
    type: CLIENT_DATA_FETCH,
    data,
  };
}

export function getClientDataSuccess(results) {
  return {
    type: CLIENT_DATA_FETCH_SUCCESS,
    results,
  };
}

export function getClientDataFailure(response) {
  return {
    type: CLIENT_DATA_FETCH_FAILED,
    response,
  };
}

export function updateClientCode(data) {
  return {
    type: UPDATE_CLIENT_INFO,
    data,
  };
}

export function updateClientCodeSuccess(response) {
  return {
    type: UPDATE_CLIENT_INFO_SUCCESS,
    response,
  };
}

export function updateClientCodeFailure(response) {
  return {
    type: UPDATE_CLIENT_INFO_FAILURE,
    response,
  };
}

export function addNewClient(data) {
  return {
    type: ADD_NEW_CLIENT,
    data,
  };
}

export function addNewClientSuccess(response) {
  return {
    type: ADD_NEW_CLIENT_SUCCESS,
    response,
  };
}

export function addNewClientFailure(response) {
  return {
    type: ADD_NEW_CLIENT_FAILURE,
    response,
  };
}

export function deleteClient(data) {
  return {
    type: DELETE_CLIENT,
    data,
  };
}

export function deleteClientStarted() {
  return {
    type: DELETE_CLIENT_STARTED,
  };
}

export function deleteClientSuccess(response) {
  return {
    type: DELETE_CLIENT_SUCCESS,
    response,
  };
}

export function deleteClientFailure(response) {
  return {
    type: DELETE_CLIENT_FAILED,
    response,
  };
}

export function deleteClientReset() {
  return {
    type: DELETE_CLIENT_RESET,
  };
}
