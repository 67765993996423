import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import IndividualScormOverview from '../IndividualScormOverview';
import { COMPLETED } from '../../../constants';
import CheckRoundIcon from '../../../assets/icons/green-round-check.svg';
import ErrorRoundIcon from '../../../assets/icons/error-round.svg';
import { MEDIUM_GREY } from '../../../stylesheets/colors';
import StatusModalWrapper from '../../ActivityLogs/StatusModalWrapper';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  subtitle: {
    color: MEDIUM_GREY,
    textAlign: 'center',
  },
  subtitleStyles: {
    marginTop: '1.25rem',
  },
  headingWrapper: {
    padding: '0 1.5rem 1.5rem 1.5rem',
    display: 'flex',
    flexDirection: 'column',
  },
  completedSubheading: {
    display: 'flex',
    flexDirection: 'column',
  },
  subHeadingSection: {
    '& p': {
      color: MEDIUM_GREY,
    },
  },
  progressRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.17,
    justifyContent: 'center',
    maxHeight: '10rem',
    transform: 'scale(0.4) translate(0, -100%)',
    padding: '2rem 0',
  },
}));

const AddTrainingMaterialsStatuses = ({
  open, onClose, modalState, transactionId, getReport, progressData,
  progressErrorMsg, onDownloadLog,
}) => {
  const classes = useStyles();

  const getStatus = useCallback(({ reportStatus }) => {
    if (reportStatus === COMPLETED) {
      return {
        src: CheckRoundIcon,
        heading: 'New training material added successfully!',
        subHeading: (
          <Box className={classes.completedSubheading}>
            <Typography variant="subtitle2" component="span" className={classes.subtitle}>New training material - SCORM type has been added successfully.</Typography>
          </Box>
        ),
        primaryBtnText: 'Close',
      };
    }

    return {
      src: ErrorRoundIcon,
      heading: 'Failed to add new training material - SCORM Type.',
      subHeading: 'Failed to add new training material - SCORM Type.',
      primaryBtnText: 'Download Log',
    };
  }, [classes.completedSubheading, classes.subtitle]);

  const reportModal = useCallback(async () => {
    const result = await getReport(transactionId);
    const reportStatus = result?.status;

    const statusObj = getStatus({ reportStatus });
    return ({
      component: IndividualScormOverview,
      wrapperProps: {
        disableGutters: true,
        logo: (
          <Box>
            <img src={statusObj?.src} alt="copied-status" width={40} height={40} />
          </Box>
        ),
        heading: (
          <Box className={classes.headingWrapper}>
            <Typography variant="subtitle1" component="span" className={classes.title}>{statusObj?.heading}</Typography>
            <Typography variant="subtitle2" component="span" className={`${classes.subtitle} ${classes.subtitleStyles}`}>{statusObj?.subHeading}</Typography>
          </Box>
        ),
        headingDivider: false,
        contentDivider: true,
        primaryBtnText: statusObj?.primaryBtnText,
        primaryBtnProps: {
          onClick: reportStatus === COMPLETED ? () => onClose()
            : () => {
              onDownloadLog({
                transaction_id: transactionId,
                created_at: result?.created_at,
              });
            },
        },
        breakpoint: 'md',
      },
    });
  }, [classes.headingWrapper, classes.subtitle, classes.subtitleStyles, classes.title, onClose,
    getReport, getStatus, onDownloadLog, transactionId]);

  return (
    <StatusModalWrapper
      open={open}
      onClose={onClose}
      modalState={modalState}
      progressData={progressData}
      progressErrorMsg={progressErrorMsg}
      reportModal={reportModal}
    />
  );
};

AddTrainingMaterialsStatuses.defaultProps = {
  progressErrorMsg: 'Unable to get Progress',
};

AddTrainingMaterialsStatuses.propTypes = {
  transactionId: PropTypes.number.isRequired,
  modalState: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDownloadLog: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
  progressData: PropTypes.object.isRequired,
  progressErrorMsg: PropTypes.string,
};

export default AddTrainingMaterialsStatuses;
