import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, Box, Typography, Container,
} from '@material-ui/core';
import _map from 'lodash/map';
import ConfirmationModal from '../common/ConfirmationModal';
import FormattedTypography from '../common/FormattedTypography';
import AsyncSelectClient from '../common/AsyncSelectClient';
import AsyncProgramSelect from '../common/AsyncProgramSelect';
import CustomSwitch from '../common/CustomSwitch';

const useStyles = makeStyles({
  wrapper: {
    padding: '0 1.5rem 2rem 1.5rem',

    '& > div:not(:first-child)': {
      marginTop: '1rem',
    },
  },
  contentWidth: {
    width: '60%',
  },
  modalHeight: {
    minHeight: '30rem',
  },
  horizontalAlignment: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2rem !important',
  },
});

const UsersFilterModal = ({
  open, onClose, isLoading, loadingText,
  selectedPrograms, selectedClients, handleApplyFilters, flaggedUser,
}) => {
  const classes = useStyles();

  const [selectedProgramsClone, setSelectedProgramsClone] = useState(selectedPrograms);
  const [selectedClientsClone, setSelectedClientsClone] = useState(selectedClients);
  const [markedDeletion, toggleMarkedDeletion] = useState(flaggedUser);

  const handlePickedProgramsClone = (items) => {
    setSelectedProgramsClone(_map(items));
  };

  const handlePickedClientsClone = (items) => {
    setSelectedClientsClone(_map(items));
  };

  const resetFormBeforeClose = () => {
    onClose();
  };

  const onSave = () => {
    handleApplyFilters({ selectedProgramsClone, selectedClientsClone, markedDeletion });
  };

  const handleClearAll = () => {
    setSelectedProgramsClone([]);
    setSelectedClientsClone([]);
    toggleMarkedDeletion(false);
  };
  return (
    <ConfirmationModal
      open={open}
      isLoading={isLoading}
      onClose={isLoading ? null : resetFormBeforeClose}
      breakpoint="md"
      minHeightClass={classes.modalHeight}
      loadingText={loadingText}
      title={(<FormattedTypography body="Filters" />)}
      dialogContentClass={classes.contentWidth}
      content={(
        <Container className={classes.wrapper}>
          <AsyncProgramSelect
            label="Learning Program Config ID"
            placeholder="Select a Learning Program Config ID"
            selectedItems={selectedProgramsClone}
            onChange={handlePickedProgramsClone}
            disableOnLimitReach={false}
          />
          <AsyncSelectClient
            label="Client ID/Client Name"
            placeholder="Select a Client ID or Client Name"
            selectedClients={selectedClientsClone}
            onChange={handlePickedClientsClone}
            disableOnLimitReach={false}
          />
          <Box className={classes.horizontalAlignment}>
            <Typography component="span" style={{ paddingRight: '2rem' }}>
              Marked for Deletion
            </Typography>
            <CustomSwitch
              checked={markedDeletion}
              onChange={(event) => toggleMarkedDeletion(event.target.checked)}
              name="markedDeletion"
            />
          </Box>
        </Container>
      )}
      actions={(
        <>
          <Button color="primary" variant="outlined" onClick={handleClearAll}>
            Clear All
          </Button>
          <Button color="primary" variant="contained" onClick={onSave}>
            Apply Filters
          </Button>
        </>
      )}
    />
  );
};

UsersFilterModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadingText: PropTypes.string.isRequired,
  selectedPrograms: PropTypes.array.isRequired,
  selectedClients: PropTypes.array.isRequired,
  handleApplyFilters: PropTypes.func.isRequired,
  flaggedUser: PropTypes.bool.isRequired,
};

export default UsersFilterModal;
