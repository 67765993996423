import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { BLACK, MEDIUM_GREY } from '../../../stylesheets/colors';
import ConfirmationModal from '../../common/ConfirmationModal';
import JournalingDeleteIcon from '../../../assets/img/deleteIconJournaling.svg';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > img': {
      marginBottom: '2.5rem',
    },

    '& > p': {
      textAlign: 'center',
    },

    '& > p:nth-child(4)': {
      marginBottom: '1rem',
    },
  },
  subHeading: {
    color: BLACK,
    fontWeight: 600,
    marginBottom: '0.75rem',
  },
  infoContentText: {
    color: MEDIUM_GREY,
  },
  modalStyle: {
    minWidth: '70rem',
  },
  modalHeight: {
    minHeight: '35rem',
    height: 'auto',
  },
}));

const DeleteScormModal = ({
  onClose,
  onConfirm,
  isLoading,
}) => {
  const classes = useStyles();

  return (
    <ConfirmationModal
      isLoading={isLoading}
      open
      onClose={onClose}
      modalStyle={classes.modalStyle}
      minHeightClass={classes.modalHeight}
      content={(
        <Container className={classes.wrapper}>
          <img src={JournalingDeleteIcon} alt="delete-icon" />
          <Typography variant="h1" component="p" className={classes.subHeading}>Delete Training Material</Typography>
          <Typography variant="subtitle2" component="p" className={`${classes.infoContentText}`}>
            You are about to delete the selected training material.
          </Typography>
          <Typography variant="subtitle2" component="p" className={`${classes.infoContentText}`}>Please note, this training material will be deleted, and will no longer be available.</Typography>
        </Container>
      )}
      actions={(
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button data-testid="delete_journal_action" color="primary" variant="contained" onClick={onConfirm}>
            Delete
          </Button>
        </>
      )}
    />
  );
};

DeleteScormModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default DeleteScormModal;
