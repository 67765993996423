import React, { useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import { Divider } from '@mui/material';
import {
  WEBINAR_MANAGEMENT_TEMPLATE, ROUTE_REPORT, WEBINAR_MANAGEMENT, WORK_SHOP_USER_RECOMMENDED_LIMIT,
} from '../../constants';
import WorkUserUpload from '../../components/WorkshopManagement/WorkshopUsersUpload';
import { parseValidationResult } from '../../helpers/xlsxValidation';

import WorkshopUploadError from '../../components/WorkshopManagement/WorkshopUploadError';
import WorkshopUserLimitWarning from '../../components/WorkshopManagement/WorkshopUserLimitWarning';
import FormattedTypography from '../../components/common/FormattedTypography';
import CustomUploadModal from '../../components/common/CustomUploadModal';
import CustomModal from '../../components/common/CustomModal';
import ValidationError from '../../assets/img/validation-error.webp';
import UserWarning from '../../assets/img/group-3.svg';
import { zeroAppender } from '../../helpers/formattingHelpers';
import ErrorModal from '../../components/ErrorModal/ErrorModal';
import {
  validateXLSXApiCall, uploadXlsx,
} from '../common/apis';
import LoadingCircle from '../../components/common/LoadingCircle/LoadingCircle';
import { internalServerErrorModalLogic } from '../common/utils';
import BreadCrumbHOC from '../../components/BreadCrumbHOC';

const useStyles = makeStyles({
  formWrapper: {
    padding: '1.25rem 0rem',
  },
  uploadHelperText: {
    fontWeight: 'bold',
    '&:not(:last-child)': {
      paddingBottom: '0.5rem',
    },
  },
  dialogContentWrapper: {
    marginBottom: '2rem',
  },
  boldText: {
    fontWeight: 'bold',
  },
  modalStyle: {
    paddingTop: '2rem',
  },
});

const WorkshopManagementUploadPage = ({
  match,
  isDisabled,
  markAsaUser,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const { state = '' } = useLocation();
  const programId = get(match, 'params.programId');
  const programSubType = get(match, 'params.programSubType');
  const course_id = get(match, 'params.transactionId');
  const { courseName } = state;
  const initialValidationResult = { status: 'none', errors: [] };
  const [modalState, setModalState] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalSchema, setModalScheme] = useState({
    VALIDATION: {
      component: WorkshopUploadError,
      wrapperProps: {
        logo: <img src={ValidationError} alt="validation error" width={120} height={120} />,
        heading: null,
        primaryBtnText: 'Re-upload File',
        contentDivider: true,
        contentWrapper: classes.dialogContentWrapper,
        primaryBtnProps: {
          onClick: setModalState.bind(null, null),
        },
      },
      props: initialValidationResult,
    },
    UPLOAD_EXCEEDS_LIMIT: {
      component: WorkshopUserLimitWarning,
      wrapperProps: {
        logo: <img src={UserWarning} alt="warning" width={120} height={120} />,
        heading: null,
        primaryBtnText: 'Proceed',
        primaryBtnProps: {
          onClick: () => { },
        },
        secondaryBtnText: 'Cancel',
        secondaryBtnProps: {
          onClick: setModalState.bind(null, null),
        },
      },
      props: {
        totalUsers: 0,
        warnings: [],
      },
    },
  });

  const breadCrumbList = [
    {
      label: 'Workshop Details', redirectionUrl: `/${ROUTE_REPORT}/${programId}/${programSubType}/${course_id}`, isActive: false, state: { courseName },
    },
    { label: 'Mark a set of users as complete', redirectionUrl: '', isActive: true },
  ];

  const onDownloadSample = async () => {
    window.open(WEBINAR_MANAGEMENT_TEMPLATE, '_blank');
  };

  const uploadFile = useCallback(
    async (formData) => {
      let uploadResult;
      setModalState(null);
      formData.append('program_id', programId);
      formData.append('course_id', course_id);
      try {
        uploadResult = await uploadXlsx(WEBINAR_MANAGEMENT, formData);
      } catch (e) {
        internalServerErrorModalLogic(history, e, setIsErrorModalOpen, () => { });
        return;
      }
      const tid = uploadResult.data.transaction_id;
      setIsLoading(false);
      markAsaUser(tid);
    },
    [course_id, history, markAsaUser, programId],
  );

  const resetFileUpload = useCallback(
    (e) => {
      setModalScheme((schema) => ({
        ...schema,
        VALIDATION: {
          ...schema.VALIDATION,
          props: initialValidationResult,
        },
      }));
      e.target.value = '';
    },
    [initialValidationResult],
  );
  const onFileInput = async (e) => {
    const selectedFile = e.target.files[0];

    const formData = new FormData();
    resetFileUpload(e);
    formData.append('file', selectedFile, selectedFile.name);
    let result;
    setIsLoading(true);
    try {
      result = await validateXLSXApiCall(WEBINAR_MANAGEMENT, formData);
    } catch (err) {
      setIsLoading(false);
      internalServerErrorModalLogic(history, err, setIsErrorModalOpen, resetFileUpload);
      return; // Early exit
    }
    const errorMessages = parseValidationResult(result);
    const errorMessageCount = errorMessages.errors?.length || 0;
    const [extension, ...nameParts] = selectedFile.name.split('.').reverse();
    batchUpdates(() => {
      setModalScheme((schema) => ({
        ...schema,
        VALIDATION: {
          ...schema.VALIDATION,
          wrapperProps: {
            ...schema.VALIDATION.wrapperProps,
            heading: (
              <FormattedTypography
                prefix="Scanning file&nbsp;"
                body={nameParts.join('.')}
                suffix={`.${extension}`}
                subHeading={`${zeroAppender(
                  errorMessageCount,
                )} errors found. Please make the required changes and re-upload your file.`}
              />
            ),
          },
          props: errorMessages,
        },
        UPLOAD_EXCEEDS_LIMIT: {
          ...schema.UPLOAD_EXCEEDS_LIMIT,
          wrapperProps: {
            ...schema.UPLOAD_EXCEEDS_LIMIT.wrapperProps,
            heading: (
              <FormattedTypography
                prefix={`Alert! Your file has more than ${WORK_SHOP_USER_RECOMMENDED_LIMIT} users`}
                prefixClassName={classes.boldText}
              />
            ),
            primaryBtnProps: {
              ...schema.UPLOAD_EXCEEDS_LIMIT.wrapperProps.primaryBtnProps,
              onClick: uploadFile.bind(null, formData),
            },
            contentDivider: true,
            contentWrapper: classes.dialogContentWrapper,
          },
          props: {
            ...schema.UPLOAD_EXCEEDS_LIMIT.props,
          },
        },
      }));
    });

    if (errorMessages.errors.length) {
      setIsLoading(false);
      setModalState('VALIDATION');
      return;
    }

    if (result.warnings && result.warnings.length) {
      setIsLoading(false);
      setModalState('UPLOAD_EXCEEDS_LIMIT');
      return;
    }
    await uploadFile(formData);
  };

  const ModalComponent = modalSchema[modalState] ? modalSchema[modalState].component : null;

  return (
    <>
      <Box className={classes.breadCrumbSection}>
        <BreadCrumbHOC
          list={breadCrumbList}
        />
      </Box>
      <Typography className={classes.boldText} variant="h1">Mark a set of users as complete</Typography>
      <Divider variant="fullWidth" className={classes.divider} light />
      <Box className={classes.formWrapper}>
        <Typography className={classes.uploadHelperText} variant="body1">{`Upload a list of users within the file to  be marked as complete for ${courseName}`}</Typography>
        <Typography variant="body1">Only participants specified in the spreadsheets who are enrolled will receieve a completion on Docebo for this specified workshop.</Typography>
      </Box>
      <Divider variant="fullWidth" className={classes.divider} sx={{ marginBottom: '1.2rem' }} light />
      <WorkUserUpload
        onFileInput={onFileInput}
        fileInputdisabled={isDisabled}
        onDownloadSample={onDownloadSample}
      />

      {ModalComponent && (
        <CustomUploadModal
          open={modalSchema[modalState] !== undefined}
          onClose={() => setModalState(null)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...modalSchema[modalState].wrapperProps}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <ModalComponent {...modalSchema[modalState].props} />
        </CustomUploadModal>
      )}
      {isLoading && (
        <CustomModal
          open
          breakpoint="sm"
          modalStyle={classes.modalStyle}
        >
          <LoadingCircle labelText="Processing file..." />
        </CustomModal>
      )}
      <ErrorModal open={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)} />

    </>
  );
};
WorkshopManagementUploadPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string,
    }),
  }).isRequired,
  setModel: PropTypes.func.isRequired,
  setInfoMessage: PropTypes.func.isRequired,
  fetchLatestData: PropTypes.bool.isRequired,
  setFetchLatestData: PropTypes.func.isRequired,
  onSingleSessionSyncClick: PropTypes.func.isRequired,
  syncInitiatedSessionId: PropTypes.number.isRequired,
  markAsaUser: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default WorkshopManagementUploadPage;
