import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';

import makeStyles from '@material-ui/core/styles/makeStyles';

import ComponentScreen from '../../components/Overview/ComponentScreen';
import RefreshSection from '../../components/Overview/RefreshSection';

import { getComponents, getLpEnrollments, getCourseEnrollments } from './apis';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  refreshWrapper: {
    marginTop: '2rem',
  },
});

const updateWithText = (data, text) => data?.map((section) => {
  if (section?.type === 'channel' || section?.type === 'catalog') return section;
  const newRows = section?.rows?.map((row) => ({
    ...row,
    enrolled: text,
  }));
  return { ...section, rows: newRows };
});

const getEnrollmentsHelper = async (data, update) => {
  // From API contract
  const LP_INDEX = 0;
  const COURSE_INDEX = 1;

  const lpEnrollPromises = data[LP_INDEX].rows
    .map((row) => getLpEnrollments(row.id, update));
  const courseEnrollPromises = data[COURSE_INDEX].rows
    .map((row) => getCourseEnrollments(row.id, update));
  const lpEnrollResponses = await Promise.all(lpEnrollPromises);
  const courseEnrollResponses = await Promise.all(courseEnrollPromises);
  const dataWithEnrollments = cloneDeep(data);

  dataWithEnrollments[LP_INDEX].rows = dataWithEnrollments[LP_INDEX].rows
    .map((row, idx) => ({
      ...row,
      ...lpEnrollResponses[idx],
    }));

  dataWithEnrollments[COURSE_INDEX].rows = dataWithEnrollments[COURSE_INDEX].rows
    .map((row, idx) => ({
      ...row,
      ...courseEnrollResponses[idx],
    }));

  return dataWithEnrollments;
};

const fetchData = async (programId, setData, setLastRefreshed, update) => {
  // TODO: Make the next requests cancelable

  setData([]);
  const componentResponse = await getComponents(programId, update);
  const loadingData = updateWithText(componentResponse?.data, 'Loading...');
  setData(loadingData);
  setLastRefreshed(componentResponse?.last_refreshed);

  try {
    const dataWithEnrollments = await getEnrollmentsHelper(loadingData, update);
    setData(dataWithEnrollments);
  } catch (e) {
    console.error(e);
    const errorData = updateWithText(loadingData, 'Unable to fetch');
    setData(errorData);
  }
};

const OverviewComponentPage = (props) => {
  const { programMetadata, match } = props;

  const classes = useStyles();
  const programId = get(match, 'params.programId');
  const configId = programMetadata.config_id;

  const [data, setData] = useState([]);
  const [lastRefreshed, setLastRefreshed] = useState(null);

  useEffect(() => {
    if (programId) {
      fetchData(programId, setData, setLastRefreshed, false);
    }
  }, [programId, setData, setLastRefreshed]);

  const onRefresh = async () => {
    await fetchData(programId, setData, setLastRefreshed, true);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.refreshWrapper}>
        <RefreshSection onRefresh={onRefresh} lastRefreshed={lastRefreshed} />
      </div>
      <ComponentScreen
        data={data}
        configId={configId}
      />
    </div>
  );
};

OverviewComponentPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.any,
    search: PropTypes.string,
  }).isRequired,
  programMetadata: PropTypes.shape({
    config_id: PropTypes.string.isRequired,
  }).isRequired,
};

export default OverviewComponentPage;
