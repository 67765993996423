export const parseValidationResult = (result) => {
  if (result.errors) {
    return { status: 'error', errors: result.errors };
  }
  return { status: 'success', errors: [] };
};

export const validateXLSXApiCall = async (body, token) => {
  const headers = new Headers({
    Authorization: `Bearer ${token}`,
  });
  const url = '/dat/validate/';
  const options = {
    method: 'POST',
    headers,
    body,
  };
  const response = await fetch(url, options);
  const result = await response.json();
  return result;
};
