import React, {
  useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@mui/material/Grid';
import { DatePicker } from '@material-ui/pickers';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import * as Yup from 'yup';
import moment from 'moment';
import { useFormik } from 'formik';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {
  format,
} from 'date-fns';
import LabledTextField from '../common/LabledTextField';
import CustomUploadModal from '../common/CustomUploadModal';
import FormattedTypography from '../common/FormattedTypography';
import {
  SWITCH_GREY, ACCORDION_GREY, ERROR_RED,
  MODERATE_LIGHT_GREY, MODERATE_DARK_GREY,
} from '../../stylesheets/colors';
import Loading from '../common/Loading';
import {
  COMPONENT_TITLE_MAX_LIMIT, DATEFORMAT, DEFAULTCLIENT, THREE_NUMBER_STEPS,
  FIELD_CHARACTER_LIMIT_18,
} from '../../constants';
import CustomSelect from '../common/CustomSelect';
import ErrorMessage from '../common/ErrorMessage';
import WizardStepper from '../common/WizardStepper';

const useStyles = makeStyles(() => ({
  fieldPadding: {
    paddingTop: '1rem',
  },
  red: {
    color: ERROR_RED,
  },
  errorBlock: {
    display: 'flex',
  },
  rootLabelText: {
    '& > div': {
      borderRadius: 'unset',
    },
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  experience_type_label: {
    marginBottom: '0.8rem',
    fontSize: '1.125rem',
    fontWeight: 'normal',
  },
  highliteErrorInput: {
    '& > div': {
      border: `1px solid ${ERROR_RED}`,
    },
  },
  datePicker: {
    margin: '0.8rem 0',
    width: '100%',
    '& .MuiOutlinedInput-input': {
      padding: '1rem 1.4rem',
    },
    '& > div': {
      borderRadius: 'unset',
    },
  },
  input: {
    width: '100%',
    margin: '.8rem 0',
    '& > div': {
      borderRadius: 'unset',
    },
    '& input': {
      paddingRight: '4.5rem',
      '&:disabled': {
        backgroundColor: MODERATE_LIGHT_GREY,
        opacity: 0.5,
      },
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  },
  rightText: {
    marginLeft: 'auto',
    float: 'right',
  },
  addCohort: {
    '&:disabled': {
      color: ACCORDION_GREY,
      boxShadow: 'none',
      backgroundColor: SWITCH_GREY,
    },
  },
  infoHelperText: {
    color: ACCORDION_GREY,
  },
  infoContentText: {
    color: MODERATE_DARK_GREY,
  },
}));

const AddECohortModal = ({
  open,
  onClose,
  isLoading,
  onCreateWave,
  waveTitleUniqueError,
  clientIdList,
  isAcademyGo,
}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (waveTitleUniqueError) {
      setActiveStep(1);
    }
  }, [waveTitleUniqueError]);

  const next = () => {
    if (activeStep === 0) {
      setActiveStep(1);
    } else if (activeStep === 1) {
      setActiveStep(2);
    } else if (activeStep === 2) {
      setActiveStep(3);
    }
  };

  const Step1Schema = Yup.object({
    wave_name: Yup.string().required('Cohort title Is Required').max(255, 'Title should not exceed 255 characters'),
    description: Yup.string(),
    experience_type: Yup.object().required('This field is required'),
  });

  const Step2Schema = Yup.object({
    client_id: Yup.array().required('Client Is Required').test({
      test(value, ctx) {
        if (ctx.parent.experience_type.value === 'Single Client' && value.length > 1) {
          return ctx.createError({ message: 'You cannot select multiple clients with "Single Client" EC type. Please remove clients or change EC type to "Multiclient" or "B2C"' });
        }
        return true;
      },
    }),
    pdt_id: Yup.string().matches(/^[a-zA-Z0-9]+$/, 'Must be alphanumeric string').max(18, 'Must be maximum 18 digits'),
    start_date: Yup.date().required('Launch date Is Required'),
    end_date: Yup.date().nullable(true).test({
      test(value, ctx) {
        if (ctx.parent.start_date && value && moment(value).diff(moment(ctx.parent.start_date), 'days') <= 0) {
          return ctx.createError({ message: 'The Completion Date must be later than the launch date' });
        }
        return true;
      },
    }),
    expiration_date: Yup.date().nullable(true).test({
      test(value, ctx) {
        if (ctx.parent.start_date && value && moment(value).diff(moment(ctx.parent.start_date), 'days') <= 0) {
          return ctx.createError({ message: 'The Expiration Date must be later than the launch date' });
        }
        if (ctx.parent.end_date && value && (moment(value).diff(moment(ctx.parent.end_date), 'days') < 0)) {
          return ctx.createError({ message: 'The Expiration Date cannot be earlier than the Completion date' });
        }
        return true;
      },
    }),
    ec_code: Yup.string().required('This field is required'),
  });

  const Step3Schema = Yup.object({
    near_target: Yup.string().nullable(true),
    cst_email: isAcademyGo ? Yup.string().required('This field is required').email('Email id is not correct') : Yup.string(),
    order_id: Yup.string(),
  });

  const ecExperienceTypeRef = useRef(null);
  const clientIdRef = useRef(null);
  const ecExperiencetypeOptions = [
    { label: 'Single Client', value: 'Single Client' },
    { label: 'Multiclient', value: 'Multiclient' },
    { label: 'B2C', value: 'B2C' },
  ];

  const schemaArray = [Step1Schema, Step2Schema, Step3Schema];
  const formik = useFormik({
    initialValues: {
      ec_code: '',
      wave_name: '',
      description: '',
      start_date: null,
      end_date: null,
      expiration_date: null,
      client_id: [],
      cst_email: '',
      experience_type: null,
      order_id: '',
      pdt_id: '',
      near_target: null,
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    // validate,
    validationSchema: schemaArray[activeStep],
    onSubmit: (values, bag) => {
      if (activeStep === 3) {
        const finalValues = {
          ec_code: values.ec_code,
          wave_name: values.wave_name,
          description: values.description,
          start_date: values.start_date ? format(values.start_date, DATEFORMAT) : null,
          end_date: values.end_date ? format(values.end_date, DATEFORMAT) : null,
          expiration_date: values.expiration_date ? format(values.expiration_date, DATEFORMAT)
            : null,
          client_id: values.client_id.map((item) => item.value),
          experience_type: values.experience_type?.value,
          cst_email: values.cst_email,
          order_id: values.order_id,
          pdt_id: values.pdt_id,
          near_target: values.near_target || null,
        };
        onCreateWave(finalValues);
      }
      next(values);
      bag.setSubmitting(false);
    },
  });

  const {
    handleSubmit, handleChange, values, errors,
    setFieldValue, resetForm, setErrors,
  } = formik;

  const beforeClose = () => {
    resetForm();
    onClose();
  };

  const checkCharacterLimit = (e, charLen) => {
    if (e.target.value.length <= charLen) {
      handleChange(e);
      if (e.target.name === 'near_target') {
        setFieldValue(e.target.name, e.target.value.replace(/[^0-9]/g, ''));
      } else if (e.target.name === 'pdt_id' && e.target.value === '') {
        setErrors({ pdt_id: '' });
      } else {
        setFieldValue(e.target.name, e.target.value);
      }
    }
  };

  const scrollToBottom = () => {
    if (clientIdRef.current) {
      const valueContainer = clientIdRef.current.select.controlRef;
      valueContainer.scrollTop = valueContainer.scrollHeight;
    }
  };

  const onClientIdChange = (e) => {
    setFieldValue('client_id', e || []);
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  };

  const requiredFieldLabel = (label, className) => (
    <Box className={`${classes.label} ${className || ''}`}>
      <Typography>{label}</Typography>
      <span className={classes.red}>*</span>
    </Box>
  );

  return (
    <>
      <CustomUploadModal
        open={open}
        onClose={beforeClose}
        heading={(
          <DialogTitle>
            <FormattedTypography
              variant="subtitle1"
              body={`${activeStep === 3 ? 'Review and Proceed' : 'Add a new enrollment cohort'} `}
              subHeading={`${activeStep === 3 ? 'Please review all the details you have added to create a new enrollment cohort.'
                : 'Create a new enrollment cohort with the following details. Select this cohort when '}`}
              helperNode={`${activeStep === 3 ? '' : 'enrolling users from the user enrollment section.'}`}
            />
          </DialogTitle>
        )}
        headingDivider
        disableGutters
        contentDivider
        // eslint-disable-next-line no-nested-ternary
        primaryBtnText={activeStep < 2 ? 'Next' : (activeStep === 3 ? 'Confirm & Proceed' : 'Add Cohort')}
        primaryBtnProps={
          {
            onClick: () => handleSubmit(),
            disabled: !!((activeStep === 0
              && !(values?.wave_name && values?.experience_type))
              || (activeStep === 1
                && !(values?.ec_code && values?.start_date && values?.client_id?.length)))
              || (activeStep === 2 && isAcademyGo && !values.cst_email),
          }
        }
        secondaryBtnText={activeStep !== 0 ? 'Back' : ''}
        secondaryBtnProps={
          { onClick: () => setActiveStep(activeStep - 1) }
        }
      >
        <Container sx={{ width: '100%' }}>
          {
            activeStep !== 3 && (
              <WizardStepper steps={THREE_NUMBER_STEPS} activeStep={activeStep} />
            )
          }
          {(() => {
            if (isLoading) {
              return (<Loading />);
            }
            if (activeStep === 0) {
              return (
                <Box sx={{ width: '70%', margin: 'auto' }}>
                  <Typography variant="subtitle1">
                    Add details below to create a new enrollment cohort
                  </Typography>
                  <Grid item xs={12}>
                    <LabledTextField
                      label={requiredFieldLabel('Cohort Title')}
                      placeholder="Enter cohort title here"
                      className={classes.fieldPadding}
                      inputProps={{
                        name: 'wave_name',
                        classes: {
                          root: `${classes.rootLabelText} ${(errors.wave_name)
                            ? ` ${classes.highliteErrorInput}` : ''}`,
                        },
                      }}
                      value={values.wave_name || ''}
                      onChange={(e) => checkCharacterLimit(e, COMPONENT_TITLE_MAX_LIMIT)}
                    />
                    <Box className={classes.errorBlock}>
                      {errors.wave_name && (
                        <Typography className={classes.red}>{errors.wave_name}</Typography>
                      )}
                      <Typography className={classes.rightText}>
                        {(values.wave_name || '').length}
                        /
                        {COMPONENT_TITLE_MAX_LIMIT}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <LabledTextField
                      label="Description"
                      className={classes.fieldPadding}
                      placeholder="Enter description text here"
                      inputProps={{
                        name: 'description',
                        classes: { root: classes.rootLabelText },
                      }}
                      value={values.description || ''}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomSelect
                      name="experience_type"
                      selectRef={ecExperienceTypeRef}
                      options={ecExperiencetypeOptions}
                      className={`${classes.fieldPadding} ${classes.experience_type_label}`}
                      defaultValue={values.experience_type}
                      placeholder="Select an option"
                      type="select"
                      label={requiredFieldLabel('EC Type', classes.experience_type_label)}
                      onChange={(e) => setFieldValue('experience_type', e)}
                    />
                  </Grid>
                </Box>
              );
            }
            if (activeStep === 1) {
              return (
                <Box sx={{ width: '70%', margin: 'auto' }}>
                  <Typography variant="subtitle1">
                    Add details below to create an enrollment cohort code
                  </Typography>
                  <Grid item xs={12}>
                    <CustomSelect
                      selectRef={clientIdRef}
                      name="client_id"
                      options={clientIdList}
                      className={`${classes.fieldPadding} ${classes.experience_type_label}`}
                      defaultValue={values.client_id}
                      placeholder="Select an option"
                      type="select"
                      label={requiredFieldLabel('Client', classes.experience_type_label)}
                      labelClass={classes.experience_type_label}
                      onChange={(e) => onClientIdChange(e)}
                      isOptionsNotLoading
                      isMulti
                      noOptionsMessage="Client does not exist. Please contact local admin."
                    />
                    <Typography>
                      {`(${DEFAULTCLIENT.name} - ${DEFAULTCLIENT.id} is part of this cohort by default.
                         You can add users with this client as needed)`}
                    </Typography>
                    <Box className={classes.errorBlock}>
                      {errors.client_id && (
                        <Typography className={classes.red}>{errors.client_id}</Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <LabledTextField
                      label="PDT Digital Milestone ID"
                      placeholder="Enter PDT ID"
                      className={classes.fieldPadding}
                      inputProps={{
                        name: 'pdt_id',
                        classes: { root: classes.rootLabelText },
                      }}
                      value={values.pdt_id || ''}
                      onChange={(e) => checkCharacterLimit(e, FIELD_CHARACTER_LIMIT_18)}
                    />
                    <Box className={classes.errorBlock}>
                      {errors.pdt_id && (
                      <Typography className={classes.red}>{errors.pdt_id}</Typography>
                      )}
                      <Typography className={classes.rightText}>
                        {(values.pdt_id || '').length}
                        /
                        {FIELD_CHARACTER_LIMIT_18}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item xs={6}>
                      {requiredFieldLabel('Launch Date', classes.fieldPadding)}
                      <DatePicker
                        className={`${classes.datePicker} ${errors.start_date ? ` ${classes.highliteErrorInput}` : ''}`}
                        value={values.start_date}
                        format={DATEFORMAT}
                        inputVariant="outlined"
                        autoOk
                        variant="inline"
                        onChange={(date) => setFieldValue('start_date', date)}
                        InputProps={{
                          className: classes.datePickerInput,
                          endAdornment: <CalendarTodayIcon size="2rem" />,
                          placeholder: 'Select a date',
                        }}
                      />
                      <Box className={classes.errorBlock}>
                        {errors.start_date && (
                          <Typography className={classes.red}>{errors.start_date}</Typography>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className={`${classes.label} ${classes.fieldPadding}`}>
                        <Typography>Completion Date</Typography>
                      </Box>
                      <DatePicker
                        className={`${classes.datePicker} ${errors.end_date ? ` ${classes.highliteErrorInput}` : ''}`}
                        value={values.end_date}
                        format={DATEFORMAT}
                        inputVariant="outlined"
                        autoOk
                        variant="inline"
                        onChange={(date) => setFieldValue('end_date', date)}
                        InputProps={{
                          className: classes.datePickerInput,
                          endAdornment: <CalendarTodayIcon size="2rem" />,
                          placeholder: 'Select a date',
                        }}
                      />
                      <Box className={classes.errorBlock}>
                        {errors.end_date && (
                          <Typography className={classes.red}>{errors.end_date}</Typography>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box className={`${classes.label} ${classes.fieldPadding}`}>
                      <Typography>Expiration Date</Typography>
                    </Box>
                    <DatePicker
                      className={classes.datePicker}
                      value={values.expiration_date}
                      format={DATEFORMAT}
                      inputVariant="outlined"
                      autoOk
                      variant="inline"
                      onChange={(date) => setFieldValue('expiration_date', date)}
                      InputProps={{
                        className: classes.datePickerInput,
                        endAdornment: <CalendarTodayIcon size="2rem" />,
                        placeholder: 'Select an expiration date',
                      }}
                    />
                    {errors.expiration_date
                      && (<ErrorMessage errorText={errors.expiration_date} />)}
                  </Grid>
                  <Grid item xs={12}>
                    <LabledTextField
                      label={requiredFieldLabel('EC Code')}
                      placeholder="client_offering_yymmdd_1"
                      className={classes.fieldPadding}
                      inputProps={{
                        name: 'ec_code',
                        classes: {
                          root: `${classes.rootLabelText} ${(errors.ec_code) || waveTitleUniqueError
                            ? ` ${classes.highliteErrorInput}` : ''}`,
                        },
                      }}
                      value={values.ec_code || ''}
                      onChange={(e) => checkCharacterLimit(e, COMPONENT_TITLE_MAX_LIMIT)}
                    />
                    <Box className={classes.errorBlock}>
                      {errors.ec_code && (
                        <Typography className={classes.red}>{errors.ec_code}</Typography>
                      )}
                      {waveTitleUniqueError ? (
                        <Typography className={classes.red}>EC code must be unique</Typography>
                      ) : (
                        <Typography>
                          (MLTI/ClientAbbr_OfferingNameAbbr_Launch YYMMDD_OptCounter)
                        </Typography>
                      )}
                      <Typography className={classes.rightText}>
                        {(values.ec_code || '').length}
                        /
                        {' '}
                        {COMPONENT_TITLE_MAX_LIMIT}
                      </Typography>
                    </Box>
                  </Grid>
                </Box>
              );
            } if (activeStep === 2) {
              return (
                <Box sx={{ width: '70%', margin: 'auto' }}>
                  <Typography variant="subtitle1">
                    Add details below to create a new enrollment cohort
                  </Typography>
                  <Grid item xs={12}>
                    <LabledTextField
                      label="Near Target (Ignore this field)"
                      type="string"
                      placeholder="Enter numeric near target value"
                      className={classes.fieldPadding}
                      inputProps={{
                        name: 'near_target',
                        classes: { root: classes.rootLabelText },
                        pattern: '[^0-9]{1,3}',
                      }}
                      value={values.near_target || ''}
                      onChange={(e) => checkCharacterLimit(e, 3)}
                    />
                    <Box className={classes.errorBlock}>
                      <Typography className={classes.rightText}>
                        {(values.near_target || '').toString().length}
                        /3
                      </Typography>
                    </Box>
                  </Grid>
                  {
                    isAcademyGo && (
                      <Grid item xs={12}>
                        <LabledTextField
                          label={requiredFieldLabel('CST Member')}
                          placeholder="Enter CST Member Email ID"
                          className={classes.fieldPadding}
                          inputProps={{
                            min: 0,
                            name: 'cst_email',
                            classes: {
                              root: `${classes.rootLabelText}`,
                            },
                          }}
                          value={values.cst_email || ''}
                          onChange={handleChange}
                        />
                        {errors.cst_email
                          && (<ErrorMessage errorText={errors.cst_email} />)}
                        <Typography>
                          (Specific to Academy-GO programs)
                        </Typography>
                      </Grid>
                    )
                  }
                  {
                    isAcademyGo && (
                      <Grid item xs={12}>
                        <LabledTextField
                          label={'Order ID'}
                          className={classes.fieldPadding}
                          placeholder="Enter Order ID"
                          inputProps={{
                            name: 'order_id',
                            classes: { root: classes.rootLabelText },
                          }}
                          value={values.order_id || ''}
                          onChange={(e) => checkCharacterLimit(e, COMPONENT_TITLE_MAX_LIMIT)}
                        />
                        <Box className={classes.errorBlock}>
                          <Typography>
                            (Specific to Academy-GO programs)
                          </Typography>
                          <Typography className={classes.rightText}>
                            {(values.order_id || '').length}
                            /
                            {COMPONENT_TITLE_MAX_LIMIT}
                          </Typography>
                        </Box>
                      </Grid>
                    )
                  }

                </Box>
              );
            }
            return (
              <Grid container rowSpacing={2} sx={{ width: '60%', margin: '0 auto' }}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" className={classes.infoHelperText}>
                    Cohort Title:
                  </Typography>
                  <Typography variant="subtitle2" component="span" className={`${classes.infoContentText}`}>
                    {values.wave_name}
                  </Typography>
                </Grid>
                <Grid item xs={12} rowSpacing={2}>
                  <Typography variant="subtitle2" className={classes.infoHelperText}>
                    Description:
                  </Typography>
                  <Typography variant="subtitle2" component="span" className={`${classes.infoContentText}`}>
                    {values.description || '-'}
                  </Typography>
                </Grid>
                <Grid item xs={12} rowSpacing={2}>
                  <Typography variant="subtitle2" className={classes.infoHelperText}>
                    EC Type:
                  </Typography>
                  <Typography variant="subtitle2" component="span" className={`${classes.infoContentText}`}>
                    {values.experience_type.label}
                  </Typography>
                </Grid>
                <Grid item xs={12} rowSpacing={2}>
                  <Typography variant="subtitle2" className={classes.infoHelperText}>
                    Client:
                  </Typography>
                  <Typography variant="subtitle2" component="div" className={`${classes.infoContentText}`}>
                    {values.client_id.length ? values.client_id.map((item) => <Box key={item.value}>{item.label}</Box>) : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={12} rowSpacing={2}>
                  <Typography variant="subtitle2" className={classes.infoHelperText}>
                    PDT Digital Milestone ID:
                  </Typography>
                  <Typography variant="subtitle2" component="span" className={`${classes.infoContentText}`}>
                    {values.pdt_id || '-'}
                  </Typography>
                </Grid>
                <Grid item container spacing={2} rowSpacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className={classes.infoHelperText}>
                      Launch Date:
                    </Typography>
                    <Typography variant="subtitle2" component="span" className={`${classes.infoContentText}`}>
                      {values.start_date ? (format(values.start_date, DATEFORMAT)) : '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" className={classes.infoHelperText}>
                      Completion Date:
                    </Typography>
                    <Typography variant="subtitle2" component="span" className={`${classes.infoContentText}`}>
                      {values.end_date ? (format(values.end_date, DATEFORMAT)) : '-'}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} rowSpacing={2}>
                  <Typography variant="subtitle2" className={classes.infoHelperText}>
                    Expiration Date:
                  </Typography>
                  <Typography variant="subtitle2" component="span" className={`${classes.infoContentText}`}>
                    {values.expiration_date ? (format(values.expiration_date, DATEFORMAT)) : '-'}
                  </Typography>
                </Grid>
                <Grid item xs={12} rowSpacing={2}>
                  <Typography variant="subtitle2" className={classes.infoHelperText}>
                    EC Code:
                  </Typography>
                  <Typography variant="subtitle2" component="span" className={`${classes.infoContentText}`}>
                    {values.ec_code || '-'}
                  </Typography>
                </Grid>
                <Grid item xs={12} rowSpacing={2}>
                  <Typography variant="subtitle2" className={classes.infoHelperText}>
                    Near Target:
                  </Typography>
                  <Typography variant="subtitle2" component="span" className={`${classes.infoContentText}`}>
                    {values.near_target || '-'}
                  </Typography>
                </Grid>
                {
                  isAcademyGo && (
                    <Grid item xs={12} rowSpacing={2}>
                      <Typography variant="subtitle2" className={classes.infoHelperText}>
                        CST Member (Specific to Academy-GO programs):
                      </Typography>
                      <Typography>
                        {values.cst_email || '-'}
                      </Typography>
                    </Grid>
                  )
                }
                {
                  isAcademyGo && (
                    <Grid item xs={12} rowSpacing={2}>
                      <Typography variant="subtitle2" className={classes.infoHelperText}>
                        Order ID (Specific to Academy-GO programs):
                      </Typography>
                      <Typography>
                        {values.order_id || '-'}
                      </Typography>
                    </Grid>
                  )
                }
              </Grid>
            );
          })()}
        </Container>
      </CustomUploadModal>
    </>
  );
};

AddECohortModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCreateWave: PropTypes.func.isRequired,
  waveTitleUniqueError: PropTypes.bool.isRequired,
  clientIdList: PropTypes.array.isRequired,
  isAcademyGo: PropTypes.bool.isRequired,
};

export default AddECohortModal;
