import React, {
  useEffect, useState, memo, createRef,
} from 'react';
import PropTypes from 'prop-types';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Chip from '@material-ui/core/Chip';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import SingleTestUserMenu from './SingleTestUserMenu';
import TestUserConfirmModal from './TestUserConfirmModal';
import DeleteUserConfirmModal from './deleteUserConfirmModal';
import ConfirmTestUserResetPassword from './ConfirmTestUserResetPassword';
import EditLanguagePreferences from './EditLanguagePreferences';
import EnrollAdditionalProgram from './EnrollAdditionalPrograms';
import {
  MODERATE_LIGHT_GREY,
  PALE_GREY,
  LIGHT_MEDIUM_GREY,
  MODERATE_DARK_GREY,
  ERROR_RED,
  SUCCESS_GREEN,
  WHITE,
  PIE_CHART_BLACK,
} from '../../stylesheets/colors';
import { testUsersTableColumnsMapping } from '../../constants';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    [theme.breakpoints.down('lg')]: {
      padding: '1rem 0.5rem',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '1rem',
    },
  },
  body: {
    fontSize: '1.25rem',
    [theme.breakpoints.down('lg')]: {
      padding: '1rem 0.5rem',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '1rem',
    },
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: PALE_GREY,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
  },
  tableHead: {
    color: MODERATE_DARK_GREY,
    backgroundColor: MODERATE_LIGHT_GREY,
  },
  tableRow: {
    minHeight: '5rem',
    height: 'auto',
  },
  tableCell: {
    color: MODERATE_DARK_GREY,
  },
  checkbox: {
    width: '1.125rem',
    height: '1.125rem',
    color: LIGHT_MEDIUM_GREY,
    opacity: 0.6,
    margin: '0 2.5rem 0 0.75rem',
  },
  checkboxChecked: {
    opacity: 1,
  },
  programsWrapper: {
    padding: '0.2rem',
    flexWrap: 'wrap',
    maxWidth: '45rem',
    alignItems: 'center',
    height: 'auto',
  },
  wordBreak: {
    wordBreak: 'break-all',
  },
});

const getStatus = (flag) => (flag ? (
  <Chip
    label="Active"
    style={{
      color: SUCCESS_GREEN,
      borderColor: SUCCESS_GREEN,
    }}
    size="small"
    variant="outlined"
  />
)
  : (
    <Chip
      label="Inactive"
      style={{
        color: ERROR_RED,
        borderColor: ERROR_RED,
      }}
      size="small"
      variant="outlined"
    />
  )
);

const CreateTestUsersTableRow = ({
  row, index, check, inputRef, checkCbk, onStatusChange, onTestUserDelete,
  onTestUserUpdate, activeLanguages, enrollAdditionalProgram, primaryExperience,
  configId,
}) => {
  const classes = useStyles({ isEven: index % 2, isInactive: !row.status });
  const [userStatusModal, setUserStatusModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [resetAccessModal, setResetAccessModal] = useState(false);
  const [enrollProgramModal, setEnrollProgramModal] = useState(false);

  const [languagePreferencesModal, setLanguagePreferencesModal] = useState(false);

  const handleChange = (ev) => {
    checkCbk(index, ev.target.checked);
  };

  const getEnroll = (programs) => (
    (programs.length > 0)
    && (
      <Stack direction="row" className={classes.programsWrapper}>
        {
          programs.map((element) => (
            <Chip
              key={element}
              label={element}
              style={{
                color: WHITE,
                backgroundColor: PIE_CHART_BLACK,
                marginRight: '0.2rem',
                marginBottom: '0.2rem',
                borderRadius: '0.5px',
              }}
              size="small"
            />
          ))
        }
      </Stack>
    )
  );

  const handleMenuClick = (event) => {
    switch (event) {
      case 'activate':
        setUserStatusModal(true);
        break;
      case 'deactivate':
        setUserStatusModal(true);
        break;
      case 'deleteUser':
        setDeleteUserModal(true);
        break;
      case 'resetPassword':
        setResetAccessModal(true);
        break;
      case 'editLanguagePreferences':
        setLanguagePreferencesModal(true);
        break;
      case 'enrollProgram':
        setEnrollProgramModal(true);
        break;
      default:
      // code block
    }
  };

  const closeUserStatusModal = () => {
    setUserStatusModal(false);
  };

  const closeDeleteUserModal = () => {
    setDeleteUserModal(false);
  };

  const closeResetAccessModal = () => {
    setResetAccessModal(false);
  };

  const closeLanguagePreferencesModal = () => {
    setLanguagePreferencesModal(false);
  };

  const closeEnrollProgramModal = () => {
    setEnrollProgramModal(false);
  };

  const onConfirm = () => {
    const action = !row.status;
    onStatusChange([row.user], !!action);
    closeUserStatusModal();
  };

  const onDelete = () => {
    onTestUserDelete([row.user]);
    closeDeleteUserModal();
  };

  const onResetAccess = () => {
    onTestUserUpdate([row.user], true, '');
    closeResetAccessModal();
  };

  const onEnrollProgram = (programs) => {
    enrollAdditionalProgram([row.user], primaryExperience, programs);
    closeEnrollProgramModal();
  };

  const onlanguageReset = (pickedLanguage) => {
    onTestUserUpdate([row.user], false, pickedLanguage);
    closeLanguagePreferencesModal();
  };

  const formatRow = (data) => ({ username: data.user, status: data.status.toString(), enrolledPrograms: data['additionally enrolled in'] });

  const getCellItem = (col, idx) => {
    if (!idx) {
      return (
        <Grid container>
          <Grid item sm={2} spacing={1}>
            <Checkbox
              className={classes.checkbox}
              checked={check}
              onChange={handleChange}
              name={`check-${idx}`}
              inputRef={inputRef}
              color="primary"
              classes={{
                checked: classes.checkboxChecked,
              }}
              inputProps={{
                'data-id': row.user,
                'data-status': row.status,
                'data-Programs': row['additionally enrolled in'],
              }}
            />
          </Grid>
          <Grid item sm={10}>
            <Typography variant="inherit" component="span" className={classes.wordBreak}>
              {row.user}
            </Typography>
          </Grid>
        </Grid>
      );
    } if (col === 'status') {
      return getStatus(row[col]);
    } if (col === 'additionally enrolled in' && row[col] && row[col].length > 0) {
      return getEnroll((row[col].indexOf(',') > -1) ? row[col].split(',') : [row[col]]);
    } if (col === 'moreIcon') {
      return (
        <SingleTestUserMenu menuClick={handleMenuClick} action={!row.status} />
      );
    }
    return row[col];
  };

  const rowDom = Object.keys(row).map((col, idx) => (
    <StyledTableCell className={classes.tableCell} key={`${row.user}-${col}`}>
      {getCellItem(col, idx)}
    </StyledTableCell>
  ));

  return (
    <>
      <StyledTableRow className={classes.tableBodyRow} key={row.user}>
        {rowDom}
      </StyledTableRow>
      <TestUserConfirmModal
        action={!row.status}
        users={[row.user]}
        onConfirm={onConfirm}
        open={userStatusModal}
        onClose={closeUserStatusModal}
      />
      <DeleteUserConfirmModal
        users={[row.user]}
        onDelete={onDelete}
        open={deleteUserModal}
        onClose={closeDeleteUserModal}
      />
      <ConfirmTestUserResetPassword
        users={[row.user]}
        onResetAccess={onResetAccess}
        open={resetAccessModal}
        onClose={closeResetAccessModal}
      />
      {languagePreferencesModal ? (
        <EditLanguagePreferences
          users={[row.user]}
          userObj={row}
          open={languagePreferencesModal}
          onClose={closeLanguagePreferencesModal}
          onlanguageReset={onlanguageReset}
          activeLanguages={activeLanguages}
        />
      ) : null}
      <EnrollAdditionalProgram
        users={[row.user]}
        selectedUsers={[formatRow(row)]}
        open={enrollProgramModal}
        configId={configId}
        onEnrollProgram={onEnrollProgram}
        onClose={closeEnrollProgramModal}
      />
    </>
  );
};

CreateTestUsersTableRow.defaultProps = {
  activeLanguages: [],
};

CreateTestUsersTableRow.propTypes = {
  row: PropTypes.shape({
    user: PropTypes.string.isRequired,
    status: PropTypes.bool.isRequired,
    'additionally enrolled in': PropTypes.bool.isRequired,
    password: PropTypes.string.isRequired,
    level: PropTypes.string.isRequired,
    branch: PropTypes.string.isRequired,
    actions: PropTypes.bool,
  }).isRequired,
  primaryExperience: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  check: PropTypes.bool.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  onTestUserUpdate: PropTypes.func.isRequired,
  onTestUserDelete: PropTypes.func.isRequired,
  enrollAdditionalProgram: PropTypes.func.isRequired,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.elementType }),
    PropTypes.shape({ current: null }),
  ]).isRequired,
  checkCbk: PropTypes.func.isRequired,
  activeLanguages: PropTypes.array,
  configId: PropTypes.string.isRequired,
};

const CreateTestUsersTable = ({
  data, onSelectUser, onStatusChange, onTestUserUpdate, onTestUserDelete,
  activeLanguages, enrollAdditionalProgram, primaryExperience, configId,
}) => {
  const classes = useStyles();
  const [check, setCheck] = useState(false);
  const [intermediate, setIntermediate] = useState(false);
  const [childCheckboxes, setChildCheckboxes] = useState(data.map(() => createRef()));

  useEffect(() => {
    setCheck(false);
    setIntermediate(false);
    setChildCheckboxes(data.map(() => createRef()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChange = (ev) => {
    const users = [];
    setIntermediate(false);
    setCheck(ev.target.checked);
    setChildCheckboxes(
      childCheckboxes.map((v) => {
        if (ev.target.checked) {
          users.push({
            username: v.current.dataset.id,
            status: v.current.dataset.status,
            enrolledPrograms: v.current.dataset.programs,
          });
        }
        v.current.checked = ev.target.checked; // eslint-disable-line no-param-reassign
        return v;
      }),
    );
    onSelectUser(users);
  };

  const getHeadCell = (column, idx) => {
    const columnName = testUsersTableColumnsMapping[column] || '';

    if (!idx) {
      return (
        <Grid container>
          <Grid item sm={2} spacing={1}>
            <Checkbox
              className={classes.checkbox}
              checked={check}
              onChange={handleChange}
              name="checkAll"
              color="primary"
              classes={{
                checked: classes.checkboxChecked,
              }}
              indeterminate={intermediate}
            />
          </Grid>
          <Grid item sm={10}>
            <Typography variant="inherit" component="span">{columnName}</Typography>
          </Grid>
        </Grid>

      );
    }
    return columnName;
  };

  const childCheckboxCallback = (index, selectedValue) => {
    const users = [];
    setChildCheckboxes(
      childCheckboxes.map((el, idx) => {
        // eslint-disable-next-line no-param-reassign
        if (index === idx) el.current.checked = selectedValue;
        if (el.current.checked) {
          users.push({
            username: el.current.dataset.id,
            status: el.current.dataset.status,
            enrolledPrograms: el.current.dataset.programs,
          });
        }
        return el;
      }),
    );

    if (!selectedValue && check) {
      setCheck(false);
      setIntermediate(true);
    }
    onSelectUser(users);
  };

  const heads = Object.keys(data[0]).map((column, idx) => (
    <StyledTableCell className={classes.tableHead} key={column}>
      {getHeadCell(column, idx)}
    </StyledTableCell>
  ));

  const body = data.map(
    (row, index) => childCheckboxes[index] && (
      <CreateTestUsersTableRow
        key={row.user}
        row={{ ...row, moreIcon: true }}
        index={index}
        inputRef={childCheckboxes[index]}
        check={childCheckboxes[index].current ? childCheckboxes[index].current.checked : false}
        checkCbk={childCheckboxCallback}
        onStatusChange={onStatusChange}
        onTestUserUpdate={onTestUserUpdate}
        onTestUserDelete={onTestUserDelete}
        enrollAdditionalProgram={enrollAdditionalProgram}
        activeLanguages={activeLanguages}
        primaryExperience={primaryExperience}
        configId={configId}
      />
    ),
  );

  return (
    <TableContainer className={classes.wrapper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <StyledTableRow className={classes.tableRow}>
            {heads}
            <StyledTableCell className={classes.tableHead}>Actions</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {body}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CreateTestUsersTable.defaultProps = {
  activeLanguages: [],
};

CreateTestUsersTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      user: PropTypes.string.isRequired,
      status: PropTypes.bool.isRequired,
      password: PropTypes.string.isRequired,
      level: PropTypes.string.isRequired,
      branch: PropTypes.string.isRequired,
    }),
  ).isRequired,
  primaryExperience: PropTypes.string.isRequired,
  onSelectUser: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  onTestUserUpdate: PropTypes.func.isRequired,
  onTestUserDelete: PropTypes.func.isRequired,
  enrollAdditionalProgram: PropTypes.func.isRequired,
  activeLanguages: PropTypes.array,
  configId: PropTypes.string.isRequired,
};

export default memo(CreateTestUsersTable);
