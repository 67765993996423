/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Paper, Box, Grid } from '@mui/material';
import { Button } from '@material-ui/core';
import { get } from 'lodash';
import { useFormik } from 'formik';
import _omit from 'lodash/omit';
import _map from 'lodash/map';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import Header from '../../components/common/Header';
import OverviewSection from '../../components/ProductionChecklist/OverviewSection';
import ProgramDetails from '../../components/ProductionChecklist/ProgramDetails';
import DoceboDetails from '../../components/ProductionChecklist/DoceboDetails';
import ScormDetails from '../../components/ProductionChecklist/ScormDetails';
import ResourceChannels from '../../components/ProductionChecklist/ResourceChannels';
import JournalDetailsSection from '../../components/ProductionChecklist/JournalDetailsSection';
import PointOfContacts from '../../components/ProductionChecklist/PointOfContacts';
import TestUsersDetails from '../../components/ProductionChecklist/TestUsersDetails';
import {
  getOverviewDetails, getProgramDetails, getDoceboDetails, getElucidatDetails,
  getNonElucidatDetails, postProdChecklist, getChannels, getTestUsersByTid, getJournalDetails,
} from './apis';
import useNotifications from '../../hooks/useNotifications';
import { getErrorMessage } from '../../helpers/apiHelper';
import {
  ROLE_PROD, ROLE_OPS,
} from '../../constants';
import useProductionChecklistData from '../../hooks/useProductionChecklistData';
import CustomModal from '../../components/common/CustomModal';
import ConfigChangeModal from '../../components/Modals/ConfigChangeModal';
import { MCKINSEY_BLUE, ACCORDION_GREY } from '../../stylesheets/colors';
import { reportPageDateTimeFormatter } from '../../helpers/formattingHelpers';
import MyContext from '../../context';
import InfoTooltip from '../../components/common/InfoTooltip';
import AlertBarWithAction from '../../components/common/AlertBarWithAction';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  divider: {
    width: '98%',
    marginBottom: ({ hasContentHeader }) => (hasContentHeader ? '0rem' : '2rem'),
  },
  customLabel: {
    fontSize: '1.5rem',
    fontWeight: '900',
  },
  pageHeader: {
    padding: '2rem 4rem 2rem 3rem',
    alignItems: 'center',
  },
  ellipsisTableString: {
    width: '7rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  refreshButton: {
    color: MCKINSEY_BLUE,
    fontSize: '1rem',
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    fontWeight: 600,
  },
  lastRefreshSection: {
    paddingBottom: '1rem',
  },
  rightHeaderWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  subHeadingHeader: {
    color: ACCORDION_GREY,
    fontSize: '1.25rem',
  },
});

const ProductionChecklist = ({
  match,
  programMetadata,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { role } = useContext(MyContext);

  const isDDAdmin = role === ROLE_OPS;
  const isCPAdmin = role === ROLE_PROD;
  const programId = get(match, 'params.programId');
  const { config_id: configId } = programMetadata;

  const [isEdit, setIsEdit] = useState(false);
  const [linkToLp, setLinkToLp] = useState([]);
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [currentPath, setCurrentPath] = useState('');
  const [fromDialog, setFromDialog] = useState(false);
  const [isAnyUnassignedScorm, setIsAnyUnassignedScorm] = useState(false);

  const { notifySuccess, notifyError } = useNotifications();

  const doceboSectionRef = useRef();

  useEffect(() => {
    if (isEdit) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setVisibleDialog(true);
        return false;
      });
    } else {
      history.block(() => { });
    }

    return () => {
      history.block(() => { });
    };
  }, [history, isEdit]);

  const {
    overviewSectionData,
    programDetailsSectionData,
    doceboDetailsSectionData,
    elucidatDetailsSectionData,
    nonElucidatDetailsSectionData,
    channelDetailsSectionData,
    testUsersSectionData,
    journalSectionData,
    isOverviewLoading,
    isProgramDetailsLoading,
    isDoceboDetailsLoading,
    isElucidatDetailsLoading,
    isNonElucidatDetailsLoading,
    ischannelDetailsLoading,
    isTestUsersLoading,
    isJournalsLoading,
    fetchAsyncData,
    refreshData,
  } = useProductionChecklistData(getOverviewDetails, getProgramDetails,
    getDoceboDetails, getElucidatDetails,
    getNonElucidatDetails, getChannels, getTestUsersByTid, getJournalDetails, programId);
  const {
    values, handleSubmit, errors, touched, handleChange, setFieldValue, resetForm, setValues,
  } = useFormik({
    initialValues: {
      ...overviewSectionData,
      ...programDetailsSectionData,
      ...doceboDetailsSectionData,
      ...elucidatDetailsSectionData,
      ...nonElucidatDetailsSectionData,
      ...channelDetailsSectionData,
      ...testUsersSectionData,
      ...journalSectionData,
    },
    enableReinitialize: true,
    onSubmit: (payload) => {
      try {
        const result = {
          ...payload,
          elucidat_details: payload?.elucidatDetails,
          non_elucidat_details: payload?.nonElucidatDetails,
          link_to_lps: _map(linkToLp, 'id'),
          overview_client_lms: payload?.overview_client_set_up?.value?.includes('LMS Integration') ? payload?.overview_client_lms : '',
          overview_source_build_status: payload?.overview_buid_type === 'Source' ? payload?.overview_source_build_status : '',
        };
        postProdChecklist(_omit(result, ['elucidatDetails', 'doceboDetails']), programId).then((resp) => {
          if (resp.data.success) {
            notifySuccess('Saved Successfully');
            if (!fromDialog) {
              fetchAsyncData();
            } else {
              setVisibleDialog(false);
              history.block(() => { });
              history.push(currentPath);
            }
          } else {
            notifyError(resp?.data?.message);
            setValues({
              ...overviewSectionData,
              ...programDetailsSectionData,
              ...doceboDetailsSectionData,
              ...elucidatDetailsSectionData,
              ...nonElucidatDetailsSectionData,
              ...channelDetailsSectionData,
              ...testUsersSectionData,
              ...journalSectionData,
            });
          }
        }).catch((e) => {
          notifyError(getErrorMessage(e));
          setValues({
            ...overviewSectionData,
            ...programDetailsSectionData,
            ...doceboDetailsSectionData,
            ...elucidatDetailsSectionData,
            ...nonElucidatDetailsSectionData,
            ...channelDetailsSectionData,
            ...testUsersSectionData,
            ...journalSectionData,
          });
        }).finally(() => { setIsEdit(false); });
      } catch (err) {
        notifyError(getErrorMessage(err, err.message));
      }
    },
  });

  useEffect(() => {
    setValues({ ...values, ...overviewSectionData });
  }, [overviewSectionData]);

  useEffect(() => {
    setValues({ ...values, ...programDetailsSectionData });
  }, [programDetailsSectionData]);

  useEffect(() => {
    setValues({ ...values, ...doceboDetailsSectionData });
  }, [doceboDetailsSectionData]);

  useEffect(() => {
    setValues({ ...values, ...elucidatDetailsSectionData });
  }, [elucidatDetailsSectionData]);

  useEffect(() => {
    setValues({ ...values, ...nonElucidatDetailsSectionData });
  }, [nonElucidatDetailsSectionData]);

  useEffect(() => {
    setValues({ ...values, ...channelDetailsSectionData });
  }, [channelDetailsSectionData]);

  useEffect(() => {
    setValues({ ...values, ...testUsersSectionData });
  }, [testUsersSectionData]);

  const SaveData = () => {
    handleSubmit();
  };

  const confirmProceed = () => {
    setFromDialog(true);
  };

  useEffect(() => {
    if (fromDialog) {
      SaveData();
    }
  }, [fromDialog]);

  const cancelData = () => {
    setIsEdit(false);
    setValues({
      ...overviewSectionData,
      ...programDetailsSectionData,
      ...doceboDetailsSectionData,
      ...elucidatDetailsSectionData,
      ...nonElucidatDetailsSectionData,
      ...channelDetailsSectionData,
      ...testUsersSectionData,
    });
  };

  const onRefresh = () => {
    refreshData();
    doceboSectionRef.current.scrollIntoView();
  };

  return (
    <Paper className={classes.wrapper}>
      <Header
        heading="Production Checklist"
        subHeading={`Last updated by ${values.last_activity_first_name} ${values.last_activity_last_name}: ${reportPageDateTimeFormatter(new Date(values?.last_activity_timestamp))}`}
        customSubHeadingClass={classes.subHeadingHeader}
        className={classes.pageHeader}
      >
        {!isDDAdmin && (
          <Box className={classes.rightHeaderWrapper}>
            <Box item className={classes.lastRefreshSection}>
              Last refreshed at
              {' '}
              {`${reportPageDateTimeFormatter(new Date(values?.lastRefreshed))}`}
              <button onClick={onRefresh} className={classes.refreshButton} type="button">Refresh</button>
              <InfoTooltip title="Docebo and Elucidat sections will be auto-refreshed using APIs if the PC was last accessed more than 2 hrs ago, but will not be auto-refreshed if the PC was last accessed less than 2 hrs ago. Manually refresh the PC if changes were recently made to Docebo or Elucidat in order to reference up-to-date information." />
            </Box>
            {isEdit ? (
              <Box>
                <Button
                  color="primary"
                  variant="outlined"
                  style={{ maxHeight: '3.25rem' }}
                  fontSize="medium"
                  onClick={cancelData}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ maxHeight: '3.25rem', marginLeft: '1rem' }}
                  fontSize="medium"
                  onClick={SaveData}
                >
                  Save Changes
                </Button>
              </Box>
            ) : (
              <Button
                color="primary"
                variant="contained"
                style={{ maxHeight: '3.25rem', marginLeft: '1rem' }}
                fontSize="medium"
                onClick={() => setIsEdit(true)}
              >
                Edit
              </Button>
            )}
          </Box>
        )}
      </Header>
      <Box className={classes.pageHeader}>
        {isAnyUnassignedScorm ? (
          <AlertBarWithAction
            variant="alertError"
            percentage={null}
            actionButtonText="View Docebo Details"
            labelText="Action Required: There are Unassigned - SCORM TM(s) for this learning plan. Please see the Docebo details section for more information and to assign SCORM"
            onActionClick={() => doceboSectionRef.current.scrollIntoView()}
            actionButtonIcon={<CloseIcon onClick={() => setIsAnyUnassignedScorm(false)} />}
          />
        ) : null}
      </Box>
      <Grid container item spacing={0}>
        <OverviewSection
          isLoading={isOverviewLoading}
          programId={programId}
          values={values}
          handleSubmit={handleSubmit}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          resetForm={resetForm}
          isEdit={isEdit}
          dividerStyle={classes.divider}
          customLabel={classes.customLabel}
          isDDAdmin={isDDAdmin}
        />
        <ProgramDetails
          isLoading={isProgramDetailsLoading}
          programId={programId}
          values={values}
          handleSubmit={handleSubmit}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          resetForm={resetForm}
          isEdit={isEdit}
          dividerStyle={classes.divider}
          customLabel={classes.customLabel}
          ellipsisTableString={classes.ellipsisTableString}
          isCPAdmin={isCPAdmin}
        />
        <DoceboDetails
          isLoading={isDoceboDetailsLoading}
          values={values}
          programId={programId}
          doceboSectionRef={doceboSectionRef}
          isDDAdmin={isDDAdmin}
          isAnyUnassignedScorm={isAnyUnassignedScorm}
          setIsAnyUnassignedScorm={setIsAnyUnassignedScorm}
        />
        <ScormDetails
          isLoading={isElucidatDetailsLoading}
          nonElucidatLoading={isNonElucidatDetailsLoading}
          values={values}
          dividerStyle={classes.divider}
          customLabel={classes.customLabel}
          setFieldValue={setFieldValue}
          isEdit={isEdit}
          configId={configId}
          linkToLp={linkToLp}
          setLinkToLp={setLinkToLp}
          programId={programId}
        />
        <ResourceChannels
          isLoading={ischannelDetailsLoading}
          values={values}
          programId={programId}
        />
        <JournalDetailsSection
          isLoading={isJournalsLoading}
          values={values}
          programId={programId}
          customLabel={classes.customLabel}
          isDDAdmin={isDDAdmin}
        />
        <PointOfContacts
          isLoading={isProgramDetailsLoading}
          programId={programId}
          values={values}
          handleSubmit={handleSubmit}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          resetForm={resetForm}
          isEdit={isEdit}
          dividerStyle={classes.divider}
          customLabel={classes.customLabel}
        />
        <TestUsersDetails
          isLoading={isTestUsersLoading}
          values={values}
          programId={programId}
        />
      </Grid>
      {
        isEdit && visibleDialog && (
          <CustomModal open={visibleDialog} onClose={() => setVisibleDialog(false)} breakpoint="sm">
            <ConfigChangeModal
              onClose={() => setVisibleDialog(false)}
              onConfirm={confirmProceed}
            />
          </CustomModal>
        )
      }
    </Paper>
  );
};

ProductionChecklist.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string,
      programSubType: PropTypes.string,
      transactionId: PropTypes.string,
    }),
  }).isRequired,
  programMetadata: PropTypes.shape({
    config_id: PropTypes.string,
    docebo_lp_id: PropTypes.string,
  }).isRequired,
};

export default ProductionChecklist;
