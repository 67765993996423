import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import MyContext from '../../context';
import PlatformUsers from './redux';
import UserDetail from './userDetailsredux';
import UserCreation from './userCreateredux';
import BulkActionUpload from './BulkActionUpload';
import {
  ROLE_PROD,
} from '../../constants';

const PlatformUsersLandingPage = (props) => {
  const { match } = props;
  const { role } = useContext(MyContext);

  const isCpRole = role === ROLE_PROD;
  const programSubType = get(match, 'params.programSubType', null);
  if (programSubType === 'bulkUpload') {
    return <BulkActionUpload match={match} isCpRole={isCpRole} />;
  }
  if (programSubType === 'createUser') {
    return <UserCreation match={match} />;
  } if (programSubType) {
    return <UserDetail match={match} />;
  }
  return <PlatformUsers match={match} isCpRole={isCpRole} />;
};
PlatformUsersLandingPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programSubType: PropTypes.string,
    }),
  }).isRequired,
};

export default PlatformUsersLandingPage;
