import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import _isArray from 'lodash/isArray';
import LinearProgress from '@mui/material/LinearProgress';
import InfoTooltip from '../common/InfoTooltip';
import ReleaseIcon from '../../assets/icons/release-icon.svg';
import DisabledReleaseIcon from '../../assets/icons/disabled-release-icon.svg';
import {
  MODERATE_DARK_GREY, DARK_GREY, DANGER_LIGHT_RED, DANGER_RED, SWITCH_GREY,
} from '../../stylesheets/colors';
import LinkButton from '../common/LinkButton';
import AlertWarning from '../../assets/icons/alert-red-warning.svg';
import { reportPageDateTimeFormatter } from '../../helpers/formattingHelpers';

const useStyles = makeStyles({
  noDisplay: {
    display: 'none',
  },
  cellPadding: {
    paddingTop: '0',
  },
  infoHelperText: {
    color: DARK_GREY,
    fontSize: '1.125rem',
    fontWeight: '800',
  },
  infoContentText: {
    color: MODERATE_DARK_GREY,
    fontWeight: '400',
    fontSize: '1.125rem',
  },
  nestedText: {
    margin: '0 0.5rem',
  },
  releaseDetails: {
    minHeight: '3rem',
  },
  addManuallyWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  alertImage: {
    paddingRight: '1rem',
  },
  verticalPadding: {
    padding: '0.25rem 0',
  },
  bgSurface: {
    padding: '0.5rem',
    backgroundColor: DANGER_LIGHT_RED,
  },
});

const ExpandedRowComponent = ({
  isExpanded, elucidatRelease, onRelease, disabledActions, addManually, isNotAllowed, loading,
  forDDAdmin, scormType,
}) => {
  const classes = useStyles();

  const greyBorder = {
    borderBottom: `1px solid ${SWITCH_GREY}`,
  };

  const hideClass = isExpanded ? '' : classes.noDisplay;
  const releasedOn = reportPageDateTimeFormatter(new Date(elucidatRelease?.last_released));

  return (
    <TableRow className={hideClass}>
      <TableCell className={classes.cellPadding} style={greyBorder} />
      <TableCell className={classes.cellPadding} style={greyBorder}>
        {(() => {
          if (!scormType) {
            return (
              <Box className={`${classes.addManuallyWrapper} ${classes.bgSurface}`}>
                <img src={AlertWarning} alt="alert" className={classes.alertImage} />
                <Typography component="body1" style={{ color: DANGER_RED }}>
                  SCORM type cannot be blank.
                  Kindly edit the training material to assign SCORM type.
                </Typography>
              </Box>
            );
          }
          if (scormType === 'Elucidat') {
            return (
              <>
                {loading ? (
                  <LinearProgress />
                ) : (
                  <>
                    {_isArray(elucidatRelease) ? (
                      <Box className={classes.addManuallyWrapper}>
                        <img src={AlertWarning} alt="alert" className={classes.alertImage} />
                        <Typography component="body1">
                          This SCORM was created outside of the Ops Portal, please
                          <LinkButton disabledSynchBtn={forDDAdmin || disabledActions} text="manually assign" onClick={addManually} color="primary" variant="btnGeneral2" />
                          the Release ID and Project ID
                        </Typography>
                      </Box>
                    ) : (Object.keys(elucidatRelease).length > 0
                      && (
                        <Box>
                          <Box>
                            <Box>
                              <Typography variant="subtitle2" className={`${classes.infoHelperText} ${classes.verticalPadding}`}>
                                Project Details:
                                <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>
                                  {elucidatRelease.elucidat_project_name}
                                  {' '}
                                  -
                                  {' '}
                                  [
                                  {elucidatRelease.elucidat_project_id}
                                  ]
                                </Typography>
                              </Typography>
                              <Typography variant="subtitle2" className={`${classes.infoHelperText} ${classes.verticalPadding}`}>
                                Release Details:
                                <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>
                                  Version
                                  {' '}
                                  {elucidatRelease.elucidat_release_version}
                                  {' '}
                                  (
                                  {elucidatRelease.elucidat_release_id}
                                  )
                                  /
                                  (
                                  {elucidatRelease.elucidat_release_description}
                                  )
                                </Typography>
                              </Typography>
                            </Box>
                          </Box>
                          {
                            elucidatRelease.last_released && (
                              <Typography variant="subtitle2" className={`${classes.infoContentText} ${classes.verticalPadding}`} style={{ fontSize: '1rem' }}>
                                Last updated on
                                <Typography variant="span" style={{ padding: '0rem 0.5rem' }}>{releasedOn}</Typography>
                                {
                                  elucidatRelease.triggered_by && (
                                    <>
                                      by
                                      {' '}
                                      {elucidatRelease.triggered_by}
                                    </>
                                  )
                                }
                              </Typography>
                            )
                          }
                        </Box>
                      )
                    )}
                  </>
                )}
              </>
            );
          }
          return (
            <Typography component="body1">
              Project details not applicable for Non-Elucidat SCORMs
            </Typography>
          );
        })()}
      </TableCell>
      <TableCell className={classes.cellPadding} style={greyBorder} />
      <TableCell className={classes.cellPadding} style={greyBorder} />
      <TableCell className={classes.cellPadding} style={greyBorder}>
        <Box className={classes.releaseDetails}>
          {!isNotAllowed && (
            <IconButton
              disabled={disabledActions}
              onClick={onRelease}
            >
              {
                (disabledActions) ? (
                  <img src={DisabledReleaseIcon} className={classes.releaseIcon} alt="disabled-release-icon" />
                ) : (
                  <InfoTooltip title="Create a full re-release for Elucidat SCORM" placement="top">
                    <img src={ReleaseIcon} className={classes.releaseIcon} alt="release-icon" />
                  </InfoTooltip>
                )
              }
            </IconButton>
          )}
        </Box>
      </TableCell>
      <TableCell className={classes.cellPadding} style={greyBorder} />
    </TableRow>
  );
};

ExpandedRowComponent.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  elucidatRelease: PropTypes.shape({
    elucidat_project_id: PropTypes.string.isRequired,
    elucidat_project_name: PropTypes.string.isRequired,
    elucidat_release_id: PropTypes.string.isRequired,
    last_released: PropTypes.string.isRequired,
    triggered_by: PropTypes.string.isRequired,
    elucidat_release_version: PropTypes.string.isRequired,
    elucidat_release_description: PropTypes.string.isRequired,
  }).isRequired,
  disabledActions: PropTypes.bool.isRequired,
  onRelease: PropTypes.func.isRequired,
  addManually: PropTypes.func.isRequired,
  isNotAllowed: PropTypes.bool.isRequired,
  forDDAdmin: PropTypes.bool.isRequired,
  scormType: PropTypes.string.isRequired,
};

export default ExpandedRowComponent;
