import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import LabledTextField from '../common/LabledTextField';
import CustomSelect from '../common/CustomSelect';

import { DARK_MEDIUM_GREY, MODERATE_LIGHT_GREY } from '../../stylesheets/colors';
import AsyncSelect from './AsyncSelect';
import { testUsersOptions } from '../../constants';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  operationsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.4,
    padding: '3rem 6rem 3rem 3rem',
  },
  operationsTitle: {
    color: DARK_MEDIUM_GREY,
  },
  primaryExperienceRow: {
    margin: '1rem 0 0 0',
  },
  defaultExperienceRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '2rem 0 0 0',
    visibility: 'hidden', // Requirement on hold
  },
  defaultExperienceCheckbox: {
    padding: '0px',
    marginRight: '0.5rem',
    '& svg': {
      height: '1.25rem',
      width: '1.25rem',
    },
  },
  createUsersButton: {
    width: '10rem',
    height: '2.5rem',
    textTransform: 'unset',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&:disabled': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      opacity: ({ buttonDisabled }) => (buttonDisabled ? '60%' : '100%'),
    },
  },
  spacer2: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.3,
  },
  previewWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.6,
    padding: '3rem 6rem 0 3rem',
    borderLeft: `1px solid ${MODERATE_LIGHT_GREY}`,
  },
  previewTitle: {
    display: 'flex',
    flexDirection: 'row',
    color: DARK_MEDIUM_GREY,
    alignItems: 'center',
  },
  previewSample: {
    display: 'flex',
    flex: 0.87,
    paddingTop: '12px',
    fontFamily: 'McKinseySans',
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  mb: {
    marginBottom: '0.875rem',
  },
}));

const sampleFormatter = (configId, testUserStart, testUserCount) => {
  if (!configId) return null;
  const configIdWithoutWhiteSpace = configId.replace(/\s+/g, '_');
  return (
    <div>
      <Typography>{`Test${testUserStart}_${configIdWithoutWhiteSpace}@mckinsey.com`}</Typography>
      <Typography>.</Typography>
      <Typography>.</Typography>
      <Typography>.</Typography>
      <Typography>
        {`Test${testUserStart + testUserCount - 1}_${configIdWithoutWhiteSpace}@mckinsey.com`}
      </Typography>
    </div>
  );
};

const CreateTestUsersOperationsPage = ({
  onCreate, experienceTypeOptions, configId,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const classes = useStyles({ buttonDisabled });
  const [primaryExperience, setPrimaryExperience] = useState(null);
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [testUserStart, setTestUserStart] = useState(1);
  const [testUserCountObj, setTestUserCountObj] = useState(testUsersOptions[1]);
  const [isDefaultExperience, setIsDefaultExperience] = useState(false);

  const testUserCount = testUserCountObj.value;
  useEffect(() => {
    // TODO: Proper form validation
    const isFormValid = primaryExperience && testUserStart && (testUserStart > 0);
    setButtonDisabled(!isFormValid);
  }, [primaryExperience, testUserStart, setButtonDisabled]);

  const onCreateInner = () => {
    onCreate(primaryExperience, testUserStart, testUserCount, isDefaultExperience,
      selectedPrograms);
  };

  const sampleUserPreview = sampleFormatter(configId, testUserStart, testUserCount);

  const handlePickedPrograms = (items) => {
    setSelectedPrograms(_map(items, 'value'));
  };

  return (
    <Paper className={classes.wrapper}>
      <div className={classes.operationsWrapper}>
        <Typography variant="body1" className={classes.operationsTitle}>
          Create new test users
        </Typography>

        <div className={classes.primaryExperienceRow}>
          <CustomSelect
            labelClass={classes.mb}
            options={experienceTypeOptions}
            placeholder="Select an option"
            label="Please select primary experience"
            onChange={(e) => setPrimaryExperience(e.value)}
          />
        </div>

        <div className={classes.primaryExperienceRow}>
          <LabledTextField
            label="# of user starting at"
            name="startingAt"
            value={testUserStart}
            onChange={(v) => setTestUserStart(Number.parseInt(v, 10))}
            type="number"
            disabled
          />
        </div>

        <div className={classes.primaryExperienceRow}>
          <CustomSelect
            labelClass={classes.mb}
            options={testUsersOptions}
            placeholder="Select an option"
            label="Count (Max: 2)"
            onChange={(e) => setTestUserCountObj(e)}
            defaultValue={testUserCountObj}
            isSearchable={false}
          />
        </div>

        <div className={classes.primaryExperienceRow}>
          <AsyncSelect
            label="Select additional program IDs"
            placeholder="Select Programs"
            selectedItems={selectedPrograms}
            onChange={(value) => handlePickedPrograms(value)}
            configId={configId}
          />
        </div>

        <div className={classes.defaultExperienceRow}>
          <Checkbox
            className={classes.defaultExperienceCheckbox}
            defaultChecked={false}
            value={isDefaultExperience}
            onChange={() => setIsDefaultExperience((previousValue) => !previousValue)}
            color="primary"
          />
          <Typography variant="body1">Set this as default experience</Typography>
        </div>

        <div>
          <Button
            type="button"
            variant="contained"
            color="primary"
            className={classes.createUsersButton}
            onClick={onCreateInner}
            disabled={buttonDisabled}
          >
            Create Users
          </Button>
        </div>

        <div className={classes.spacer2} />
      </div>

      <div className={classes.previewWrapper}>
        <Typography variant="body1" className={classes.previewTitle}>
          Preview of sample user
        </Typography>

        <div className={classes.previewSample}>{sampleUserPreview}</div>
      </div>
    </Paper>
  );
};

CreateTestUsersOperationsPage.propTypes = {
  onCreate: PropTypes.func.isRequired,
  experienceTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  configId: PropTypes.string.isRequired,
};

export default CreateTestUsersOperationsPage;
