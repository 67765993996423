import { isEmpty } from 'lodash';
import { createPrompt, deletePrompt, updatePrompt } from '../../../../../containers/ContentLibrary/api';
import { USER_INFO_KEY } from '../../../../../constants';
import { getUserNameFromLocalStorage } from '../../../../../helpers/userHelper';
import { getErrorMessage } from '../../../../../helpers/apiHelper';

export const handlePromptDelete = async ({
  promptId, setSnackbarObj, cbFunction, setAlertBarConfig,
}) => {
  try {
    await deletePrompt(promptId);
    cbFunction();
    setAlertBarConfig({
      labelText: 'Prompt Deleted Successfully',
      variant: 'success',
      open: true,
    });
  } catch (e) {
    console.log(e);
    setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
  }
};

export const handlePromptCreteAndEdit = async ({
  setSnackbarObj,
  values,
  savedPromptValues,
  selectedPrompt,
  pageId,
  prompts,
  onPromptModalClose,
  cbFunction,
  history,
  allowRedirect,
  setAlertBarConfig,
}) => {
  try {
    const { promptText, promptType } = values;
    const isNewPrompt = isEmpty(savedPromptValues);
    const { id: promptId, sequence_number } = selectedPrompt;
    const userName = getUserNameFromLocalStorage(USER_INFO_KEY);
    const data = {
      prompt: promptText,
      journal_page: pageId,
      sequence_number: isNewPrompt ? prompts.length + 1 : sequence_number,
      is_required: promptType === 'mandatory',
      modified_by: userName,
    };

    let successMessage = '';
    if (isNewPrompt) {
      data.created_by = userName;
      successMessage = 'Prompt Created Successfully';
      await createPrompt(data);
    } else {
      successMessage = 'Prompt Edited Successfully';
      await updatePrompt(data, promptId);
    }
    onPromptModalClose();
    await cbFunction();
    if (allowRedirect) {
      history.push({
        pathname: `/contentLibrary/lti/pages/${pageId}`,
        state: { successMessage },
      });
    } else {
      setAlertBarConfig({
        labelText: successMessage,
        variant: 'success',
        open: true,
      });
    }
  } catch (e) {
    setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
    console.error(e);
  }
};
