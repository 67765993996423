import config from '../../config';
import request from '../../helpers/apiHelper';

export const validateLMSUserApiCall = async (
  programSubType,
  data,
) => {
  const url = `${config.ROUTES.VALIDATE_XLSX}/${programSubType}`;
  const options = { url, method: 'POST', data };
  try {
    const result = await request(options);
    return result.data;
  } catch (e) {
    if (e.response.status === 400) {
      return e.response.data;
    }
    return { errors: [{ message: e.toString() }] };
  }
};

export const uploadXlsx = async (programSubType, data) => {
  const url = `${config.API_SERVER}${programSubType}`;
  const options = { url, method: 'POST', data };
  const result = await request(options);
  return result;
};
