import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import AlertBarWithAction from '../common/AlertBarWithAction';
import { REPORT } from '../../constants';
import ReorderTrainingMaterialsStatuses from './ReorderTrainingMaterialsStatuses';
import ReorderComponentsStatuses from './ReorderComponentsStatuses';

const ReorderTmsStatuses = ({
  transactionId, statusVariant, showElement, onDownloadLog, getReport,
  forComponents, closeNotification,
}) => {
  const [modalState, setModalState] = useState('');
  const [open, setOpen] = useState(false);

  const onViewStatus = (status) => {
    setModalState(status);
    setOpen(true);
  };

  const onInfoModalClose = () => {
    setOpen(false);
    setModalState('');
  };

  const Component = forComponents ? ReorderComponentsStatuses : ReorderTrainingMaterialsStatuses;

  const actionCompoentText = statusVariant.variant === 'error' ? 'View Status' : '';

  const actionTMText = statusVariant.variant !== 'success' ? 'View Status' : '';

  return (
    <>
      {showElement ? (
        <AlertBarWithAction
          variant={statusVariant.variant}
          percentage={null}
          labelText={statusVariant.message}
          actionButtonText={forComponents ? actionCompoentText : actionTMText}
          onActionClick={() => onViewStatus(REPORT)}
          actionButtonIcon={<CloseIcon onClick={closeNotification} />}
        />
      ) : null}
      {
        transactionId && open && (
          <Component
            transactionId={transactionId}
            modalState={modalState}
            open={open}
            onClose={onInfoModalClose}
            onDownloadLog={onDownloadLog}
            getReport={getReport}
            progressData={{}}
          />
        )
      }
    </>

  );
};

ReorderTmsStatuses.defaultProps = {
  forComponents: false,
};

ReorderTmsStatuses.propTypes = {
  transactionId: PropTypes.string.isRequired,
  statusVariant: PropTypes.shape({
    variant: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }).isRequired,
  showElement: PropTypes.bool.isRequired,
  onDownloadLog: PropTypes.func.isRequired,
  getReport: PropTypes.func.isRequired,
  forComponents: PropTypes.bool,
  closeNotification: PropTypes.func.isRequired,
};

export default ReorderTmsStatuses;
