import React from 'react';
import PropTypes from 'prop-types';

import Chip from '@mui/material/Chip';
import {
  MODERATE_LIGHT_GREY, BLACK, DANGER_RED,
} from '../../stylesheets/colors';
import UnassignedScormIcon from '../../assets/icons/unassigned-scorm.svg';

const ScormTypeChip = ({ label, icon, className }) => (
  <>
    {label !== 'Unspecified' && (
      <Chip
        className={className}
        style={{
          backgroundColor: MODERATE_LIGHT_GREY,
          color: label === 'SCORM - Unassigned' ? DANGER_RED : BLACK,
          fontSize: '0.8rem',
          fontWeight: '800',
          padding: '0 0.5rem',
        }}
        icon={<img src={label === 'SCORM - Unassigned' ? UnassignedScormIcon : icon} alt="lo-type-icon" />}
        label={label}
        size="small"
      />
    )}
  </>
);

ScormTypeChip.defaultProps = {
  className: '',
};

ScormTypeChip.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ScormTypeChip;
