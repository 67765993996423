import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import _has from 'lodash/has';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useFormik } from 'formik';
import {
  validateCourseBeforeClone,
} from '../../containers/ContentManagement/apis';
import ConfirmationModal from '../common/ConfirmationModal';
import FormattedTypography from '../common/FormattedTypography';
import LabledTextField from '../common/LabledTextField';
import NoComponentThumbnail from '../../assets/img/empty-component-title.svg';
import { ACCORDION_GREY, MODERATE_DARK_GREY, REPORT_ERROR_RED } from '../../stylesheets/colors';
import { CopyComponentValidationSchema } from '../../helpers/validationHelper';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    padding: '1.5rem',
  },
  displayWrpapper: {
    padding: '1.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '1.5rem 0',
  },
  displayContainer: {
    padding: '0 1.5rem',
  },
  displayLabelText: {
    padding: '1rem 0',
  },
  width100: {
    width: '60%',
  },
  infoHelperText: {
    color: ACCORDION_GREY,
  },
  greyText: {
    color: MODERATE_DARK_GREY,
  },
  thumbnailImage: {
    maxWidth: '100%',
    display: 'block',
    objectFit: 'cover',
    objectPosition: 'bottom',
  },
  error: {
    color: REPORT_ERROR_RED,
  },
  modalHeight: {
    minHeight: '30rem',
  },
  rightText: {
    marginLeft: 'auto',
    float: 'right',
  },
});

const isValue = (value) => (!!((value !== null && value !== '' && value !== undefined)));

const Error = ({ errorText }) => {
  const classes = useStyles();

  return (
    <span className={classes.error}>{errorText || 'This field is required'}</span>
  );
};
Error.propTypes = {
  errorText: PropTypes.string.isRequired,
};

const DisplaySection = ({ src, title, code }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.displayWrpapper}>
      <img src={isValue(src) ? `https:${src}` : NoComponentThumbnail} className={classes.thumbnailImage} height="90" width="90" alt="my-mage" />
      <Box className={classes.displayContainer}>
        <Box className={classes.displayLabelText}>
          <Typography variant="body1" className={classes.infoHelperText}> Component Title:</Typography>
          <Typography variant="body1">{title}</Typography>
        </Box>
        <Box>
          <Typography variant="body1" className={classes.infoHelperText}>Component Code:</Typography>
          <Typography variant="body1">{code}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

DisplaySection.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
};

const CopyComponentModal = ({
  open, onClose, data, onCopyComponent, configId, programId, copyProcessing, loadingText,
}) => {
  const classes = useStyles();

  const {
    name, code, course_graphic, id,
  } = data;

  const onValidateComponent = async (newComponentData) => {
    try {
      return await validateCourseBeforeClone(programId,
        { ...newComponentData, lp_config_id: configId });
    } catch (err) {
      console.log(err);
    }
    return {};
  };

  const {
    values, handleSubmit, handleChange, errors, touched, resetForm,
  } = useFormik({
    initialValues: {
      newComponentTitle: `Copy of_${name}`,
      newComponentCode: `Copy of_${code}`,
    },
    enableReinitialize: true,
    validationSchema: CopyComponentValidationSchema,
    onSubmit: (payload, actions) => {
      // resetForm();
      onValidateComponent({
        new_component_title: payload?.newComponentTitle,
        new_component_code: payload?.newComponentCode,
      }).then((resp) => {
        if (resp.data.success === true) {
          actions.setSubmitting(true);
          onCopyComponent(id,
            {
              new_component_title: payload?.newComponentTitle,
              new_component_code: payload?.newComponentCode,
            });
        } else {
          actions.setSubmitting(false);
          if (_has(resp.data, 'error-key')) {
            if (resp.data['error-key'] === 'course_name') {
              actions.setErrors({
                newComponentTitle: resp.data.message,
              });
            }
            if (resp.data['error-key'] === 'course_code') {
              actions.setErrors({
                newComponentCode: resp.data.message,
              });
            }
          }
        }
      }).catch((err) => {
        console.log(err);
      });
    },
  });

  const resetFormBeforeClose = () => {
    resetForm();
    onClose();
  };

  return (
    <ConfirmationModal
      open={open}
      isLoading={copyProcessing}
      onClose={copyProcessing ? null : resetFormBeforeClose}
      breakpoint="md"
      minHeightClass={classes.modalHeight}
      loadingText={loadingText}
      title={(
        <FormattedTypography
          body="Copy Component Shell"
          subHeading="Create a copy of this component as a shell including it's basic details, settings and metadata."
        />
      )}
      dialogContentClass={classes.width100}
      content={(
        <Container className={classes.wrapper}>
          <Typography variant="subtitle2">Copying Component</Typography>
          <DisplaySection src={course_graphic} title={name} code={code} />
          <LabledTextField
            label="Enter a new Component Title"
            name="newComponentTitle"
            required
            labelClass={classes.greyText}
            value={values.newComponentTitle}
            onChange={(e) => {
              if (e.target.value.length <= 255) {
                handleChange(e);
              }
            }}
          />
          <div className={classes.errorBlock}>
            {touched.newComponentTitle && !!errors.newComponentTitle
              && <Error errorText={touched.newComponentTitle && errors.newComponentTitle} />}

            <Typography className={classes.rightText}>
              {(values.newComponentTitle || '').length}
              /255
            </Typography>
          </div>

          <LabledTextField
            label="Enter a new Component Code"
            name="newComponentCode"
            required
            labelClass={classes.greyText}
            value={values.newComponentCode}
            onChange={(e) => {
              if (e.target.value.length <= 50) {
                handleChange(e);
              }
            }}
          />
          <div className={classes.errorBlock}>
            {touched.newComponentCode && !!errors.newComponentCode
              && <Error errorText={touched.newComponentCode && errors.newComponentCode} />}
            <Typography className={classes.rightText}>
              {(values.newComponentCode || '').length}
              /50
            </Typography>
          </div>
        </Container>
      )}
      actions={(
        <>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            Copy Component Shell
          </Button>
        </>
      )}
    />
  );
};

CopyComponentModal.defaultProps = {
  data: {
    id: '',
    name: '',
    code: '',
    course_graphic: '',
  },
};
CopyComponentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    code: PropTypes.string,
    course_graphic: PropTypes.string,
  }),
  onCopyComponent: PropTypes.func.isRequired,
  configId: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired,
  copyProcessing: PropTypes.bool.isRequired,
  loadingText: PropTypes.string.isRequired,
};

export default CopyComponentModal;
