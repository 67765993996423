import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import { Box } from '@material-ui/core';
import { Grid } from '@mui/material';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import LoaderIcon from '../../assets/icons/loader-icon-white.svg';
import CheckRoundIconDark from '../../assets/icons/green-round-check-dark.svg';
import ErrorRoundIcon from '../../assets/icons/error-round.svg';
import PendingIcon from '../../assets/icons/pending-icon.svg';
import AlertWarning from '../../assets/icons/alert-red-warning.svg';
import ConfigurationInfoIcon from '../../assets/icons/configuration-info-icon.svg';
import {
  DARK_GREEN1, ERROR_ORANGE1, MCKINSEY_BLUE, RED_DARK, SUCCESS_GREEN3, WHITE,
  FADED_BLUE, ENV_BANNER_COLOR,
} from '../../stylesheets/colors';
import MCKINSEY_SANS from '../../stylesheets/fonts';
import LinkButton from './LinkButton';

const useStyles = makeStyles({
  flexCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bodyContainer: {
    display: 'flex',
    minWidth: '9.5rem',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: MCKINSEY_BLUE,
    marginRight: '1rem',
  },
  '@keyframes spinner': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(359deg)',
    },
  },
  loaderIconWithAnimation: {
    padding: '1rem',
    marginBottom: '0 !important',
    animation: '$spinner 1s linear infinite',
  },
  statusIcon: {
    padding: '1.125rem',
  },
  centageTextClass: {
    fontFamily: MCKINSEY_SANS,
    fontSize: '1.5rem',
    color: WHITE,
    paddingRight: '1rem',
  },
  labelTextCls: {
    paddingLeft: '2rem',
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  alertBarActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: ({ actionButtonText }) => (actionButtonText ? '20%' : '5%'),
  },

  alertBarInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: ({ actionButtonText }) => (actionButtonText ? '80%' : '95%'),
  },
});

const AlertBarWithAction = ({
  percentage,
  labelText,
  actionButtonText,
  actionButtonIcon,
  onActionClick,
  variant,
  customStyle,
}) => {
  const classes = useStyles({ actionButtonText });
  const getStylingProps = useCallback(() => {
    if (variant === 'with-percentage') {
      return {
        statusIcon: LoaderIcon,
        colorOuter: 'transparent',
        innerContainerCls: classes.bodyContainer,
        imgClassName: classes.loaderIconWithAnimation,
        labelTextColor: 'inherit',
        linkButtonVariant: 'btnGeneral',
      };
    }
    if (variant === 'loading-without-percentage') {
      return {
        statusIcon: LoaderIcon,
        colorOuter: 'transparent',
        innerContainerCls: classes.bodyContainer,
        imgClassName: classes.loaderIconWithAnimation,
        labelTextColor: 'inherit',
        linkButtonVariant: 'btnGeneral',
      };
    }
    if (variant === 'success') {
      return {
        statusIcon: CheckRoundIconDark,
        colorOuter: SUCCESS_GREEN3,
        innerContainerCls: classes.flexCenter,
        imgClassName: classes.statusIcon,
        labelTextColor: DARK_GREEN1,
        linkButtonVariant: 'btnGeneralGreen',
      };
    }
    if (variant === 'error' || variant === 'serverError') {
      return {
        statusIcon: ErrorRoundIcon,
        colorOuter: ERROR_ORANGE1,
        innerContainerCls: classes.flexCenter,
        imgClassName: classes.statusIcon,
        labelTextColor: RED_DARK,
        linkButtonVariant: 'btnGeneralError',
      };
    }
    if (variant === 'pending') {
      return {
        statusIcon: PendingIcon,
        colorOuter: 'transparent',
        innerContainerCls: classes.bodyContainer,
        imgClassName: classes.statusIcon,
        labelTextColor: 'inherit',
        linkButtonVariant: 'btnGeneral',
      };
    }
    if (variant === 'alertError') {
      return {
        statusIcon: AlertWarning,
        colorOuter: ERROR_ORANGE1,
        innerContainerCls: classes.flexCenter,
        imgClassName: classes.statusIcon,
        labelTextColor: RED_DARK,
        linkButtonVariant: 'btnGeneralError',
      };
    }
    if (variant === 'configurationInfo') {
      return {
        statusIcon: ConfigurationInfoIcon,
        colorOuter: FADED_BLUE,
        innerContainerCls: classes.flexCenter,
        imgClassName: classes.statusIcon,
        labelTextColor: ENV_BANNER_COLOR,
        linkButtonVariant: 'btnGeneralError',
      };
    }
    return {
      statusIcon: LoaderIcon,
      colorOuter: 'transparent',
      innerContainerCls: classes.flexCenter,
      imgClassName: classes.statusIcon,
      labelTextColor: 'inherit',
      linkButtonVariant: 'btnGeneral',
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant]);

  const {
    statusIcon, colorOuter, imgClassName, innerContainerCls, labelTextColor, linkButtonVariant,
  } = getStylingProps();

  return (
    <Grid container className={`${classes.flexCenter} ${customStyle}`} sx={{ boxShadow: 1, bgcolor: colorOuter }}>
      <Grid item className={classes.alertBarInfo}>
        <Box component="div" className={innerContainerCls}>
          <img
            alt="status-icons"
            src={statusIcon}
            width="40rem"
            height="40rem"
            className={imgClassName}
          />
          {percentage !== null ? (
            <Box className={classes.centageTextClass}>{`${Math.floor(percentage)}%`}</Box>
          ) : null}
        </Box>
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: '1.125rem',
            color: `${labelTextColor}`,
            padding: '0 1rem',
          }}
        >
          {labelText}
        </Typography>
      </Grid>
      <Grid item sx={{ pr: 2 }} className={classes.alertBarActions}>
        {actionButtonText && (
          <LinkButton variant={linkButtonVariant} onClick={onActionClick} text={actionButtonText} />
        )}
        {actionButtonIcon && (
          <IconButton aria-label="upload picture" component="label">
            {actionButtonIcon}
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

AlertBarWithAction.defaultProps = {
  variant: 'success',
  percentage: null,
  actionButtonIcon: null,
  actionButtonText: '',
  onActionClick: () => { },
  customStyle: '',
};

AlertBarWithAction.propTypes = {
  variant: PropTypes.string,
  percentage: PropTypes.number,
  labelText: PropTypes.string.isRequired,
  actionButtonText: PropTypes.string,
  actionButtonIcon: PropTypes.element,
  onActionClick: PropTypes.func,
  customStyle: PropTypes.string,
};

export default AlertBarWithAction;
