import React, {
  useEffect, useState, useCallback, useContext,
} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _omit from 'lodash/omit';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import MetaDataHeader from '../../components/MetaDataManagement/MetaDataHeader';
import MetaDataGeneral from '../../components/MetaDataManagement/MetaDataGeneral';
import MetaDataCourses from '../../components/MetaDataManagement/MetaDataCourses';
import MetaDataEnrollment from '../../components/MetaDataManagement/MetaDataEnrollment';
import MetaDataUsers from '../../components/MetaDataManagement/MetaDataUsers/index';
import ConflictErrorModal from '../../components/ConflictErrorModal/ConflictErrorModal';
import AlertBar from '../../components/common/AlertBar';
import TabPanel from '../../components/common/TabPanel';

import {
  fetchActiveLanguages, getProgramInfo, getProgress, getReport, downloadFile,
} from '../common/apis';
import {
  updateMetadata, fetchMetadataByDoceboId, updateMetadataCourses,
  updateEnrollmentInfo, getDoceboId, getActivityLogs, uploadXlsx,
  downloadEnrolledUsers, getMetadata,
} from './apis';

import {
  META_DATA_TABS, DOCEBO_METADATA_LOOKUP, ADMIN_GROUP, ADMIN,
} from '../../constants';
import {
  MCKINSEY_BLUE,
} from '../../stylesheets/colors';
import MyContext from '../../context';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  spinnerWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 4rem',
  },
  timeline: {
    flex: 0.20,
  },
  body: {
    flex: 0.80,
  },
});

const MetaDataManagement = (props) => {
  const { match, programMetadata, qs } = props;
  const programId = get(match, 'params.programId');
  const { role, programType } = useContext(MyContext);
  const programSubType = get(match, 'params.programSubType');
  const configId = get(programMetadata, 'config_id', '');

  const classes = useStyles();
  const [value, setValue] = useState('');
  const [filteredTabs, setFilteredTabs] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [componentsMetaData, setComponentsMetaData] = useState({});
  const [activeLanguages, setActiveLanguages] = useState([]);
  const [isSnackBarOpen, setSnackBarOpen] = useState(false);
  const [isConflictErrorModalOpen, setIsConflictErrorModalOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('Unable to connect to Docebo. Please try later.');
  const [isSuccess, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [doceboLpId, setDoceboLpId] = useState('');
  const [metadataReport, setMetadataReport] = useState({});
  const [userTransactionIds, setUserTransactionIds] = useState([]);
  const [downloadXlsTransId, setDownloadXlsTransId] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState('');
  const isAdmin = ADMIN_GROUP.includes(role);
  const fetchProgramMetadata = useCallback(async () => {
    const response = await getProgramInfo(programId);
    const transactionIds = get(
      response,
      `activities_status.${isAdmin ? ADMIN : programType}.bulk_edit_metadata.transaction_ids`,
      [],
    );
    const downloadXlstransactionIds = get(
      response,
      `activities_status.${isAdmin ? ADMIN : programType}.sync_user_metadata.transaction_ids`,
      [],
    );
    setUserTransactionIds(transactionIds);
    setDownloadXlsTransId(downloadXlstransactionIds);
  }, [programId, isAdmin, programType]);

  const getUserMetadata = useCallback(
    async () => {
      const response = await getMetadata(programId);
      if (response.success && response.transaction_id) {
        const { transaction_id } = response;
        setDownloadXlsTransId([transaction_id]);
      }
    }, [programId],
  );

  useEffect(() => {
    fetchProgramMetadata();
  }, [fetchProgramMetadata]);

  const handleChange = async (event, newValue) => {
    if (newValue === 'Users') {
      await fetchProgramMetadata();
    }
    setAlertText('');
    setShowAlert(false);
    setValue(newValue);
  };

  useEffect(() => {
    const tabs = META_DATA_TABS.filter(
      (tab) => tab.roles.includes(role),
    );
    const params = new URLSearchParams(qs);
    setFilteredTabs(tabs);
    const tab = +params.get('tab');
    if (tab >= 1 && tab <= 4) {
      setValue(META_DATA_TABS[tab].label);
    } else {
      setValue(tabs.length && tabs[0].label);
    }
  }, [qs, role]);

  const downloadUserMetadata = () => {
    downloadEnrolledUsers(programId);
  };

  const settingAlertText = (text) => {
    setAlertText(text);
    setShowAlert(true);
  };

  const onDownloadLog = async (data) => {
    try {
      const tid = data.transaction_id;
      const createdAt = data.created_at ? data.created_at : new Date();
      await downloadFile(tid, configId, programSubType, createdAt);
    } catch (e) {
      // TODO: Show error toast
      console.error(e);
    }
  };

  const populateMetadataDetails = useCallback(async (doceboId, includeComponents = false) => {
    try {
      if (doceboId) {
        const response = await fetchMetadataByDoceboId(doceboId, includeComponents);
        const metadata = get(response, 'data.data.metadata', []);
        setMetaData(metadata);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);
  const populateComponentsMetadata = useCallback(async (doceboId,
    fromDocebo = false, includeComponents = true) => {
    try {
      setIsLoading(true);
      const response = await fetchMetadataByDoceboId(doceboId, includeComponents, fromDocebo);
      if (response && response.data && response.data.success) {
        const componentsMetadata = get(response, 'data.data.components_metadata', []);
        setIsLoading(false);
        setComponentsMetaData(componentsMetadata);
      } else {
        setIsLoading(false);
        setComponentsMetaData({});
        setErrorMsg(get(response, 'data.error', 'unable to fetch'));
        setSnackBarOpen(true);
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  const fetchMetadataActivity = useCallback(
    async () => {
      if (programId) {
        try {
          const response = await getActivityLogs(programId);
          if (response?.success) {
            setMetadataReport(response);
          } else {
            setMetadataReport({});
          }
        } catch (e) {
          console.error(e);
        }
      }
    }, [programId],
  );

  const onUpdateMetaInfo = async (language) => {
    try {
      if (metaData) {
        const response = await updateMetadata(doceboLpId, language, programId);
        if (response && response.data && response.data.success) {
          populateMetadataDetails(doceboLpId);
          setSuccess(true);
          setErrorMsg('Language changed successfully');
          setSnackBarOpen(true);
          fetchMetadataActivity();
        } else {
          setErrorMsg(get(response, 'data.error', 'unable to update'));
          setSnackBarOpen(true);
        }
      } else {
        setErrorMsg('Metadata ID does not available');
        setSnackBarOpen(true);
      }
    } catch (e) {
      if (e.response.status === 409) {
        setIsConflictErrorModalOpen(true);
      } else {
        setSnackBarOpen(true);
      }
      console.error(e);
    }
  };

  const onConflictModalClose = () => {
    setIsConflictErrorModalOpen(false);
  };

  const onAlertClose = () => {
    setSnackBarOpen(false);
    setTimeout(() => {
      setSuccess(false);
      setErrorMsg('Unable to connect to Docebo. Please try later.');
    }, 0);
  };

  const formatObject = (object) => _map(object,
    (val, key) => ({ slug: key, key: DOCEBO_METADATA_LOOKUP[key], value: val }));

  const onUpdateMetadata = async (values, callback) => {
    try {
      setIsLoading(true);
      const response = await updateMetadataCourses(
        formatObject(_omit(values, ['componentDoceboId'])), [values?.componentDoceboId], programId,
      );
      if (response && response.data && response.data.success) {
        setSuccess(true);
        setIsLoading(false);
        populateComponentsMetadata(doceboLpId);
        setErrorMsg('Course update successfully');
        setSnackBarOpen(true);
        callback();
      } else {
        setIsLoading(false);
        setErrorMsg(get(response, 'data.error', 'unable to update'));
        setSnackBarOpen(true);
      }
    } catch (e) {
      if (e.response.status === 409) {
        setIsLoading(false);
        setIsConflictErrorModalOpen(true);
        callback();
      } else {
        setIsLoading(false);
        setSnackBarOpen(true);
        setErrorMsg(get(e.response.data, 'data.error', e?.response?.data?.message || 'unable to update data'));
      }
      console.error(e);
    }
  };

  const onUpdateEnrollmentInfo = async (userSlug, userRole) => {
    try {
      const response = await updateEnrollmentInfo(doceboLpId, userSlug, userRole, programId);
      if (response && response.data && response.data.success) {
        setSuccess(true);
        setErrorMsg('User role updated successfully');
        setSnackBarOpen(true);
      } else {
        setIsLoading(false);
        setErrorMsg(get(response, 'data.error', 'unable to update'));
        setSnackBarOpen(true);
      }
    } catch (e) {
      if (e.response.status === 409) {
        setIsLoading(false);
        setIsConflictErrorModalOpen(true);
      } else {
        setSnackBarOpen(true);
      }
      console.error(e);
    }
  };

  const bulkUpdateMetadata = async (values, entityIds, callback) => {
    try {
      setIsLoading(true);
      const response = await updateMetadataCourses(
        formatObject(_omit(values, ['componentDoceboId'])), entityIds, programId,
      );
      if (response?.data?.success) {
        setSuccess(true);
        setIsLoading(false);
        populateComponentsMetadata(doceboLpId);
        setErrorMsg(response?.data?.message);
        setSnackBarOpen(true);
        callback();
      } else {
        setErrorMsg(get(response[0], 'data.error', 'unable to update'));
        setSnackBarOpen(true);
        setIsLoading(false);
      }
    } catch (e) {
      if (e.response.status === 409) {
        setIsLoading(false);
        setIsConflictErrorModalOpen(true);
        callback();
      } else {
        setIsLoading(false);
        setSnackBarOpen(true);
        setErrorMsg(get(e.response.data, 'data.error', e?.response?.data?.message || 'unable to update data'));
      }
      console.error(e);
    }
  };

  useEffect(() => {
    const fetchDoceboLpId = async () => {
      try {
        const response = await getDoceboId(programId);
        if (response && response.success) {
          const doceboID = get(_find(response.data, { type: 'learning_plan' }), 'rows[0].id', '');
          setDoceboLpId(doceboID);
        } else {
          setErrorMsg(get(response, 'data.error', 'unable to find docebo id'));
          setSnackBarOpen(true);
        }
      } catch (e) {
        console.error(e);
      }
    };

    fetchDoceboLpId();
  }, [programId]);

  useEffect(() => {
    const populateActiveLanguages = async () => {
      try {
        const response = await fetchActiveLanguages();
        const activeLanguagesResult = get(response, 'data.languages', []);
        const abc = Object.keys(activeLanguagesResult).map(
          (language) => ({
            value: activeLanguagesResult[language].code,
            label: activeLanguagesResult[language].description,
          }),
        );
        setActiveLanguages(abc);
      } catch (e) {
        console.error(e);
      }
    };
    populateActiveLanguages();
    populateMetadataDetails(doceboLpId);
  }, [populateMetadataDetails, doceboLpId]);

  return (
    <Paper className={classes.wrapper}>
      {showAlert && (
        <AlertBar open={showAlert} text={alertText} />
      )}
      <MetaDataHeader shouldShow={false} />
      <Container className={classes.innerWrapper} disableGutters maxWidth={false}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              sx={{
                '& .MuiTab-root.Mui-selected': {
                  color: MCKINSEY_BLUE,
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: MCKINSEY_BLUE,
                },
              }}
              textColor="primary"
              indicatorColor="primary"
              aria-label="basic tabs example"
            >
              {filteredTabs.map(
                (tab) => (
                  <Tab
                    key={tab.label}
                    label={tab.label}
                    value={tab.label}
                    id={`meta-tab-${tab.label}`}
                    aria-controls={`simple-tabpanel-${tab.label}`}
                  />
                ),
              )}
            </Tabs>
          </Box>
          <TabPanel value={value} index="General">
            <MetaDataGeneral
              activeLanguages={activeLanguages}
              metadataReport={metadataReport}
              onUpdateMetaInfo={onUpdateMetaInfo}
              metaData={metaData}
              fetchMetadataActivity={fetchMetadataActivity}
              onDownloadLog={onDownloadLog}
            />
          </TabPanel>
          <TabPanel value={value} index="Users">
            <MetaDataUsers
              downloadFile={downloadUserMetadata}
              match={match}
              transactionIds={userTransactionIds}
              downloadXlsTransId={downloadXlsTransId}
              getUserMetadata={getUserMetadata}
              uploadXlsx={uploadXlsx}
              getProgress={getProgress}
              getReport={getReport}
              onDownloadLog={onDownloadLog}
              fetchProgramMetadata={fetchProgramMetadata}
              settingAlertText={settingAlertText}
            />
          </TabPanel>
          <TabPanel value={value} index="Courses & ILT">
            <MetaDataCourses
              componentsMetaData={componentsMetaData}
              fetchMetadataCourses={
                (fromDocebo) => populateComponentsMetadata(doceboLpId, fromDocebo)
              }
              onUpdateMetadata={onUpdateMetadata}
              bulkUpdateMetadata={bulkUpdateMetadata}
              isLoading={isLoading}
              doceboLpId={doceboLpId}
            />
          </TabPanel>
          <TabPanel value={value} index="Enrollment">
            <MetaDataEnrollment
              doceboLpId={doceboLpId}
              onUpdateEnrollmentInfo={onUpdateEnrollmentInfo}
            />
          </TabPanel>
        </Box>
      </Container>
      <ConflictErrorModal open={isConflictErrorModalOpen} onClose={onConflictModalClose} />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isSnackBarOpen}
        autoHideDuration={6000}
        onClose={onAlertClose}
      >
        <Alert
          className={classes.alertSnackBar}
          onClose={onAlertClose}
          severity={isSuccess ? 'success' : 'error'}
        >
          {errorMsg}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

MetaDataManagement.defaultProps = {
  qs: '',
};

MetaDataManagement.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.any,
    search: PropTypes.string,
  }).isRequired,
  programMetadata: PropTypes.shape({
    config_id: PropTypes.string,
  }).isRequired,
  qs: PropTypes.string,
};

export default MetaDataManagement;
