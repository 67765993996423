import React, {
  useState, useEffect, useMemo, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import _debounce from 'lodash.debounce';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@mui/material/Checkbox';
import {
  Table, TableContainer, TableBody, TableRow, TableCell,
} from '@mui/material';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import EnhancedTableHeader from '../Table/EnhancedTableHeader';
import LoadMoreComponent from '../common/LoadMore';
import CustomPopover from '../common/CustomPopover';
import ThreeDotButton from '../common/ThreeDotButton';
import NoDataComponent from '../common/NoDataComponent';
import LabledTextField from '../common/LabledTextField';
import NoData from '../../assets/img/no-data.svg';
import NoSearchedUserData from '../../assets/img/noChannel.svg';
import HyperlinkButton from '../common/HyperlinkButton';
import {
  getCohortUserList,
} from '../../containers/WaveManagementPage/api';
import {
  USER_WAVE_TABLE_HEAD_ATTRIBUTES, ROUTE_REPORT, AUTO_ENROLLMENT,
  WAVE_MANAGEMENT,
} from '../../constants';
import LoadingCircle from '../common/LoadingCircle/LoadingCircle';
import {
  LIGHT_MEDIUM_GREY, MCKINSEY_BLUE, MODERATE_DARK_GREY, SWITCH_GREY,
} from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  tableHeadingSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  tableBtnSection: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    alignItems: 'center',
  },
  inputSearchField: {
    width: '22rem',
  },
  editMetadataBtn: {
    marginRight: '1rem',
    '&:disabled': {
      borderColor: SWITCH_GREY,
    },
  },
  table: {
    '& .MuiTableCell-root': {
      padding: '0.7rem',
    },
  },
  nouserSection: {
    boxShadow: '0 0 1px 0 rgb(5 28 44 / 20%), 0 2px 4px -1px rgb(5 28 44 / 20%)',
    padding: '2rem 0',
    display: 'flex',
    flexDirection: 'column',
  },
  noUserContent: {
    padding: '0 2rem',
  },
  userEnrollMentButton: {
    fontSize: '1.5rem',
  },
  checkbox: {
    height: '1.125rem',
    color: LIGHT_MEDIUM_GREY,
    opacity: 0.6,
    margin: '0 2.5rem 0 0.75rem',
  },
  checkboxChecked: {
    opacity: 1,
  },
  showDots: {
    width: '.85rem',
    height: '.85rem',
    background: MCKINSEY_BLUE,
    borderRadius: '5px',
  },
  inputBox: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '0px',
      height: '31.91px',
    },
    '& input': {
      fontSize: '1rem',
      color: MODERATE_DARK_GREY,
    },
  },
  secondaryTextCls: {
    fontSize: '2.25rem',
  },
  checkBoxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  untaggedBtn: {
    justifyContent: 'end',
  },
}));

const fetchCohortUserList = async (
  waveId,
  userListData,
  setUserListData,
  hasMore,
  setHasMore,
  currentPage,
  setCurrentPage,
  setIsLoading,
  setTotalUserList,
  setSearchedUser,
  pageSize,
  searchValue,
) => {
  if (!hasMore) return;
  try {
    if (!searchValue) {
      setIsLoading(true);
    }

    const nextPage = currentPage + 1;
    const result = await getCohortUserList(
      waveId,
      nextPage,
      pageSize,
      searchValue,
    );
    if (!result.data.success) throw new Error();
    const newuserList = result?.data?.data?.users;
    const totalUsers = result?.data?.data?.cohort_user_count;
    const searchuserList = result?.data?.data?.total_rows;
    batchUpdates(() => {
      setUserListData(userListData.concat(newuserList));
      setHasMore(result.data.data.has_more);
      setCurrentPage(nextPage);
      setTotalUserList(totalUsers);
      setSearchedUser(searchuserList);
      setIsLoading(false);
    });
  } catch (e) {
    batchUpdates(() => {
      setHasMore(true);
      setIsLoading(false);
    });
  }
};

const UserListOnCohort = ({
  wave_name, programId, waveId, editMetadata,
  refreshUserList, setRefreshUserList, downloadUntaggedUsers,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const loadButtonRef = useRef(null);
  const tableRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [userListData, setUserListData] = useState([]);
  const [totalUserList, setTotalUserList] = useState(0);
  const [searchedUser, setSearchedUser] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [prevSelectedUser, setPrevSelectedUsers] = useState([]);
  const [headerCheckBox, setHeaderCheckBox] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [tableScrollTop, setTableScrollTop] = useState(0);
  const pageSize = 50;
  const goToUserEnrollMent = () => {
    history.push(`/${ROUTE_REPORT}/${programId}/${AUTO_ENROLLMENT}`);
  };

  useEffect(() => {
    batchUpdates(() => {
      setCurrentPage(0);
      setUserListData([]);
      setHasMore(false);
      setIsLoading(true);
    });
    const populateUserList = async () => {
      await fetchCohortUserList(
        waveId,
        [],
        setUserListData,
        true,
        setHasMore,
        0,
        setCurrentPage,
        setIsLoading,
        setTotalUserList,
        setSearchedUser,
        pageSize,
        '',
      );
    };
    if (waveId) {
      populateUserList();
    }
  }, [waveId]);

  useEffect(() => {
    if (refreshUserList) {
      fetchCohortUserList(
        waveId,
        [],
        setUserListData,
        true,
        setHasMore,
        0,
        setCurrentPage,
        setIsLoading,
        setTotalUserList,
        setSearchedUser,
        pageSize,
        searchValue,
      );
      batchUpdates(() => {
        setPrevSelectedUsers(selectedUsers);
        setSelectedUsers([]);
        setHeaderCheckBox(false);
      });
      setTimeout(() => {
        setPrevSelectedUsers([]);
        setRefreshUserList(false);
      }, 5000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshUserList]);

  useEffect(() => {
    if (currentPage > 1 && loadButtonRef.current) {
      loadButtonRef.current.scrollIntoView();
      tableRef.current.scrollTo({ top: tableScrollTop });
    }
  }, [currentPage, tableScrollTop]);

  const onLoadMore = async () => {
    setTableScrollTop(tableRef.current.scrollTop);
    await fetchCohortUserList(
      waveId,
      userListData,
      setUserListData,
      hasMore,
      setHasMore,
      currentPage,
      setCurrentPage,
      setIsLoading,
      setTotalUserList,
      setSearchedUser,
      pageSize,
      searchValue,
      window.pageYOffset,
    );
  };

  const goToUploadMetadata = () => {
    history.push(
      `/${ROUTE_REPORT}/${programId}/${WAVE_MANAGEMENT}/${waveId}/userMetaData`,
    );
  };

  const editsingerUserData = (rowData) => {
    setSelectedUsers([rowData]);
    editMetadata([rowData]);
  };

  const handleChange = (ev, userData) => {
    if (ev.target.checked) {
      const updatedArray = [...selectedUsers, userData];
      setSelectedUsers(updatedArray);
      if (updatedArray.length === userListData.length) {
        setHeaderCheckBox(true);
      }
    } else {
      const filteredArray = selectedUsers.filter((item) => item.user_name !== userData.user_name);
      setSelectedUsers(filteredArray);
      setHeaderCheckBox(false);
    }
  };

  const selectAllRow = (checked) => {
    if (checked) {
      setSelectedUsers(userListData);
    } else {
      setSelectedUsers([]);
    }
  };

  const searchUser = useCallback((value) => {
    fetchCohortUserList(
      waveId,
      [],
      setUserListData,
      true,
      setHasMore,
      0,
      setCurrentPage,
      setIsLoading,
      setTotalUserList,
      setSearchedUser,
      pageSize,
      value,
    );
  }, [waveId]);

  const debouncedSearchHandler = useMemo(
    () => _debounce((value) => searchUser(value), 1000),
    [searchUser],
  );

  const handleSearch = (searchVal) => {
    setSearchValue(searchVal);
    debouncedSearchHandler(searchVal.trim());
  };

  const clearSearch = () => {
    setSearchValue('');
    debouncedSearchHandler('');
  };

  if (isLoading) return <LoadingCircle />;
  return (
    <>
      {totalUserList
        ? (
          <>
            <Box className={classes.tableHeadingSection}>
              {
                searchValue ? <Typography variant="h1">{`Showing Results: ${searchedUser} of ${totalUserList} users`}</Typography>
                  : <Typography variant="h1">{`${searchedUser} users in this cohort`}</Typography>
              }
              <HyperlinkButton
                onClick={downloadUntaggedUsers}
              >
                Download Untagged Users
              </HyperlinkButton>
            </Box>
            <Box className={classes.tableBtnSection}>
              <Box className={classes.inputSearchField}>
                <LabledTextField
                  label="Search"
                  placeholder="Search by Name/User Name"
                  className={classes.inputBox}
                  inputProps={{
                    name: 'search_username',
                    className: classes.input,
                    'data-testid': 'search_username',
                    InputProps: {
                      endAdornment: !searchValue ? (<SearchIcon fontSize="small" />)
                        : (<CloseIcon data-testid="clearSearch" onClick={() => clearSearch()} style={{ cursor: 'pointer' }} fontSize="small" />),
                    },
                  }}
                  value={searchValue}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </Box>
              {
              !!searchedUser && (
              <Box className={classes.btnSection}>
                <Button
                  className={classes.editMetadataBtn}
                  disabled={!selectedUsers.length}
                  color="primary"
                  variant="outlined"
                  onClick={() => editMetadata(selectedUsers)}
                >
                  Edit Metadata
                </Button>
                <Button color="primary" variant="contained" onClick={goToUploadMetadata}>
                  Download/Upload Metadata XLS File
                </Button>
              </Box>
              )
              }
            </Box>
            {
              (searchedUser) ? (
                <TableContainer style={{ maxHeight: 400, marginBottom: '2rem' }} ref={tableRef}>
                  <Table aria-label="User cohort table" className={classes.table} padding="normal" stickyHeader>
                    <EnhancedTableHeader
                      headCells={USER_WAVE_TABLE_HEAD_ATTRIBUTES}
                      order="asc"
                      orderBy="name"
                      checkboxEnable
                      checkboxHandler={selectAllRow}
                      defaultCheckBoxValue={headerCheckBox}
                      onRequestSort={() => {}}
                    />
                    <TableBody className={classes.wrapperTableBody}>
                      {userListData && userListData.map((row) => (
                        <TableRow
                          key={row.user_name}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="center">
                            <Box className={classes.checkBoxContainer}>
                              {
                                !!prevSelectedUser.length
                                && prevSelectedUser.map((item) => item.id).includes(row.id)
                                && (<span className={classes.showDots} />)
                              }
                              <Checkbox
                                className={classes.checkbox}
                                checked={
                                selectedUsers.map((item) => item.user_name).includes(row.user_name)
                                }
                                onChange={(e) => handleChange(e, row)}
                                name="checkAll"
                                color="primary"
                                data-testid="selectrow"
                                classes={{
                                  checked: classes.checkboxChecked,
                                }}
                              />
                            </Box>

                          </TableCell>
                          <TableCell>
                            <Typography variant="body1">{`${row.firstname} ${row.lastname}`}</Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body1">
                              {row.user_name}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body1">
                              {row.metadata_json.role || ''}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body1">
                              {row.metadata_json.ec_custom_user_field_1 || ''}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body1">
                              {row.metadata_json.ec_custom_user_field_2 || ''}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body1">
                              {row.metadata_json.ec_custom_user_field_3 || ''}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="body1">
                              {row.metadata_json.ec_custom_user_field_4 || ''}
                            </Typography>
                          </TableCell>
                          <TableCell align="left" data-testid="ThreeDotButtonCell">
                            <CustomPopover
                              list={[
                                {
                                  title: 'Edit Metadata',
                                  onClick: () => editsingerUserData(row),
                                },
                              ]}
                              button={ThreeDotButton}
                              additionalClass={classes.customPopoverList}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )
                : (
                  <NoDataComponent
                    imgSrc={NoSearchedUserData}
                    primaryText="Oops!"
                    secondaryText="Could not find the user you were looking for."
                    secondaryTextCls={classes.secondaryTextCls}
                  />
                )
            }
            {
              !!searchedUser && (
              <LoadMoreComponent
                totalItems={totalUserList ?? 0}
                onLoadMore={onLoadMore}
                loadMoreText="Users"
                itemsShown={userListData ? userListData.length : 0}
                hasMore={hasMore}
                isLoading={isLoading}
                btnRef={loadButtonRef}
              />
              )
            }

          </>
        )
        : (
          <>
            <Box className={`${classes.tableHeadingSection} ${classes.untaggedBtn}`}>
              <HyperlinkButton
                onClick={downloadUntaggedUsers}
              >
                Download Untagged Users
              </HyperlinkButton>
            </Box>
            <Box className={classes.nouserSection}>
              <NoDataComponent
                imgSrc={NoData}
                primaryText="There are currently no users enrolled in this cohort."
              />
              <Typography className={classes.noUserContent} align="center" variant="subtitle1">
                Please add users through
                {' '}
                <HyperlinkButton
                  className={classes.userEnrollMentButton}
                  onClick={() => goToUserEnrollMent()}
                >
                  User Enrollment
                </HyperlinkButton>
                {' '}
                and select the cohort
                {' '}
                {wave_name}
                {' '}

              </Typography>
            </Box>
          </>
        ) }
    </>
  );
};
UserListOnCohort.propTypes = {
  wave_name: PropTypes.string.isRequired,
  programId: PropTypes.number.isRequired,
  waveId: PropTypes.string.isRequired,
  editMetadata: PropTypes.func.isRequired,
  refreshUserList: PropTypes.bool.isRequired,
  setRefreshUserList: PropTypes.func.isRequired,
  downloadUntaggedUsers: PropTypes.func.isRequired,
};
export default UserListOnCohort;
