import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import _map from 'lodash/map';
import _find from 'lodash/find';
import { useHistory } from 'react-router-dom';
import { ACCORDION_GREY } from '../../stylesheets/colors';
import ConfirmationModal from '../common/ConfirmationModal';
import FormattedTypography from '../common/FormattedTypography';
import CustomSelect from '../common/CustomSelect';
import OopsStopIcon from '../../assets/icons/oops-stop-icon.svg';
import InfoTooltip from '../common/InfoTooltip';
import {
  ROUTE_REPORT,
  CONTENT_MANAGEMENT,
} from '../../constants';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  infoContentText: {
    color: ACCORDION_GREY,
  },
  modalStyle: {
    minWidth: '70rem',
  },
  dialogContent: {
    width: '100%',
  },
  headText: {
    fontWeight: '900',
  },
  singleItemWrapper: {
    width: '80%',
    marginBottom: '3rem',
  },
  paperWrapper: {
    padding: '1rem',
    display: 'flex',
    minHeight: '10rem',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  dropdownOptions: {
    display: 'flex',
    flexDirection: 'column',
  },
  optionTitle: {
    fontSize: ' 1rem',
  },
  optionSubtitle: {
    fontSize: ' 0.875rem',
  },
  width80: {
    width: '80%',
  },
  releaseSelectWrapper: {
    marginBottom: '3rem',
  },
  modalHeight: {
    minHeight: '400px',
  },
  noDataImage: {
    display: 'block',
    margin: '0 auto',
  },
  singleBoxWrapper: {
    padding: '1rem 0',
  },
  helperTextWrapper: {
    padding: '0.5rem 0',
  },
}));

const AddProjectAndReleaseDetails = ({
  open,
  onClose,
  onConfirm,
  elucidatRelease,
  programId,
  courseId,
  courseName,
  scormName,
}) => {
  const classes = useStyles();
  const [projectOptions, setProjectOptions] = useState([]);
  const [releaseOptions, setReleaseOptions] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState({});
  const [selectedReleaseId, setSelectedReleaseId] = useState({});
  const history = useHistory();

  const onProjectIdChange = (project) => {
    const releaseList = _find(elucidatRelease, { project_code: project.value })?.releases;
    if (releaseList?.length > 0) {
      const elucidatReleaseList = _map(releaseList, (obj) => ({
        label: (
          <Box className={classes.dropdownOptions}>
            <Typography className={classes.optionTitle}>{obj?.release_code}</Typography>
            <Typography className={classes.optionSubtitle}>
              {obj?.description}
            </Typography>
          </Box>
        ),
        value: obj?.release_code,
        releaseId: obj?.release_code,
        releaseDescription: obj?.description,
        releaseVersion: obj?.version_number,
        lastReleased: obj?.modified,
      }));
      setSelectedProjectId(project);
      setReleaseOptions(elucidatReleaseList);
    }
  };

  useEffect(() => {
    if (elucidatRelease.length > 0) {
      const elucidatProjectList = _map(elucidatRelease, (obj) => ({
        projectId: obj?.project_code,
        projectName: obj?.name,
        label: (
          <Box className={classes.dropdownOptions}>
            <Typography className={classes.optionTitle}>{obj?.project_code}</Typography>
            <Typography className={classes.optionSubtitle}>
              {obj?.name}
            </Typography>
          </Box>
        ),
        value: obj?.project_code,
      }));
      if (elucidatProjectList.length === 1) {
        onProjectIdChange(elucidatProjectList[0]);
      }
      setProjectOptions(elucidatProjectList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elucidatRelease]);

  const SingleItemComponent = useCallback((items, helperText = '') => (
    <Box className={classes.singleItemWrapper}>
      <Paper className={classes.paperWrapper}>
        {
          items.length > 0 && items.map((item) => (
            <Box key={item?.title} className={classes.singleBoxWrapper}>
              <Typography className={classes.infoContentText}>
                {item?.title}
                :
              </Typography>
              <Typography>
                {item?.value}
              </Typography>
            </Box>
          ))
        }
      </Paper>
      {helperText.length > 0
        && <Typography className={classes.helperTextWrapper}>{helperText}</Typography>}
    </Box>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  const isHavingProjectId = elucidatRelease.length > 0 && _find(elucidatRelease, 'project_code');

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      modalStyle={classes.modalStyle}
      dialogContentClass={classes.dialogContent}
      minHeightClass={classes.modalHeight}
      title={isHavingProjectId && (
        <FormattedTypography
          variant="subtitle2"
          body="Select the Project and Release details"
          subHeading="Selections will be saved against this SCORM package for any re-release attempts in the future"
          dense
        />
      )}
      content={(
        <Container className={classes.wrapper}>
          {isHavingProjectId ? (
            <>
              {courseName.length > 0 && (
                <>
                  {
                    SingleItemComponent([
                      { title: 'Component', value: courseName },
                      { title: 'Training Material', value: scormName },
                    ])
                  }
                </>
              )}
              {
                elucidatRelease.length > 1 ? (
                  <Box className={`${classes.width80} ${classes.releaseSelectWrapper}`}>
                    <InfoTooltip title="Values populated from Elucidat Project ID field in Settings & Details tab">
                      <CustomSelect
                        options={projectOptions}
                        placeholder="Select an Elucidat Project ID"
                        label="Elucidat Project ID"
                        onChange={(v) => onProjectIdChange(v)}
                      />
                    </InfoTooltip>
                    <Typography className={classes.helperTextWrapper}>
                      This project ID was pulled from the component metadata
                    </Typography>
                  </Box>
                ) : (
                  <>
                    {
                      SingleItemComponent([
                        { title: 'Project ID', value: elucidatRelease[0]?.project_code },
                        { title: 'Project Name', value: elucidatRelease[0]?.name },
                      ], 'This project ID was pulled from the component metadata')
                    }
                  </>
                )
              }
              <Box className={`${classes.width80} ${classes.releaseSelectWrapper}`}>
                <CustomSelect
                  isOptionsNotLoading
                  options={releaseOptions}
                  placeholder="Select a release ID"
                  label="Select Release ID"
                  onChange={(releaseObj) => setSelectedReleaseId(releaseObj)}
                />
              </Box>
            </>
          ) : (
            <Box>
              <img src={OopsStopIcon} alt="no-data" className={classes.noDataImage} />
              <FormattedTypography
                variant="subtitle2"
                body="Oops! No Elucidat Project ID found in the component metadata"
                subHeading="Please add a valid ID in the additional fields section of the Settings & Details tab"
                dense
              />
            </Box>
          )}
        </Container>
      )}
      actions={
        isHavingProjectId ? (
          <Button
            color="primary"
            variant="contained"
            disabled={
              Object.keys(selectedProjectId).length === 0
              || Object.keys(selectedReleaseId).length === 0
            }
            onClick={() => { onConfirm(selectedProjectId, selectedReleaseId); }}
          >
            Save Details
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              history.push(`/${ROUTE_REPORT}/${programId}/${CONTENT_MANAGEMENT}?feature=view-course&id=${courseId}&tab=settings`);
            }}
          >
            Go to the Settings & Details tab
          </Button>
        )
      }
    />
  );
};

AddProjectAndReleaseDetails.defaultProps = {
  courseName: '',
  scormName: '',
};

AddProjectAndReleaseDetails.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  elucidatRelease: PropTypes.arrayOf(PropTypes.object).isRequired,
  programId: PropTypes.string.isRequired,
  courseId: PropTypes.number.isRequired,
  courseName: PropTypes.string,
  scormName: PropTypes.string,
};

export default AddProjectAndReleaseDetails;
