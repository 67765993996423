import React, {
  useRef, useState, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import axios, { CancelToken, isCancel } from 'axios';
import AddNewScormWizard from './AddNewScormWizard';
import ScormFileUpload from './ScormFileUpload';
import ProgressFailedModal from './ProgressFailedModal';
import { generatePresignedUrl } from '../../../containers/ContentManagement/apis';
import { getErrorMessage } from '../../../helpers/apiHelper';
import useNotifications from '../../../hooks/useNotifications';
import FileUploadCancelConfirmation from './FileUploadCancelConfirmation';

const AddNewScormModal = ({
  addScormModal, setAddScormModal, programId, courseId, code, getCourseDetails,
  setNewScorm, addScormTmTransaction, setIsScormAdding,
}) => {
  const [scormFileUploading, setScormFileUploading] = useState(false);
  const [scormFileUploadProgress, setScormFileUploadProgress] = useState(0);
  const [progressFailed, setProgressFailed] = useState(false);
  const [scormFileName, setScormFileName] = useState('');
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [confirmCancelFileUpload, setConfirmCancelFileUpload] = useState(false);

  const cancelFileUpload = useRef(null);
  const { notifyError } = useNotifications();

  const openAddScorm = () => {
    setProgressFailed(false);
    setAddScormModal(true);
  };

  const showProgressErrorModal = useCallback(() => {
    setProgressFailed(true);
  }, [setProgressFailed]);

  const generateFormDataForFile = useCallback(
    (fileName) => {
      const formData = new FormData();
      formData.append('filename', fileName);
      return formData;
    },
    [],
  );

  const fetchPreSignedUrl = useCallback(async (file) => {
    try {
      const presignedUrlData = await generatePresignedUrl(
        generateFormDataForFile(file?.name),
      );
      return presignedUrlData;
    } catch (e) {
      notifyError(getErrorMessage(e));
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateFormDataForFile]);

  const getConfig = (file) => ({
    headers: {
      'Content-Type': file?.type,
    },
    onUploadProgress(progressEvent) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setScormFileUploadProgress(percentCompleted);
    },
    // eslint-disable-next-line no-return-assign
    cancelToken: new CancelToken((cancel) => cancelFileUpload.current = cancel),
  });

  const getFileUploadProgress = useCallback(async (presignedUrl = '', file) => {
    try {
      setIsFileUploaded(false);
      setScormFileUploading(true);
      setScormFileName(file?.name);
      const axiosResponse = await axios.put(presignedUrl, file, getConfig(file));
      if (axiosResponse.status === 200) {
        setScormFileUploading(false);
        setIsFileUploaded(true);
      }
    } catch (e) {
      if (isCancel(e)) {
        console.log(e.message);
      } else {
        showProgressErrorModal();
      }
      setAddScormModal(false);
      setScormFileUploading(false);
    } finally {
      setScormFileUploadProgress(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPresignedUrl = useCallback(async (fileObj) => {
    const presignedUrlData = await fetchPreSignedUrl(fileObj);
    if (presignedUrlData?.data?.presigned_url?.length > 0) {
      getFileUploadProgress(presignedUrlData?.data?.presigned_url, fileObj);
    }
  }, [fetchPreSignedUrl, getFileUploadProgress]);

  useEffect(() => {
    if (addScormModal) {
      setIsFileUploaded(false);
    }
  }, [addScormModal]);

  const confirmCancelUpload = () => {
    setConfirmCancelFileUpload(true);
  };

  const confirmCancelFileUploading = () => {
    if (cancelFileUpload.current) {
      cancelFileUpload.current('File Upload Canceled');
    }
    setScormFileUploading(false);
    setConfirmCancelFileUpload(false);
  };

  return (
    <>
      <AddNewScormWizard
        open={addScormModal}
        onClose={() => setAddScormModal(false)}
        programId={programId}
        courseId={courseId}
        refetchTms={getCourseDetails}
        setNewScorm={setNewScorm}
        addScormTmTransaction={addScormTmTransaction}
        code={code}
        setIsScormAdding={setIsScormAdding}
        getPresignedUrl={getPresignedUrl}
        isFileUploaded={isFileUploaded}
      />
      {scormFileUploading && (
        <ScormFileUpload
          progress={scormFileUploadProgress}
          open={scormFileUploading}
          fileName={scormFileName}
          cancelFileUpload={confirmCancelUpload}
        />
      )}
      {progressFailed && (
        <ProgressFailedModal
          open={progressFailed}
          onClose={() => setProgressFailed(false)}
          fileName={scormFileName}
          openAddScorm={openAddScorm}
        />
      )}
      {confirmCancelFileUpload && (
        <FileUploadCancelConfirmation
          open={confirmCancelFileUpload}
          onConfirm={confirmCancelFileUploading}
          onResumeUploading={() => setConfirmCancelFileUpload(false)}
        />
      )}
    </>
  );
};

AddNewScormModal.propTypes = {
  addScormModal: PropTypes.bool.isRequired,
  setAddScormModal: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  getCourseDetails: PropTypes.func.isRequired,
  setNewScorm: PropTypes.func.isRequired,
  addScormTmTransaction: PropTypes.func.isRequired,
  setIsScormAdding: PropTypes.func.isRequired,
};

export default AddNewScormModal;
