import {
  USERS_FETCH_SUCCESS,
  USERS_FETCH_FAILED,
  SET_LOADING,
  CLEAR_ALERTBAR,
  MARK_FOR_DELETION,
  MARK_FOR_DELETION_SUCCESS,
  MARK_FOR_DELETION_FAILURE,
  CLEAR_TRANSACTION_ID,
  DELETE_MARKED_USER,
  DELETE_MARKED_USER_SUCCESS,
  DELETE_MARKED_USER_FAILURE,
  SET_TRANSACTION_ID,
  SELECTED_BULK_ACTION,
} from '../../constants';
import { snackBarInitialState } from '../../../constants';
import { getErrorMessage } from '../../../helpers/apiHelper';

const initialState = {
  isLoading: true,
  result: { total_rows: 0, data: [], success: true },
  snackbarObj: snackBarInitialState,
  transaction_id: null,
  message: '',
  selectedBulkAction: '',
};

function onFetchSuccess(state, { results }) {
  return {
    ...state,
    result: results,
    isLoading: false,
  };
}

function onFetchFailure(state, { response }) {
  const { message } = response;
  return {
    ...state,
    isLoading: false,
    snackbarObj: { open: true, severity: 'error', message: message || getErrorMessage(message) },
  };
}

function setLoading(state, { response }) {
  return {
    ...state,
    isLoading: response,
  };
}

function clearSnackbar(state) {
  return {
    ...state,
    snackbarObj: snackBarInitialState,
  };
}

function onUpdateState(state) {
  return {
    ...state,
    isLoading: true,
    message: '',
    transaction_id: null,
  };
}

function markDeletionSuccess(state, { results }) {
  return {
    ...state,
    isLoading: false,
    message: results.message,
    transaction_id: results.transaction_id,
  };
}

function clearTransactionId(state) {
  return {
    ...state,
    isLoading: false,
    message: '',
    transaction_id: null,
  };
}

function setTransactionId(state, { data }) {
  return {
    ...state,
    transaction_id: data.transaction_id,
  };
}

function onDeleteUsersSuccess(state, { results }) {
  return {
    ...state,
    isLoading: false,
    transaction_id: results.transaction_id,
  };
}

function onSelectBulkAction(state, { action }) {
  return {
    ...state,
    selectedBulkAction: action,
  };
}

export default function platformUsersData(state = initialState, action = {}) {
  switch (action.type) {
    case CLEAR_ALERTBAR:
      return clearSnackbar(state, action);
    case SET_LOADING:
      return setLoading(state, action);
    case USERS_FETCH_SUCCESS:
      return onFetchSuccess(state, action);
    case USERS_FETCH_FAILED:
      return onFetchFailure(state, action);
    case MARK_FOR_DELETION:
    case DELETE_MARKED_USER:
      return onUpdateState(state, action);
    case MARK_FOR_DELETION_SUCCESS:
      return markDeletionSuccess(state, action);
    case MARK_FOR_DELETION_FAILURE:
      return onFetchSuccess(state, action);
    case CLEAR_TRANSACTION_ID:
      return clearTransactionId(state);
    case SET_TRANSACTION_ID:
      return setTransactionId(state, action);
    case DELETE_MARKED_USER_SUCCESS:
      return onDeleteUsersSuccess(state, action);
    case DELETE_MARKED_USER_FAILURE:
      return onFetchFailure(state, action);
    case SELECTED_BULK_ACTION:
      return onSelectBulkAction(state, action);
    default:
      return state;
  }
}
