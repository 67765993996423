import React, {
  useState, useEffect, useMemo, useRef, memo,
} from 'react';
import PropTypes from 'prop-types';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import _debounce from 'lodash.debounce';
import {
  Box, Paper, CircularProgress, Container,
} from '@mui/material';
import Button from '@material-ui/core/Button';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import LabledTextField from '../common/LabledTextField';
import MasterDataTable from './MasterDataTable';
import Header from '../common/Header';
import AddEditSkillModal from './AddEditSkillModal';
import DeleteModal from './DeleteModal';
import LoadingCircle from '../common/LoadingCircle/LoadingCircle';
import NoDataComponent from '../common/NoDataComponent';
import NoSearchedUserData from '../../assets/img/noChannel.svg';
import { downloadSkillMaster } from '../../containers/common/apis';
import MappedCohortsModal from './MappedCohortsModal';

import {
  SKILL_MASTERDATA_TABLE_HEAD_ATTRIBUTES,
} from '../../constants';

const SkillPage = ({
  skillMasterData, updateSkill, alertMessage, deleteSkill,
  loadMoreData, isAdmin, addNewSkill, classes, deleteSkillResetRequest,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [editModal, setEditModal] = useState(false);
  const [selectedPortfolioRow, setSelectedPortfolioRow] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [updatedId, setUpdatedId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableScrollTop, setTableScrollTop] = useState(0);
  const [isNewSkill, setIsNewSkill] = useState(false);
  const skillTableData = skillMasterData?.result?.data ?? [];
  const totalRows = skillMasterData?.result?.total_rows ?? 0;
  const hasMore = skillMasterData?.result?.has_more ?? false;
  const tableRef = useRef(null);
  const [pickedRow, setPickedRow] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [mappedCohortsModal, setMappedCohortsModal] = useState(false);
  const [mappedCohortsData, setMappedCohortsData] = useState([]);

  useEffect(() => {
    setIsLoading(skillMasterData?.isLoading);
    if (!skillMasterData?.isLoading) {
      setSearchLoading(false);
    }
    setTimeout(() => {
      if (!skillMasterData?.isLoading && currentPage > 1 && tableRef.current && tableScrollTop) {
        tableRef.current.scrollTo({ top: tableScrollTop });
      }
    }, 200);
  }, [skillMasterData.isLoading, currentPage, tableScrollTop]);

  const showMappedCohortsModal = () => {
    setMappedCohortsModal(true);
  };

  useEffect(() => {
    if (skillMasterData?.mappedSkillLp?.length) {
      showMappedCohortsModal();
      setMappedCohortsData(skillMasterData.mappedSkillLp);
    }
  }, [skillMasterData.mappedSkillLp]);

  const hideDots = () => {
    setTimeout(() => {
      setUpdatedId(0);
    }, 6000);
  };

  useEffect(() => {
    if (skillMasterData.isError) {
      setIsSnackBarOpen(true);
    } else if (skillMasterData.statusMsg === 'skill has been updated') {
      batchUpdates(() => {
        setEditModal(false);
        setIsSnackBarOpen(true);
        setUpdatedId(skillMasterData.id);
        setCurrentPage(1);
        setTableScrollTop(0);
      });
      hideDots();
    } else if (skillMasterData.statusMsg === 'new skill has been created') {
      batchUpdates(() => {
        setIsNewSkill(false);
        setEditModal(false);
        setIsSnackBarOpen(true);
        setUpdatedId(skillMasterData.id);
        setCurrentPage(1);
        setTableScrollTop(0);
      });
      hideDots();
    } else if (skillMasterData.statusMsg === 'Skill has been deleted') {
      setCurrentPage(1);
      setTableScrollTop(0);
      setIsSnackBarOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillMasterData]);

  const debouncedSearchHandler = useMemo(
    () => _debounce((value) => loadMoreData(1, value), 1000),
    [loadMoreData],
  );

  const handleSearch = (searchVal) => {
    debouncedSearchHandler(searchVal);
    batchUpdates(() => {
      setSearchValue(searchVal);
      setCurrentPage(1);
      setTableScrollTop(0);
      setSearchLoading(true);
    });
  };

  const editSkillData = (row) => {
    batchUpdates(() => {
      setIsNewSkill(false);
      setEditModal(true);
      setSelectedPortfolioRow(row);
    });
  };

  const deleteSkillById = (row) => {
    setPickedRow(row);
    setDeleteModal(true);
  };

  const onUpdateSkillData = (data) => {
    updateSkill(data);
  };

  const onAddNewSkill = (data) => {
    addNewSkill(data);
  };

  const onLoadMore = () => {
    loadMoreData(currentPage + 1, searchValue);
    batchUpdates(() => {
      setCurrentPage(currentPage + 1);
      setTableScrollTop(tableRef.current.scrollTop);
    });
  };
  const preCloseModal = () => {
    batchUpdates(() => {
      setEditModal(false);
      setIsNewSkill(false);
    });
  };
  const preCloseDeleteModal = () => {
    setDeleteModal(false);
  };
  const onAlertClose = () => {
    setIsSnackBarOpen(false);
  };

  const onDeleteSkill = (data) => {
    deleteSkill(data);
    setDeleteModal(false);
  };

  const preCloseMappedCohortModal = () => {
    setMappedCohortsModal(false);
    setMappedCohortsData([]);
    deleteSkillResetRequest();
  };

  const onDownloadLog = async () => {
    try {
      await downloadSkillMaster(new Date());
    } catch (e) {
      // TODO: Show error toast
      console.error(e);
    }
  };

  const getSearchIcon = () => {
    if (searchLoading) {
      return <CircularProgress size="1.5rem" />;
    }
    if (!searchValue) {
      return <SearchIcon />;
    }
    return <CloseIcon data-testid="clearSearch" onClick={() => handleSearch('')} style={{ cursor: 'pointer' }} />;
  };

  const addNewSkillHandler = () => {
    batchUpdates(() => {
      setEditModal(true);
      setIsNewSkill(true);
    });
  };
  if (isLoading && !searchLoading) return <LoadingCircle />;
  return (
    <Paper elevation={0} className={classes.mainWrapper}>
      <Header
        heading="Skill Master Data"
        subHeading="Details of all the skills on the platform"
        className={classes.pageHeader}
      >

        <Button
          color="primary"
          variant="outlined"
          style={{ maxHeight: '3.25rem', marginLeft: '1rem' }}
          fontSize="medium"
          onClick={() => onDownloadLog()}
        >
          Download Skill Master Data
        </Button>
      </Header>
      <Box className={classes.wrapper}>
        <Container maxWidth={false} className={classes.middleWrapper}>
          <Box className={classes.portfolioHeading}>
            <Typography variant="h1">{`${totalRows} skills`}</Typography>
          </Box>
          <Box className={classes.portfolioTopSection}>
            <Box className={classes.inputSearchField}>
              <LabledTextField
                label="Search"
                placeholder="Search by Skill Code / Skill Name"
                className={classes.inputBox}
                inputProps={{
                  name: 'search_skill',
                  className: classes.input,
                  'data-testid': 'search_skill',
                  InputProps: {
                    endAdornment: getSearchIcon(),
                  },
                }}
                value={searchValue}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </Box>
            {
              isAdmin && (
                <Button disabled={false} color="primary" variant="contained" onClick={addNewSkillHandler}>
                  Add  Skill
                </Button>
              )
            }
          </Box>
          {
            totalRows ? (
              <MasterDataTable
                tabledata={skillTableData}
                tableColumn={SKILL_MASTERDATA_TABLE_HEAD_ATTRIBUTES}
                isAdmin={isAdmin}
                adminRestriction
                updatedId={updatedId}
                ref={tableRef}
                actionList={isAdmin ? [
                  {
                    title: 'Edit Skill',
                    handler: editSkillData,
                  },
                  {
                    title: 'Delete Skill',
                    handler: deleteSkillById,
                  },
                ] : []}
                loadMoreButton
                totalItems={totalRows}
                loadMoreText="Skills"
                hasMore={hasMore}
                isLoading={!!isLoading}
                onLoadMore={onLoadMore}
              />
            )
              : (
                !isLoading
                && (
                  <Box className={classes.noDataContent}>
                    <NoDataComponent
                      imgSrc={NoSearchedUserData}
                      primaryText="Oops!"
                      secondaryText="Could not find Skills you were looking for."
                      secondaryTextCls={classes.secondaryTextCls}
                    />
                  </Box>
                )
              )
          }

        </Container>
      </Box>
      {
        editModal
        && (
          <AddEditSkillModal
            open={editModal}
            onClose={preCloseModal}
            skillData={selectedPortfolioRow}
            onUpdateSkillData={onUpdateSkillData}
            onAddSkill={onAddNewSkill}
            isLoading={isLoading}
            isNewSkill={isNewSkill}
          />
        )
      }
      {
        deleteModal
        && (
          <DeleteModal
            open={deleteModal}
            onClose={preCloseDeleteModal}
            data={pickedRow}
            onConfirmDelete={onDeleteSkill}
            heading="Delete Skill"
            subHeading="You are about to delete skill "
            feature={pickedRow?.name}
            helperText="Please note, the skill once deleted cannot be restored back."
          />
        )
      }
      {
        mappedCohortsModal
        && (
          <MappedCohortsModal
            open={mappedCohortsModal}
            onClose={preCloseMappedCohortModal}
            data={mappedCohortsData}
            heading="This skill cannot be deleted as it is mapped to one or more Course(s)"
            subHeading="Skill is mapped to Course(s). Please try again"
            tableColumn1={{ id: 'course_code', label: 'Course Code' }}
            tableColumn2={{ id: 'program_config_id', label: 'Program Name' }}
          />
        )
      }
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isSnackBarOpen}
        autoHideDuration={6000}
        onClose={onAlertClose}
      >
        <Alert
          className={classes.alertSnackBar}
          onClose={onAlertClose}
          severity={alertMessage?.type}
        >
          {alertMessage?.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

SkillPage.propTypes = {
  skillMasterData: PropTypes.object.isRequired,
  updateSkill: PropTypes.func.isRequired,
  addNewSkill: PropTypes.func.isRequired,
  alertMessage: PropTypes.object.isRequired,
  loadMoreData: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  deleteSkill: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  deleteSkillResetRequest: PropTypes.func.isRequired,
};

export default memo(SkillPage, (prevProps, nextProps) => (
  prevProps.skillMasterData.isLoading === nextProps.skillMasterData.isLoading
  && prevProps.skillMasterData.isError === nextProps.skillMasterData.isError
  && prevProps.skillMasterData.id === nextProps.skillMasterData.id
  && prevProps.skillMasterData.message === nextProps.skillMasterData.message
  && prevProps.skillMasterData.page === nextProps.skillMasterData.page
  && prevProps.skillMasterData.searchText === nextProps.skillMasterData.searchText
  && prevProps.skillMasterData.result === nextProps.skillMasterData.result
  && prevProps.skillMasterData.statusText === nextProps.skillMasterData.statusText
  && prevProps.skillMasterData.message === nextProps.skillMasterData.message
  && prevProps.skillMasterData.statusMsg === nextProps.skillMasterData.statusMsg
  && prevProps.alertMessage === nextProps.alertMessage
));
