import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import CloseIcon from '@mui/icons-material/Close';
import { MCKINSEY_BLUE, MODERATE_LIGHT_GREY } from '../../../stylesheets/colors';
import {
  LTI_TABS, snackBarInitialState,
} from '../../../constants';

import Header from '../../common/Header';
import JournalsTab from './JournalsTab';
import CustomSnackbar from '../../common/CustomSnackbar';
import AlertBarWithAction from '../../common/AlertBarWithAction';
import useAlertBar from '../../../hooks/useAlertBar';

const useStyles = makeStyles({
  wrapper2: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  bodyWrapper2: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
    width: '85%',
    overflowY: 'scroll',
  },

  pageHeader: {
    padding: '2rem 4rem 2rem 2rem',
  },
  header: {
    borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
  },
});

const StyledTabs = withStyles({
  indicator: {
    backgroundColor: MCKINSEY_BLUE,
  },
})(Tabs);

const StyledTab = withStyles({
  root: {
    textTransform: 'none',
    minWidth: '0px',
  },
  selected: {
    borderBottom: '0px',
  },
})(Tab);

const LTIJournals = ({ match }) => {
  const classes = useStyles();
  const [snackbarObj, setSnackbarObj] = useState(snackBarInitialState);
  const feature = get(match, 'params.feature');
  const history = useHistory();

  const {
    labelText, variant, open, setAlertBarConfig, initialAlertBarConfig,
  } = useAlertBar();

  return (
    <Box className={classes.bodyWrapper2}>
      <Paper className={classes.wrapper2}>
        {open && (
        <AlertBarWithAction
          variant={variant}
          labelText={labelText}
          actionButtonIcon={<CloseIcon onClick={() => setAlertBarConfig(initialAlertBarConfig)} />}
        />
        )}
        <Header heading="All Journals" subHeading="Manage journals, pages and prompts across all Learning Plans" className={classes.pageHeader} />
        <Box style={{
          padding: '1rem 2rem 2rem 2rem', display: 'flex', flexDirection: 'column', height: '69.3vh', overflowY: 'scroll',
        }}
        >
          <Box className={classes.header}>
            <StyledTabs value={feature} onChange={(e, newVal) => { history.push(`/contentLibrary/lti/${newVal}`); }}>
              {LTI_TABS.map(({ value, label, isDisabled }) => (
                <StyledTab
                  key={value}
                  value={value}
                  label={label}
                  disabled={isDisabled}
                />
              ))}
            </StyledTabs>
          </Box>
          {feature === 'journals' ? <JournalsTab setSnackbarObj={setSnackbarObj} match={match} setAlertBarConfig={setAlertBarConfig} /> : null}
        </Box>
      </Paper>
      <CustomSnackbar snackbarObj={snackbarObj} setSnackbarObj={setSnackbarObj} />
    </Box>
  );
};

LTIJournals.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      platformType: PropTypes.string,
      programSubType: PropTypes.string,
      feature: PropTypes.string,
      id: PropTypes.number,
    }),
  }).isRequired,
};

export default LTIJournals;
