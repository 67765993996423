/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { Grid, Box } from '@mui/material';
import _map from 'lodash/map';
import { Typography } from '@material-ui/core';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import ContentDetailsBlock from '../ContentManagement/ContentDetailsBlock';
import TableRC from '../common/TableRC';
import { convertInDigits } from '../../helpers/formattingHelpers';
import LoadingCircle from '../common/LoadingCircle/LoadingCircle';
import InfoTooltip from '../common/InfoTooltip';

import EmptyComponentTitle from '../../assets/img/empty-component-title.svg';
import {
  CONTENT_TYPE_MAPPING, ROUTE_REPORT, CONTENT_MANAGEMENT,
} from '../../constants';
import {
  tableSort, getComparator, secondsToHms, getMaterialType, isScormUnassigned,
} from '../../helpers/utils';
import { MCKINSEY_BLUE } from '../../stylesheets/colors';
import LinkButton from '../common/LinkButton';

const useStyles = makeStyles({
  centerAllign: {
    display: 'flex',
    alignItems: 'center',

  },
  graphicImage: {
    '& img': {
      height: '1.75rem',
      width: '3.125rem',
    },
  },
  leftPadding: {
    paddingLeft: '1rem',
  },
  boldText: {
    fontWeight: 900,
    fontSize: '1.25rem',
  },
  removeMarginTop: {
    '&.MuiListItemText-root': {
      marginTop: '0px',
    },
  },
  linkButton: {
    padding: '0',
    marginLeft: '1rem',
    '& span': {
      textDecoration: 'underline',
      fontSize: '1.25rem',
    },
    '& :hover': {
      backgroundColor: 'white',
    },
  },
});

const DoceboDetails = ({
  values, isLoading, programId, doceboSectionRef, isDDAdmin, isAnyUnassignedScorm, setIsAnyUnassignedScorm,
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('order');
  const history = useHistory();

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    if (isLoading) {
      setOrder('asc');
      setOrderBy('order');
    }
  }, [isLoading]);

  const getList = useCallback((list, redirectToCourse, courseId, removeMargin) => list.map((item, index) => {
    if (!isAnyUnassignedScorm && isScormUnassigned(item)) {
      setIsAnyUnassignedScorm(true);
    }
    return (
      <ListItem
        key={item?.name}
        disableGutters
        disablePadding
      >
        {isScormUnassigned(item) ? (
          <ListItemText
            className={removeMargin}
            primaryTypographyProps={{
              fontSize: '1.25rem',
            }}
            primary={item?.name && (
              <>
                <Typography component="span" style={{ fontSize: '1.25rem' }}>{`${index + 1}. ${item?.name} | `}</Typography>
                <InfoTooltip title={'Click here to assign SCORM'} placement="bottom">
                  <span>
                    <LinkButton text={getMaterialType(item).label} variant="btnGeneralError" onClick={() => redirectToCourse(courseId)} />
                  </span>
                </InfoTooltip>
              </>
            )}
          />
        ) : (
          <ListItemText
            className={removeMargin}
            primaryTypographyProps={{
              fontSize: '1.25rem',
            }}
            primary={`${index + 1}. ${item?.name} | ${getMaterialType(item).label}`}
          />
        )}
      </ListItem>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  const redirectToCourse = (doceboCourseId) => {
    history.push(`/${ROUTE_REPORT}/${programId}/${CONTENT_MANAGEMENT}?feature=view-course&id=${doceboCourseId}`);
  };

  const data = useMemo(() => _map(values?.doceboDetails?.length > 0 ? tableSort(values?.doceboDetails, getComparator(order, orderBy)) : values?.doceboDetails, (obj) => ({
    ...obj,
    orderId: <Typography className={classes.boldText} variant="subtitle2">{convertInDigits(obj.orderId)}</Typography>,
    name:
      <Box className={`${classes.centerAllign} ${classes.graphicImage}`}>
        {obj?.course_graphic ? <img src={obj?.course_graphic} alt="img" /> : <img src={EmptyComponentTitle} alt="img" />}
        {isDDAdmin ? (<Typography component="span" variant="subtitle2" className={classes.leftPadding}>{obj.name}</Typography>
        ) : (
          <Button
            variant="text"
            style={{ color: MCKINSEY_BLUE }}
            className={classes.linkButton}
            onClick={() => {
              history.push(`/${ROUTE_REPORT}/${programId}/${CONTENT_MANAGEMENT}?feature=view-course&id=${obj.docebo_course_id}`);
            }}
          >
            {obj.name}
          </Button>
        )}
      </Box>,
    course_code: <Typography variant="subtitle2">{obj.course_code}</Typography>,
    course_type:
      <Box className={classes.centerAllign}>
        {obj?.course_type && <img src={CONTENT_TYPE_MAPPING[obj?.course_type].icon} alt="img" />}
        <Typography component="span" variant="subtitle2" className={classes.leftPadding}>{CONTENT_TYPE_MAPPING[obj.course_type].label}</Typography>
      </Box>,
    duration: <Typography variant="subtitle2">{obj?.duration ? secondsToHms(obj?.duration) : '-'}</Typography>,
    training_materials: obj?.training_materials.length > 0 ? <List disablePadding>{getList(obj?.training_materials.filter((tm) => tm?.type !== ''), redirectToCourse, obj?.docebo_course_id, classes.removeMarginTop)}</List> : 'N/A',
    style: { verticalAlign: 'top' },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })), [values?.doceboDetails, order, orderBy, getList]);

  return (
    <ContentDetailsBlock
      blockHeaderRef={doceboSectionRef}
      headerText="Docebo Details"
      fieldRedirectOnLabel={`/${ROUTE_REPORT}/${programId}/${CONTENT_MANAGEMENT}`}
      noPaper
    >
      {isLoading ? (<LoadingCircle labelText="Loading Docebo Details" />) : (
        <Grid item xs={12} md={12} style={{ paddingTop: 0, paddingBottom: '1rem' }}>
          <TableRC
            columns={[
              {
                title: 'Order', property: 'orderId', sortable: true, width: '10%',
              },
              {
                title: 'Component Title', property: 'name', width: '18%', sortable: true,
              },
              { title: 'Code', property: 'course_code' },
              { title: 'Type', property: 'course_type', width: '15%' },
              { title: 'Est. times', property: 'duration', sortable: true },
              { title: 'Training materials in order', property: 'training_materials', width: '20%' },
            ]}
            data={data}
            order={order}
            orderBy={orderBy}
            onSort={handleRequestSort}
            noDataMessage="Docebo details unavailable for this learning plan."
          />
        </Grid>
      )}

    </ContentDetailsBlock>
  );
};

DoceboDetails.propTypes = {
  values: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  programId: PropTypes.string.isRequired,
  doceboSectionRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  isDDAdmin: PropTypes.bool.isRequired,
  isAnyUnassignedScorm: PropTypes.bool.isRequired,
  setIsAnyUnassignedScorm: PropTypes.func.isRequired,
};

export default DoceboDetails;
