import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { useStyles, useDialogStyles } from '../../stylesheets/commonModal.styles';

import SuccessIcon from '../../assets/icons/ready-to-go.svg';
import rightArrow from '../../assets/icons/path.svg';
import XlsxImage from '../../assets/icons/xlsx.png';

const DialogTitle = (props) => {
  const classes = useStyles();
  const { children, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.titleBlock}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      <div className={classes.titleText}>{children}</div>
    </MuiDialogTitle>
  );
};

DialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

const Banner = ({ xlsxName }) => {
  const classes = useStyles();
  return (
    <div className={classes.banner}>
      <img src={XlsxImage} width={33} height={32} alt="XLSX" />
      <Typography className={classes.bannerText}>
        {xlsxName}
        {' '}
        uploaded sucessfully and is ready for automation.
      </Typography>
    </div>
  );
};

Banner.propTypes = {
  xlsxName: PropTypes.string.isRequired,
};

const SuccessModal = ({
  open,
  onClose,
  onModify,
  onBuildProgram,
  steps,
  xlsxName,
  heading,
  buttonText,
  footerText,
  hideBanner,
}) => {
  const classes = useStyles();
  const dialogClasses = useDialogStyles();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const StepLine = (step, index) => (
    <div style={{ padding: '5px' }} key={index}>
      <Typography className={classes.stepText}>
        <img src={rightArrow} alt="check" style={{ width: '25px', height: '12px' }} />
        {' '}
        {step}
      </Typography>
    </div>
  );
  return (
    <Dialog
      maxWidth="md"
      fullWidth
      disableBackdropClick
      onClose={onClose}
      open={open}
      classes={dialogClasses}
    >
      <DialogTitle onClose={onClose} className={classes.dialogTitle} xlsxName={xlsxName}>
        {!hideBanner && <Banner xlsxName={xlsxName} />}
        <img
          className={classes.imageBlock}
          src={SuccessIcon}
          alt="Spreadsheet"
          width={119}
          height={100}
        />
        <span className={classes.headingText}>{heading}</span>
      </DialogTitle>
      <MuiDialogContent className={classes.dialogContent}>
        {steps && steps.map(StepLine)}
      </MuiDialogContent>
      <MuiDialogActions className={classes.buttonActions} style={{ justifyContent: 'center' }}>
        {onModify && (
          <Button
            variant="outlined"
            color="primary"
            onClick={onModify}
          >
            Modify Course
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          disabled={buttonDisabled}
          onClick={() => {
            setButtonDisabled(true);
            onBuildProgram();
          }}
        >
          {buttonText}
        </Button>
      </MuiDialogActions>
      <Typography className={classes.bottomToolTip}>{footerText}</Typography>
    </Dialog>
  );
};

SuccessModal.defaultProps = {
  onModify: null,
};

SuccessModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onModify: PropTypes.func,
  onBuildProgram: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  xlsxName: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  footerText: PropTypes.string.isRequired,
  hideBanner: PropTypes.bool,
};

SuccessModal.defaultProps = {
  hideBanner: false,
};

export default SuccessModal;
