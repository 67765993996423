import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Typography from '@material-ui/core/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import ContentDetailsSidebar from './ContentDetailsSidebar';

import {
  SWITCH_GREY, MODERATE_DARK_GREY, MODERATE_LIGHT_GREY, DARK_GREY,
  ACCORDION_GREY, MCKINSEY_BLUE,
} from '../../stylesheets/colors';
import NoComponentThumbnail from '../../assets/img/empty-component-title.svg';
import { SETTINGS_AND_DETAIL_TAB, CONTENT_TAB } from '../../constants';

const useStyles = makeStyles({
  mainHeading: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1rem',
  },
  subHeading: {
    padding: '1rem 0',
    display: 'flex',
    flexDirection: 'row',
  },
  ctaWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '2rem 0',
  },
  actionWrapper: {
    minWidth: '18rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  underlineText: {
    borderBottom: `3px solid ${MCKINSEY_BLUE}`,
  },
  headerImage: {
    maxWidth: '100%',
    height: '100px',
    width: '100px',
    display: 'block',
    objectFit: 'cover',
    objectPosition: 'bottom',
  },
});

const ContentDetailsHeader = ({
  name, type, thumbnail, description, uidCourse, code, isEdit, onEdit, onCancel, handleSubmit,
  contentTab, handleContentTab, updateUserAccess, editUserAccess, userAccessObj, isUserAccessEdit,
  isValidating, errors, isRequired,
}) => {
  const classes = useStyles();
  const borderStyle = `1px solid ${SWITCH_GREY}`;

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    handleContentTab(newValue);
  };

  return (
    <Grid container item>
      <Grid container item xs={12} md={12}>
        <Grid
          item
          xs={9}
          sx={{
            display: 'flex', flexDirection: 'row', padding: '2rem 0', borderBottom: borderStyle,
          }}
        >
          <img src={thumbnail ? `https:${thumbnail}` : NoComponentThumbnail} className={classes.headerImage} alt="my-mage" />
          <Container className={classes.heading}>
            <Box className={classes.mainHeading}>
              <Typography
                variant="h4"
                style={{ marginRight: '1.5rem', textTransform: 'unset' }}
              >
                {name}
              </Typography>
              <Chip
                style={{
                  backgroundColor: MODERATE_LIGHT_GREY,
                  color: DARK_GREY,
                  fontSize: '1rem',
                  fontWeight: 500,
                  padding: '0 0.5rem',
                  borderRadius: '5px',
                }}
                label={type}
                size="small"
              />
            </Box>
            <Typography component="p" style={{ fontSize: '1.25rem', color: MODERATE_DARK_GREY, textTransform: 'unset' }}>
              {description}
            </Typography>
            <Box className={classes.subHeading}>
              <Typography
                component="p"
                style={{
                  fontSize: '1.25rem', marginRight: '3rem', color: ACCORDION_GREY, textTransform: 'unset',
                }}
              >
                {uidCourse}
              </Typography>
              <Typography component="p" style={{ fontSize: '1.25rem', color: MODERATE_DARK_GREY, textTransform: 'unset' }}>
                {code}
              </Typography>
            </Box>
          </Container>
        </Grid>
        <Grid item xs={3} sx={{ borderBottom: borderStyle, borderLeft: borderStyle }}>
          <ContentDetailsSidebar
            label={userAccessObj.label}
            icon={userAccessObj.icon}
            editUserAccess={editUserAccess}
            isUserAccessEdit={isUserAccessEdit}
            userAccessvalue={userAccessObj.value}
            updateUserAccess={updateUserAccess}
            isMandatory={isRequired}
          />
        </Grid>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={contentTab}
              onChange={handleChange}
              sx={{
                '& .MuiTab-root.Mui-selected': {
                  color: MCKINSEY_BLUE,
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: MCKINSEY_BLUE,
                },
              }}
              textColor="primary"
              indicatorColor="primary"
              aria-label="basic tabs example"
            >
              <Tab label="Content" value={CONTENT_TAB} {...a11yProps(0)} />
              <Tab label="Settings & Details" value={SETTINGS_AND_DETAIL_TAB} {...a11yProps(1)} />
            </Tabs>
          </Box>
          {contentTab === SETTINGS_AND_DETAIL_TAB && (
            <Box className={classes.ctaWrapper}>
              {
                isEdit ? (
                  <Box className={classes.actionWrapper}>
                    <Button
                      variant="outlined"
                      onClick={onCancel}
                    >
                      Cancel
                    </Button>
                    <Button disabled={isValidating || (Object.keys(errors).length && !!errors['elucidat-project-id'])} color="primary" variant="contained" onClick={handleSubmit}>
                      Save Changes
                    </Button>
                  </Box>
                ) : (
                  <Button
                    style={{ width: '9rem' }}
                    color="primary"
                    variant="contained"
                    disableElevation
                    disableRipple
                    onClick={onEdit}
                  >
                    Edit
                  </Button>
                )
              }
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

ContentDetailsHeader.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  uidCourse: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  isEdit: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  contentTab: PropTypes.number.isRequired,
  handleContentTab: PropTypes.func.isRequired,
  updateUserAccess: PropTypes.func.isRequired,
  userAccessObj: PropTypes.object.isRequired,
  editUserAccess: PropTypes.func.isRequired,
  isUserAccessEdit: PropTypes.bool.isRequired,
  isValidating: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  isRequired: PropTypes.bool.isRequired,
};

export default ContentDetailsHeader;
