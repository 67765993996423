import React from 'react';
import PropTypes from 'prop-types';
import { Container, Box } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FileInput from '../../FileInput';
import MainButton from '../../MainButton';
import { MCKINSEY_BLUE, REPORT_ERROR_RED } from '../../../stylesheets/colors';
import ErrorText from '../../common/ErrorText';

const useStyles = makeStyles({
  border: {
    marginTop: '2rem',
    border: `0.5px dashed ${MCKINSEY_BLUE}`,
    width: '100%',
  },
  required: {
    '&.MuiTypography-root::after': {
      content: "'*'",
      color: REPORT_ERROR_RED,
    },
  },
});

const AddScormFileUpload = ({
  onUpload, errors, touched,
}) => {
  const classes = useStyles();

  return (
    <Container sx={{ width: '80%' }}>
      <Typography variant="h1" className={classes.required}>
        Upload SCORM package
      </Typography>
      <Box className={classes.border}>
        <FileInput
          idx={0}
          onFileInput={onUpload}
          acceptedFile=".zip,.rar,.7zip"
        >
          <MainButton
            title="Upload SCORM file"
            subtitle="Please upload relevant SCORM zip file."
          />
        </FileInput>
      </Box>
      <ErrorText
        touchedField={touched.file}
        errorField={errors.file}
      />
    </Container>
  );
};

AddScormFileUpload.propTypes = {
  onUpload: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    file: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    file: PropTypes.string,
  }).isRequired,
};

export default AddScormFileUpload;
