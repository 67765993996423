import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import _map from 'lodash/map';
import ContentDetailsBlock from '../ContentManagement/ContentDetailsBlock';
import TableRC from '../common/TableRC';
import LoadingCircle from '../common/LoadingCircle/LoadingCircle';
import {
  ROUTE_REPORT, SETTINGS,
} from '../../constants';
import { MCKINSEY_BLUE } from '../../stylesheets/colors';

const ResourceChannels = ({
  values, isLoading, programId,
}) => {
  const data = _map(values?.channels, (channel) => ({
    ...channel,
    name: <Typography variant="subtitle2">{channel?.name}</Typography>,
    channel_link: channel?.channel_link && <a target="_blank" rel="noopener noreferrer" href={channel.channel_link} style={{ fontSize: '1.25rem', color: MCKINSEY_BLUE }}>{channel?.channel_link}</a>,
    expert: <Typography variant="subtitle2">{channel?.expert}</Typography>,
  }));

  return (
    <ContentDetailsBlock
      headerText="Resource Channel"
      fieldRedirectOnLabel={`/${ROUTE_REPORT}/${programId}/${SETTINGS}?tab=5&mode=view`}
      noPaper
    >
      {isLoading ? (<LoadingCircle labelText="Loading Resource Channel" />) : (
        <Grid item xs={12} md={12} style={{ paddingTop: 0, paddingBottom: '1rem' }}>
          <TableRC
            columns={[
              { title: 'Title', property: 'name' },
              { title: 'Link', property: 'channel_link' },
              { title: 'Expert User Details', property: 'expert' },
            ]}
            data={data}
            noDataMessage="Resource channels unavailable for this learning plan."
          />
        </Grid>
      )}

    </ContentDetailsBlock>
  );
};

ResourceChannels.propTypes = {
  values: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  programId: PropTypes.string.isRequired,
};

export default ResourceChannels;
