import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import StagesMap from './constants';

import {
  MODERATE_LIGHT_GREY,
  STAGE_IN_BUILD_COLOR, STAGE_HEADER_IN_BUILD_COLOR,
  STAGE_LAUNCHED_COLOR, STAGE_HEADER_LAUNCHED_COLOR,
  STAGE_LAUNCH_PREP_COLOR, STAGE_HEADER_LAUNCH_PREP_COLOR,
  STAGE_QA_COLOR, STAGE_HEADER_QA_COLOR,
  STAGE_UNKNOWN_COLOR, STAGE_HEADER_UNKNOWN_COLOR,
  WHITE,
  STAGE_DELETED_COLOR, STAGE_HEADER_DELETED_COLOR,
  STAGE_REPLICATED_COLOR, STAGE_HEADER_REPLICATED_COLOR,
  SKY_BLUE,
  USER_CIRCLE_DARK_BG,
  GSR_BG_CHANTILLY,
  SKILL_BG_YELLOW,
  GSR_FG_FUCHSIA,
  YELLOW1,
  PINK1,
  GREEN1,
  GREEN2,
  PINK2,
  BLUE1, DARK_GREY,
  SKILL_FG_AMBER_YELLOW,
} from '../../stylesheets/colors';

import {
  STAGE_UNKNOWN,
  STAGE_IN_BUILD,
  STAGE_QA,
  STAGE_LAUNCH_PREP,
  STAGE_LAUNCHED,
  STAGE_DELETED,
  STAGE_REPLICATED,
  ACADEMY_GO,
  LP_GSR,
  LP_SKILLS,
} from '../../constants';

import PreLaunchIcon from '../../assets/img/pre-launch.svg';
import PostLaunchIcon from '../../assets/img/post-launch.svg';
import GoAcademy from '../../assets/icons/go-academy-stage.svg';
import GSR from '../../assets/icons/gsr_icon.svg';
import SKILLS from '../../assets/icons/skill_icon.svg';
import MCKINSEY_SANS from '../../stylesheets/fonts';

const decideBgColor = (stage, position) => ({
  [STAGE_UNKNOWN]: position === 'landing_page' ? STAGE_UNKNOWN_COLOR : STAGE_HEADER_UNKNOWN_COLOR,
  [STAGE_IN_BUILD]: position === 'landing_page' ? STAGE_IN_BUILD_COLOR : STAGE_HEADER_IN_BUILD_COLOR,
  [STAGE_QA]: position === 'landing_page' ? STAGE_QA_COLOR : STAGE_HEADER_QA_COLOR,
  [STAGE_LAUNCH_PREP]: position === 'landing_page' ? STAGE_LAUNCH_PREP_COLOR : STAGE_HEADER_LAUNCH_PREP_COLOR,
  [STAGE_LAUNCHED]: position === 'landing_page' ? STAGE_LAUNCHED_COLOR : STAGE_HEADER_LAUNCHED_COLOR,
  [STAGE_DELETED]: position === 'landing_page' ? STAGE_DELETED_COLOR : STAGE_HEADER_DELETED_COLOR,
  [STAGE_REPLICATED]: position === 'landing_page' ? STAGE_REPLICATED_COLOR : STAGE_HEADER_REPLICATED_COLOR,
  [ACADEMY_GO]: SKY_BLUE,
  [LP_GSR]: GSR_BG_CHANTILLY,
  [LP_SKILLS]: SKILL_BG_YELLOW,
}[stage] || STAGE_UNKNOWN_COLOR);

const decideFgColor = (stage, position) => ({
  [STAGE_UNKNOWN]: position === 'landing_page' ? YELLOW1 : WHITE,
  [STAGE_IN_BUILD]: position === 'landing_page' ? YELLOW1 : WHITE,
  [STAGE_QA]: position === 'landing_page' ? PINK1 : DARK_GREY,
  [STAGE_LAUNCH_PREP]: position === 'landing_page' ? GREEN1 : DARK_GREY,
  [STAGE_LAUNCHED]: position === 'landing_page' ? GREEN2 : WHITE,
  [STAGE_DELETED]: position === 'landing_page' ? PINK2 : WHITE,
  [STAGE_REPLICATED]: position === 'landing_page' ? BLUE1 : WHITE,
  [ACADEMY_GO]: USER_CIRCLE_DARK_BG,
  [LP_GSR]: GSR_FG_FUCHSIA,
  [LP_SKILLS]: SKILL_FG_AMBER_YELLOW,
}[stage] || MODERATE_LIGHT_GREY);

const WRAPPER_WIDTH = '8rem';

const useStyles = makeStyles({

  wrapper: ({ stage, position }) => ({
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: decideBgColor(stage, position),
    color: decideFgColor(stage, position),
    display: 'flex',
    width: 'auto',
    padding: '4px 6px',
    cursor: 'default !important;',
    borderRadius: '4px',
    maxHeight: '18px',
  }),
  stageBadgeIcon: ({ stage, position }) => ({
    color: decideFgColor(stage, position),
    marginRight: '3px',
  }),
  stageBadgeTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '8px',
    borderRadius: '10px',
    fontFamily: MCKINSEY_SANS,
    fontWeight: 700,
    lineHeight: '10px',
  },
  expandMoreButton: {
    backgroundColor: 'transparent',
    padding: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  expandMoreIcon: {
    width: '1rem',
    height: '1rem',
  },
  dropdown: {
    display: 'flex',
    flexDirection: 'column',
    width: WRAPPER_WIDTH,
  },
  dropdownOption: {
    height: '2rem',
    backgroundColor: WHITE,
    borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
  },
});

const WrappingDom = ({
  stage, editable, children, onClick, position,
}) => {
  const classes = useStyles({ stage, position });

  if (editable) {
    return (
      <button
        className={classes.wrapper}
        type="button"
        onClick={onClick}
      >
        {children}
      </button>
    );
  }

  return <div className={classes.wrapper}>{children}</div>;
};

WrappingDom.defaultProps = {
  onClick: () => { },
  position: '',
};

WrappingDom.propTypes = {
  stage: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  position: PropTypes.string,
};

const StageBadge = ({
  stage,
  editable,
  hideIcon,
  position,
}) => {
  const classes = useStyles({ stage, position });

  const [innerStage, setInnerStage] = useState(stage);

  useEffect(setInnerStage.bind(this, stage), [stage]);

  const icon = {
    [STAGE_UNKNOWN]: PostLaunchIcon,
    [STAGE_IN_BUILD]: PostLaunchIcon,
    [STAGE_QA]: PreLaunchIcon,
    [STAGE_LAUNCH_PREP]: PreLaunchIcon,
    [STAGE_LAUNCHED]: PostLaunchIcon,
    [STAGE_REPLICATED]: PostLaunchIcon,
    [ACADEMY_GO]: GoAcademy,
    [LP_GSR]: GSR,
    [LP_SKILLS]: SKILLS,
  }[innerStage] || PreLaunchIcon;

  const stageText = StagesMap[innerStage] || 'Loading...';

  return (
    <>
      <WrappingDom stage={innerStage} position={position} editable={editable}>
        {
          !hideIcon && (<img className={classes.stageBadgeIcon} src={icon} alt={stageText} />)
        }
        {' '}
        <Typography component="span" variant="body1" className={classes.stageBadgeTitle}>
          {stageText}
        </Typography>
      </WrappingDom>
    </>
  );
};

StageBadge.propTypes = {
  stage: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  hideIcon: PropTypes.bool,
  position: PropTypes.string,
};

StageBadge.defaultProps = {
  editable: false,
  hideIcon: false,
  position: '',
};

export default StageBadge;
