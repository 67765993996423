import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  wrapper: {
    display: 'inherit',
    flexDirection: 'inherit',
    justifyContent: 'inherit',
    alignItems: 'inherit',
    width: '100%',
    marginBottom: 0,
  },
});

const FileInput = ({
  idx, children, onFileInput, isDisabled, acceptedFile,
}) => {
  const classes = useStyles();

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={classes.wrapper}>
      <input
        type="file"
        accept={acceptedFile}
        className="fileInput"
        // id="fileInput"
        key={idx}
        style={{ display: 'none' }}
        onChange={onFileInput}
        data-testid="fileUpload"
        disabled={isDisabled}
      />
      {children}
    </label>
  );
};

FileInput.defaultProps = {
  idx: 0,
  isDisabled: false,
  acceptedFile: '.xlsx',
};

FileInput.propTypes = {
  idx: PropTypes.number,
  children: PropTypes.node.isRequired,
  onFileInput: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  acceptedFile: PropTypes.string,
};

export default FileInput;
