import React, {
  useEffect, useState, useCallback, useRef,
} from 'react';
import _find from 'lodash/find';

import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@mui/material/Box';
import { DatePicker } from '@material-ui/pickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  format, isBefore, isEqual,
} from 'date-fns';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FormattedTypography from '../common/FormattedTypography';
import ConfirmationModal from '../common/ConfirmationModal';
import LabledTextField from '../common/LabledTextField';
import Loading from '../common/Loading';
import {
  ERROR_RED, MODERATE_LIGHT_GREY,
  SWITCH_GREY, ACCORDION_GREY, LIGHT_MEDIUM_GREY,
} from '../../stylesheets/colors';
import {
  DATEFORMAT, COMPONENT_TITLE_MAX_LIMIT, DEFAULTCLIENT, FIELD_CHARACTER_LIMIT_18,
} from '../../constants';
import CustomSelect from '../common/CustomSelect';

const useStyles = makeStyles(() => ({
  modalWrapper: {
    padding: '0 48px 20px',
    width: '87%',
    margin: 'auto',
  },
  width100: {
    width: '100%',
  },
  modalHeight: {
    minHeight: '400px',
  },
  fieldPadding: {
    paddingTop: '1rem',
  },
  red: {
    color: ERROR_RED,
  },
  errorBlock: {
    display: 'flex',
  },
  rootLabelText: {
    '& > div': {
      borderRadius: 'unset',
    },
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  experience_type_label: {
    marginBottom: '8px',
    fontSize: '1.125rem',
    fontWeight: 'normal',
  },
  highliteErrorInput: {
    '& > div': {
      border: `1px solid ${ERROR_RED}`,
    },
  },
  datePicker: {
    margin: '8px 0',
    width: '100%',
    '& .MuiOutlinedInput-input': {
      padding: '10.5px 14px',
    },
    '& > div': {
      borderRadius: 'unset',
    },
  },
  input: {
    width: '100%',
    margin: '8px 0',
    '& > div': {
      borderRadius: 'unset',
    },
    '& input': {
      paddingRight: '4.5rem',
      '&:disabled': {
        backgroundColor: MODERATE_LIGHT_GREY,
        opacity: 0.5,
      },
      '&[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '&::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
  },
  rightText: {
    marginLeft: 'auto',
    float: 'right',
  },
  addCohort: {
    '&:disabled': {
      color: ACCORDION_GREY,
      boxShadow: 'none',
      backgroundColor: SWITCH_GREY,
    },
  },
  checkbox: {
    height: '1.125rem',
    color: LIGHT_MEDIUM_GREY,
    opacity: 0.6,
    margin: '0 2.5rem 0 0.75rem',
    '& > svg': {
      width: '2rem',
      height: '2rem',
    },
  },
  checkboxChecked: {
    opacity: 0.4,
  },
}));

const validateEmail = (email) => String(email)
  .toLowerCase()
  .match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

const validate = (values) => {
  const errors = {};
  const eventStartDate = values.start_date ? new Date(format(values.start_date, DATEFORMAT)) : null;
  const eventEndDate = values.end_date ? new Date(format(values.end_date, DATEFORMAT)) : null;
  const Expirationdate = values.expiration_date
    ? new Date(format(values.expiration_date, DATEFORMAT))
    : null;
  if (!values.ec_code) {
    errors.ec_code = 'This field is required';
  }
  if (!values.wave_name) {
    errors.wave_name = 'This field is required';
  }
  if (eventStartDate && eventEndDate
    && (isBefore(eventEndDate, eventStartDate) || isEqual(eventEndDate, eventStartDate))) {
    errors.end_date = 'The Completion Date must be later than the launch date';
    errors.start_date = 'The Launch date must be earlier than the completion Date';
  }
  if (Expirationdate) {
    if (eventStartDate
      && (isBefore(Expirationdate, eventStartDate) || isEqual(Expirationdate, eventStartDate))) {
      errors.expiration_date = 'The Expiration Date must be later than the launch date';
    } else if (eventEndDate
      && (isBefore(Expirationdate, eventEndDate))) {
      errors.expiration_date = 'The Expiration Date cannot be earlier than the Completion date';
    }
  }
  if (values.experience_type.value === 'Single Client' && values.client_id.length > 1) {
    errors.client_id = 'You cannot select multiple clients with "Single Client" EC type. Please remove clients or change EC type to "Multiclient" or "B2C"';
  }
  if (values.cst_email && !validateEmail(values.cst_email)) {
    errors.wave_email = 'Email id is not correct';
  }
  if (values.pdt_id && !(/^[a-zA-Z0-9]+$/.test(values.pdt_id))) {
    errors.pdt_id = 'Must be alphanumeric string';
  }
  return errors;
};

const EditCohort = ({
  open,
  onClose,
  isLoading,
  onUpdateWave,
  cohortData,
  waveTitleUniqueError,
  clientIdList,
  isAcademyGo,
  checkUserExistOnClient,
}) => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState({});
  const [selectIconLoading, setSelectIconLoading] = useState(false);

  const waveForm = {
    edit: {
      heading: 'Edit enrollment cohort',
      subHeading: 'Please make sure enrollment cohort edits are valid. Changes to the cohort title will reflect in user enrollment.',
    },
  };
  const ecExperienceTypeRef = useRef(null);
  const clientIdRef = useRef(null);
  const ecExperiencetypeOptions = [
    { label: 'Single Client', value: 'Single Client' },
    { label: 'Multiclient', value: 'Multiclient' },
    { label: 'B2C', value: 'B2C' },
  ];

  const prepareFormValues = useCallback(() => ({
    ec_code: cohortData?.ec_code,
    wave_name: cohortData?.wave_name,
    description: cohortData?.description || '',
    start_date: cohortData?.start_date ? new Date(cohortData?.start_date) : null,
    end_date: cohortData?.end_date ? new Date(cohortData?.end_date) : null,
    expiration_date: cohortData?.expiration_date ? new Date(cohortData?.expiration_date) : null,
    client_id: cohortData?.client_id ? cohortData?.client_id.map((item) => ({ value: item.id, label: `${item.client_id}-${item.client_name}` })) : [],
    experience_type: _find(ecExperiencetypeOptions, {
      value: cohortData?.experience_type || '',
    }),
    cst_email: cohortData?.cst_username || '',
    order_id: cohortData?.order_id || '',
    pdt_id: cohortData?.pdt_id || '',
    near_target: cohortData?.near_target || null,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [cohortData]);

  useEffect(() => {
    setFormValues(prepareFormValues());
  }, [prepareFormValues]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: formValues,
    validate,
    onSubmit: (values) => {
      const finalValues = {
        ec_code: values.ec_code,
        wave_name: values.wave_name,
        description: values.description,
        start_date: values.start_date ? format(values.start_date, DATEFORMAT) : null,
        end_date: values.end_date ? format(values.end_date, DATEFORMAT) : null,
        expiration_date: values.expiration_date ? format(values.expiration_date, DATEFORMAT) : null,
        client_id: values.client_id ? values.client_id.map((item) => item.value) : [],
        experience_type: values.experience_type.value,
        cst_email: values.cst_email,
        order_id: values.order_id,
        pdt_id: values.pdt_id,
        near_target: values.near_target || null,
      };
      onUpdateWave({ ...finalValues, wave_id: cohortData.wave_id });
    },
  });

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
    resetForm,
    errors,
    setErrors,
    touched,
  } = formik;
  useEffect(() => {
    setValues(formValues);
  }, [formValues, setValues]);

  useEffect(() => {
    if (ecExperienceTypeRef.current && values.experience_type) {
      const setSessionCompletion = ecExperienceTypeRef.current.select?.setValue;
      setSessionCompletion(values.experience_type);
    }
  }, [values.experience_type]);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open, resetForm]);

  const checkCharacterLimit = (e, charLen) => {
    if (e.target.value.length <= charLen) {
      handleChange(e);
      if (e.target.name === 'near_target') {
        setFieldValue(e.target.name, e.target.value.replace(/[^0-9]/g, ''));
      } else if (e.target.name === 'pdt_id' && e.target.value === '') {
        setErrors({ pdt_id: '' });
      } else {
        setFieldValue(e.target.name, e.target.value);
      }
    }
  };

  const scrollToBottom = () => {
    if (clientIdRef.current) {
      const valueContainer = clientIdRef.current.select.controlRef;
      valueContainer.scrollTop = valueContainer.scrollHeight;
    }
  };

  // eslint-disable-next-line consistent-return
  const onClientIdChange = async (e, ctx) => {
    if (ctx.action === 'remove-value') {
      setSelectIconLoading(true);

      try {
        const res = await checkUserExistOnClient(ctx.removedValue.value, cohortData?.wave_id);
        if (res?.data.success === false) {
          // Handle case where client cannot be removed
          return false;
        }
      } catch (err) {
        console.error(err);
      } finally {
        setSelectIconLoading(false);
      }
    }

    setFieldValue('client_id', e || []);
    if (e) {
      setTimeout(scrollToBottom, 100);
    }
  };

  const requiredFieldLabel = (label, className) => (
    <Box className={`${classes.label} ${className || ''}`}>
      <Typography>{label}</Typography>
      <span className={classes.red}>*</span>
    </Box>
  );

  return (
    <ConfirmationModal
      open={open}
      isContainForm
      onClose={onClose}
      onSubmit={handleSubmit}
      dialogContentClass={classes.width100}
      minHeightClass={classes.modalHeight}
      modalWidth={85}
      title={(
        <FormattedTypography
          variant="subtitle1"
          body={waveForm.edit.heading}
          subHeading={waveForm.edit.subHeading}
          dense
          suffixClassName={classes.bold}
        />
        )}
      content={
          isLoading ? (
            <Loading />
          ) : (
            <Grid container justifyContent="space-between" className={classes.modalWrapper}>
              <Grid item xs={12}>
                <FormControlLabel
                  key="default cohort"
                  value="default cohort"
                  control={(
                    <Checkbox
                      className={classes.checkbox}
                      checked={cohortData?.is_default}
                      disabled
                      name="Default cohort"
                      classes={{
                        checked: classes.checkboxChecked,
                      }}
                    />
                  )}
                  label="Default cohort"
                  disabled={!cohortData?.is_default}
                />
              </Grid>
              <Grid item xs={12}>
                <LabledTextField
                  label={requiredFieldLabel('Cohort Title')}
                  placeholder="Enter cohort title here"
                  className={classes.fieldPadding}
                  inputProps={{
                    name: 'wave_name',
                    classes: {
                      root: `${classes.rootLabelText} ${(errors.wave_name)
                        ? ` ${classes.highliteErrorInput}`
                        : ''
                      }`,
                    },
                  }}
                  value={values.wave_name || ''}
                  onChange={(e) => checkCharacterLimit(e, COMPONENT_TITLE_MAX_LIMIT)}
                />
                <Box className={classes.errorBlock}>
                  {errors.wave_name && (
                    <Typography className={classes.red}>{errors.wave_name}</Typography>
                  )}
                  <Typography className={classes.rightText}>
                    {(values.wave_name || '').length}
                    /
                    {COMPONENT_TITLE_MAX_LIMIT}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <LabledTextField
                  label="Description"
                  className={classes.fieldPadding}
                  placeholder="Enter description text here"
                  inputProps={{
                    name: 'description',
                    classes: { root: classes.rootLabelText },
                  }}
                  value={values.description || ''}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomSelect
                  name="experience_type"
                  selectRef={ecExperienceTypeRef}
                  options={ecExperiencetypeOptions}
                  className={`${classes.fieldPadding} ${classes.experience_type_label}`}
                  defaultValue={values.experience_type}
                  placeholder="Select an option"
                  type="select"
                  label={requiredFieldLabel('EC Type', classes.experience_type_label)}
                  onChange={(e) => setFieldValue('experience_type', e)}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomSelect
                  selectRef={clientIdRef}
                  name="client_id"
                  options={clientIdList}
                  className={`${classes.fieldPadding} ${classes.experience_type_label}`}
                  defaultValue={values.client_id}
                  placeholder="Select an option"
                  type="select"
                  label={requiredFieldLabel('Client', classes.experience_type_label)}
                  labelClass={classes.experience_type_label}
                  onChange={(e, ctx) => onClientIdChange(e, ctx)}
                  isMulti
                  isOptionsNotLoading
                  noOptionsMessage="Client does not exist. Please contact local admin."
                  disabled={selectIconLoading}
                  selectIconLoading={selectIconLoading}
                />
                <Typography>
                  {`(${DEFAULTCLIENT.name} - ${DEFAULTCLIENT.id} is part of this cohort by default.
                     You can add users with this client as needed)`}

                </Typography>
                <Box className={classes.errorBlock}>
                  {errors.client_id && (
                  <Typography className={classes.red}>{errors.client_id}</Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <LabledTextField
                  label="PDT Digital Milestone ID"
                  placeholder="Enter PDT ID"
                  className={classes.fieldPadding}
                  inputProps={{
                    name: 'pdt_id',
                    classes: { root: classes.rootLabelText },
                  }}
                  value={values.pdt_id || ''}
                  onChange={(e) => checkCharacterLimit(e, FIELD_CHARACTER_LIMIT_18)}
                />
                <Box className={classes.errorBlock}>
                  {errors.pdt_id && (
                  <Typography className={classes.red}>{errors.pdt_id}</Typography>
                  )}
                  <Typography className={classes.rightText}>
                    {(values.pdt_id || '').length}
                    /
                    {FIELD_CHARACTER_LIMIT_18}
                  </Typography>
                </Box>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={6}>
                  {requiredFieldLabel('Launch Date', classes.fieldPadding)}
                  <DatePicker
                    className={`${classes.datePicker} ${errors.start_date ? ` ${classes.highliteErrorInput}` : ''}`}
                    value={values.start_date}
                    format={DATEFORMAT}
                    inputVariant="outlined"
                    autoOk
                    variant="inline"
                    onChange={(date) => setFieldValue('start_date', date)}
                    InputProps={{
                      className: classes.datePickerInput,
                      endAdornment: <CalendarTodayIcon size="2rem" />,
                      placeholder: 'Select a date',
                    }}
                  />
                  <Box className={classes.errorBlock}>
                    {errors.start_date && (
                      <Typography className={classes.red}>{errors.start_date}</Typography>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box className={`${classes.label} ${classes.fieldPadding}`}>
                    <Typography>Completion Date</Typography>
                  </Box>
                  <DatePicker
                    className={`${classes.datePicker} ${errors.end_date ? ` ${classes.highliteErrorInput}` : ''}`}
                    value={values.end_date}
                    format={DATEFORMAT}
                    inputVariant="outlined"
                    autoOk
                    variant="inline"
                    onChange={(date) => setFieldValue('end_date', date)}
                    InputProps={{
                      className: classes.datePickerInput,
                      endAdornment: <CalendarTodayIcon size="2rem" />,
                      placeholder: 'Select a date',
                    }}
                  />
                  <Box className={classes.errorBlock}>
                    {errors.end_date && (
                      <Typography className={classes.red}>{errors.end_date}</Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box className={`${classes.label} ${classes.fieldPadding}`}>
                  <Typography>Expiration Date</Typography>
                </Box>
                <DatePicker
                  className={classes.datePicker}
                  value={values.expiration_date}
                  format={DATEFORMAT}
                  inputVariant="outlined"
                  autoOk
                  variant="inline"
                  onChange={(date) => setFieldValue('expiration_date', date)}
                  InputProps={{
                    className: classes.datePickerInput,
                    endAdornment: <CalendarTodayIcon size="2rem" />,
                    placeholder: 'Select an expiration date',
                  }}
                />
                <Box className={classes.errorBlock}>
                  {errors.expiration_date && (
                  <Typography className={classes.red}>{errors.expiration_date}</Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <LabledTextField
                  label={requiredFieldLabel('EC Code')}
                  placeholder="client_offering_yymmdd_1"
                  className={classes.fieldPadding}
                  inputProps={{
                    name: 'ec_code',
                    classes: {
                      root: `${classes.rootLabelText} ${(errors.ec_code) || waveTitleUniqueError
                        ? ` ${classes.highliteErrorInput}`
                        : ''
                      }`,
                    },
                  }}
                  value={values.ec_code || ''}
                  onChange={(e) => checkCharacterLimit(e, COMPONENT_TITLE_MAX_LIMIT)}
                />
                <Box className={classes.errorBlock}>
                  {errors.ec_code && (
                    <Typography className={classes.red}>{errors.ec_code}</Typography>
                  )}
                  {waveTitleUniqueError && (
                  <Typography className={classes.red}>EC code must be unique</Typography>
                  )}
                  <Typography className={classes.rightText}>
                    {(values.ec_code || '').length}
                    /
                    {' '}
                    {COMPONENT_TITLE_MAX_LIMIT}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <LabledTextField
                  label="Near Target (Ignore this field)"
                  type="string"
                  placeholder="Enter numeric near target value"
                  className={classes.fieldPadding}
                  inputProps={{
                    name: 'near_target',
                    classes: { root: classes.rootLabelText },
                    pattern: '[^0-9]{1,3}',
                  }}
                  value={values.near_target || ''}
                  onChange={(e) => checkCharacterLimit(e, 3)}
                />
                <Box className={classes.errorBlock}>
                  <Typography className={classes.rightText}>
                    {(values.near_target || '').toString().length}
                    /3
                  </Typography>
                </Box>
              </Grid>
              {
                isAcademyGo && (
                <Grid item xs={12}>
                  <LabledTextField
                    label={requiredFieldLabel('CST Member (Specific to Academy-GO programs)')}
                    placeholder="Enter CST Member Email ID"
                    className={classes.fieldPadding}
                    inputProps={{
                      min: 0,
                      name: 'cst_email',
                      classes: {
                        root: `${classes.rootLabelText}`,
                      },
                    }}
                    value={values.cst_email || ''}
                    onChange={handleChange}
                  />
                  <Box className={classes.errorBlock}>
                    {errors.wave_email && touched.cst_email && (
                    <Typography className={classes.red}>{errors.wave_email}</Typography>
                    )}
                  </Box>
                </Grid>
                )
              }
              {
                isAcademyGo && (
                <Grid item xs={12}>
                  <LabledTextField
                    label={'Order ID (Specific to Academy-GO programs)'}
                    className={classes.fieldPadding}
                    placeholder="Enter Order ID"
                    inputProps={{
                      name: 'order_id',
                      classes: { root: classes.rootLabelText },
                    }}
                    value={values.order_id || ''}
                    onChange={(e) => checkCharacterLimit(e, COMPONENT_TITLE_MAX_LIMIT)}
                  />
                  <Box className={classes.errorBlock}>
                    <Typography className={classes.rightText}>
                      {(values.order_id || '').length}
                      /
                      {COMPONENT_TITLE_MAX_LIMIT}
                    </Typography>
                  </Box>
                </Grid>
                )
              }

            </Grid>
          )
        }
      actions={(
        <Button
          className={classes.addCohort}
          color="primary"
          disabled={!values.ec_code || !values.wave_name
                      || !values.experience_type || !values.start_date || !values.client_id.length
                      || (isAcademyGo && !values.cst_email) || selectIconLoading}
          variant="contained"
          type="submit"
        >
          Save Details
        </Button>
        )}
    />
  );
};

export default EditCohort;

EditCohort.defaultProps = {
  cohortData: {},
};

EditCohort.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdateWave: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  waveTitleUniqueError: PropTypes.bool.isRequired,
  clientIdList: PropTypes.array.isRequired,
  isAcademyGo: PropTypes.bool.isRequired,
  checkUserExistOnClient: PropTypes.func.isRequired,
  cohortData: PropTypes.shape({
    wave_id: PropTypes.number,
    ec_code: PropTypes.string,
    wave_name: PropTypes.string,
    description: PropTypes.string,
    start_date: PropTypes.string,
    expiration_date: PropTypes.string,
    end_date: PropTypes.string,
    experience_type: PropTypes.string,
    client_id: PropTypes.array,
    cst_email: PropTypes.string,
    order_id: PropTypes.string,
    pdt_id: PropTypes.string,
    cst_username: PropTypes.string,
    near_target: PropTypes.number,
    is_default: PropTypes.bool,
  }),
};
