import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import {
  ROUTE_REPORT,
  PROGRAM_CREATION,
  ASSIGN_COURSE_CONTENT,
  DOCEBO,
  ELUCIDAT,
  ELEARNING,
  NO_RESULTS_ELUCIDAT,
  NO_RESULTS_DOCEBO,
} from '../../constants';

import SimpleHeader from '../../components/SimpleHeader';
import ContentSearch from '../../components/ContentSearch';

import { VERY_LIGHT_GREY } from '../../stylesheets/colors';
import EnvironmentBanner from '../../components/EnvironmentBanner';
import { getEnvironmentFromLocalStorage } from '../../helpers/userHelper';
import {
  getContentsFromElucidat,
  getContentsFromDocebo,
  postAssignedContent,
  getCourseInfo,
} from './apis';

const navigateToReportPage = (history, programId, transactionId, courseId) => {
  history
    .push(`/${ROUTE_REPORT}/${programId}/${ASSIGN_COURSE_CONTENT}/${transactionId}`, { courseId });
};

const ContentAssignmentPage = (props) => {
  const { match } = props;

  const history = useHistory();
  const programId = get(match, 'params.programId');
  const transactionId = get(match, 'params.transactionId');
  const courseId = get(match, 'params.courseId');

  const environment = getEnvironmentFromLocalStorage();

  const [cards, setCards] = useState([]);
  const [doceboCards, setDoceboCards] = useState([]);
  const [title, setTitle] = useState('Loading');
  const [configId, setConfigId] = useState('');
  const [courseType, setCourseType] = useState('');
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [assignFailed, setAssignFailed] = useState(false);
  const [message, setMessage] = useState('');
  const [assignFailedErrors, setAssignFailedErrors] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [selectedResource, setSelectedResource] = useState();
  const [alertbarOpen, setAlertbarOpen] = useState(false);
  const [alertbarText, setAlertbarText] = useState('');
  const [lastSource, setLastSource] = useState(null);

  useEffect(() => {
    const fetchCourseInfo = async () => {
      const info = await getCourseInfo(transactionId, courseId);
      if (info.status && info.status === 404) {
        history.push('/404', info.data);
      }
      if (info.already_assigned) {
        navigateToReportPage(history, programId, transactionId, null);
        return;
      }
      setTitle(info.title);
      setConfigId(info.config_id);
      setCourseType(info.course_type);
    };
    fetchCourseInfo();
  }, [history, programId, transactionId, courseId]);

  const fetchCardsFromElucidat = async (searchText, type, token) => {
    if (!(searchText && searchText.length)) return null; // Loading
    setCards([]);
    const contents = await getContentsFromElucidat(searchText, type, token);
    if (contents.success) {
      setMessage('');
      const isCardValid = (card) => get(card, 'releases.length') > 0;
      const validCards = contents.search_results.filter(isCardValid);
      setCards(validCards);
      if (validCards.length === 0) {
        return { count: 0 };
      }
      return { count: validCards.length };
    } if (contents.aborted) {
      // No op
    } else {
      setMessage('');
      setAlertbarOpen(true);
      setAlertbarText('Failed to receive results from Elucidat');
      setCards([]);
    }
    return null;
  };

  const fetchCardsFromDocebo = async (searchText, type, token) => {
    if (!(searchText && searchText.length)) return null; // Loading
    setDoceboCards([]);
    const contents = await getContentsFromDocebo(searchText, type, token);
    if (contents.success) {
      setMessage('');
      const isCardValid = (card) => get(card, 'versions.length') > 0;
      const validCards = contents.search_results.filter(isCardValid);
      setDoceboCards(validCards);
      if (validCards.length === 0) {
        return { count: 0 };
      }
      return { count: validCards.length };
    } if (contents.aborted) {
      // No op
    } else {
      setMessage('');
      setAlertbarOpen(true);
      setAlertbarText('Failed to receive results from Docebo');
      setDoceboCards([]);
    }
    return null;
  };

  const fetchContent = async (searchText, token, resource = selectedResource) => {
    setAlertbarOpen(false);

    if (resource === ELUCIDAT) {
      setMessage('Fetching data from Elucidat...');
      const result = await fetchCardsFromElucidat(searchText, selectedType, token);
      if (result && result.count === 0) {
        setMessage(NO_RESULTS_ELUCIDAT);
      }
    } else if (resource === DOCEBO) {
      setMessage('Fetching data from Docebo...');
      const result = await fetchCardsFromDocebo(searchText, selectedType, token);
      if (result && result.count === 0) {
        setMessage(NO_RESULTS_DOCEBO);
      }
    } else {
      setMessage('Fetching data from Docebo and Elucidat...');
      const [elucidatResult, doceboResult] = await Promise.all([
        fetchCardsFromElucidat(searchText, selectedType, token),
        fetchCardsFromDocebo(searchText, selectedType, token)]);
      if (elucidatResult && elucidatResult.count === 0
        && doceboResult && doceboResult.count === 0) {
        setMessage('No results from Docebo and Elucidat');
      } else if (elucidatResult && elucidatResult.count === 0) {
        setMessage(NO_RESULTS_ELUCIDAT);
      } else if (doceboResult && doceboResult.count === 0) {
        setMessage(NO_RESULTS_DOCEBO);
      }
    }
  };

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    if (title !== 'Loading' && selectedType !== undefined) {
      const defaultResource = courseType === ELEARNING ? ELUCIDAT : DOCEBO;
      fetchContent(title, source.token, defaultResource);
      setLastSource(source);
    }
    return () => {
      source.cancel('Operation canceled by the user.');
    };
    // eslint-disable-next-line
  }, [courseType]);

  const onBack = () => navigateToReportPage(history, programId, transactionId, null);

  const onAssign = async (selectedCards, selectedDoceboCards) => {
    const assignContent = async () => {
      setBackdropOpen(true);
      const assignResult = await postAssignedContent(transactionId, courseId,
        selectedCards, selectedDoceboCards);
      setBackdropOpen(false);
      if (assignResult.success) {
        setAssignFailed(false);
        navigateToReportPage(history, programId, transactionId, courseId);
      } else {
        setAssignFailedErrors(assignResult.errors);
        setAssignFailed(true);
      }
    };
    assignContent();
  };

  const onSearch = (searchText) => {
    if (searchText.indexOf('/') !== -1) {
      setMessage('');
      setAlertbarOpen(true);
      setAlertbarText('Forward Slash "/" not allowed in content search');
      return;
    }
    if (lastSource) {
      setMessage('Fetching data...');
      setCards([]);
      lastSource.cancel('Operation canceled by the user.');
    }
    const { CancelToken } = axios;
    const source = CancelToken.source();
    setLastSource(source);
    fetchContent(searchText, source.token);
  };

  const formattedDoceboCards = doceboCards.map((doceboCard) => ({
    id: doceboCard['LO ID'],
    type: doceboCard['Object Type'],
    title: doceboCard.Title,
    versions: doceboCard.versions,
    version: doceboCard.versions[0],
    createdDates: doceboCard.createdDates,
    createdAt: doceboCard.createdDates[0],
    checked: false,
  }));

  return (
    <div style={{ backgroundColor: VERY_LIGHT_GREY, minHeight: '100vh', paddingBottom: '50px' }}>
      <SimpleHeader programSubType={PROGRAM_CREATION} />
      <EnvironmentBanner environment={environment.environment} />
      <ContentSearch
        title={title}
        configId={configId}
        programId={programId}
        courseType={courseType}
        transactionId={transactionId}
        onBack={onBack}
        onAssign={onAssign}
        onSearch={onSearch}
        cards={cards}
        backdropOpen={backdropOpen}
        message={message}
        assignFailed={assignFailed}
        setAssignFailed={setAssignFailed}
        assignFailedErrors={assignFailedErrors}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        selectedResource={selectedResource}
        setSelectedResource={setSelectedResource}
        doceboCards={formattedDoceboCards}
        alertbarOpen={alertbarOpen}
        setAlertbarOpen={setAlertbarOpen}
        alertbarText={alertbarText}
      />
    </div>
  );
};

ContentAssignmentPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      transactionId: PropTypes.string,
      courseId: PropTypes.string,
    }),
  }).isRequired,
};

export default ContentAssignmentPage;
