import {
  SKILL_DATA_FETCH,
  SKILL_DATA_FETCH_SUCCESS,
  SKILL_DATA_FETCH_FAILED,
  UPDATE_SKILL_INFO,
  UPDATE_SKILL_INFO_SUCCESS,
  UPDATE_SKILL_INFO_FAILURE,
  ADD_NEW_SKILL,
  ADD_NEW_SKILL_SUCCESS,
  ADD_NEW_SKILL_FAILURE,
  DELETE_SKILL,
  DELETE_SKILL_STARTED,
  DELETE_SKILL_SUCCESS,
  DELETE_SKILL_FAILED,
  DELETE_SKILL_RESET,
  RESET_MAPPED_SKILL_LP,
} from '../constants';

export function getSkillData(data) {
  return {
    type: SKILL_DATA_FETCH,
    data,
  };
}

export function getSkillDataSuccess(results) {
  return {
    type: SKILL_DATA_FETCH_SUCCESS,
    results,
  };
}

export function getSkillDataFailure(response) {
  return {
    type: SKILL_DATA_FETCH_FAILED,
    response,
  };
}

export function updateSkillData(data) {
  return {
    type: UPDATE_SKILL_INFO,
    data,
  };
}

export function updateSkillSuccess(response) {
  return {
    type: UPDATE_SKILL_INFO_SUCCESS,
    response,
    msg: 'skill has been updated',
  };
}

export function updateSkillFailure(response) {
  return {
    type: UPDATE_SKILL_INFO_FAILURE,
    response,
  };
}

export function addNewSkill(data) {
  return {
    type: ADD_NEW_SKILL,
    data,
  };
}

export function addNewSkillSuccess(response) {
  return {
    type: ADD_NEW_SKILL_SUCCESS,
    response,
    msg: 'new skill has been created',
  };
}

export function addNewSkillFailure(response) {
  return {
    type: ADD_NEW_SKILL_FAILURE,
    response,
  };
}

export function deleteSkill(data) {
  return {
    type: DELETE_SKILL,
    data,
  };
}

export function deleteSkillStarted() {
  return {
    type: DELETE_SKILL_STARTED,
  };
}

export function deleteSkillSuccess(response) {
  return {
    type: DELETE_SKILL_SUCCESS,
    response,
    msg: 'Skill has been deleted',
  };
}

export function deleteSkillFailure(response) {
  return {
    type: DELETE_SKILL_FAILED,
    response,
  };
}

export function deleteSkillReset() {
  return {
    type: DELETE_SKILL_RESET,
  };
}

export function resetMappedSkillLp() {
  return {
    type: RESET_MAPPED_SKILL_LP,
  };
}
