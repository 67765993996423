import {
  MCKINSEY_LIGHT_BLUE, WHITE, MEDIUM_GREY, ERROR_LABEL_RED,
} from '../../stylesheets/colors';

export default {
  errorLine: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: MEDIUM_GREY,
    padding: '10px',
    whiteSpace: 'pre-line',
  },
  errorRow: {
    backgroundColor: MCKINSEY_LIGHT_BLUE,
    color: WHITE,
    padding: '4px',
    fontSize: '75%',
    lineHeight: '1.33em',
    verticalAlign: 'middle',
    minWidth: '45px',
  },
  errorColumn: {
    fontStyle: 'italic',
    fontWeight: 'bold',
    padding: '0px 10px 0 0',
    textTransform: 'capitalize',
    lineHeight: '1em',
    verticalAlign: 'middle',
  },
  iconWrapper: {
    height: '2rem',
    width: '4rem',
    backgroundColor: ERROR_LABEL_RED,
    alignItems: 'center',
    justifyContent: 'center',
    color: WHITE,
    display: 'flex',
    marginRight: '2rem',
  },
};
