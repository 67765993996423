import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import _isArray from 'lodash/isArray';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import {
  useField,
} from 'formik';
import _map from 'lodash/map';
import LinkButton from '../../common/LinkButton';
import AddProjectAndReleaseDetails from '../AddProjectAndReleaseDetails';
import AlertWarning from '../../../assets/icons/alert-red-warning.svg';
import {
  MODERATE_LIGHT_GREY, WHITE, MCKINSEY_BLUE,
} from '../../../stylesheets/colors';
import { AUTO_HIDE_DURATION, ROUTE_REPORT, CONTENT_MANAGEMENT } from '../../../constants';
import { getErrorMessage } from '../../../helpers/apiHelper';
import useNotifications from '../../../hooks/useNotifications';
import { getMaterialType } from '../../../helpers/utils';
import ScormTypeChip from '../../common/ScormTypeChip';

const useStyles = makeStyles({
  styleXs1: {
    flexBasis: '4%',
  },
  addManuallyWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  alertImage: {
    paddingRight: '0.5rem',
  },
  bottomText: {
    fontSize: '1rem',
    padding: '0.5rem 0',
  },
  blankTextSpace: {
    minHeight: '1.25em',
    lineHeight: '1.25',
  },
  cellWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '5rem',
    justifyContent: 'space-between',
  },
  indicator: {
    height: '0.875rem',
    width: ' 0.875rem',
    borderRadius: ' 50%',
    marginRight: '0.5rem',
  },
  indicatorWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  scormText: {
    paddingRight: '0.5rem',
  },
  alertMsgWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const ScormRow = ({
  scorm,
  scormIndex,
  isExpanded,
  tableBodyRow,
  coureseRow,
  courseId,
  programId,
  updateScorm,
  formik,
  courseName,
  progressReport,
  isDDAdmin,
  disabledActions,
}) => {
  const classes = useStyles();

  const { values, setValues } = formik;
  const { notifySuccess, notifyError } = useNotifications();
  const history = useHistory();

  const [openModal, setOpenModal] = useState(false);
  const [showIndicator, setShowIndicator] = useState(false);

  const elucidatData = scorm?.elucidat_data;
  const { label, icon } = getMaterialType(scorm);

  const isElucidatDataExist = elucidatData && !_isArray(elucidatData);

  const {
    elucidat_project_id, elucidat_project_name, elucidat_release_id,
    elucidat_release_version, elucidat_release_description, last_released, triggered_by,
  } = (isElucidatDataExist && elucidatData) || [];

  const isValidElucidatData = elucidat_project_id && elucidat_release_id;

  // eslint-disable-next-line no-unused-vars
  const [selectedField, selectedMeta, selectedHelpers] = useField({
    name: `${coureseRow}.training_materials[${scormIndex}].selected`,
    type: 'checkbox',
  });

  useEffect(() => {
    if (showIndicator) {
      setTimeout(() => {
        setShowIndicator(false);
      }, AUTO_HIDE_DURATION);
    }
  }, [showIndicator]);

  const updateScormDetials = async (data) => {
    try {
      const resp = await updateScorm(courseId, scorm?.id?.split(':')[0], data);
      if (resp?.data?.success === true) {
        // double check we have the release data or not
        const updateCourses = _map(values?.courses,
          (a) => (a.id === courseId ? {
            ...a,
            courseValidRelease: true,
            selected: true,
            training_materials: _map(a.training_materials,
              (scormItem) => (scormItem.id === scorm?.id
                ? {
                  ...scormItem,
                  tmValidRelease: true,
                  elucidat_data: resp?.data?.data,
                  selected: true,
                } : scormItem)),
          } : a));
        setValues({ ...values, courses: updateCourses });
        notifySuccess('SCORM details were successfully saved');
        setShowIndicator(true);
      }
    } catch (err) {
      notifyError(getErrorMessage(err));
    }
  };

  const saveReleaseDetails = (projectDetails, releaseDetials) => {
    setOpenModal(false);
    updateScormDetials({
      project_id: projectDetails?.projectId,
      project_name: projectDetails?.projectName,
      release_id: releaseDetials?.releaseId,
      release_description: releaseDetials?.releaseDescription,
      release_version: releaseDetials?.releaseVersion,
      last_released: releaseDetials?.lastReleased,
    });
  };

  const redirectToCourse = () => {
    history.push(`/${ROUTE_REPORT}/${programId}/${CONTENT_MANAGEMENT}?feature=view-course&id=${courseId}`);
  };

  return (
    <>
      {isExpanded && (
        <>
          <Grid container item xs={12} className={tableBodyRow} style={{ backgroundColor: WHITE, borderTop: `1px solid ${MODERATE_LIGHT_GREY}`, paddingTop: '1rem' }}>
            <Grid item xs={1} className={classes.styleXs1} />
            <Grid item xs={2} />
            <Grid item xs={1} className={`${classes.styleXs1} ${classes.cellWrapper}`}>
              <Checkbox
                color="primary"
                disabled={!isValidElucidatData || !scorm?.scorm_type}
                id={selectedField.name}
                name={selectedField.name}
                checked={scorm?.tmValidRelease ? selectedField.checked : false}
                onClick={(event) => {
                  selectedHelpers.setValue(event.target.checked);
                }}
                style={{ padding: 0 }}
              />
              <Box className={classes.blankTextSpace} />
            </Grid>
            <Grid item xs={3} direction="column" className={classes.cellWrapper}>
              <Typography className={classes.scormText}>{scorm.name}</Typography>
              <>
                {
                  !scorm?.scorm_type ? (
                    <Box className={`${classes.bottomText} ${classes.alertMsgWrapper}`}>
                      <img src={AlertWarning} alt="alert" className={classes.alertImage} />
                      <Typography>
                        SCORM type cannot be blank. Kindly
                        <LinkButton text="edit the training material" onClick={() => redirectToCourse()} color="primary" variant="btnGeneral2" />
                        to assign SCORM type.
                      </Typography>
                    </Box>
                  ) : (
                    <>
                      {
                        isElucidatDataExist ? (
                          <Box className={`${classes.blankTextSpace} ${classes.indicatorWrapper}`}>
                            <Box>
                              {
                                (showIndicator || progressReport.includes(scorm?.id.split(':')[0]))
                                && (
                                  <Box
                                    className={classes.indicator}
                                    style={{ backgroundColor: MCKINSEY_BLUE }}
                                  />
                                )
                              }
                            </Box>
                            <Typography variant="body1" className={classes.bottomText}>
                              Last updated on
                              {' '}
                              {last_released && format(new Date(`${last_released}+00:00`), 'do MMM yy hh:mm a')}
                              {' '}
                              {triggered_by && (
                                <>
                                  by
                                  {' '}
                                  {triggered_by}
                                </>
                              )}
                            </Typography>
                          </Box>
                        ) : (
                          <Box className={classes.blankTextSpace} />
                        )
                      }
                    </>
                  )
                }
              </>
            </Grid>
            <Grid item xs={2}>
              <ScormTypeChip label={label} icon={icon} />
            </Grid>
            {isElucidatDataExist ? (
              <>
                <Grid item xs className={classes.cellWrapper}>
                  <Typography variant="subtitle2" component="span" className={classes.scormText}>
                    {elucidat_project_name}
                    {' '}
                    -
                    {' '}
                    [
                    {elucidat_project_id}
                    ]
                  </Typography>
                  <Box className={classes.blankTextSpace} />
                </Grid>
                <Grid item xs className={classes.cellWrapper}>
                  <Typography variant="subtitle2" component="span" className={classes.scormText}>
                    Version
                    {' '}
                    {elucidat_release_version}
                    {' '}
                    (
                    {elucidat_release_id}
                    )
                    /
                    (
                    {elucidat_release_description}
                    )
                  </Typography>
                  <Box className={classes.blankTextSpace} />
                </Grid>
              </>
            ) : (
              <>
                {
                  scorm?.scorm_type && (
                    <Grid item xs className={`${classes.cellWrapper}`}>
                      <Box className={classes.addManuallyWrapper}>
                        <img src={AlertWarning} alt="alert" className={classes.alertImage} />
                        <Typography component="body1">
                          This SCORM was created outside of the Ops Portal, please
                          <LinkButton
                            text="manually assign"
                            disabledSynchBtn={isDDAdmin || disabledActions}
                            onClick={() => setOpenModal(true)}
                            color="primary"
                            variant="btnGeneral2"
                          />
                          the Release ID and Project ID
                        </Typography>
                      </Box>
                      <Box className={classes.blankTextSpace} />
                    </Grid>
                  )
                }
              </>
            )}
            <Grid item xs={1} style={{ flexBasis: '5%' }} />
          </Grid>
          {
            openModal && (
              <AddProjectAndReleaseDetails
                open={openModal}
                onClose={() => setOpenModal(false)}
                elucidatRelease={elucidatData || []}
                onConfirm={saveReleaseDetails}
                programId={programId}
                courseId={courseId}
                courseName={courseName}
                scormName={scorm?.name}
              />
            )
          }
        </>
      )}

    </>
  );
};

ScormRow.propTypes = {
  scorm: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tmValidRelease: PropTypes.bool.isRequired,
    scorm_type: PropTypes.string.isRequired,
    elucidat_data: PropTypes.shape({
      elucidat_project_id: PropTypes.string.isRequired,
      elucidat_project_name: PropTypes.string.isRequired,
      elucidat_release_id: PropTypes.string.isRequired,
      elucidat_release_version: PropTypes.string.isRequired,
      elucidat_release_description: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  formik: PropTypes.shape({
    values: PropTypes.array.isRequired,
    errors: PropTypes.object.isRequired,
    validateForm: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
  }).isRequired,
  tableBodyRow: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  scormIndex: PropTypes.string.isRequired,
  coureseRow: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired,
  updateScorm: PropTypes.func.isRequired,
  courseName: PropTypes.string.isRequired,
  progressReport: PropTypes.array.isRequired,
  isDDAdmin: PropTypes.bool.isRequired,
  disabledActions: PropTypes.bool.isRequired,
};

export default ScormRow;
