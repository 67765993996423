import React from 'react';

import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  ACCORDION_GREY, DARK_GREY, SWITCH_GREY,
} from '../../stylesheets/colors';

import uploadIcon from '../../assets/icons/upload-icon.svg';
import disabledUploadIcon from '../../assets/icons/disabled-upload-icon.svg';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.secondary.main,
    cursor: 'pointer',
    boxShadow: '5px 13px 24px 0 rgba(0, 0, 0, 0.08)',
    padding: '3.75rem',
    '&.disabled': {
      backgroundColor: SWITCH_GREY,
      cursor: 'not-allowed',
    },
  },
  uploadIcon: {
    flex: 1,
    maxWidth: '12.5rem',
    maxHeight: '7.8rem',
    width: '7.5vw',
  },
  heading: {
    flex: 1,
    textAlign: 'center',
    fontWeight: 400,
    color: ({ isDisabled }) => (isDisabled ? ACCORDION_GREY : DARK_GREY),
    marginTop: '2rem',
    // lineHeight: '3rem',
  },
  subHeading: {
    flex: 1,
    textAlign: 'center',
    // fontWeight: 400,
    color: ({ isDisabled }) => (isDisabled ? ACCORDION_GREY : DARK_GREY),
    lineHeight: '2rem',
  },
}));

const MainButton = ({ title, subtitle, isDisabled }) => {
  const classes = useStyles({ isDisabled });
  return (
    <div key={`uploaddisabled${isDisabled}`} className={`${isDisabled ? 'disabled' : ''} ${classes.wrapper}`}>
      <img src={isDisabled ? disabledUploadIcon : uploadIcon} alt="uploadIcon" className={classes.uploadIcon} />
      <Typography variant="subtitle1" className={classes.heading}>{title}</Typography>
      <Typography variant="body1" className={classes.subHeading}>
        {subtitle}
      </Typography>
    </div>
  );
};

MainButton.propTypes = {
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string,
  isDisabled: PropTypes.bool,
};

MainButton.defaultProps = {
  title: 'Upload Template Spreadsheet',
  isDisabled: false,
};

export default MainButton;
