import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { LIGHT_MEDIUM_GREY, ERROR_RED } from '../../stylesheets/colors';
import theme from '../../stylesheets/theme';
import { getOptionsBgColor } from '../../helpers/utils';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginBottom: '.75rem',
  },
  labelWrapper: {
    display: 'flex',
    flex: 0.25,
    fontWeight: 'bold',
    alignItems: 'flex-end',
  },
  selectWrapper: {
    flex: 0.75,
    width: '100%',
    paddingTop: '8px',
    minHeight: '40px',
    maxHeight: '2.5rem',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  customSelect: {
    '& div': {
      borderColor: ({ isError }) => (isError ? ERROR_RED : LIGHT_MEDIUM_GREY),
    },
  },
}));

const selectStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0px',
    borderColor: LIGHT_MEDIUM_GREY,
    minHeight: 'unset',
    height: '32px',
    paddingTop: '0px',
    paddingBottom: '0px',
    '&:hover': {
      borderColor: LIGHT_MEDIUM_GREY,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '32px',
    minHeight: 'unset',
    fontSize: '1.125rem',
    color: '#000',
    fontWeight: 400,

    'p, span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '32px',
    minHeight: 'unset',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: '0px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: LIGHT_MEDIUM_GREY,
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamily,
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: '1.125rem',
    fontFamily: theme.typography.fontFamily,
    zIndex: 9999,
  }),
  menuPortal: (base, { scrollValue }) => ({ top: scrollValue, ...base, zIndex: 9999 }),
  option: (provided, { isDisabled, isFocused, isSelected }) => (
    {
      ...provided,
      backgroundColor: getOptionsBgColor({ isDisabled, isFocused, isSelected }),
      cursor: isDisabled ? 'not-allowed' : 'default',
    }
  ),
};

const Select = ({
  defaultValue, options, placeholder, maxMenuHeight, onChange, disabled, isMulti, isSearchable,
  selectRef, isError,
}) => {
  const classes = useStyles({ isError });
  const [selected, setSelected] = useState('');
  const [top, setTop] = useState({ top: 0 });

  useEffect(() => {
    setSelected(defaultValue || '');
  }, [defaultValue]);

  let optionalFields = {};
  if (defaultValue?.label) {
    optionalFields = { label: defaultValue?.label };
  }

  const handleCloseMenuOnScroll = () => {
    // forcing select menu re-rendering on scroll
    setTop({ top: 0 });
    // as expected do not close the menu on scroll
    return false;
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.selectWrapper}>
        <ReactSelect
          className={classes.customSelect}
          ref={selectRef}
          styles={selectStyles}
          placeholder={placeholder}
          options={options}
          value={selected}
          onChange={onChange}
          maxMenuHeight={maxMenuHeight}
          menuPortalTarget={document.body}
          isDisabled={disabled}
          isMulti={isMulti}
          isClearable={false}
          isSearchable={isSearchable}
          menuPosition="fixed"
          menuPlacement={'auto'}
          scrollValue={top.top}
          closeMenuOnScroll={handleCloseMenuOnScroll}
          {...optionalFields}
        />
      </div>
    </div>
  );
};

Select.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  maxMenuHeight: PropTypes.number,
  defaultValue: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  selectRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  isError: PropTypes.bool,
};

Select.defaultProps = {
  disabled: false,
  isMulti: false,
  maxMenuHeight: 300,
  placeholder: '',
  isSearchable: true,
  selectRef: null,
  isError: false,
};

export default Select;
