import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { ContentDetailsSection, ContentDetailsLabel } from '../ContentManagement/ContentDetailsSection';
import ContentDetailsBlock from '../ContentManagement/ContentDetailsBlock';
import LoadingCircle from '../common/LoadingCircle/LoadingCircle';
import TableRC from '../common/TableRC';

const JournalDetailsSection = ({
  values, handleChange, isLoading, customLabel, isDDAdmin,
}) => (
  <ContentDetailsBlock
    headerText="Journal Details"
    fieldRedirectOnLabel={!isDDAdmin && values?.id && `/contentLibrary/lti/journals/${values?.id}`}
    noPaper
  >
    {isLoading ? <LoadingCircle labelText="Loading Journal Details" /> : (
      <>
        <Grid item xs={12} md={12}>
          <ContentDetailsLabel
            label="Journal"
            labelCustomCss={customLabel}
          />
        </Grid>
        <ContentDetailsSection
          label="Title"
          name="journal_title"
          type="text"
          value={values.journal_title}
          handleChange={handleChange}
        />
        <ContentDetailsSection
          label="ID"
          name="journal_id"
          type="text"
          value={values.journal_id}
          handleChange={handleChange}
        />

        <Grid item xs={12} md={12} sx={{ padding: '1rem 0' }}>
          <ContentDetailsLabel
            label="Pages"
            labelCustomCss={customLabel}
          />
          <Grid item xs={12} md={12} sx={{ padding: '1rem 0' }}>
            <TableRC
              columns={[
                { title: 'Page Title', property: 'page_title', width: '50%' },
                { title: 'ID', property: 'page_id' },
              ]}
              data={values?.pages}
              noDataMessage="Pages unavailable for this learning plan."
            />
          </Grid>
        </Grid>
      </>
    )}
  </ContentDetailsBlock>
);

JournalDetailsSection.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  customLabel: PropTypes.string.isRequired,
  isDDAdmin: PropTypes.bool.isRequired,
};

export default JournalDetailsSection;
