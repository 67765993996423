// General colors
export const LIGHT_PURPLE = '#ebebf6';
export const PURPLE = '#9671f1';
export const FADED_BLUE = '#e6ecff';
export const SUCCESS_GREEN = '#3aa537';
export const SUCCESS_GREEN2 = '#007f26';
export const SUCCESS_GREEN3 = '#d0ffd5';
export const LIGHT_GREEN = '#d1f4dd';
export const DARK_GREEN = '#529d1d';
export const DARK_GREEN1 = '#00611b';
export const ERROR_RED = '#e12e40';
export const ERROR_RED_BG = '#fce7e6';
export const ERROR_LABEL_RED = '#e30000';
export const ERROR_DEEP_BLUE = '#051c2c';
export const ERROR_ORANGE = '#ffbe00';
export const ERROR_ORANGE1 = '#fde7e7';
export const ORANGE = '#f18571';
export const FLAG_RED = '#ff2b2b';
export const DANGER_LIGHT_RED = '#FEEBEB';
export const DANGER_RED = '#C8302D';
export const MCKINSEY_BLUE = '#2251ff';
export const MCKINSEY_LIGHT_BLUE = '#00a9f4';
export const MCKINSEY_BLUE_DISABLED = '#7D97FF';
export const BACKDROP_BLUE = 'rgba(5, 28, 44, 0.85)';
export const PROGRESS_CIRCLE_BLUE = 'rgba(5, 28, 44)';
export const BACKDROP_GREY = 'rgba(230,230,230,0.28)';
export const HEADER_BLUE = '#082644';

// Shades of grey
export const BLACK = '#000000';
export const WHITE = '#ffffff';
export const VERY_LIGHT_GREY = '#f9f9f9';
export const VERY_LIGHT_GREY_V2 = '#f7f7f7';
export const MODERATE_LIGHT_GREY = '#e6e6e6';
export const LIGHT_MEDIUM_GREY = '#b3b3b3';
export const MEDIUM_GREY = '#4d4d4d';
export const DARK_MEDIUM_GREY = '#7f7f7f';
export const MODERATE_DARK_GREY = '#333333';
export const DARK_GREY = '#051c2c';
export const PALE_GREY = '#f0f0f0';
export const PLATINUM_GREY = '#E8E9E9';
export const GREY = '#e3e6fc';
export const VERY_DARK_GREY = '#656565';
export const YELLOW1 = '#906D04';
export const PINK1 = '#9c217d';
export const PINK2 = '#CD3030';
export const GREEN1 = '#12a195';
export const GREEN2 = '#3B894D';
export const BLUE1 = '#0291dc';

// Component specific colors
export const BANNER_FOREGROUND = '#1b1b19';
export const LOGIN_LIGHT_GREY = '#4d4d4d';
export const HOME_SIDEBAR_BG = '#051c2c';
export const HEADER_SEPARATOR = '#e6e6e6';
export const ENV_BANNER_COLOR = '#1e32e6';
export const REPORT_ERROR_RED = '#d0021b';
export const FILL_ERROR_RED = '#d51f31';
export const PIE_CHART_BLUE = '#39bdf3';
export const PIE_CHART_LIGHT_BLUE = '#dff4fd';
export const PIE_CHART_RED = '#e5546c';
export const PIE_CHART_MAROON = '#d14c62';
export const PIE_CHART_BLACK = '#041b2b';
export const SWITCH_GREY = '#d0d0d0';
export const TEXT_GREEN = '#006200';
export const LIGHT_YELLOW = '#fff3ce';
export const DARK_YELLOW = '#6a4f00';
export const SKY_BLUE = '#dae2ff';
export const GSR_BG_CHANTILLY = '#F7BAF6';
export const GSR_FG_FUCHSIA = '#CC49C2';
export const SKILL_BG_YELLOW = '#EB9B00';
export const SKILL_FG_AMBER_YELLOW = '#FFE4B3';

// Stage colors
export const STAGE_HEADER_UNKNOWN_COLOR = MCKINSEY_BLUE;
export const STAGE_HEADER_REPLICATED_COLOR = '#8B008B';
export const STAGE_HEADER_IN_BUILD_COLOR = '#007000';
export const STAGE_HEADER_QA_COLOR = '#ffee58';
export const STAGE_HEADER_LAUNCH_PREP_COLOR = '#ffc156';
export const STAGE_HEADER_LAUNCHED_COLOR = '#d2222d';
export const STAGE_HEADER_DELETED_COLOR = '#e12e40';

export const STAGE_UNKNOWN_COLOR = '#f5ead6';
export const STAGE_REPLICATED_COLOR = '#e5f0ff';
export const STAGE_IN_BUILD_COLOR = '#FEDE7E';
export const STAGE_QA_COLOR = '#fccffc';
export const STAGE_LAUNCH_PREP_COLOR = '#b6fbf6';
export const STAGE_LAUNCHED_COLOR = '#c4eccd';
export const STAGE_DELETED_COLOR = '#feebeb';
export const STAGE_MODAL_OTHER_COLOR = '#007cb6';
export const STAGE_MODAL_LAUNCHED_COLOR = '#f84569';

export const STAGE_REPLICATED_BGCOLOR = '#5e9def';
export const STAGE_LAUNCH_PREP_BGCOLOR = '#12a195';
export const STAGE_IN_BUILD_BGCOLOR = '#ffd048';
export const STAGE_DELETED_BGCOLOR = '#cd3030';
export const STAGE_UNKNOWN_BGCOLOR = '#d8c6a6';
export const STAGE_QA_BGCOLOR = '#db57db';
export const STAGE_LAUNCHED_BGCOLOR = '#92cc9f';

// Keys
export const KEY_BLACK = 'black';

// USER CIRCLE COLORS
export const USER_CIRCLE_LIGHT_BG = '#00A9F4';
export const USER_CIRCLE_DARK_BG = '#2251ff';
export const USER_CIRCLE_LIGHT_TEXT = '#ffffff';
export const USER_CIRCLE_DARK_TEXT = '#ffffff';

// Course Material type colors
export const COURSE_MATERIAL_TYPE_COLOR_LOOKUP = {
  slides: '#00a9f4',
  'file upload': '#faa082',
  html: '#8c5ac8',
  survey: '#00a9f4',
  scorm: '#faa082',
  test: '#8c5ac8',
  tincan: '#00a9f4',
  video: '#faa082',
  pdf: '#8c5ac8',
  text: '#00a9f4',
  course: '#faa082',
  elucidat: '#00a9f4',
  file: '#faa082',
  htmlpage: '#8c5ac8',
  scormorg: '#00a9f4',
  authoring: '#faa082',
  poll: '#8c5ac8',
};

// Drag n Drop colors
export const DROPPABLE_COLOR = '#f0f0f0';

// Accordion
export const ACCORDION_GREY = '#757575';

// Cancel Suggestion Modal
export const CANCEL_SUGGESTION_BACKGROUND_COLOR = '#afc3ff';
export const CANCEL_SUGGESTION_FONT_COLOR = '#1e32e6';

// Chip Colors
export const CHIP_LABEL_BLUE = '#0099b7';
export const RED_DARK = '#a41523';

// Status Colors
export const MCKINSEY_ORANGE = '#faa082';
export const BLUE_TRANSITION = '#027ab1';

// Dropdown
export const DROPDOWN_SELECT = '#2684FF';
export const DROPDOWN_HOVER = '#DEEBFF';

export const ELECTRIC_BLUE = '#2251FF';
