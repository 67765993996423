import * as React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import FormattedTypography from '../../common/FormattedTypography';
import {
  MEDIUM_GREY,
} from '../../../stylesheets/colors';
import CustomUploadModal from '../../common/CustomUploadModal';
import ErrorRoundIcon from '../../../assets/icons/alert-red-warning.svg';

const useStyles = makeStyles({
  subHeadingSection: {
    '& p': {
      color: MEDIUM_GREY,
    },
  },
  progressRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.17,
    justifyContent: 'center',
    maxHeight: '10rem',
    transform: 'scale(0.4) translate(0, -100%)',
    padding: '2rem 0',
  },
});

const ProgressFailedModal = ({
  open, onClose, fileName, openAddScorm,
}) => {
  const classes = useStyles({});

  return (
    <CustomUploadModal
      open={open}
      onClose={onClose}
      heading={(
        <DialogTitle>
          <FormattedTypography
            body="Failed to Upload SCORM package"
            subHeading={`Failed to upload  SCORM ${fileName}.`}
            subHeadingSection={classes.subHeadingSection}
          />
        </DialogTitle>
      )}
      logo={(
        <Box>
          <img src={ErrorRoundIcon} alt="progress-failed" width={40} height={40} />
        </Box>
      )}
      primaryBtnText="Try Again"
      primaryBtnProps={{
        onClick: () => openAddScorm(),
      }}
    >
      <></>
    </CustomUploadModal>
  );
};

ProgressFailedModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired,
  openAddScorm: PropTypes.func.isRequired,
};

export default ProgressFailedModal;
