import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { InputAdornment, IconButton } from '@material-ui/core';
import { useFormik } from 'formik';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Typography from '@material-ui/core/Typography';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import WarningIcon from '@mui/icons-material/Warning';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ConfirmationModal from '../common/ConfirmationModal';
import FormattedTypography from '../common/FormattedTypography';
import LabledTextField from '../common/LabledTextField';
import {
  ERROR_RED, MODERATE_DARK_GREY, SUCCESS_GREEN, LIGHT_MEDIUM_GREY,
} from '../../stylesheets/colors';
import ErrorRoundIcon from '../../assets/icons/cross-outline2.svg';
import CheckRoundIcon from '../../assets/icons/green-round-check.svg';
import WarningInfoAlert from '../common/WarningInfoAlert';
import { checkSequentialNumber, checkSequentialCharcter } from '../../helpers/utils';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5rem',
  },
  width60: {
    width: '80%',
  },
  greyText: {
    color: MODERATE_DARK_GREY,
    fontWeight: 600,
    fontSize: '1.25rem',
  },
  modalHeight: {
    minHeight: '30rem',
  },
  errorBlock: {
    marginBottom: '1.2rem',
  },
  modalStyle: {
    maxWidth: '60rem',
  },
  errorRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '0.7rem',

    '& img': {
      width: '1.5rem',
      height: '1.5rem',
      marginRight: '1rem',
    },
  },
  checkbox: {
    height: '1.125rem',
    color: LIGHT_MEDIUM_GREY,
    opacity: 1,
    margin: '0 2.5rem 0 0.75rem',
    '& > svg': {
      width: '2rem',
      height: '2rem',
    },
  },
  iconBtn: {
    padding: '1px',
    fontSize: '1.6rem',
  },
  headingText: {
    paddingTop: '1.5rem',
    fontWeight: 'bold',
  },
});

const UpdatePasswordModal = ({
  open, onClose, loadingText, onChangePassword, isLoading,
}) => {
  const classes = useStyles();

  const specialCharsValidation = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  const hasNumberValidation = /\d/;
  const sequentialValidation = /(.)\1\1/i;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [sequentialError, setSequentialError] = useState('');
  const toggleShowPassword = (name) => {
    if (name === 'password') {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const validate = (values) => {
    const errors = {
      confirmPasswordError: true, // true means error exists
      min_characters: true,
      no_special_char: true,
      no_number: true,
      repeating_char: true,
    };

    if (values.password && values.confirmPassword === values.password) {
      delete errors.confirmPasswordError;
    }

    if (values.password.length >= 12) {
      delete errors.min_characters;
    }
    if (specialCharsValidation.test(values.password)) {
      delete errors.no_special_char;
    }
    if (hasNumberValidation.test(values.password)) {
      delete errors.no_number;
    }
    if (!sequentialValidation.test(values.password)) {
      delete errors.repeating_char;
    }

    return errors;
  };

  const {
    values, handleSubmit, handleChange, errors, resetForm, setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    validate,
    initialValues: { password: '', confirmPassword: '', forcePassword: false },
    initialErrors: {
      confirmPasswordError: true, // true means error exists
      min_characters: true,
      no_special_char: true,
      no_number: true,
      repeating_char: true,
    },
    onSubmit: (payload) => {
      if (!checkSequentialNumber(payload.password)) {
        setSequentialError('Password can not contain 3 or more consecutive numbers');
      } else if (!checkSequentialCharcter(payload.password)) {
        setSequentialError('Password can not contain 4 or more alphabetically consecutive characters');
      } else {
        onChangePassword(payload);
      }
    },
  });

  useEffect(() => {
    if (open) {
      resetForm();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const {
    confirmPasswordError, min_characters, no_special_char, no_number, repeating_char,
  } = errors;

  const resetFormBeforeClose = () => {
    onClose();
  };

  const getErrorBlock = useCallback((showErrorIcon, errorMsg) => (
    <Box className={classes.errorRow}>
      {showErrorIcon ? <img src={ErrorRoundIcon} alt="error-cross" /> : <img src={CheckRoundIcon} alt="success-tick" /> }
      <Typography style={{ color: showErrorIcon ? ERROR_RED : SUCCESS_GREEN }} variant="body1">{errorMsg}</Typography>
    </Box>
  ),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  const handlePasswordChange = useCallback((e) => {
    if (e.target.value.indexOf(' ') >= 0) {
      // restrict space
      return;
    }
    setSequentialError('');
    handleChange(e);
  }, [handleChange]);

  return (
    <ConfirmationModal
      open={open}
      isLoading={isLoading}
      onClose={isLoading ? null : resetFormBeforeClose}
      modalWidth={85}
      minHeightClass={classes.modalHeight}
      loadingText={loadingText}
      title={(<FormattedTypography className={classes.headingText} body="Update Password" subHeading="Change password here for users in the selected cohort or learning plan who have not logged in to Docebo." />)}
      modalStyle={classes.modalStyle}
      dialogContentClass={classes.width60}
      content={(
        <Container className={classes.wrapper}>
          <LabledTextField
            label="Password"
            placeholder={'Type new password'}
            type={showPassword ? 'text' : 'password'}
            name="password"
            labelClass={classes.greyText}
            value={values.password}
            onChange={handlePasswordChange}
            inputProps={{
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => { toggleShowPassword('password'); }}
                      onMouseDown={() => { toggleShowPassword('password'); }}
                      className={classes.iconBtn}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
          <Box className={classes.errorBlock}>
            {
              sequentialError && getErrorBlock(true, sequentialError)
            }
            {!sequentialError && getErrorBlock(min_characters, 'Password must be minimum 12 characters')}
            {!sequentialError && getErrorBlock(no_special_char, 'Password must have at least one special character')}
            {!sequentialError && getErrorBlock(no_number, 'Password must have at least one numeric value')}
            {!sequentialError && getErrorBlock(repeating_char, 'Password cannot contain 3 or more repeating characters')}
          </Box>

          <LabledTextField
            label="Retype Password"
            placeholder={'Retype password'}
            type={showConfirmPassword ? 'text' : 'password'}
            name="confirmPassword"
            labelClass={classes.greyText}
            value={values.confirmPassword}
            onChange={handlePasswordChange}
            inputProps={{
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => { toggleShowPassword('confirmPassword'); }}
                      onMouseDown={() => { toggleShowPassword('confirmPassword'); }}
                      className={classes.iconBtn}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
          <Box className={classes.errorBlock}>
            <Box className={classes.errorRow}>
              {!sequentialError && getErrorBlock(confirmPasswordError, 'Confirm password must match new password')}
            </Box>
          </Box>
          <Box>
            <FormControlLabel
              key="force_password"
              value="Force Password Change"
              className={classes.checkboxLabel}
              control={(
                <Checkbox
                  className={classes.checkbox}
                  checked={values.forcePassword}
                  name="force_password_change"
                  classes={{
                    checked: classes.checkboxChecked,
                  }}
                  onChange={(e) => setFieldValue('forcePassword', e.target.checked)}
                />
                  )}
              label="Force Password Change"
            />
            <WarningInfoAlert severity="info" icon={<WarningIcon fontSize="medium" />} customStyles={classes.customAlert}>
              Force users to change their password at their first login
            </WarningInfoAlert>
          </Box>
        </Container>
      )}
      actions={(
        <>
          <Button color="primary" variant="outlined" onClick={resetFormBeforeClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={min_characters
              || no_number
              || no_special_char || repeating_char || confirmPasswordError}
          >
            Update Password
          </Button>
        </>
      )}
    />
  );
};

UpdatePasswordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  loadingText: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChangePassword: PropTypes.func.isRequired,
};

export default UpdatePasswordModal;
