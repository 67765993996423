import config from '../../../config';
import request from '../../../helpers/apiHelper';

// eslint-disable-next-line import/prefer-default-export
export const validateXLSXApiCall = async (programSubType, data) => {
  const url = `${config.ROUTES.VALIDATE_XLSX}/${programSubType}`;
  const options = { url, method: 'POST', data };
  try {
    const result = await request(options);
    return result.data;
  } catch (e) {
    if (e.response.status === 400) {
      return e.response.data;
    }
    return { errors: [{ message: e.toString() }] };
  }
};

export const getProgress = async (transactionId, signal = undefined) => {
  const url = `${config.ROUTES.GET_PROGRESS}/${transactionId}`;
  const options = { url, method: 'GET', signal };
  const result = await request(options);

  return result;
};

export const getReport = async (transactionId) => {
  const url = `${config.ROUTES.GET_REPORT}/${transactionId}`;
  const options = { url, method: 'GET' };
  const result = await request(options);

  return result.data;
};

export const getTransaction = async (feature) => {
  const url = `${config.ROUTES.GET_TRANSACTION}${feature}&latest=true`;
  const options = { url, method: 'GET' };
  const result = await request(options);
  return result;
};
