import React, { useCallback, useContext } from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@mui/material/Container';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ConfirmationModal from '../../common/ConfirmationModal';
import FormattedTypography from '../../common/FormattedTypography';
import CustomSelect from '../../common/CustomSelect';
import { SCORM_TYPE_SELECT_OPTIONS, ADMIN_GROUP } from '../../../constants';
import ErrorText from '../../common/ErrorText';
import { updateScormTrainingMaterial } from '../../../containers/ContentManagement/apis';
import { getErrorMessage } from '../../../helpers/apiHelper';
import MyContext from '../../../context';
import { isScormUnassigned, isEmpty, validateUrl } from '../../../helpers/utils';
import ScormLink from '../AddNewScormModal/ScormLink';

const useStyles = makeStyles({
  heading: {
    padding: '2rem',
  },
  subHeading: {
    '& div': {
      fontSize: '1.5rem',
    },
  },
  dropdown: {
    padding: '0.5rem 0',
  },
  labelClass: {
    margin: '0.8rem 0rem',
    fontSize: '1.125rem',
    fontWeight: 'normal',
  },
  width70: {
    width: '70%',
  },
  modalHeight: {
    minHeight: '450px',
    height: 'auto',
  },
});

const EditScormModal = ({
  open, onClose, data, loading, showAlert,
}) => {
  const classes = useStyles();
  const { role } = useContext(MyContext);
  const isAdmin = ADMIN_GROUP.includes(role);

  const validationSchema = Yup.object({
    scorm_type: Yup.string().when('showScormType', {
      is: isAdmin || isScormUnassigned(data),
      then: Yup.string().required('Please select scorm type').nullable('Please select scorm type'),
    }),
    scorm_link: Yup.string().notRequired()
      .when('scorm_type', {
        is: (val) => val !== 'Elucidat',
        then: validateUrl,
      }),
    box_link: Yup.string().notRequired()
      .when('scorm_type', {
        is: (val) => val !== 'Elucidat',
        then: validateUrl,
      }),
  });

  const generateFormData = useCallback(
    (formValues) => {
      const formData = new FormData();
      formData.append('tm_id', formValues.tm_id);
      formData.append('course_id', formValues.course_id);
      formData.append('scorm_type', formValues.scorm_type);
      if (formValues.scorm_type !== 'Elucidat') {
        formData.append('scorm_link', formValues.scorm_link);
        formData.append('box_link', formValues.box_link);
      }
      return formData;
    },
    [],
  );

  const formik = useFormik({
    initialValues: {
      course_id: data?.course_id,
      tm_id: data?.id,
      scorm_type: data.scorm_type,
      scorm_link: isEmpty(data.scorm_link) ? '' : data.scorm_link,
      box_link: isEmpty(data.box_link) ? '' : data.box_link,
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema,
    onSubmit: (values, bag) => {
      const updateSormTmValues = async () => {
        try {
          const resp = await updateScormTrainingMaterial(generateFormData(values));
          if (resp.data.success) {
            showAlert('success', resp.data.message);
          } else {
            showAlert('error', resp.data.message);
          }
        } catch (err) {
          showAlert('error', getErrorMessage(err));
        } finally {
          bag.resetForm();
          onClose();
        }
      };
      updateSormTmValues();
    },
  });

  const {
    handleSubmit, handleChange, values, errors, touched,
    setFieldValue, setFieldTouched,
  } = formik;

  const {
    scorm_type, scorm_link, box_link,
  } = values;

  return (
    <ConfirmationModal
      open={open}
      isLoading={loading}
      onClose={onClose}
      breakpoint="md"
      dialogContentClass={classes.width70}
      minHeightClass={classes.modalHeight}
      title={(
        <Container className={classes.heading}>
          <FormattedTypography
            variant="h1"
            body="Edit training material"
            subHeading={`Edit SCORM - ${data?.name}.`}
            helperNode="SCORM package, title, description and view mode can be updated via Docebo."
            subHeadingSection={classes.subHeading}
          />
        </Container>
      )}
      content={(
        <Container sx={{ width: '100%' }}>
          <Box>
            <Typography variant="h1">
              Edit SCORM details
            </Typography>
            <Typography variant="body1" className={classes.subHeading}>
              Values entered will not get updated on Docebo.
            </Typography>
          </Box>
          {(isAdmin || isScormUnassigned(data)) && (
            <>
              <CustomSelect
                label="SCORM Type"
                placeholder="Select type"
                options={SCORM_TYPE_SELECT_OPTIONS}
                className={classes.dropdown}
                labelClass={classes.labelClass}
                defaultValue={SCORM_TYPE_SELECT_OPTIONS.find((el) => el.value === scorm_type)}
                required
                onChange={(event) => {
                  setFieldValue('scorm_type', event.value, false);
                  setTimeout(() => setFieldTouched('scorm_type', true));
                }}
              />
              <ErrorText
                touchedField={touched.scorm_type}
                errorField={errors.scorm_type}
              />
            </>
          )}
          {scorm_type && scorm_type !== 'Elucidat' && (
            <ScormLink
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              box_link={box_link}
              scorm_link={scorm_link}
            />
          )}
        </Container>
      )}
      actions={(
        <>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            Save Changes
          </Button>
        </>
      )}
    />
  );
};
EditScormModal.defaultProps = {
  data: {
    scorm_link: PropTypes.string,
    box_link: PropTypes.string,
  },
};

EditScormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    course_id: PropTypes.string.isRequired,
    scorm_type: PropTypes.string.isRequired,
    scorm_link: PropTypes.string,
    box_link: PropTypes.string,
  }),
  showAlert: PropTypes.func.isRequired,
};

export default EditScormModal;
