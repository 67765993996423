import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import WarningIcon from '@mui/icons-material/Warning';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { get } from 'lodash';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Table, TableContainer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@material-ui/core/Button';
import { Divider } from '@material-ui/core';
import BreadCrumbHOC from '../BreadCrumbHOC';
import CustomStyledSwitch from '../common/CustomStyledSwitch';
import {
  AUTO_HIDE_DURATION,
  USER_ENROLLED_LP_TABLE_HEAD_ATTRIBUTES, ROLE_PROD,
} from '../../constants';
import { MODERATE_LIGHT_GREY, SWITCH_GREY } from '../../stylesheets/colors';
import UserEditCell from './UserEditCell';
import EnhancedTableHeader from '../Table/EnhancedTableHeader';
import {
  getComparator, tableSort, checkSequentialNumber, checkSequentialCharcter,
} from '../../helpers/utils';
import LoadingCircle from '../common/LoadingCircle/LoadingCircle';
import InfoTooltip from '../common/InfoTooltip';
import { dateFormatter, reportPageDateTimeFormatter } from '../../helpers/formattingHelpers';
import UserEnrolledLpTableBody from './UserEnrolledLpTableBody';
import CustomSnackbar from '../common/CustomSnackbar';
import EditIcon from '../../assets/icons/user-interface-edit.svg';
import EditUserModal from './EditUserModal';
import AlertBarWithAction from '../common/AlertBarWithAction';
import EditUserPasswordSection from './EditUserPassworSection';
import WarningInfoAlert from '../common/WarningInfoAlert';
import MyContext from '../../context';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  promptWrapper: {
    padding: '1.25rem 2rem 2rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  sectionPadding: {
    padding: '2rem 2rem 2rem 2rem',
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginLeft: '0.625rem',
      fontSize: '1.2rem',
    },
  },
  passwordSectionPadding: {
    padding: '0rem 2rem 2rem 2rem',
    display: 'flex',

    '& svg': {
      marginLeft: '0.625rem',
      fontSize: '1.2rem',
    },
  },
  table: {
    '& .MuiTableCell-root': {
      padding: '0.7rem',
    },
    '& th.MuiTableCell-root:first-child > div': {
      marginLeft: '1.5rem',
    },
    '& td.MuiTableCell-root:first-child p': {
      marginLeft: '1.5rem',
    },
    '& th.MuiTableCell-root': {
      lineHeight: '1.92rem',
    },
  },
  userMetadata: {
    borderBottom: `1px solid ${SWITCH_GREY}`,
    paddingBottom: '1.3rem',

    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: '1.5rem',
      marginBottom: '0.7rem',
      alignItems: 'center',

      '& > div': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  userStatus: {
    padding: '0.125rem 0.5rem',
    border: `1px solid ${MODERATE_LIGHT_GREY}`,
    background: MODERATE_LIGHT_GREY,
    borderRadius: '4px',
    marginLeft: '1rem',
  },
  userContainerBorder: {
    borderTop: `1px solid ${MODERATE_LIGHT_GREY}`,
  },
  boldText: {
    fontWeight: 'bold',
  },
  tableContainer: {
    maxHeight: '24vh',
    marginBottom: '3rem',
    flex: 1,
  },
  editIcon: {
    marginLeft: '1rem',
    cursor: 'pointer',
  },
  spaceBtw: {
    justifyContent: 'space-between',
  },
  resetBtn: {
    fontSize: '1.125rem',
  },
  doceboEditSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  passwordSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  doceboEditButtonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',

    '& > button:nth-child(2)': {
      marginLeft: '1.25rem',
    },
  },
  doceboDetailsEditContainer: {
    marginTop: '1.5rem',
    minHeight: '35vh',
  },
  customAlert: {
    paddingTop: '1rem',
  },
  toggleWrapper: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    gap: '0.8rem',
    alignItems: 'center',
    marginTop: '1.4rem',
  },
}));

const UserDetail = (props) => {
  const {
    userDetails, getUserDetails, match, clearAlertBar,
    setUserDetailsSuccess, setSnackbar, toggleAlertBar,
    fetchLanguages, saveUserDetails, updateUserDetailsStore,
  } = props;
  const history = useHistory();
  const { role } = useContext(MyContext);
  const isCpRole = role === ROLE_PROD;
  const classes = useStyles();
  const userId = get(match, 'params.programSubType');
  const [orderSort, setOrderSort] = useState('');
  const [showUserEditModal, setShowUserEditModal] = useState(false);
  const [data, setData] = useState({ });
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const [passwordError, setPasswordError] = useState(true);
  const [forcePassword, setForcePassword] = useState(false);
  const [sequentialError, setSequentialError] = useState('');

  const {
    isLoading, result = {}, snackbarObj, languageOptions, alertBarValues,
    primaryExperienceOptions, isPlatformDetailsLoading, editDoceboDetails,
  } = userDetails;
  const {
    client_id, client_name, status, creation_date, expiration_date, language,
    primary_experience, enrolled_learning_plans, firstname, lastname, email, last_login,
    force_change_password,
  } = data;

  useEffect(() => {
    setForcePassword(!!force_change_password);
  }, [force_change_password]);

  const initialAlertBarConfig = {
    labelText: '',
    variant: '',
    open: false,
  };

  useEffect(() => {
    setData(result);
    if (result.noUserFound && isLoading === false) {
      history.push({
        pathname: '/users',
        state: { errorMessage: 'The user doesn’t exist in the platform' },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  useEffect(() => {
    if (editDoceboDetails) {
      setPassword('');
      setRetypePassword('');
      setPasswordError(true);
    }
  }, [editDoceboDetails]);

  const { labelText, variant, open } = alertBarValues;

  useEffect(() => {
    getUserDetails(userId);
  }, [getUserDetails, userId]);

  useEffect(() => () => {
    toggleAlertBar(initialAlertBarConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timer;
    if (open) {
      timer = setTimeout(() => {
        toggleAlertBar(initialAlertBarConfig);
      }, AUTO_HIDE_DURATION);
    }
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleDoceboDetailsEdit = () => {
    fetchLanguages();
  };

  const handleDoceboDetailsSave = () => {
    const lang = languageOptions.find((el) => (el.value === language));
    if (!checkSequentialNumber(password)) {
      setSequentialError('Password can not contain 3 or more consecutive numbers');
    } else if (!checkSequentialCharcter(password)) {
      setSequentialError('Password can not contain 4 or more alphabetically consecutive characters');
    } else {
      saveUserDetails({
        data:
        {
          language: lang,
          primary_experience,
          ...(!passwordError && { password }),
          force_password_change: forcePassword,
        },
        userId,
      });
    }
  };

  const handleCancel = () => {
    updateUserDetailsStore({ editDoceboDetails: false });
    setData(result); // restore change
  };

  const handleComponentChange = (e, value) => {
    setData({ ...data, [e]: value });
  };

  const breadCrumbList = [
    { label: 'User Management', redirectionUrl: '/users', isActive: false },
    { label: 'User Profile', redirectionUrl: '', isActive: true },
  ];

  const handleRequestSort = () => {
    const isAsc = (orderSort && orderSort === 'asc');
    setOrderSort(isAsc ? 'desc' : 'asc');
  };

  const getUserInfoJsx = () => (
    <Box className={classes.userMetadata}>
      <Box>
        <Box>
          <Typography variant="h4" component="span">{`${firstname} ${lastname}`}</Typography>
          <Box onClick={() => { setShowUserEditModal(true); }}>
            <img
              src={EditIcon}
              className={classes.editIcon}
              alt="edit-icon"
            />
          </Box>
          <Typography
            variant="h2"
            component="span"
            className={classes.userStatus}
          >
            {status}
          </Typography>
        </Box>
        <Typography variant="h3" component="span">
          Last login on
          {' '}
          {last_login ? reportPageDateTimeFormatter(new Date(last_login)) : 'N/A'}
        </Typography>
      </Box>
      <Typography variant="subtitle2" component="span">{email}</Typography>
    </Box>
  );

  const isButtonDisabled = () => {
    if (password || retypePassword) {
      return passwordError;
    }
    return false;
  };

  const getDoceboDetailsJsx = () => (
    <Paper style={{ display: isPlatformDetailsLoading ? 'flex' : '' }} className={classes.doceboDetailsEditContainer}>
      {isPlatformDetailsLoading ? <LoadingCircle />
        : (
          <>
            <Box className={`${classes.sectionPadding} ${classes.spaceBtw}`}>
              <Box className={classes.doceboEditSection}>
                <Typography variant="subtitle1" component="span" className={classes.boldText}>Docebo Platform Details</Typography>
                {(editDoceboDetails || isCpRole) ? null : (
                  <Box onClick={handleDoceboDetailsEdit}>
                    <img
                      src={EditIcon}
                      className={classes.editIcon}
                      alt="edit-icon-docebo-details"
                    />
                  </Box>
                )}
              </Box>
              {editDoceboDetails ? (
                <Box className={classes.doceboEditButtonsWrapper}>
                  <Button variant="outlined" color="primary" onClick={handleCancel}>Cancel</Button>
                  <Button color="primary" variant="contained" disabled={isButtonDisabled()} onClick={handleDoceboDetailsSave}>Save changes</Button>
                </Box>
              )
                : <></> }
            </Box>
            <Box>
              <Grid container spacing={2} className={`${classes.userContainerBorder} ${classes.sectionPadding}`}>
                <Grid item xs={4}>
                  <UserEditCell
                    label="Client Id and Name"
                    name="client_id"
                    value={`${client_id} - ${client_name}`}
                  />
                </Grid>
                <Grid item xs={4}>
                  <UserEditCell
                    label="Creation Date"
                    name="creation_date"
                    value={creation_date ? dateFormatter(new Date(creation_date)) : ''}
                    infoText={'Date of when user was created in Ops Portal'}
                  />
                </Grid>
                <Grid item xs={4}>
                  <UserEditCell
                    label="Expiration Date"
                    name="expiration_date"
                    value={expiration_date ? dateFormatter(new Date(expiration_date)) : ''}
                    infoText={'Date set for when the user is deactivated'}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} direction="row" className={classes.sectionPadding}>
                <Grid item xs={4}>
                  <UserEditCell
                    label="Primary Experience Landing Page"
                    name="primary_experience"
                    value={primary_experience}
                    fieldType="select"
                    isEdit={editDoceboDetails}
                    options={primaryExperienceOptions}
                    handleChange={handleComponentChange}
                    placeHolder="Select Primary Experience"
                  />
                </Grid>
                <Grid item xs={4}>
                  <UserEditCell
                    label="Language"
                    name="language"
                    value={language}
                    fieldType="select"
                    isEdit={editDoceboDetails}
                    options={languageOptions}
                    handleChange={handleComponentChange}
                    placeHolder="Select Language"
                  />
                </Grid>
              </Grid>
              <Divider />
              <Box className={`${classes.sectionPadding} ${classes.spaceBtw}`}>
                <Box className={classes.passwordSection}>
                  <Typography variant="subtitle1" component="span" className={classes.boldText}>Password Details</Typography>
                  {
                    editDoceboDetails && (
                    <WarningInfoAlert icon={<WarningIcon fontSize="medium" />} customStyles={classes.customAlert}>
                      If this user is not SSO, you will need to select
                      &ldquo;force password reset&rdquo; for the user&apos;s next login.
                      If they are SSO, you do not need to select force password update.
                    </WarningInfoAlert>
                    )
                  }
                </Box>
              </Box>
              <Grid container spacing={2} direction="row" className={classes.passwordSectionPadding}>
                {
                  !editDoceboDetails ? (
                    <>
                      <Grid item xs={2}>
                        <UserEditCell
                          label="Password"
                          name="password"
                          value={'**********'}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomStyledSwitch
                          label={'Force users to change their password at login'}
                          wrapperClass={classes.toggleWrapper}
                          option={force_change_password}
                          isChecked={force_change_password}
                          disabled
                          onChange={() => { }}
                        />
                      </Grid>
                    </>

                  ) : (
                    <EditUserPasswordSection
                      open
                      setPassword={setPassword}
                      setRetypePassword={setRetypePassword}
                      setPasswordError={setPasswordError}
                      setForcePassword={setForcePassword}
                      forcePassword={forcePassword}
                      sequentialError={sequentialError}
                      setSequentialError={setSequentialError}
                      forcePasswordType="toggle"
                    />
                  )
                }
              </Grid>
            </Box>
          </>
        )}
    </Paper>
  );

  const getEnrolledLpTableJsx = () => (
    <Paper style={{ marginTop: '2rem' }}>
      <Box className={classes.sectionPadding}>
        <Typography variant="subtitle1" component="span" className={classes.boldText}>Enrolled Learning Plan Information </Typography>
        <InfoTooltip style={{ marginLeft: '0.5rem' }} title={'Live Docebo LPs for this user, a complete list can be accessed by selecting “View All”'} />
      </Box>
      <Box className={`${classes.userContainerBorder} ${classes.sectionPadding}`}>
        <TableContainer className={classes.tableContainer}>
          <Table aria-label="Users table" className={classes.table} padding="normal" stickyHeader>
            <EnhancedTableHeader
              headCells={USER_ENROLLED_LP_TABLE_HEAD_ATTRIBUTES}
              order={orderSort}
              orderBy={orderSort ? 'learning_program' : ''}
              onRequestSort={handleRequestSort}
            />
            <UserEnrolledLpTableBody
              userEnrolledLpData={orderSort ? tableSort(enrolled_learning_plans, getComparator(orderSort, 'learning_program')) : enrolled_learning_plans}
            />
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );

  return (
    <>
      {open && (
        <AlertBarWithAction
          variant={variant}
          labelText={labelText}
          actionButtonIcon={<CloseIcon onClick={() => toggleAlertBar(initialAlertBarConfig)} />}
        />
      )}
      <Paper className={classes.wrapper}>
        <EditUserModal
          loadingText="Saving user details..."
          open={showUserEditModal}
          onClose={() => setShowUserEditModal(false)}
          data={{ ...data, userId }}
          setUserDetailsSuccess={setUserDetailsSuccess}
          setSnackbar={setSnackbar}
          setAlertBarConfig={toggleAlertBar}
        />
        {isLoading ? <LoadingCircle /> : (
          <Box className={classes.promptWrapper}>
            <BreadCrumbHOC list={breadCrumbList} />
            {getUserInfoJsx()}
            {getDoceboDetailsJsx()}
            {getEnrolledLpTableJsx()}
          </Box>
        )}
        <CustomSnackbar snackbarObj={snackbarObj} setSnackbarObj={clearAlertBar} />
      </Paper>
    </>
  );
};

UserDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string,
      programSubType: PropTypes.string,
    }),
  }).isRequired,
  getUserDetails: PropTypes.func.isRequired,
  userDetails: PropTypes.object.isRequired,
  clearAlertBar: PropTypes.func.isRequired,
  setUserDetailsSuccess: PropTypes.func.isRequired,
  setSnackbar: PropTypes.func.isRequired,
  toggleAlertBar: PropTypes.func.isRequired,
  fetchLanguages: PropTypes.func.isRequired,
  saveUserDetails: PropTypes.func.isRequired,
  updateUserDetailsStore: PropTypes.func.isRequired,
};

export default UserDetail;
