import config from '../../config';
import request from '../../helpers/apiHelper';

export const uploadXlsx = async (programSubType, data, programId) => {
  const url = `${config.ROUTES.AUTOMATION}/${programSubType}/${programId}`;
  const method = 'PUT';
  const options = { url, method, data };
  const result = await request(options);
  return result;
};

export const getWebinars = async (programId, update) => {
  const url = `${config.ROUTES.GET_WEBINARS}/${programId}?update=${update}`;

  const options = { url, method: 'GET' };
  const result = await request(options);
  const { data } = result;

  return data;
};

export const getCourseEnrollments = async (courseId, update) => {
  const url = `${config.ROUTES.GET_WEBINAR_ENROLLMENTS}/${courseId}?update=${update}`;

  const options = { url, method: 'GET' };
  const result = await request(options);
  const { data } = result.data;

  return data;
};
