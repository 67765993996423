import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Paper from '@mui/material/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import ClearIcon from '@material-ui/icons/Clear';
import CustomModal from '../../../components/common/CustomModal';
import FormattedTypography from '../../../components/common/FormattedTypography';
import MCKINSEY_SANS from '../../../stylesheets/fonts';
import { MODERATE_DARK_GREY, WHITE } from '../../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  modalStyles: {
    maxWidth: '72rem',
  },
  dialogContentClass: {
    padding: '0 4rem 0.5rem 4rem',
    width: '100%',
  },
  modalHeader: {
    margin: '0 auto',
    width: '100%',
    maxHeight: '50vh',
    overflow: 'auto',
  },
  divider: {
    width: '100%',
    marginBottom: '2rem',
  },
  centerAction: {
    justifyContent: 'center',

    '& > button:nth-child(1)': {
      width: '10rem',

      '&  > span': {
        fontSize: '1rem',
      },
    },
    '& > button:nth-child(2)': {
      width: '10rem',
      marginLeft: '1.25rem',

      '&  > span': {
        fontSize: '1rem',
        fontWeight: 600,
      },
    },
  },
  heading: {
    fontSize: '1.5rem',
    fontWeight: 600,
  },
  filterWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  filterLabel: {
    fontFamily: MCKINSEY_SANS,
    fontSize: '1rem',
    fontWeight: 600,
    color: MODERATE_DARK_GREY,
  },
  filterBlockWrapper: {
    display: 'flex',
    marginTop: '0.75rem',

    '& > div': {
      padding: '1rem 1.25rem',
    },
    '& > div:nth-child(1)': {
      marginRight: '2%',
    },
  },
  contentType: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '50%',

    '& > div:nth-child(1)': {
      display: 'flex',
      alignItems: 'center',

      '& > img': {
        height: '1rem',
        width: '1rem',
      },
      '& > span': {
        marginLeft: '4px',
        fontSize: '0.875rem',
        fontFamily: MCKINSEY_SANS,
        color: MODERATE_DARK_GREY,
      },
    },
  },
  checkbox: {
    width: '1.125rem',
    height: '1.125rem',
    opacity: 0.6,
  },
  checkboxChecked: {
    opacity: 1,
  },
  filterBlockWrapper2: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: '0.75rem',
  },
  contentType2: {
    display: 'flex',
    alignItems: 'center',
    width: '25%',
    marginBottom: '1.5rem',

    '& > span:nth-child(2)': {
      marginLeft: '0.5rem',
      fontSize: '1rem',
      fontFamily: MCKINSEY_SANS,
      color: MODERATE_DARK_GREY,
    },
  },
  filtersAppliedTitle: {
    fontSize: '0.875rem',
    fontFamily: MCKINSEY_SANS,
    color: MODERATE_DARK_GREY,
  },
  appliedFiltersWrapper: {
    marginBottom: '0.95rem',
    display: 'flex',
    alignItems: 'center',

    '& > div': {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  selectedFilterBlock: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.25rem 0.5rem',
    backgroundColor: MODERATE_DARK_GREY,
    width: 'fit-content',
    margin: '0.3rem',
    borderRadius: '4px',

    '& > span': {
      fontSize: '0.75rem',
      fontWeight: 600,
      fontFamily: MCKINSEY_SANS,
      color: WHITE,
    },

    '& > svg': {
      cursor: 'pointer',
      height: '1rem',
      width: '1rem',
      marginLeft: '0.75rem',
      color: WHITE,
    },
  },
}));

const FilterModal = ({
  open, onClose, filters, updateFilters, handleClearAll, handleApplyAll,
  removeFilterFromChip, noOfSelectedFiltersInModal,
}) => {
  const classes = useStyles();

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      modalStyle={classes.modalStyles}
    >
      <>
        <DialogTitle disableTypography className={classes.modalHeader}>
          <FormattedTypography
            variant="subtitle2"
            body="Filters"
            dense
            className={classes.heading}
          />
        </DialogTitle>
        <Divider variant="fullWidth" className={classes.divider} light />
        <DialogContent className={classes.dialogContentClass}>
          {noOfSelectedFiltersInModal > 0 && (
            <>
              <div className={classes.appliedFiltersWrapper}>
                <span className={classes.filtersAppliedTitle}>Filters applied:</span>
                <div>
                  {
                    Object.keys(filters).map((filterType) => (
                      // eslint-disable-next-line max-len
                      Object.keys(filters[filterType].options).map((filter) => (filters[filterType].options[filter].is_selected
                        ? (
                          <div key={filter.value} className={classes.selectedFilterBlock}>
                            <span>{filters[filterType].options[filter].label}</span>
                            <ClearIcon
                              onClick={() => {
                                removeFilterFromChip({ filterType, selectedFilter: filter });
                              }}
                            />
                          </div>
                        )
                        : null))
                    ))
                  }
                </div>
              </div>
              <Divider variant="fullWidth" className={classes.divider} light />
            </>
          )}
          {
            Object.keys(filters).map((filterType) => (
              <div key={filterType} className={classes.filterWrapper} style={{ marginTop: `${filterType === 'user_access' || filterType === 'type_of_tm' ? '2.5rem' : 0}` }}>
                <span className={classes.filterLabel}>
                  {filters[filterType].label}
                </span>
                {filterType === 'type_of_tm' ? (
                  <div className={classes.filterBlockWrapper2}>
                    {Object.keys(filters[filterType].options).map((filter) => (
                      <div className={classes.contentType2} key={filter}>
                        <Checkbox
                          className={classes.checkbox}
                          checked={filters[filterType].options[filter].is_selected}
                          // eslint-disable-next-line max-len
                          onChange={() => { updateFilters({ filterType, selectedFilter: filter }); }}
                          name={filter.value}
                          color="primary"
                          classes={{
                            checked: classes.checkboxChecked,
                          }}
                        />
                        <span>{filters[filterType].options[filter].label}</span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className={classes.filterBlockWrapper}>
                    {Object.keys(filters[filterType].options).map((filter) => (
                      <Paper className={classes.contentType} key={filter} elevation={1}>
                        <div>
                          <img src={filters[filterType].options[filter].icon} alt="icon" />
                          <span>{filters[filterType].options[filter].label}</span>
                        </div>
                        <Checkbox
                          className={classes.checkbox}
                          checked={filters[filterType].options[filter].is_selected}
                          // eslint-disable-next-line max-len
                          onChange={() => { updateFilters({ filterType, selectedFilter: filter }); }}
                          name={filter.value}
                          color="primary"
                          classes={{
                            checked: classes.checkboxChecked,
                          }}
                        />
                      </Paper>
                    ))}
                  </div>
                )}
              </div>
            ))
          }
        </DialogContent>
        <Divider variant="fullWidth" className={classes.divider} light />
        <DialogActions className={classes.centerAction}>
          <Button variant="outlined" color="primary" onClick={() => { handleClearAll(); }}>
            Clear All
          </Button>
          <Button color="primary" variant="contained" onClick={() => { handleApplyAll(); }}>
            Apply Filters
          </Button>
        </DialogActions>
      </>
    </CustomModal>
  );
};

export default FilterModal;

FilterModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  updateFilters: PropTypes.func.isRequired,
  handleClearAll: PropTypes.func.isRequired,
  handleApplyAll: PropTypes.func.isRequired,
  removeFilterFromChip: PropTypes.func.isRequired,
  noOfSelectedFiltersInModal: PropTypes.number.isRequired,
};
