import React from 'react';
import PropTypes from 'prop-types';

import ContentTabTable from './ContentTabComponent';
import ContentSettingDetailsPage from './ContentSettingDetailsPage';
import {
  CONTENT_TAB,
} from '../../constants';

const ContentDetailsBody = ({
  selectedTab, name, code, courseId, doceboUrl, doceboLpId, trainingMaterials,
  getScormReleaseDetails, updateScorm, updateReleaseTransaction, refreshScorm,
  configId, programId, disabledActions, location, releaseTransaction, progress,
  isDDAdmin, saveReorderTMs, getCourseDetails, addScormTmTransaction,
  openEditScormModal, openDeleteScormModal, updatedscormRow, isEdit, values,
  errors, touched, handleChange, setFieldValue, setErrors, languages, isValidating,
  setIsValidating, is_skill_lp,
}) => (
  <>
    {selectedTab === CONTENT_TAB ? (
      <ContentTabTable
        name={name}
        code={code}
        courseId={courseId}
        doceboUrl={doceboUrl}
        doceboLpId={doceboLpId}
        trainingMaterials={trainingMaterials}
        getScormReleaseDetails={getScormReleaseDetails}
        updateScorm={updateScorm}
        updateReleaseTransaction={updateReleaseTransaction}
        refreshScorm={refreshScorm}
        configId={configId}
        programId={programId}
        disabledActions={disabledActions}
        location={location}
        releaseTransaction={releaseTransaction}
        progress={progress}
        isDDAdmin={isDDAdmin}
        saveReorderTMs={saveReorderTMs}
        getCourseDetails={getCourseDetails}
        addScormTmTransaction={addScormTmTransaction}
        openEditScormModal={openEditScormModal}
        openDeleteScormModal={openDeleteScormModal}
        updatedscormRow={updatedscormRow}
      />
    )
      : (
        <ContentSettingDetailsPage
          isEdit={isEdit}
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          setErrors={setErrors}
          languages={languages}
          isValidating={isValidating}
          setIsValidating={setIsValidating}
          is_skill_lp={is_skill_lp}
        />
      )}
  </>
);

ContentDetailsBody.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  doceboUrl: PropTypes.string.isRequired,
  doceboLpId: PropTypes.string.isRequired,
  getScormReleaseDetails: PropTypes.func.isRequired,
  updateScorm: PropTypes.func.isRequired,
  refreshScorm: PropTypes.func.isRequired,
  configId: PropTypes.string.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  isDDAdmin: PropTypes.bool.isRequired,
  courseId: PropTypes.number.isRequired,
  trainingMaterials: PropTypes.array.isRequired,
  programId: PropTypes.string.isRequired,
  updateReleaseTransaction: PropTypes.string.isRequired,
  disabledActions: PropTypes.bool.isRequired,
  releaseTransaction: PropTypes.string.isRequired,
  progress: PropTypes.shape({
    done: PropTypes.bool.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  saveReorderTMs: PropTypes.func.isRequired,
  getCourseDetails: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
  addScormTmTransaction: PropTypes.func.isRequired,
  openEditScormModal: PropTypes.func.isRequired,
  openDeleteScormModal: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isEdit: PropTypes.bool.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  languages: PropTypes.array.isRequired,
  isValidating: PropTypes.bool.isRequired,
  setIsValidating: PropTypes.func.isRequired,
  updatedscormRow: PropTypes.object.isRequired,
  is_skill_lp: PropTypes.bool.isRequired,
};

export default ContentDetailsBody;
