import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add';
import NoData from '../../assets/img/no-data.svg';
import FolderIcon from '../../assets/icons/tm_folder_icon.svg';
import {
  BLACK, SWITCH_GREY, MODERATE_DARK_GREY, VERY_LIGHT_GREY_V2, MCKINSEY_BLUE,
} from '../../stylesheets/colors';
import NoDataComponent from '../common/NoDataComponent';
import ContentTabRow from './ContentTabRow';
import ReorderTMsconfirmationModal from './ReorderTMsconfirmationModal';
import CustomPopper from '../common/Popper';
import AddNewScormModal from './AddNewScormModal';
import CtaActions from '../common/CtaActions';

const useStyles = makeStyles({
  wrapperTableHead: {
    '& .MuiTableCell-head': {
      backgroundColor: SWITCH_GREY,
      color: MODERATE_DARK_GREY,
      fontSize: '1.25rem',
      fontWeight: '800',
    },
  },
  wrapperTableBody: {
    '& .MuiTableCell-body': {
      fontSize: '1.25rem',
      color: MODERATE_DARK_GREY,
    },
  },
  order: {
    color: BLACK,
    fontWeight: 800,
  },
  tmWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  noDisplay: {
    display: 'none',
  },
  rowDirection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  collapseWrapper: {
    justifyContent: 'space-between',
  },
  folderRow: {
    backgroundColor: VERY_LIGHT_GREY_V2,
    '& .MuiTableCell-root': {
      padding: '10px 16px 10px 40px',
      borderBottom: 'none',
    },
  },
  pointerCursor: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  selectedFolder: {
    borderLeft: `3px solid ${MCKINSEY_BLUE}`,
  },
  folderLeftPadding: {
    '&.MuiTableCell-root': {
      padding: '10px 16px 10px 37px',
    },
  },
  orderIndicator: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  indicator: {
    height: '1.25rem',
    width: ' 1.25rem',
    borderRadius: ' 50%',
  },
  subHeaderWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  fabActionWrapper: {
    padding: '2rem 0',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  popperStyle: {
    padding: '1rem',
    width: '15rem',
  },
});

const ExpandedRows = ({
  isExpanded,
  folderList,
  getTMs,
  setDataType,
  dataType,
  selectedFolderId,
  setSelectedFolderId,
}) => {
  const classes = useStyles();

  const handleFolderClick = (folderId) => {
    setSelectedFolderId(folderId);
    setDataType('folder');
    getTMs(folderId);
  };

  const hideClass = isExpanded ? '' : classes.noDisplay;

  return (
    <>
      {
        folderList.map((folder) => (
          <>
            <TableRow className={`${hideClass} ${classes.folderRow}`}>
              <TableCell
                className={`${classes.rowDirection} ${classes.pointerCursor}
               ${dataType !== 'all' && selectedFolderId === folder?.id && classes.selectedFolder}
               ${dataType !== 'all' && selectedFolderId === folder?.id && classes.folderLeftPadding}
               `}
                onClick={() => handleFolderClick(folder?.id)}
              >
                <img src={FolderIcon} alt="folder-icon" style={{ paddingRight: '0.5rem' }} />
                <Typography variant="body1" style={{ color: MODERATE_DARK_GREY }}>
                  {folder?.name}
                </Typography>
              </TableCell>
            </TableRow>
          </>
        ))
      }
    </>
  );
};

const ContentTabTable = ({
  courseId, doceboUrl, doceboLpId, trainingMaterials, getScormReleaseDetails, updateScorm,
  refreshScorm, configId, programId, updateReleaseTransaction, disabledActions,
  location, releaseTransaction, progress, isDDAdmin, name, openEditScormModal, openDeleteScormModal,
  saveReorderTMs, getCourseDetails, code, addScormTmTransaction, updatedscormRow,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [dataType, setDataType] = useState('all');
  const [tableRows, setTableRows] = useState([]);
  const [enableReordering, setEnableReordering] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState('');
  const [copyTrainingMaterials, setCopyTrainingMaterials] = useState([]);
  const [confimationModal, setConfimationModal] = useState(false);
  const [addScormModal, setAddScormModal] = useState(false);
  const [newScorm, setNewScorm] = useState(false);
  const [isScormAdding, setIsScormAdding] = useState(false);

  const initAddScorm = () => {
    setAddScormModal(true);
  };

  const actionMenu = [
    {
      title: 'Scorm',
      value: 'add_scorm',
      onClick: initAddScorm,
    },
  ];

  useEffect(() => {
    setCopyTrainingMaterials(trainingMaterials);
  }, [trainingMaterials]);

  const folderList = _filter(copyTrainingMaterials, ['type', '']);

  useEffect(() => {
    if (dataType === 'all') {
      setTableRows(copyTrainingMaterials);
    } else {
      setTableRows(_find(copyTrainingMaterials, { id: selectedFolderId }).children);
    }
  }, [copyTrainingMaterials, dataType, selectedFolderId]);

  const getFolderTMs = (folderId) => {
    setTableRows(_find(copyTrainingMaterials, { id: folderId }).children);
  };

  const handleRootClick = () => {
    setDataType('all');
  };

  const saveReordering = () => {
    setEnableReordering(false);
    setConfimationModal(false);
    saveReorderTMs(copyTrainingMaterials);
  };

  const cancelReordering = () => {
    setEnableReordering(false);
    setCopyTrainingMaterials(trainingMaterials);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const movedItems = reorder(
      tableRows,
      result.source.index,
      result.destination.index,
    );
    setTableRows(movedItems);
    if (dataType === 'all') {
      setCopyTrainingMaterials(movedItems);
    } else {
      const objlist = _map(copyTrainingMaterials, (obj) => {
        if (obj.id === selectedFolderId) {
          return ({
            ...obj,
            children: movedItems,
          });
        }
        return obj;
      });
      setCopyTrainingMaterials(objlist);
    }
  };

  return (
    <Grid item xs={12} md={12}>
      {!isDDAdmin && (
        <Box className={classes.subHeaderWrapper}>
          <CtaActions
            ctaText="Re-Order Training Materials"
            isReorderingEnabled={enableReordering}
            onEnableReordering={() => setEnableReordering(true)}
            onSave={() => setConfimationModal(true)}
            onCancel={cancelReordering}
          />
        </Box>
      )}
      {copyTrainingMaterials?.length > 0 ? (
        <Box className={classes.tmWrapper}>

          {folderList.length > 0 && (
            <TableContainer style={{
              width: '30%', maxHeight: 400, marginTop: '2rem', paddingRight: '1rem',
            }}
            >
              <Table aria-label="simple table" padding="normal" stickyHeader>
                <TableHead className={classes.wrapperTableHead}>
                  <TableRow>
                    <TableCell>Folders</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      className={`${classes.rowDirection} ${classes.collapseWrapper} 
                    ${classes.pointerCursor} ${dataType === 'all' && classes.selectedFolder}`}
                      onClick={handleRootClick}
                    >
                      <Box className={classes.rowDirection}>
                        <img src={FolderIcon} alt="folder-icon" style={{ paddingRight: '0.5rem' }} />
                        <Typography variant="body1" style={{ color: MODERATE_DARK_GREY }}>
                          {name}
                        </Typography>
                      </Box>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                      >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <ExpandedRows
                    isExpanded={open}
                    folderList={folderList}
                    getTMs={getFolderTMs}
                    setDataType={setDataType}
                    dataType={dataType}
                    selectedFolderId={selectedFolderId}
                    setSelectedFolderId={setSelectedFolderId}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <TableContainer style={{ maxHeight: 400, marginTop: '2rem' }}>
            <Table aria-label="simple table" padding="normal" stickyHeader>
              <TableHead className={classes.wrapperTableHead}>
                <TableRow>
                  <TableCell align="left">
                    <Box className={classes.orderIndicator}>
                      <Box
                        className={classes.indicator}
                      />
                      <Typography variant="subtitle2" className={classes.order}>Order</Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="left">Training Material Name</TableCell>
                  <TableCell align="left" style={{ width: '15%' }}>Material Type</TableCell>
                  <TableCell align="left" style={{ width: '5%' }}>Actions</TableCell>
                  <TableCell align="right" style={{ width: '5%' }}> </TableCell>
                  <TableCell align="right" style={{ width: '5%' }}> </TableCell>
                </TableRow>
              </TableHead>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="all" type="column">
                  {(provided) => (
                    <TableBody
                      className={classes.wrapperTableBody}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {tableRows?.map((row, index) => (
                        <ContentTabRow
                          courseId={courseId}
                          doceboUrl={doceboUrl}
                          doceboLpId={doceboLpId}
                          index={index}
                          code={code}
                          totalRows={tableRows?.length}
                          row={row}
                          key={`content-${row.id}`}
                          getScormReleaseDetails={getScormReleaseDetails}
                          updateScorm={updateScorm}
                          configId={configId}
                          programId={programId}
                          disabledActions={disabledActions}
                          location={location}
                          refreshScorm={refreshScorm}
                          updateReleaseTransaction={updateReleaseTransaction}
                          releaseTransaction={releaseTransaction}
                          progress={progress}
                          isDDAdmin={isDDAdmin}
                          enableReordering={enableReordering}
                          newScorm={newScorm}
                          openEditScormModal={openEditScormModal}
                          openDeleteScormModal={openDeleteScormModal}
                          updatedscormRow={updatedscormRow}
                        />
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </TableContainer>
        </Box>
      )
        : (
          <Paper elevation="3" sx={{ padding: '8rem 0', marginTop: '2rem' }}>
            <NoDataComponent
              imgSrc={NoData}
              primaryText="No training materials added yet!"
              secondaryText=""
            />
          </Paper>
        )}
      {confimationModal && (
        <ReorderTMsconfirmationModal
          open={confimationModal}
          onClose={() => setConfimationModal(false)}
          onConfirm={saveReordering}
        />
      )}
      <AddNewScormModal
        addScormModal={addScormModal}
        setAddScormModal={setAddScormModal}
        programId={programId}
        courseId={courseId}
        code={code}
        getCourseDetails={getCourseDetails}
        setNewScorm={setNewScorm}
        addScormTmTransaction={addScormTmTransaction}
        setIsScormAdding={setIsScormAdding}
      />
      {!isDDAdmin && (
        <Box className={classes.fabActionWrapper}>
          <CustomPopper
            list={actionMenu}
            disabled={isScormAdding || disabledActions}
            isFabElement
            element={<AddIcon />}
            customStyle={classes.popperStyle}
          />
        </Box>
      )}
    </Grid>
  );
};

ExpandedRows.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  folderList: PropTypes.array.isRequired,
  getTMs: PropTypes.func.isRequired,
  setDataType: PropTypes.func.isRequired,
  dataType: PropTypes.string.isRequired,
  selectedFolderId: PropTypes.string.isRequired,
  setSelectedFolderId: PropTypes.func.isRequired,
};

ContentTabTable.propTypes = {
  courseId: PropTypes.number.isRequired,
  doceboUrl: PropTypes.string.isRequired,
  doceboLpId: PropTypes.string.isRequired,
  trainingMaterials: PropTypes.array.isRequired,
  getScormReleaseDetails: PropTypes.func.isRequired,
  updateScorm: PropTypes.func.isRequired,
  refreshScorm: PropTypes.func.isRequired,
  configId: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired,
  updateReleaseTransaction: PropTypes.string.isRequired,
  disabledActions: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  releaseTransaction: PropTypes.string.isRequired,
  progress: PropTypes.shape({
    done: PropTypes.bool.isRequired,
  }).isRequired,
  isDDAdmin: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  getTMs: PropTypes.func.isRequired,
  folderTMS: PropTypes.array.isRequired,
  saveReorderTMs: PropTypes.func.isRequired,
  getCourseDetails: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
  addScormTmTransaction: PropTypes.func.isRequired,
  openEditScormModal: PropTypes.func.isRequired,
  updatedscormRow: PropTypes.object.isRequired,
  openDeleteScormModal: PropTypes.func.isRequired,
};

export default ContentTabTable;
