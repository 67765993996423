import React, { useEffect, useState, memo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import CreateTestUsersTab from './CreateTestUsersTab';
import CreateTestUsersTable from './CreateTestUsersTable';
import AddPrimaryExperienceModal from './AddPrimaryExperienceModal';
import AddMoreUsers from './AddMoreUsersBlock';

import { MODERATE_LIGHT_GREY } from '../../stylesheets/colors';
import { FEATURE_CLICKED } from '../../constants';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '3rem',
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `1px solid ${MODERATE_LIGHT_GREY}`,
  },
  tabsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.85,
  },
  addPrimaryExperienceWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.15,
    fontSize: '1.125rem',
  },
  addPrimaryExperienceButton: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '2.5rem',
    width: '14rem',
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  bodyRow: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.91,
  },
  addMoreGroupsRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.93,
  },
  loading: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const Loading = () => {
  const classes = useStyles();

  return (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  );
};

const CreateTestUsersReportPage = ({
  data,
  activeLanguages,
  onAddPrimaryExperience,
  onAddMoreUsers,
  experienceTypeOptions,
  onStatusChange,
  onTestUserUpdate,
  onTestUserDelete,
  enrollAdditionalProgram,
  configId,
  programSubType,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { hash } = useLocation();

  const [tabs, setTabs] = useState([]);
  const [currentTab, setCurrentTab] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    const tabKeys = Object.keys(data);
    setTabs(tabKeys);

    if (tabKeys.length) {
      if (hash) {
        const tab = decodeURI(hash.substr(1));
        setCurrentTab(tab);

        if (tabKeys.includes(tab)) {
          setSelectedUsers([]);
          return;
        }
      }
      history.replace({
        hash: tabKeys[0],
      });
    }
  }, [data, history, hash]);

  // Loading
  if (tabs.length === 0 || !data[currentTab]) {
    return (
      <Paper className={classes.wrapper}>
        <Loading />
      </Paper>
    );
  }

  const testUserStart = data[currentTab].length + 1;

  const onSelectUser = (users) => {
    setSelectedUsers(users);
  };

  const onFindUserStart = (primaryExperience, testUserCount, selectedProgram) => {
    const userIndex = get(data, primaryExperience, []).length + 1;

    // adding heap tracking script to track actual feature click
    window.heap.track(FEATURE_CLICKED, programSubType);
    onAddPrimaryExperience(primaryExperience, userIndex, testUserCount, selectedProgram);
  };

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.bodyRow}>
        <div className={classes.titleRow}>
          <div className={classes.tabsWrapper}>
            <CreateTestUsersTab
              currentTab={currentTab}
              tabs={tabs}
              experienceTypeOptions={experienceTypeOptions}
            />
          </div>

          <div className={classes.addPrimaryExperienceWrapper}>
            <button
              type="button"
              className={classes.addPrimaryExperienceButton}
              onClick={() => setModalOpen(true)}
            >
              Add Primary Experience
            </button>
          </div>
        </div>
        <AddMoreUsers
          primaryExperience={currentTab}
          onAddMoreUsers={onAddMoreUsers}
          onStatusChange={onStatusChange}
          startingValueDefault={testUserStart}
          selectedUsers={selectedUsers}
          activeLanguages={activeLanguages}
          experienceTypeOptions={experienceTypeOptions}
          onTestUserUpdate={onTestUserUpdate}
          onTestUserDelete={onTestUserDelete}
          enrollAdditionalProgram={enrollAdditionalProgram}
          configId={configId}
        />
        <Box className={classes.tableRow}>
          <CreateTestUsersTable
            data={data[currentTab]}
            primaryExperience={currentTab}
            onSelectUser={onSelectUser}
            onStatusChange={onStatusChange}
            onTestUserUpdate={onTestUserUpdate}
            onTestUserDelete={onTestUserDelete}
            enrollAdditionalProgram={enrollAdditionalProgram}
            activeLanguages={activeLanguages}
            configId={configId}
          />
        </Box>
        {modalOpen ? (
          <AddPrimaryExperienceModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            onCreate={onFindUserStart}
            experienceTypeOptions={experienceTypeOptions}
            configId={configId}
          />
        ) : null}
      </Box>
    </Box>
  );
};

CreateTestUsersReportPage.defaultProps = {
  activeLanguages: [],
};

CreateTestUsersReportPage.propTypes = {
  onAddPrimaryExperience: PropTypes.func.isRequired,
  onAddMoreUsers: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  onTestUserUpdate: PropTypes.func.isRequired,
  onTestUserDelete: PropTypes.func.isRequired,
  enrollAdditionalProgram: PropTypes.func.isRequired,
  experienceTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  data: PropTypes.shape({}).isRequired,
  activeLanguages: PropTypes.array,
  configId: PropTypes.string.isRequired,
  programSubType: PropTypes.string.isRequired,
};

export default memo(CreateTestUsersReportPage);
