import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import Grid from '@material-ui/core/Grid';
import get from 'lodash/get';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import _debounce from 'lodash/debounce';
import { CancelToken, isCancel } from 'axios';
import Box from '@mui/material/Box';
import Typography from '@material-ui/core/Typography';
import LabledTextField from '../common/LabledTextField';
import LabledTextArea from '../common/LabeledTextArea';
import CustomStyledSwitch from '../common/CustomStyledSwitch';
import CustomSelect from '../common/CustomSelect';
import { DARK_GREEN, ERROR_RED, PLATINUM_GREY } from '../../stylesheets/colors';
import formFields from './constant';
import { toArrayOfObject } from '../../helpers/utils';
import {
  MASTER_ID_FOUND, PROJECT_ID_FAILED, PROJECT_ID_INVALID, PROJECT_ID_NOT_FOUND, PROJECT_ID_VALID,
} from '../../constants';
import { checkValidityOfElucidatPid } from '../../containers/ReplicationPage/api';
import { getPortfolioOfferingProgram } from '../../containers/common/apis';

const useStyles = makeStyles(() => ({
  logicbox: {
    padding: '0 8px',
    '& > div': {
      padding: '8px 0',
    },
  },
  flexbox: {
    display: 'flex',
    padding: '8px',
    '& > div': {
      '&:first-child': {
        paddingRight: '30px',
        borderRight: `1px solid ${PLATINUM_GREY}`,
      },
      '&:last-child': {
        paddingLeft: '30px',
      },
    },
  },
  red: {
    color: ERROR_RED,
  },
  green: {
    color: DARK_GREEN,
  },
  dropdown: {
    margin: '8px 0',
  },
  labelClass: {
    marginBottom: '.8rem',
    fontSize: '1.125rem',
    fontWeight: 'normal',
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '.8rem',
  },
}));

const helperTextLookup = {
  [PROJECT_ID_VALID]: 'Project ID is valid',
  [PROJECT_ID_FAILED]: 'Failed to contact Elucidat servers',
  [PROJECT_ID_INVALID]: 'Invalid Project Id',
  [PROJECT_ID_NOT_FOUND]: 'Project Id not found',
  [MASTER_ID_FOUND]: 'Please enter an ID that is NOT a Master Project on Elucidat',
};

const cancelTokenSource = {};

const MetadataFormTemplate = ({
  values, handleChange, setFieldValue, options, mode, selectedFields, errors,
  setErrors, status, setStatus, isValidating, setIsValidating,
}) => {
  const classes = useStyles();
  const dropdownRef = useRef({});
  dropdownRef.current = {};
  const [portfolioOption, setPortfolioOptions] = useState([]);
  const [offeringOption, setOfferingOptions] = useState([]);
  const [programOption, setProgramOptions] = useState([]);

  const getCheckedStatus = (key) => values[key].includes(options[0].value);

  const fetchPortfolioOfferingProgram = async (data) => {
    const response = await getPortfolioOfferingProgram(data);
    const optionArray = get(response, 'data.data', []);
    if (!data) {
      setPortfolioOptions(toArrayOfObject(optionArray));
    } else if (Object.keys(data).length === 1) {
      setOfferingOptions(toArrayOfObject(optionArray));
    } else {
      setProgramOptions(toArrayOfObject(optionArray));
    }
  };

  useEffect(() => {
    fetchPortfolioOfferingProgram();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (values.portfolio) {
      fetchPortfolioOfferingProgram({ portfolio: values.portfolio });
    }
  }, [values.portfolio]);

  useEffect(() => {
    if (values.portfolio && values.offering) {
      fetchPortfolioOfferingProgram({ portfolio: values.portfolio, offering: values.offering });
    }
  }, [values.portfolio, values.offering]);

  const getCheckedValue = (isChecked) => {
    const index = isChecked ? 0 : 1;
    return options[index].value;
  };

  const checkPIdValidity = useCallback(
    async (pid) => {
      // Cancel old request
      setIsValidating(true);
      if (cancelTokenSource[['elucidat-project-id']]) {
        cancelTokenSource[['elucidat-project-id']].cancel();
      }

      setErrors({ 'elucidat-project-id': '' });
      setStatus('');

      if (!pid) {
        setIsValidating(false);
        return;
      }

      if (!new RegExp('^[a-z0-9;]+$', 'i').test(pid)) {
        setErrors({ 'elucidat-project-id': 'Special characters are not allowed' });
        setIsValidating(false);
        return;
      }

      try {
        cancelTokenSource[['elucidat-project-id']] = CancelToken.source();
        const response = await checkValidityOfElucidatPid(pid, cancelTokenSource[['elucidat-project-id']].token);

        // validate master project id and project id in general.
        if (response?.data?.is_master_project) {
          setErrors({ 'elucidat-project-id': helperTextLookup[MASTER_ID_FOUND] });
        } else if (response?.data?.valid) {
          setStatus(`${helperTextLookup[PROJECT_ID_VALID]}`);
        } else {
          setErrors({ 'elucidat-project-id': helperTextLookup[PROJECT_ID_INVALID] });
        }

        setIsValidating(false);
      } catch (e) {
        if (!isCancel(e)) {
          console.error(e);
          setErrors({ 'elucidat-project-id': 'Something went wrong, Try again' });
          setIsValidating(false);
        }
      }
    },
    [setErrors, setIsValidating, setStatus],
  );

  const debouncedChange = useCallback(_debounce(checkPIdValidity, 500), [checkPIdValidity]);

  const onChange = useCallback(
    (e) => {
      handleChange(e);
      debouncedChange(e.target.value);
    },
    [debouncedChange, handleChange],
  );

  const onDropdownChange = (key, e) => {
    if (e?.value !== values[key] && key === 'portfolio') {
      if (dropdownRef.current.offering) {
        dropdownRef.current.offering.select.clearValue();
      }
      if (dropdownRef.current.program) {
        dropdownRef.current.program.select.clearValue();
      }
      batchUpdates(() => {
        setFieldValue(key, e?.value);
        setOfferingOptions([]);
        setProgramOptions([]);
        setFieldValue('offering', '');
        setFieldValue('program', '');
      });
    } else if (e?.value !== values[key] && key === 'offering') {
      if (dropdownRef.current.program) {
        dropdownRef.current.program.select.clearValue();
      }
      batchUpdates(() => {
        setProgramOptions([]);
        setFieldValue(key, e?.value);
        setFieldValue('program', '');
      });
    } else {
      setFieldValue(key, e?.value);
    }
  };
  const switchFields = formFields.filter((fld) => (fld.type === 'switch' && selectedFields.includes(fld.key)));
  const textboxFields = formFields.filter((fld) => ((fld.type !== 'switch' && fld.type !== 'dropdown') && selectedFields.includes(fld.key)));
  const dropdownFields = formFields.filter((fld) => (fld.type === 'dropdown' && selectedFields.includes(fld.key)));

  const getOptionArray = (key) => {
    if (key === 'portfolio') {
      return portfolioOption;
    }
    if (key === 'offering') {
      return offeringOption;
    }
    if (key === 'program') {
      return programOption;
    }
    return [];
  };

  const addToRefs = (el, key) => {
    if (el && !dropdownRef.current[key]) {
      dropdownRef.current[key] = el;
    }
  };

  return (
    <Grid container alignItems="flex-start" justifyContent="center" spacing={2}>
      <Grid xs={10} className={mode === 'bulk' ? classes.logicbox : classes.flexbox}>
        {
          switchFields.map((fld) => (
            <Grid xs={12} key={fld.label}>
              <CustomStyledSwitch
                label={fld.label}
                option={options[0].label}
                isChecked={getCheckedStatus(fld.key)}
                onChange={
                  ({ target: { checked } }) => setFieldValue(fld.key, getCheckedValue(checked))
                }
              />
            </Grid>
          ))
        }
      </Grid>

      {
        dropdownFields.map((fld) => (
          <Grid item xs={10} key={fld.key}>
            <CustomSelect
              name={fld.key}
              options={getOptionArray(fld.key)}
              placeholder="Select an option"
              type="select"
              className={`${classes.select_label}`}
              labelClass={`${classes.labelClass}`}
              label={fld.required ? (
                <Box className={classes.label}>
                  <Typography>{fld.label}</Typography>
                  <span className={classes.red}>*</span>
                </Box>
              ) : fld.label}
              defaultValue={
                values[fld.key] ? [{ value: values[fld.key], label: values[fld.key] }] : null
              }
              selectStyles={classes.dropdown}
              onChange={(e) => onDropdownChange(fld.key, e)}
              isOptionsNotLoading
              selectRef={(el) => addToRefs(el, fld.key)}
            />
          </Grid>
        ))
      }

      {
        textboxFields.map((fld) => {
          const Component = fld.type === 'textbox' ? LabledTextField : LabledTextArea;

          return (
            <Grid item xs={10} key={fld.key}>
              <Component
                label={fld.label}
                inputProps={{
                  name: fld.key,
                  required: fld.required,
                  InputProps: {
                    endAdornment: isValidating && fld.key === 'elucidat-project-id' && <CircularProgress size="2rem" />,
                  },
                }}
                value={values[fld.key]}
                onChange={fld.key === 'elucidat-project-id' ? onChange : handleChange}
              />
              {
                fld.key === 'elucidat-project-id' && errors['elucidat-project-id'] && <Typography className={classes.red}>{errors['elucidat-project-id']}</Typography>
              }
              {
                fld.key === 'course-runner' && errors['course-runner'] && <Typography className={classes.red}>{errors['course-runner']}</Typography>
              }
              {fld.key === 'elucidat-project-id' && status && <Typography className={classes.green}>{status}</Typography>}
            </Grid>
          );
        })
      }
    </Grid>
  );
};

export default MetadataFormTemplate;

MetadataFormTemplate.defaultProps = {
  mode: 'bulk',
  selectedFields: [],
};

MetadataFormTemplate.propTypes = {
  values: PropTypes.shape({
    courseName: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    portfolio: PropTypes.string.isRequired,
    offering: PropTypes.string.isRequired,
    program: PropTypes.string.isRequired,
    'elucidat-project-id': PropTypes.string,
    'course-runner': PropTypes.string,
    'additional-metadata': PropTypes.string,
    'keep-in-mind': PropTypes.string,
    'after-you-complete-this-course': PropTypes.string,
    'progress-logic': PropTypes.string,
    'proficiency-logic': PropTypes.string,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  mode: PropTypes.string,
  selectedFields: PropTypes.arrayOf(PropTypes.string),
  errors: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired,
  isValidating: PropTypes.bool.isRequired,
  setIsValidating: PropTypes.func.isRequired,
};
