import React, { useEffect, useState } from 'react';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _maxBy from 'lodash/maxBy';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import FormattedTypography from '../common/FormattedTypography';
import SelectedUserTable from './SelectedUserTable';
import ConfirmationModal from '../common/ConfirmationModal';
import AsyncSelect from './AsyncSelect';

const useStyles = makeStyles(() => ({
  userTable: {
    marginBottom: '2rem',
  },
  width100: {
    width: '100%',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '0.5rem',
  },
  menuIcon: {
    flex: '0.2',
    height: '1.5rem',
    lineHeight: '1.5rem',
  },
  menuText: {
    flex: '0.8',
  },
  modalHeight: {
    minHeight: '450px',
    height: 'auto',
  },
}));

const EnrollAdditionalProgram = ({
  users, open, onClose, onEnrollProgram, selectedUsers, configId,
}) => {
  const classes = useStyles();
  const [preEnrolledPrograms, setPreEnrolledPrograms] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [disableSelect, setDisableSelect] = useState(false);

  const onConfirm = () => {
    onEnrollProgram(selectedPrograms);
    setSelectedPrograms([]);
  };

  const handlePickedPrograms = (items) => {
    const programs = _map(items, 'value');
    setSelectedPrograms(programs);
  };

  const onCancel = () => {
    setSelectedPrograms([]);
    onClose();
  };

  const updateUserObj = (obj) => ({
    ...obj,
    enrolledPrograms: obj.enrolledPrograms
      && ((obj.enrolledPrograms.indexOf(',') > -1) ? obj.enrolledPrograms.replace(/ /g, '')
        .split(',') : [obj.enrolledPrograms]),
  });

  useEffect(() => {
    if (selectedUsers && selectedUsers.length) {
      const activeSelectedUser = _filter(selectedUsers, { status: 'true' });
      setPreEnrolledPrograms(_map(activeSelectedUser, updateUserObj));
    }
  }, [selectedUsers]);

  useEffect(() => {
    const maxEnroll = _maxBy(preEnrolledPrograms,
      (o) => (o.enrolledPrograms && o.enrolledPrograms.length ? o.enrolledPrograms.length : 0));
    if (maxEnroll && selectedPrograms
      && (maxEnroll.enrolledPrograms
        && maxEnroll.enrolledPrograms.length + selectedPrograms.length >= 5)) {
      setDisableSelect(true);
    } else {
      setDisableSelect(false);
    }
  }, [preEnrolledPrograms, selectedPrograms]);
  return (
    <ConfirmationModal
      open={open}
      onClose={onCancel}
      dialogContentClass={classes.width100}
      minHeightClass={classes.modalHeight}
      breakpoint="md"
      title={(
        <FormattedTypography
          variant="subtitle2"
          body="Enroll selected users to additional Learning Plans"
          subHeading={`${users.length} selected active users will get enrolled additionally to the Learning Plans you select below. You can un-enroll them anytime as needed.`}
          dense
        />
      )}
      content={(
        <Grid container justifyContent="space-between">
          <Grid item md={6}><SelectedUserTable title="Test Users" list={users} className={classes.userTable} /></Grid>
          <Grid item md={1}>
            <Divider light orientation="vertical" variant="middle" />
          </Grid>
          <Grid item md={5} direction="column" spacing={1}>
            <AsyncSelect
              label="Select additional program IDs"
              placeholder="Select Programs"
              filteredOptions={selectedUsers.length === 1
                ? preEnrolledPrograms[0]?.enrolledPrograms
                && [...preEnrolledPrograms[0]?.enrolledPrograms, configId] : [configId]}
              selectedItems={selectedPrograms}
              onChange={(value) => handlePickedPrograms(value)}
              disableSelect={disableSelect}
              configId={configId}
            />
          </Grid>
        </Grid>
      )}
      actions={(
        <>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <Button disabled={selectedPrograms.length ? false : disableSelect} color="primary" variant="contained" onClick={onConfirm}>
            Enroll test users
          </Button>
        </>
      )}
    />
  );
};

export default EnrollAdditionalProgram;

EnrollAdditionalProgram.defaultProps = {
  selectedUsers: [{
    username: '',
    status: '',
    enrolledPrograms: '',
  }],
};

EnrollAdditionalProgram.propTypes = {
  users: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEnrollProgram: PropTypes.func.isRequired,
  selectedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string,
      status: PropTypes.string,
      enrolledPrograms: PropTypes.string,
    }),
  ),
  configId: PropTypes.string.isRequired,
};
