import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';
import _map from 'lodash/map';
import { Typography } from '@material-ui/core';
import ContentDetailsBlock from '../ContentManagement/ContentDetailsBlock';
import TableRC from '../common/TableRC';
import LoadingCircle from '../common/LoadingCircle/LoadingCircle';
import { ROUTE_REPORT, TEST_USERS_CREATION } from '../../constants';

const TestUsersDetails = ({
  values, isLoading, programId,
}) => {
  const data = _map(values?.testUsers, (obj) => ({
    ...obj,
    user: <Typography variant="subtitle2">{obj?.user}</Typography>,
    password: <Typography variant="subtitle2">{obj?.password}</Typography>,
    pm: <Typography variant="subtitle2">{obj?.primary_experience}</Typography>,
  }));

  return (
    <ContentDetailsBlock
      headerText={`Test Users (${data?.length})`}
      fieldRedirectOnLabel={`/${ROUTE_REPORT}/${programId}/${TEST_USERS_CREATION}`}
      noPaper
    >
      {
        isLoading ? (<LoadingCircle labelText="Loading Test Users" />) : (
          <Grid item xs={12} md={12} sx={{ padding: '1rem 0' }}>
            <TableRC
              columns={[
                { title: 'User', property: 'user' },
                { title: 'Password', property: 'password' },
                { title: 'Primary Experience', property: 'pm' },
              ]}
              data={data}
              noDataMessage="Test users unavailable for this learning plan."
            />
          </Grid>
        )
      }

    </ContentDetailsBlock>
  );
};

TestUsersDetails.propTypes = {
  values: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  programId: PropTypes.string.isRequired,
};

export default TestUsersDetails;
