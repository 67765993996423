import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import _map from 'lodash/map';
import Typography from '@material-ui/core/Typography';
import { ContentDetailsLabel } from '../ContentManagement/ContentDetailsSection';
import TableRC from '../common/TableRC';
import LoadingCircle from '../common/LoadingCircle/LoadingCircle';
import Select from '../common/SelectMui';
import { MCKINSEY_BLUE } from '../../stylesheets/colors';
import { SCORED_ASSESSMENT_OPTIONS } from '../../constants';

const NonElucidatDetails = ({
  values, customLabel, isLoading, isEdit, setFieldValue,
}) => {
  const transformedData = _map(values?.nonElucidatDetails, (obj, index) => ({
    ...obj,
    scorm_type: <Typography variant="subtitle2">{obj?.scorm_type}</Typography>,
    component_title: <Typography variant="subtitle2">{obj?.component_title}</Typography>,
    scorm_url: obj?.scorm_url && (<a target="_blank" rel="noopener noreferrer" href={obj.scorm_url || '#'} style={{ fontSize: '1.25rem', color: MCKINSEY_BLUE }}>{obj.scorm_url}</a>),
    scorm_title: <Typography variant="subtitle2">{obj?.scorm_title}</Typography>,
    box_link: obj?.box_link && (<a target="_blank" rel="noopener noreferrer" href={obj.box_link || '#'} style={{ fontSize: '1.25rem', color: MCKINSEY_BLUE }}>{obj.box_link}</a>),
    scored_assessment: isEdit ? (
      <Select
        name="select"
        defaultValue={SCORED_ASSESSMENT_OPTIONS.find((el) => el.value === obj?.scored_assessment)}
        options={SCORED_ASSESSMENT_OPTIONS}
        placeholder="Select..."
        type="select"
        isOptionsNotLoading
        onChange={(v) => {
          setFieldValue(`nonElucidatDetails[${index}].scored_assessment`, v?.value, true);
        }}
      />
    ) : obj?.scored_assessment,
  }));

  return (
    <>
      <Grid item xs={12} md={12} sx={{ padding: '1rem 0' }}>
        <ContentDetailsLabel
          label="Non Elucidat Details"
          subHeading="Values entered for SCORM Link and Box Link will be updated for respective SCORM"
          subheadingVariant="body1"
          labelCustomCss={customLabel}
        />
      </Grid>
      <Grid item xs={12} md={12} style={{ paddingTop: 0, paddingBottom: '1rem' }}>
        {
          isLoading ? (
            <LoadingCircle labelText="Loading Elucidat Details" />
          ) : (
            <TableRC
              columns={[
                {
                  title: 'SCORM Type', property: 'scorm_type', width: '15%', infoText: 'Authoring tool where the SCORM was created',
                },
                {
                  title: 'Component Title', property: 'component_title', width: '15%', infoText: 'Docebo component which contains the SCORM training material',
                },
                {
                  title: 'SCORM URL', property: 'scorm_url', width: '20%', infoText: 'Link to the project in the Authoring tool that was used to create SCORM',
                },
                {
                  title: 'SCORM Title', property: 'scorm_title', width: '15%', infoText: 'Title of the SCORM training material',
                },
                {
                  title: 'Box Link', property: 'box_link', width: '20%', infoText: 'Box link where the SCORM package is maintained',
                },
                {
                  title: 'Scored Assessment', property: 'scored_assessment', width: '15%', infoText: 'Indicates if the SCORM training material has a scored assessment',
                },
              ]}
              data={transformedData}
              noDataMessage="Non Elucidat SCORMS unavailable for this learning plan. Click on “Add Non Elucidat SCORM” to add SCORMS from other authoring tools."
            />
          )
        }
      </Grid>
    </>
  );
};

NonElucidatDetails.propTypes = {
  values: PropTypes.object.isRequired,
  customLabel: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default NonElucidatDetails;
