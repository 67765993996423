import config from '../../config';
import request from '../../helpers/apiHelper';

export const getLearningPlanSettings = (programId, doceboVal) => {
  const url = `${config.ROUTES.LP_SETTINGS}/${programId}?update=${doceboVal ? 'true' : 'false'}`;
  const options = { url, method: 'GET' };
  return request(options);
};

export const updateLearningPlanSettings = (programId, data) => {
  const url = `${config.ROUTES.LP_SETTINGS}/${programId}`;
  const options = { url, method: 'PUT', data };
  return request(options);
};

export const updateLearningPlanAccess = (doceboLpId, data) => {
  const url = `${config.ROUTES.LP_SETTINGS}/access/${doceboLpId}`;
  const options = { url, method: 'PUT', data };
  return request(options);
};
