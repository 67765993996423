import {
  USERS_FETCH, USERS_FETCH_SUCCESS, USERS_FETCH_FAILED, SET_LOADING, CLEAR_ALERTBAR,
  MARK_FOR_DELETION, MARK_FOR_DELETION_SUCCESS, MARK_FOR_DELETION_FAILURE, CLEAR_TRANSACTION_ID,
  DELETE_MARKED_USER, DELETE_MARKED_USER_SUCCESS, DELETE_MARKED_USER_FAILURE, SET_TRANSACTION_ID,
} from '../../constants';

export function getUsers(data) {
  return {
    type: USERS_FETCH,
    data,
  };
}

export function getUsersSuccess(results) {
  return {
    type: USERS_FETCH_SUCCESS,
    results,
  };
}

export function getUsersFailure(response) {
  return {
    type: USERS_FETCH_FAILED,
    response,
  };
}

export function setLoading(response) {
  return {
    type: SET_LOADING,
    response,
  };
}

export function clearAlertBar(response) {
  return {
    type: CLEAR_ALERTBAR,
    response,
  };
}

export function markForDelete(data) {
  return {
    type: MARK_FOR_DELETION,
    data,
  };
}

export function clearTransactionId() {
  return {
    type: CLEAR_TRANSACTION_ID,
  };
}

export function markForDeleteSuccess(results) {
  return {
    type: MARK_FOR_DELETION_SUCCESS,
    results,
  };
}

export function markForDeleteFailure(response) {
  return {
    type: MARK_FOR_DELETION_FAILURE,
    response,
  };
}
export function deleteUser(data) {
  return {
    type: DELETE_MARKED_USER,
    data,
  };
}

export function deleteUserSucess(results) {
  return {
    type: DELETE_MARKED_USER_SUCCESS,
    results,
  };
}

export function deleteUserFailure(response) {
  return {
    type: DELETE_MARKED_USER_FAILURE,
    response,
  };
}

export function setTransactionId(data) {
  return {
    type: SET_TRANSACTION_ID,
    data,
  };
}
