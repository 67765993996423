/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import { TableCell, TableRow, TableHead } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { MODERATE_LIGHT_GREY, BLACK, LIGHT_MEDIUM_GREY } from '../../stylesheets/colors';
import InfoTooltip from '../common/InfoTooltip';

const useStyles = makeStyles({
  wrapper: {
    '& .MuiTableCell-head': {
      backgroundColor: MODERATE_LIGHT_GREY,
      color: BLACK,
      fontSize: '1.25rem',
      zIndex: 22,
    },
  },
  headTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  checkbox: {
    height: '1.125rem',
    color: LIGHT_MEDIUM_GREY,
    opacity: 0.6,
    margin: '0 2.5rem 0 0.75rem',
    display: 'flex !important',
    justifyContent: 'end !important',
  },
  checkboxChecked: {
    opacity: 1,
  },
});

const EnhancedTableHeader = ({
  headCells,
  getTooltipJSX,
  order,
  disableAllSorting,
  defaultHeaderCell,
  orderBy,
  onRequestSort,
  checkboxEnable,
  checkboxHandler,
  defaultCheckBoxValue,
  isDisabled,
}) => {
  const classes = useStyles();
  const [check, setCheck] = useState(defaultCheckBoxValue);

  useEffect(() => {
    if (defaultCheckBoxValue) {
      checkboxHandler(defaultCheckBoxValue);
    }
    setCheck(defaultCheckBoxValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCheckBoxValue]);

  const handleChange = (e) => {
    setCheck(e.target.checked);
    checkboxHandler(e.target.checked);
  };

  return (
    <TableHead className={classes.wrapper}>
      <TableRow>
        {checkboxEnable && (
          <TableCell key="selectAll" size="medium" align="center">
            <Checkbox
              // className={classes.checkbox}
              checked={defaultHeaderCell ? false : check}
              onChange={(e) => {
                handleChange(e);
              }}
              name="checkAll"
              data-testid="selectAllrow"
              color="primary"
              classes={{
                checked: classes.checkboxChecked,
              }}
              disabled={isDisabled}
            />
          </TableCell>
        )}
        {defaultHeaderCell && <TableCell key="selectAll" size="medium" align="center" />}
        {headCells.map(({
          title, property, sortable, infoText, width, ...otherProps
        }) => (
          <TableCell
            key={property}
            size="medium"
            onClick={
              sortable === true && !disableAllSorting ? () => onRequestSort(property) : () => {}
            }
            style={width && { width }}
            {...otherProps}
          >
            <Box className={classes.headTitle}>
              {title}
              {sortable === true && !disableAllSorting ? (
                orderBy === property ? (
                  <>
                    {order === 'desc' ? (
                      <ArrowDownwardIcon style={{ color: '#2251FF' }} />
                    ) : (
                      <ArrowUpwardIcon style={{ color: '#2251FF' }} />
                    )}
                  </>
                ) : (
                  <ArrowUpwardIcon style={{ color: '#757575' }} />
                )
              ) : null}
              {infoText?.length > 0 && <InfoTooltip title={infoText} placement="top" />}
              {property === 'user_access' && (
              <InfoTooltip title={getTooltipJSX()} placement="top" />
              )}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHeader.defaultProps = {
  checkboxEnable: false,
  checkboxHandler: () => {},
  defaultCheckBoxValue: false,
  getTooltipJSX: () => {},
  disableAllSorting: false,
  defaultHeaderCell: false,
  isDisabled: false,
};

EnhancedTableHeader.propTypes = {
  headCells: PropTypes.array.isRequired,
  getTooltipJSX: PropTypes.func,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  checkboxEnable: PropTypes.bool,
  checkboxHandler: PropTypes.func,
  defaultCheckBoxValue: PropTypes.bool,
  disableAllSorting: PropTypes.bool,
  defaultHeaderCell: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default EnhancedTableHeader;
