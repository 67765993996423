import {
  CLIENT_DATA_FETCH,
  CLIENT_DATA_FETCH_SUCCESS,
  CLIENT_DATA_FETCH_FAILED,
  UPDATE_CLIENT_INFO,
  UPDATE_CLIENT_INFO_SUCCESS,
  UPDATE_CLIENT_INFO_FAILURE,
  ADD_NEW_CLIENT,
  ADD_NEW_CLIENT_SUCCESS,
  ADD_NEW_CLIENT_FAILURE,
} from '../constants';

const initialState = {
  isLoading: true,
  isError: false,
  result: {},
  message: '',
  statusText: '',
  id: '',
  searchText: '',
  page: 0,
};

function updateScreenState(state, { data }) {
  const { searchValue, pageNum } = data;
  if (typeof searchValue !== 'undefined' && searchValue !== state.searchText) {
    return {
      ...state, result: {}, searchText: searchValue, isLoading: true, isError: false, page: pageNum,
    };
  }
  return {
    ...state, isLoading: true, isError: false, page: pageNum || state.page,
  };
}

function onFetchSuccess(state, { results }) {
  if (state.page > 1) {
    const data = state.result.data ? state.result.data.concat(results.data) : results.data;
    const result = results;
    result.data = data;
    return {
      ...state, result, message: '', id: '', isLoading: false,
    };
  }

  const result = results;
  return {
    ...state, result, message: '', id: '', isLoading: false,
  };
}

function onUpdateSuccess(state, { response }) {
  if (response) {
    const { message, id } = response;
    return {
      ...state, message, id, isLoading: false,
    };
  }
  return {
    ...state, isLoading: false,
  };
}

function onFetchFailure(state, { response }) {
  const { message } = response;
  return {
    ...state, message, isLoading: false, isError: true,
  };
}

export default function clientMasterData(state = initialState, action = {}) {
  switch (action.type) {
    case CLIENT_DATA_FETCH:
    case UPDATE_CLIENT_INFO:
    case ADD_NEW_CLIENT:
      return updateScreenState(state, action);
    case CLIENT_DATA_FETCH_SUCCESS:
      return onFetchSuccess(state, action);
    case UPDATE_CLIENT_INFO_SUCCESS:
    case ADD_NEW_CLIENT_SUCCESS:
      return onUpdateSuccess(state, action);
    case CLIENT_DATA_FETCH_FAILED:
    case UPDATE_CLIENT_INFO_FAILURE:
    case ADD_NEW_CLIENT_FAILURE:
      return onFetchFailure(state, action);
    default:
      return state;
  }
}
