import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { CLIENT_MASTER, PORTFOLIO_MASTER, SKILL_MASTER } from '../../constants';
import { MODERATE_LIGHT_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    display: 'flex',
    flex: 1,
    userSelect: 'none',
    textDecoration: 'none !important',
    '-webkit-user-select': 'none',
    '-moz-select': 'none',
    '-ms-select': 'none',
    '&:hover': {
      textDecoration: 'none',
      color: 'initial',
    },
    '&:active': {
      textDecoration: 'none',
      color: 'initial',
    },
    '&:focus': {
      textDecoration: 'none',
      color: 'initial',
    },
  },
  selectedlink: {
    background: MODERATE_LIGHT_GREY,
  },
}));

const MasterDataSidebar = ({ programSubType }) => {
  const classes = useStyles();
  const menuItems = [
    { label: 'Client Master', link: CLIENT_MASTER },
    { label: 'Portfolio, Offering Master', link: PORTFOLIO_MASTER },
    { label: 'Skill Master', link: SKILL_MASTER },
  ];
  return (
    <Paper className={classes.wrapper}>
      <div className={classes.cardWrapperOuter}>
        <List component="nav" className={classes.list} disablePadding>
          {
            menuItems.map((item) => (
              <Link
                key={item.label}
                className={classes.link}
                color="inherit"
                component={RouterLink}
                to={{ pathname: `/master_data/${item.link}` }}
              >
                <ListItem className={`${programSubType === item.link && classes.selectedlink}`}>
                  <ListItemText primary={item.label} />
                </ListItem>
              </Link>

            ))
        }
        </List>
      </div>
    </Paper>
  );
};

MasterDataSidebar.propTypes = {
  programSubType: PropTypes.string.isRequired,
};

export default MasterDataSidebar;
