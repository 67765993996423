import React, {
  useContext, useState, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link as RouterLink, withRouter, useLocation } from 'react-router-dom';

import { actions as midActions } from '@cs/mid-react-saga';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// import { GlobalHeader } from '@dat-frontend/ds-react-components';
import GlobalHeader from '../ds-react-components/GlobalHeader';
import UserDropdown from './UserDropdown';
import { getUserFromMid } from '../../helpers/userHelper';

import mckAcademyLogo from '../../assets/img/mck-academy-logo.svg';
import './Header.css';
import { ROUTE_HOME } from '../../constants';
import Config from '../../config';
import MyContext from '../../context';

const useStyles = makeStyles({
  tab: {
    '&.MuiTab-root': {
      color: 'White',
      textDecoration: 'none',
      fontSize: '1.125rem',
      fontFamily: 'McKinseySans',
    },
    '.MuiTabs-scrollableX': {
      overflow: 'visible',
    },
  },
  tabContainer: {
    overflow: 'visible !important;',
    marginTop: '12px',
  },
});

const Header = (props) => {
  const {
    logout, mid, allowedRoutes, tokenExpire,
  } = props;
  const user = getUserFromMid(mid);
  const classes = useStyles();
  const location = useLocation();
  // eslint-disable-next-line no-useless-escape
  const slashValue = location.pathname.split('/')[1];
  const { role } = useContext(MyContext);
  const [isUserDropdownOpen, setUserDropdownState] = useState(false);
  const [routepage, setRoutePage] = useState('');
  const [value, setValue] = useState(slashValue === 'report' ? 'home' : (slashValue || 'home'));
  const routesValue = useMemo(() => allowedRoutes.map((item) => item.value), [allowedRoutes]);

  useEffect(() => {
    setValue(slashValue === 'report' ? 'home' : (slashValue || 'home'));
    if (slashValue === 'home' || slashValue === 'error' || slashValue === '404') {
      setRoutePage('');
    } else if (slashValue === 'report') {
      setRoutePage(location.pathname);
    }
  }, [location.pathname, routesValue, slashValue]);

  useEffect(() => {
    if (tokenExpire) {
      logout();
    }
  }, [logout, tokenExpire]);

  const getAllowedRoutes = (routes) => routes.filter(({ permission }) => {
    if (!permission) return true;
    return permission.includes(role);
  });

  const onUserDropdownChange = (option) => {
    if (option.value === 'LOGOUT') logout();
  };
  const allowedTabs = getAllowedRoutes(allowedRoutes);

  const handleChange = (event, newValue) => {
    if (slashValue === 'report') {
      setRoutePage(location.pathname);
    }
    setValue(newValue);
  };
  const toggleUserDropdown = () => {
    setUserDropdownState((oldIsUserDropdownOpen) => !oldIsUserDropdownOpen);
  };

  return (
    <GlobalHeader>
      <GlobalHeader.Logo
        imageUrl={mckAcademyLogo}
        url={`${Config.BASE_PATH}${ROUTE_HOME}`}
        title="mckAcademyLogo"
      />
      <GlobalHeader.Left>
        <Tabs
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          value={value}
          className={classes.tabContainer}
          variant="scrollable"
        >
          {
            allowedTabs.map((tabs) => (
              <Tab
                key={tabs.title}
                label={tabs.title}
                value={tabs.value}
                className={classes.tab}
                component={RouterLink}
                to={tabs.value === 'home' && routepage ? { pathname: routepage } : { pathname: tabs.path }}
              />
            ))
           }
        </Tabs>
      </GlobalHeader.Left>

      <GlobalHeader.Right>
        <UserDropdown
          user={user}
          onDropdownChange={onUserDropdownChange}
          toggleDropdown={toggleUserDropdown}
          open={isUserDropdownOpen}
        />
      </GlobalHeader.Right>
    </GlobalHeader>
  );
};

Header.propTypes = {
  mid: PropTypes.shape({
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  logout: PropTypes.func.isRequired,
  allowedRoutes: PropTypes.array.isRequired,
  tokenExpire: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  mid: state.mid,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(midActions.logout()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
