import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getUsers, clearAlertBar, markForDelete, clearTransactionId, deleteUser,
  setTransactionId,
} from '../../store/actions/PlatformLevelUsers/users';
import PlatformUsers from './index';

const mapStateToProps = (state) => ({
  usersData: state.platformUsersData,
  transaction_id: state.platformUsersData.transaction_id,
  message: state.platformUsersData.message,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getUsers,
    clearAlertBar,
    markForDelete,
    clearTransactionId,
    setTransactionId,
    deleteUser,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PlatformUsers);
