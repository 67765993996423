/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@mui/material/Container';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@mui/material/Box';
import Typography from '@material-ui/core/Typography';
import LinkButton from '../common/LinkButton';
import AlertIcon from '../../assets/icons/alert-status-icon.svg';
import ErrorRoundIcon from '../../assets/icons/error-round.svg';
import DeleteRoundIcon from '../../assets/icons/delete-round-icon.svg';
import CheckRoundIcon from '../../assets/icons/green-round-check.svg';
import { ELECTRIC_BLUE, ACCORDION_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles(() => ({
  toastWrapper: {
    padding: '1rem',
    width: '420px',
  },
  toastBody: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  toastIcon: {
    width: '50px',
  },
  toastDescription: {
    width: '320px',
  },
  linkbtn: {
    textDecoration: 'none',
  },
  linkPrimary: {
    color: ELECTRIC_BLUE,
    paddingLeft: 0,
  },
  linkSecondary: {
    color: ACCORDION_GREY,
  },
}));

const ModalInfo = {
  duplicate_name: {
    title: 'Rename Filter',
    icon: AlertIcon,
    getMessage: (name) => (
      <Typography variant="subtitle2" component="p" style={{ fontWeight: 'lighter', lineHeight: '20px', padding: '1rem 0' }}>
        <strong style={{ fontWeight: 'bold' }}>{name}</strong>{' '}
        already exists. Please rename the filter to save it.
      </Typography>
    ),
  },
  duplicate_settings: {
    title: 'Duplicate Filter',
    icon: AlertIcon,
    getMessage: (name) => (
      <Typography variant="subtitle2" component="p" style={{ fontWeight: 'lighter', lineHeight: '20px', padding: '1rem 0' }}>
        <strong style={{ fontWeight: 'bold' }}>{name}</strong>{' '}
        is already saved with same settings. Click view to see it.
      </Typography>
    ),
    getActions: (onDelete, onCancel, primaryStyle, secondaryStyle) => (
      <>
        <LinkButton text="View" onClick={onDelete} className={primaryStyle} />
        <LinkButton text="Cancel" onClick={onCancel} className={secondaryStyle} />
      </>
    ),
  },
  max_limit: {
    title: 'Limit Reached',
    icon: ErrorRoundIcon,
    getMessage: () => (
      <Typography variant="subtitle2" component="p" style={{ fontWeight: 'lighter', lineHeight: '20px', padding: '1rem 0' }}>
        You have reached the maximum limit of{' '}
        <strong style={{ fontWeight: 'bold' }}>20 filter groups.</strong>{/*
        */}To create a new filter group, please delete or update an existing one.
      </Typography>
    ),
  },
  delete_confirm: {
    title: 'Delete Filter',
    icon: DeleteRoundIcon,
    getMessage: (name) => (
      <Typography variant="subtitle2" component="p" style={{ fontWeight: '300', padding: '1rem 0' }}>
        Are you sure you want to delete your custome <strong style={{ fontWeight: 'bold' }}>{name}</strong>?
      </Typography>
    ),
    getActions: (onDelete, onCancel, primaryStyle, secondaryStyle) => (
      <>
        <LinkButton text="Delete" onClick={onDelete} className={primaryStyle} />
        <LinkButton text="Cancel" onClick={onCancel} className={secondaryStyle} />
      </>
    ),
  },
  delete_success: {
    title: 'Deleted',
    icon: CheckRoundIcon,
    getMessage: (name) => (
      <Typography variant="subtitle2" component="p" style={{ fontWeight: '300', padding: '1rem 0' }}>
        <strong style={{ fontWeight: 'bold' }}>{name}</strong> has been successfully deleted.
      </Typography>
    ),
  },
  error: {
    title: 'Error',
    icon: ErrorRoundIcon,
    getMessage: (msg) => (
      <Typography variant="subtitle2" component="p" style={{ fontWeight: '300', padding: '1rem 0' }}>
        {msg}
      </Typography>
    ),
  },
};

const FilterInfoToast = ({
  close, closeIconStyle, data, filterName, onDelete,
}) => {
  const classes = useStyles();

  return (
    <Container className={classes.toastWrapper}>
      <IconButton aria-label="close" className={closeIconStyle} onClick={close}>
        <CloseIcon />
      </IconButton>
      <Box className={classes.toastBody}>
        <Box className={classes.toastIcon}>
          <img src={ModalInfo[data.type].icon} alt="error-icon" width={25} height={25} />
        </Box>
        <Box className={classes.toastDescription}>
          <Typography variant="h1" style={{ padding: 0 }}>{ModalInfo[data.type].title}</Typography>
          {data.type === 'error' ? ModalInfo[data.type].getMessage(data.message) : ModalInfo[data.type].getMessage(filterName)}
          {ModalInfo[data.type].getActions && (
            <Box className={classes.toastActions}>
              {ModalInfo[data.type].getActions(onDelete, close, `${classes.linkbtn} ${classes.linkPrimary}`, `${classes.linkbtn} ${classes.linkSecondary}`)}
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

FilterInfoToast.propTypes = {
  close: PropTypes.func.isRequired,
  closeIconStyle: PropTypes.string.isRequired,
  data: PropTypes.shape({
    type: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }).isRequired,
  filterName: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default FilterInfoToast;
