import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { getConfigNameOfReplicatedProgram } from '../../containers/ReplicationPage/api';

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
  },
  link: {
    textDecoration: 'underline',
  },
});

const ReplicationPrompt = ({ sourceConfigId, sourceProgramId, fileName }) => {
  const classes = useStyles();
  const [replicationId, setReplicationId] = useState(sourceConfigId);
  const [replicationProgramId, setReplicationProgramId] = useState(sourceProgramId);

  const getReplicationName = useCallback(async (programId) => {
    const { data } = await getConfigNameOfReplicatedProgram(programId);
    if (data?.source_config_id) {
      // when receive source_config_id/source_program_id
      setReplicationId(data.source_config_id);
      setReplicationProgramId(data.source_program_id);
    }
  }, []);

  useEffect(() => {
    if ((!replicationId || !replicationProgramId) && fileName) {
      /* when sourceConfigId/sourceProgramId is null */
      getReplicationName(fileName);
    }
  }, [replicationId, replicationProgramId, fileName, getReplicationName]);

  return (
    <div className={classes.wrapper}>
      Replicated from
      {' '}
      <a href={`report/${replicationProgramId}/overview`}>{replicationId}</a>
    </div>
  );
};

ReplicationPrompt.propTypes = {
  sourceConfigId: PropTypes.string.isRequired,
  sourceProgramId: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default ReplicationPrompt;
