import React, {
  useEffect, useState, useCallback, useContext,
} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';

import {
  ROUTE_ERROR,
  TEST_USERS_CREATION,
  METADATA_MANAGEMENT,
  ASSIGNMENT_DOWNLOAD,
  UPLOAD_GRADE,
  BULK_UPDATE_ACCOUNTS,
  PROGRAM_CREATION,
  ROUTE_REPORT,
  ROUTE_HOME,
  ASSIGN_COURSE_CONTENT,
  BULK_UNENROLLMENT,
  AUTO_ENROLLMENT,
  GROUPWORK_GROUPS,
  OVERVIEW,
  WEBINAR_MANAGEMENT,
  REPLICATION,
  EDIT_PROGRAM,
  ADMIN_GROUP,
  ACTIVITIES_STATUS,
  SETTINGS,
  OPS_FEATURE,
  ROLE_PROD,
  WORKSHOP_MANAGEMENT,
  MODIFY_GRADE,
  GLOBAL_ACTIVE_LOGS,
  ACTIVE_LOGS_MENU, CONTENT_MANAGEMENT, PRODUCTION_CHECKLIST,
  SYNC_SESSION_ENROLLMENTS, SINGLE_SESSION_SYNC,
  UPLOAD_GROUPWORK_ROLES, WAVE_MANAGEMENT, GROUP_ENROLLMENT,
  permissionGroup,
} from '../../constants';

import ProgramCreationReportPage from '../ProgramCreationReportPage';
import TestUserCreationReportPage from '../TestUserCreationReportPage';
import MetaDataManagement from '../MetaDataManagement';
import OuterSidebar from '../../components/OuterSidebar';
import ReportBanner from '../../components/CourseProdReportBody/FlowCommonComponents/ReportBanner';
import FindContentReportPage from '../FindContentReportPage';
import GroupWorkPage from '../GroupWorkPage';
import BulkUnenrollReportPage from '../BulkUnenrollReportPage';
import OverviewPage from '../OverviewPage';
import WebinarManagementPage from '../WebinarManagementPage';
import UserEnrollmentPage from '../UserEnrollment';
import ReplicationPage from '../ReplicationPage';
import EditProgramPage from '../EditProgramPage';
import AssignmentManagementPage from '../AssignmentManagementPage';
import WorkshopManagementPage from '../WorkshopManagementPage';
import UploadGradesPage from '../UploadGradesPage';
import BulkUpdateAccountsPage from '../BulkUpdateAccountsPage';
import SettingsPage from '../SettingsPage';
import WaveManagement from '../WaveManagementPage';
import ProductionChecklist from '../ProductionChecklist';
import ActivityLogs from '../ActivityLogs';
import GlobalActivityNav from '../../components/common/GlobalActivityNav';
import { VERY_LIGHT_GREY } from '../../stylesheets/colors';

import { createProgramLinks, getExperienceTypeOptions, getProgramInfo } from '../common/apis';
import {
  updateProgramMetadata, onDownload, getExtendedEnterprise, getDeltaForEnrollment,
  onForceEnroll,
} from './apis';
import ContentManagementPage from '../ContentManagement';
import AppAlertBar from '../../components/common/AppAlertBar';
import { NotificationProvider } from '../../providers/NotificationProvider';
import MyContext from '../../context';

const exceptionalEndpoints = [EDIT_PROGRAM];

const useStyles = makeStyles({
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: VERY_LIGHT_GREY,
    minHeight: '100vh',
  },
  containerWrapper: {
    display: 'flex',
    flex: 1,
  },
  contentWrapper: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },
  collapseContent: {
    flex: '0.98',
  },
  logWrapper: {
    maxHeight: '100%',
    flex: '0.02',
  },
  summaryBanner: {
    display: 'flex',
  },
  innerWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  sidebarWrapper: {
    display: 'flex',
    flex: 0.18,
    marginRight: '2rem',
    minWidth: '15rem',
  },
  bodyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.82,
    marginTop: '2rem',
  },
  drawerWrapper: {
    '& .MuiDrawer-paper': {
      display: 'flex',
      flexDirection: 'row',
    },
  },
});

const subtypeAssignmentComponent = {
  [OVERVIEW]: {
    component: OverviewPage,
    permission: permissionGroup.ALL,
  },
  [PROGRAM_CREATION]: {
    component: ProgramCreationReportPage,
    permission: permissionGroup.GROUP2,
  },
  [ASSIGN_COURSE_CONTENT]: {
    component: FindContentReportPage,
    permission: permissionGroup.ALL,
  },
  [TEST_USERS_CREATION]: {
    component: TestUserCreationReportPage,
    permission: permissionGroup.ALL,
  },
  [METADATA_MANAGEMENT]: {
    component: MetaDataManagement,
    permission: permissionGroup.ALL,
  },
  [CONTENT_MANAGEMENT]: {
    component: ContentManagementPage,
    permission: permissionGroup.ALL,
  },
  [PRODUCTION_CHECKLIST]: {
    component: ProductionChecklist,
    permission: permissionGroup.ALL,
  },
  [ASSIGNMENT_DOWNLOAD]: {
    component: AssignmentManagementPage,
    permission: permissionGroup.GROUP1,
  },
  [UPLOAD_GRADE]: {
    component: UploadGradesPage,
    permission: permissionGroup.GROUP1,
  },
  [MODIFY_GRADE]: {
    component: UploadGradesPage,
    permission: permissionGroup.GROUP1,
  },
  [BULK_UPDATE_ACCOUNTS]: {
    component: BulkUpdateAccountsPage,
    permission: permissionGroup.ADMIN,
  },
  [GROUPWORK_GROUPS]: {
    component: GroupWorkPage,
    permission: permissionGroup.ALL,
  },
  [UPLOAD_GROUPWORK_ROLES]: {
    component: GroupWorkPage,
    permission: permissionGroup.GROUP1,
  },
  [GROUP_ENROLLMENT]: {
    component: GroupWorkPage,
    permission: permissionGroup.GROUP1,
  },
  [AUTO_ENROLLMENT]: {
    component: UserEnrollmentPage,
    permission: permissionGroup.GROUP1,
  },
  [BULK_UNENROLLMENT]: {
    component: BulkUnenrollReportPage,
    permission: permissionGroup.GROUP1,
  },
  [WEBINAR_MANAGEMENT]: {
    component: WebinarManagementPage,
    permission: permissionGroup.ALL,
  },
  [REPLICATION]: {
    component: ReplicationPage,
    permission: permissionGroup.GROUP2,
  },
  [EDIT_PROGRAM]: {
    component: EditProgramPage,
    permission: permissionGroup.GROUP2,
  },
  [SETTINGS]: {
    component: SettingsPage,
    permission: permissionGroup.ALL,
  },
  [WORKSHOP_MANAGEMENT]: {
    component: WorkshopManagementPage,
    permission: permissionGroup.ALL,
  },
  [SYNC_SESSION_ENROLLMENTS]: {
    component: WorkshopManagementPage,
    permission: permissionGroup.ALL,
  },
  [SINGLE_SESSION_SYNC]: {
    component: WorkshopManagementPage,
    permission: permissionGroup.ALL,
  },
  [WAVE_MANAGEMENT]: {
    component: WaveManagement,
    permission: permissionGroup.GROUP1,
  },
};

// export const Context = createContext();

const ReportPage = ({ match, location }) => {
  const classes = useStyles();
  const history = useHistory();
  const programId = get(match, 'params.programId');
  const {
    role, isActivityLogModalOpen,
  } = useContext(MyContext);
  const [programSubType, qs_opt] = get(match, 'params.programSubType').split('?');
  const routeTransactionId = get(match, 'params.transactionId');
  const qs = location.search || (qs_opt ? `?${qs_opt}` : '');

  const [programMetadata, setProgramMetadata] = useState(null);
  const [experienceTypeOptions, setExperienceTypeOptions] = useState([]);
  const [extendedEnterpriseTypeOptions, setExtendedEnterpriseTypeOptions] = useState([]);
  const [isSnackBarOpen, setSnackBarOpen] = useState(false);
  const [updatedConfig, setUpdatedConfig] = useState({
    updatedStatus: '',
    updatedExp: '',
    updatedPlatformUrl: '',
  });
  const [snackBarMessage, setSnackBarMessage] = useState(
    'Unable to connect to Docebo. Please try later.',
  );
  const [open, setOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(ACTIVE_LOGS_MENU[0]);
  // const [isActivityLogModalOpen, setIsActivityLogModalOpen] = useState(false);

  const isShow = GLOBAL_ACTIVE_LOGS.includes(programSubType);

  const toggleDrawer = (value) => {
    setOpen(value);
  };

  const onMenuItemClick = (item) => {
    setSelectedMenu(item);
  };

  const Body = subtypeAssignmentComponent[programSubType].component || (() => null);

  useEffect(() => {
    window.heap.track(OPS_FEATURE, programSubType);
    console.log('tracking OPS_FEATURE => ', programSubType);
  }, [programSubType]);

  useEffect(() => {
    setUpdatedConfig({ updatedStatus: '', updatedExp: '', updatedPlatformUrl: '' });
  }, [programId]);

  useEffect(() => {
    (async () => {
      try {
        const listEE = await getExtendedEnterprise();
        setExtendedEnterpriseTypeOptions(get(listEE, 'data.results', []));
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  useEffect(() => {
    const goToError = () => {
      history.replace(`/${ROUTE_ERROR}`);
    };

    const fetchProgramMetadata = async () => {
      try {
        const response = await getProgramInfo(programId);
        setProgramMetadata(response);
      } catch (e) {
        console.log(e);

        // If error status 404, then redirect to 404 page
        if (get(e, 'response.status', 0) === 404) {
          goToError();
        }
      }
    };

    if (!Object.keys(subtypeAssignmentComponent).includes(programSubType)
      || !subtypeAssignmentComponent[programSubType].permission.includes(role)) {
      goToError();
      return;
    }
    fetchProgramMetadata();
  }, [role, programId, setProgramMetadata, history, programSubType]);

  const onProgramMetadataRefresh = useCallback(async () => {
    const response = await getProgramInfo(programId);
    setProgramMetadata(response);
  }, [programId]);

  const transactionIds = get(
    programMetadata,
    `activities_status.${ACTIVITIES_STATUS[programSubType] ? ACTIVITIES_STATUS[programSubType] : ''
    }.${programSubType}.transaction_ids`,
    [],
  );

  // handle browser back button
  useEffect(
    () => () => {
      if (history.action === 'POP') {
        const currentSearchText = get(location, 'state.currentSearchText', '');
        history.push(`/${ROUTE_HOME}`, { currentSearchText });
      }
    },
    [history, location],
  );

  useEffect(() => {
    const currentSearchText = get(location, 'state.currentSearchText', '');
    if (
      !routeTransactionId
      && get(transactionIds, 'length') === 1
      && !exceptionalEndpoints.includes(programSubType)
    ) {
      const tid = transactionIds[0];
      history.replace(`/${ROUTE_REPORT}/${programId}/${programSubType}/${tid}`, {
        currentSearchText,
      });
    }
  }, [
    history,
    location,
    programId,
    programSubType,
    transactionIds,
    routeTransactionId,
  ]);

  useEffect(() => {
    const populateExpTypes = async () => {
      try {
        const expTypeResult = await getExperienceTypeOptions();
        const options = expTypeResult.data.data.options
          .sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }))
          .map((op) => ({ value: op, label: op }));
        setExperienceTypeOptions(options);
      } catch (e) {
        setSnackBarMessage('Unable to load experiences from Docebo. Please try later.');
        setSnackBarOpen(true);
        setExperienceTypeOptions([]);
      }
    };

    if (programMetadata) {
      populateExpTypes();
    }
  }, [setExperienceTypeOptions, programMetadata]);

  if (!programMetadata) return <div />; // Loading

  const onProgramSubtypeChange = (newProgramSubType) => {
    const currentSearchText = get(location, 'state.currentSearchText', '');
    const newTid = get(
      programMetadata,
      `activities_status.${ACTIVITIES_STATUS[newProgramSubType] ? ACTIVITIES_STATUS[newProgramSubType] : ''
      }.${newProgramSubType}.transaction_id`,
    );

    // const unmappedFeatures = [TEST_USERS_CREATION, METADATA_MANAGEMENT, GROUPWORK_GROUPS];

    // const typeOfUser = PROGRAM_TYPE[newProgramSubType]
    //   && !unmappedFeatures.includes(newProgramSubType)
    //   ? PROGRAM_TYPE[newProgramSubType] : programType;

    const routeToNavigate = `/${ROUTE_REPORT}/${programId}/${newProgramSubType}/${newTid || ''}`;

    history.push(routeToNavigate, { currentSearchText });
  };

  const isAdmin = ADMIN_GROUP.includes(role);
  const showAcadmeygGo = isAdmin || role === ROLE_PROD;
  const showIsGsr = isAdmin || role === ROLE_PROD;

  const onBackClick = () => {
    const currentSearchText = get(location, 'state.currentSearchText', '');
    history.push(`/${ROUTE_HOME}`, { currentSearchText });
  };

  // const environment = getEnvironmentFromLocalStorage();

  const onLinkSave = async (linkObj) => {
    try {
      await createProgramLinks(programId, linkObj);
      await onProgramMetadataRefresh();
    } catch (e) {
      setSnackBarMessage('Unable to create program links. Please try later.');
      setSnackBarOpen(true);
    }
  };

  const onExperienceTypeChange = async (newExpType) => {
    try {
      await updateProgramMetadata(programId, 'primary_experience', newExpType);
      setProgramMetadata((prevMetadata) => ({ ...prevMetadata, experience: newExpType }));
    } catch (e) {
      setSnackBarMessage('Unable to change primary experience. Please try later.');
      setSnackBarOpen(true);
    }
  };

  const onExtendedEnterpriseChange = async (newExpType) => {
    try {
      await updateProgramMetadata(programId, 'extended_enterprise', newExpType);
      setProgramMetadata((prevMetadata) => ({ ...prevMetadata, extended_enterprise: newExpType }));
    } catch (e) {
      setSnackBarMessage('Unable to change extended enterprise. Please try later.');
      setSnackBarOpen(true);
    }
  };

  const onStageChange = async (newStage) => {
    try {
      await updateProgramMetadata(programId, 'stage', newStage);
    } catch (e) {
      setSnackBarMessage('Unable to change stage. Please try later.');
      setSnackBarOpen(true);
    }
  };

  const getValueFromConfig = (updatedStatus, updatedExp, updatedPlatformUrl) => {
    setUpdatedConfig({ updatedStatus, updatedExp, updatedPlatformUrl });
  };

  const onGoStatusChange = (is_academy_go) => {
    setProgramMetadata((prevMetadata) => ({ ...prevMetadata, is_academy_go }));
  };

  const onGsrStatusChange = (is_gsr) => {
    setProgramMetadata((prevMetadata) => ({ ...prevMetadata, is_gsr }));
  };

  const preparePageTitle = (title) => title.replaceAll('_', ' ').toUpperCase();

  return (
    <div className={classes.wrapper}>
      <div className={classes.containerWrapper}>
        <div className={`${classes.contentWrapper} ${isShow ? classes.collapseContent : null}`}>
          <Helmet>
            <title>{preparePageTitle(programSubType)}</title>
          </Helmet>
          <div className={classes.summaryBanner}>
            <ReportBanner
              onBackClick={onBackClick}
              programMetadata={{ ...programMetadata, ...updatedConfig }}
              onLinkSave={onLinkSave}
              experienceTypeOptions={experienceTypeOptions}
              extendedEnterpriseTypeOptions={extendedEnterpriseTypeOptions}
              onExtendedEnterpriseChange={onExtendedEnterpriseChange}
              onExperienceTypeChange={onExperienceTypeChange}
              onStageChange={onStageChange}
              match={match}
              userRole={role}
            />
          </div>
          <div className={classes.innerWrapper}>
            <div className={classes.sidebarWrapper}>
              <OuterSidebar
                programSubType={programSubType}
                onChange={onProgramSubtypeChange}
                programMetadata={programMetadata}
              />
            </div>
            <div className={classes.bodyWrapper}>
              <NotificationProvider>
                <AppAlertBar />
                <Body
                  match={match}
                  history={history}
                  location={location}
                  isAdmin={isAdmin}
                  userRole={role}
                  experienceTypeOptions={experienceTypeOptions}
                  extendedEnterpriseTypeOptions={extendedEnterpriseTypeOptions}
                  onProgramMetadataRefresh={onProgramMetadataRefresh}
                  transactionIds={transactionIds}
                  programMetadata={programMetadata}
                  onDownload={onDownload}
                  getDeltaForEnrollment={getDeltaForEnrollment}
                  onForceEnroll={onForceEnroll}
                  currentSearchText={get(location, 'state.currentSearchText', '')}
                  getValueFromConfig={getValueFromConfig}
                  onGoStatusChange={onGoStatusChange}
                  onGsrStatusChange={onGsrStatusChange}
                  qs={qs}
                  isActivityLogModalOpen={isActivityLogModalOpen}
                  showAcadmeygGo={showAcadmeygGo}
                  showIsGsr={showIsGsr}
                  programId={programId}
                />
              </NotificationProvider>
            </div>
          </div>
        </div>
        <Paper className={classes.logWrapper} component="div" elevation={3}>
          <GlobalActivityNav
            expanded={open}
            toggleDrawer={toggleDrawer}
            selectedMenu={selectedMenu}
            onMenuItemClick={onMenuItemClick}
            disabled
          />
        </Paper>
        <Drawer
          anchor="right"
          open={open}
          onClose={() => toggleDrawer(false)}
          className={classes.drawerWrapper}
        >
          <Paper className={classes.drawerIcons} elevation={3}>
            <GlobalActivityNav
              expanded={open}
              toggleDrawer={toggleDrawer}
              selectedMenu={selectedMenu}
              onMenuItemClick={onMenuItemClick}
              disabled={false}
            />
          </Paper>
          <Box className={classes.drawerContainer}>
            <ActivityLogs
              selectedMenu={selectedMenu}
              programId={programId}
              programMetadata={programMetadata}
              programSubType={programSubType}
            />
          </Box>
        </Drawer>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isSnackBarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackBarOpen(false)}
      >
        <Alert
          className={classes.alertSnackBar}
          onClose={() => setSnackBarOpen(false)}
          severity="error"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

ReportPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string,
    }),
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.any,
    search: PropTypes.string,
  }).isRequired,
};

export default ReportPage;
