import {
  DELETE_CLIENT_STARTED,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILED,
  DELETE_CLIENT_RESET,
} from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  isError: false,
  isMappedCohorts: false,
  message: '',
};

function onDeleteSuccess(state, { response }) {
  const { message, clientName, mapped_cohorts } = response;
  return {
    ...state, message, clientName, isLoading: false, isMappedCohorts: mapped_cohorts !== undefined, mapped_cohorts, status: 'success',
  };
}

export default function clientMasterData(state = initialState, action = {}) {
  switch (action.type) {
    case DELETE_CLIENT_STARTED:
      return { ...state, isLoading: true, status: 'ongoing' };
    case DELETE_CLIENT_SUCCESS:
      return onDeleteSuccess(state, action);
    case DELETE_CLIENT_FAILED:
      return {
        ...state, isLoading: false, isError: true, status: 'failed', message: action.response.message,
      };
    case DELETE_CLIENT_RESET:
      return {
        ...state, isLoading: false, isError: false, status: '', isMappedCohorts: false, mapped_cohorts: undefined,
      };
    default:
      return state;
  }
}
