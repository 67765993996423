/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import { useQuery } from 'react-query';
import _map from 'lodash/map';
import { getErrorMessage } from '../helpers/apiHelper';
import useNotifications from './useNotifications';

const useProductionChecklistData = (getOverviewDetails, getProgramDetails,
  getDoceboDetails, getElucidatDetails,
  getNonElucidatDetails, getChannels, getTestUsersByTid, getJournalDetails, programId) => {
  const { notifyError } = useNotifications();
  const [refresh, setRefresh] = useState(false);

  const formatDate = (date) => date && moment(date).format('yyyy-MM-DD');

  const {
    data: overviewSectionData, isFetching: isOverviewLoading,
    isError: isOverviewError, error: overviewError, refetch: refetchOverviewSectionData,
  } = useQuery(['overview', programId], async () => {
    const response = await getOverviewDetails(programId);
    if (response.data.success) {
      return ({
        ...response.data.data,
        overview_closeout_date: formatDate(response.data.data.overview_closeout_date),
        overview_docebo_std_date: formatDate(response.data.data.overview_docebo_std_date),
        overview_elucidat_std_date: formatDate(response.data.data.overview_elucidat_std_date),
      });
    }
    throw new Error(response?.data?.message);
  }, { enabled: false });

  const {
    data: programDetailsSectionData, isFetching: isProgramDetailsLoading,
    isError: isProgramDetailsError, error: programDetailsError,
    refetch: refetchProgramDetailsSectionData,
  } = useQuery(['program', programId], async () => {
    const response = await getProgramDetails(programId);
    if (response.data.success) {
      return ({
        ...response.data.data,
      });
    }
    throw new Error(response?.data?.message);
  }, { enabled: false });

  const {
    data: nonElucidatDetailsSectionData, isFetching: isNonElucidatDetailsLoading,
    isError: isNonElucidatDetailsError, error: nonElucidatDetailsError,
    refetch: refetchNonElucidatDetailsSectionData,
  } = useQuery(['nonElucidat', programId], async () => {
    const response = await getNonElucidatDetails(programId);
    if (response.data.success) {
      return ({
        nonElucidatDetails: response.data.data,
      });
    }
    throw new Error(response?.data?.message);
  }, { enabled: false });

  const {
    data: doceboDetailsSectionData, isFetching: isDoceboDetailsLoading,
    isError: isDoceboDetailsError, error: doceboDetailsError,
    refetch: refetchDoceboDetailsSectionData,
  } = useQuery(['docebo', programId], async () => {
    const response = await getDoceboDetails(programId, refresh);
    if (response.data.success) {
      refetchNonElucidatDetailsSectionData();
      return ({
        doceboDetails: _map(response.data.data, (obj, index) => ({ ...obj, orderId: index + 1 })),
        lastRefreshed: response.data.last_refreshed,
      });
    }
    throw new Error(response?.data?.message);
  }, { enabled: false });

  const {
    data: elucidatDetailsSectionData, isFetching: isElucidatDetailsLoading,
    isError: isElucidatDetailsError, error: elucidatDetailsError,
    refetch: refetchElucidatDetailsSectionData,
  } = useQuery(['elucidat', programId], async () => {
    const response = await getElucidatDetails(programId, refresh);
    if (response.data.success) {
      return ({
        elucidatDetails: response.data.data,
      });
    }
    throw new Error(response?.data?.message);
  }, { enabled: false });

  const {
    data: channelDetailsSectionData, isFetching: ischannelDetailsLoading,
    isError: ischannelDetailsError, error: channelDetailsError,
    refetch: refetchChannelDetailsSectionData,
  } = useQuery(['channels', programId], async () => {
    const response = await getChannels(programId);
    if (response.data.success) {
      return ({
        channels: response.data.data,
      });
    }
    throw new Error(response?.data?.message);
  }, { enabled: false });

  const {
    data: testUsersSectionData, isFetching: isTestUsersLoading,
    isError: isTestUsersError, error: testUserError, refetch: refetchTestUsersSectionData,
  } = useQuery(['testUsers', programId], async () => {
    const response = await getTestUsersByTid(programId);
    if (response.data.success) {
      return ({ testUsers: response.data.data });
    }
    throw new Error(response?.data?.message);
  }, { enabled: false });

  const {
    data: journalSectionData, isFetching: isJournalsLoading,
    isError: isJournalError, error: journalsError, refetch: refetchJournalSectionData,
  } = useQuery(['journals', programId], async () => {
    const response = await getJournalDetails(programId);
    if (response.data.success) {
      return ({ ...response.data.data });
    }
    throw new Error(response?.data?.message);
  }, { enabled: false, retry: 1 });

  const fetchAsyncData = useCallback(() => {
    refetchOverviewSectionData();
    refetchProgramDetailsSectionData();
    refetchDoceboDetailsSectionData();
    refetchElucidatDetailsSectionData();
    refetchChannelDetailsSectionData();
    refetchTestUsersSectionData();
    refetchJournalSectionData();
  }, [refetchOverviewSectionData, refetchProgramDetailsSectionData, refetchDoceboDetailsSectionData,
    refetchElucidatDetailsSectionData,
    refetchChannelDetailsSectionData, refetchTestUsersSectionData, refetchJournalSectionData]);

  useEffect(() => {
    if (refresh) {
      refetchDoceboDetailsSectionData();
      refetchElucidatDetailsSectionData();
      setRefresh(false);
    }
  }, [refetchDoceboDetailsSectionData, refetchElucidatDetailsSectionData, refresh]);

  const refreshData = useCallback(() => {
    setRefresh(true);
  }, []);

  useEffect(() => {
    fetchAsyncData();
  }, [fetchAsyncData]);

  useEffect(() => {
    if (isOverviewError) {
      notifyError(getErrorMessage(overviewError));
    }
  }, [isOverviewError, overviewError]);

  useEffect(() => {
    if (isProgramDetailsError) {
      notifyError(getErrorMessage(programDetailsError));
    }
  }, [isProgramDetailsError, programDetailsError]);

  useEffect(() => {
    if (isDoceboDetailsError) {
      notifyError(getErrorMessage(doceboDetailsError));
    }
  }, [isDoceboDetailsError, doceboDetailsError]);

  useEffect(() => {
    if (isElucidatDetailsError) {
      notifyError(getErrorMessage(elucidatDetailsError));
    }
  }, [isElucidatDetailsError, elucidatDetailsError]);

  useEffect(() => {
    if (isNonElucidatDetailsError) {
      notifyError(getErrorMessage(nonElucidatDetailsError));
    }
  }, [isNonElucidatDetailsError, nonElucidatDetailsError]);

  useEffect(() => {
    if (ischannelDetailsError) {
      notifyError(getErrorMessage(channelDetailsError));
    }
  }, [ischannelDetailsError, channelDetailsError]);

  useEffect(() => {
    if (isTestUsersError) {
      notifyError(getErrorMessage(testUserError));
    }
  }, [isTestUsersError, testUserError]);

  useEffect(() => {
    if (isJournalError) {
      notifyError(getErrorMessage(journalsError));
    }
  }, [isJournalError, journalsError]);

  return {
    overviewSectionData,
    programDetailsSectionData,
    doceboDetailsSectionData,
    elucidatDetailsSectionData,
    nonElucidatDetailsSectionData,
    channelDetailsSectionData,
    testUsersSectionData,
    journalSectionData,
    isOverviewLoading,
    isProgramDetailsLoading,
    isDoceboDetailsLoading,
    isElucidatDetailsLoading,
    isNonElucidatDetailsLoading,
    ischannelDetailsLoading,
    isTestUsersLoading,
    isJournalsLoading,
    fetchAsyncData,
    refreshData,
  };
};

export default useProductionChecklistData;
