import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';

import Popover from '@material-ui/core/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import { WHITE, ACCORDION_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles({
  disabledList: {
    background: `${WHITE} !important`,
    pointerEvents: 'none',
    color: `${ACCORDION_GREY} !important`,
  },
});
const CustomPopover = ({
  list, button: Button, disabled, additionalClass,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const toggleMenu = (event) => {
    event.stopPropagation();
    setAnchorEl((prevState) => (prevState ? null : event.currentTarget));
  };

  const onListItemClick = (e, passedMethod) => {
    passedMethod();
    toggleMenu(e);
  };

  return (
    <>
      <Button disabled={disabled} onClick={toggleMenu} anchorEl={anchorEl} />
      <Popover
        disableEnforceFocus
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={toggleMenu}
        elevation={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableScrollLock
      >
        <List className={additionalClass} disablePadding>
          {list.map((item) => (
            <Box key={item?.title}>
              {item && (
                <ListItem key={item.title} disablePadding sx={item?.listItemStyle}>
                  <ListItemButton data-testid={`popoverActionBtn_${item?.value}`} className={item?.disabled ? classes.disabledList : ''} onClick={(e) => onListItemClick(e, item.onClick)}>
                    <ListItemText sx={item?.listItemTextStyle} primary={item?.title} />
                  </ListItemButton>
                </ListItem>
              )}
            </Box>
          ))}
        </List>
      </Popover>
    </>
  );
};
CustomPopover.defaultProps = {
  disabled: false,
  additionalClass: '',
};
CustomPopover.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  })).isRequired,
  button: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.func,
  ]).isRequired,
  disabled: PropTypes.bool,
  additionalClass: PropTypes.string,
};

export default memo(
  CustomPopover,
  (prevProp, nextProp) => _isEqual(prevProp.list, nextProp.list),
);
