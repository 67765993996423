import React from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpreadSheetGirlImage from '../../assets/icons/spreadsheet-girl.svg';
import ValidationError from '../../assets/img/validation-error.webp';
import { useStyles, useDialogStyles } from '../../stylesheets/commonModal.styles';
import errorLineStyles from './XlsxValidationModal.styles';
import { zeroAppender } from '../../helpers/formattingHelpers';

const DialogTitle = (props) => {
  const classes = useStyles();
  const { children, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.titleBlock}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      <Typography className={classes.titleText}>{children}</Typography>
    </MuiDialogTitle>
  );
};

DialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

const XlsxValidationModal = ({
  open, onClose, onOk, errors, type, filename,
}) => {
  const classes = useStyles();
  const dialogClasses = useDialogStyles();

  const ErrorLine = (error, index) => {
    const { row, column, message } = error;
    return (
      <div key={index} style={errorLineStyles.errorLine}>
        {type === 'Unenrollment' && (<span style={errorLineStyles.iconWrapper}>{`E${index + 1}`}</span>)}
        {type !== 'Unenrollment' && row && <span style={errorLineStyles.errorRow}>{`Row #${row}`}</span>}
        {column && <span style={errorLineStyles.errorColumn}>{`${column}: `}</span>}
        {message}
      </div>
    );
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      disableBackdropClick
      onClose={onClose}
      open={open}
      classes={dialogClasses}
    >
      <DialogTitle onClose={onClose} className={classes.dialogTitle}>
        <img
          className={classes.imageBlock}
          src={type === 'Unenrollment' ? ValidationError : SpreadSheetGirlImage}
          alt="Spreadsheet"
          width={119}
          height={120}
        />
        <span className={type === 'Unenrollment' ? classes.headingTextSecondary : classes.headingText}>{type === 'Unenrollment' ? `Scanning file ${filename}` : 'Oh Snap! Something needs fixing...'}</span>
        {
          type === 'Unenrollment' && (
            <span>
              {zeroAppender(errors.length)}
              {' '}
              errors found. Please make the required changes and re-upload your file.
            </span>
          )
        }
      </DialogTitle>
      <MuiDialogContent style={{ marginBottom: '0' }}>
        <Box style={{ paddingLeft: '14rem' }}>
          <ul>{errors.map(ErrorLine)}</ul>
        </Box>
      </MuiDialogContent>
      <MuiDialogActions className={classes.buttonActions} style={{ justifyContent: 'center' }}>
        <Button variant="contained" color="primary" onClick={onOk}>Re-Upload File</Button>
      </MuiDialogActions>
    </Dialog>
  );
};

XlsxValidationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      row: PropTypes.number,
      column: PropTypes.number,
      message: PropTypes.string,
    }),
  ).isRequired,
  type: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
};

export default XlsxValidationModal;
