/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
  Table, TableContainer, TableBody, TableRow, TableCell,
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { DatePicker } from '@material-ui/pickers';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import EnhancedTableHeader from '../Table/EnhancedTableHeader';
import NoDataComponent from '../common/NoDataComponent';
import NoData from '../../assets/img/noChannel.svg';
import EmptyComponentTitle from '../../assets/img/empty-component-title.svg';
import {
  COMPONENT_TABLE_HEAD_ATTRIBUTES, DATEFORMAT, ROUTE_REPORT,
  WAVE_MANAGEMENT,
} from '../../constants';
import AlertBarWithAction from '../common/AlertBarWithAction';
import { tableSort, getComparator } from '../../helpers/utils';
import {
  BLACK, RED_DARK,
} from '../../stylesheets/colors';
import LoadingCircle from '../common/LoadingCircle/LoadingCircle';

const useStyles = makeStyles(() => ({
  tableTopSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  table: {
    '& .MuiTableCell-root': {
      padding: '0.7rem',
      fontSize: '1.25rem',
    },
  },
  nouserSection: {
    boxShadow: '0 0 1px 0 rgb(5 28 44 / 20%), 0 2px 4px -1px rgb(5 28 44 / 20%)',
    padding: '2rem 0',
    display: 'flex',
    flexDirection: 'column',
  },
  componentTitle: {
    display: 'flex',
    alignItems: 'center',

    '& img': {
      height: '1.75rem',
      width: '3.125rem',
    },

    '& span': {
      marginLeft: '2.25rem',
    },
  },
  datePicker: {
    '& .MuiOutlinedInput-input': {
      padding: '6.5px 14px',
    },
    '& > div': {
      borderRadius: 'unset',
    },
  },
  orderText: {
    fontWeight: 'bold',
    color: BLACK,
    textAlign: 'center',
    maxWidth: '1rem',
  },
  duedatecell: {
    minWidth: '40%',
    maxWidth: '40%',
    width: '40%',
  },
  alertBarLabelStyle: {
    marginBottom: '1rem',
    '& h6.MuiTypography-subtitle1': {
      fontSize: '1rem',
      padding: '0',
      fontWeight: '500',
    },
    '& button': {
      fontSize: '1rem',
      fontWeight: '500',
    },
  },
  alertbarCloseIcon: {
    color: RED_DARK,
    fontSize: '1.2rem',
  },
}));

const ComponentListOnCohort = ({
  isEdit, cohort, componentData, setComponentData, isLoading, programId,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [orderSort, setOrderSort] = useState('');
  const [showAlertBar, setShowAlertBar] = useState(true);

  const handleRequestSort = () => {
    const isAsc = (orderSort && orderSort === 'asc');
    setOrderSort(isAsc ? 'desc' : 'asc');
  };
  const handleDatePicker = (date, row) => {
    const updatedData = componentData.map((item) => {
      if (item.order === row.order) {
        item.due_date = date;
      }
      return item;
    });
    setComponentData(updatedData);
  };

  if (isLoading) return <LoadingCircle />;

  return (
    <>
      {componentData.length
        ? (
          <>
            { !(cohort.start_date && cohort.end_date) && showAlertBar
            && (
            <AlertBarWithAction
              variant="error"
              percentage={null}
              labelText="Please add a launch and completion date for this cohort in the previous, “All Enrollment Cohort Details” page to enable component due dates within that period."
              actionButtonText="Go to All Enrollment Cohorts"
              onActionClick={() => history.push(`/${ROUTE_REPORT}/${programId}/${WAVE_MANAGEMENT}`)}
              customStyle={classes.alertBarLabelStyle}
              actionButtonIcon={(
                <CloseIcon
                  className={classes.alertbarCloseIcon}
                  onClick={() => setShowAlertBar(false)}
                />
              )}
            />
            )}
            <Box className={classes.tableTopSection}>
              <Typography variant="h1">{`${componentData.length} components`}</Typography>
            </Box>
            <TableContainer style={{ marginBottom: '2rem' }}>
              <Table aria-label="Component cohort table" className={classes.table} padding="normal" stickyHeader>
                <EnhancedTableHeader
                  headCells={COMPONENT_TABLE_HEAD_ATTRIBUTES}
                  order={orderSort}
                  orderBy={orderSort ? 'component_title' : 'order'}
                  onRequestSort={handleRequestSort}
                />
                <TableBody className={classes.wrapperTableBody}>
                  {componentData && tableSort(componentData, getComparator(orderSort, orderSort ? 'component_title' : 'order')).map((row) => (
                    <TableRow
                      key={row.componentTitle}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell className={classes.orderText}>
                        <Typography>{row.order}</Typography>
                      </TableCell>
                      <TableCell className={classes.componentCell}>
                        <Box
                          className={classes.componentTitle}
                        >
                          {row.course_graphic ? <img src={row.course_graphic} alt="img" /> : <img src={EmptyComponentTitle} alt="img" />}
                          <span>{row.component_title || '-'}</span>
                        </Box>
                      </TableCell>
                      <TableCell align="left" className={classes.codeCell}>{row.code}</TableCell>
                      <TableCell align="left" className={classes.duedatecell}>
                        {isEdit ? (
                          <DatePicker
                            className={`${classes.datePicker}`}
                            value={row.due_date || null}
                            format={DATEFORMAT}
                            inputVariant="outlined"
                            autoOk
                            variant="inline"
                            onChange={(date) => { handleDatePicker(date, row); }}
                            // eslint-disable-next-line max-len
                            minDate={cohort.start_date ? format(new Date(cohort.start_date), DATEFORMAT) : new Date()}
                            // eslint-disable-next-line max-len
                            maxDate={cohort.end_date ? format(new Date(cohort.end_date), DATEFORMAT) : new Date(2099, 1, 29)}
                            InputProps={{
                              className: classes.datePickerInput,
                              endAdornment: <CalendarTodayIcon size="2rem" />,
                              placeholder: 'Select a due date',
                            }}
                          />
                        ) : (row.due_date ? format(new Date(row.due_date), DATEFORMAT) : '-') }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )
        : (
          <Box className={classes.nouserSection}>
            <NoDataComponent
              imgSrc={NoData}
              primaryText="This learning plan has no components yet."
              secondaryText="Please contact a Course Production member for further assistance."
            />
          </Box>
        ) }
    </>
  );
};
ComponentListOnCohort.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  cohort: PropTypes.object.isRequired,
  componentData: PropTypes.array.isRequired,
  setComponentData: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  programId: PropTypes.number.isRequired,
};
export default ComponentListOnCohort;
