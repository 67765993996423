import React, { useCallback, useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { cloneDeep, isEmpty } from 'lodash';
import _omit from 'lodash/omit';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import _debounce from 'lodash/debounce';
import { useHistory, useLocation } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import _filter from 'lodash/filter';
import LabledTextField from '../../../common/LabledTextField';
import {
  ACCORDION_GREY, MODERATE_DARK_GREY, SWITCH_GREY, WHITE,
} from '../../../../stylesheets/colors';
import NoPages from '../../../../assets/img/NoPages.png';
import {
  createPage, deleteJournal, deletePage, getComponents, getJournalDetails, getPages, updatePage,
} from '../../../../containers/ContentLibrary/api';
import JournalBook from '../../../../assets/icons/LTI_journal_thumbnail.svg';
import {
  NA, REFRESH_SUCCESS, snackBarInitialState, TOOLTIP_TIME, USER_INFO_KEY,
} from '../../../../constants';
import BreadCrumbHOC from '../../../BreadCrumbHOC';
import AddPageModal from './AddPageModal';
import PageOverview from './Pages';
import Loading from '../../../common/Loading';
import CustomSnackbar from '../../../common/CustomSnackbar';
import { dateFormatter, reportPageDateTimeFormatter } from '../../../../helpers/formattingHelpers';
import { getUserNameFromLocalStorage } from '../../../../helpers/userHelper';
import NoDataComponent from '../../NoDataComponent';
import { getErrorMessage } from '../../../../helpers/apiHelper';
import AddJournalModal from './AddJournalModal';
import handleJournalCreteAndEdit, { lockUnlockJournal } from './utils';
import EmptySearchComponent from '../../../common/NoDataComponent';
import NoChannel from '../../../../assets/img/noChannel.svg';
import DeleteJournalModal from './Pages/DeleteJournalModal';
import DeletePageModal from './Pages/PageDetail/DeletePageModal';
import LinkButton from '../../../common/LinkButton';
import AlertBarWithAction from '../../../common/AlertBarWithAction';
import useAlertBar from '../../../../hooks/useAlertBar';
import CustomSwitch from '../../../common/CustomSwitch';
import LockJournalModal from './Pages/LockJournalModal';
import UnlockJournalModal from './Pages/UnlockJournalModal';

const useStyles = makeStyles((theme) => ({
  bodyWrapper2: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2rem',
    flex: 1,
  },
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  components: {
    fontSize: '1.75rem',
    fontWeight: 600,
  },
  componentsBreakdown: {
    fontSize: '0.875rem',
    color: MODERATE_DARK_GREY,
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: '2rem',
  },
  searchBox: {
    marginTop: '1.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& > button': {
      width: '9.06rem',
      marginTop: '1rem',
      fontSize: '1rem',
    },
  },
  input: {
    paddingRight: '0 !important',
  },
  inputBox: {
    width: '21rem',
    '& input': {
      fontSize: '1rem',
      color: MODERATE_DARK_GREY,
    },
  },

  journalDetailWrapper: {
    display: 'flex',
    flexDirection: 'row',
    fontFamily: theme.typography.fontFamily,
    marginTop: '1.25rem',
    overflow: 'hidden',
    padding: '0.1rem',
  },
  dataWrapper: {
    height: '12.2rem',
    padding: '1.25rem',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textInfo: {
    marginLeft: '1rem',
    width: '100%',

    '& > p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-inline-box',
      boxOrient: 'vertical',
    },
    '& > p:nth-child(1)': {
      lineClamp: 1,
    },
    '& > p:nth-child(3)': {
      lineClamp: 2,
    },
  },
  journalAccessSection: {
    width: '10%',
    paddingRight: '1.25rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: '1.25rem',
    height: '100%',
    borderLeft: `1px solid ${SWITCH_GREY}`,
    '& > div': {
      marginTop: '1rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& > span:nth-child(1)': {
        marginRight: '0.5rem',
      },
    },
  },
  journalInfoSection: {
    display: 'flex',
    width: '90%',
    paddingRight: '1.25rem',
  },
  journalInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '70%',
    alignItems: 'center',

    '& > img': {
      height: '5.313rem',
      width: '6.563rem',
      margin: '0.813rem',
    },
  },
  journalActionContainer: {
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'end',
  },
  journalDescription: {
    fontSize: '1rem',
    color: ACCORDION_GREY,
    lineHeight: 1.5,
    marginTop: '0.688rem',

  },
  journalTitle: {
    fontWeight: 600,
    fontSize: '2.25rem',
  },
  lowerWrapper: {

    '& > div': {
      display: 'flex',

      '& > div': {
        display: 'flex',
        justifyContent: 'flex-end',

        '& > p:nth-child(1)': {
          maxWidth: '70%',
        },
      },
    },

  },
  title: {
    fontSize: '0.875rem',
    color: ACCORDION_GREY,
    whiteSpace: 'nowrap',
  },
  value: {
    fontSize: '0.875rem',
    fontWeight: 600,
    marginLeft: '2px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 1,
    boxOrient: 'vertical',
  },
  journalActions: {
    '& > button': {
      height: '2.5rem',
    },
    '& > button:nth-child(2)': {
      marginLeft: '1.25rem',
    },
    '& > Typography': {
      fontSize: '1rem',
      color: WHITE,
    },
  },
  journalId: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.75rem',
    width: '100%',
  },
  pageWrapper: {
    padding: '1.25rem 2rem 2rem 2rem',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  linkedLp: {
    display: 'flex',
    alignItems: 'center',
  },
  alertSnackBar: {
    display: 'flex',
    alignItems: 'center',
  },
  badgeSeparator: {
    margin: '0 1rem',
  },
  badgeWrapper: {
    display: 'flex',
    alignItems: 'center',

    '& > div': {
      marginRight: '0.5rem',
    },
  },
  lpMetadata: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '0.75rem',
  },
}));

const JournalDetail = ({ match }) => {
  const classes = useStyles();
  const journalId = get(match, 'params.id');
  const [isLoading, setIsLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [pages, setPages] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [journalInfo, setJournalInfo] = useState({
    learning_plan_id: null,
    journal_title: '',
    description: '',
    journal_id: '',
  });
  const [showAddJournalModal, setShowAddJournalModal] = useState(false);
  const [showDeleteJournalModal, setShowDeleteJournalModal] = useState(false);
  const [showDeletePageModal, setShowDeletePageModal] = useState(false);
  const [savedJournalValues, setSavedJournalValues] = useState({});
  const [showAddPageModal, setShowAddPageModal] = useState(false);
  const [snackbarObj, setSnackbarObj] = useState(snackBarInitialState);
  const [savedValues, setSavedValues] = useState({});
  const [selectedPage, setSelectedPage] = useState({});
  const [showLockJournalModal, setShowLockJournalModal] = useState(false);
  const [showUnlockJournalModal, setShowUnlockJournalModal] = useState(false);
  const {
    learning_plan_id, journal_title, description, program_name, is_locked,
    learning_plan_code, journal_id, modified_by, modified, num_pages, last_refresh = '', replicated_from,
  } = journalInfo;

  const history = useHistory();
  const location = useLocation();
  const message = location?.state?.successMessage;
  const { ENTER_DELAY, ENTER_NEXT_DELAY } = TOOLTIP_TIME;

  const {
    labelText, variant, open, setAlertBarConfig, initialAlertBarConfig,
  } = useAlertBar();

  const standardPagesLength = _filter(pages, { journal_type: 'standard' }).length;
  const reviewPagesLength = pages.length - standardPagesLength;

  const getDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getJournalDetails(journalId);
      setJournalInfo(response);
    } catch (e) {
      setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  }, [journalId]);

  const fetchPages = useCallback(async (searchVal) => {
    setIsPageLoading(true);
    try {
      const response = await getPages(journalId, searchVal || '');
      const pageList = response?.items;
      setPages(pageList);
    } catch (e) {
      setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
    } finally {
      setIsPageLoading(false);
    }
  }, [journalId]);

  useEffect(() => {
    getDetails();
    fetchPages();
    if (message) {
      setAlertBarConfig({
        labelText: message,
        variant: 'success',
        open: true,
      });
      history.replace({ state: {} });
    }
  }, [fetchPages, getDetails, history, journalId, message, setAlertBarConfig]);

  const breadCrumbList = [
    { label: 'All Journals', redirectionUrl: '/contentLibrary/lti/journals', isActive: false },
    { label: 'Journal Details', redirectionUrl: '', isActive: true },
  ];

  const handleOnClose = () => {
    batchUpdates(() => {
      setShowAddPageModal(false);
      setSavedValues({});
      setSelectedPage({});
    });
  };

  const handleOnOk = async (values, selectedComponent) => {
    try {
      const { pageTitle, pageDesc, journalType } = values;
      const selectedCmpClone = cloneDeep(selectedComponent);
      const filteredComponentObj = _omit(selectedCmpClone, ['value', 'id', 'isDisabled', 'label']);
      const userName = getUserNameFromLocalStorage(USER_INFO_KEY);
      setIsLoading(true);
      const data = {
        course: filteredComponentObj,
        page_title: pageTitle,
        page_description: pageDesc,
        journal_type: journalType,
        journal_id: journalId,
        modified_by: userName,
        training_material_id: selectedPage.training_material_id,
      };
      let successMessage = '';
      if (isEmpty(savedValues)) {
        data.created_by = userName;
        successMessage = 'Page Created Successfully';
        await createPage(data);
      } else {
        successMessage = 'Page Edited Successfully';
        await updatePage(data, selectedPage?.id);
      }
      setIsLoading(false);
      handleOnClose();
      setAlertBarConfig({
        labelText: successMessage,
        variant: 'success',
        open: true,
      });
      fetchPages(searchText);
    } catch (e) {
      setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
    }
  };

  const handlePageEdit = async (selectedPageObj) => {
    const {
      journal_title: pageTitle, description: pageDesc, page_id, course_id, journal_type,
    } = selectedPageObj;
    const filledFormData = {
      selectedComponent: course_id,
      pageTitle,
      pageId: page_id,
      pageDesc,
      journalType: journal_type,
    };
    setSelectedPage(selectedPageObj);
    setSavedValues(filledFormData);
    setShowAddPageModal(true);
  };

  const handlePageDelete = async ({ pageId }) => {
    try {
      await deletePage(pageId);
      setShowDeletePageModal(false);
      fetchPages(searchText);
      setAlertBarConfig({
        labelText: 'Page Deleted Successfully',
        variant: 'success',
        open: true,
      });
    } catch (e) {
      setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
    }
  };

  const onAddPage = () => {
    setShowAddPageModal(true);
  };

  const arePagesEmpty = !pages.length;

  const onJournalModalClose = () => {
    batchUpdates(() => {
      setShowAddJournalModal(false);
      setSavedJournalValues({});
    });
  };

  const handleOnJournalSave = async (values) => {
    handleJournalCreteAndEdit({
      values,
      selectedJournal: journalInfo,
      savedJournalValues,
      onJournalModalClose,
      setSnackbarObj,
      setAlertBarConfig,
      cbFunction: getDetails,
    });
  };

  const onEditJournal = async () => {
    const filledFormData = {
      searchId: learning_plan_code,
      journalTitle: journal_title,
      journalDesc: description,
    };
    setSavedJournalValues(filledFormData);
    setShowAddJournalModal(true);
  };

  const debouncedChange = useCallback(_debounce((searchVal) => { fetchPages(searchVal); }, 500),
    [fetchPages]);

  const handleSearch = (searchVal) => {
    setSearchText(searchVal);
    setIsPageLoading(true);
    debouncedChange(searchVal);
  };

  const onDeleteJournal = async () => {
    try {
      await deleteJournal(journalId);
      history.push({
        pathname: '/contentLibrary/lti/journals',
        state: { successMessage: 'Journal Deleted Successfully' },
      });
    } catch (e) {
      setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
    }
  };

  const onClickDeletePage = (selectedPageObj) => {
    setSelectedPage(selectedPageObj);
    setShowDeletePageModal(true);
  };

  const onCloseDeletePageModal = () => {
    setSelectedPage({});
    setShowDeletePageModal(false);
  };

  const getJournalsJsx = () => {
    if (isLoading || isPageLoading) {
      return <Loading />;
    }
    if (searchText && arePagesEmpty) {
      return (
        <EmptySearchComponent
          imgSrc={NoChannel}
          primaryText="Oops! Could not find the page you were looking for."
          secondaryText="Adjust your search to see more results."
        />
      );
    } if (arePagesEmpty) {
      return <NoDataComponent emptyIcon={NoPages} level="Pages" onAdd={onAddPage} disableButton={is_locked} />;
    }
    return (
      <PageOverview
        pages={pages}
        setSnackbarObj={setSnackbarObj}
        journal_id={journal_id}
        is_locked={is_locked}
        handlePageEdit={handlePageEdit}
        handlePageDelete={onClickDeletePage}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        fetchPages={() => { fetchPages(searchText); }}
        setAlertBarConfig={setAlertBarConfig}
      />
    );
  };

  const onClickDeleteJournal = () => {
    setShowDeleteJournalModal(true);
  };

  const onCloseDeleteJournalModal = () => {
    setShowDeleteJournalModal(false);
  };

  const onRefresh = async () => {
    try {
      setIsLoading(true);
      setIsPageLoading(true);
      await getComponents(journalId, true);
      await getDetails();
      await fetchPages(searchText);
      setSnackbarObj({ open: true, message: REFRESH_SUCCESS, severity: 'success' });
    } catch (e) {
      console.log(e);
      setSnackbarObj({ open: true, message: getErrorMessage(e), severity: 'error' });
    } finally {
      setIsLoading(false);
      setIsPageLoading(false);
    }
  };

  const getBadgeJsx = () => (
    <>
      {replicated_from ? (
        <>
          <Typography className={classes.badgeSeparator}>|</Typography>
          <Box className={classes.badgeWrapper}>
            <>
              <Typography className={classes.title}>Replication from:</Typography>
              <Typography className={classes.value}>{replicated_from}</Typography>
            </>
          </Box>
        </>
      ) : null}
    </>
  );

  const onCloseLockUnlockJournalModal = () => {
    batchUpdates(() => {
      setShowLockJournalModal(false);
      setShowUnlockJournalModal(false);
    });
  };

  const onLockUnlockJournal = async (lockJournal) => {
    lockUnlockJournal({
      selectedJournal: journalInfo,
      onCloseLockUnlockJournalModal,
      setSnackbarObj,
      setIsLoading,
      lockJournal,
      cbFunction: (success, successMessage) => {
        if (success) {
          const updatedJournalInfo = cloneDeep(journalInfo);
          setJournalInfo({ ...updatedJournalInfo, is_locked: !is_locked });
        }
        setAlertBarConfig({
          labelText: successMessage,
          variant: 'success',
          open: true,
        });
        setIsLoading(false);
      },
    });
  };

  const onJournalAccessToggle = () => {
    if (!is_locked) {
      setShowLockJournalModal(true);
    } else {
      setShowUnlockJournalModal(true);
    }
  };

  return (
    <Box className={classes.bodyWrapper2}>
      { showLockJournalModal && (
      <LockJournalModal
        onClose={onCloseLockUnlockJournalModal}
        onConfirm={() => { onLockUnlockJournal(true); }}
        selectedJournal={journalInfo}
      />
      )}

      {showUnlockJournalModal && (
      <UnlockJournalModal
        onClose={onCloseLockUnlockJournalModal}
        onConfirm={() => { onLockUnlockJournal(false); }}
        selectedJournal={journalInfo}
      />
      )}
      {showAddJournalModal && (
      <AddJournalModal
        open={showAddJournalModal}
        onClose={onJournalModalClose}
        onOk={handleOnJournalSave}
        savedJournalValues={savedJournalValues}
        selectedJournal={journalInfo}
      />
      )}
      { showDeleteJournalModal && (
      <DeleteJournalModal
        onClose={onCloseDeleteJournalModal}
        onConfirm={onDeleteJournal}
        selectedJournal={journalInfo}
      />
      )}
      { showDeletePageModal && (
      <DeletePageModal
        onClose={onCloseDeletePageModal}
        onConfirm={handlePageDelete}
        page={selectedPage}
        standardPagesLength={standardPagesLength}
        reviewPagesLength={reviewPagesLength}
      />
      )}
      {
        showAddPageModal && (
        <AddPageModal
          setSnackbarObj={setSnackbarObj}
          open={showAddPageModal}
          onClose={handleOnClose}
          onOk={handleOnOk}
          journalInfo={{
            program_name,
            learning_plan_code,
            learning_plan_id,
            journalId,
            journal_id,
            num_pages,
          }}
          savedValues={savedValues}
          pageTitle={selectedPage?.journal_title}
          standardPagesLength={standardPagesLength}
          onSubmitLoading={isLoading}
        />
        )
      }
      <CustomSnackbar snackbarObj={snackbarObj} setSnackbarObj={setSnackbarObj} />
      {open && (
      <AlertBarWithAction
        variant={variant}
        labelText={labelText}
        actionButtonIcon={<CloseIcon onClick={() => setAlertBarConfig(initialAlertBarConfig)} />}
      />
      )}
      <Paper className={classes.wrapper}>
        <Box className={classes.pageWrapper}>
          <BreadCrumbHOC
            customJsx={(
              <Typography component="p" variant="h3">
                Last updated data from Docebo:
                {' '}
                {!isLoading ? reportPageDateTimeFormatter(new Date(last_refresh)) : 'Loading...'}
                <LinkButton text="Refresh" onClick={() => { onRefresh(); }} color="primary" variant="btnGeneral2" />
              </Typography>
)}
            list={breadCrumbList}
          />
          <Box className={classes.journalDetailWrapper}>
            <Paper className={classes.dataWrapper} square elevation={1}>
              {isLoading ? <Loading /> : (
                <>
                  <Box className={classes.journalInfoSection}>
                    <Box className={classes.journalInfoContainer}>
                      <img src={JournalBook} alt="dots" />
                      <Box className={classes.textInfo}>
                        <Tooltip title={journal_title} arrow placement="top" enterDelay={ENTER_DELAY} enterNextDelay={ENTER_NEXT_DELAY}>
                          <Typography className={classes.journalTitle}>
                            {journal_title}
                          </Typography>
                        </Tooltip>
                        <Box className={classes.journalId}>
                          <Typography className={classes.title}>
                            Journal ID:
                          </Typography>
                          <Typography className={classes.value}>
                            {journal_id}
                          </Typography>
                        </Box>
                        <Typography className={classes.journalDescription}>
                          {description}
                        </Typography>
                        <Box className={classes.lpMetadata}>
                          <Box className={classes.linkedLp}>
                            <Typography className={classes.title}>
                              Linked LP Docebo ID:
                            </Typography>
                            <Typography className={classes.value}>
                              {learning_plan_id}
                            </Typography>
                          </Box>
                          {getBadgeJsx()}
                        </Box>
                      </Box>
                    </Box>
                    <Box className={classes.journalActionContainer}>
                      <Box className={classes.journalActions}>
                        <Button
                          type="button"
                          color="primary"
                          variant="outlined"
                          onClick={onEditJournal}
                          disabled={is_locked}
                        >
                          <Typography variant="h3" component="p">Edit</Typography>
                        </Button>
                        <Button
                          type="button"
                          color="primary"
                          variant="outlined"
                          onClick={onClickDeleteJournal}
                          disabled={is_locked}
                        >
                          <Typography variant="h3" component="p">Delete</Typography>
                        </Button>
                      </Box>

                      <Box className={classes.lowerWrapper}>
                        <Box>
                          <Typography className={classes.title}>
                            Updated By:
                          </Typography>
                          <Box>
                            <Typography className={classes.value}>
                              {modified_by || NA}
                            </Typography>
                            <Typography className={classes.value}>
                              |
                            </Typography>
                            <Typography className={classes.value}>
                              {modified ? dateFormatter(new Date(modified)) : NA}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.journalAccessSection}>
                    <Typography component="span" variant="subtitle2">
                      Journal Access
                    </Typography>
                    <Box>
                      <CustomSwitch
                        checked={is_locked}
                        onChange={onJournalAccessToggle}
                        name="journalAccess"
                      />
                      <Typography component="span" variant="body1">
                        {is_locked ? 'Locked' : 'Unlocked'}
                      </Typography>
                    </Box>
                  </Box>
                </>
              )}
            </Paper>
          </Box>
          <Box className={classes.bodyContainer}>
            <Typography className={classes.components}>
              Pages (
              {pages.length}
              )
            </Typography>
            <Box className={classes.searchBox}>
              <LabledTextField
                label="Search"
                placeholder="Search by Page/Component"
                labelClass={classes.componentsBreakdown}
                customCss={`${classes.inputBox}`}
                inputProps={{
                  inputProps: {
                    name: 'pageSearchText',
                    className: classes.input,
                  },
                  InputProps: {
                    endAdornment: searchText ? (
                      <ClearIcon fontSize="small" style={{ cursor: 'pointer' }} onClick={() => { handleSearch(''); }} />
                    ) : (<SearchIcon style={{ cursor: 'pointer' }} fontSize="small" />),
                  },
                }}
                value={searchText}
                onChange={(e) => { handleSearch(e.target.value); }}
              />
              {pages.length > 0 && (
                <Button type="button" variant="contained" color="primary" onClick={() => { onAddPage(); }} disabled={is_locked}>
                  Add Page
                </Button>
              )}
            </Box>
            {getJournalsJsx()}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

JournalDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      platformType: PropTypes.string,
      programSubType: PropTypes.string,
      feature: PropTypes.string,
      id: PropTypes.number,
    }),
  }).isRequired,
};

export default JournalDetail;
