/* eslint-disable no-unused-vars */
import React, {
  useState, useEffect, useCallback,
} from 'react';
import {
  useField,
} from 'formik';
import { CancelToken, isCancel } from 'axios';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';
import _unset from 'lodash/unset';
import _map from 'lodash/map';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Divider from '@mui/material/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import LockIcon from '../../assets/icons/lock-shape.svg';
import DuplicateParentProjectName from './DuplicateParentProjectName';
import LinkButton from '../common/LinkButton';
import {
  PROJECT_ID_FAILED,
  PROJECT_ID_INVALID,
  PROJECT_ID_NOT_FOUND,
  PROJECT_ID_VALID,
  MASTER_ID_FOUND,
  SCORM_TM_SELECT_OPTIONS,
} from '../../constants';
import { ACCORDION_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles({
  wrapper: {
    minWidth: '25rem',
  },
  selectContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  selectWrapper: {
    flex: 1,
  },
  elucidatInput: {
    marginTop: '34px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    fontWeight: 500,
  },
  statusText: {
    textAlign: 'center',
    padding: '1rem',
  },
  input: {
    color: 'white',
  },
  newProjectField: {
    display: 'flex',
    flex: 1,
    margin: '1.7rem 0 0 0',
  },
  infoHelperText: {
    color: ACCORDION_GREY,
  },
  verticalPadding: {
    padding: '0.5rem 0',
  },
  duplicateProjectAction: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  infoLightText: {
    fontWeight: '300',
  },
  infoDarkText: {
    fontWeight: '900',
  },
});

const cancelTokenSource = {};

const helperTextLookup = {
  [PROJECT_ID_VALID]: null,
  [PROJECT_ID_FAILED]: 'Failed to contact Elucidat servers',
  [PROJECT_ID_INVALID]: 'Invalid Project Id',
  [PROJECT_ID_NOT_FOUND]: 'Project Id not found',
  [MASTER_ID_FOUND]: 'Please enter an ID that is NOT a Master Project on Elucidat.',
};

const ElucidatProjectInput = ({
  scormInputPath, lo, checkElucidatValidity, formik, updateCourseErrorStatus,
  selected, destinationConfigId, feature,
}) => {
  const { lo_id: loId, original_project_id: parentProjectId, project_name: projectName } = lo;

  const elucidatIdSelect = `${scormInputPath}.elucidatSelect`;
  const elucidatIdInput = `${scormInputPath}.elucidatInput`;
  const validatingElucidat = `${scormInputPath}.isValidating`;
  const duplicateProjectName = `${scormInputPath}.duplicateProjectName`;
  const additionalFolders = `${scormInputPath}.additionalFolders`;
  const scormInputStatus = `${scormInputPath}.inputStatus]`;

  const [selectField, selectMeta, selectHelpers] = useField({
    name: elucidatIdSelect,
    type: 'select',
  });

  const [textField, textMeta, textHelpers] = useField({
    name: elucidatIdInput,
    type: 'text',
  });

  const [duplicateProjectField, duplicateProjectMeta, duplicateProjectHelpers] = useField({
    name: duplicateProjectName,
    type: 'text',
  });

  const [additionalFoldersField, additionalFoldersMeta, additionalFoldersHelpers] = useField({
    name: additionalFolders,
    type: 'text',
  });

  const [validatingField, validatingMeta, validatingHelpers] = useField({
    name: validatingElucidat,
    type: 'input',
    value: false,
  });

  const [statusField, statusMeta, statusHelpers] = useField({
    name: scormInputStatus,
    type: 'input',
    value: false,
  });

  const classes = useStyles();

  // const [status, setStatus] = useState('');
  const [duplicateProjectModal, setDuplicateProjectModal] = useState(false);

  const { errors } = formik;

  useEffect(() => {
    updateCourseErrorStatus(errors, validatingMeta.value);
  }, [textMeta.error, updateCourseErrorStatus, errors, validatingMeta.value]);

  const clearProgress = useCallback(() => {
    if (cancelTokenSource[loId]) {
      cancelTokenSource[loId].cancel();
      validatingHelpers.setValue(false);
    }
    textHelpers.setValue('');
    selectHelpers.setValue('');
    validatingHelpers.setValue(false);
    statusHelpers.setValue('');
    _unset(errors, `${scormInputPath}.elucidatInput`);
    _unset(errors, `${scormInputPath}.elucidatSelect`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, scormInputPath, errors, loId]);

  useEffect(() => {
    if (!selected) {
      clearProgress();
    } else {
      if (textMeta.value === '') {
        textHelpers.setError('Required');
      }
      if (selectMeta.value === '') {
        selectHelpers.setError('Required');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const onCheckInner = useCallback(
    async (pid) => {
      // Cancel old request

      if (cancelTokenSource[loId]) {
        cancelTokenSource[loId].cancel();
      }

      if (!pid) {
        textHelpers.setError('Required');
        validatingHelpers.setValue(false);
        return;
      }

      if (!new RegExp('^[a-z0-9]+$', 'i').test(pid)) {
        textHelpers.setError('Invalid Elucidat Project ID');
        validatingHelpers.setValue(false);
        return;
      }

      statusHelpers.setValue('');
      _unset(errors, `${scormInputPath}.elucidatInput`);
      validatingHelpers.setValue(true);

      try {
        cancelTokenSource[loId] = CancelToken.source();
        const response = await checkElucidatValidity(pid, cancelTokenSource[loId].token);

        if (response.data.valid) {
          if (response?.data?.is_master_project) {
            textHelpers.setError(helperTextLookup[MASTER_ID_FOUND]);
          } else {
            statusHelpers.setValue(`Elucidat Project Name: ${response.data.name}`);
          }
        } else {
          textHelpers.setError(helperTextLookup[PROJECT_ID_INVALID]);
        }

        validatingHelpers.setValue(false);
      } catch (e) {
        if (!isCancel(e)) {
          console.error(e);
          textHelpers.setError('Something went wrong, Try again');
          validatingHelpers.setValue(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkElucidatValidity, loId, textHelpers, validatingHelpers, errors],
  );

  const debouncedChange = useCallback(_debounce(onCheckInner, 500), [onCheckInner]);

  const onChange = useCallback(
    (e) => {
      textHelpers.setValue(e.target.value);
      debouncedChange(e.target.value);
    },
    [debouncedChange, textHelpers],
  );

  const onBlur = useCallback(() => {
    // Should run onError & not inProgress
    if (!validatingField.value && textField.value && textMeta.error) {
      onCheckInner(textField.value);
    }
  }, [validatingField.value, textField.value, textMeta.error, onCheckInner]);

  const handleSelectChange = useCallback((value) => {
    statusHelpers.setValue('');
    if (value === 'parent' || value === 'duplicate') {
      textHelpers.setValue(parentProjectId);
      _unset(errors, `${scormInputPath}.elucidatInput`);
      if (value === 'duplicate') {
        duplicateProjectHelpers.setValue('');
        additionalFoldersHelpers.setValue('');
        setDuplicateProjectModal(true);
      }
    } else {
      textHelpers.setValue('');
      textHelpers.setError('Required');
    }

    if (cancelTokenSource[loId]) {
      cancelTokenSource[loId].cancel();
      validatingHelpers.setValue(false);
    }
    selectHelpers.setValue(value);
    _unset(errors, `${scormInputPath}.elucidatSelect`);
  }, [scormInputPath, errors, loId, parentProjectId, selectHelpers, textHelpers,
    validatingHelpers, duplicateProjectHelpers, statusHelpers, additionalFoldersHelpers]);

  const saveDuplicateProjectName = (duplicateProject, selectedFolders) => {
    duplicateProjectHelpers.setValue(duplicateProject);
    additionalFoldersHelpers.setValue(selectedFolders);
    setDuplicateProjectModal(false);
  };

  const closeModal = () => {
    if (duplicateProjectField.value.length === 0) {
      textHelpers.setValue('');
      selectHelpers.setValue('');
      textHelpers.setError('Required');
      selectHelpers.setError('Required');
    }
    setDuplicateProjectModal(false);
  };

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.selectContainer}>
        <FormControl
          className={classes.selectWrapper}
          error={selectMeta.error && selectMeta.error.length > 0}
        >
          <InputLabel htmlFor="elucidat-select-type">Select one</InputLabel>
          <Select
            inputProps={{
              name: selectField.name,
              id: 'elucidat-select-type',
              'data-testid': 'elucidat-select-type',
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              disableScrollLock: true,
              getContentAnchorEl: null,
            }}
            value={selectField.value}
            disabled={!selected}
            onChange={(e) => handleSelectChange(e.target.value)}
          >
            {SCORM_TM_SELECT_OPTIONS?.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                disabled={!parentProjectId && (option.value === 'parent' || option.value === 'duplicate')}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <input
          type="hidden"
          name={validatingField.name}
          value={validatingField.value}
        />
        <TextField
          name={textField.name}
          margin="dense"
          disabled={selectField?.value?.length === 0 || selectField?.value === 'parent' || selectField?.value === 'duplicate'}
          className={classes.newProjectField}
          error={textMeta.error && textMeta.error.length > 0}
          helperText={textMeta.error && textMeta.error}
          value={textField.value}
          onBlur={onBlur}
          onChange={onChange}
          InputProps={{
            endAdornment: selectField?.value === 'parent' || selectField?.value === 'duplicate' ? <img src={LockIcon} alt="lock-icon" /> : validatingMeta?.value && <CircularProgress size="2rem" />,
          }}
        />
      </Box>
      <input
        type="hidden"
        name={statusField.name}
        value={statusField.value}
      />
      {
        selectField.value === 'duplicate' ? (
          <Box>
            <Box className={classes.verticalPadding}>
              <Box className={classes.duplicateProjectAction}>
                <Typography variant="subtitle2" className={`${classes.infoHelperText} ${classes.infoDarkText}`}>
                  New Project Name:
                </Typography>
                <LinkButton text="Edit" onClick={() => setDuplicateProjectModal(true)} />
              </Box>
              <Typography component="p" className={`${classes.infoHelperText} ${classes.infoLightText}`} variant="body1">
                {duplicateProjectField.value}
              </Typography>
            </Box>
            {additionalFoldersField.value.length > 0 && (
              <Box className={classes.verticalPadding}>
                <Box className={classes.duplicateProjectAction}>
                  <Typography variant="subtitle2" className={`${classes.infoHelperText} ${classes.infoDarkText}`}>
                    Additional Folders Assigned:
                  </Typography>
                </Box>
                <Typography component="p" className={`${classes.infoHelperText} ${classes.infoLightText}`} variant="body1">
                  {_map(additionalFoldersField.value, 'label').toString()}
                </Typography>
              </Box>
            )}
          </Box>
        ) : (
          <Typography component="p" className={classes.verticalPadding} variant="body1">
            {statusMeta.value}
          </Typography>
        )
      }
      {
        duplicateProjectModal && (
          <DuplicateParentProjectName
            open={duplicateProjectModal}
            onClose={closeModal}
            projectName={projectName || ''}
            fieldValue={duplicateProjectField.value}
            SelectedFoldersFieldValue={additionalFoldersField.value || []}
            handleSubmit={saveDuplicateProjectName}
            destinationConfigId={destinationConfigId}
            feature={feature}
          />
        )
      }
    </Box>
  );
};

ElucidatProjectInput.propTypes = {
  lo: PropTypes.shape({
    id: PropTypes.string.isRequired,
    lo_id: PropTypes.string.isRequired,
    original_project_id: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.oneOf([null]),
    ]),
    project_id_status: PropTypes.string.isRequired,
    project_name: PropTypes.string.isRequired,
  }).isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({
      courses: PropTypes.array.isRequired,
    }).isRequired,
    errors: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  checkElucidatValidity: PropTypes.func.isRequired,
  scormInputPath: PropTypes.string.isRequired,
  updateCourseErrorStatus: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  destinationConfigId: PropTypes.string.isRequired,
  feature: PropTypes.string.isRequired,
};

export default ElucidatProjectInput;
