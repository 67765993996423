import {
  put, call, takeLatest,
} from 'redux-saga/effects';
import {
  deleteClientStarted,
  deleteClientSuccess,
  deleteClientFailure,
} from '../clientMasterData';
import {
  deleteClientMaster,
} from '../async/masterdataAsynch';

import {
  DELETE_CLIENT,
  DELETE_CLIENT_RESET,
} from '../actionTypes';

export function* deleteClient({ data }) {
  yield put(deleteClientStarted());
  try {
    const response = yield call(deleteClientMaster, data?.id);
    const clientName = data.client_name;
    yield put(deleteClientSuccess({ ...response.data, clientName }));
  } catch (err) {
    yield put(deleteClientFailure(err.response.data));
  }
}

export function* deleteClientMasterSaga() {
  yield takeLatest(DELETE_CLIENT, deleteClient);
  yield put({
    type: DELETE_CLIENT_RESET,
  });
}
