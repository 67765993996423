import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';

import Button from '../ds-react-components/Button';
import CustomDropdown from './CustomDropdown';
import HeaderUserCircle from './HeaderUserCircle';

import { userDropdownOptions } from './header-constants';
import { HEADER_SEPARATOR } from '../../stylesheets/colors';

import './Header.css';

const useStyles = makeStyles((theme) => ({
  userDropdownContainer: {
    display: 'flex',
    height: '100%',
    borderLeft: `solid 1px ${HEADER_SEPARATOR}`,
  },
  userDropdownText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.secondary.main,
    padding: '1px 5px',
    fontSize: '1.25rem',
  },
  button: {
    flex: 1,
    background: 'none',
    border: 'none',
    justifyContent: 'flex-end',
    paddingLeft: '32px',
    '&:hover': {
      backgroundColor: 'unset',
    },
    '& > div': {
      backgroundColor: 'unset',
    },
    '& > div:nth-of-type(1)': {
      backgroundColor: theme.palette.primary.main,
      fontFamily: theme.typography.fontFamily,
      fontSize: '1.125rem',
      width: '32px',
      height: '32px',
    },
  },
  dropdown: {
    flex: 1,
    fontFamily: theme.typography.fontFamily,
    fontSize: '1.25rem',
    '& li': {
      fontFamily: theme.typography.fontFamily,
      fontSize: '1.25rem',
    },
  },
}));

const UserDropdown = ({
  user, onDropdownChange, toggleDropdown,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.userDropdownContainer}>
      <CustomDropdown
        className={classes.dropdown}
        options={userDropdownOptions}
        onChange={onDropdownChange}
        onClick={toggleDropdown}
      >
        <Button className={classes.button}>
          <HeaderUserCircle user={user} />
        </Button>
      </CustomDropdown>
    </div>
  );
};

UserDropdown.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  onDropdownChange: PropTypes.func.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
};

export default UserDropdown;
