/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  FieldArray,
  FormikProvider,
  useField,
} from 'formik';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _flatten from 'lodash/flatten';
import _isEmpty from 'lodash/isEmpty';
import _unset from 'lodash/unset';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@mui/material/Box';

import EnhancedTableHead from '../Table/EnhancedTableHead';
import ExpandableTableRow from './ExpandableTableRow';
import HyperlinkButton from '../common/HyperlinkButton';
import AlertWarning from '../../assets/icons/alert-red-warning.svg';

const useStyles = makeStyles({
  rowError: {
    '&.MuiTableRow-root': {
      borderLeft: '1px solid red',
    },
  },
  alertImage: {
    paddingRight: '0.5rem',
  },
  centerAlign: {
    display: 'flex',
    alignItems: 'center',
  },
});

const CourseRow = ({
  index, course, checkElucidatValidity, expandAll, labelId, formik,
  destinationConfigId, programId,
}) => {
  const [selectedField, selectedMeta, selectedHelpers] = useField({
    name: `courses[${index}.selected]`,
    type: 'checkbox',
  });

  const [isCourseValid, isCourseValidMeta, isCourseValidHelpers] = useField({
    name: `courses[${index}.isCourseValidated`,
    type: 'input',
  });

  const [tmLimitExceed, tmLimitExceedMeta, tmLimitExceedHelpers] = useField({
    name: `courses[${index}.isTmLimitExceed`,
    type: 'text',
  });

  const classes = useStyles();

  const {
    errors, validateForm, values, setValues,
  } = formik;

  useEffect(() => {
    validateForm();
  }, []);

  useEffect(() => {
    selectedHelpers.setValue(values.selectAll);
  }, [values.selectAll]);

  useEffect(() => {
    if (selectedField.checked) {
      isCourseValidHelpers.setValue(!(_get(errors, `courses[${index}].lo`, []).length > 0));
    } else {
      isCourseValidHelpers.setValue(true);
      _unset(errors, `courses[${index}]`);
      const updateCourses = _map(values?.courses,
        (a) => (a.course_id === course.course_id ? {
          ...a,
          isCourseValidated: true,
          lo: _map(a.lo,
            (loItem) => ({ ...loItem, selected: false })),
          selected: false,
        } : a));
      setValues({ ...values, courses: updateCourses });
    }
  }, [selectedField.checked]);

  const updateCourseErrorStatus = useCallback((err, isValidating) => {
    const loList = _get(err, `courses[${index}].lo`, []);
    if (loList.length > 0) {
      const isContainValues = _filter(_flatten(loList), (obj) => !_isEmpty(obj));
      isCourseValidHelpers.setValue(!((isValidating || isContainValues.length > 0)));
    } else if (isValidating) {
      isCourseValidHelpers.setValue(false);
    } else {
      isCourseValidHelpers.setValue(true);
    }
  }, [index]);

  const courseLimitExceeded = useCallback((isLimitExceeded) => {
    tmLimitExceedHelpers.setValue(isLimitExceeded);
  }, []);

  return (
    <ExpandableTableRow
      hover
      role="checkbox"
      aria-checked={course?.selected}
      tabIndex={-1}
      key={course.course_id}
      course={course}
      courseIndex={index}
      checkElucidatValidity={checkElucidatValidity}
      expandAll={expandAll}
      coureseRow={`courses.${index}`}
      formik={formik}
      className={(!isCourseValid.value || tmLimitExceed.value) && classes.rowError}
      updateCourseErrorStatus={updateCourseErrorStatus}
      courseLimitExceeded={courseLimitExceeded}
      destinationConfigId={destinationConfigId}
      programId={programId}
    >
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          id={selectedField.name}
          name={selectedField.name}
          checked={selectedField.checked}
          onClick={(event) => {
            selectedHelpers.setValue(event.target.checked);
          }}
          inputProps={{
            'aria-labelledby': labelId,
          }}
        />
      </TableCell>
      <TableCell style={{ maxWidth: '1rem' }}>
        <input
          type="hidden"
          name={isCourseValid.name}
          value={isCourseValid.value}
        />
        {course?.course_name}
      </TableCell>
      <TableCell />
      <TableCell>
        <Box className={classes.centerAlign}>
          {tmLimitExceed.value && <img src={AlertWarning} alt="alert" className={classes.alertImage} />}
          {`${_filter(course?.lo, { selected: true }).length} / ${course?.lo?.length} included for replication`}
        </Box>
      </TableCell>
      <TableCell />
      <TableCell />
    </ExpandableTableRow>
  );
};

const ReplicationCoursesTable = ({
  report,
  formik,
  checkElucidatValidity,
  handleSelectAllClick,
  destinationConfigId,
  programId,
}) => {
  const [expandAll, setExpandAll] = useState(true);

  const ReplicationHeadTitles = [
    { title: 'Component Name & ID' }, { title: '' }, { title: 'Training Material Name' },
    { title: 'Material Type' }, { title: 'Material ID' },
    {
      title: <HyperlinkButton onClick={() => { setExpandAll(!expandAll); }}>{expandAll ? 'Collapse All' : 'Expand All'}</HyperlinkButton>,
      align: 'right',
    }];

  return (
    <Table aria-label="simple table" padding="normal">
      <FormikProvider value={formik}>
        <EnhancedTableHead
          headCells={ReplicationHeadTitles}
        />
        <FieldArray name="courses">
          {() => (
            <TableBody>
              {formik.values.courses.map((row, idx) => {
                const labelId = `enhanced - table - checkbox - ${idx}`;
                return (
                  <CourseRow
                    index={idx}
                    formik={formik}
                    key={row.course_id}
                    course={row}
                    checkElucidatValidity={checkElucidatValidity}
                    expandAll={expandAll}
                    labelId={labelId}
                    destinationConfigId={destinationConfigId}
                    programId={programId}
                  />
                );
              })}
            </TableBody>
          )}
        </FieldArray>
      </FormikProvider>
    </Table>
  );
};
export default ReplicationCoursesTable;

ReplicationCoursesTable.propTypes = {
  report: PropTypes.object.isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({
      courses: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
  checkElucidatValidity: PropTypes.func.isRequired,
  handleSelectAllClick: PropTypes.func.isRequired,
  destinationConfigId: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired,
};

CourseRow.propTypes = {
  course: PropTypes.shape({
    course_id: PropTypes.number.isRequired,
    course_name: PropTypes.string.isRequired,
    course_code: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    lo: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        original_project_id: PropTypes.oneOfType([
          PropTypes.string.isRequired,
          PropTypes.oneOf([null]),
        ]),
        project_id_status: PropTypes.string.isRequired,
        project_name: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  formik: PropTypes.shape({
    values: PropTypes.shape({
      selectAll: PropTypes.bool.isRequired,
      courses: PropTypes.array.isRequired,
    }).isRequired,
    errors: PropTypes.object.isRequired,
    validateForm: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
  }).isRequired,
  index: PropTypes.string.isRequired,
  labelId: PropTypes.string.isRequired,
  checkElucidatValidity: PropTypes.func.isRequired,
  expandAll: PropTypes.bool.isRequired,
  destinationConfigId: PropTypes.string.isRequired,
  programId: PropTypes.string.isRequired,
};
