import { put, call, takeLatest } from 'redux-saga/effects';
import {
  getUsersSuccess, getUsersFailure, setLoading, markForDeleteSuccess, markForDeleteFailure,
  deleteUserFailure, deleteUserSucess,
} from '../PlatformLevelUsers/users';
import fetchUsers from '../async/platformUserAsync';
import { markForDeleteAPI, deleteUserAPI } from '../async/platFormusersApi';
import { USERS_FETCH, MARK_FOR_DELETION, DELETE_MARKED_USER } from '../../constants';

export function* getUsersList({ data }) {
  try {
    yield put(setLoading(true));
    const response = yield call(fetchUsers, data);
    yield put(getUsersSuccess(response));
  } catch (err) {
    yield put(getUsersFailure(err.response.data));
  }
}

export function* markForDeleteAction({ data }) {
  try {
    const response = yield call(markForDeleteAPI, data);
    yield put(markForDeleteSuccess(response));
  } catch (err) {
    yield put(markForDeleteFailure(err.response.data));
  }
}

export function* deleteMarkedUser({ data }) {
  try {
    const response = yield call(deleteUserAPI, data);
    yield put(deleteUserSucess(response));
  } catch (err) {
    yield put(deleteUserFailure(err.response.data));
  }
}

export function* platformUsersSaga() {
  yield takeLatest(USERS_FETCH, getUsersList);
  yield takeLatest(MARK_FOR_DELETION, markForDeleteAction);
  yield takeLatest(DELETE_MARKED_USER, deleteMarkedUser);
}
