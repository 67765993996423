import React, { useState, useEffect, useCallback } from 'react';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';

import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import AssignmentManagementHeader from '../../components/AssignmentManagement/AssignmentManagementHeader';
import AssignmentManagementContent from '../../components/AssignmentManagement/AssignmentManagementContent';
import {
  downloadAssignments,
  downloadMetadata,
  getAssignmentList,
  getDownloadLink,
  getAssignmentActivityLogs,
  downloadActivityLogs,
} from './api';
import { blobToJson } from '../../helpers/apiHelper';
import { getProgress } from '../common/apis';
import DownloadSuccessBanner from '../../components/AssignmentManagement/DownloadSuccessBanner';
import AssignmentDownloadModal from '../../components/AssignmentManagement/AssignmentDownloadModal';
import { ASSIGNMENT_DOWNLOAD, FEATURE_CLICKED } from '../../constants';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  alertSnackBar: {
    display: 'flex',
    alignItems: 'center',
  },
});

const AssignmentManagementContainer = (props) => {
  const { programMetadata, match } = props;
  const { config_id: configId } = programMetadata;
  const programId = _get(match, 'params.programId');
  const programSubType = _get(match, 'params.programSubType');

  const [loading, setLoading] = useState(true);
  const [assignmentList, setAssignmentList] = useState([]);
  const [downloadList, setDownloadList] = useState([]);
  const [activityReport, setActivityReport] = useState([]);
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [successBanner, setSuccessBanner] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [startBundling, setStartBundling] = useState(false);
  const [disable, setDisable] = useState(true);
  const [isSnackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarSeverity, setSnackBarSeverity] = useState('error');
  const [snackBarMsg, setSnackBarMsg] = useState('Please try later.');

  const classes = useStyles();

  const getAssignmentData = useCallback(async (update = false) => {
    try {
      setLoading(true);
      const resp = await getAssignmentList(programId, update);
      const assignmentListObj = _filter(_get(resp, 'data.data.courses_data', []), (item) => item.assignment_data.length).map((item) => ({
        'Course Name': item.name,
        Course_id: item.docebo_course_id,
        Course_code: item.docebo_course_code,
        Assignments: item.assignment_data.map((val) => ({
          id: val.id, assignment_name: val.name, attempts: val.attempts,
        })),
        Submissions: item.assignment_data.reduce((acc, j) => acc + +j.attempts, 0),
      }));

      batchUpdates(() => {
        setAssignmentList(assignmentListObj);
        setLoading(false);
      });
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  }, [programId]);

  const getActivityLogs = useCallback(async () => {
    const { data: logs } = await getAssignmentActivityLogs(programId);
    if (logs?.data) {
      setActivityReport(logs.data);
    }
  }, [programId]);

  useEffect(() => {
    let finishCounter = 0;
    let timer = null;
    let sleepTime = 1000;
    const pollProgressApi = async () => {
      if (!transactionId) return;
      try {
        const res = await getProgress(transactionId);
        if (res?.data.percentage === 100) {
          finishCounter += 1;

          if (finishCounter >= 3) {
            setStartBundling(true);
          }
        }
        setPercentage((per) => {
          sleepTime = per === res?.data.percentage ? sleepTime + 250 : 1000;
          return res?.data.percentage || 0;
        });

        if (res.data.done) {
          // get download link
          const downloadLink = await getDownloadLink(transactionId);
          window.open(downloadLink.data.url, '_self');

          // update activity logs
          await getActivityLogs();

          batchUpdates(() => {
            setDownloadModalOpen(false);
            setStartBundling(false);
            setSuccessBanner(true);
          });
        } else {
          timer = setTimeout(pollProgressApi, sleepTime);
        }
      } catch (err) {
        console.log(err);
      }
    };
    pollProgressApi();

    return () => {
      clearTimeout(timer);
    };
  }, [transactionId, configId, getActivityLogs]);

  useEffect(() => {
    getAssignmentData();
    getActivityLogs();
  }, [getAssignmentData, getActivityLogs]);

  const onDownload = async (delta = false) => {
    const dList = [];
    const requestData = [];
    assignmentList.forEach((item) => {
      item.Assignments.forEach((assignment) => {
        if (assignment.selected) {
          dList.push(`${item['Course Name']}-${assignment.assignment_name}`);
          const idx = requestData.findIndex((i) => item.Course_id === i.course_id);
          if (idx > -1) {
            requestData[idx].assignments.push(+assignment.id);
          } else {
            requestData.push({ course_id: item.Course_id, assignments: [+assignment.id] });
          }
        }
      });
    });

    batchUpdates(() => {
      setDownloadList(dList);
      setPercentage(0);
      setDownloadModalOpen(true);
    });

    try {
      // adding heap tracking script to track actual feature click
      window.heap.track(FEATURE_CLICKED, programSubType);

      const tid = await downloadAssignments(programId, requestData, delta);
      setTransactionId(tid.data.transaction_id);
    } catch (e) {
      console.log(e.response?.data);
      batchUpdates(() => {
        setSnackBarSeverity('error');
        setSnackBarMsg(e.response?.data.message);
        setDownloadModalOpen(false);
        setSnackBarOpen(true);
        setDisable(false);
      });
    }
  };

  const onDownloadMetadata = async () => {
    const dList = [];
    const requestData = [];
    assignmentList.forEach((item) => {
      item.Assignments.forEach((assignment) => {
        if (assignment.selected) {
          dList.push(`${item['Course Name']}-${assignment.assignment_name}`);
          const idx = requestData.findIndex((i) => item.Course_id === i.course_id);
          if (idx > -1) {
            requestData[idx].assignments.push(+assignment.id);
          } else {
            requestData.push({ course_id: item.Course_id, assignments: [+assignment.id] });
          }
        }
      });
    });

    batchUpdates(() => {
      setSnackBarSeverity('info');
      setSnackBarMsg('Generating the assignments metadata file, this may take few seconds');
      setSnackBarOpen(true);
    });

    try {
      // adding heap tracking script to track actual feature click
      window.heap.track(FEATURE_CLICKED, programSubType);
      await downloadMetadata(programId, requestData, configId);
      setSnackBarOpen(false);
    } catch (e) {
      const response = await blobToJson(e.response?.data);

      batchUpdates(() => {
        setSnackBarSeverity('error');
        setSnackBarMsg(response.message);
        setDisable(false);
      });
    }
  };

  const onActivityLogsDownload = useCallback(async (row) => {
    await downloadActivityLogs(row.transaction_id, configId, ASSIGNMENT_DOWNLOAD, row.created_at);
  }, [configId]);

  const onAssignmentModalClose = () => {
    setDownloadModalOpen(!downloadModalOpen);
    setPercentage(0);
  };

  const onRefresh = () => {
    getAssignmentData(true);
  };

  return (
    <>
      <Paper className={classes.wrapper}>
        {successBanner && (
        <DownloadSuccessBanner
          fileName={configId}
          onClose={() => { setSuccessBanner(false); }}
        />
        )}
        <AssignmentManagementHeader shouldShow={false} />
        <AssignmentManagementContent
          data={assignmentList}
          setAssignmentList={setAssignmentList}
          loading={loading}
          onDownload={onDownload}
          onDownloadMetadata={onDownloadMetadata}
          configId={configId}
          onRefresh={onRefresh}
          disable={disable}
          setDisable={setDisable}
          setSuccessBanner={setSuccessBanner}
          activityReport={activityReport}
          onActivityLogsDownload={onActivityLogsDownload}
        />
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isSnackBarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackBarOpen(false)}
      >
        <Alert
          className={classes.alertSnackBar}
          onClose={() => setSnackBarOpen(false)}
          severity={snackBarSeverity}
        >
          {snackBarMsg}
        </Alert>
      </Snackbar>
      <AssignmentDownloadModal
        open={downloadModalOpen}
        startBundling={startBundling}
        onClose={onAssignmentModalClose}
        downloadList={downloadList}
        percentage={percentage}
        fileName={configId}
      />
    </>
  );
};

AssignmentManagementContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string,
    }),
  }).isRequired,
  programMetadata: PropTypes.shape({
    config_id: PropTypes.string.isRequired,
    program_id: PropTypes.number.isRequired,
    docebo_lp_id: PropTypes.number.isRequired,
  }).isRequired,
};

export default AssignmentManagementContainer;
