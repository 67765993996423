import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { useFormik } from 'formik';
import _every from 'lodash/every';
import _filter from 'lodash/filter';
import _flatten from 'lodash/flatten';
import _map from 'lodash/map';
import _get from 'lodash/get';
import Header from '../../common/Header';
import CountCard from '../../common/CountCard';
import BulkScormReleaseTable from './BulkScormReleaseTable';
import LoadingCircle from '../../common/LoadingCircle/LoadingCircle';
import ReleaseConfirmationModal from '../ReleaseConfirmationModal';
import ErrorModal from '../../ErrorModal/ErrorModal';
import { getProgress, getReport, downloadFile } from '../../../containers/common/apis';
import {
  AUTO_HIDE_DURATION, COURSE_PROD, MULTIPLE_SCORM_REFRESH, INDICATOR_AUTO_HIDE_DURATION,
  ROUTE_ERROR,
} from '../../../constants';
import { MEDIUM_GREY } from '../../../stylesheets/colors';
import NoData from '../../../assets/img/no-data.svg';
import NoDataComponent from '../../common/NoDataComponent';
import BulkScormReleaseStatuses from './BulkScormReleaseStatuses';

import useProgressResult from '../../../hooks/useProgressResult';
import useNotifications from '../../../hooks/useNotifications';
import AlertReleaseInfo from '../AlertReleaseInfo';
import {
  anyScormValidInComponent, isAllScormValidInComponent, checkReleaseData, objectHasData,
} from '../../../helpers/utils';
import { getErrorMessage } from '../../../helpers/apiHelper';

const useStyles = makeStyles({
  wrapperContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 2rem',
    minHeight: '80vh',
  },
  innerWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: '2rem 0',
  },
  header: {
    padding: '0 0 2rem 0',
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  loadingWrapper: {
    padding: '15% 0',
  },
  progressRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.17,
    justifyContent: 'center',
    maxHeight: '10rem',
    transform: 'scale(0.4) translate(0, -100%)',
    padding: '2rem 0',
  },
  subHeadingSection: {
    '& p': {
      color: MEDIUM_GREY,
    },
  },
  containerDisabled: {
    pointerEvents: 'none',
    opacity: 0.6,
  },
});

const BulkScormRelease = ({
  navigationBreadcrumb, fetchBulkScormTMs, programId, updateScorm,
  postBulkScormTMs, configId, bulkScormTms, programMetadata,
  isScormTmsLoading, isDDAdmin,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [tableData, setTableData] = useState({});
  const [totalScormCount, setTotalScormCount] = useState('');
  const [selectedScormCount, setSelectedScormCount] = useState('');
  const [scormsWithMissingDataCount, setScormsWithMissingDataCount] = useState('');
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [releaseTransaction, setReleaseTransaction] = useState('');
  const [loading, setLoading] = useState(false);
  const [showElement, setShowElement] = useState(false);
  const [disabledActions, setDisabledActions] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [modalState, setModalState] = useState('');
  const [pollProgressValue, setPollProgressValue] = useState(false);
  const [progressReportData, setProgressReportData] = useState([]);
  const { notifyError } = useNotifications();

  useEffect(() => {
    if (isDDAdmin) {
      history.replace(`/${ROUTE_ERROR}`);
    }
  }, [history, isDDAdmin]);

  const updatePollProgress = useCallback((value) => {
    setPollProgressValue(value);
  }, []);

  const {
    progressResult, progressError, clearProgressResult, clearProgressError,
  } = useProgressResult(
    pollProgressValue, releaseTransaction, updatePollProgress,
  );

  const { data: progress } = objectHasData(progressResult) ? progressResult : { data: {} };

  const BulkScormTransactionIds = _get(
    programMetadata,
    `activities_status.${COURSE_PROD}.${MULTIPLE_SCORM_REFRESH}.transaction_ids`,
    [],
  );

  const onDownloadLog = async (transaction, q) => {
    try {
      const tid = transaction.transaction_id;
      const createdAt = transaction.created_at;
      await downloadFile(tid, configId, MULTIPLE_SCORM_REFRESH, createdAt, q);
    } catch (e) {
      // TODO: Show error toast
      console.error(e);
    }
  };

  const updateTableData = useCallback(
    (data, checked) => {
      const updatedReport = data.length > 0 ? data?.map((course) => ({
        ...course,
        selected: anyScormValidInComponent(course?.training_materials) ? checked : false,
        courseValidRelease: anyScormValidInComponent(course?.training_materials),
        eachChildScormValidRelease: isAllScormValidInComponent(course?.training_materials),
        training_materials: course.training_materials.map((so) => ({
          ...so,
          courseId: course?.id,
          tmValidRelease: checkReleaseData(so),
          selected: checkReleaseData(so) ? checked : false,
        })),
      })) : [];
      if (updatedReport.length > 0) {
        setTableData({
          selectAll: checked,
          isAllCourseSelected: _every(updatedReport, ['selected', true]),
          isNoneCourseSelected: _every(updatedReport, ['selected', false]),
          courses: updatedReport,
        });
      }
    },
    [],
  );

  useEffect(() => {
    if (progressReportData.length > 0) {
      setTimeout(() => {
        setProgressReportData([]);
      }, INDICATOR_AUTO_HIDE_DURATION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressReportData]);

  const updateReportData = useCallback(async () => {
    try {
      const progressReport = await getReport(releaseTransaction);
      if (progressReport?.data?.length > 0) {
        setProgressReportData(_map(progressReport?.data,
          (ScormObject) => (ScormObject.status === 'COMPLETED' && ScormObject?.old_lo_id?.toString())));
      }
    } catch (err) {
      console.log(err);
    }
  }, [releaseTransaction]);

  useEffect(() => {
    if (progressError !== null) {
      updatePollProgress(false);
      setDisabledActions(false);
      notifyError(progressError);
      clearProgressResult();
      clearProgressError();
    }
    if (progress?.done) {
      batchUpdates(() => {
        updatePollProgress(false);
        setDisabledActions(false);
        fetchBulkScormTMs();
        updateReportData();
        setShowElement(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress, progressError, updateReportData]);

  useEffect(() => {
    if (releaseTransaction) {
      clearProgressResult();
      updatePollProgress(true);
      setDisabledActions(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseTransaction]);

  useEffect(() => {
    if (showElement) {
      setTimeout(() => {
        setShowElement(false);
      }, AUTO_HIDE_DURATION);
    }
  }, [showElement]);

  const statusOfLastTransaction = useCallback(async (latestTransactionId) => {
    const res = await getProgress(latestTransactionId);
    const { done } = res.data;
    if (!done) {
      setReleaseTransaction(latestTransactionId);
    }
  }, []);

  useEffect(() => {
    if (BulkScormTransactionIds.length) {
      statusOfLastTransaction(BulkScormTransactionIds[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BulkScormTransactionIds.length]);

  useEffect(() => {
    updateTableData(bulkScormTms, true);
  }, [updateTableData, bulkScormTms, statusOfLastTransaction]);

  const onViewStatus = useCallback((status) => {
    batchUpdates(() => {
      setInfoModal(true);
      setModalState(status);
    });
  }, []);

  const onInfoModalClose = () => {
    setInfoModal(false);
    setModalState('');
  };

  const initiateBulkScormRelease = async (data) => {
    try {
      setLoading(true);
      const resp = await postBulkScormTMs(data);
      if (resp.data.success === true) {
        setReleaseTransaction(resp?.data?.transaction_id);
      } else {
        notifyError(resp?.data?.message);
        fetchBulkScormTMs();
      }
    } catch (e) {
      notifyError(getErrorMessage(e));
      fetchBulkScormTMs();
    } finally {
      setLoading(false);
      setConfirmationModal(false);
    }
  };

  const formik = useFormik({
    initialValues: tableData,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      const selectedTMs = _filter(_flatten(_map(values?.courses, 'training_materials')), { selected: true });
      const scormPayload = _map(selectedTMs, (scormObj) => ({
        project_id: scormObj?.elucidat_data?.elucidat_project_id,
        course_id: scormObj?.courseId,
        release_id: scormObj?.elucidat_data?.elucidat_release_id,
        tm_id: scormObj?.elucidat_data?.tm_id,
        lo_title: scormObj?.name,
        scorm_type: scormObj?.scorm_type,
      }));
      initiateBulkScormRelease({
        program_id: programId,
        target_lp_code: configId,
        tms: scormPayload,
      });
    },
  });

  const { values, handleSubmit } = formik;

  useEffect(() => {
    if (!values?.courses?.length) {
      fetchBulkScormTMs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const totalScorm = _flatten(_map(values?.courses, 'training_materials'));
    const selectedScorm = _filter(_flatten(_map(values?.courses, 'training_materials')), { selected: true });
    const scormsWithMissingData = _filter(_flatten(_map(values?.courses, 'training_materials')), (tm) => (!tm.scorm_type || !tm?.elucidat_data?.elucidat_release_id));
    batchUpdates(() => {
      setTotalScormCount(totalScorm && totalScorm.length);
      setSelectedScormCount(selectedScorm && selectedScorm.length);
      setScormsWithMissingDataCount(totalScorm && scormsWithMissingData.length);
    });
  }, [values]);

  return (
    <Paper className={classes.wrapperContainer}>
      {releaseTransaction && Object.keys(progress).length > 0 && !infoModal ? (
        <AlertReleaseInfo
          progress={progress}
          showElement={showElement}
          progressMessage={`Full re-release for ${progress?.total_scorms} selected SCORMs is in progress`}
          withErrorsMessage={`Re-release of ${progress?.total_scorms} selected SCORMs was completed with errors.`}
          failedMessage={`Failed to re-release ${progress?.total_scorms} selected SCORMs, please try again.`}
          successMessage={`${progress?.total_scorms} selected SCORMs were successfully re - released`}
          onViewStatus={onViewStatus}
          setShowElement={setShowElement}
        />
      ) : null}
      <Box className={classes.wrapper}>
        <Box>
          {navigationBreadcrumb('Bulk SCORM Full Re-release')}
          <Header
            className={classes.header}
            heading="Re-release of multiple SCORMs"
            subHeading="Select the SCORMs within this Learning Plan to perform a full re-release."
          />
          {
            !isScormTmsLoading ? (
              <>
                {(Object.keys(formik?.values).length > 0 && formik?.values?.courses?.length > 0) ? (
                  <>
                    <Box className={classes.innerWrapper}>
                      <Box className={classes.cardsContainer}>
                        <CountCard text="Total SCORMs" value={totalScormCount} />
                        <CountCard text="SCORMs with missing data" value={scormsWithMissingDataCount} />
                        <CountCard text="Selected SCORMs" value={selectedScormCount} />
                      </Box>
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={isDDAdmin || disabledActions || selectedScormCount === 0}
                        style={{ maxHeight: '3.25rem' }}
                        fontSize="medium"
                        onClick={() => setConfirmationModal(true)}
                      >
                        Create Re-release for (
                        {selectedScormCount}
                        ) selected SCORMs
                      </Button>
                    </Box>
                    <Box className={disabledActions ? `${classes.containerDisabled} ${classes.cardsContainer}` : classes.cardsContainer}>
                      <BulkScormReleaseTable
                        formik={formik}
                        programId={programId}
                        updateScorm={updateScorm}
                        progress={progress}
                        disabledActions={disabledActions}
                        isDDAdmin={isDDAdmin}
                        progressReport={progressReportData}
                      />
                    </Box>
                  </>
                ) : (
                  <NoDataComponent
                    imgSrc={NoData}
                    primaryText="No SCORM added yet!"
                    secondaryText=""
                  />
                )}
              </>
            ) : (
              <Box className={classes.loadingWrapper}>
                <LoadingCircle />
              </Box>
            )
          }
        </Box>
        {
          confirmationModal && (
            <ReleaseConfirmationModal
              open={confirmationModal}
              onClose={() => setConfirmationModal(false)}
              loading={loading}
              subTitle={`This action will create a full re-release for ${selectedScormCount} selected SCORMs`}
              mainContent="All the components will update automatically with the new full re-release.
              Actions on training materials will be disabled until the re-release is complete."
              initiateRelease={handleSubmit}
            />
          )
        }
        <ErrorModal open={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)} />
      </Box>
      {
        releaseTransaction && infoModal && (
          <BulkScormReleaseStatuses
            transactionId={releaseTransaction}
            modalState={modalState}
            open={infoModal}
            onClose={onInfoModalClose}
            onDownloadLog={onDownloadLog}
            getReport={getReport}
            configId={configId}
            progressData={progress}
            progressError={progressError}
          />
        )
      }
    </Paper>
  );
};

BulkScormRelease.propTypes = {
  navigationBreadcrumb: PropTypes.func.isRequired,
  fetchBulkScormTMs: PropTypes.func.isRequired,
  bulkScormTms: PropTypes.object.isRequired,
  programId: PropTypes.string.isRequired,
  updateScorm: PropTypes.func.isRequired,
  postBulkScormTMs: PropTypes.func.isRequired,
  configId: PropTypes.string.isRequired,
  programMetadata: PropTypes.shape({
    activities_status: PropTypes.shape({
      course_prod: PropTypes.shape({
        rerelease_multiple_scorm: PropTypes.shape({
          transaction_ids: PropTypes.array.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  isScormTmsLoading: PropTypes.bool.isRequired,
  isDDAdmin: PropTypes.bool.isRequired,
};

export default BulkScormRelease;
