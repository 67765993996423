import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import StyledSwitch from '../UserEnrollment/common/StyledSwitch';
import { DARK_MEDIUM_GREY } from '../../stylesheets/colors';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    color: DARK_MEDIUM_GREY,
  },
  switchwrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const CustomStyledSwitch = ({
  label,
  option,
  isChecked,
  onChange,
  wrapperClass,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.wrapper} ${wrapperClass}`}>
      <Typography variant="body1" className={classes.label}>
        {label}
      </Typography>
      <div className={classes.switchwrapper}>
        <Typography variant="body1">
          {option}
        </Typography>
        <StyledSwitch checked={isChecked} onChange={onChange} disabled={disabled} />
      </div>
    </div>
  );
};

CustomStyledSwitch.defaultProps = {
  isChecked: false,
  wrapperClass: '',
  disabled: false,
};

CustomStyledSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  option: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  wrapperClass: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CustomStyledSwitch;
