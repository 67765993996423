import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import LabledTextField from '../../common/LabledTextField';
import CustomSelect from '../../common/CustomSelect';
import ErrorText from '../../common/ErrorText';

const ElucidatFields = ({
  values, handleChange, labelTextClass, validateProjectId, setFieldValue, errors,
  setFieldTouched, labelClass, selectBoxClass, isValidating, overlaySection,
}) => {
  const {
    project_id, project_name, project_link, project_releases, release_id,
  } = values;
  return (
    <>
      <LabledTextField
        className={labelTextClass}
        name="project_id"
        label="Project ID"
        placeholder="Enter Project ID"
        required
        value={project_id}
        inputProps={{
          InputProps: {
            endAdornment: isValidating && <CircularProgress size="2rem" />,
          },
        }}
        onChange={(e) => {
          handleChange(e);
          validateProjectId(e.currentTarget.value);
        }}
      />
      <ErrorText
        touchedField
        errorField={errors.project_id}
      />
      <Box className={isValidating && overlaySection}>
        <LabledTextField
          className={labelTextClass}
          name="project_name"
          label="Project Name"
          placeholder="Enter Project Name"
          value={project_name}
          inputProps={{
            className: 'Mui-disabled',
          }}
        />
        <LabledTextField
          className={labelTextClass}
          name="project_link"
          label="Project Link"
          placeholder="Enter Project Link"
          value={project_link}
          inputProps={{
            className: 'Mui-disabled',
          }}
        />
        <CustomSelect
          labelClass={labelClass}
          className={selectBoxClass}
          isOptionsNotLoading
          required
          options={project_releases}
          placeholder="Select Release ID"
          label="Select Release ID"
          defaultValue={project_releases?.find((el) => el?.value === release_id)}
          onChange={async (elem) => {
            await setFieldValue('release_id', elem.value, false);
            setFieldValue('release_version', elem.releaseVersion, true);
            setFieldValue('last_released', elem.lastReleased, true);
            setFieldTouched('release_id', true);
          }}
        />
        <ErrorText
          touchedField
          errorField={errors.release_id}
        />
      </Box>
    </>
  );
};

ElucidatFields.propTypes = {
  values: PropTypes.shape({
    project_id: PropTypes.string.isRequired,
    project_name: PropTypes.string.isRequired,
    project_link: PropTypes.string.isRequired,
    project_releases: PropTypes.array.isRequired,
    release_id: PropTypes.string.isRequired,
  }).isRequired,
  labelClass: PropTypes.string.isRequired,
  selectBoxClass: PropTypes.string.isRequired,
  overlaySection: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  labelTextClass: PropTypes.string.isRequired,
  validateProjectId: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  touched: PropTypes.shape({
    release_id: PropTypes.string,
    project_id: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    release_id: PropTypes.string,
    project_id: PropTypes.string,
  }).isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  isValidating: PropTypes.bool.isRequired,
};

export default ElucidatFields;
