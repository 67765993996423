import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import { useHistory } from 'react-router-dom';
import InfoTooltip from '../../../components/common/InfoTooltip';
import CopyUrl from '../../../assets/icons/icon-copy-url.svg';
import LinkRedirectionIcon from '../../../assets/icons/link-redirection-icon.svg';
import { getMaterialType, getScorm } from '../../../helpers/utils';
import ScormTypeChip from '../../../components/common/ScormTypeChip';

const SingleTableRow = ({
  rowData, doceboUrl, courseId, history,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const getActionIcon = () => (
    <>
      <InfoTooltip title={isCopied ? 'URL Copied' : 'Copy Playable URL'} placement="top">
        <img src={CopyUrl} alt="copy-url" />
      </InfoTooltip>

    </>
  );

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      // eslint-disable-next-line no-return-await
      return await navigator.clipboard.writeText(text);
    }
    return document.execCommand('copy', true, text);
  }

  const handleCopyClick = (copyText) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const { label, icon } = getMaterialType(rowData);

  return (
    <TableRow>
      <TableCell>
        <Typography>{rowData.order}</Typography>
      </TableCell>
      <TableCell>
        <Typography>{rowData.name}</Typography>
      </TableCell>
      <TableCell>
        <ScormTypeChip label={label} icon={icon} />
      </TableCell>
      <TableCell>
        {rowData?.type?.includes('scorm') ? (
          <IconButton
            onClick={() => handleCopyClick(
              getScorm(doceboUrl, courseId, rowData.id, rowData.slug_name),
            )}
          >
            {(rowData?.type?.includes('scorm') ? getActionIcon() : '')}
          </IconButton>
        ) : null}
        {rowData?.type === 'lti' && rowData?.sub_type === 'journal' ? (
          <IconButton
            onClick={() => history.push(`/contentLibrary/lti/journals/${rowData?.journal_id}`)}
          >
            <InfoTooltip title={'Manage journal'}>
              <img src={LinkRedirectionIcon} alt="redirect-to-journal" />
            </InfoTooltip>

          </IconButton>
        ) : null}
      </TableCell>
    </TableRow>
  );
};

const TrainingMaterialTableBody = ({
  data, courseId, doceboUrl,
}) => {
  const history = useHistory();

  return (
    <>
      {data.map((row) => (
        <SingleTableRow
          key={row.id}
          rowData={row}
          courseId={courseId}
          doceboUrl={doceboUrl}
          history={history}
        />
      ))}
    </>
  );
};

TrainingMaterialTableBody.propTypes = {
  data: PropTypes.array.isRequired,
  courseId: PropTypes.string.isRequired,
  doceboUrl: PropTypes.string.isRequired,
};

SingleTableRow.propTypes = {
  rowData: PropTypes.shape({
    order: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug_name: PropTypes.string.isRequired,
    sub_type: PropTypes.string.isRequired,
    journal_id: PropTypes.number.isRequired,
  }).isRequired,
  courseId: PropTypes.string.isRequired,
  doceboUrl: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,

};

export default TrainingMaterialTableBody;
