import {
  put, call, takeLatest,
} from 'redux-saga/effects';
import {
  getSkillDataSuccess,
  getSkillDataFailure,
  updateSkillSuccess,
  updateSkillFailure,
  addNewSkillSuccess,
  addNewSkillFailure,
  deleteSkillSuccess,
  deleteSkillFailure,
} from '../skillMasterData';
import {
  fetchSkillData,
  addSkill,
  updateSkill,
  deleteSkill,
} from '../async/masterdataAsynch';

import {
  ADD_NEW_SKILL,
  SKILL_DATA_FETCH,
  UPDATE_SKILL_INFO,
  DELETE_SKILL,
} from '../../constants';

export function* getSkillDataList({ data }) {
  try {
    const response = yield call(fetchSkillData, data);
    yield put(getSkillDataSuccess(response.data));
  } catch (err) {
    yield put(getSkillDataFailure(err.response.data));
  }
}

export function* updateSkillData({ data }) {
  try {
    const response = yield call(updateSkill, data);
    yield put(updateSkillSuccess(response.data));
  } catch (err) {
    yield put(updateSkillFailure(err.response.data));
  }
}

export function* createNewSkill({ data }) {
  try {
    const response = yield call(addSkill, data);
    yield put(addNewSkillSuccess(response.data));
  } catch (err) {
    yield put(addNewSkillFailure(err.response.data));
  }
}

export function* deleteSkillData({ data }) {
  try {
    const response = yield call(deleteSkill, data);
    yield put(deleteSkillSuccess(response.data));
  } catch (err) {
    yield put(deleteSkillFailure(err.response.data));
  }
}

export function* skillDataSaga() {
  yield takeLatest(SKILL_DATA_FETCH, getSkillDataList);
  yield takeLatest(UPDATE_SKILL_INFO, updateSkillData);
  yield takeLatest(ADD_NEW_SKILL, createNewSkill);
  yield takeLatest(DELETE_SKILL, deleteSkillData);
}
