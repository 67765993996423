import React, {
  useState, useEffect, useCallback, memo,
} from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';

import ConfirmModal from './ConfirmModal';

const CustomPrompt = ({
  shouldBlockNavigation, navigate, when, message,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  useEffect(() => {
    if (lastLocation && confirmedNavigation) {
      navigate(lastLocation.pathname + lastLocation.search);
    }
  }, [lastLocation, confirmedNavigation, navigate]);

  const showModal = useCallback((location) => {
    setModalVisible(true);
    setLastLocation(location);
  }, []);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
        showModal(nextLocation);
        return false;
      }

      return true;
    },
    [confirmedNavigation, shouldBlockNavigation, showModal],
  );

  const handleConfirmNavigationClick = useCallback(() => {
    closeModal();
    setConfirmedNavigation(true);
  }, [closeModal]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <ConfirmModal
        open={modalVisible}
        message={message}
        onClose={closeModal}
        onConfirm={handleConfirmNavigationClick}
      />
    </>
  );
};

CustomPrompt.defaultProps = {
  when: true,
};

CustomPrompt.propTypes = {
  when: PropTypes.bool,
  message: PropTypes.string.isRequired,
  navigate: PropTypes.func.isRequired,
  shouldBlockNavigation: PropTypes.func.isRequired,
};

export default memo(CustomPrompt, (prevProps, nextProps) => (
  prevProps.message === nextProps.message && prevProps.navigate === nextProps.navigate
  && prevProps.when === nextProps.when
  && prevProps.shouldBlockNavigation === nextProps.shouldBlockNavigation
));
