import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import _map from 'lodash/map';
import _transform from 'lodash/transform';
import _filter from 'lodash/filter';
import _update from 'lodash/update';
import _keyBy from 'lodash/keyBy';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';
import _get from 'lodash/get';
import _has from 'lodash/has';
import qs from 'query-string';
import moment from 'moment';
import { useFormik } from 'formik';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { unstable_batchedUpdates as batchUpdates } from 'react-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../common/Loading';
import {
  getCourseDetailsById, updateCourseDetailsById, reorderTrainingMaterials,
  deleteScormTm, validateCourseCode,
} from '../../containers/ContentManagement/apis';
import ContentDetailsHeader from './ContentDetailsHeader';
import {
  DOCEBO_METADATA_LOOKUP, ROUTE_REPORT, USER_ACCESS, CONTENT_TAB,
  AUTO_HIDE_DURATION, COURSE_PROD, INDIVIDUAL_SCORM_REFRESH,
} from '../../constants';
import {
  fetchActiveLanguages, getProgress, getReport, downloadFile,
} from '../../containers/common/apis';
import { stripHtmlTags, zeroAppender } from '../../helpers/formattingHelpers';
import { BLACK } from '../../stylesheets/colors';
import MCKINSEY_SANS from '../../stylesheets/fonts';
import ErrorModal from '../ErrorModal/ErrorModal';
import useProgressResult from '../../hooks/useProgressResult';
import IndividualScormReleaseStatuses from './IndividualScormReleaseStatuses';
import AlertReleaseInfo from './AlertReleaseInfo';
import useNotifications from '../../hooks/useNotifications';
import { getErrorMessage } from '../../helpers/apiHelper';
import ReorderTmsStatuses from './ReorderTmsStatuses';
import AddScormAlertBar from './AddNewScormModal/AddScormAlertBar';
import EditScormModal from './EditScormModal';
import AlertBarWithAction from '../common/AlertBarWithAction';
import DeleteScormModal from './DeleteScormModal';
import { SHOW_NOTIFICATION, REMOVE_NOTIFICATION } from '../../store/constants';
import { ComponentDetailsValidationSchema } from '../../helpers/validationHelper';
import ContentDetailsBody from './ContentDetailsBody';

const useStyles = makeStyles({
  outerWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '0 2rem',
    minHeight: '80vh',
  },
  wrapper: {
    '&.MuiContainer-root': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  innerWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  contentWrapper: {
    border: '1px solid red',
  },
  loader: {
    margin: 'auto',
  },
  divider: {
    width: '98%',
    marginBottom: ({ hasContentHeader }) => (hasContentHeader ? '0rem' : '2rem'),
  },
  order: {
    color: BLACK,
    fontWeight: 600,
    fontFamily: MCKINSEY_SANS,
  },
});

const formFields = ['id', 'name', 'code', 'thumbnail', 'type', 'uidCourse', 'description', 'lang_label', 'show_toc', 'has_autoplay', 'credits', 'additional_fields', 'is_published', 'skill_id', 'skill_alias'];

const additionalFormFields = ['Portfolio', 'Offering', 'Program', 'Elucidat Project ID', 'Course Name', 'Course runner',
  'Additional Metadata', 'Keep in mind', 'After you complete this course'];

const BooleanToBinary = (value) => (value === true ? 1 : 0);

const formatDate = (date) => date && moment(date).format('yyyy-MM-DD');

const ContentManagementView = ({
  transactionId, match, doceboUrl, doceboLpId, getScormReleaseDetails, refreshScorm, configId,
  location, updateScorm, isDDAdmin, navigationBreadcrumb, programMetadata,
}) => {
  const classes = useStyles();
  const [courseData, setCourseData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isUserAccessEdit, setIsUserAccessEdit] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [trainingMaterials, setTrainingMaterials] = useState([]);
  const [showElement, setShowElement] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [releaseTransaction, setReleaseTransaction] = useState(null);
  const [reorderTransaction, setReorderTransaction] = useState(null);
  const [addScormTransaction, setAddScormTransaction] = useState(null);
  const [disabledActions, setDisabledActions] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [modalState, setModalState] = useState('');
  const [pollProgressValue, setPollProgressValue] = useState(false);
  const [editScormModal, setEditScormModal] = useState(false);
  const [deleteScormModal, setDeleteScormModal] = useState(false);
  const [scormRow, setScormRow] = useState({});
  const [showUpdateScormAlert, setShowUpdateScormAlert] = useState(false);
  const [showUpdateScormAlertDetails, setShowUpdateScormAlertDetails] = useState({});
  const [deleteScormInprogress, setDeleteScormInprogress] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification);

  const updatePollProgress = (value) => {
    setPollProgressValue(value);
  };

  const {
    progressResult, progressError,
  } = useProgressResult(
    pollProgressValue, releaseTransaction, updatePollProgress,
  );

  const { notifySuccess, notifyError } = useNotifications();

  const { data: progress } = progressResult
    && Object.keys(progressResult).length > 0 ? progressResult : { data: {} };

  const history = useHistory();
  const {
    id: courseId, is_published, name, type, thumbnail, description, uidCourse, code,
  } = courseData;

  const userAccessObj = is_published ? USER_ACCESS.published
    : USER_ACCESS.locked;

  const { is_skill: is_skill_lp = false } = programMetadata;
  const IndividualScormTransactionIds = _get(
    programMetadata,
    `activities_status.${COURSE_PROD}.${INDIVIDUAL_SCORM_REFRESH}.transaction_ids`,
    [],
  );

  const onDownloadLog = async (transaction, q) => {
    try {
      const tid = transaction.transaction_id;
      const createdAt = transaction.created_at;
      await downloadFile(tid, configId, INDIVIDUAL_SCORM_REFRESH, createdAt, q);
    } catch (e) {
      // TODO: Show error toast
      console.error(e);
    }
  };

  useEffect(() => {
    if (progress?.done) {
      batchUpdates(() => {
        updatePollProgress(false);
        setDisabledActions(false);
        setShowElement(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress?.done]);

  useEffect(() => {
    if (releaseTransaction) {
      updatePollProgress(true);
      setDisabledActions(true);
    }
  }, [releaseTransaction]);

  useEffect(() => {
    if (notification !== null) {
      setTimeout(() => {
        dispatch({ type: REMOVE_NOTIFICATION });
      }, AUTO_HIDE_DURATION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  useEffect(() => {
    if (showElement) {
      setTimeout(() => {
        setShowElement(false);
      }, AUTO_HIDE_DURATION);
    }
  }, [showElement]);

  const onInfoModalClose = () => {
    setInfoModal(false);
    setModalState('');
  };

  const onViewStatus = useCallback((status) => {
    batchUpdates(() => {
      setInfoModal(true);
      setModalState(status);
    });
  }, []);

  const programId = get(match, 'params.programId');
  const numberProgramId = Number.parseInt(programId, 10);
  const programSubType = get(match, 'params.programSubType');

  const isValue = (value) => (!!((value !== null && value !== '' && value !== undefined)));

  const filterAdditinalFields = (array) => _keyBy(_map(array,
    (obj) => ({ ...obj, value: stripHtmlTags(obj.value) ? stripHtmlTags(obj.value) : obj.value })), 'title');

  const secondsToHms = (seconds) => {
    const h = Math.floor(seconds / 3600);
    // eslint-disable-next-line no-mixed-operators
    const m = Math.floor(seconds % 3600 / 60);
    return `${zeroAppender(h)}:${zeroAppender(m)}`;
  };

  const checkEachField = useCallback((result, value, key) => {
    if (isValue(value)) {
      if (typeof value === 'boolean') {
        result[key] = BooleanToBinary(value);
      } else if (key === 'duration') {
        result[key] = secondsToHms(value);
      } else if (key === 'description') {
        result[key] = value.replace(/&nbsp;/g, '').replace(/(<([^>]+)>)/gi, '');
      } else {
        result[key] = value;
      }
    } else {
      result[key] = '';
    }
  }, []);

  const goToList = useCallback(() => {
    history.push(`/${ROUTE_REPORT}/${programId}/${programSubType}`);
  }, [history, programId, programSubType]);

  const getCourseDetails = useCallback(async () => {
    try {
      const resp = await getCourseDetailsById(transactionId, programId);
      const data = resp?.data?.data[0];
      setCourseData(_update(_transform({
        ..._pick(data, formFields),
        eop: resp?.data?.eop,
        is_required: resp?.data?.is_required ?? false,
        ...(is_skill_lp && { skill_id: resp?.data?.skill_id }),
        ...(is_skill_lp && { skill_alias: resp?.data?.skill_alias }),
        ...(!(data?.type.includes('classroom'))) && {
          date_begin: formatDate(data?.course_date_start),
          date_end: formatDate(data?.course_date_end),
          is_soft_deadline_enabled: _has(data, 'is_soft_deadline_enabled') ? data?.is_soft_deadline_enabled : true,
          soft_deadline: _has(data, 'is_soft_deadline_enabled') ? data?.is_soft_deadline_enabled : true,
          duration: data?.duration,
          training_materials: data?.tree,
        },
      }, checkEachField, {}), 'additional_fields', filterAdditinalFields));
      setTrainingMaterials(data?.tree);
    } catch (err) {
      notifyError(getErrorMessage(err));
      goToList();
    }
  }, [transactionId, checkEachField, goToList, notifyError, programId, is_skill_lp]);

  const updateReleaseTransaction = (isValid, id, scormName, errorMsg = '') => {
    if (isValid) {
      setReleaseTransaction(id);
    } else {
      notifyError(errorMsg);
    }
  };

  const onUpdateCourseDetails = async (data) => {
    try {
      setCourseData({});
      const resp = await updateCourseDetailsById(courseData?.id, data);
      if (resp.data.success === true) {
        notifySuccess('Course updated successfully');
        setIsEdit(false);
      } else {
        notifyError(resp.data.message);
        setIsEdit(false);
      }
      getCourseDetails();
    } catch (err) {
      notifyError(getErrorMessage(err));
      getCourseDetails();
    }
  };

  const saveReorderTMs = async (trainingMaterialsList) => {
    try {
      setCourseData({});
      const resp = await reorderTrainingMaterials({
        tree: trainingMaterialsList,
        program_id: programId,
        docebo_course_id: courseId,
      });
      if (resp.data.success === true) {
        setReorderTransaction(resp.data.transaction_id);
        dispatch({ type: SHOW_NOTIFICATION, payload: { variant: 'success', message: 'Changes saved successfully.' } });
      } else {
        setReorderTransaction(resp.data.transaction_id);
        dispatch({ type: SHOW_NOTIFICATION, payload: { variant: 'error', message: resp.data.message || 'Changes not saved successfully.' } });
      }
    } catch (err) {
      setReorderTransaction(err?.response?.data?.transaction_id);
      dispatch({ type: SHOW_NOTIFICATION, payload: { variant: 'error', message: getErrorMessage(err) || 'Changes not saved successfully.' } });
    } finally {
      getCourseDetails();
    }
  };

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const updateFieldObject = (val) => ({
    id: val.id,
    key: val.title,
    slug: getKeyByValue(DOCEBO_METADATA_LOOKUP, val.title),
    value: val.value,
  });

  const filterFieldsByValue = (val) => additionalFormFields.includes(val.title);

  const formatAdditionalField = (fields) => (
    _map(_filter(Object.values(fields), filterFieldsByValue), updateFieldObject));

  const onUpdateCourseData = (payload) => {
    const result = { ...payload };
    const duration = result?.duration ? result?.duration?.split(':') : ['00', '00'];
    onUpdateCourseDetails(_omit(_update({
      ...result,
      program_id: numberProgramId,
      ...(!(courseData?.type.includes('classroom'))) && {
        soft_deadline: result?.date_end === '' ? null : result?.soft_deadline,
        duration_hours: duration[0],
        duration_minutes: duration[1],
      },
    }, 'additional_fields', formatAdditionalField), ['thumbnail', 'duration', 'type', 'uidCourse', 'show_toc', 'has_autoplay', 'duration',
      moment(result?.date_begin).isAfter(new Date()) && result?.date_end === '' && 'date_end']));
  };

  const {
    values, handleSubmit, errors, touched, handleChange, setFieldValue, resetForm, setErrors,
  } = useFormik({
    initialValues: courseData,
    enableReinitialize: true,
    validationSchema: ComponentDetailsValidationSchema(is_skill_lp),
    onSubmit: (payload, bag) => {
      // eslint-disable-next-line consistent-return
      const validateComponentCode = async () => {
        try {
          const resp = await validateCourseCode({
            new_course_code: values?.code,
            target_lp_code: configId,
          });
          if (resp?.data?.success === true) {
            return onUpdateCourseData(payload);
          }
          bag.setErrors({ code: resp?.data?.message });
        } catch (err) {
          bag.setErrors({ code: getErrorMessage(err) });
        }
      };
      if (courseData.code !== values?.code) {
        validateComponentCode();
      } else {
        onUpdateCourseData(payload);
      }
    },
  });

  const getLanguages = async () => {
    fetchActiveLanguages().then(({ data }) => {
      const activeLanguages = Object.values(data.languages).map((lang) => ({
        label: lang.description,
        value: lang.description,
        code: lang.code,
        shortCode: lang.browsercode,
      }));
      setLanguages(activeLanguages);
    });
  };

  useEffect(() => {
    getLanguages();
  }, []);

  const changeMode = () => {
    setIsEdit(!isEdit);
  };

  const resetPage = () => {
    resetForm();
    changeMode();
  };

  const editUserAccess = () => {
    setIsUserAccessEdit(!isUserAccessEdit);
  };

  useEffect(() => {
    getCourseDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const statusOfLastTransaction = useCallback(async (latestTransactionId) => {
    const res = await getProgress(latestTransactionId);
    const { done } = res.data;
    if (!done) {
      setReleaseTransaction(latestTransactionId);
    }
  }, []);

  useEffect(() => {
    if (IndividualScormTransactionIds.length) {
      statusOfLastTransaction(IndividualScormTransactionIds[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IndividualScormTransactionIds.length]);

  const query = qs.parse(location.search);
  const selectedTab = get(query, 'tab', CONTENT_TAB);

  const updateUserAccess = (value, requiredValue) => {
    onUpdateCourseDetails(
      { status: value, program_id: numberProgramId, is_required: requiredValue ? 1 : 0 },
    );
    setIsUserAccessEdit(false);
  };

  const onTabChange = (newTab) => history.push(`/${ROUTE_REPORT}/${programId}/${programSubType}?feature=view-course&id=${transactionId}&tab=${newTab}`, {});

  const addScormTmTransaction = (tId, info, message) => {
    setAddScormTransaction(tId);
    dispatch({ type: SHOW_NOTIFICATION, payload: { variant: info, message } });
  };

  const showAlert = useCallback((status, message) => {
    setShowUpdateScormAlert(true);
    setShowUpdateScormAlertDetails({
      status, message,
    });
    setScormRow({ ...scormRow, status });
    if (status === 'success') {
      getCourseDetails();
    }
  }, [getCourseDetails, scormRow]);

  useEffect(() => {
    if (showUpdateScormAlert) {
      setTimeout(() => {
        setShowUpdateScormAlert(false);
        setShowUpdateScormAlertDetails({});
        setScormRow({});
      }, AUTO_HIDE_DURATION);
    }
  }, [showUpdateScormAlert]);

  const deleteScormConfirm = useCallback(async () => {
    const { course_id, id } = scormRow;
    try {
      setDeleteScormInprogress(true);
      const resp = await deleteScormTm(course_id, id?.split(':')[0]);
      if (resp.data.success === true) {
        showAlert('success', resp.data.message);
      } else {
        showAlert('error', resp.data.message);
      }
    } catch (err) {
      showAlert('error', getErrorMessage(err));
    } finally {
      setDeleteScormInprogress(false);
      setDeleteScormModal(false);
    }
  }, [scormRow, showAlert]);

  const openEditScormModal = (rowData) => {
    setEditScormModal(true);
    setScormRow(rowData);
  };

  const openDeleteScormModal = (rowData) => {
    setScormRow(rowData);
    setDeleteScormModal(true);
  };

  return (
    <Paper>
      {releaseTransaction && Object.keys(progress).length > 0 ? (
        <AlertReleaseInfo
          progress={progress}
          showElement={showElement}
          progressMessage={`Full re-release for ’${progress?.scorm_name}’ in progress`}
          withErrorsMessage={`Re-release of ’${progress?.scorm_name}’ was completed with errors.`}
          failedMessage={`Failed to re-release ’${progress?.scorm_name}’, please try again.`}
          successMessage={`'${progress?.scorm_name}' was successfully re-released`}
          onViewStatus={onViewStatus}
          setShowElement={setShowElement}
        />
      ) : null}
      {reorderTransaction && notification && (
        <ReorderTmsStatuses
          transactionId={reorderTransaction}
          statusVariant={notification}
          showElement={notification !== null}
          closeNotification={() => dispatch({ type: REMOVE_NOTIFICATION })}
          onDownloadLog={onDownloadLog}
          getReport={getReport}
        />
      )}
      {addScormTransaction && notification && (
        <AddScormAlertBar
          transactionId={addScormTransaction}
          statusVariant={notification}
          showElement={notification !== null}
          closeNotification={() => dispatch({ type: REMOVE_NOTIFICATION })}
          onDownloadLog={onDownloadLog}
          getReport={getReport}
        />
      )}
      {showUpdateScormAlert ? (
        <AlertBarWithAction
          variant={showUpdateScormAlertDetails.status}
          percentage={null}
          labelText={showUpdateScormAlertDetails.message}
          actionButtonIcon={<CloseIcon onClick={() => setShowElement(false)} />}
        />
      ) : null}
      <Box className={classes.outerWrapper}>
        {Object.keys(courseData).length > 0 ? (
          <>
            {navigationBreadcrumb(name)}
            <Grid container spacing={2} direction="row" sx={{ height: '100%' }}>
              <ContentDetailsHeader
                name={name}
                type={type}
                thumbnail={thumbnail}
                description={description}
                uidCourse={uidCourse}
                code={code}
                isEdit={isEdit}
                onEdit={changeMode}
                onCancel={resetPage}
                handleSubmit={handleSubmit}
                contentTab={selectedTab}
                handleContentTab={onTabChange}
                updateUserAccess={updateUserAccess}
                editUserAccess={editUserAccess}
                userAccessObj={userAccessObj}
                isUserAccessEdit={isUserAccessEdit}
                isValidating={isValidating}
                errors={errors}
                isRequired={courseData.is_required}
              />
              <ContentDetailsBody
                selectedTab={selectedTab}
                name={name}
                code={code}
                courseId={courseData.id}
                doceboUrl={doceboUrl}
                doceboLpId={doceboLpId}
                trainingMaterials={trainingMaterials}
                getScormReleaseDetails={getScormReleaseDetails}
                updateScorm={updateScorm}
                updateReleaseTransaction={updateReleaseTransaction}
                refreshScorm={refreshScorm}
                configId={configId}
                programId={programId}
                disabledActions={disabledActions}
                location={location}
                releaseTransaction={releaseTransaction}
                progress={progress}
                isDDAdmin={isDDAdmin}
                saveReorderTMs={saveReorderTMs}
                setTrainingMaterials={setTrainingMaterials}
                getCourseDetails={getCourseDetails}
                addScormTmTransaction={addScormTmTransaction}
                openEditScormModal={openEditScormModal}
                openDeleteScormModal={openDeleteScormModal}
                updatedscormRow={scormRow}
                isEdit={isEdit}
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                setErrors={setErrors}
                languages={languages}
                isValidating={isValidating}
                setIsValidating={setIsValidating}
                is_skill_lp={is_skill_lp}
              />
            </Grid>
          </>
        ) : <Loading minHeight="100%" />}
      </Box>
      <ErrorModal open={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)} />
      {
        releaseTransaction && infoModal && (
          <IndividualScormReleaseStatuses
            transactionId={releaseTransaction}
            modalState={modalState}
            open={infoModal}
            onClose={onInfoModalClose}
            onDownloadLog={onDownloadLog}
            getReport={getReport}
            progressData={progress}
            progressError={progressError}
          />
        )
      }
      {
        editScormModal && (
          <EditScormModal
            open={editScormModal}
            data={scormRow}
            onClose={() => setEditScormModal(false)}
            showAlert={showAlert}
          />
        )
      }
      {
        deleteScormModal && (
          <DeleteScormModal
            open={deleteScormModal}
            onClose={() => setDeleteScormModal(false)}
            onConfirm={deleteScormConfirm}
            isLoading={deleteScormInprogress}
          />

        )
      }
    </Paper>
  );
};

ContentManagementView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programId: PropTypes.string,
      programSubType: PropTypes.string,
      transactionId: PropTypes.string,
    }),
  }).isRequired,
  transactionId: PropTypes.string.isRequired,
  doceboUrl: PropTypes.string.isRequired,
  doceboLpId: PropTypes.string.isRequired,
  getScormReleaseDetails: PropTypes.func.isRequired,
  updateScorm: PropTypes.func.isRequired,
  refreshScorm: PropTypes.func.isRequired,
  configId: PropTypes.string.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  isDDAdmin: PropTypes.bool.isRequired,
  navigationBreadcrumb: PropTypes.func.isRequired,
  programMetadata: PropTypes.shape({
    is_skill: PropTypes.bool,
    activities_status: PropTypes.shape({
      course_prod: PropTypes.shape({
        rerelease_multiple_scorm: PropTypes.shape({
          transaction_ids: PropTypes.array.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default ContentManagementView;
