/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as Yup from 'yup';
import { CancelToken, isCancel } from 'axios';
import { useFormik } from 'formik';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@material-ui/core/Typography';
import _debounce from 'lodash/debounce';
import _map from 'lodash/map';
import get from 'lodash/get';
import AddScormFileUpload from './AddScormFileUpload';
import CustomUploadModal from '../../common/CustomUploadModal';
import FormattedTypography from '../../common/FormattedTypography';
import ScormDetailsForm from './ScormDetailsForm';
import DesktopViewMode from './DesktopViewMode';
import { addTrainingMaterials, fetchScormReleasesByProjectId } from '../../../containers/ContentManagement/apis';
import LoadingCircle from '../../common/LoadingCircle/LoadingCircle';
import CustomModal from '../../common/CustomModal';
import { ADD_SCORM_STEPS, USER_INFO_KEY } from '../../../constants';
import WizardStepper from '../../common/WizardStepper';
import { getUserFromLocalStorage } from '../../../helpers/userHelper';
import { MEDIUM_GREY } from '../../../stylesheets/colors';
import { validateUrl } from '../../../helpers/utils';

const useStyles = makeStyles({
  dropdownOptions: {
    display: 'flex',
    flexDirection: 'column',
  },
  optionTitle: {
    fontSize: ' 1rem',
  },
  optionSubtitle: {
    fontSize: ' 0.875rem',
  },
  modalStyle: {
    paddingTop: '3rem',
  },
  loadingWrapper: {
    '&.MuiContainer-root': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    '& > img': {
      marginBottom: '2.5rem',
    },

    '& > p': {
      textAlign: 'center',
    },

  },
  infoContentText: {
    color: MEDIUM_GREY,
  },
  formHeading: {
    fontFamily: 'McKinseySans',
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  titleFont: {
    fontSize: '1.5rem',
  },
});

const cancelTokenSource = {};

const AddNewScormWizard = ({
  open, onClose, programId, courseId, refetchTms, setNewScorm, code, addScormTmTransaction,
  setIsScormAdding, getPresignedUrl, isFileUploaded,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const userEmail = get(getUserFromLocalStorage(USER_INFO_KEY), 'email', []);

  const classes = useStyles();

  const next = (values) => {
    if (activeStep === 0) {
      setActiveStep(1);
    } else if (activeStep === 1) {
      setActiveStep(2);
    }
  };
  const Step1Schema = Yup.object({
    file: Yup
      .mixed()
      .required('File is required')
      .test('type', 'Only the following formats are accepted: .zip, .rar and .7zip', (value) => value && (
        value?.type === 'application/zip'
        || value?.type === 'application/rar'
        || value?.type === 'application/7zip'
        || value?.type === 'application/x-zip-compressed'
      )),
  });

  const Step2Schema = Yup.object({
    title: Yup.string().required('This is a required field').max(255, 'Title should not exceed 255 characters'),
    scorm_type: Yup.string().required('Please select scorm type'),
    project_id: Yup.string().notRequired()
      .when('scorm_type', {
        is: (val) => val === 'Elucidat',
        then: Yup.string().required('This is a required field'),
        otherwise: Yup.string().notRequired(),
      }),
    release_id: Yup.string().notRequired()
      .when('scorm_type', {
        is: (val) => val === 'Elucidat',
        then: Yup.string().required('This is a required field'),
        otherwise: Yup.string().notRequired(),
      }),
    scorm_link: Yup.string().notRequired()
      .when('scorm_type', {
        is: (val) => val !== 'Elucidat',
        then: validateUrl,
      }),
    box_link: Yup.string().notRequired()
      .when('scorm_type', {
        is: (val) => val !== 'Elucidat',
        then: validateUrl,
      }),
  });

  const schemaArray = [Step1Schema, Step2Schema];

  const generateFormData = useCallback(
    (data) => {
      const formData = new FormData();
      formData.append('filename', data.file.name);
      formData.append('program_id', data.program_id);
      formData.append('course_id', data.course_id);
      formData.append('name', data.title);
      formData.append('launch_mode', data.launch_mode);
      formData.append('scorm_type', data.scorm_type);
      formData.append('component_code', code);

      if (data.scorm_type === 'Elucidat') {
        formData.append('project_id', data.project_id);
        formData.append('project_name', data.project_name);
        formData.append('release_id', data.release_id);
        formData.append('release_version', data.release_version);
        formData.append('last_released', data.last_released);
      } else {
        formData.append('scorm_link', data.scorm_link);
        formData.append('box_link', data.box_link);
      }
      return formData;
    },
    [code],
  );

  const formik = useFormik({
    initialValues: {
      file: null,
      title: '',
      scorm_type: '',
      launch_mode: 'fullscreen',
      program_id: programId,
      course_id: courseId,
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: schemaArray[activeStep],
    onSubmit: (values, bag) => {
      if (activeStep === 2) {
        // eslint-disable-next-line consistent-return
        const submit = async () => {
          try {
            setIsScormAdding(true);
            const resp = await addTrainingMaterials(generateFormData(values));
            if (resp?.data?.success === true) {
              addScormTmTransaction(resp?.data?.transaction_id, 'success', resp?.data?.message);
              refetchTms();
              setNewScorm(true);
              setTimeout(() => {
                setNewScorm(false);
              }, 9000);
            } else {
              addScormTmTransaction(resp?.data?.transaction_id, 'error', resp?.data?.message);
            }
          } catch (err) {
            addScormTmTransaction(err?.response?.data?.transaction_id, 'error', err?.response?.data?.message);
          } finally {
            setIsLoading(false);
            bag.resetForm();
            setActiveStep(0);
            setIsScormAdding(false);
          }
        };
        submit();
      }
      next(values);
      bag.setSubmitting(false);
    },
  });

  const {
    handleSubmit, handleChange, values, errors, touched,
    setErrors, setFieldValue, setValues, resetForm, setFieldTouched,
  } = formik;

  useEffect(() => {
    if (open) {
      setActiveStep(0);
      resetForm();
    }
  }, [open, resetForm]);

  useEffect(() => {
    if (isFileUploaded) {
      setActiveStep(1);
    }
  }, [isFileUploaded]);

  useEffect(() => {
    if (values?.file?.name?.length > 0) {
      getPresignedUrl(values.file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.file]);

  const preSubmit = () => {
    if (activeStep === 2) {
      onClose();
      setIsLoading(true);
    }
    handleSubmit();
  };

  const moveToFileUpload = () => {
    setActiveStep(0);
  };

  const clearElucidatDetails = useCallback(() => {
    setFieldValue('project_name', '');
    setFieldValue('project_link', '');
    setFieldValue('release_id', '');
  }, [setFieldValue]);

  const checkPIdValidity = useCallback(
    async (pid) => {
      // Cancel old request
      setIsValidating(true);
      clearElucidatDetails();
      if (cancelTokenSource[['project_id']]) {
        cancelTokenSource[['project_id']].cancel();
      }
      setErrors({ ...errors, project_id: '' });
      if (!pid) {
        setIsValidating(false);
        return;
      }

      if (!new RegExp('^[a-z0-9;]+$', 'i').test(pid)) {
        setErrors({ ...errors, project_id: 'Special characters are not allowed' });
        setIsValidating(false);
        return;
      }

      try {
        cancelTokenSource[['project_id']] = CancelToken.source();
        const response = await fetchScormReleasesByProjectId(pid, cancelTokenSource[['project_id']].token);
        if (response?.data?.success === true) {
          setFieldValue('project_name', response?.data?.data?.name);
          setFieldValue('project_link', response?.data?.data?.view_url);
          const releaseList = response?.data?.data?.releases;
          if (releaseList?.length > 0) {
            const elucidatReleaseList = _map(releaseList, (obj) => ({
              label: (
                <Box className={classes.dropdownOptions}>
                  <Typography className={classes.optionTitle}>{obj?.release_code}</Typography>
                  <Typography className={classes.optionSubtitle}>
                    {obj?.description}
                  </Typography>
                </Box>
              ),
              value: obj?.release_code,
              releaseId: obj?.release_code,
              releaseDescription: obj?.description,
              releaseVersion: obj?.version_number,
              lastReleased: obj?.modified,
            }));
            setFieldValue('project_releases', elucidatReleaseList);
          } else {
            setFieldValue('project_releases', []);
          }
          setIsValidating(false);
        }
      } catch (e) {
        if (!isCancel(e)) {
          setErrors({ ...errors, project_id: e?.response?.data?.data || 'Something went wrong, Try again' });
          setIsValidating(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errors, setErrors, setIsValidating, setFieldValue],
  );

  const debouncedChange = useCallback(_debounce(checkPIdValidity, 500), [checkPIdValidity]);

  const validateProjectId = (val) => {
    debouncedChange(val);
  };

  const onFileUpload = (event) => {
    setFieldValue('file', event.currentTarget.files[0]);
  };

  return (
    <>
      {
        isLoading
        && (
          <CustomModal
            open
            breakpoint="sm"
            modalStyle={classes.modalStyle}
          >
            <LoadingCircle labelNode={(
              <Box className={classes.loadingWrapper}>
                <Typography variant="body2" component="p" className={classes.subHeading}>
                  Adding new training material - SCORM type
                </Typography>
                <Typography variant="body1" component="p" className={`${classes.infoContentText}`}>
                  {`Activity initiated by ${userEmail} on ${new Date()} is in process.`}
                </Typography>
                <Typography variant="body1">Please hold on a bit, we are working on it…</Typography>
              </Box>
            )}
            />
          </CustomModal>
        )
      }
      <CustomUploadModal
        open={open}
        onClose={onClose}
        heading={(
          <DialogTitle>
            <FormattedTypography
              variant="subtitle1"
              body="Add new training material - SCORM"
              subHeading="Create a new SCORM type training material by filling in the following details.
              Score calculation details can be updated via Docebo."
            />
          </DialogTitle>
        )}
        headingDivider
        disableGutters
        contentDivider
        primaryBtnText={activeStep < 2 ? 'Next' : 'Add'}
        primaryBtnProps={
          {
            onClick: () => preSubmit(),
            // disabled: activeStep === 0 && Object.keys(errors).length !== 0,
          }
        }
        secondaryBtnText={activeStep === 2 && 'Back'}
        secondaryBtnProps={
          { onClick: () => setActiveStep(activeStep - 1) }
        }
      >
        <Container sx={{ width: '100%' }}>
          <WizardStepper steps={ADD_SCORM_STEPS} activeStep={activeStep} />
          {(() => {
            if (activeStep === 2) {
              return (
                <DesktopViewMode
                  values={values}
                  errors={errors}
                  touched={touched}
                  setValues={setValues}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  formHeadingClass={classes.formHeading}
                  titleFontSize={classes.titleFont}
                />
              );
            }
            if (activeStep === 1) {
              return (
                <ScormDetailsForm
                  values={values}
                  errors={errors}
                  touched={touched}
                  setValues={setValues}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  validateProjectId={validateProjectId}
                  clearElucidatDetails={clearElucidatDetails}
                  setFieldTouched={setFieldTouched}
                  isValidating={isValidating}
                  formHeadingClass={classes.formHeading}
                  onReplace={moveToFileUpload}
                />
              );
            }
            return (
              <AddScormFileUpload
                onUpload={onFileUpload}
                errors={errors}
                touched={touched}
              />
            );
          })()}
        </Container>
      </CustomUploadModal>
    </>
  );
};

AddNewScormWizard.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
  refetchTms: PropTypes.func.isRequired,
  setNewScorm: PropTypes.func.isRequired,
  code: PropTypes.string.isRequired,
  addScormTmTransaction: PropTypes.func.isRequired,
  setIsScormAdding: PropTypes.func.isRequired,
  getPresignedUrl: PropTypes.func.isRequired,
  isFileUploaded: PropTypes.bool.isRequired,
};

export default AddNewScormWizard;
