import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { VERY_DARK_GREY } from '../../stylesheets/colors';
import ErrorText from '../common/ErrorText';

const BuildTypeWrapper = ({
  children, isHavingBuildType, buildType, forSource,
}) => (
  <>
    <Typography variant="subtitle2" component="span" style={{ fontWeight: '400' }}>
      The values for this field will only show up when selected
    </Typography>
    <Typography variant="subtitle2" style={{ fontWeight: '900' }}>
      {' '}
      {forSource ? 'build type(s) : Source' : 'build type(s) : Replication, Configuration - Docebo only or Configuration - Docebo & Elucidat'}
    </Typography>
    {children}
    {isHavingBuildType ? (<Typography style={{ color: VERY_DARK_GREY }}>{`Currently selected build type : ${buildType}`}</Typography>) : (
      <Box>
        <ErrorText
          touchedField
          errorField="Please select build type first"
        />
      </Box>
    )}
  </>
);

BuildTypeWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  buildType: PropTypes.string.isRequired,
  isHavingBuildType: PropTypes.bool.isRequired,
  forSource: PropTypes.bool.isRequired,
};

export default BuildTypeWrapper;
