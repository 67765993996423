import {
  GET_PROGRAMS_STARTED,
  GET_PROGRAMS_SUCCESS,
  GET_PROGRAMS_FAILURE,
  GET_INITIAL_PROGRAMS_STARTED,
  GET_FILTERED_PROGRAMS_SUCCESS,
} from '../constants';

const initialState = {
  page: 0,
  hasMore: false,
  searchText: '',
  programs: [],
  isLoading: false,
  isFilteredData: false,
  isError: false,
};

export default function LearningProgramsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_INITIAL_PROGRAMS_STARTED:
      return {
        ...state,
        isLoading: true,
        isError: false,
        hasMore: false,
        programs: [],
        isFilteredData: false,
        totalPrograms: 0,
      };
    case GET_PROGRAMS_STARTED:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case GET_PROGRAMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        programs: state.programs.concat(action.data.programs),
        totalPrograms: action.data.totalPrograms,
        hasMore: action.data.hasMore,
        page: action.data.page,
        isFilteredData: false,
      };
    case GET_PROGRAMS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case GET_FILTERED_PROGRAMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        programs: state.programs.concat(action.data.programs),
        totalPrograms: action.data.totalPrograms,
        hasMore: action.data.hasMore,
        page: action.data.page,
        isFilteredData: true,
      };
    default:
      return state;
  }
}
