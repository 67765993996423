import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import LabledTextField from '../../common/LabledTextField';
import ErrorText from '../../common/ErrorText';

const useStyles = makeStyles(() => ({
  labelText: {
    marginTop: '1rem',
  },
}));

const ScormLink = ({
  errors, touched, handleChange, box_link, scorm_link,
}) => {
  const classes = useStyles();

  return (
    <>
      <LabledTextField
        className={classes.labelText}
        name="scorm_link"
        label="SCORM Link"
        infoText="Please provide the authoring platform URL where an author can access the course project directly (e.g., Branchtrack, Elucidat, etc.)."
        value={scorm_link}
        onChange={handleChange}
        placeholder="Enter SCORM URL"
      />
      <ErrorText
        touchedField={touched.scorm_link}
        errorField={errors.scorm_link}
      />
      <LabledTextField
        className={classes.labelText}
        name="box_link"
        label="Box Link"
        infoText="Please provide the Box link where the saved SCORM file can be downloaded from Box."
        value={box_link}
        onChange={handleChange}
        placeholder="Enter Box Link"
      />
      <ErrorText
        touchedField={touched.box_link}
        errorField={errors.box_link}
      />
    </>
  );
};

ScormLink.defaultProps = {
  scorm_link: '',
  box_link: '',
  touched: {
    scorm_link: '',
    box_link: '',
  },
  errors: {
    scorm_link: '',
    box_link: '',
  },
};

ScormLink.propTypes = {
  handleChange: PropTypes.func.isRequired,
  scorm_link: PropTypes.string,
  box_link: PropTypes.string,
  touched: PropTypes.shape({
    scorm_link: PropTypes.string,
    box_link: PropTypes.string,
  }),
  errors: PropTypes.shape({
    scorm_link: PropTypes.string,
    box_link: PropTypes.string,
  }),
};

export default ScormLink;
