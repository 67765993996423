import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import ErrorPage from './containers/ErrorPage';
import {
  LANDING_PATH,
  ROUTE_NVT_AUTH_CALLBACK,
} from './constants';

const AllowedRoutes = ({ allowedRoutes, role }) => {
  const getAllowedRoutes = (routes) => routes.filter(({ permission }) => {
    if (!permission) return true;
    return permission.includes(role);
  });

  const privateRoute = getAllowedRoutes(allowedRoutes);

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => <Redirect to={{ pathname: `${LANDING_PATH}` }} />}
      />
      <Route
        exact
        path="/logout"
        render={() => <Redirect to={{ pathname: `${LANDING_PATH}` }} />}
      />
      <Route
        path={`/${ROUTE_NVT_AUTH_CALLBACK}`}
        render={() => <Redirect to={{ pathname: `${LANDING_PATH}` }} />}
      />
      {privateRoute.map((route) => {
        const {
          path,
          component,
          title,
          permission,
          ...rest
        } = route;
        return (
          <Route {...rest} exact key={title} path={path} component={component} />);
      })}
      <Route path="*" component={ErrorPage} />
    </Switch>
  );
};

AllowedRoutes.propTypes = {
  allowedRoutes: PropTypes.array.isRequired,
  role: PropTypes.string.isRequired,
};

export default AllowedRoutes;
