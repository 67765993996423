import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getUserDetails,
  clearAlertBar,
  setUserDetailsSuccess,
  setSnackbar, fetchLanguages, saveUserDetails, updateUserDetailsStore, toggleAlertBar,
  fetchClientIds, createUser, clearTransactionId,
} from '../../store/actions/PlatformLevelUsers/userDetail';
import UserCreation from '../../components/PlatformLevelUsers/UserCreation';

const mapStateToProps = (state) => ({
  userDetails: state.platformUserDetailsData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getUserDetails,
    clearAlertBar,
    setUserDetailsSuccess,
    setSnackbar,
    fetchLanguages,
    saveUserDetails,
    updateUserDetailsStore,
    toggleAlertBar,
    fetchClientIds,
    createUser,
    clearTransactionId,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(UserCreation);
