import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Container from '@material-ui/core/Container';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LabledTextField from '../../common/LabledTextField';
import { zeroAppender } from '../../../helpers/formattingHelpers';
import { USER_ACCESS_OPTIONS, SCORMSELECT } from '../../../constants';
import CustomRadio from '../../UserEnrollment/common/CustomRadio';
import ErrorText from '../../common/ErrorText';
import {
  ACCORDION_GREY, BLACK, FILL_ERROR_RED, USER_CIRCLE_DARK_BG, SWITCH_GREY,
  REPORT_ERROR_RED,
} from '../../../stylesheets/colors';
import { getMaterialType } from '../../../helpers/utils';
import ScormTypeChip from '../../common/ScormTypeChip';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  addMargin: {
    marginTop: '2rem',
  },
  width100: {
    width: '100%',
  },
  subHeading: {
    fontWeight: 800,
  },
  contentHeadingHelper: {
    padding: '1.5rem 0',
    borderBottom: `1px solid ${ACCORDION_GREY}`,
  },
  contentWrapper: {
    padding: '1.5rem 0',
  },
  contentListWrapper: {
    width: '100%',
    padding: '1.5rem 0',
  },
  contentList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoHelperText: {
    color: ACCORDION_GREY,
  },
  infoContentText: {
    color: BLACK,
  },
  nestedText: {
    margin: '0 0.5rem',
  },
  tmWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  updateTmDetail: {
    paddingTop: '1rem',
  },
  paddingWrapper: {
    padding: '2rem 0',
  },
  dashedBorder: {
    borderBottom: `1px dashed ${SWITCH_GREY}`,
  },
  newComponentWrapper: {
    width: '60%',
  },
  radioGroup: {
    '&.MuiFormGroup-root': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  required: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&.MuiTypography-root::after': {
      content: "'*'",
      color: REPORT_ERROR_RED,
    },
  },
  errorBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rightText: {
    textAlign: 'right',
  },
}));

const ReviewComponent = ({
  formik, targetLpCode, handleChange,
}) => {
  const classes = useStyles();

  const {
    values: {
      lo, configId, componentName, newComponentTitle, newComponentCode, userAccess,
    }, errors, touched,
  } = formik;

  return (
    <>
      <Typography variant="subtitle1">
        Review the component and any changes made below
      </Typography>
      <Box className={`${classes.paddingWrapper} ${classes.dashedBorder}`}>
        <Typography variant="subtitle2" className={classes.infoHelperText}>
          Importing Component:
        </Typography>
        <Typography variant="subtitle2" component="span" className={`${classes.infoContentText}`}>
          {componentName}
        </Typography>
        <Box>
          <Typography variant="subtitle2" className={classes.infoHelperText}>
            Learning Plan:
            <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>
              {configId}
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Box className={classes.paddingWrapper}>
        <Typography variant="subtitle2" className={classes.infoHelperText}>
          Learning Plan Imported to:
        </Typography>
        <Typography variant="subtitle2" component="span" className={`${classes.infoContentText}`}>
          {targetLpCode}
        </Typography>
      </Box>
      <Divider variant="fullWidth" light />
      <List component="div" disablePadding className={classes.paddingWrapper}>
        {lo?.map((loObj, index) => {
          const { label, icon } = getMaterialType(loObj);
          return (loObj.selected === false || loObj?.elucidatSelect) && (
            <ListItem alignItems="flex-start" sx={{ px: '0' }} key={loObj?.name}>
              <Box className={classes.tmWrapper}>
                <Box className={classes.contentList} style={{ width: '100%' }}>
                  <Typography variant="subtitle2">
                    {`${zeroAppender(index + 1)}:`}
                    <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>
                      {loObj?.name}
                    </Typography>
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    {loObj.selected === false ? (
                      <Chip
                        style={{
                          color: FILL_ERROR_RED,
                          borderColor: FILL_ERROR_RED,
                          fontSize: '1rem',
                          fontWeight: 500,
                        }}
                        label="Excluded"
                        size="small"
                        variant="outlined"
                      />
                    ) : null}
                    <ScormTypeChip label={label} icon={icon} />
                  </Stack>
                </Box>
                {loObj?.elucidatSelect && (
                  <Box className={`${classes.contentList} ${classes.updateTmDetail}`} style={{ width: '100%' }}>
                    <Typography variant="subtitle2" className={classes.infoHelperText} style={{ paddingLeft: '2rem' }}>
                      SCORM ID:
                      <Typography variant="subtitle2" component="span" className={`${classes.infoContentText} ${classes.nestedText}`}>
                        {`${SCORMSELECT[loObj?.elucidatSelect]} Project ID : ${loObj.elucidatInput}`}
                      </Typography>
                    </Typography>
                    <Stack direction="row" spacing={1}>
                      {loObj?.elucidatSelect && (
                        <Chip
                          style={{
                            color: USER_CIRCLE_DARK_BG,
                            borderColor: USER_CIRCLE_DARK_BG,
                            fontSize: '1rem',
                            fontWeight: 500,
                          }}
                          label="Updated"
                          size="small"
                          variant="outlined"
                        />
                      )}
                    </Stack>
                  </Box>
                )}
              </Box>
            </ListItem>
          );
        })}
      </List>
      <Divider variant="fullWidth" light />
      <Container className={`${classes.newComponentWrapper} ${classes.paddingWrapper}`}>
        <LabledTextField
          label="Enter a new Component Title"
          name="newComponentTitle"
          required
          labelClass={classes.greyText}
          value={newComponentTitle}
          onChange={(e) => {
            if (e.target.value.length <= 255) {
              handleChange(e);
            }
          }}
        />
        <Box className={classes.errorBlock}>
          <Box style={{ minWidth: '1rem' }}>
            <ErrorText
              touchedField={touched.newComponentTitle}
              errorField={errors.newComponentTitle}
            />
          </Box>
          <Typography className={classes.rightText}>
            {(newComponentTitle || '').length}
            /255
          </Typography>
        </Box>

        <LabledTextField
          label="Enter a new Component Code"
          name="newComponentCode"
          required
          labelClass={classes.greyText}
          value={newComponentCode}
          onChange={(e) => {
            if (e.target.value.length <= 50) {
              handleChange(e);
            }
          }}
        />
        <Box className={classes.errorBlock}>
          <Box style={{ minWidth: '1rem' }}>
            <ErrorText
              touchedField={touched.newComponentCode}
              errorField={errors.newComponentCode}
            />
          </Box>
          <Typography className={classes.rightText}>
            {(newComponentCode || '').length}
            /50
          </Typography>
        </Box>
        <Box style={{ paddingTop: '2rem' }}>
          <Typography variant="subtitle2" className={classes.required}>User Access</Typography>
          <RadioGroup
            row
            className={classes.radioGroup}
            aria-label="channel"
            name="userAccess"
            value={userAccess}
            onChange={(e) => {
              handleChange(e);
            }}
          >
            {USER_ACCESS_OPTIONS.map(
              ({
                label, value,
              }) => (
                <>
                  <FormControlLabel
                    key={value}
                    value={value}
                    control={<CustomRadio />}
                    label={label}
                  />
                </>
              ),
            )}
          </RadioGroup>
          <ErrorText
            touchedField={touched.userAccess}
            errorField={errors.userAccess}
          />
        </Box>
      </Container>
    </>
  );
};

export default ReviewComponent;

ReviewComponent.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({
      lo: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          original_project_id: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.oneOf([null]),
          ]),
          project_id_status: PropTypes.string.isRequired,
          project_name: PropTypes.string.isRequired,
        }),
      ).isRequired,
      newComponentTitle: PropTypes.string.isRequired,
      newComponentCode: PropTypes.string.isRequired,
      userAccess: PropTypes.string.isRequired,
      configId: PropTypes.string.isRequired,
      componentName: PropTypes.string.isRequired,
    }).isRequired,
    errors: PropTypes.shape({
      newComponentTitle: PropTypes.string.isRequired,
      newComponentCode: PropTypes.string.isRequired,
      userAccess: PropTypes.string.isRequired,
    }).isRequired,
    touched: PropTypes.shape({
      newComponentTitle: PropTypes.string.isRequired,
      newComponentCode: PropTypes.string.isRequired,
      userAccess: PropTypes.string.isRequired,
    }).isRequired,
    setErrors: PropTypes.func.isRequired,
  }).isRequired,
  targetLpCode: PropTypes.string.isRequired,

  handleChange: PropTypes.func.isRequired,
};
