import _some from 'lodash/some';
import _every from 'lodash/every';
import _has from 'lodash/has';
import * as Yup from 'yup';

import {
  MATERIAL_TYPE_MAPPING, EMAIL_REGEX, COMPLETED, FAILED, CANCELLED,
} from '../constants';
import CheckRoundIcon from '../assets/icons/green-round-check.svg';
import ErrorRoundIcon from '../assets/icons/error-round.svg';
import BlueAlertIcon from '../assets/icons/icon-blue-alert.svg';
import ErrorRoundGrey from '../assets/icons/error_round_grey.svg';
import {
  DROPDOWN_HOVER, DROPDOWN_SELECT, PALE_GREY, WHITE,
} from '../stylesheets/colors';
// eslint-disable-next-line import/prefer-default-export
export const URLValidator = (str) => {
  const URLvalidatorExpression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi;
  const regex = new RegExp(URLvalidatorExpression);
  return !!str.match(regex);
};

export const getScorm = (doceboUrl, courseId, tm_id, slug_name) => `${doceboUrl}/learn/course/${courseId}/play/${tm_id}/${slug_name}`;

export const checkReleaseData = (e) => (!!((typeof e?.scorm_type === 'string' && e?.scorm_type?.length > 0 && e?.elucidat_data
  && e?.elucidat_data?.elucidat_project_id && e?.elucidat_data?.elucidat_release_id)));

export const anyScormValidInComponent = (tmList) => _some(tmList,
  (tmData) => checkReleaseData(tmData));

export const isAllScormValidInComponent = (tmList) => _every(tmList,
  (tmData) => checkReleaseData(tmData));

export const tableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const sortOrder = comparator(a[0], b[0]);
    if (sortOrder !== 0) return sortOrder;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const descendingComparator = (a, b, compOrderBy) => {
  if (typeof b[compOrderBy] === 'string' && b[compOrderBy]?.toLowerCase() < a[compOrderBy]?.toLowerCase()) {
    return -1;
  }
  if (typeof b[compOrderBy] === 'string' && b[compOrderBy]?.toLowerCase() > a[compOrderBy]?.toLowerCase()) {
    return 1;
  }
  if (b[compOrderBy] < a[compOrderBy]) {
    return -1;
  }
  if (b[compOrderBy] > a[compOrderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (compOrder, compOrderBy) => (compOrder === 'desc'
  ? (a, b) => descendingComparator(a, b, compOrderBy)
  : (a, b) => -descendingComparator(a, b, compOrderBy));

export const anyScormSelectedInComponent = (tmList) => _some(tmList, 'selected');
export const objectHasData = (result) => result && Object.keys(result).length > 0;

export const getMaterialType = (tmObj) => {
  const { sub_type, type, scorm_type } = tmObj;
  if (sub_type && MATERIAL_TYPE_MAPPING[sub_type]) return MATERIAL_TYPE_MAPPING[sub_type];
  if (_has(tmObj, 'scorm_type') && type === 'scormorg') {
    return { ...MATERIAL_TYPE_MAPPING[type], label: scorm_type ? `SCORM - ${scorm_type}` : 'SCORM - Unassigned' };
  }
  if (type && MATERIAL_TYPE_MAPPING[type]) return MATERIAL_TYPE_MAPPING[type];
  return MATERIAL_TYPE_MAPPING.unspecified;
};

export const getOptionsBgColor = ({ isDisabled, isFocused, isSelected }) => {
  let bgColor = WHITE;
  if (isSelected) {
    bgColor = DROPDOWN_SELECT;
  } else if (isFocused) {
    bgColor = DROPDOWN_HOVER;
  } else if (isDisabled) {
    bgColor = PALE_GREY;
  }
  return bgColor;
};

export const validateEmail = (email) => String(email)
  .toLowerCase()
  .match(EMAIL_REGEX);

export const getStatusLogo = (status) => {
  if (status === COMPLETED) {
    return CheckRoundIcon;
  } if (status === FAILED) {
    return ErrorRoundIcon;
  }
  if (status === CANCELLED) {
    return ErrorRoundGrey;
  }
  return BlueAlertIcon;
};

export const getObjectLabel = (obj, valArray) => valArray.map((key) => obj[key]).join(' - ');

export const toArrayOfObject = (arr, propObj) => arr.map(
  (a) => ({
    value: propObj ? a[propObj.key] : a,
    label: propObj ? getObjectLabel(a, propObj.value) : a,
  }),
);

export const validateEmailSeperatedBySemiColon = (emails) => {
  const emailAdress = emails?.split(';');
  const emailArray = emailAdress.filter((element) => validateEmail(element.trim()));
  return emailAdress.length === emailArray.length;
};

export const getSquareBracketsString = (str) => str.match(/\[(.*?)\]/);

export const isValidUrl = (urlString) => {
  const urlPattern = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
  return !!urlPattern.test(urlString);
};

export const secondsToHms = (seconds) => {
  const h = Math.floor(seconds / 3600);
  // eslint-disable-next-line no-mixed-operators
  const m = Math.floor(seconds % 3600 / 60);

  const hDisplay = `${h} h `;
  const mDisplay = m > 0 ? m + (m === 1 ? 'min' : ' mins') : '';
  return hDisplay + mDisplay;
};

export const objectToQueryString = (obj) => {
  const keys = Object.keys(obj);
  const keyValuePairs = keys.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
  return keyValuePairs.join('&');
};

export const isEmpty = (value) => (value == null || value.length === 0);

export const isScormUnassigned = (tm) => (tm?.type?.includes('scorm') && isEmpty(tm?.scorm_type));

export const checkSequentialNumber = (str) => {
  for (let i = 0; i < str.length; i += 1) {
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(str[i]) && !isNaN(str[i + 1]) && !isNaN(str[i + 2])) {
      if (parseInt(str[i], 10) + 1 === parseInt(str[i + 1], 10)
        && parseInt(str[i], 10) + 2 === parseInt(str[i + 2], 10)) return false;
      if (parseInt(str[i], 10) - 1 === parseInt(str[i + 1], 10)
        && parseInt(str[i], 10) - 2 === parseInt(str[i + 2], 10)) return false;
    }
  }
  return true;
};

export const checkSequentialCharcter = (str) => {
  const charRegex = /[A-Za-z]/;
  for (let i = 0; i < str.length; i += 1) {
    if (charRegex.test(str[i])
      && charRegex.test(str[i + 1])
      && charRegex.test(str[i + 2])
      && charRegex.test(str[i + 3])) {
      if (str.charCodeAt(i) + 1 === str.charCodeAt(i + 1)
        && str.charCodeAt(i) + 2 === str.charCodeAt(i + 2)
        && str.charCodeAt(i) + 3 === str.charCodeAt(i + 3)
      ) return false;
    }
  }
  return true;
};

export const validateUrl = Yup.string().matches(
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/,
  'Enter correct url',
);

// eslint-disable-next-line no-restricted-globals
export const isDateValid = (dateStr) => !isNaN(new Date(dateStr));
