import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box } from '@mui/material';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography/Typography';

const useStyles = makeStyles({
  cancelActionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    '& > span': {
      marginTop: '1rem',
    },
  },

  cancelActionButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '2rem',

    '& > button:nth-child(2)': {
      marginLeft: '2rem',
    },
  },
});

const LoaderCancelSection = ({
  cancelText, onCancelTransaction, cancellationRequested, setCancellationRequested,
}) => {
  const classes = useStyles();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleCancelOperation = () => {
    if (!showConfirmation) {
      setShowConfirmation(true);
    } else {
      setCancellationRequested(true);
      onCancelTransaction();
    }
  };
  return (
    <Box>
      <Box className={classes.cancelActionWrapper}>
        {showConfirmation ? (
          <Typography component="span" variant="body1">
            {cancelText}
          </Typography>
        ) : null}
        <Box className={classes.cancelActionButtons}>
          {showConfirmation ? (
            <Button color="primary" variant="outlined" onClick={() => { setShowConfirmation(false); }} disabled={cancellationRequested}>
              Continue Operation
            </Button>
          ) : null}
          <Button color="primary" variant="contained" style={{ marginTop: !showConfirmation ? '3rem' : '' }} onClick={() => { handleCancelOperation(); }} disabled={cancellationRequested}>
            Cancel Operation
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

LoaderCancelSection.propTypes = {
  cancelText: PropTypes.string.isRequired,
  onCancelTransaction: PropTypes.func.isRequired,
  cancellationRequested: PropTypes.bool.isRequired,
  setCancellationRequested: PropTypes.func.isRequired,
};

export default LoaderCancelSection;
