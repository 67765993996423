import React, {
  useEffect, useState, useCallback, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@mui/material/Box';
import MasterDataSidebar from '../../components/MasterData/MasterDataSidebar';
import { VERY_LIGHT_GREY, WHITE, MEDIUM_GREY } from '../../stylesheets/colors';
import ClientPage from '../../components/MasterData/ClientPage';
import PortfolioPage from '../../components/MasterData/PortfolioPage';
import SkillPage from '../../components/MasterData/SkillPage';
import {
  CLIENT_MASTER, PORTFOLIO_MASTER, SKILL_MASTER, ADMIN_GROUP,
} from '../../constants';
import {
  getPortfolioData, createNewPortfolio, deletePortfolio, updatePortFolio,
  getPortfolioOfferingData,
} from '../../store/actions/portfolioMasterData';
import MyContext from '../../context';
import {
  getClientData, updateClientCode, addNewClient, deleteClient, deleteClientReset,
} from '../../store/actions/clientMasterData';
import {
  getSkillData, addNewSkill, updateSkillData, deleteSkill, resetMappedSkillLp,
} from '../../store/actions/skillMasterData';

const useStyles = makeStyles({
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: VERY_LIGHT_GREY,
    minHeight: '100vh',
  },
  containerWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  sidebarWrapper: {
    display: 'flex',
    flex: 0.15,
    minWidth: '15rem',
  },
  bodyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.85,
    margin: '2.5rem',
    background: WHITE,
  },
});

const pageStyle = makeStyles({
  pageHeader: {
    padding: '2rem',
  },
  portfolioHeading: {
    margin: '1rem 0',
  },
  portfolioTopSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1rem 0',
  },
  inputSearchField: {
    width: '22rem',
  },
  table: {
    '& .MuiTableCell-root': {
      padding: '0.7rem',
    },
    '& th.MuiTableCell-root:first-child > div': {
      marginLeft: '1.5rem',
    },
  },
  secondaryTextCls: {
    fontSize: '2.25rem',
  },
  noDataContent: {
    boxShadow: `0px 1px 4px -2px ${MEDIUM_GREY}`,
    padding: '2rem',
  },
  exportBtnWrapper: {
    alignItems: 'center',
  },
});

const MasterData = (props) => {
  const {
    match,
    getPortfolioData: getPortfolioMasterData,
    getPortfolioOfferingData: getPortfolioOfferingsList,
    getClientData: getClientMasterData,
    createNewPortfolio: addNewPortfolio,
    deletePortfolio: deletePortfolioData,
    updatePortFolio: updatePortfolioData,
    updateClientCode: updateClientInfo,
    addNewClient: addNewClientData,
    deleteClient: deleteClientById,
    deleteClientReset: deleteClientResetRequest,
    getSkillData: getSkillMasterData,
    addNewSkill: addNewSkillData,
    updateSkillData: updateSkill,
    deleteSkill: deleteSkillData,
    resetMappedSkillLp: deleteSkillResetRequest,
    portfolioData,
    clientData,
    skillData,
    deleteClientData,
  } = props;
  const { role } = useContext(MyContext);
  const isAdmin = ADMIN_GROUP.includes(role);
  const classes = useStyles();
  const pageClasses = pageStyle();
  const programSubType = get(match, 'params.programSubType');

  const [clientMasterData, setClientMasterData] = useState({});
  const [portfolioOfferingData, setPortfolioOfferingDataData] = useState({});
  const [skillMasterData, setSkillMasterData] = useState({});
  const [alertMessage, setAlertMessage] = useState({ type: '', message: '' });
  const [searchClientText, setSearchClientText] = useState('');
  const [searchPortfolioText, setSearchPortfolioText] = useState('');
  const [searchSkillText, setSearchSkillText] = useState('');
  const pageSize = 50;
  const pageNum = 1;

  useEffect(() => {
    const data = {
      pageSize,
      pageNum,
      searchValue: '',
    };
    if (programSubType === CLIENT_MASTER) {
      getClientMasterData(data);
    } else if (programSubType === PORTFOLIO_MASTER) {
      getPortfolioMasterData(data);
    } else if (programSubType === SKILL_MASTER) {
      getSkillMasterData(data);
    }
  }, [getClientMasterData, getPortfolioMasterData, getSkillMasterData, programSubType]);

  useEffect(() => {
    const data = {
      pageSize,
      pageNum,
      searchValue: searchPortfolioText,
    };
    if (portfolioData.isError) {
      setAlertMessage({ type: 'error', message: portfolioData.message });
    } else {
      if (portfolioData.message === 'Portfolio is created') {
        getPortfolioMasterData(data);
        setAlertMessage({ type: 'success', message: 'Offerings has been added successfully.' });
      }
      if (portfolioData.message === 'Portfolio deleted successfully') {
        getPortfolioMasterData(data);
        setAlertMessage({ type: 'success', message: 'Offerings has been deleted successfully.' });
      }
      if (portfolioData.message === 'Portfolio updated') {
        getPortfolioMasterData(data);
        setAlertMessage({ type: 'success', message: 'Offerings has been updated successfully.' });
      }
    }
  }, [getPortfolioMasterData, portfolioData.isError, portfolioData.message, searchPortfolioText]);

  useEffect(() => {
    if (clientData.isError) {
      setAlertMessage({ type: 'error', message: clientData.message });
    } else if (clientData.message === 'Client updated') {
      const data = {
        pageSize,
        pageNum,
        searchValue: searchClientText,
      };
      getClientMasterData(data);
      setAlertMessage({ type: 'success', message: 'Client has been updated successfully.' });
    } else if (clientData.message === 'Client record created') {
      const data = {
        pageSize,
        pageNum,
        searchValue: searchClientText,
      };
      getClientMasterData(data);
      setAlertMessage({ type: 'success', message: 'Client has been added successfully.' });
    }
  }, [getClientMasterData, clientData.message, clientData.isError, searchClientText]);

  useEffect(() => {
    if (skillData.isError) {
      setAlertMessage({ type: 'error', message: skillData.message });
    } else if (skillData.statusMsg === 'skill has been updated'
      || skillData.statusMsg === 'new skill has been created'
      || skillData.statusMsg === 'Skill has been deleted'
    ) {
      const data = {
        pageSize,
        pageNum,
        searchValue: searchSkillText,
      };
      getSkillMasterData(data);
      setAlertMessage({ type: 'success', message: skillData.message });
    }
  }, [searchSkillText,
    skillData.isError, skillData.statusMsg, getSkillMasterData, skillData.message]);

  useEffect(() => {
    if (deleteClientData.isError) {
      setAlertMessage({ type: 'error', message: deleteClientData.message });
    } else if (deleteClientData.status === 'success' && deleteClientData.isMappedCohorts === false) {
      setAlertMessage({ type: 'success', message: `${deleteClientData?.clientName} has been deleted successfully!` });
      const data = {
        pageSize,
        pageNum,
        searchValue: searchClientText,
      };
      getClientMasterData(data);
    }
    // eslint-disable-next-line max-len
  }, [deleteClientData.isError, deleteClientData.message, deleteClientData.status, deleteClientData.clientName, deleteClientData.isMappedCohorts, getClientMasterData, searchClientText]);

  useEffect(() => {
    if (programSubType === CLIENT_MASTER) {
      setClientMasterData(clientData);
    } else if (programSubType === PORTFOLIO_MASTER) {
      setPortfolioOfferingDataData(portfolioData);
    } else if (programSubType === SKILL_MASTER) {
      setSkillMasterData(skillData);
    }
  }, [clientData, skillData, portfolioData, programSubType]);

  const addNewOffering = (data) => {
    addNewPortfolio(data);
  };

  const updateOffering = (data) => {
    updatePortfolioData(data);
  };

  const deleteOffering = (id) => {
    deletePortfolioData(id);
  };

  const createNewClient = (data) => {
    addNewClientData(data);
  };

  const updateClient = (data) => {
    updateClientInfo(data);
  };

  const deleteClientRow = (data) => {
    deleteClientById(data);
  };

  const loadMoreData = useCallback((page, search) => {
    const data = {
      pageSize,
      pageNum: page,
      searchValue: search,
    };
    if (programSubType === CLIENT_MASTER) {
      getClientMasterData(data);
      setSearchClientText(search);
    } else if (programSubType === PORTFOLIO_MASTER) {
      getPortfolioMasterData(data);
      setSearchPortfolioText(search);
    } else if (programSubType === SKILL_MASTER) {
      getSkillMasterData(data);
      setSearchSkillText(search);
    }
  }, [getClientMasterData, getPortfolioMasterData, getSkillMasterData, programSubType]);

  const masterDataAssignment = {
    [CLIENT_MASTER]: ClientPage,
    [PORTFOLIO_MASTER]: PortfolioPage,
    [SKILL_MASTER]: SkillPage,
  };

  const Page = masterDataAssignment[programSubType];
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.containerWrapper}>
        <Box className={classes.sidebarWrapper}>
          <MasterDataSidebar
            programSubType={programSubType}
          />
        </Box>
        <Box className={classes.bodyWrapper}>
          <Page
            clientMasterData={clientMasterData}
            portfolioOfferingData={portfolioOfferingData}
            skillMasterData={skillMasterData}
            deleteClientData={deleteClientData}
            addNewOffering={addNewOffering}
            deleteOffering={deleteOffering}
            updateOffering={updateOffering}
            alertMessage={alertMessage}
            setAlertMessage={setAlertMessage}
            addNewClient={createNewClient}
            updateClient={updateClient}
            deleteClient={deleteClientRow}
            deleteClientResetRequest={deleteClientResetRequest}
            loadMoreData={loadMoreData}
            getPortfolioOfferingsList={getPortfolioOfferingsList}
            isAdmin={isAdmin}
            addNewSkill={addNewSkillData}
            updateSkill={updateSkill}
            deleteSkill={deleteSkillData}
            classes={pageClasses}
            deleteSkillResetRequest={deleteSkillResetRequest}
          />
        </Box>
      </Box>
    </Box>
  );
};
MasterData.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      programSubType: PropTypes.string,
    }),
  }).isRequired,
  getPortfolioData: PropTypes.func.isRequired,
  getClientData: PropTypes.func.isRequired,
  getSkillData: PropTypes.func.isRequired,
  createNewPortfolio: PropTypes.func.isRequired,
  deletePortfolio: PropTypes.func.isRequired,
  updatePortFolio: PropTypes.func.isRequired,
  portfolioData: PropTypes.object.isRequired,
  clientData: PropTypes.object.isRequired,
  skillData: PropTypes.object.isRequired,
  deleteClientData: PropTypes.object.isRequired,
  updateClientCode: PropTypes.func.isRequired,
  addNewClient: PropTypes.func.isRequired,
  deleteClient: PropTypes.func.isRequired,
  deleteClientReset: PropTypes.func.isRequired,
  getPortfolioOfferingData: PropTypes.func.isRequired,
  addNewSkill: PropTypes.func.isRequired,
  updateSkillData: PropTypes.func.isRequired,
  deleteSkill: PropTypes.func.isRequired,
  resetMappedSkillLp: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  portfolioData: state.portfolioMasterData,
  clientData: state.clientMasterData,
  deleteClientData: state.deleteClientMasterReducer,
  skillData: state.skillMasterData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    getPortfolioData,
    getClientData,
    createNewPortfolio,
    deletePortfolio,
    updatePortFolio,
    updateClientCode,
    addNewClient,
    deleteClient,
    deleteClientReset,
    getPortfolioOfferingData,
    getSkillData,
    addNewSkill,
    updateSkillData,
    deleteSkill,
    resetMappedSkillLp,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(MasterData);
