import {
  SHOW_NOTIFICATION, REMOVE_NOTIFICATION,
} from '../constants';

const initialState = null;

export default function NotificationReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return {
        ...state,
        variant: action.payload.variant,
        message: action.payload.message,
      };
    case REMOVE_NOTIFICATION:
      return null;
    default:
      return state;
  }
}
