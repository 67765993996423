import { connectRouter, routerMiddleware } from 'connected-react-router';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import { reducerBuilder as midReducer } from '@cs/mid-react-saga';

import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import clientMasterData from './reducers/clientMasterData';
import skillMasterData from './reducers/skillMasterData';
import portfolioMasterData from './reducers/portfolioMasterData';
import * as constants from './constants';
import platformUsersData from './reducers/PlatformLevelUsers/users';
import platformUserDetailsData from './reducers/PlatformLevelUsers/userDetail';
import FileValidateReducer from './reducers/FileValidateReducer';
import FileUploadReducer from './reducers/FileUploadReducer';
import deleteClientMasterReducer from './reducers/deleteClientMasterReducer';
import notificationReducer from './reducers/notificationReducer';
import learningProgramsReducer from './reducers/learningProgramsReducer';
import lpFilterFormReducer from './reducers/lpFilterFormReducer';

function reducer(state = {}, action) {
  switch (action.type) {
    case constants.TEST_FETCH_SUCCESS: {
      return { ...state, posts: JSON.parse(action.payload.posts).posts };
    }
    case constants.ANTIVIRUS_CHECK: {
      return { ...state, payload: action.payload };
    }
    case constants.ANTIVIRUS_CHECK_SUCCESS: {
      return { ...state, antivirusResult: JSON.parse(action.payload.res).av_status };
    }
    default:
      return state;
  }
}

function createReducer(history, mid) {
  return combineReducers({
    mid: midReducer(mid),
    router: connectRouter(history),
    reducer,
    clientMasterData,
    portfolioMasterData,
    skillMasterData,
    platformUsersData,
    platformUserDetailsData,
    FileValidateReducer,
    FileUploadReducer,
    deleteClientMasterReducer,
    notification: notificationReducer,
    learningPrograms: learningProgramsReducer,
    filterFormData: lpFilterFormReducer,
  });
}

export default function configureStore(history, sagaMiddleware, mid) {
  const store = createStore(
    createReducer(history, mid),
    composeWithDevTools(applyMiddleware(routerMiddleware(history), sagaMiddleware, logger)),
  );

  return store;
}
