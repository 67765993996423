import FileDownload from 'js-file-download';
import config from '../../config';
import request from '../../helpers/apiHelper';

export const getWaves = async (programId) => {
  const url = `${config.ROUTES.GET_WAVES}/${programId}?fetch_deleted=false`;
  const method = 'GET';
  const options = { url, method };
  const result = await request(options);

  return result;
};

export const createWave = async (programId, data) => {
  const url = `${config.ROUTES.GET_WAVES}/${programId}`;
  const method = 'POST';
  const options = { url, method, data };
  const result = await request(options);

  return result;
};

export const updateWave = async (programId, data) => {
  const url = `${config.ROUTES.GET_WAVES}/${programId}`;
  const method = 'PUT';
  const options = { url, method, data };
  const result = await request(options);

  return result;
};

export const deleteCohort = async (waveId) => {
  const url = `${config.ROUTES.DELETE_WAVES}/${waveId}`;
  const method = 'DELETE';
  const options = { url, method };
  const result = await request(options);

  return result;
};

export const getCohortUserList = async (waveId, page, pageSize, searchValue) => {
  const url = `${config.ROUTES.GET_WAVES}/users/${waveId}?page=${page}&page_size=${pageSize}&search=${searchValue}`;
  const method = 'GET';
  const options = { url, method };
  const result = await request(options);

  return result;
};

export const updateCohortUserList = async (waveId, data) => {
  const url = `${config.ROUTES.GET_WAVES}/users/${waveId}`;
  const method = 'PUT';
  const options = { url, method, data };
  const result = await request(options);

  return result;
};

export const fetchWaveDetails = async (waveId) => {
  const url = `${config.ROUTES.GET_WAVES}/details/${waveId}`;
  const method = 'GET';
  const options = { url, method };
  const result = await request(options);

  return result;
};

export const uploadXlsx = async (programSubType, data) => {
  let url;
  if (programSubType === 'ec_users_management') {
    url = `${config.ROUTES.ENROLLMENT_METADATA}/${data.get('wave_id')}`;
  } else {
    url = `${config.ROUTES.TRANSFER_USERS_WAVE}/${data.get('program_id')}`;
  }
  const method = 'POST';
  const options = { url, method, data };
  const result = await request(options);
  return result;
};

export const fetchComponentDueDateApi = async (waveId) => {
  const url = `${config.ROUTES.COMPONENTS_DUE_DATE}/${waveId}`;
  const method = 'GET';
  const options = { url, method };
  const result = await request(options);

  return result;
};

export const postComponentDueDateApi = async (waveId, data) => {
  const url = `${config.ROUTES.COMPONENTS_DUE_DATE}/${waveId}`;
  const method = 'POST';
  const options = { url, method, data };
  const result = await request(options);

  return result;
};

export const getEnrollmentMetaData = async (waveId) => {
  const url = `${config.ROUTES.ENROLLMENT_METADATA}/${waveId}`;
  const method = 'GET';
  const options = { url, method };
  const result = await request(options);

  return result;
};
export const downloadEnrollMentMetaData = async (waveId) => {
  const url = `${config.ROUTES.ENROLLMENT_METADATA}/${waveId}`;
  const options = { url, method: 'GET', responseType: 'blob' };
  const result = await request(options);
  const { data } = result;
  FileDownload(data, 'cohort_user_metadata.xlsx');
  return result;
};

export const getUntaggedUser = async (waveId) => {
  const url = `${config.ROUTES.UNTAGGED_USERS}/${waveId}`;
  const options = { url, method: 'GET', responseType: 'blob' };
  const result = await request(options);
  const { data } = result;
  FileDownload(data, 'cohort_untagged_users.xlsx');
  return result;
};

export const updateDefaultCohort = async (programId, data) => {
  const url = `${config.ROUTES.MANAGE_COHORT}/${programId}`;
  const method = 'PUT';
  const options = { url, method, data };
  const result = await request(options);

  return result;
};

export const fetchClientId = async () => {
  const url = `${config.ROUTES.MASTER_DATA_CLIENT_ID}`;
  const method = 'GET';
  const options = { url, method };
  const result = await request(options);

  return result;
};

export const updateCohortPassword = async (data) => {
  const url = `${config.ROUTES.COHORT_USER_PASSWORD_UPDATE}`;
  const method = 'POST';
  const options = { url, method, data };
  const result = await request(options);

  return result;
};

export const checkUsersOnClientId = async (cohort_id, client_id) => {
  const url = `${config.ROUTES.WAVE_MANAGEMENT}/validate/${cohort_id}/client/${client_id}`;
  const method = 'GET';
  const options = { url, method };
  const result = await request(options);

  return result;
};
